import React from 'react'
import { Flex, Money, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import sizes from '../../../ui-kit/sizes'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import activityType from '../../../types/activities'
import { statusPaymentPlanMapping } from '../../payments/paymentPlan/util'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import ActivityCardStatus from './ActivityCardStatus'
import { getCreatedByEvent } from '../utils'
import { getPaymentMethodNickName } from '../../payments/paymentsTab/util'

const PaymentPlanActivity = ({ paymentPlan }) => {
  const { t } = useTranslation()
  const entity = paymentPlan?.entity
  const mappedStatus = statusPaymentPlanMapping(entity?.paymentPlanStatus, entity?.approvedAt)

  return (
    <Flex justifyContent="between">
      <Flex>
        <ActivityCardDate date={paymentPlan?.createdAt} />
        <ActivityCardGeneralInfo
          additionalCaptionNode={
            <>
              {' '}
              ({entity?.paymentPlanInstallments?.length} {t('installments')})
            </>
          }
          detailsValue={getCreatedByEvent(paymentPlan)}
          entityId={entity?.id}
          entityLabelKey="paymentPlan"
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="between">
        <Flex>
          <Flex shrink={0}>
            <Flex column>
              <Text className="mt-1" color="text-black-500">
                {t('totalPlanAmount')}:
              </Text>
              <Text className="mt-3" color="text-black-500">
                {t('paymentMethod')}:
              </Text>
            </Flex>
            <Flex className="ml-1" column>
              <Money className="font-semibold text-xl" value={entity?.amountCents} />
              <Text className="mt-2" size={sizes.SM}>
                {entity?.paymentMethod?.title +
                  getPaymentMethodNickName(entity?.paymentMethod?.nickname)}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <ActivityCardStatus
          lastUpdatesDate={paymentPlan.lastUpdateDate}
          statusNode={<StatusBadge color={mappedStatus?.color} value={t(mappedStatus?.label)} />}
        />
      </Flex>
    </Flex>
  )
}

export default PaymentPlanActivity

PaymentPlanActivity.propTypes = {
  paymentPlan: activityType,
}

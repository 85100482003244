import PropTypes from 'prop-types'
import React from 'react'
import { Flex } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'

const ProjectPartyList = ({ data, setIsOpenedModal, setIsSidebarOpened }) => {
  const { t } = useTranslation()
  const descriptionData = [
    {
      value: data.businessName,
      label: t('projectPartyBusinessName'),
    },
    {
      value: data.legalName,
      label: t('projectPartyLegalName'),
    },
    {
      value: t(data.role),
      label: t('projectPartyRole'),
    },
    {
      value: data.name,
      label: t('primaryContactName'),
    },
    {
      value: data.email,
      label: t('email'),
    },
    {
      value: data.formattedOfficePhone,
      label: t('officePhone'),
    },
    {
      value: data?.formattedMobilePhone,
      label: t('mobilePhone'),
    },
    {
      value: data.address?.toHuman,
      label: t('address'),
    },
  ]

  return (
    <Flex className="pt-8 pt-2 px-0.5 w-[40rem]" column>
      <div className="w-full">
        <Flex className="w-full" column>
          <DividedList content={descriptionData} />
        </Flex>

        <Flex className="w-full pt-6" justifyContent="end">
          <Button
            label={t('close')}
            onClick={() => {
              setIsOpenedModal(false)
              setIsSidebarOpened(true)
            }}
            testData="open-project-party"
            variant={buttonsVariants.TERTIARY}
          />
        </Flex>
      </div>
    </Flex>
  )
}

ProjectPartyList.propTypes = {
  data: PropTypes.object,
  setIsOpenedModal: PropTypes.func.isRequired,
  setIsSidebarOpened: PropTypes.func.isRequired,
}

export default ProjectPartyList

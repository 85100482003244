import React, { useMemo, useState } from 'react'
import { Flex, Money, Text, Tooltip } from '../../index'
import FormattedDate from '../text/FormattedDate'
import { useTranslation } from 'react-i18next'
import Accordion from '../accordion/Accordion'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import PT from 'prop-types'
import { dateType, idType, moneyType } from '../../../types/defaultTypes'
import ViewInvoiceLink from '../../../pages/invoices/ViewInvoiceLink'

const InvoicesAccordion = ({ invoices, className }) => {
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      {
        field: 'invoiceNumber',
        headerName: t('number'),
        renderCell: (values) => (
          <Flex className="text-ellipsis overflow-hidden" column>
            <Tooltip content={values?.row?.invoiceNumber}>
              <ViewInvoiceLink
                id={values?.row?.hashid}
                invoiceNumber={values?.row?.invoiceNumber}
                isNumberView
              />
            </Tooltip>
            <Tooltip content={values?.row?.project?.name}>
              <div className="text-ellipsis overflow-hidden">
                <Text>{values?.row?.project?.name || '-'}</Text>
              </div>
            </Tooltip>
          </Flex>
        ),
        flex: 40,
        sortable: false,
      },
      {
        field: 'maturityDate',
        headerName: t('dueDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.maturityDate} />,
        flex: 30,
        sortable: false,
      },
      {
        field: 'outstandingAmountCents',
        headerName: t('o/s'),
        renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
        align: 'right',
        headerAlign: 'right',
        flex: 30,
        sortable: false,
      },
    ],
    [t],
  )
  const [isInvoicesDetailsViewOpened, setIsInvoicesDetailsViewOpened] = useState(false)
  const invoicesDetailsButtonLabelKey = useMemo(() => {
    if (invoices?.length === 1) {
      return isInvoicesDetailsViewOpened ? 'hideInvoice' : 'viewInvoice'
    }

    return isInvoicesDetailsViewOpened ? 'hideInvoices' : 'viewInvoices'
  }, [invoices, isInvoicesDetailsViewOpened])
  return (
    <Accordion
      isExpanded={isInvoicesDetailsViewOpened}
      setIsExpanded={setIsInvoicesDetailsViewOpened}
      summaryLabel={t(invoicesDetailsButtonLabelKey)}
      wrapperClassName={className}
      isMobile>
      <DataGridComponent columns={columns} pageSize={100} rows={invoices} localPagination />
    </Accordion>
  )
}

export default InvoicesAccordion

InvoicesAccordion.propTypes = {
  className: PT.string,
  invoices: PT.arrayOf(
    PT.shape({
      id: idType,
      invoiceNumber: PT.number,
      outstandingAmountCents: moneyType,
      maturityDate: dateType,
      project: PT.shape({
        id: idType,
        name: PT.string,
      }),
    }),
  ).isRequired,
}
InvoicesAccordion.defaultProps = {
  className: '',
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { VendorPaymentSettingRequest } from '../../queries/mutations/updateVendorPaymentSettingRequest.gql'
import { UpdateVendorPaymentPreferences } from '../../queries/mutations/updateVendorPaymentPreferences.gql'
import { useNotifications } from '../../hooks/useNotifications'
import { VendorSettingsQuery } from '../../queries/vendors.gql'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import AsideContainer from '../../components/containers/AsideContainer'
import PaymentSettingsContent from '../../components/paymentSettingsContent/PaymentSettingsContent'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { applyCurrentUserUpdate } from '../../store/actions/auth'
import { useDispatch } from 'react-redux'

export const vendorPreferences = {
  lienWaiverRequestEnabled: true,
  showCreditLimit: true,
}

const PaymentSettings = () => {
  const [updateVendorSettings, { loading: isUpdateVendorSettingsLoading }] = useCustomMutation({
    mutation: VendorPaymentSettingRequest,
    rollbarOptions: { operationName: 'VendorPaymentSettingRequest', target: 'PaymentSettings' },
    mutationOptions: {
      refetchQueries: [VendorSettingsQuery],
    },
  })

  const [updateVendorPreference, { loading: isUpdateVendorPrefLoading }] = useCustomMutation({
    mutation: UpdateVendorPaymentPreferences,
    rollbarOptions: { operationName: 'VendorPaymentSettingRequest', target: 'PaymentSettings' },
    mutationOptions: {
      refetchQueries: [VendorSettingsQuery],
    },
  })

  const { newNotification } = useNotifications()
  const currentUser = useCurrentUser()

  const { data, loading } = useCustomQuery({
    query: VendorSettingsQuery,
    queryOptions: {
      variables: { id: currentUser?.vendorId, withPaymentSettings: true, withPreference: true },
    },
    rollbarOptions: { operationName: 'VendorSettingsQuery', target: 'PaymentSettings' },
  })

  const vendorPaymentSettings = data?.vendor?.paymentSettings
    ? {
        ...data?.vendor?.paymentSettings,
        ...data?.vendor?.vendorPreference,
      }
    : {}
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const updatePaymentSettings = (variables, closeFunc) => {
    updateVendorSettings({
      variables,
    }).then(({ data }) => {
      const responseData = data?.updateVendorPaymentSetting || {}

      if (responseData?.entity) {
        newNotification({ success: t('paymentSettingsUpdated') })
        const user = {
          ...currentUser,
          paymentSettings: responseData?.entity,
        }
        dispatch(applyCurrentUserUpdate(user))
        if (closeFunc) {
          closeFunc(false)
        }
      }
    })
  }

  const updatePaymentPreference = (variables, closeFunc) => {
    updateVendorPreference({
      variables,
    }).then(({ data }) => {
      const responseData = data?.updateVendorPaymentSetting || {}

      if (responseData?.entity) {
        newNotification({ success: t('paymentSettingsUpdated') })
        if (closeFunc) {
          closeFunc(false)
        }
      }
    })
  }

  return (
    <>
      <AsideContainer title={t('paymentSettings')}>
        <PaymentSettingsContent
          data={vendorPaymentSettings}
          disabledOptions={['financeCharges', 'creditCardConvenienceFee']}
          isUpdatePaymentSettingsLoading={
            isUpdateVendorSettingsLoading || isUpdateVendorPrefLoading
          }
          loading={loading}
          updatePaymentPreference={updatePaymentPreference}
          updatePaymentSettings={updatePaymentSettings}
          isGlobalSettings
          withCardConvenienceFee
          withCreditLimit
          withLienWaiver
        />
      </AsideContainer>
    </>
  )
}

export default PaymentSettings

import React, { useCallback, useMemo } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Text, Flex, Money, Button, FormattedDate, Icon } from '../../ui-kit'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { useNavigate } from 'react-router-dom'
import DividedList from '../../ui-kit/components/sidebar/DividedList'
import StatusBadge from '../../ui-kit/components/badges/StatusBadge'
import { projectsStatusMapping } from './projectsUtils'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { ProjectPartiesOverviewQuery } from '../../queries/projectParties/projectPartiesOverview.gql'
import { ProjectDocumentsQuery } from '../../queries/projectDocuments.gql'
import colors from '../../ui-kit/colors'
import InvoicesAccordion from '../../ui-kit/components/invoicesAccordion/InvoicesAccordion'
import { camelCase } from 'lodash/string'
import { DeleteFileMutation } from '../../queries/mutations/deleteFile.gql'
import { useNotifications } from '../../hooks/useNotifications'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'

const ProjectsContent = ({
  data,
  contractId,
  setIsSidebarOpened,
  setViewProjectPartyId,
  setEditableProjectPartyId,
  setIsEditProjectModalOpened,
  setIsAddProjectPartyModalOpened,
  setIsAddProjectDocumentModalOpened,
  setIsEditProjectPartyModalOpened,
}) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()
  const navigate = useNavigate()
  const getDisplayValue = (value) => {
    if (value === null) {
      return '-'
    } else if (value) {
      return t('yes')
    }
    return t('no')
  }

  const descriptionData = useMemo(
    () => [
      {
        label: t('status'),
        value: (
          <StatusBadge
            color={projectsStatusMapping[data.status]?.color}
            value={t(projectsStatusMapping[data.status]?.label)}
          />
        ),
      },
      { label: t('projectId'), value: data.referenceId },
      { label: t('projectType'), value: t(data.kind) },
      { label: t('address'), value: data.address?.toHuman, address: true },
      { label: t('estimatedStartDate'), value: <FormattedDate date={data.estimatedStartDate} /> },
      { label: t('estimatedEndDate'), value: <FormattedDate date={data.estimatedEndDate} /> },
      { label: t('estimatedMaterials'), value: <Money value={data.estimatedCostCents} /> },
      { label: t('buildingType'), value: t(camelCase(data.buildingType)) },
      { label: t('customerRole'), value: t(data.role) },
      { label: t('taxExempt'), value: getDisplayValue(data.taxExempt) },
      { label: t('bonded'), value: getDisplayValue(data.bonded) },
    ],
    [data],
  )

  const queryVariables = {
    projectId: data.id,
  }
  const { data: ppData } = useCustomQuery({
    query: ProjectPartiesOverviewQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !queryVariables.projectId,
    },
    rollbarOptions: { operationName: 'ProjectPartiesOverviewQuery', target: 'ProjectsContent' },
  })
  const projectParties = ppData?.projectParties || []

  const docQueryVariables = {
    id: data.id,
  }
  const { data: docData } = useCustomQuery({
    query: ProjectDocumentsQuery,
    queryOptions: {
      variables: docQueryVariables,
      skip: !docQueryVariables.id,
    },
    rollbarOptions: { operationName: 'ProjectDocumentsQuery', target: 'ProjectsContent' },
  })
  const projectDocuments = docData?.project?.documents || []

  const [deleteFile, { loading }] = useCustomMutation({
    mutation: DeleteFileMutation,
    mutationOptions: {
      refetchQueries: [ProjectDocumentsQuery],
    },
    rollbarOptions: { operationName: 'DeleteFileMutation', target: 'ProjectsContent' },
  })
  const handleDeleteDocument = useCallback((id) => {
    const variables = {
      id,
    }
    deleteFile({ variables }).then(({ data }) => {
      const responseData = data?.deleteFile || {}

      if (responseData?.entity) {
        newNotification({ success: t('documentDeletedSuccessfully') })
      }
    })
  }, [])

  return (
    <Flex className="mt-[-1.5rem]" column>
      <Flex column>
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
          {data.name}
        </Text>
        <Button
          label={t('goToInvoices')}
          onClick={() =>
            navigate(
              `/customers/${contractId}/invoices/outstanding?filters=projectName.${data.name}`,
            )
          }
          size={sizes.SM}
          testData="invoice-link"
          variant={buttonsVariants.LINK}
        />
      </Flex>

      <DividedList content={descriptionData} />

      <Button
        className="mt-4"
        label={t('editProjectInfo')}
        onClick={() => {
          setIsSidebarOpened(false)
          setIsEditProjectModalOpened(true)
        }}
        size={sizes.SM}
        testData="edit-project"
        variant={buttonsVariants.LINK}
      />

      <InvoicesAccordion className="mt-4" invoices={data.invoices} />

      <Flex className="mt-6" column>
        <Text fontWeight={fontWeight.MEDIUM}>{`${t('projectParties')}:`}</Text>
        {projectParties.map((projectParty) => (
          <Flex className="mt-3" key={projectParty.id} column>
            <Text color="text-warmBlack-400" size={sizes.SM}>
              {`${t(projectParty.role)}:`}
            </Text>
            <Flex justifyContent="between">
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {projectParty.businessName}
              </Text>
              <Flex>
                <Button
                  className="mr-3"
                  label={t('view')}
                  onClick={() => {
                    setIsSidebarOpened(false)
                    setViewProjectPartyId(projectParty.id)
                  }}
                  size={sizes.SM}
                  testData="project-detail"
                  variant={buttonsVariants.LINK}
                />
                <Button
                  className="mr-3"
                  label={t('edit')}
                  onClick={() => {
                    setIsEditProjectPartyModalOpened(true)
                    setIsSidebarOpened(false)
                    setEditableProjectPartyId(projectParty.id)
                  }}
                  size={sizes.SM}
                  variant={buttonsVariants.LINK}
                />
                {projectParty?.email && (
                  <a
                    className="hover:no-underline hover:text-blue-500 text-blue-500 font-medium"
                    href={`mailto:${projectParty.email}`}>
                    {t('contact')}
                  </a>
                )}
              </Flex>
            </Flex>
          </Flex>
        ))}
        <Button
          className="mt-4"
          iconName="plus"
          label={t('addNew')}
          onClick={() => {
            setIsSidebarOpened(false)
            setIsAddProjectPartyModalOpened(true)
          }}
          size={sizes.SM}
          testData="add-project-party"
          variant={buttonsVariants.LINK}
        />
      </Flex>

      <Flex className="mt-6" column>
        <Text fontWeight={fontWeight.MEDIUM}>{`${t('projectDocuments')}:`}</Text>
        {projectDocuments.map((document) => (
          <Flex alignItems="center" key={document.id}>
            <Button
              className="w-fit underline mt-3 text-left"
              key={document.id}
              label={document.name}
              onClick={() => window.open(document.url)}
              size={sizes.SM}
              testData="view-document"
              variant={buttonsVariants.LINK}
            />
            <Icon
              className="min-w-5 ml-2 mt-3"
              color={colors.GREY}
              disabled={loading}
              name="trash"
              onClick={() => handleDeleteDocument(document.id)}
            />
          </Flex>
        ))}
        <Button
          className="mt-4"
          iconName="plus"
          label={t('addNew')}
          onClick={() => {
            setIsSidebarOpened(false)
            setIsAddProjectDocumentModalOpened(true)
          }}
          size={sizes.SM}
          testData="add-new-doc"
          variant={buttonsVariants.LINK}
        />
      </Flex>
    </Flex>
  )
}

ProjectsContent.propTypes = {
  data: PT.object,
  contractId: PT.string,
  setIsSidebarOpened: PT.func.isRequired,
  setViewProjectPartyId: PT.func.isRequired,
  setEditableProjectPartyId: PT.func.isRequired,
  setIsEditProjectModalOpened: PT.func.isRequired,
  setIsAddProjectPartyModalOpened: PT.func.isRequired,
  setIsAddProjectDocumentModalOpened: PT.func.isRequired,
  setIsEditProjectPartyModalOpened: PT.func.isRequired,
}

ProjectsContent.defaultProps = {
  data: {},
  contractId: '',
}

export default ProjectsContent

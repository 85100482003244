import { combineReducers } from 'redux'
import authReducer, { AUTH_KEY } from './reducers/auth'
import breadcrumbsReducer, { BREADCRUMBS_KEY } from './reducers/breadcrumbs'
import notificationsReducer, { NOTIFICATIONS_KEY } from './reducers/notifications'
import memoReducer, { MEMO_KEY } from './reducers/memo'
import dirtyFormAlertReducer, { DIRTY_FORM_ALERT_KEY } from './reducers/dirtyFormAlert'
import filtersReducer, { FILTERS_KEY } from './reducers/filters'

const makeRootReducer = () =>
  combineReducers({
    [AUTH_KEY]: authReducer,
    [BREADCRUMBS_KEY]: breadcrumbsReducer,
    [NOTIFICATIONS_KEY]: notificationsReducer,
    [MEMO_KEY]: memoReducer,
    [DIRTY_FORM_ALERT_KEY]: dirtyFormAlertReducer,
    [FILTERS_KEY]: filtersReducer,
  })

export default makeRootReducer

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Dialog, Flex, Icon, Text, Tooltip } from '../../../ui-kit'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import colors from '../../../ui-kit/colors'
import sizes from '../../../ui-kit/sizes'
import UpdateAttachmentForm from '../../contracts/cashApp/updateAttachmentForm/UpdateAttachmentForm'
import { useTranslation } from 'react-i18next'

const DepositAttachment = ({ attachment, setAttachment }) => {
  const { t } = useTranslation()
  const [isAttachmentMemoModalOpened, setIsAttachmentMemoModalOpened] = useState(false)
  const onAttachmentSubmit = (values) => {
    setAttachment(values.file)
    setIsAttachmentMemoModalOpened(false)
  }
  const handleDeleteAttachment = () => {
    setAttachment(null)
    setIsAttachmentMemoModalOpened(false)
  }

  return (
    <>
      <Flex alignItems="center" className="w-full mt-6">
        {!attachment?.name ? (
          <Button
            iconName="plus"
            label={t('addAttachment')}
            onClick={() => setIsAttachmentMemoModalOpened(true)}
            size={sizes.SM}
            testData="add-new-doc"
            variant={buttonsVariants.LINK}
          />
        ) : (
          <Flex alignItems="center">
            <Tooltip className="flex" content={attachment?.name} placement="top">
              <Text className="w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">
                {attachment?.name}
              </Text>
            </Tooltip>
            <Icon
              className="min-w-5 ml-2"
              color={colors.GREY}
              name="pencil"
              onClick={() => setIsAttachmentMemoModalOpened(true)}
            />
            <Icon
              className="min-w-5 ml-2"
              color={colors.GREY}
              name="trash"
              onClick={handleDeleteAttachment}
            />
          </Flex>
        )}
      </Flex>
      <Dialog
        isOpened={isAttachmentMemoModalOpened}
        setIsOpened={setIsAttachmentMemoModalOpened}
        title={t('addAttachment')}>
        <UpdateAttachmentForm
          onSubmit={onAttachmentSubmit}
          setIsOpenedModal={setIsAttachmentMemoModalOpened}
          fileRequired
        />
      </Dialog>
    </>
  )
}

DepositAttachment.propTypes = {
  attachment: PropTypes.object,
  setAttachment: PropTypes.func,
}

export default DepositAttachment

import React from 'react'
import { Flex, Text } from '../../../ui-kit'
import activityType from '../../../types/activities'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import { getCreatedByEvent } from '../utils'

const NoteActivity = ({ note }) => {
  return (
    <Flex justifyContent="between">
      <Flex>
        <ActivityCardDate date={note?.createdAt} />
        <ActivityCardGeneralInfo
          detailsValue={getCreatedByEvent(note)}
          entityId={note.noteId}
          entityLabelKey="note"
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="end">
        <Text className="w-full line-clamp-3">{note.message}</Text>
      </Flex>
    </Flex>
  )
}

export default NoteActivity

NoteActivity.propTypes = {
  note: activityType,
}

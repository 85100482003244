export const DOCUMENT_TYPES = [
  {
    label: 'Credit Application',
    value: 'credit_application',
  },
  {
    label: 'Job Sheet',
    value: 'job_sheet',
  },
  {
    label: 'Job Contract',
    value: 'job_contract',
  },
  {
    label: 'Tax Exempt Form',
    value: 'tax_exempt_form',
  },
  {
    label: 'Bonding Information',
    value: 'bonding_information',
  },
  {
    label: 'Lien Document',
    value: 'lien_document',
  },
  {
    label: 'Commercial Credit Report',
    value: 'commercial_credit_report',
  },
  {
    label: 'Personal Credit Report',
    value: 'personal_credit_report',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

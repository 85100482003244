import React, { useState } from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import InputWrapper from './InputWrapper'

import classes from './Input.module.scss'
import NumberFormat from 'react-number-format'

const Input = ({
  id,
  value,
  name,
  label,
  helpText,
  placeholder,
  type,
  error,
  errorMessage,
  onChange,
  onKeyPress,
  onBlur,
  onFocus,
  required,
  className,
  disabled,
  icon,
  iconPosition,
  isCurrencyInput,
  decimalScale,
  prefix,
  isTableInput,
  testData,
  ...rest
}) => {
  const inputClassnames = cx(classes.input, {
    [classes.inputError]: !!errorMessage || error,
    [classes.leftPadding]: icon && iconPosition === 'start',
    [classes.inputTable]: isTableInput,
  })
  const wrapperClassNames = cx({ [classes.inputWrapper]: !className }, className)
  const [inputValue, setInputValue] = useState(value)

  const normalizeCurrencyValue = (value) =>
    typeof (value === 'string') && isCurrencyInput
      ? value.replaceAll('$', '').replaceAll(',', '')
      : value

  const handleChange = (e) => {
    const copyEvent = { ...e }
    copyEvent.target = {
      ...copyEvent.target,
      value: normalizeCurrencyValue(copyEvent.target.value),
    }
    if (onChange) {
      onChange(copyEvent)
    }
    setInputValue(e.target.value)
  }
  const [isFocused, setIsFocused] = useState(false)
  const handleFocus = () => {
    if (disabled) {
      return
    }

    setIsFocused(true)
    if (onFocus) {
      onFocus()
    }
  }
  const handleBlur = (e) => {
    setIsFocused(false)
    if (onBlur) {
      onBlur(e)
    }
  }

  return (
    <InputWrapper
      className={wrapperClassNames}
      errorMessage={errorMessage}
      helpText={helpText}
      id={id}
      label={label}>
      {!isCurrencyInput && (
        <input
          className={inputClassnames}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          required={required}
          testData={testData}
          type={type}
          value={onChange ? value : inputValue}
          {...rest}
        />
      )}
      {isCurrencyInput && (
        <NumberFormat
          allowNegative={false}
          className={`${inputClassnames} text-right`}
          decimalScale={decimalScale}
          decimalSeparator="."
          disabled={disabled}
          displayType="input"
          fixedDecimalScale={true}
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          prefix={prefix}
          required={required}
          testData={testData}
          thousandSeparator={true}
          thousandsGroupStyle="thousand"
          type="text"
          value={onChange ? value : inputValue}
        />
      )}
      {icon && (
        <button
          className={cx(classes.icon, classes[iconPosition], {
            [classes.focusedIcon]: isFocused && !disabled,
          })}
          onClick={handleFocus}
          testData={testData}>
          {icon}
        </button>
      )}
    </InputWrapper>
  )
}

Input.propTypes = {
  className: PT.string,
  testData: PT.string,
  disabled: PT.bool,
  error: PT.bool,
  errorMessage: PT.string,
  helpText: PT.string,
  icon: PT.node,
  iconPosition: PT.oneOf(['start', 'end']),
  id: PT.string.isRequired,
  label: PT.string,
  name: PT.string.isRequired,
  onBlur: PT.func,
  onChange: PT.func,
  onKeyPress: PT.func,
  onFocus: PT.func,
  placeholder: PT.string,
  required: PT.bool,
  type: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
  isCurrencyInput: PT.bool,
  prefix: PT.string,
  decimalScale: PT.number,
  isTableInput: PT.bool,
}

Input.defaultProps = {
  className: '',
  disabled: false,
  error: false,
  errorMessage: '',
  helpText: '',
  icon: null,
  iconPosition: 'end',
  label: '',
  onBlur: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  onFocus: undefined,
  placeholder: '',
  required: false,
  type: 'text',
  value: '',
  prefix: '',
  decimalScale: 2,
  isTableInput: null,
}

export default Input

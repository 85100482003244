import React from 'react'
import PT from 'prop-types'
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

export const tooltipTypes = {
  GREY: 'grey',
  LIGHT: 'light',
}
const tooltipStyles = {
  [tooltipTypes.GREY]: {
    color: '#111827',
    fontSize: '0.825rem',
    backgroundColor: '#FFF9F8',
    maxWidth: 330,
    border: '1px solid #FBAB9B',
  },
  [tooltipTypes.LIGHT]: {
    color: '#111827',
    fontSize: '0.825rem',
    backgroundColor: '#FFFFFF',
    maxWidth: 330,
    padding: 0,
    boxShadow:
      '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
  },
}

export const HtmlTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ type }) => ({
  [`& .${tooltipClasses.tooltip}`]: tooltipStyles[type],
}))

const Tooltip = ({ id, disabled, children, content, placement, className, type, isOpen }) => {
  if (disabled || !content) {
    return children
  }

  return (
    <HtmlTooltip id={id} open={isOpen} placement={placement} title={content} type={type}>
      <div className={className}>{children}</div>
    </HtmlTooltip>
  )
}

Tooltip.propTypes = {
  children: PT.element.isRequired,
  content: PT.node,
  disabled: PT.bool,
  id: PT.oneOfType([PT.string, PT.number]),
  placement: PT.string,
  className: PT.string,
  type: PT.string,
  isOpen: PT.bool,
}

Tooltip.defaultProps = {
  content: null,
  disabled: false,
  id: null,
  placement: 'left',
  className: '',
  type: tooltipTypes.GREY,
}

export default Tooltip

import React from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Text } from '../../../ui-kit'
import fontWeight from '../../fontWeight'
import sizes from '../../sizes'
import { useTranslation } from 'react-i18next'

const EmptyResultBody = ({
  error,
  search,
  filters,
  errorTitleKey,
  onReloadClick,
  reloadButtonKey,
  noRecordsKey,
  noMatchesKey,
  checkSearchKey,
  checkFiltersKey,
}) => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" className="h-full w-full" justifyContent="center" column>
      {error ? (
        <>
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
            {t(errorTitleKey)}
          </Text>
          <Button
            iconName="refresh"
            label={t(reloadButtonKey)}
            onClick={onReloadClick}
            testData="refresh-results"
          />
        </>
      ) : !search && !filters?.length ? (
        <>
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
            {t(noRecordsKey)}
          </Text>
        </>
      ) : (
        <>
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
            {t(noMatchesKey)}
          </Text>
          {!!search && <Text fontWeight={fontWeight.MEDIUM}>{t(checkSearchKey)}</Text>}
          {!!filters?.length && <Text fontWeight={fontWeight.MEDIUM}>{t(checkFiltersKey)}</Text>}
        </>
      )}
    </Flex>
  )
}

EmptyResultBody.propTypes = {
  error: PropTypes.any,
  search: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.any),
  noRecordsKey: PropTypes.string,
  noMatchesKey: PropTypes.string,
  errorTitleKey: PropTypes.string,
  onReloadClick: PropTypes.func,
  checkSearchKey: PropTypes.string,
  checkFiltersKey: PropTypes.string,
  reloadButtonKey: PropTypes.string,
}

EmptyResultBody.defaultProps = {
  error: null,
  search: '',
  filters: [],
  noRecordsKey: 'noRecordsFound',
  noMatchesKey: 'noExactMatchesFound',
  errorTitleKey: 'dataFetchFailed',
  onReloadClick: null,
  checkSearchKey: 'checkSearchForTypos',
  checkFiltersKey: 'considerChangingCurrentFilters',
  reloadButtonKey: 'reload',
}

export default EmptyResultBody

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '../../hooks/useQueryParams'
import { InvoicesPaidQuery } from '../../queries/invoices/invoicesPaid.gql'
import {
  getModelsByIds,
  getPaginationData,
  getTableParams,
  parseFilterQuery,
} from '../../utils/utils'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import PT from 'prop-types'
import {
  getCommonColumns,
  getInvoiceBreadcrumbs,
  getLoadingFunc,
  scopeFilterMap,
} from './invoicesUtils'
import InvoiceRefundModal from './invoiceRefund/InvoiceRefundModal'
import MenuButton from '../../components/dataGrid/menuButton/MenuButton'
import SidebarPaidInvoicesContent from './sidebar/SidebarPaidInvoicesContent'
import { Flex, Icon, StatusBadge, Text, Tooltip } from '../../ui-kit'
import colors from '../../ui-kit/colors'
import { getPaymentMethodTitle } from '../payments/paymentsTab/util'
import { refundLogo } from '../../ui-kit/assets'
import { chargebackLogo } from '../../ui-kit/assets'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import { useOpenEntitySidebar } from '../../hooks/useOpenEntitySidebar'
import AssignToProjectModal from './assignToProject/AssignToProjectModal'
import { SendInvoiceMutation } from '../../queries/mutations/sendInvoices.gql'
import { useNotifications } from '../../hooks/useNotifications'
import PaidInvoiceSelected from './PaidInvoiceSelected'
import { ContractsAutocompleteQuery } from '../../queries/contracts.gql'
import { ProjectsAutocompleteQuery } from '../../queries/projects.gql'
import { InvoicesAvailableFilters } from '../../queries/invoices/invoicesOutstanding.gql'
import { camelCase } from 'lodash'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import sizes from '../../ui-kit/sizes'

const InvoicesPaid = ({ contractData, contractId, scope, isAllContracts, refetchContract }) => {
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [isRefundModalOpened, setIsRefundModalOpened] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState({})
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)

  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const { sort, page, search } = getTableParams(queryParams)

  const { setBreadcrumbs } = useBreadcrumbs()
  const { newNotification } = useNotifications()
  const [availableFilters, setAvailableFilters] = useState(null)
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)

  useEffect(() => {
    setBreadcrumbs([...getInvoiceBreadcrumbs(contractData, t, scope)])
  }, [scope, contractData])

  const filters = [scopeFilterMap[scope]]

  if (contractId) {
    filters.push({
      key: 'contract_id',
      values: [contractId],
    })
  }

  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(
    () => ({
      filters: [...filters, ...userFilters],
      sort,
      page,
      search,
      perPage: pageSize,
    }),
    [filters, userFilters, sort, page, search, pageSize],
  )

  const { data, loading, refetch } = useCustomQuery({
    query: InvoicesPaidQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !scope,
    },
    rollbarOptions: { operationName: 'InvoicesPaidQuery', target: 'InvoicesPaid' },
  })
  const onCompleted = useCallback((response) => {
    response?.invoices?.availableFilters && setAvailableFilters(response.invoices.availableFilters)
  }, [])
  useCustomQuery({
    query: InvoicesAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'InvoicesAvailableFilters', target: 'InvoicesPaid' },
  })

  useEffect(() => {
    return function cleanup() {
      setAvailableFilters(null)
    }
  }, [])

  const invoices = data?.invoices
  const rows = invoices?.data || []
  const paginationData = getPaginationData(invoices)
  const sideBarHandler = (values) => {
    setSelectedInvoice(values?.row)
    setIsSidebarOpened(true)
  }
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'InvoicesPaid' },
  })

  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const { refetch: refetchProjectsLoadingData } = useCustomQuery({
    query: ProjectsAutocompleteQuery,
    rollbarOptions: { operationName: 'ProjectsAutocompleteQuery', target: 'InvoicesPaid' },
  })

  const loadProjectOptions = getLoadingFunc(
    refetchProjectsLoadingData,
    'projectsAutocomplete',
    null,
    {
      contractId,
    },
  )

  const columns = useMemo(
    () => [
      ...getCommonColumns(t, isAllContracts, sideBarHandler, additionalFilters, {
        loadContractOptions,
        loadProjectOptions,
      }),
      {
        field: 'paymentDate',
        filterId: 'paymentDate',
        filterTitle: t('paidOn'),
        headerName: t('paidOn'),
        renderCell: (values) => (
          <FormattedDate date={values?.row?.paidWith?.paymentTransaction?.entryDate} />
        ),
        flex: 10,
      },
      {
        field: 'paymentMethod',
        headerName: t('paymentMethod'),
        renderCell: (values) => <span>{getPaymentMethodTitle(values?.row?.paidWith, t)}</span>,
        flex: 15,
      },
      {
        field: 'status',
        headerName: t('status'),
        renderCell: () => <StatusBadge color={colors.GREEN} label={t('paid')} />,
        flex: 7.5,
      },
      {
        field: 'refundIcon',
        headerName: '',
        sortable: false,
        renderCell: (values) => {
          if (values.row.refundsPresent && values.row.chargebacksPresent) {
            const aggregatedContent = (
              <Flex column>
                <Text className="pr-1" size={sizes.SM}>
                  {t('refunded')}
                </Text>
                <Text className="pr-1" size={sizes.SM}>
                  {t('chargeback')}
                </Text>
              </Flex>
            )
            return (
              <Tooltip content={aggregatedContent}>
                <div className="relative">
                  <img alt="Suppli" className="h-8" src={refundLogo} />
                  <Icon
                    className="absolute top-[-3px] right-[-6px]"
                    color={colors.PRIMARY}
                    name="plusCircle"
                    size={sizes.XS}
                    type="solid"
                  />
                </div>
              </Tooltip>
            )
          }
          if (values.row.refundsPresent)
            return (
              <div>
                <Tooltip content={t('refunded')}>
                  <img alt="Suppli" className="h-8" src={refundLogo} />
                </Tooltip>
              </div>
            )
          if (values.row.chargebacksPresent)
            return (
              <div>
                <Tooltip content={t('chargeback')}>
                  <img alt="Suppli" className="h-8" src={chargebackLogo} />
                </Tooltip>
              </div>
            )
        },
        flex: 2.5,
      },
      {
        field: 'actions',
        sortable: false,
        headerName: '',
        renderCell: (values) => {
          return <MenuButton options={options} values={values} />
        },
        flex: 2.5,
      },
    ],
    [],
  )

  const setSelectionModel = (ids) => {
    const selected = getModelsByIds(rows, ids)
    setSelectedInvoices(selected)
  }

  const handleSelectionModelChange = useCallback(
    (models) => {
      setSelectionModel(models)
    },
    [rows],
  )

  useMemo(() => {
    setSelectionModel([])
  }, [contractId, scope, sort, page, search])

  const refundModalHandler = (values) => {
    setSelectedInvoice(values?.row)
    setIsRefundModalOpened(true)
    isSidebarOpened && setIsSidebarOpened(false)
  }
  const [assignToProjectModalOpened, setAssignToProjectModalOpened] = useState(false)
  const selectedInvoicesContract = contractId || selectedInvoices[0]?.contract?.id
  const assignToProjectHandler = (values) => {
    setSelectedInvoices([values.row])
    setSelectedInvoice(values.row)
    setAssignToProjectModalOpened(true)
  }
  const updateInvoices = () => {
    refetch()
    refetchContract()
    setSelectedInvoices([])
  }
  const reloadInvoices = () => {
    updateInvoices()
  }
  const handleSubmitAssignToProject = () => {
    setAssignToProjectModalOpened(false)
    reloadInvoices()
  }

  const [sendInvoices, { loading: isSendInvoicesLoading }] = useCustomMutation({
    mutation: SendInvoiceMutation,
    rollbarOptions: { operationName: 'SendInvoiceMutation', target: 'InvoicesPaid' },
  })
  const downloadHandler = (values) => {
    if (isSendInvoicesLoading) {
      return
    }

    const invoiceIds = values?.row ? [values?.row.id] : selectedInvoices.map((inv) => inv.id)
    const variables = { invoiceIds }
    sendInvoices({ variables }).then(({ data }) => {
      const responseData = data?.sendInvoices || {}

      if (responseData?.entity) {
        newNotification({ success: t('selectedInvoicesSentByEmail') })
      }
    })
  }

  const options = [
    { label: t('viewDetails'), action: sideBarHandler },
    { label: t('download'), action: downloadHandler },
    { label: t('issueRefund'), action: refundModalHandler },
    {
      label: t('assignToProject'),
      action: assignToProjectHandler,
    },
  ]

  const reopenSidebar = useCallback(() => {
    !!Object.keys(selectedInvoice).length && setIsSidebarOpened(true)
  }, [selectedInvoice, setIsSidebarOpened])

  const selectedContractId = useMemo(
    () => selectedInvoice?.contract?.id || contractId,
    [selectedInvoice, contractId],
  )

  useOpenEntitySidebar(rows, setSelectedInvoice, setIsSidebarOpened)

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  return (
    <>
      <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
        <SidebarPaidInvoicesContent
          contractId={selectedContractId}
          data={selectedInvoice}
          setAssignProjectModalOpened={setAssignToProjectModalOpened}
          setIsRefundModalOpened={setIsRefundModalOpened}
          setIsSidebarOpened={setIsSidebarOpened}
          setSelectedInvoices={setSelectedInvoices}
        />
      </Sidebar>

      {setIsRefundModalOpened && (
        <InvoiceRefundModal
          invoiceId={selectedInvoice?.id}
          isOpened={isRefundModalOpened}
          reopenSidebar={reopenSidebar}
          setIsOpened={setIsRefundModalOpened}
        />
      )}

      <div className={cx('flex pt-4', !!selectedInvoices.length && 'pb-12')}>
        <DataGridComponent
          availableFilters={availableFilters}
          columns={columns}
          handleSelectionModelChange={handleSelectionModelChange}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rows={rows}
          searchLabel={t('invoices')}
          selectionModel={selectedInvoices.map((inv) => inv.id)}
          sortModel={defaultSortModel}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
      <PaidInvoiceSelected
        models={selectedInvoices}
        onDownloadAction={downloadHandler}
        showPanel={!!selectedInvoices.length}
      />

      {assignToProjectModalOpened && (
        <AssignToProjectModal
          contractId={selectedInvoicesContract}
          handleSubmit={handleSubmitAssignToProject}
          invoiceId={(selectedInvoices.length && selectedInvoices[0].id) || selectedInvoice?.id}
          isOpened={assignToProjectModalOpened}
          reopenSidebar={reopenSidebar}
          setIsOpened={setAssignToProjectModalOpened}
        />
      )}
    </>
  )
}

InvoicesPaid.propTypes = {
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  contractId: PT.string,
  isAllContracts: PT.bool,
  scope: PT.string.isRequired,
  refetchContract: PT.func,
}
InvoicesPaid.defaultProps = {
  contractData: {},
  contractId: '',
  isAllContracts: false,
  refetchContract: () => {},
}

export default InvoicesPaid

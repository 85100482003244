export const getInvoiceBreadcrumbs = (contractData, t, scope) => [
  { label: t('customers'), link: '/customers' },
  { label: contractData?.buyer?.name, link: `/customers/${contractData.id}` },
  { label: t('projects'), link: `/customers/${contractData.id}/projects` },
  { label: t(scope) },
]

export const projectsStatusMapping = {
  active: { label: 'active', color: 'green' },
  inactive: { label: 'inactive', color: 'orange' },
}

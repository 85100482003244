import React, { useCallback } from 'react'
import PT from 'prop-types'
import { VendorUsersQuery } from '../../../../queries/vendors.gql'
import { AssignedRepsQuery } from '../../../../queries/assignedReps.gql'
import { CreateAssignedRep } from '../../../../queries/mutations/assignedRep.gql'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Button, LoadingSpinner, Dialog, Flex } from '../../../../ui-kit'
import { validateRequiredField } from '../../../../utils/validators'
import NDropdown from '../../../../ui-kit/components/dropdown/NDropdown'
import { ASSIGNED_REPS_ROLES } from './assignedRepsRoles'
import { filter, includes, map } from 'lodash'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { useBeforeUnload } from '../../../../hooks/useBeforeUnload'
import { useDirtyFormAlert } from '../../../../hooks/useDirtyFormAlert'
import AlertModal from '../../../../ui-kit/components/alertModal/AlertModal'
import { useCustomQuery } from '../../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

const AddNewAssignedRepsModal = ({ assignedReps, contractData, isOpened, setIsOpened }) => {
  const { t } = useTranslation()

  const vendorUsersVariables = {
    id: contractData.vendor.id,
  }

  const { data: vendorUsersData, loading: vendorUsersLoading } = useCustomQuery({
    query: VendorUsersQuery,
    queryOptions: {
      variables: vendorUsersVariables,
    },
    rollbarOptions: { operationName: 'VendorUsersQuery', target: 'AddNewAssignedRepsModal' },
  })

  const onCompleted = useCallback(() => {
    setIsOpened(false)
  }, [])
  const [createAssignedRep, { loading }] = useCustomMutation({
    mutation: CreateAssignedRep,
    onCompleted,
    rollbarOptions: { operationName: 'CreateAssignedRep', target: 'AddNewAssignedRepsModal' },
    mutationOptions: {
      refetchQueries: [AssignedRepsQuery],
    },
  })

  const initialValues = {
    vendorUserId: '',
    role: '',
  }

  const onSubmit = (values) => {
    const variables = {
      data: {
        contractId: contractData.id,
        ...values,
      },
    }

    createAssignedRep({ variables })
  }

  const userOptions = () => {
    const users = vendorUsersData.vendor.vendorUsers
    const assignedRepsIds = map(assignedReps, 'user.id')

    const vendorUsersMapping = users.map(({ id, fullName }) => ({
      label: fullName,
      value: id,
    }))

    return filter(vendorUsersMapping, (vendorUser) => !includes(assignedRepsIds, vendorUser.value))
  }

  const closeForm = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const renderForm = () => (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col mt-10 w-96" onSubmit={handleSubmit}>
            <div className="w-full">
              <Field name="vendorUserId">
                {({ input }) => {
                  return (
                    <NDropdown
                      id={input.name}
                      label={t('user')}
                      name={input.name}
                      onChange={input.onChange}
                      options={userOptions()}
                      placeholder={t('selectUser')}
                      testData="selectUser"
                      value={input.value}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="role">
                {({ input }) => {
                  return (
                    <NDropdown
                      id={input.name}
                      label={t('role')}
                      name={input.name}
                      onChange={input.onChange}
                      options={ASSIGNED_REPS_ROLES}
                      placeholder={t('selectRole')}
                      testData="selectRole"
                      value={input.value}
                    />
                  )
                }}
              </Field>
            </div>

            <Flex className="mt-4" justifyContent="end">
              <Button
                className="mr-4"
                label={t('cancel')}
                onClick={requestClose}
                testData="cancel-new-assigned-reps"
                variant={buttonsVariants.TERTIARY}
              />
              <Button
                disabled={loading}
                label={t('save')}
                testData="add-new-reps-save"
                type="submit"
              />
            </Flex>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => ({
        vendorUserId: validateRequiredField(values.vendorUserId),
        role: validateRequiredField(values.role),
      })}
    />
  )

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('addNewAssignedRep')}
      titleClass="text-2xl font-semibold">
      {vendorUsersLoading ? (
        <LoadingSpinner className="w-full" loading={vendorUsersLoading} />
      ) : (
        renderForm()
      )}
    </Dialog>
  )
}

AddNewAssignedRepsModal.propTypes = {
  assignedReps: PT.arrayOf(
    PT.shape({
      id: PT.string,
      role: PT.string,
      user: PT.shape({
        id: PT.string,
        fullName: PT.string,
      }),
    }),
  ),
  contractData: PT.shape({
    id: PT.string,
    vendor: PT.shape({
      id: PT.string.isRequired,
    }),
  }).isRequired,
  isOpened: PT.bool.isRequired,
  setIsOpened: PT.func.isRequired,
}

export default AddNewAssignedRepsModal

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import Accordion from '../../../ui-kit/components/accordion/Accordion'
import { paymentsReportsListMap, paymentsReports } from './paymentsReportsList'
import { downloadReport } from '../logic/reportsUtils'
import CustomerPayments from './CustomerPayments'
import PaymentPlans from './PaymentPlans'
import PromiseToPays from './PromiseToPays'
import Refunds from './Refunds'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { parseFilterQuery } from '../../../utils/utils'
import { useRollbar } from '@rollbar/react'
import { toInteger } from 'lodash'
import Authorizations from './Authorizations'

const PaymentsReports = ({ authToken, createDetails, createSummary }) => {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const { queryParams, removeQueryParam } = useQueryParams()
  const page = toInteger(queryParams.page) || 1
  const sort = useMemo(() => queryParams.sort || '', [queryParams])
  const filtersQuery = useMemo(() => queryParams.filters || null, [queryParams])
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const [isExpanded, setIsExpanded] = useState(false)
  const [openedReport, setOpenedReport] = useState('')
  const [reportData, setReportData] = useState(null)
  const summaryProps = useMemo(
    () => ({
      label: t('paymentsReports'),
      iconName: 'currencyDollar',
      isExpanded: isExpanded,
    }),
    [isExpanded, t],
  )
  const getReportData = useCallback(
    async (report) => {
      let params = {}

      params.pagination_data = { page, per_page: 100 }

      if (userFilters.length) {
        params.filters = userFilters.reduce((acc, filter) => {
          acc[filter.key] = filter.values
          return acc
        }, {})
      }

      if (report?.defaultFilters) {
        params.filters = { ...params.filters, ...report.defaultFilters }
      }

      if (sort) {
        params.sort = sort
      }

      const data = await downloadReport(report?.jsonUrl, authToken, params, rollbar)
      setReportData(data)
    },
    [setReportData, setOpenedReport, authToken, userFilters, rollbar, sort, page],
  )
  const detailsProps = useMemo(
    () => ({
      reportsMetadata: paymentsReports,
      setOpenedReport,
    }),
    [setOpenedReport],
  )
  const closeReport = useCallback(() => {
    setOpenedReport('')
    setReportData(null)
    removeQueryParam(['filters', 'sort', 'page'])
  }, [setOpenedReport, setReportData])

  useEffect(async () => {
    if (openedReport) {
      const report = paymentsReports[openedReport]
      await getReportData(report)
    }
  }, [openedReport, userFilters, sort, page])

  return (
    <>
      <Accordion
        createDetails={createDetails}
        createSummary={createSummary}
        detailsClassName="divide-y ml-24 mr-8"
        detailsProps={detailsProps}
        id="payments-reports"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        summaryProps={summaryProps}
        wrapperClassName={cx('mt-8', {
          'border border-orange-700': isExpanded,
        })}
      />
      <CustomerPayments
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === paymentsReportsListMap.CUSTOMER_PAYMENTS}
        page={page}
        reportMetadata={paymentsReports[paymentsReportsListMap.CUSTOMER_PAYMENTS]}
        userFilters={userFilters}
      />
      <Authorizations
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === paymentsReportsListMap.AUTHORIZATION}
        page={page}
        reportMetadata={paymentsReports[paymentsReportsListMap.AUTHORIZATION]}
        userFilters={userFilters}
      />
      <PromiseToPays
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === paymentsReportsListMap.PROMISE_TO_PAYS}
        page={page}
        reportMetadata={paymentsReports[paymentsReportsListMap.PROMISE_TO_PAYS]}
        userFilters={userFilters}
      />
      <PaymentPlans
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === paymentsReportsListMap.PAYMENT_PLANS}
        page={page}
        reportMetadata={paymentsReports[paymentsReportsListMap.PAYMENT_PLANS]}
        userFilters={userFilters}
      />
      <Refunds
        authToken={authToken}
        closeReport={closeReport}
        data={reportData}
        isOpened={openedReport === paymentsReportsListMap.REFUNDS}
        page={page}
        reportMetadata={paymentsReports[paymentsReportsListMap.REFUNDS]}
        userFilters={userFilters}
      />
    </>
  )
}

PaymentsReports.propTypes = {
  authToken: PT.string.isRequired,
  createDetails: PT.func.isRequired,
  createSummary: PT.func.isRequired,
}

export default PaymentsReports

import { applyFilters } from './applyFilters'

export const filterChange = (
  value,
  key,
  isApplyOnBlur,
  selectedFilter,
  setSelectedFilters,
  setQueryParams,
  refs,
) => {
  if (!key || !selectedFilter) {
    return
  }

  const copyFilters = { ...selectedFilter }

  if (key.indexOf('/') > -1) {
    const [keyName, index] = key.split('/')
    const filterCurrentValues = copyFilters[keyName] || [null, null]
    filterCurrentValues[index] = value
    copyFilters[keyName] = filterCurrentValues
  } else {
    if (!value.length) {
      delete copyFilters[key]
    } else {
      copyFilters[key] = value
    }
  }

  setSelectedFilters?.(copyFilters)

  if (!isApplyOnBlur) {
    applyFilters(copyFilters, setQueryParams, refs)
  }
}

import React, { useMemo } from 'react'
import { Flex, PreviewBlock } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { AllInvoicesStatQuery } from '../../../queries/invoices/allInvoicesStat.gql'
import { get } from 'lodash'
import { getAllInvoicesStatQueryParams, getInvoicesLargestPastDueColumns } from './utils'
import { useCustomQuery } from '../../../hooks/useCustomQuery'

const InvoicesLargestPastDue = () => {
  const { t } = useTranslation()

  const sort = 'outstanding_amount_cents.desc'
  const queryVariables = useMemo(() => getAllInvoicesStatQueryParams(sort), [sort])
  const { data, loading } = useCustomQuery({
    query: AllInvoicesStatQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'AllInvoicesStatQuery', target: 'InvoicesLargestPastDue' },
  })

  const contracts = useMemo(() => get(data, 'contracts'), [data])
  const rows = useMemo(() => get(contracts, 'data', []), [contracts])
  const columns = useMemo(() => getInvoicesLargestPastDueColumns(t), [t])

  return (
    <PreviewBlock
      loading={loading}
      title={t('largestPastDue')}
      viewMoreLink={`/customers?sort=${sort}`}>
      <Flex>
        <DataGridComponent
          columns={columns}
          headerHeight={44}
          pageSize={11}
          rows={rows}
          wrapperClassNames="pt-3.5"
          hideFooter
        />
      </Flex>
    </PreviewBlock>
  )
}

export default InvoicesLargestPastDue

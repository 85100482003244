import React, { useCallback, useMemo, useState } from 'react'
import { Button, Flex, Text } from '../../ui-kit'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PasswordFields from '../../components/security/PasswordFields'
import { validateEqualFields, validateRequiredField } from '../../utils/validators'
import { useQueryParams } from '../../hooks/useQueryParams'
import { setNewMerchantPassword } from './logic/logic'
import { useRollbar } from '@rollbar/react'

const SetNewPassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const rollbar = useRollbar()
  const { queryParams } = useQueryParams()

  const onSubmit = useCallback(
    async (values) =>
      await setNewMerchantPassword(
        values,
        queryParams?.reset_password_token,
        t,
        dispatch,
        navigate,
        rollbar,
      ),
    [dispatch, navigate, queryParams, t, rollbar],
  )

  const initialValues = useMemo(
    () => ({
      password: '',
      passwordConfirmation: '',
    }),
    [],
  )
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false)

  return (
    <Flex
      alignItems="center"
      className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
      justifyContent="center">
      <Flex className="w-sm bg-white shadow rounded-md overflow-hidden p-8" column>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => {
            return (
              <form className={'flex flex-col flex-1 px-4 pt-6 text-left'} onSubmit={handleSubmit}>
                <Flex className="flex-1" column>
                  <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                    {t('createPassword')}
                  </Text>
                  <Text
                    className="pt-3"
                    color="text-black-500"
                    fontWeight={fontWeight.MEDIUM}
                    size={sizes.BASE}>
                    {t('createPasswordHint')}
                  </Text>
                  <PasswordFields
                    isPasswordConfirmationVisible={isPasswordConfirmationVisible}
                    isPasswordVisible={isPasswordVisible}
                    setIsPasswordConfirmationVisible={setIsPasswordConfirmationVisible}
                    setIsPasswordVisible={setIsPasswordVisible}
                  />
                </Flex>
                <Flex className="py-4">
                  <Button
                    label={t('createPassword')}
                    testData="create-password"
                    type="submit"
                    fullWidth
                  />
                </Flex>
              </form>
            )
          }}
          validate={(values) => ({
            password: validateRequiredField(values.password),
            passwordConfirmation:
              validateRequiredField(values.passwordConfirmation) ||
              validateEqualFields(
                values.password,
                values.passwordConfirmation,
                t('passwordMatchErrorMessage'),
              ),
          })}
        />
      </Flex>
    </Flex>
  )
}

export default SetNewPassword

import React from 'react'
import { Button, Flex, Money } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'

const PaidInvoiceSelected = ({ models, onDownloadAction, showPanel }) => {
  const { t } = useTranslation()

  const total = models.reduce((acc, item) => acc + item.outstandingAmountCents, 0)

  if (showPanel) {
    return (
      <Flex
        alignItems="center"
        className="w-full h-16 bg-white p-8 text-sm fixed bottom-0 right-0 lg:pl-56 md:pl-14"
        justifyContent="between"
        row>
        <div className="pl-8">
          <span className="bg-primary primary px-2 py-1  rounded-md">{models.length}</span>

          <span className="pl-2">{t('selected')}</span>
        </div>

        <div>
          {t('totalOutstanding')}:
          <span className="font-bold pl-2">
            <Money value={total} />
          </span>
        </div>
        <Button
          className="px-6 mr-2 w-40"
          label={t('download')}
          onClick={onDownloadAction}
          size={sizes.SM}
          testData="download"
          variant={buttonsVariants.PRIMARY}
        />
      </Flex>
    )
  }

  return null
}

PaidInvoiceSelected.propTypes = {
  models: PT.arrayOf(
    PT.shape({
      outstandingAmountCents: PT.number,
    }),
  ),
  onDownloadAction: PT.func,
  showPanel: PT.bool.isRequired,
}
PaidInvoiceSelected.defaultProps = {
  models: [],
}

export default PaidInvoiceSelected

import React from 'react'
import PT from 'prop-types'
import './CurrencyInput.scss'
import { Input } from '../../index'

const CurrencyInput = ({
  disabled,
  error,
  id,
  label,
  name,
  onChange,
  value,
  helperText,
  errorMessage,
  placeholder,
  className,
  isTableInput,
  testData,
}) => {
  return (
    <Input
      className={className}
      decimalScale={2}
      disabled={disabled}
      error={error}
      errorMessage={errorMessage}
      helpText={helperText}
      id={id}
      isTableInput={isTableInput}
      label={label}
      name={name}
      onChange={(event) => onChange(event)}
      placeholder={placeholder}
      prefix="$"
      testData={testData}
      value={value}
      isCurrencyInput
    />
  )
}

CurrencyInput.propTypes = {
  currencySymbol: PT.string,
  className: PT.string,
  disabled: PT.bool,
  errorMessage: PT.string,
  testData: PT.string,
  placeholder: PT.string,
  error: PT.bool,
  helperText: PT.string,
  id: PT.string,
  isTableInput: PT.bool,
  label: PT.string,
  name: PT.string,
  onChange: PT.func.isRequired,
  value: PT.oneOfType([PT.string, PT.number]),
}
CurrencyInput.defaultProps = {
  className: '',
  currencySymbol: '$',
  disabled: false,
  testData: undefined,
  errorMessage: undefined,
  placeholder: undefined,
  helperText: null,
  error: false,
  id: '',
  isTableInput: false,
  label: null,
  name: '',
  value: null,
}

export default CurrencyInput

import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { Flex, Input, Money, Text } from '../../../ui-kit'
import { validateRequiredField } from '../../../utils/validators'
import { useTranslation } from 'react-i18next'
import colors from '../../../ui-kit/colors'
import sizes from '../../../ui-kit/sizes'
import fontWeight from '../../../ui-kit/fontWeight'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { DepositRefund } from '../../../queries/mutations/createDepositRefund.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { InvoicesDepositsQuery } from '../../../queries/invoices/invoicesDeposits.gql'
import { paymentMethodTypes } from '../../../constants/paymentResults'
import { ContractQuery } from '../../../queries/contracts.gql'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

const DepositReturnForm = ({
  data,
  setIsOpenedModal,
  closeForm,
  isFormDirty,
  requestClose,
  setDirtyFormState,
}) => {
  const [createDepositRefund, { loading: requestInProgress }] = useCustomMutation({
    mutation: DepositRefund,
    rollbarOptions: { operationName: 'DepositRefund', target: 'DepositReturnForm' },
    mutationOptions: {
      refetchQueries: [InvoicesDepositsQuery, ContractQuery],
    },
  })
  const currentUser = useCurrentUser()
  const { newNotification } = useNotifications()

  const onSubmit = (values) => {
    const variables = {
      data: {
        ...values,
        contractId: data?.contract?.id,
        depositId: data?.id || data?.depositId,
        refundedCents: Math.round(values.refundedCents * 100),
      },
    }
    createDepositRefund({
      variables,
    }).then(({ data }) => {
      const responseData = data?.createDepositRefund || {}

      if (responseData?.entity) {
        newNotification({ success: t('refundSubmittedSuccessfully') })
        setIsOpenedModal(false)
      }
    })
  }

  const { t } = useTranslation()
  const initialValues = useMemo(
    () => ({
      refundedCents: data?.refundableAmountCents / 100 || 0,
      refundedAsPaycheck:
        data?.latestPaymentTransaction?.paymentMethod?.type ===
          paymentMethodTypes.PAYCHECK_PAYMENT_METHOD || false,
    }),
    [data],
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, values, form }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col w-[50rem] px-2" onSubmit={handleSubmit}>
            <div className="w-full">
              <Flex>
                <Text className="pr-1" color="text-black-500" size={sizes.SM}>
                  {t('id')}:
                </Text>
                <Text size={sizes.SM}>{data?.id}</Text>
              </Flex>
              <Flex className="mt-4">
                <div className="text-right flex-col w-10/12 pr-4 pt-4 align-bottom">
                  <Button
                    label={t('fullReturn')}
                    onClick={() => form.restart()}
                    testData="full-refund"
                    variant={buttonsVariants.LINK}
                  />
                </div>
                <div className="text-center flex-col w-2/12">
                  <div>{t('issueExternalPayment')}</div>
                </div>
              </Flex>
              <Flex className="w-full" column>
                <Flex alignItems="center" className="w-full py-3" gap={4}>
                  <Flex className="w-3/12" column>
                    <Text size={sizes.SM}>
                      {t(data?.latestPaymentTransaction?.paymentMethod?.type)}
                    </Text>
                    <Text color={colors.GREY} size={sizes.XS}>
                      {data?.latestPaymentTransaction?.paymentMethod?.title}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" className="w-3/12">
                    <Text className="whitespace-pre" color={colors.GREY} size={sizes.SM}>{`${t(
                      'available',
                    )}: `}</Text>
                    <Text>
                      <Money value={data?.refundableAmountCents} />
                    </Text>
                  </Flex>
                  <Flex className="w-4/12" column>
                    <Field name="refundedCents">
                      {({ input, meta }) => {
                        return (
                          <Input
                            error={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value}
                            isCurrencyInput
                          />
                        )
                      }}
                    </Field>
                  </Flex>
                  <Flex className="w-2/12" justifyContent="center">
                    <Field name="refundedAsPaycheck">
                      {({ input }) => (
                        <Toggle
                          disabled={
                            data?.latestPaymentTransaction?.paymentMethod?.type ===
                            paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
                          }
                          handleChange={input.onChange}
                          id={input.name}
                          name={input.name}
                          value={input.value}
                        />
                      )}
                    </Field>
                  </Flex>
                </Flex>

                <Flex className="w-full pt-4 mb-2 border-t border-gray-300">
                  <Flex className="w-1/2" column>
                    <Text fontWeight={fontWeight.BOLD} size={sizes.SM}>
                      {t('totalDepositReturn')}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" className="w-1/2" justifyContent="end">
                    <Text fontWeight={fontWeight.BOLD} size={sizes.XL}>
                      <Money value={values.refundedCents * 100} />
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              {values.refundedAsPaycheck && (
                <div className="w-full pt-4">
                  <Text className="pr-1" size={sizes.LG}>
                    {t('send')}
                  </Text>
                  <Text color={colors.PRIMARY} size={sizes.LG}>
                    <Money className="pr-1" value={values.refundedCents * 100} />
                  </Text>
                  <Text size={sizes.LG}>{t('finalMsg')}</Text>
                </div>
              )}
              {!currentUser.erpMetadata?.vendorSyncDepositReturns && (
                <div className="w-full pt-4">
                  <Text size={sizes.BASE}>{t('depositSyncMsg')}</Text>
                </div>
              )}

              <div className="w-full mt-6 flex flex-row justify-end">
                <Button
                  className="mr-2"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="cancel-dep-return"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  disabled={requestInProgress}
                  label={t('returnDeposit')}
                  testData="submit-dep-return"
                  type="submit"
                />
              </div>
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => ({
        refundedCents: validateRequiredField(values.refundedCents),
      })}
    />
  )
}

DepositReturnForm.propTypes = {
  data: PropTypes.any,
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  setIsOpenedModal: PropTypes.func,
  setDirtyFormState: PropTypes.func.isRequired,
}

export default DepositReturnForm

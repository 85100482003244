import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import NewPaymentMethod, { METHODS } from './NewPaymentMethod'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { paymentMethodsEligibilityEnum } from '../../constants/paymentMethodEligibilityEnum'
import { getDefaultPaymentMethod } from './PaymentType'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { PaymentMethodsQuery } from '../../queriesUpdated/queries/paymentMethod.gql'
import { Flex, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { getPaymentTypesOptions } from '../../pages/invoices/invoicesUtils'
import cs from 'classnames'
import { Field } from 'react-final-form'
import fontWeight from '../../ui-kit/fontWeight'
import DatePicker from '../../ui-kit/components/datePicker/DatePicker'
import { DateTime } from 'luxon'
import FinancialEntity from '../financialEntity/FinancialEntity'
import { paymentMethodTypes } from '../../constants/paymentResults'

const MethodBox = ({
  children,
  className,
  key,
  setPaymentMethod,
  value,
  isExternalPayment,
  testData,
  isSelected,
}) => {
  const selectedClass = isSelected ? 'border-orange-700' : ''
  const isExternalPaymentClass = isExternalPayment ? 'h-32' : 'h-20'
  return (
    <Flex
      alignItems="center"
      className={cs(
        'w-full border rounded-lg mb-5 cursor-pointer p-3',
        className,
        selectedClass,
        isExternalPaymentClass,
      )}
      justifyContent="center"
      key={key}
      onClick={isSelected ? null : () => setPaymentMethod(value, isExternalPayment)}
      testData={testData}
      column>
      {children}
    </Flex>
  )
}

MethodBox.propTypes = {
  children: PT.node.isRequired,
  key: PT.string || PT.number,
  value: PT.string,
  className: PT.string,
  testData: PT.string,
  setPaymentMethod: PT.func.isRequired,
  isSelected: PT.bool,
  isExternalPayment: PT.bool,
}
MethodBox.defaultProps = {
  className: '',
  key: '',
  isExternalPayment: false,
}

const PaymentMethodsView = ({
  selected,
  setSelected,
  contractID,
  eligibility,
  skipCreditCardWarning,
  setPaymentDate,
  financialEntitiesOptions,
}) => {
  const { t } = useTranslation()
  const { paymentSettings } = useCurrentUser()
  const [types, setTypes] = useState([])
  const [creditCardWarning, setShowCreditCardWarning] = useState(false)
  const [newSelectedMethod, setNewSelectedMethod] = useState(null)
  const [newMethodModalIsOpened, setNewMethodModalIsOpened] = useState(false)

  const { data: paymentMethodsData, refetch } = useCustomQuery({
    query: PaymentMethodsQuery,
    queryOptions: {
      variables: { contractId: contractID, eligibility, includeMetaInfo: true },
      skip: !contractID || !eligibility,
    },
    rollbarOptions: {
      target: 'ReceivePaymentModal',
      operationName: 'PaymentMethodsQuery',
    },
  })

  useEffect(() => {
    if (paymentMethodsData) {
      setTypes(getPaymentTypesOptions(paymentMethodsData, t))
    }
  }, [paymentMethodsData])

  const checkIfCreditCardSelected = (type) => {
    if (
      eligibility === paymentMethodsEligibilityEnum.PAYMENT ||
      eligibility === paymentMethodsEligibilityEnum.DEPOSIT
    ) {
      if (type === 'CreditCardPaymentMethod' && paymentSettings.creditCardFeeEnabled) {
        return setShowCreditCardWarning(true)
      }
    }

    if (eligibility === paymentMethodsEligibilityEnum.PAYMENT_PLAN) {
      if (
        type === 'CreditCardPaymentMethod' &&
        paymentSettings.creditCardFeeForPaymentPlansEnabled
      ) {
        return setShowCreditCardWarning(true)
      }
    }

    setShowCreditCardWarning(false)
  }

  useEffect(() => {
    if (selected) {
      const currentOption = getTypeById(selected.id)

      if (currentOption) {
        return
      }
    }

    const defaultOption = getDefaultPaymentMethod(types)
    defaultOption && setSelected(defaultOption)
  }, [types])

  const getTypeById = (id) => {
    const options = types.map((type) => type.options).flat()
    return options.find((option) => option.value === id)?.type
  }

  const onSelectChange = (value) => {
    if (!value) {
      return
    }

    const selectedOption = types
      .map((type) => {
        if (type.value.toString() === value) {
          return type
        } else {
          const foundedSubOption = type.options.find(
            (subOption) => subOption.value.toString() === value.toString(),
          )
          if (foundedSubOption) {
            return foundedSubOption
          }
        }
      })
      .filter((el) => !!el)

    setSelected(selectedOption?.[0])
    const type = getTypeById(value)
    checkIfCreditCardSelected(type)
  }

  useEffect(() => {
    checkIfCreditCardSelected(getTypeById(selected?.id))
  }, [selected])

  const openNewPaymentMethodModal = (method) => {
    setNewSelectedMethod(method)
    setNewMethodModalIsOpened(true)
  }

  const handleCreateMethod = () => {
    refetch()
    setNewMethodModalIsOpened(false)
    setSelected(null)
  }

  return (
    <>
      <Field name="paymentMethod">
        {({ meta }) => {
          return (
            <>
              <Flex className="w-full flex-wrap mt-2" column>
                {types[2] && (
                  <MethodBox
                    isExternalPayment={types[2].value === selected?.value}
                    isSelected={types[2].value === selected?.value}
                    setPaymentMethod={onSelectChange}
                    testData={`payment-method-${types[2].id}`}
                    value={types[2].value}>
                    <div>
                      <div className="w-full text-center mb-2">
                        <Text
                          fontWeight={
                            types[2].value === selected?.value ? fontWeight.SEMIBOLD : void 0
                          }
                          size={sizes.SM}>
                          {t('externalPaymentCheck')}
                        </Text>
                      </div>
                      <Flex
                        className={types[2].value === selected?.value ? 'w-full' : 'hidden'}
                        gap="5">
                        <Field name="paymentDate">
                          {({ input }) => {
                            return (
                              <div className="w-1/2">
                                <Text color="text-gray-700" fontWeight={fontWeight.MEDIUM}>
                                  {t('paymentDate')}
                                </Text>
                                <DatePicker
                                  className="mt-[0.3rem]"
                                  maxDate={DateTime.now().toJSDate()}
                                  onChange={setPaymentDate}
                                  placeholder={t('chooseDate')}
                                  value={input.value}
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <Field name="financialEntityId">
                          {({ input }) => {
                            return (
                              <Flex className="w-1/2" column>
                                <FinancialEntity
                                  disabled={
                                    selected?.type !== paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
                                  }
                                  options={financialEntitiesOptions}
                                  selected={input.value}
                                  selectedPaymentMethod={selected}
                                  setSelected={input.onChange}
                                  skipSettingDefaultValue
                                  withSingleOptionAutoSelection
                                />
                              </Flex>
                            )
                          }}
                        </Field>
                      </Flex>
                    </div>
                  </MethodBox>
                )}
                <Flex className="w-full" gap="5">
                  <div className="w-1/2">
                    {!!types[0]?.options?.length &&
                      types[0]?.options?.map((option) => (
                        <MethodBox
                          isSelected={option.value === selected?.value}
                          key={option.id}
                          setPaymentMethod={onSelectChange}
                          testData={`payment-method-${option.id}`}
                          value={option.value}>
                          <Text
                            align="center"
                            fontWeight={
                              option.value === selected?.value ? fontWeight.SEMIBOLD : void 0
                            }
                            size={sizes.SM}>
                            {option.title}
                          </Text>
                          {option.nickname && (
                            <Text align="center" color="text-warmBlack-400" size={sizes.SM}>
                              {option.nickname}
                            </Text>
                          )}
                        </MethodBox>
                      ))}
                    <MethodBox setPaymentMethod={openNewPaymentMethodModal} value={METHODS.CARD}>
                      <Text color="text-warmBlack-400" size={sizes.SM}>
                        + {t('addCreditCard')}
                      </Text>
                    </MethodBox>
                  </div>
                  <div className="w-1/2">
                    {!!types[1]?.options?.length &&
                      types[1]?.options?.map((option) => (
                        <MethodBox
                          isSelected={option.value === selected?.value}
                          key={option.id}
                          setPaymentMethod={onSelectChange}
                          testData={`payment-method-${option.id}`}
                          value={option.value}>
                          <Text
                            align="center"
                            fontWeight={
                              option.value === selected?.value ? fontWeight.SEMIBOLD : void 0
                            }
                            size={sizes.SM}>
                            {option.title}
                          </Text>
                          {option.nickname && (
                            <Text align="center" color="text-warmBlack-400" size={sizes.SM}>
                              {option.nickname}
                            </Text>
                          )}
                        </MethodBox>
                      ))}
                    <MethodBox setPaymentMethod={openNewPaymentMethodModal} value={METHODS.BANK}>
                      <Text color="text-warmBlack-400" size={sizes.SM}>
                        + {t('addACH')}
                      </Text>
                    </MethodBox>
                  </div>
                </Flex>
              </Flex>

              {meta.error ? <p className="pt-2 text-sm text-error">{meta.error}</p> : null}
            </>
          )
        }}
      </Field>

      {!skipCreditCardWarning && creditCardWarning && (
        <p className="mt-2 text-xs text-orange-800">{t('convenienceFeeWarn')}</p>
      )}

      <NewPaymentMethod
        contractID={contractID}
        isOpened={newMethodModalIsOpened}
        onCreateMethod={handleCreateMethod}
        selectedMethod={newSelectedMethod}
        setIsOpened={setNewMethodModalIsOpened}
        isInner
      />
    </>
  )
}

export default PaymentMethodsView

PaymentMethodsView.propTypes = {
  contractID: PT.string,
  eligibility: PT.string.isRequired,
  financialEntitiesOptions: PT.arrayOf(
    PT.shape({
      value: PT.string,
      label: PT.string,
    }),
  ),
  selected: PT.object,
  setSelected: PT.func.isRequired,
  setPaymentDate: PT.func.isRequired,
  skipCreditCardWarning: PT.bool,
}
PaymentMethodsView.defaultProps = {
  skipCreditCardWarning: false,
}

import React from 'react'
import PT from 'prop-types'
import Dialog, { DIALOG_VARIANTS } from './Dialog'
import { Button, Icon, Text } from '../../index'
import Flex from '../spacing/Flex'
import { useTranslation } from 'react-i18next'
import sizes from '../../sizes'

const WarningDialog = ({
  children,
  isOpened,
  setIsOpened,
  onModalClose,
  withCustomButtons,
  message,
  buttonLabel,
  title,
}) => {
  const { t } = useTranslation()
  const icon = (
    <Icon
      className="min-w-5 mr-1"
      color="text-darkRed-600"
      name="exclamation"
      size={sizes.LG}
      type="outline"
    />
  )

  return (
    <Dialog
      icon={icon}
      isOpened={isOpened}
      onModalClose={onModalClose}
      setIsOpened={setIsOpened}
      title={title}
      variant={DIALOG_VARIANTS.WARNING}>
      {message && (
        <Flex>
          <Text align={'center'} className="py-8 max-w-[45rem]" size={sizes.BASE}>
            {message}
          </Text>
        </Flex>
      )}
      {children}
      {!withCustomButtons && (
        <Flex className="w-full mt-6" justifyContent="end">
          <Button
            label={buttonLabel || t('goBack')}
            onClick={onModalClose}
            testData="close-dialog"
          />
        </Flex>
      )}
    </Dialog>
  )
}

WarningDialog.propTypes = {
  children: PT.node,
  isOpened: PT.bool.isRequired,
  setIsOpened: PT.func.isRequired,
  onModalClose: PT.func,
  withCustomButtons: PT.bool,
  message: PT.oneOfType([PT.string, PT.node]),
  buttonLabel: PT.string,
  title: PT.oneOfType([PT.string, PT.node]),
}
WarningDialog.defaultProps = {
  withCustomButtons: false,
  message: '',
}

export default WarningDialog

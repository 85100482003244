import React, { useState } from 'react'
import { Button, FileInput, Flex, Dialog, Text } from '../../ui-kit'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { CATEGORIES, FILE_RESOURCES } from '../../constants/fileInputResources'
import { VendorSettingsQuery } from '../../queries/vendors.gql'
import { idType } from '../../types/defaultTypes'
import { LOGO_IMAGE_TYPES } from '../../ui-kit/components/inputs/FileInput'

const LogoEditSection = ({ logo, vendorId }) => {
  const { t } = useTranslation()
  const [modalIsOpened, setModalIsOpened] = useState(false)
  return (
    <>
      <Flex column>
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.BASE}>
          {t('logo')}
        </Text>
        <Flex className={'p-4'} column>
          {logo && <img alt={'logo'} className={'mb-4 w-12'} src={logo} />}
          <Button
            label={logo ? t('change') : t('upload')}
            onClick={() => setModalIsOpened(true)}
            testData="change-logo"
            variant={buttonsVariants.LINK}
          />
        </Flex>
      </Flex>
      <Dialog
        isOpened={modalIsOpened}
        setIsOpened={setModalIsOpened}
        title={t('uploadLogo')}
        shouldCloseOnBackdropClick>
        <Flex column>
          <FileInput
            allowedFileTypes={LOGO_IMAGE_TYPES}
            category={CATEGORIES.LOGO}
            id={'logoFileUpload'}
            onFileUpload={() => setModalIsOpened(false)}
            refetchQueries={[VendorSettingsQuery]}
            resourceId={vendorId}
            resourceType={FILE_RESOURCES.VENDOR}
            withExtensionsList
          />
        </Flex>
      </Dialog>
    </>
  )
}

export default LogoEditSection

LogoEditSection.propTypes = {
  logo: PT.string,
  vendorId: idType,
}

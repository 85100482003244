import React from 'react'
import PT from 'prop-types'
import { Drawer } from '@mui/material'
import Icon from '../icons/Icon'
import colors from '../../colors'
import { useQueryParams } from '../../../hooks/useQueryParams'

const Sidebar = ({ children, isSidebarOpened, setIsSidebarOpened, clearSelectedInvoice }) => {
  const { removeQueryParam } = useQueryParams()
  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    removeQueryParam('openSidebarParams')
    setIsSidebarOpened(false)
    if (clearSelectedInvoice) {
      clearSelectedInvoice()
    }
  }

  return (
    <Drawer anchor="right" onClose={toggleDrawer()} open={isSidebarOpened}>
      <div className="w-[25rem]">
        <div className="h-full flex flex-col py-6 bg-white">
          <div className="pl-6 pr-4">
            <div className="flex justify-end">
              <button
                className="z-50 focus:outline-none"
                onClick={toggleDrawer()}
                testData="x-close-sidebar">
                <Icon className="hover:text-primary-700" color={colors.GREY} name="x" />
              </button>
            </div>
          </div>

          <div className="relative flex-1 pl-6 pr-4">{children}</div>
        </div>
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  children: PT.element,
  isSidebarOpened: PT.bool,
  setIsSidebarOpened: PT.func,
  clearSelectedInvoice: PT.func,
}

Sidebar.defaultProps = {
  children: '',
  openSidebar: false,
  onClose: () => {},
}

export default Sidebar

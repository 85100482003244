import React, { useEffect } from 'react'
import { LoadingSpinner, Section, Tabs } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { snakeCase, camelCase, isEqual } from 'lodash'
import { Navigate, Route, useLocation, useNavigate, Routes } from 'react-router-dom'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import PageHeader from '../../components/headers/PageHeader'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { membershipRoles } from './teamManagement/membershipRoles'
import CompanyInformation from './CompanyInformation'
import TeamManagement from './TeamManagement'
import PaymentSettings from './PaymentSettings'
import CustomerNotifications from './CustomerNotifications'
import ErpFiles from './ErpFiles'

const Settings = () => {
  const currentUser = useCurrentUser()
  const tabs = [
    { name: 'companyInformation', breadcrumbLabel: 'companyInformation' },
    { name: 'teamManagement', breadcrumbLabel: 'teamManagement' },
    { name: 'paymentSettings', breadcrumbLabel: 'paymentSettings' },
    { name: 'customerNotifications', breadcrumbLabel: 'notifications' },
  ]
  if (currentUser.erpMetadata?.erpExportDownloadsEnabled) {
    tabs.push({
      name: 'erpFiles',
      breadcrumbLabel: 'erpFiles',
    })
  }
  const location = useLocation()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const currentTabPage = location.pathname.split('/')[2]
  const page = currentTabPage || snakeCase(tabs[0].name)

  const tabsMap = tabs.map((tab) => ({
    label: t(tab.name),
    active: camelCase(page) === tab.name,
    link: snakeCase(tab.name),
    breadcrumbLabel: t(tab.breadcrumbLabel),
  }))

  const { setBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    const breadcrumbs = [{ label: t('settings'), link: '/settings' }]
    if (currentTabPage) {
      breadcrumbs.push({
        label: t(tabsMap.find((tab) => isEqual(tab.link, currentTabPage))?.breadcrumbLabel),
      })
    } else {
      breadcrumbs.push({ label: t('allSettings') })
    }
    setBreadcrumbs(breadcrumbs)
  }, [page])

  useEffect(() => {
    if (!currentUser?.membershipRole) {
      return
    }

    if (currentUser.membershipRole !== membershipRoles.ADMIN) {
      navigate('/dashboard')
    }
  }, [currentUser?.membershipRole])

  return (
    <div>
      <PageHeader headerTitle={t('settings')} />
      <Tabs tabs={tabsMap} />
      <Section className={'mt-6'} withTitle>
        {currentUser?.membershipRole !== membershipRoles.ADMIN && <LoadingSpinner loading />}
        {currentUser?.membershipRole === membershipRoles.ADMIN && (
          <Routes>
            <Route element={<Navigate to="company_information" replace />} path="/" />
            <Route element={<CompanyInformation />} path="/company_information" />
            <Route element={<TeamManagement />} path="/team_management" />
            <Route element={<PaymentSettings />} path="/payment_settings" />
            <Route element={<CustomerNotifications />} path="/customer_notifications" />
            {currentUser.erpMetadata?.erpExportDownloadsEnabled && (
              <Route element={<ErpFiles />} path="/erp_files/*" />
            )}
            <Route element={<Navigate to="/settings" />} path="*" />
          </Routes>
        )}
      </Section>
    </div>
  )
}

export default Settings

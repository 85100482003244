import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '../../hooks/useQueryParams'
import {
  InvoicesCreditsQuery,
  InvoicesCreditsAvailableFilters,
} from '../../queries/invoices/invoicesCredits.gql'
import {
  getCreditsTableParams,
  getModelsByIds,
  getPaginationData,
  parseFilterQuery,
} from '../../utils/utils'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import PT from 'prop-types'
import { creditStatusMapping, getInvoiceBreadcrumbs, getLoadingFunc } from './invoicesUtils'
import { Money, Tooltip } from '../../ui-kit'
import StatusBadge from '../../ui-kit/components/badges/StatusBadge'
import SidebarCreditContent from './sidebar/SidebarCreditContent'
import Button from '../../ui-kit/components/buttons/Button'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { useNavigate } from 'react-router-dom'
import { MONEY } from '../../utils/dataTypes'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import { SendCreditsMutation } from '../../queries/mutations/sendCredits.gql'
import { useNotifications } from '../../hooks/useNotifications'
import CreditsSelected from './CreditsSelected'
import MenuButton from '../../components/dataGrid/menuButton/MenuButton'
import { ContractsAutocompleteQuery } from '../../queries/contracts.gql'
import { camelCase } from 'lodash'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'

const Credits = ({ contractData, scope, isAllContracts, contractId }) => {
  const [selectedCredits, setSelectedCredits] = useState([])
  const [selectedCredit, setSelectedCredit] = useState({})
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)

  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const navigate = useNavigate()
  const { sort, page, search } = getCreditsTableParams(queryParams)

  const { setBreadcrumbs } = useBreadcrumbs()
  const { newNotification } = useNotifications()
  const [availableFilters, setAvailableFilters] = useState(null)
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  useEffect(() => {
    setBreadcrumbs([...getInvoiceBreadcrumbs(contractData, t, scope)])
  }, [scope, contractData])

  const filters = useMemo(
    () =>
      contractId
        ? [
            {
              key: 'contract_id',
              values: [contractId],
            },
          ]
        : [],
    [contractId],
  )

  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(
    () => ({
      filters: [...filters, ...userFilters],
      sort,
      page,
      search,
      perPage: pageSize,
    }),
    [filters, userFilters, sort, page, search, pageSize],
  )

  const onCompletedRecords = useCallback(
    (response) => {
      if (selectedCredit) {
        const updatedCredit = response?.credits?.data?.find?.(
          (credit) => credit.id === selectedCredit.id,
        )
        updatedCredit && setSelectedCredit(updatedCredit)
      }
    },
    [selectedCredit],
  )

  const { data, loading } = useCustomQuery({
    query: InvoicesCreditsQuery,
    queryOptions: {
      variables: queryVariables,
    },
    onCompleted: onCompletedRecords,
    rollbarOptions: { operationName: 'InvoicesCreditsQuery', target: 'Credits' },
  })
  const onCompleted = useCallback((response) => {
    response?.credits?.availableFilters && setAvailableFilters(response.credits.availableFilters)
  }, [])
  useCustomQuery({
    query: InvoicesCreditsAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'InvoicesCreditsAvailableFilters', target: 'Credits' },
  })

  useEffect(() => {
    return function cleanup() {
      setAvailableFilters(null)
    }
  }, [])

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'Credits' },
  })

  const loadOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const credits = data?.credits

  const rows = credits?.data || []
  const paginationData = getPaginationData(credits)
  const sideBarHandler = (values) => {
    setSelectedCredit(values?.row)
    setIsSidebarOpened(true)
  }

  const columns = useMemo(
    () => [
      {
        field: 'issueDate',
        filterId: 'issueDate',
        headerName: t('date'),
        renderCell: (values) => <FormattedDate date={values?.row?.issueDate} />,
        flex: 17.5,
      },
      {
        field: 'referenceId',
        filterId: additionalFilters.includes('id') && 'id',
        headerName: t('id'),
        renderCell: (values) => (
          <Button
            label={values?.row?.referenceId}
            onClick={() => sideBarHandler(values)}
            size={sizes.SM}
            testData="credits-detail"
            variant={buttonsVariants.LINK}
          />
        ),
        flex: 25,
      },
      {
        field: 'customer',
        filterId: isAllContracts && 'contractId',
        headerName: t('customer'),
        renderCell: (values) => values?.row?.contract?.buyer?.name,
        flex: 20,
        hide: !isAllContracts,
        loadOptions: loadOptions,
      },
      {
        field: 'project',
        headerName: t('project'),
        flex: 25,
        renderCell: (values) => (
          <Tooltip content={values?.row?.project?.name || '-'}>
            <span className={'text-ellipsis overflow-hidden'}>
              {values?.row?.project?.name || '-'}
            </span>
          </Tooltip>
        ),
      },
      {
        field: 'locationName',
        headerName: t('Location'),
        filterId: 'locationName',
        filterTitle: t('creditLocation'),
        flex: 25,
        renderCell: (values) => (
          <span className={'text-ellipsis overflow-hidden'}>
            {values?.row?.location?.name || '-'}
          </span>
        ),
      },
      {
        field: 'amountCents',
        filterId: 'amountCents',
        filterDataType: MONEY,
        headerName: t('amount'),
        renderCell: (values) => <Money value={values?.row?.amountCents} />,
        flex: 17.5,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'outstandingAmountCents',
        filterId: 'outstandingAmount',
        filterDataType: MONEY,
        headerName: t('outstanding'),
        renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
        flex: 17.5,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'balanceStatus',
        filterId: 'balanceStatus',
        headerName: t('status'),
        renderCell: (values) => (
          <StatusBadge
            color={creditStatusMapping[values?.row?.balanceStatus]?.color}
            value={t(creditStatusMapping[values?.row?.balanceStatus]?.label)}
          />
        ),
        flex: 17.5,
      },
      {
        field: 'actions',
        sortable: false,
        headerName: '',
        renderCell: (values) => {
          return <MenuButton options={options} values={values} />
        },
        flex: 2.5,
      },
    ],
    [isAllContracts, navigate, additionalFilters],
  )

  const setSelectionModel = (ids) => {
    const selected = getModelsByIds(rows, ids)
    setSelectedCredits(selected)
  }

  const handleSelectionModelChange = useCallback(
    (models) => {
      setSelectionModel(models)
    },
    [rows],
  )

  useMemo(() => {
    setSelectionModel([])
  }, [contractId, scope, sort, page, search])

  const [sendCredits, { loading: isSendCreditsLoading }] = useCustomMutation({
    mutation: SendCreditsMutation,
    rollbarOptions: { operationName: 'SendCreditsMutation', target: 'Credits' },
  })
  const downloadHandler = (values) => {
    if (isSendCreditsLoading) {
      return
    }

    const creditIds = values?.row ? [values?.row.id] : selectedCredits.map((cred) => cred.id)
    const variables = { creditIds }
    sendCredits({ variables }).then(({ data }) => {
      const responseData = data?.sendCredits || {}

      if (responseData?.entity) {
        newNotification({ success: t('selectedCreditsSentByEmail') })
      }
    })
  }
  const options = [
    { label: t('viewDetails'), action: sideBarHandler },
    // { label: t('download'), action: downloadHandler },
  ]

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  return (
    <>
      <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
        <SidebarCreditContent data={selectedCredit} />
      </Sidebar>

      <div className={cx('flex pt-4', !!selectedCredits.length && 'pb-12')}>
        <DataGridComponent
          availableFilters={availableFilters}
          columns={columns}
          handleSelectionModelChange={handleSelectionModelChange}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rows={rows}
          searchLabel={t('credits')}
          selectionModel={selectedCredits.map((cred) => cred.id)}
          sortModel={defaultSortModel}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>

      <CreditsSelected
        models={selectedCredits}
        onDownloadAction={downloadHandler}
        showPanel={!!selectedCredits.length}
      />
    </>
  )
}

Credits.propTypes = {
  scope: PT.string.isRequired,
  contractId: PT.string.isRequired,
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  isAllContracts: PT.bool.isRequired,
}
Credits.defaultProps = {
  scope: '',
  contractId: '',
  contractData: {},
  isAllContracts: false,
}

export default Credits

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, NarrowList, Text, Tooltip } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import AccountTermsModal from '../../../pages/settings/paymentSettingsModals/AccountTermsModal'
import PT from 'prop-types'
import { idType } from '../../../types/defaultTypes'
import { SUPPLI_SUPPORT_EMAIL } from '../../../constants/contacts'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

const ContractPaymentSettingsContent = ({
  data,
  loading,
  updatePaymentSettings,
  withPaymentTerms,
  isReadOnlyNonAdmin,
  isUpdatePaymentSettingsLoading,
  disabledOptions,
}) => {
  const [accountTermsModalIsOpened, setAccountTermsModalIsOpened] = useState(false)
  const { paymentSettings } = useCurrentUser()
  const handleSubmit = (values, closeFunc) => {
    const variables = {
      id: data.id,
      data: {
        earlyPaymentCreditCardDiscountEnabled: values.earlyPaymentCreditCardDiscountEnabled,
        allowPartialPayments: values.allowPartialPayments,
        creditCardFeeEnabled: values.creditCardFeeEnabled,
      },
    }

    updatePaymentSettings(variables, closeFunc)
  }

  const { t } = useTranslation()
  const handleFieldEnabled = (propName) => {
    const updatedValues = { [propName]: !data[propName], id: data.id }
    handleSubmit(updatedValues)
  }
  const handleSubmitSettingsUpdate = (values, closeFunc) => {
    const updatedValues = { ...data, ...values }
    handleSubmit(updatedValues, closeFunc)
  }

  const editPaymentTerm = () => {
    setAccountTermsModalIsOpened(true)
  }

  const renderItems = () => {
    const settingsList = []

    if (withPaymentTerms) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="paymentTerms">
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM}>{t('accountTerms')}</Text>
          </div>
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
              {data.paymentTerms}
            </Text>
          </div>
          <div className="w-1/12">
            <Button
              className="hidden"
              disabled={isReadOnlyNonAdmin || isUpdatePaymentSettingsLoading}
              iconName={'pencil'}
              label={t('edit')}
              onClick={() => editPaymentTerm()}
              size={sizes.SM}
              testData="edit-payment-term"
              variant={buttonsVariants.LINK}
            />
          </div>
        </Flex>,
      )
    }

    if (paymentSettings.creditCardFeeEnabled) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="customerPaymentTerms">
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM}>{t('creditCardFees')}</Text>
          </div>
          <div className="w-5/12 flex flex-col pt-1">
            <Text size={sizes.XS}>{t('chargeCreditFeeForThisCustomer')}</Text>
          </div>
          <div className="w-1/12" />
          <div className="w-1/12">
            <Tooltip content={isReadOnlyNonAdmin ? t('customerSettingsReadOnlyNonAdmin') : ''}>
              <Toggle
                className="pl-4 pr-5"
                disabled={isReadOnlyNonAdmin}
                handleChange={() => handleFieldEnabled('creditCardFeeEnabled')}
                testData="toggle-allow-partial-payment"
                value={data.creditCardFeeEnabled}
              />
            </Tooltip>
          </div>
        </Flex>,
      )
    }

    settingsList.push(
      <Flex alignItems="start" className="w-full" key="earlyPaymentDiscount">
        <div className="w-5/12">
          <Text fontWeight={fontWeight.MEDIUM}>{t('earlyPaymentDiscountForCardPayments')}</Text>
        </div>
        <div className="w-5/12 flex flex-col pt-1">
          <Text size={sizes.XS}>{t('allowEarlyPaymentDiscount')}</Text>
        </div>
        <div className="w-1/12" />
        <div className="w-1/12">
          <Tooltip
            content={
              isReadOnlyNonAdmin
                ? t('customerSettingsReadOnlyNonAdmin')
                : disabledOptions.includes('earlyPaymentDiscount') &&
                  t('modifySettingsHint', { email: SUPPLI_SUPPORT_EMAIL })
            }>
            <Toggle
              className="pl-4 pr-5"
              disabled={
                isReadOnlyNonAdmin ||
                isUpdatePaymentSettingsLoading ||
                disabledOptions.includes('earlyPaymentDiscount')
              }
              handleChange={() => handleFieldEnabled('earlyPaymentCreditCardDiscountEnabled')}
              testData="toggle-early-payment-discount"
              value={data.earlyPaymentCreditCardDiscountEnabled}
            />
          </Tooltip>
        </div>
      </Flex>,
    )

    settingsList.push(
      <Flex alignItems="start" className="w-full" key="customerPaymentTerms">
        <div className="w-5/12">
          <Text fontWeight={fontWeight.MEDIUM}>{t('partialPayments')}</Text>
        </div>
        <div className="w-5/12 flex flex-col pt-1">
          <Text size={sizes.XS}>{t('allowPartialPaymentsInTheCustomerPortal')}</Text>
        </div>
        <div className="w-1/12" />
        <div className="w-1/12">
          <Tooltip
            content={
              isReadOnlyNonAdmin
                ? t('customerSettingsReadOnlyNonAdmin')
                : disabledOptions.includes('allowPartialPayments') &&
                  t('modifySettingsHint', { email: SUPPLI_SUPPORT_EMAIL })
            }>
            <Toggle
              className="pl-4 pr-5"
              disabled={
                isReadOnlyNonAdmin ||
                isUpdatePaymentSettingsLoading ||
                disabledOptions.includes('allowPartialPayments')
              }
              handleChange={() => handleFieldEnabled('allowPartialPayments')}
              testData="toggle-allow-partial-payment"
              value={data.allowPartialPayments}
            />
          </Tooltip>
        </div>
      </Flex>,
    )

    return settingsList
  }

  return (
    <>
      {!loading && (
        <div className="mr-10" onSubmit={handleSubmit}>
          <NarrowList listItems={renderItems()} />
        </div>
      )}

      {!isReadOnlyNonAdmin && accountTermsModalIsOpened && (
        <AccountTermsModal
          handleSubmit={(values) => {
            handleSubmitSettingsUpdate(values, setAccountTermsModalIsOpened)
          }}
          initialAccountTerms={data.paymentTerms}
          isOpened={accountTermsModalIsOpened}
          setIsOpened={setAccountTermsModalIsOpened}
        />
      )}
    </>
  )
}

export default ContractPaymentSettingsContent
const contractPaymentSettings = PT.shape({
  earlyPaymentCreditCardDiscountEnabled: PT.bool.isRequired,
  id: idType,
  paymentTerms: PT.number.isRequired,
  allowPartialPayments: PT.bool,
  creditCardFeeEnabled: PT.bool,
})

ContractPaymentSettingsContent.propTypes = {
  data: PT.oneOfType([contractPaymentSettings]),
  loading: PT.bool.isRequired,
  updatePaymentSettings: PT.func.isRequired,
  withLienWaiver: PT.bool,
  withCreditLimit: PT.bool,
  withPaymentTerms: PT.bool,
  isReadOnlyNonAdmin: PT.bool,
  isUpdatePaymentSettingsLoading: PT.bool,
  disabledOptions: PT.arrayOf(PT.string),
}

ContractPaymentSettingsContent.defaultProps = {
  disabledOptions: [],
  isReadOnlyNonAdmin: false,
  withLienWaiver: false,
  withCreditLimit: false,
  withPaymentTerms: false,
  isUpdatePaymentSettingsLoading: false,
}

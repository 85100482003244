const FILE_RESOURCES = {
  CONTRACT: 'contract',
  USER: 'user',
  VENDOR: 'vendor',
  PROJECT: 'project',
  CREDIT_REVIEW: 'credit_review',
}

const CATEGORIES = {
  DOCUMENT: 'document',
  STATEMENT: 'statement',
  AVATAR: 'avatar',
  LOGO: 'logo',
  PROJECT: 'project',
  CREDIT_REVIEW: 'credit_review',
}

export { FILE_RESOURCES, CATEGORIES }

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Button, Flex, Icon, LoadingSpinner, Text } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { paymentResultMods } from '../../constants/paymentResults'

const PaymentResult = ({ mode, setMode, onDoneClick, onActionClick, failureReason }) => {
  const { t } = useTranslation()

  const actionCTA = useMemo(() => {
    let label = ''

    switch (mode) {
      case paymentResultMods.SERVER_ERROR:
        label = t('retry')
        break
      case paymentResultMods.CREDIT_CARD_FAILURE:
      case paymentResultMods.ACH_FAILURE:
        label = t('retryPayment')
        break
      case paymentResultMods.CARD_AUTHORIZATION_FAILURE:
        label = t('retryAuthorization')
        break
      default:
        break
    }

    if (!label) {
      return null
    }

    return (
      <Button
        className="w-48"
        label={label}
        onClick={() => {
          setMode(null)
          onActionClick?.()
        }}
        testData="retry_payment"
      />
    )
  }, [t, setMode, mode])
  const doneCTA = useMemo(() => {
    if (mode === paymentResultMods.LOADING) {
      return null
    }

    const buttonVariant =
      mode === paymentResultMods.SERVER_ERROR ||
      mode === paymentResultMods.CREDIT_CARD_FAILURE ||
      mode === paymentResultMods.ACH_FAILURE ||
      mode === paymentResultMods.CARD_AUTHORIZATION_FAILURE
        ? buttonsVariants.SECONDARY
        : buttonsVariants.PRIMARY

    return (
      <Button
        className="w-48"
        label={t('done')}
        onClick={() => onDoneClick()}
        testData="done"
        variant={buttonVariant}
      />
    )
  }, [t, mode, onDoneClick])
  const body = useMemo(() => {
    switch (mode) {
      case paymentResultMods.LOADING:
        return <LoadingSpinner className="w-full h-full" loading />
      case paymentResultMods.ACH_SUCCESS:
        return (
          <>
            <Icon
              backgroundColor="white"
              className="w-28 h-28 border border-green-450 stroke-0.3"
              color="text-green-450"
              name="fastForward"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('achPaymentPending')}
            </Text>
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('achPaymentPendingHint1')}
            </Text>
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('achPaymentPendingHint2')}
            </Text>
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('achPaymentPendingHint3')}
            </Text>
          </>
        )
      case paymentResultMods.SERVER_ERROR:
        return (
          <>
            <Icon
              backgroundColor="white"
              className="w-28 h-28 stroke-0.3"
              color="text-black-700"
              name="terminal"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('paymentServerError')}
            </Text>
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('paymentServerErrorDescription')}
            </Text>
          </>
        )
      case paymentResultMods.CREDIT_CARD_SUCCESS:
        return (
          <>
            <Icon
              backgroundColor="green"
              className="w-28 h-28 stroke-0.3"
              color="text-white"
              name="currencyDollar"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('paymentWasSuccessful')}
            </Text>
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('paymentWasSuccessfulHint1')}
            </Text>
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('paymentWasSuccessfulHint2')}
            </Text>
          </>
        )
      case paymentResultMods.PAYCHECK_CREDIT_DEPOSIT_SUCCESS:
        return (
          <>
            <Icon
              backgroundColor="green"
              className="w-28 h-28 stroke-0.3"
              color="text-white"
              name="currencyDollar"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('paymentWasSuccessful')}
            </Text>
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('paymentWasSuccessfulHint1')}
            </Text>
          </>
        )
      case paymentResultMods.CREDIT_CARD_FAILURE:
        return (
          <>
            <Icon
              backgroundColor="red"
              className="w-28 h-28 stroke-0.3"
              color="text-white"
              name="x"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('cardPaymentFailed')}
            </Text>
            {failureReason && (
              <Text align="center" className="mt-10" size={sizes.LG}>
                [{failureReason}]
              </Text>
            )}
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('cardPaymentFailedHint')}
            </Text>
          </>
        )
      case paymentResultMods.ACH_FAILURE:
        return (
          <>
            <Icon
              backgroundColor="red"
              className="w-28 h-28 stroke-0.3"
              color="text-white"
              name="x"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('achPaymentFailure')}
            </Text>
            {failureReason && (
              <Text align="center" className="mt-10" size={sizes.LG}>
                [{failureReason}]
              </Text>
            )}
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('cardPaymentFailedHint')}
            </Text>
          </>
        )
      case paymentResultMods.CARD_AUTHORIZATION_SUCCESS:
        return (
          <>
            <Icon
              className="w-28 h-28 stroke-0.3"
              color="text-primary-700"
              name="currencyDollar"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('authorizationWasSuccessful')}
            </Text>
            <Text align="center" className="mt-3.5 px-28" size={sizes.LG}>
              {t('authorizationWasSuccessfulHint1')}
            </Text>
            <Text align="center" className="mt-3.5" size={sizes.LG}>
              {t('authorizationWasSuccessfulHint2')}
            </Text>
          </>
        )
      case paymentResultMods.CARD_AUTHORIZATION_FAILURE:
        return (
          <>
            <Icon
              backgroundColor="red"
              className="w-28 h-28 stroke-0.3"
              color="text-white"
              name="x"
              type="outline"
            />
            <Text
              align="center"
              className="mt-10"
              fontWeight={fontWeight.SEMIBOLD}
              size={sizes.XL}
              testData="payment-result-title">
              {t('authorizationFailed')}
            </Text>
            {failureReason && (
              <Text align="center" className="mt-10" size={sizes.LG}>
                [{failureReason}]
              </Text>
            )}
            <Text align="center" className="mt-10" size={sizes.LG}>
              {t('authorizationFailedHint')}
            </Text>
          </>
        )
      default:
        return null
    }
  }, [mode])

  return (
    <Flex className="w-146 h-full" column>
      <Flex alignItems="center" className="flex-1" column>
        {body}
      </Flex>
      <Flex
        className={cx('w-full mt-12', actionCTA && 'px-16 px-20')}
        justifyContent={actionCTA ? 'between' : 'center'}>
        {doneCTA}
        {actionCTA}
      </Flex>
    </Flex>
  )
}

PaymentResult.propTypes = {
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func,
  failureReason: PropTypes.string.isRequired,
}

export default React.memo(PaymentResult)

import React, { useMemo } from 'react'
import AsideContainer from '../../components/containers/AsideContainer'
import { Flex, Text } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { DefaultVendorNotificationSettings } from '../../queries/vendors.gql'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import CustomerNotificationsContent from '../../components/notificationSettingsContent/CustomerNotificationsContent'

const CustomerNotifications = () => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()

  const { data, loading } = useCustomQuery({
    query: DefaultVendorNotificationSettings,
    queryOptions: {
      variables: { id: currentUser?.vendorId },
      skip: !currentUser?.vendorId,
    },
    rollbarOptions: {
      operationName: 'VendorNotificationSettings',
      target: 'CustomerNotifications',
    },
  })

  const initialValues = useMemo(() => {
    if (data?.vendor?.defaultContractNotificationSettings) {
      const notificationTypes = data?.vendor?.defaultContractNotificationSettings.notificationTypes
      const targetSetting = notificationTypes?.find(
        (setting) => setting.name === 'monthly_statements',
      )
      if (targetSetting) {
        targetSetting.statementCutoff =
          data?.vendor?.defaultContractNotificationSettings.monthlyStatementCutoff
      }
    }
    return data?.vendor?.defaultContractNotificationSettings
  }, [data?.vendor?.defaultContractNotificationSettings])

  return (
    <AsideContainer
      title={t('customerNotifications')}
      titleChild={
        <Flex column>
          <Text className="pb-2" color="text-warmBlack-400">
            {t('manageSystemNotifications1')}
          </Text>
          <Text className="pb-2" color="text-warmBlack-400">
            {t('manageSystemNotifications2')}
          </Text>
          <Text className="pb-2" color="text-warmBlack-400">
            {t('manageSystemNotifications3')}
          </Text>
        </Flex>
      }>
      <CustomerNotificationsContent
        initialValues={initialValues}
        loading={loading}
        refetchQueries={DefaultVendorNotificationSettings}
        isGlobalSettings
      />
    </AsideContainer>
  )
}

export default CustomerNotifications

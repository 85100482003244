import React, { useCallback, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getDisplayStatusValue } from '../../pages/contracts/utils'
import ColumnChart from '../../ui-kit/components/charts/ColumChart'
import { useNavigate } from 'react-router-dom'
import { InvoicesAvailableFilters } from '../../queries/invoices/invoicesOutstanding.gql'
import { applyStatusFilter } from '../filters/filterUtils'
import { useCustomQuery } from '../../hooks/useCustomQuery'

const DaysPastDueChart = ({
  className,
  overdueLevelGroups,
  valueFieldName,
  isLoading,
  redirectUrl,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [availableFilters, setAvailableFilters] = useState(null)

  const statisticData = useMemo(
    () =>
      overdueLevelGroups?.map((level) => {
        return {
          title: getDisplayStatusValue(level.overdueLevel),
          value: level[valueFieldName] || 0,
        }
      }),
    [overdueLevelGroups],
  )
  const daysPastDueTitles = statisticData?.map((el) => el.title)
  const daysPAstDueValues = statisticData?.map((el) => el.value)

  const onCompleted = useCallback((response) => {
    response?.invoices?.availableFilters && setAvailableFilters(response.invoices.availableFilters)
  }, [])
  useCustomQuery({
    query: InvoicesAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'InvoicesAvailableFilters', target: 'DaysPastDueChart' },
  })
  const onColumnClick = useCallback(
    (event) => {
      if (!event?.point) {
        return
      }

      const { category } = event.point
      applyStatusFilter('days_past_due', category, availableFilters, navigate, redirectUrl)
    },
    [navigate, availableFilters],
  )

  return (
    <ColumnChart
      chartHeight={207}
      className={className}
      columnWidth={50}
      isLoading={isLoading}
      onColumnClick={onColumnClick}
      subTitle={t('daysPastDueChartHint')}
      title={t('daysPastDue')}
      valueFieldName={valueFieldName}
      xAxisData={daysPastDueTitles}
      yAxisData={daysPAstDueValues}
      showLabels
      showXAxis
    />
  )
}

export default DaysPastDueChart

DaysPastDueChart.propTypes = {
  className: PT.string,
  isLoading: PT.bool,
  overdueLevelGroups: PT.arrayOf(
    PT.shape({
      amountCents: PT.number,
      id: PT.string,
      overdueLevel: PT.shape({
        id: PT.string,
        overdueFrom: PT.number,
        overdueTo: PT.number,
        title: PT.string,
      }),
    }),
  ),
  valueFieldName: PT.string.isRequired,
  redirectUrl: PT.string,
}

DaysPastDueChart.defaultProps = {
  className: '',
  isLoading: false,
  redirectUrl: '',
  overdueLevelGroups: undefined,
}

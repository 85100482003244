import React from 'react'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { getCompanyRole } from '../../contracts/customerInfo/contactInfoSections/CompanyContacts'
import { Money } from '../../../ui-kit'

export const getActiveUsersColumns = (t) => [
  {
    field: 'id',
    headerName: t('id'),
    flex: 1,
  },
  {
    field: 'customerName',
    headerName: t('customerName'),
    flex: 3,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.customerName}</span>
    ),
  },
  {
    field: 'contactName',
    headerName: t('contactName'),
    flex: 3,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.contactName}</span>
    ),
  },
  {
    field: 'email',
    headerName: t('email'),
    flex: 3,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.email}</span>
    ),
  },
  {
    field: 'companyRole',
    headerName: t('role'),
    renderCell: (values) => getCompanyRole(values?.row?.companyRole),
    flex: 2,
  },
  {
    field: 'lastLoginDate',
    headerName: t('lastLoginDate'),
    renderCell: (values) => (
      <FormattedDate date={values?.row?.lastLoginDate} format={'MM/dd/yyyy h:mm a'} />
    ),
    flex: 2,
  },
]

export const getActiveUsersFiltersColumns = (t) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'lastSignInAt',
    filterTitle: t('lastLoginDate'),
  },
]

export const getInactiveUsersColumns = (t) => [
  {
    field: 'id',
    headerName: t('id'),
    flex: 1,
  },
  {
    field: 'customerName',
    headerName: t('customerName'),
    flex: 3,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.customerName}</span>
    ),
  },
  {
    field: 'contactName',
    headerName: t('contactName'),
    flex: 3,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.contactName}</span>
    ),
  },
  {
    field: 'email',
    headerName: t('email'),
    flex: 3,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.email}</span>
    ),
  },
  {
    field: 'companyRole',
    headerName: t('role'),
    renderCell: (values) => getCompanyRole(values?.row?.companyRole),
    flex: 2,
  },
  {
    field: 'lastInvitationDate',
    headerName: t('lastInvitationDate'),
    renderCell: (values) => (
      <FormattedDate date={values?.row?.lastInvitationDate} format={'MM/dd/yyyy h:mm a'} />
    ),
    flex: 2,
  },
]

export const getInactiveUsersFiltersColumns = (t) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'invitationSentAt',
    filterTitle: t('lastInvitationDate'),
  },
]

export const getERPOverridesColumns = (t) => [
  {
    field: 'date',
    headerName: t('date'),
    renderCell: (values) => <FormattedDate date={values?.row?.date} />,
    flex: 5,
  },
  {
    field: 'id',
    headerName: t('overrideId'),
    flex: 5,
  },
  {
    field: 'customerName',
    headerName: t('customerName'),
    flex: 10,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.customerName}</span>
    ),
  },
  {
    field: 'contractId',
    headerName: t('customerId'),
    flex: 10,
  },
  {
    field: 'auditableType',
    headerName: t('object'),
    flex: 5,
  },
  {
    field: 'auditableReferenceId',
    headerName: t('objectId'),
    flex: 6,
  },
  {
    field: 'previousAmountCents',
    headerName: t('previousAmt'),
    renderCell: (values) => <Money value={values?.row?.previousAmountCents} />,
    flex: 5,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'newAmountCents',
    headerName: t('newAmt'),
    renderCell: (values) => <Money value={values?.row?.newAmountCents} />,
    flex: 5,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'previousOutstandingAmountCents',
    headerName: t('previousAmtOS'),
    renderCell: (values) => <Money value={values?.row?.previousOutstandingAmountCents} />,
    flex: 5,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'newOutstandingAmountCents',
    headerName: t('newAmtOS'),
    renderCell: (values) => <Money value={values?.row?.newOutstandingAmountCents} />,
    flex: 5,
    align: 'right',
    headerAlign: 'right',
  },
  // {
  //   field: 'memo',
  //   headerName: t('memo'),
  //   flex: 10,
  //   sortable: false,
  //   renderCell: (values) => (
  //     <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
  //   ),
  // },
]

export const getERPOverridesFiltersColumns = (t) => [
  {
    filterId: 'date',
    filterTitle: t('date'),
  },
  {
    filterId: 'auditableType',
    filterTitle: t('type'),
  },
]

import { useDispatch, useSelector } from 'react-redux'
import { selectNotifications } from '../store/selectors/notifications'
import { hideNotifications, showNotification } from '../store/actions/notifications'

export const useNotifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(selectNotifications)
  const newNotification = (payload) => {
    dispatch(showNotification(payload))
  }
  const hideNotification = () => {
    dispatch(hideNotifications())
  }
  return { notifications, newNotification, hideNotification }
}

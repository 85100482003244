import { getCompanyRole } from '../pages/contracts/customerInfo/contactInfoSections/CompanyContacts'

export const requestSources = {
  EMAIL: 'email',
  PHONE: 'phone',
}

const getPresentationName = (contact, t, forceSourceType) => {
  if (!contact) {
    return t ? t('unknownUser') : 'Unknown user'
  }

  const { user, email, formattedPhoneNumber } = contact

  if (user?.fullName && user?.companyRole) {
    return `${user.fullName} (${getCompanyRole(user.companyRole)})`
  }

  let forceSource = ''

  switch (forceSourceType) {
    case requestSources.EMAIL:
      forceSource = user?.email
      break
    case requestSources.PHONE:
      forceSource = user?.formattedPhoneNumber
      break
    default:
      break
  }

  if (forceSourceType) {
    return forceSource || t ? t('unknownUser') : 'Unknown user'
  }

  const source = email || formattedPhoneNumber

  if (source) {
    return source
  }

  return t ? t('unknownUser') : 'Unknown user'
}

export const getPresentationGuestName = (email, phoneNumber, t) =>
  `${email || phoneNumber} (${t('guestUser')})`

export default getPresentationName

import { toInteger } from 'lodash'

const isInt = (value) => {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
}

const getSuffix = (day) => {
  const number = toInteger(day)
  if (isInt(number)) {
    const digits = Math.abs(number).toString().split('').reverse()
    if (digits.length > 1 && digits[1] === '1') {
      return 'th'
    } else {
      if (digits[0] === '1') {
        return 'st'
      } else if (digits[0] === '2') {
        return 'nd'
      } else if (digits[0] === '3') {
        return 'rd'
      } else {
        return 'th'
      }
    }
  }
  return ''
}

export const getDayWithSuffix = (number) => `${number}${getSuffix(number)}`

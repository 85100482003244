import React, { Fragment } from 'react'
import cx from 'classnames'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import colors from '../../colors'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'

const Modal = ({
  children,
  title,
  className,
  onModalClose,
  isOpened,
  setIsOpened,
  isInner,
  shouldCloseOnBackdropClick,
  withBackdrop,
  modalClass,
  btnClass,
  titleClass,
  hideCross,
  contentClass,
  skipHeightLimit,
}) => {
  const { t } = useTranslation()
  const btnClasses =
    btnClass ||
    'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl' +
      ' transform' +
      ' transition-all sm:my-8 sm:align-middle sm:max-w-max sm:w-full sm:p-6 min-w-min '

  const titleClasses = titleClass || 'text-lg leading-6 font-medium text-gray-900 pr-11'
  const modalClasses =
    modalClass ||
    `flex items-center justify-center pt-4 px-4 pb-20 text-center sm:block
      sm:p-0`

  const positionClasses = modalClass
    ? `flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block
            sm:p-0`
    : 'h-screen overflow-hidden flex p-8 items-center justify-center'

  const close = () => {
    if (onModalClose) {
      onModalClose()
    }
    setIsOpened(false)
  }

  return (
    <Transition.Root as={Fragment} show={isOpened}>
      <Dialog
        as="div"
        className={`fixed z-30 inset-0 overflow-y-auto  ${className}`}
        onClose={shouldCloseOnBackdropClick ? close : () => null}>
        <div className={positionClasses}>
          {withBackdrop && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay
                className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
                  isInner ? 'top-14' : ''
                }`}
              />
            </Transition.Child>
          )}

          <span aria-hidden="true" className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div className={modalClasses}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className={btnClasses}>
                {!hideCross && (
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-20">
                    <button
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none
                  focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={close}
                      testData="x-close"
                      type="button">
                      <span className="sr-only">{t('close')}</span>

                      <XIcon
                        aria-hidden="true"
                        className="h-6 w-6"
                        color={colors.BLACK}
                        onClick={close}
                      />
                    </button>
                  </div>
                )}

                <div className="sm:flex sm:items-start">
                  <div
                    className={cx(
                      'mt-3 text-center sm:mt-0 sm:text-left overflow-y-auto min-w-[25rem]',
                      contentClass,
                    )}
                    style={!skipHeightLimit ? { maxHeight: 'calc(100vh - 2rem - 1.5rem)' } : {}}>
                    <Dialog.Title as="h3" className={titleClasses}>
                      {title}
                    </Dialog.Title>
                    {children}
                    <div className="mt-2" />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.propTypes = {
  btnClass: PT.string,
  children: PT.node.isRequired,
  className: PT.string,
  isInner: PT.bool,
  isOpened: PT.bool.isRequired,
  modalClass: PT.string,
  onModalClose: PT.func,
  setIsOpened: PT.func.isRequired,
  shouldCloseOnBackdropClick: PT.bool,
  title: PT.string,
  titleClass: PT.string,
  withBackdrop: PT.bool,
  hideCross: PT.bool,
  contentClass: PT.string,
  skipHeightLimit: PT.bool,
}
Modal.defaultProps = {
  btnClass: undefined,
  className: '',
  isInner: false,
  modalClass: undefined,
  onModalClose: null,
  shouldCloseOnBackdropClick: false,
  title: '',
  titleClass: undefined,
  withBackdrop: true,
  hideCross: false,
  contentClass: '',
  skipHeightLimit: false,
}

export default Modal

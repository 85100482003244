import React, { useState } from 'react'
import PT from 'prop-types'
import cx from 'classnames'

import classes from './Input.module.scss'

const Checkbox = ({ id, value, name, onChange, className }) => {
  const checkboxClassnames = cx(classes.checkbox, className)

  const [inputValue, setInputValue] = useState(value)

  const handleChange = () => {
    if (onChange) {
      onChange(!inputValue)
    }
    setInputValue(!inputValue)
  }

  return (
    <input
      checked={inputValue}
      className={checkboxClassnames}
      id={id}
      name={name}
      onChange={handleChange}
      testdata={`checkbox-${inputValue ? 'checked' : 'unchecked'}`}
      type="checkbox"
    />
  )
}

Checkbox.propTypes = {
  className: PT.string,
  id: PT.string.isRequired,
  name: PT.string,
  onChange: PT.func,
  value: PT.string,
}

Checkbox.defaultProps = {
  className: '',
  name: null,
  onChange: null,
  value: false,
}

export default Checkbox

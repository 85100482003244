import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { Heading, Tabs, Collapse, Flex } from '../../ui-kit'

const PageHeader = ({
  headerTitle,
  tabs,
  actions,
  collapseContent,
  collapseOpen,
  subHeading,
  className,
}) => {
  const renderActions = () => {
    if (actions && actions.length > 0) {
      return <Flex>{actions.map((element) => element)}</Flex>
    }
  }

  const renderHeading = () => <Heading subHeading={subHeading}>{headerTitle}</Heading>

  const renderTabsOrHeading = () => {
    if (tabs.length) {
      return <Tabs tabs={tabs} />
    } else {
      return renderHeading()
    }
  }

  return (
    <div className={cx('pb-5 mb-2 capitalize', className)}>
      {!!tabs.length && renderHeading()}

      <Flex alignItems="end" className="mt-3 sm:mt-4" justifyContent="between">
        {renderTabsOrHeading()}

        {renderActions()}
      </Flex>

      {collapseContent && (
        <Collapse className="mt-3" isOpen={collapseOpen}>
          {collapseContent}
        </Collapse>
      )}
    </div>
  )
}

PageHeader.propTypes = {
  actions: PT.arrayOf(PT.node),
  collapseContent: PT.element,
  collapseOpen: PT.bool,
  headerTitle: PT.string,
  subHeading: PT.oneOfType([PT.string, PT.number]),
  tabs: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      link: PT.string,
      onClick: PT.func,
      active: PT.bool,
    }),
  ),
  className: PT.string,
}

PageHeader.defaultProps = {
  actions: [],
  collapseContent: null,
  collapseOpen: false,
  headerTitle: '',
  subHeading: null,
  tabs: [],
  className: '',
}

export default PageHeader

import React from 'react'
import Icon from '../../ui-kit/components/icons/Icon'
import colors from '../../ui-kit/colors'
import PT from 'prop-types'
import { Flex, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'

const FilterChip = ({ label, value, onRemoveFilter }) => {
  return (
    <Flex className="border px-2 bg-white border-primary-700 rounded-md mr-2">
      <Text className="pr-1" color="text-black-500" size={sizes.SM}>
        {label}:
      </Text>
      <span className="pr-1">{value}</span>
      <button className="focus:outline-none" onClick={onRemoveFilter} testData="remove_filter_chip">
        <Icon className="hover:text-primary-700" color={colors.GREY} name="x" size={sizes.SM} />
      </button>
    </Flex>
  )
}

FilterChip.propTypes = {
  label: PT.string,
  value: PT.string,
  onRemoveFilter: PT.func,
}

export default FilterChip

import React, { useCallback } from 'react'
import { useBeforeUnload } from '../../hooks/useBeforeUnload'
import { validateBankRoutingNumber, validateRequiredField } from '../../utils/validators'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import AlertModal from '../../ui-kit/components/alertModal/AlertModal'
import { Button, Flex, Icon, Input, Tooltip } from '../../ui-kit'
import Checkbox from '../../ui-kit/components/inputs/Checkbox'
import RadioGroup from '../../ui-kit/components/inputs/RadioGroup'
import { CreateACHPaymentMethodRequest } from '../../queries/mutations/createAchPaymentMethod.gql'
import { idType } from '../../types/defaultTypes'
import { useNotifications } from '../../hooks/useNotifications'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { normalizeLettersNumbers } from '../../utils/normalizeBankInputFields'

const BankTransferForm = ({
  onCreateMethod,
  isFormDirty,
  setDirtyFormState,
  closeForm,
  contractId,
}) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()

  const onCompleted = useCallback(
    (response) => {
      if (!response.createAchPaymentMethod?.entity) {
        return
      }

      onCreateMethod({
        ...response.createAchPaymentMethod.entity,
        isDefault: response.createAchPaymentMethod.entity.default,
      })
      newNotification({ success: t('newPaymentMethodWasAddedSuccessfully') })
    },
    [onCreateMethod],
  )
  const [createAchPaymentMethod, { loading }] = useCustomMutation({
    mutation: CreateACHPaymentMethodRequest,
    onCompleted,
    rollbarOptions: { operationName: 'CreateACHPaymentMethodRequest', target: 'BankTransferForm' },
  })
  const onSubmit = (values) => {
    createAchPaymentMethod({
      variables: {
        data: {
          contractId,
          ...values,
        },
      },
    })
  }
  const initialValues = {
    nameOnTheAccount: '',
    routingNumber: '',
    accountNumber: '',
    checkType: 'PERSONAL',
    accountType: 'CHECKING',
    default: true,
    nickname: '',
  }
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, dirty }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col mt-5" id="add-ach-method-form" onSubmit={handleSubmit}>
            <Flex column>
              <Flex alignItems="center">
                <Field name="nameOnTheAccount">
                  {({ input, meta }) => (
                    <Input
                      className="mr-2.5 w-full"
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      placeholder={t(input.name)}
                      {...input}
                    />
                  )}
                </Field>
                <Tooltip content={t('accountHolderNameTooltip')}>
                  <Icon className="w-8" color="text-warmBlack-400" name="questionMarkCircleIcon" />
                </Tooltip>
              </Flex>
              <Flex alignItems="center" className="mt-4">
                <Field name="routingNumber" parse={normalizeLettersNumbers}>
                  {({ input, meta }) => (
                    <Input
                      className="mr-2.5 w-full"
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      placeholder={t(input.name)}
                      {...input}
                    />
                  )}
                </Field>
                <Tooltip content={t('bankRoutingNumberTooltip')}>
                  <Icon className="w-8" color="text-warmBlack-400" name="questionMarkCircleIcon" />
                </Tooltip>{' '}
              </Flex>
              <Flex alignItems="center" className="mt-4">
                <Field name="accountNumber" parse={normalizeLettersNumbers}>
                  {({ input, meta }) => (
                    <Input
                      className="mr-2.5 w-full"
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      placeholder={t(input.name)}
                      {...input}
                    />
                  )}
                </Field>
                <Tooltip content={t('bankAccountNumberTooltip')}>
                  <Icon className="w-8" color="text-warmBlack-400" name="questionMarkCircleIcon" />
                </Tooltip>{' '}
              </Flex>
              <Flex alignItems="center" className="mt-4">
                <Field name="nickname">
                  {({ input, meta }) => (
                    <Input
                      className="mr-10 w-full"
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      maxLength={16}
                      placeholder={t(input.name)}
                      {...input}
                    />
                  )}
                </Field>
              </Flex>

              <Field name="accountType">
                {({ input }) => (
                  <RadioGroup
                    defaultCheckedValue={input.value}
                    itemClassName={'w-1/2'}
                    itemMargin={'ml-16'}
                    name={input.name}
                    onValueChange={input.onChange}
                    options={[
                      { id: 'CHECKING', label: t('checking'), value: 'CHECKING' },
                      {
                        id: 'SAVINGS',
                        label: t('savings'),
                        value: 'SAVINGS',
                      },
                    ]}
                    wrapperClassName={'flex items-center pl-5 pr-[60px] mt-5'}
                  />
                )}
              </Field>
              <Field name="checkType">
                {({ input }) => (
                  <RadioGroup
                    defaultCheckedValue={input.value}
                    itemClassName={'w-1/2'}
                    itemMargin={'ml-16'}
                    name={input.name}
                    onValueChange={input.onChange}
                    options={[
                      { id: 'PERSONAL', label: t('personal'), value: 'PERSONAL' },
                      {
                        id: 'BUSINESS',
                        label: t('business'),
                        value: 'BUSINESS',
                      },
                    ]}
                    wrapperClassName={'flex items-center pl-5 pr-[60px] mt-5'}
                  />
                )}
              </Field>

              <Flex alignItems="center" className="mt-6">
                <Field name="default">
                  {({ input }) => (
                    <Checkbox
                      id={input.name}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )}
                </Field>
                <label className="ml-2" htmlFor="default">
                  {t('setAsDefaultPaymentMethod')}
                </label>
              </Flex>

              <Button
                className="mt-4"
                disabled={submitting || loading}
                label={t('addBankTransfer')}
                testData="add-bank-transfer"
                type="submit"
                fullWidth
              />
            </Flex>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => {
        const errors = {}
        errors.routingNumber =
          validateRequiredField(values.routingNumber) ||
          validateBankRoutingNumber(values.routingNumber)
        errors.accountNumber = validateRequiredField(values.accountNumber)
        errors.nameOnTheAccount = validateRequiredField(values.nameOnTheAccount)
        return errors
      }}
      validateOnBlur={false}
    />
  )
}

export default BankTransferForm

BankTransferForm.propTypes = {
  contractId: idType,
  closeForm: PT.func.isRequired,
  isFormDirty: PT.bool.isRequired,
  onCreateMethod: PT.func.isRequired,
  setDirtyFormState: PT.func.isRequired,
}

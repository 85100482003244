export const ACTIVITY_TYPES = {
  PAYMENT_PROMISE: 'payment_promise',
  PAYMENT_PLAN: 'payment_plan',
  DISPUTE: 'dispute',
  INSTALLMENT: 'payment_plan_installment',
  PAYMENT_REQUEST: 'payment_request',
  PAYMENT: 'payment',
  NOTE: 'note',
  REFUND: 'invoice_refund',
  DEPOSIT: 'deposit',
  CREDIT_REVIEW: 'credit_review',
  AUDIT: 'audit',
}

export const PAYMENT_PLAN_ACTIONS = {
  BROKEN: 'broken',
  CREATE: 'create',
}

export const PAYMENT_DISPUTE_ACTIONS = {
  RESOLVE: 'resolve',
}

export const PAYMENT_PROMISE_STATUSES = {
  BROKEN: 'broken',
  PROMISED: 'promised',
  FULFILLED: 'fulfilled',
  CANCELLED: 'cancelled',
}

export const PAYMENT_PLAN_STATUSES = {
  INITIAL: 'initial',
  PARTIALLY_PAID: 'partially_paid',
  PAID: 'paid',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
}

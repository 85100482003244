import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'react-final-form'
import { Icon, Input } from '../../ui-kit'

const PasswordFields = ({
  isPasswordVisible,
  setIsPasswordVisible,
  isPasswordConfirmationVisible,
  setIsPasswordConfirmationVisible,
  isShowCurrentPasswordField,
  isCurrentPasswordVisible,
  setIsCurrentPasswordVisible,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {isShowCurrentPasswordField && (
        <Field name="currentPassword">
          {({ input, meta }) => {
            return (
              <Input
                {...input}
                className="pt-6"
                errorMessage={
                  meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                }
                icon={
                  <Icon
                    name={isCurrentPasswordVisible ? 'eyeOff' : 'eye'}
                    onClick={() => setIsCurrentPasswordVisible(!isCurrentPasswordVisible)}
                    type="outline"
                  />
                }
                id={input.name}
                label={t(input.name)}
                placeholder={t(input.name)}
                tabIndex="1"
                type={isCurrentPasswordVisible ? 'text' : 'password'}
              />
            )
          }}
        </Field>
      )}
      <Field name="password">
        {({ input, meta }) => {
          return (
            <Input
              {...input}
              className="pt-6"
              errorMessage={
                meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
              }
              helpText={t('passwordHint')}
              icon={
                <Icon
                  name={isPasswordVisible ? 'eyeOff' : 'eye'}
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  type="outline"
                />
              }
              id={input.name}
              label={t(input.name)}
              placeholder={t(input.name)}
              tabIndex="1"
              type={isPasswordVisible ? 'text' : 'password'}
            />
          )
        }}
      </Field>
      <Field name="passwordConfirmation">
        {({ input, meta }) => {
          return (
            <Input
              {...input}
              className="pt-6"
              errorMessage={
                meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
              }
              helpText={t('passwordConfirmationHint')}
              icon={
                <Icon
                  name={isPasswordConfirmationVisible ? 'eyeOff' : 'eye'}
                  onClick={() => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible)}
                  type="outline"
                />
              }
              id={input.name}
              label={t(input.name)}
              placeholder={t(input.name)}
              tabIndex="1"
              type={isPasswordConfirmationVisible ? 'text' : 'password'}
            />
          )
        }}
      </Field>
    </>
  )
}

PasswordFields.propTypes = {
  isPasswordVisible: PropTypes.bool,
  setIsPasswordVisible: PropTypes.bool,
  isPasswordConfirmationVisible: PropTypes.func.isRequired,
  setIsPasswordConfirmationVisible: PropTypes.func.isRequired,
  isCurrentPasswordVisible: PropTypes.bool,
  setIsCurrentPasswordVisible: PropTypes.func,
  isShowCurrentPasswordField: PropTypes.bool,
}

PasswordFields.defaultProps = {
  isPasswordVisible: false,
  setIsPasswordVisible: false,
  isShowCurrentPasswordField: false,
}

export default React.memo(PasswordFields)

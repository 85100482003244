import React, { useCallback, useMemo } from 'react'
import PT from 'prop-types'
import { Form, Field } from 'react-final-form'
import Textarea from '../../../../ui-kit/components/inputs/Textarea'
import { useTranslation } from 'react-i18next'
import { Button, Flex } from '../../../../ui-kit'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'

const AddMemoForm = ({ customerName, setIsOpenedModal, setMemo, memo, customSubmit }) => {
  const initialValues = useMemo(
    () => ({
      memo: memo,
    }),
    [memo],
  )
  const { t } = useTranslation()

  const handleSubmit = useCallback((values) => {
    setMemo(values.memo)
    setIsOpenedModal(false)
  }, [])

  return (
    <div className={'w-[700px]'}>
      <div className={'mb-2 font-bold'}>{customerName}</div>
      <Form
        initialValues={initialValues}
        onSubmit={customSubmit ? customSubmit : handleSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field name={'memo'}>
                {({ input }) => {
                  return (
                    <Textarea
                      id={input.name}
                      inputClassName={'h-44'}
                      label={t('memo')}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )
                }}
              </Field>
              <Flex className="w-full mt-6" justifyContent="end">
                <Button
                  label={t('cancel')}
                  onClick={() => setIsOpenedModal(false)}
                  testData="cancel-memo"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  className="w-36 ml-3"
                  label={t('save')}
                  testData="save-memo"
                  type="submit"
                />
              </Flex>
            </form>
          )
        }}
      />
    </div>
  )
}

export default AddMemoForm

AddMemoForm.propTypes = {
  customerName: PT.string,
  setIsOpenedModal: PT.func.isRequired,
  setMemo: PT.func,
  memo: PT.string,
  customSubmit: PT.func,
}

AddMemoForm.defaultProps = {
  memo: '',
  customerName: '',
}

import React, { useCallback } from 'react'
import PT from 'prop-types'
import { Icon, Tooltip } from '../../../ui-kit'
import colors from '../../../ui-kit/colors'
import { useTranslation } from 'react-i18next'
import { writeTextToClipboard } from '../../../utils/utils'
import { useNotifications } from '../../../hooks/useNotifications'
import { membershipRoles } from './membershipRoles'

const UserActions = ({
  user,
  onEdit,
  onDelete,
  sendInvitation,
  isCompanyContactsActions,
  isInactiveContract,
  userId,
  userMembershipRole,
}) => {
  const status = user.status

  const { t } = useTranslation()
  const { newNotification } = useNotifications()

  const resendInvitationAction = (
    <Tooltip content={t('resentInvitation')} key="resend-invitation">
      <Icon
        className="mr-4 cursor-pointer"
        color={colors.PRIMARY}
        name="refresh"
        onClick={() => sendInvitation(user.id)}
      />
    </Tooltip>
  )

  const sendEmailAction = (
    <Tooltip content={t('sendInvitationEmail')} key="send-invitation">
      <Icon
        className="mr-4 cursor-pointer"
        color={colors.GREY}
        name="mail"
        onClick={() => sendInvitation(user.id)}
        f
      />
    </Tooltip>
  )

  const editAction = (
    <Tooltip content={t('editUser')} key="edit-invitation">
      <Icon
        className="mr-4 cursor-pointer blue"
        color={colors.GREY}
        name="pencil"
        onClick={onEdit}
      />
    </Tooltip>
  )

  const archiveAction = (
    <Tooltip content={t('removeUser')} key="archive">
      <Icon color={colors.GREY} name="trash" onClick={onDelete} />
    </Tooltip>
  )

  const handleCopyInvitationLinkClick = useCallback(async () => {
    ;(await writeTextToClipboard(user?.invitationLink)) &&
      newNotification({ success: t('inviteLinkCopied') })
  }, [user?.invitationLink, newNotification])

  const copyInviteLinkAction = (
    <Tooltip content={t('copyInviteLink')} key="copyInvite">
      <Icon
        color="mr-4 cursor-pointer text-primary-700"
        name="duplicate"
        onClick={handleCopyInvitationLinkClick}
        type="outline"
      />
    </Tooltip>
  )

  const actions = []

  if (status === 'active' || status === 'pending_onboarding') {
    if (
      isCompanyContactsActions ||
      (!isCompanyContactsActions &&
        userMembershipRole === membershipRoles.ADMIN &&
        user.id !== userId)
    ) {
      actions.push([editAction])
    }

    if (isCompanyContactsActions || (!isCompanyContactsActions && user.id !== userId)) {
      actions.push([archiveAction])
    }
  }
  if (status === 'invited') {
    if (isCompanyContactsActions && !isInactiveContract) {
      actions.push([resendInvitationAction, copyInviteLinkAction, editAction, archiveAction])
    }
    if (isCompanyContactsActions && isInactiveContract) {
      actions.push([editAction, archiveAction])
    }
    if (!isCompanyContactsActions) {
      actions.push([resendInvitationAction, editAction, archiveAction])
    }
  }
  if (status === 'pending_invitation') {
    if (isCompanyContactsActions && isInactiveContract) {
      actions.push([editAction, archiveAction])
    } else {
      actions.push([sendEmailAction, editAction, archiveAction])
    }
  }

  return actions
}

UserActions.propTypes = {
  user: PT.shape({
    id: PT.string,
    email: PT.string,
    firstName: PT.string,
    lastName: PT.string,
    phoneNumber: PT.string,
    permissions: PT.string,
  }),
  onEdit: PT.func.isRequired,
  onDelete: PT.func.isRequired,
  sendInvitation: PT.func.isRequired,
  refetchQueries: PT.arrayOf(PT.any),
  isCompanyContactsActions: PT.bool,
  userId: PT.string,
  userMembershipRole: PT.string,
}

UserActions.defaultProps = {
  user: {},
  refetchQueries: [],
  isCompanyContactsActions: false,
}

export default UserActions

import {
  Dialog,
  Flex,
  Icon,
  InfoNote,
  LoadingSpinner,
  Money,
  StatusBadge,
  Text,
  Tooltip,
} from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import Button from '../../../ui-kit/components/buttons/Button'
import { depositStatuses, depositStatusMapping } from '../invoicesUtils'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useNavigate } from 'react-router-dom'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { UpdateDeposit } from '../../../queriesUpdated/mutations/updateDeposit.gql'
import { InvoicesDepositsQuery } from '../../../queries/invoices/invoicesDeposits.gql'
import DepositActivities from '../depositActivity/DepositActivities'
import { paymentMethodTypes } from '../../../constants/paymentResults'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { chargebackLogo } from '../../../ui-kit/assets'
import UpdateAttachmentForm from '../../contracts/cashApp/updateAttachmentForm/UpdateAttachmentForm'
import colors from '../../../ui-kit/colors'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { DepositContentQuery } from '../../../queries/invoices/invoicesDeposits.gql'

const SidebarDepositContent = ({
  depositId,
  setIsSidebarOpened,
  setIsReturnDepositModalOpened,
  setIsOpenedCancelRequestConfirmationModal,
  runAuthorizedPaymentHandler,
  setIsOpenedCancelAuthorizationConfirmationModal,
  isRunAuthorizedPaymentDisabled,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isAttachmentMemoModalOpened, setIsAttachmentMemoModalOpened] = useState(false)

  const { data: depositQueryData, loading: loadingDepositData } = useCustomQuery({
    query: DepositContentQuery,
    queryOptions: {
      variables: { id: depositId },
      skip: !depositId,
    },
    rollbarOptions: { operationName: 'DepositQuery', target: 'Deposits' },
  })
  const data = depositQueryData?.deposit || {}

  const descriptionData = [
    { label: t('location'), value: data?.location?.name || '-' },
    {
      label: t('customer'),
      node: (
        <Button
          label={data?.contract?.buyer?.name}
          onClick={() => navigate(`/customers/${data?.contract?.id}/overview`)}
          size={sizes.SM}
          testData="customer-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    {
      label: t('project'),
      value: !data?.project?.name && '-',
      node: data?.project?.name && (
        <Button
          className="overflow-hidden"
          label={data.project.name}
          onClick={() =>
            navigate(
              // eslint-disable-next-line max-len
              `/customers/${data?.contract?.id}/projects/${data.project.status}?additionalFilters=id&filters=id.${data.project.id}&openSidebarParams=id.${data.project.id}`,
            )
          }
          size={sizes.SM}
          testData="project-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    { label: t('erpID'), value: data?.erpId || '-' },
    { label: t('type'), value: data?.overpayment ? t('overpayment') : t('deposit') },
    { label: t('source'), value: t(data?.source) },
    {
      label: t('createdOn'),
      value: <FormattedDate date={data?.createdAt} format={'MM/dd/yyyy h:mm a'} />,
    },
    {
      label: t('paymentDate'),
      value: <FormattedDate date={data?.latestPaymentTransaction?.entryDate} />,
    },
    {
      label: t('settlementDate'),
      value: (
        <FormattedDate
          date={data?.latestPaymentTransaction?.settledAt}
          format={'MM/dd/yyyy h:mm a'}
        />
      ),
      hidden:
        data?.latestPaymentTransaction?.paymentMethod?.type !==
        paymentMethodTypes.ACH_PAYMENT_METHOD,
    },
    {
      label: t('paymentMethod'),
      value: data?.latestPaymentTransaction?.paymentMethod?.title
        ? `${data?.latestPaymentTransaction?.paymentMethod?.title}${
            data?.latestPaymentTransaction?.paymentMethod?.nickname
              ? ` (${data?.latestPaymentTransaction?.paymentMethod?.nickname})`
              : ''
          }`
        : '-',
    },
    {
      label: t('depositedTo'),
      value: data?.latestPaymentTransaction?.payable?.financialEntity?.name || '-',
    },
    { label: t('amount'), value: <Money value={data?.amountCents} /> },
  ]
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)

  const [updateDeposit, { loading }] = useCustomMutation({
    mutation: UpdateDeposit,
    rollbarOptions: { operationName: 'UpdateDepositMemo', target: 'SidebarDepositContent' },
    onCompleted: () => setIsAttachmentMemoModalOpened(false),
    mutationOptions: {
      refetchQueries: [InvoicesDepositsQuery, DepositContentQuery],
    },
  })
  const onAttachmentSubmit = (values) => {
    const variables = { id: data.id, data: { file: values.file } }
    updateDeposit({ variables })
  }
  const handleDeleteAttachment = () => {
    const variables = { id: data.id, data: { file: null } }
    updateDeposit({ variables })
  }

  const currentUser = useCurrentUser()

  return (
    <>
      {loadingDepositData && <LoadingSpinner className="w-full" loading />}
      {!loadingDepositData && (
        <Flex column>
          <Flex column>
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
              {t('deposit')}
            </Text>

            <InfoNote label={t('id')} size={sizes.SM}>
              {currentUser.erpMetadata?.supportDeposits ? data.id : data.referenceId}
            </InfoNote>
          </Flex>

          <Flex className="mt-3">
            <Flex className="w-6/12" column>
              <SidebarLabel text="totalOutstanding" />
              <Money className="text-2xl mt-1" value={data?.outstandingAmountCents} />
            </Flex>
            <Flex className="w-6/12" column>
              <SidebarLabel text="status" />
              <div className="mt-2 flex items-center">
                <StatusBadge
                  color={depositStatusMapping[data?.displayStatus]?.color}
                  value={t(depositStatusMapping[data?.displayStatus]?.label)}
                />
                {data.chargebacksPresent && (
                  <div>
                    <Tooltip content={t('refunded')}>
                      <img alt="Suppli" className="h-6 ml-2" src={chargebackLogo} />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Flex>
          </Flex>

          <DividedList content={descriptionData} />

          <div className="w-full mt-6">
            <MemoField
              closeForm={() => null}
              entityId={data.id}
              initialValue={data.memo}
              isFormDirty={memoFormIsDirty}
              isLoading={loading}
              responseEntityName={'updateDeposit'}
              setDirtyFormState={setMemoFormIsDirty}
              updateEntity={updateDeposit}
            />
          </div>

          {!data.file ? (
            <Button
              className="mt-8"
              iconName="plus"
              label={t('addAttachment')}
              onClick={() => setIsAttachmentMemoModalOpened(true)}
              size={sizes.SM}
              testData="add-new-doc"
              variant={buttonsVariants.LINK}
            />
          ) : (
            <Flex alignItems="center" className="mt-8">
              <Button
                className="w-fit underline text-left"
                label={data.file.filename}
                onClick={() => window.open(data.file.url)}
                size={sizes.SM}
                testData="view-file"
                variant={buttonsVariants.LINK}
              />
              <Icon
                className="min-w-5 ml-2"
                color={colors.GREY}
                disabled={loading}
                name="pencil"
                onClick={() => setIsAttachmentMemoModalOpened(true)}
              />
              <Icon
                className="min-w-5 ml-2"
                color={colors.GREY}
                disabled={loading}
                name="trash"
                onClick={handleDeleteAttachment}
              />
            </Flex>
          )}

          {currentUser.erpMetadata?.depositReturnsEnabled &&
            !!data?.refundableAmountCents &&
            data?.latestPaymentTransaction && (
              <Button
                className="mt-6"
                label={t('return')}
                onClick={() => {
                  setIsReturnDepositModalOpened(true)
                  setIsSidebarOpened(false)
                }}
                size={sizes.BASE}
                testData="open-return-deposit"
                variant={buttonsVariants.SECONDARY}
                fullWidth
              />
            )}

          {data?.displayStatus === depositStatuses.SENT && (
            <Button
              className="mt-6"
              label={t('cancelRequest')}
              onClick={() => {
                setIsSidebarOpened(false)
                setIsOpenedCancelRequestConfirmationModal(true)
              }}
              size={sizes.BASE}
              testData="cancel-deposit-request"
              variant={buttonsVariants.TERTIARY}
              fullWidth
            />
          )}

          {data?.displayStatus === depositStatuses.AUTHORIZED && (
            <>
              <Button
                className="mt-6"
                disabled={isRunAuthorizedPaymentDisabled}
                label={t('runPayment')}
                onClick={() => runAuthorizedPaymentHandler(data)}
                size={sizes.BASE}
                testData="run-authorization-payment"
                fullWidth
              />
              <Button
                className="mt-6"
                label={t('cancelAuthorization')}
                onClick={() => {
                  setIsSidebarOpened(false)
                  setIsOpenedCancelAuthorizationConfirmationModal(true)
                }}
                size={sizes.BASE}
                testData="cancel-authorization"
                variant={buttonsVariants.TERTIARY}
                fullWidth
              />
            </>
          )}

          {(!!data?.events?.length || !!data?.audits?.length) && (
            <DepositActivities contractId={data.contract?.id} data={data} />
          )}
        </Flex>
      )}
      <Dialog
        isOpened={isAttachmentMemoModalOpened}
        setIsOpened={setIsAttachmentMemoModalOpened}
        title={t('addAttachment')}>
        <UpdateAttachmentForm
          onSubmit={onAttachmentSubmit}
          setIsOpenedModal={setIsAttachmentMemoModalOpened}
          fileRequired
        />
      </Dialog>
    </>
  )
}

SidebarDepositContent.propTypes = {
  depositId: PropTypes.string,
  setIsSidebarOpened: PropTypes.func.isRequired,
  runAuthorizedPaymentHandler: PropTypes.func.isRequired,
  setIsReturnDepositModalOpened: PropTypes.func.isRequired,
  isRunAuthorizedPaymentDisabled: PropTypes.bool.isRequired,
  setIsOpenedCancelRequestConfirmationModal: PropTypes.func.isRequired,
  setIsOpenedCancelAuthorizationConfirmationModal: PropTypes.func.isRequired,
}

export default SidebarDepositContent

import React from 'react'
import { Flex, Text } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { logo } from '../../ui-kit/assets'
import PT from 'prop-types'
import fontWeight from '../../ui-kit/fontWeight'

const LoginWrapTemplate = ({ children }) => {
  const { t } = useTranslation()

  return (
    <Flex
      alignItems="center"
      className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
      justifyContent="center">
      <Flex className="w-[72rem] bg-white shadow rounded-md overflow-hidden min-h-[40rem]">
        <div className="w-6/12 space-y-6 py-24 px-16">
          <div className="flex items-center flex-shrink-0 pb-6">
            <img alt="Suppli" className="h-16" src={logo} />
          </div>
          {children}
        </div>
        <Flex alignItems="center" className="w-8/12 bg-orange-700 p-12">
          <div className="text-center">
            <Text
              className="text-[6rem] leading-[9rem]"
              color="text-white"
              fontWeight={fontWeight.MEDIUM}>
              {t('itsGreatDayToGetPaid')}
            </Text>
          </div>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LoginWrapTemplate

LoginWrapTemplate.propTypes = {
  children: PT.node.isRequired,
}

import React, { useCallback, useMemo, useRef } from 'react'
import PT from 'prop-types'
import DatePickerLibComponent, { CalendarContainer } from 'react-datepicker'
import styles from './FilterDatePicker.module.scss'
import cx from 'classnames'
import Icon from '../icons/Icon'
import 'react-datepicker/dist/react-datepicker.css'
import Input from '../inputs/Input'
import { DEFAULT_DATE_FORMAT } from '../../utils/dateUtils'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

const FilterDatePicker = ({
  startDate,
  endDate,
  onChange,
  onBlur,
  placeholder = 'Select Date',
  error,
  className,
  testData,
  ...props
}) => {
  const { t } = useTranslation()
  const pickerRef = useRef()
  const closeDatePicker = useCallback(() => {
    pickerRef?.current?.setOpen?.(false)
  }, [pickerRef.current])
  const getDateRange = useCallback((action, config) => {
    const currentDate = DateTime.now().startOf('day')
    const targetDate =
      action === 'plus'
        ? currentDate.plus(config).startOf('day')
        : currentDate.minus(config).startOf('day')

    return action === 'plus'
      ? [currentDate.toJSDate(), targetDate.toJSDate()]
      : [targetDate.toJSDate(), currentDate.toJSDate()]
  }, [])
  const actions = useMemo(
    () => [
      {
        label: t('nextWeek'),
        onClick: () => {
          const [startDate, endDate] = getDateRange('plus', { days: 7 })
          onChange([startDate, endDate])
          closeDatePicker()
        },
      },
      {
        label: t('last14Days'),
        onClick: () => {
          const [startDate, endDate] = getDateRange('minus', { days: 14 })
          onChange([startDate, endDate])
          closeDatePicker()
        },
      },
      {
        label: t('last30Days'),
        onClick: () => {
          const [startDate, endDate] = getDateRange('minus', { days: 30 })
          onChange([startDate, endDate])
          closeDatePicker()
        },
      },
      {
        label: t('last3Month'),
        onClick: () => {
          const [startDate, endDate] = getDateRange('minus', { months: 3 })
          onChange([startDate, endDate])
          closeDatePicker()
        },
      },
      {
        label: t('last12Month'),
        onClick: () => {
          const [startDate, endDate] = getDateRange('minus', { months: 12 })
          onChange([startDate, endDate])
          closeDatePicker()
        },
      },
      {
        label: t('monthToDate'),
        onClick: () => {
          const startDate = DateTime.now().startOf('month').toJSDate()
          const endDate = DateTime.now().startOf('day').toJSDate()
          onChange([startDate, endDate])
          closeDatePicker()
        },
      },
    ],
    [closeDatePicker, onChange, getDateRange, t],
  )

  // eslint-disable-next-line react/prop-types
  const CustomContainer = ({ className, children }) => {
    const calendarWrapperClasses = cx('flex flex-col', actions?.length ? 'w-[125%]' : 'w-full')
    const calendarClasses = cx(className, {
      'react-datepicker--with-actions': !!actions?.length,
    })
    return (
      <div>
        <CalendarContainer className="flex">
          {actions?.length ? (
            <div className="flex flex-col min-w-[25%] bg-white border-gray-200 border-r py-4">
              {actions.map((action) => (
                <div
                  className="px-6 my-2 cursor-pointer"
                  key={action.label}
                  onClick={action.onClick}>
                  {action.label}
                </div>
              ))}
            </div>
          ) : null}
          <div className={calendarWrapperClasses}>
            <div className={calendarClasses}>{children}</div>
          </div>
        </CalendarContainer>
      </div>
    )
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      <DatePickerLibComponent
        dateFormat={DEFAULT_DATE_FORMAT}
        ref={pickerRef}
        {...props}
        calendarContainer={CustomContainer}
        closeOnScroll={(e) => e.target === document}
        customInput={
          <Input
            error={error}
            icon={
              <Icon
                color={'primary-700'}
                name={'calendar'}
                onClick={(e) => {
                  e.preventDefault()
                }}
                type={'solid'}
              />
            }
            testData={testData}
          />
        }
        endDate={endDate}
        monthsShown={2}
        onBlur={onBlur}
        onChange={onChange}
        onChangeRaw={(event) => {
          const inputValue = event.target.value?.replace(/\s/g, '')
          if (inputValue?.length <= 1) {
            onChange([null, null])
          }
          if (inputValue?.length >= 21) {
            const [startDate, endDate] = inputValue.split('-')
            onChange([new Date(startDate), new Date(endDate)])
          }
        }}
        onKeyDown={(e) => {
          const allowedSymbols = [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '0',
            '/',
            '.',
            '-',
            'Backspace',
            'ArrowLeft',
            'ArrowRight',
          ]
          if (!allowedSymbols.includes(e.key)) {
            e.preventDefault()
          }
        }}
        placeholderText={placeholder}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        portalId="filter-datepicker-portal"
        shouldCloseOnSelect={true}
        startDate={startDate}
        strictParsing={true}
        selectsRange
      />
    </div>
  )
}

FilterDatePicker.propTypes = {
  error: PT.bool,
  className: PT.string,
  endDate: PT.string,
  startDate: PT.string,
  onChange: PT.func,
  onBlur: PT.func,
  placeholder: PT.string,
  testData: PT.string,
}

FilterDatePicker.defaultProps = {
  error: false,
  className: '',
  endDate: '',
  startDate: '',
  onChange: () => {},
  onBlur: () => {},
  placeholder: null,
}

export default FilterDatePicker

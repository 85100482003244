import sizes from '../../sizes'
import Icon from './Icon'

export function SortedDescendingIcon() {
  return <Icon name="chevronDown" size={sizes.BASE} />
}

export function SortedAscendingIcon() {
  return <Icon name="chevronUp" size={sizes.BASE} />
}

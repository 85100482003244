import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Flex, Icon, Text } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import fontWeight from '../../../ui-kit/fontWeight'
import colors from '../../../ui-kit/colors'

const ValidationTooltips = ({ errorKey, actionKey }) => {
  const { t } = useTranslation()
  const actionKeyStringMap = {
    receivePayment: 'receivePaymentOnInvoice',
    promiseToPay: 'addInvoiceToP2P',
    paymentPlan: 'addAnInvoiceToPaymentPlan',
    requestPayment: 'requestPaymentInvoice',
  }
  const actionIconMap = {
    invoicePaid: (
      <Text className="px-1 pt-2 flex" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
        <Icon className="pr-1" color={colors.RED} name="fastForward" type="solid" />
        {t('pendingPmt')}
      </Text>
    ),
    invoicePromisedToPay: (
      <Text className="px-1 pt-2 flex" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
        <Icon className="pr-1" color="text-orange-600" name="cash" type="outline" />
        {t('p2p')}
      </Text>
    ),
    invoicePlannedToPay: (
      <Text className="px-1 pt-2 flex" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
        <Icon className="pr-1" color={colors.GREEN} name="clipboardCheck" type="outline" />
        {t('paymentPlan')}
      </Text>
    ),
  }
  const unselectMsg = (
    <Text className="text-gray-700 pt-2" size={sizes.SM}>
      {t('please')}
      <Text className="px-1 uppercase" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
        {t('unselect')}
      </Text>
      {t('anyInvoiceToContinue')}
    </Text>
  )
  const validMap = {
    invoicesFromDifferentContract: (
      <Flex className="p-2" column>
        <Text color={colors.GREY_700} size={sizes.SM}>
          {t('invoicesFromDifferentContract')}
        </Text>
        <Text className="text-gray-700 pt-2" size={sizes.SM}>
          {t('selectInvoicesForOneContract')}
        </Text>
      </Flex>
    ),
    invoicePaid: (
      <Flex className="p-2" column>
        <Text color={colors.GREY_700} size={sizes.SM}>
          {t('preventActionMessage', {
            actionName: t(actionKeyStringMap[actionKey]),
            partOf: t('pendingPayment'),
          })}
        </Text>
        {unselectMsg}
        {actionIconMap.invoicePaid}
      </Flex>
    ),
    invoicePromisedToPay: (
      <>
        <Flex className="p-2" column>
          <Text color={colors.GREY_700} size={sizes.SM}>
            {t('preventActionMessage', {
              actionName: t(actionKeyStringMap[actionKey]),
              partOf: t('p2p'),
            })}
          </Text>
          {unselectMsg}
          {actionIconMap.invoicePromisedToPay}
        </Flex>
      </>
    ),
    invoicePlannedToPay: (
      <>
        <Flex className="p-2" column>
          <Text color={colors.GREY_700} size={sizes.SM}>
            {t('preventActionMessage', {
              actionName: t(actionKeyStringMap[actionKey]),
              partOf: t('paymentPlan'),
            })}
          </Text>
          {unselectMsg}
          {actionIconMap.invoicePlannedToPay}
        </Flex>
      </>
    ),
  }
  return <div>{validMap[errorKey]}</div>
}

ValidationTooltips.propTypes = {
  errorKey: PT.string,
  actionKey: PT.string,
}

export default ValidationTooltips

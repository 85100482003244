import React from 'react'
import PropTypes from 'prop-types'
import { ProjectPartyQuery } from '../../../queries/projectParties/projectParty.gql'
import { LoadingSpinner } from '../../../ui-kit'
import ProjectPartyList from './ProjectPartyList'
import { useCustomQuery } from '../../../hooks/useCustomQuery'

const ViewProjectPartyModal = ({ projectPartyId, setIsOpenedModal, setIsSidebarOpened }) => {
  const queryVariables = {
    id: projectPartyId,
  }
  const { data, loading } = useCustomQuery({
    query: ProjectPartyQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !queryVariables.id,
    },
    rollbarOptions: { operationName: 'ProjectPartyQuery', target: 'ViewProjectPartyModal' },
  })

  return data && !loading ? (
    <ProjectPartyList
      data={data?.projectParty}
      setIsOpenedModal={setIsOpenedModal}
      setIsSidebarOpened={setIsSidebarOpened}
    />
  ) : (
    <LoadingSpinner loading={loading} />
  )
}

ViewProjectPartyModal.propTypes = {
  projectPartyId: PropTypes.string.isRequired,
  setIsOpenedModal: PropTypes.func.isRequired,
  setIsSidebarOpened: PropTypes.func.isRequired,
}

export default ViewProjectPartyModal

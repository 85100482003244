import React, { useState } from 'react'
import PT from 'prop-types'
import { Section, Heading, Flex, NarrowList, Text, LoadingSpinner, Icon } from '../../../../ui-kit'
import sizes from '../../../../ui-kit/sizes'
import colors from '../../../../ui-kit/colors'
import { AssignedRepsQuery } from '../../../../queries/assignedReps.gql'
import { DeleteAssignedRep } from '../../../../queries/mutations/assignedRep.gql'
import { useTranslation } from 'react-i18next'
import AddNewAssignedRepsModal from './AddNewAssignedRepsModal'
import Button from '../../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { useCustomQuery } from '../../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

const AssignedReps = ({ contractData }) => {
  const { t } = useTranslation()
  const [modalOpened, setModalOpened] = useState(false)

  const assignedRepsVariables = {
    contractId: contractData.id,
  }

  const { data: assignedRepsData, loading: assignedRepsLoading } = useCustomQuery({
    query: AssignedRepsQuery,
    queryOptions: {
      variables: assignedRepsVariables,
    },
    rollbarOptions: { operationName: 'AssignedRepsQuery', target: 'AssignedReps' },
  })

  const [deleteAssignedRep, { loading }] = useCustomMutation({
    mutation: DeleteAssignedRep,
    rollbarOptions: { operationName: 'DeleteAssignedRep', target: 'AssignedReps' },
    mutationOptions: {
      refetchQueries: [AssignedRepsQuery],
    },
  })

  const assignedReps = assignedRepsData?.assignedReps || []

  const handleDelete = (id) => {
    const variables = { id }
    deleteAssignedRep({ variables })
  }

  const renderAssignedRepsList = () => {
    const listItems = assignedReps.map(({ user, role, id }) => (
      <>
        <Flex basis="40%">
          <Text className="text-gray-500 font-semibold" size={sizes.SM} customColor>
            {t(`${role}`)}
          </Text>
        </Flex>
        <Flex basis="50%">
          <Text size={sizes.SM}>{user?.fullName}</Text>
        </Flex>
        <Flex basis="10%">
          <Icon
            color={colors.GREY}
            disabled={loading}
            name="trash"
            onClick={() => handleDelete(id)}
            size={sizes.LG}
            type="outline"
          />
        </Flex>
      </>
    ))

    return <NarrowList listItems={listItems} paddingsClasses="lg:py-5 md:py-3 flex" />
  }

  if (assignedRepsLoading) return <LoadingSpinner loading={assignedRepsLoading} />

  return (
    <>
      {modalOpened && (
        <AddNewAssignedRepsModal
          assignedReps={assignedReps}
          contractData={contractData}
          isOpened={modalOpened}
          setIsOpened={setModalOpened}
        />
      )}
      <Flex basis="40%" column>
        <Heading className="leading-8" isBold={false} size={sizes.XL2}>
          {t('assignedReps')}
        </Heading>
        <Section className="mt-4" withTitle>
          {renderAssignedRepsList()}
          <Button
            label={`+ ${t('addNew')}`}
            onClick={() => setModalOpened(true)}
            size={sizes.XS}
            testData="add-new-reps"
            variant={buttonsVariants.FOURTH}
          />
        </Section>
      </Flex>
    </>
  )
}

AssignedReps.propTypes = {
  contractData: PT.shape({
    id: PT.string,
    vendor: PT.shape({
      id: PT.string.isRequired,
    }),
  }).isRequired,
}

export default AssignedReps

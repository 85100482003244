import React from 'react'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import getNavigationMap from './navigationMap'

const Navigation = () => {
  const isCurrent = (item) => location.pathname.match(item.href)
  const currentUser = useCurrentUser()

  const activeLinkClasses = (item) =>
    cx(
      isCurrent(item) ? 'bg-primary-200 primary' : 'grey hover:bg-gray-50',
      'group hover:no-underline flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-gray-900',
    )

  return (
    <nav className="flex mt-2 lg:py-2 xl:space-x-8 md:space-x-2">
      {getNavigationMap(currentUser).map(
        (item) =>
          !item.hidden && (
            <NavLink
              className={activeLinkClasses(item)}
              id={item.href}
              key={item.name}
              to={`${item.href}`}>
              {item.name}
            </NavLink>
          ),
      )}
    </nav>
  )
}

export default Navigation

import React from 'react'
import PT from 'prop-types'
import fontWeight from '../../ui-kit/fontWeight'
import { Flex } from '../../ui-kit'
import Heading from '../../ui-kit/text/Heading'

const AsideContainer = ({ className, title, titleChild, children }) => {
  return (
    <div className={className}>
      <Flex row>
        <div className="lg:w-64 md:w-36 lg:pr-8 md:pr-2">
          <Heading className="mb-4 text-xl" fontWeight={fontWeight.MEDIUM}>
            {title}
          </Heading>

          {titleChild}
        </div>

        <div className="flex-1 w-full">{children}</div>
      </Flex>
    </div>
  )
}

AsideContainer.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  title: PT.string.isRequired,
  titleChild: PT.node,
}
AsideContainer.defaultProps = {
  className: '',
  titleChild: null,
}
export default AsideContainer

import { HIDE_FILTERS, SHOW_FILTERS } from '../reducers/filters'

const showFiltersAction = () => ({
  type: SHOW_FILTERS,
})

const hideFiltersAction = () => ({
  type: HIDE_FILTERS,
})

export { showFiltersAction, hideFiltersAction }

export const arReportsListMap = {
  AR_AGING_USER_DETAILS: 'arAgingUserDetails',
  AR_AGING_SUMMARY: 'arAgingSummary',
  AR_AGING_USER_SUMMARY: 'arAgingUserSummary',
  AR_AGING_LOCATION_SUMMARY: 'arAgingLocationSummary',
  INVOICE_LIST: 'invoiceList',
  DISPUTED_INVOICES: 'disputedInvoices',
  CREDITS: 'credits',
  DEPOSITS: 'deposits',
  NET_DUE_BY_CUSTOMER: 'netDueByCustomer',
}

export const arReports = {
  [arReportsListMap.AR_AGING_USER_DETAILS]: {
    jsonUrl: '/report_filters/aging_detail_by_customer.json',
    xlsxUrl: '/reports/aging_detail_by_customer.xlsx',
    fileNameKey: arReportsListMap.AR_AGING_USER_DETAILS,
  },
  [arReportsListMap.AR_AGING_SUMMARY]: {
    csvUrl: '/reports/aging_summary.csv?sort=total.desc',
    jsonUrl: '/reports/aging_summary.json?sort=total.desc',
    xlsxUrl: '/reports/aging_summary.xlsx?sort=total.desc',
    fileNameKey: arReportsListMap.AR_AGING_SUMMARY,
  },
  [arReportsListMap.AR_AGING_USER_SUMMARY]: {
    csvUrl: '/reports/aging_user_summary.csv?sort=total.desc',
    jsonUrl: '/reports/aging_user_summary.json?sort=total.desc',
    xlsxUrl: '/reports/aging_user_summary.xlsx?sort=total.desc',
    fileNameKey: arReportsListMap.AR_AGING_USER_SUMMARY,
  },
  [arReportsListMap.AR_AGING_LOCATION_SUMMARY]: {
    csvUrl: '/reports/aging_location_summary.csv?sort=total.desc',
    jsonUrl: '/reports/aging_location_summary.json?sort=total.desc',
    xlsxUrl: '/reports/aging_location_summary.xlsx?sort=total.desc',
    fileNameKey: arReportsListMap.AR_AGING_LOCATION_SUMMARY,
  },
  [arReportsListMap.INVOICE_LIST]: {
    csvUrl: '/reports/invoice_list.csv?sort=invoice_date.desc',
    jsonUrl: '/reports/invoice_list.json?sort=invoice_date.desc',
    xlsxUrl: '/reports/invoice_list.xlsx?sort=invoice_date.desc',
    fileNameKey: arReportsListMap.INVOICE_LIST,
  },
  [arReportsListMap.DISPUTED_INVOICES]: {
    csvUrl: '/reports/disputed_invoices.csv?sort=dispute_date.desc',
    jsonUrl: '/reports/disputed_invoices.json?sort=dispute_date.desc',
    xlsxUrl: '/reports/disputed_invoices.xlsx?sort=dispute_date.desc',
    fileNameKey: arReportsListMap.DISPUTED_INVOICES,
  },
  [arReportsListMap.CREDITS]: {
    csvUrl: '/reports/credits.csv?sort=credit_date.desc',
    jsonUrl: '/reports/credits.json?sort=credit_date.desc',
    xlsxUrl: '/reports/credits.xlsx?sort=credit_date.desc',
    fileNameKey: arReportsListMap.CREDITS,
  },
  [arReportsListMap.DEPOSITS]: {
    csvUrl: '/reports/deposits.csv?sort=deposit_date.desc',
    jsonUrl: '/reports/deposits.json?sort=deposit_date.desc',
    xlsxUrl: '/reports/deposits.xlsx?sort=deposit_date.desc',
    fileNameKey: arReportsListMap.DEPOSITS,
  },
  [arReportsListMap.NET_DUE_BY_CUSTOMER]: {
    jsonUrl: '/report_filters/net_due_by_customer.json',
    xlsxUrl: '/reports/net_due_by_customer.xlsx',
    fileNameKey: arReportsListMap.NET_DUE_BY_CUSTOMER,
  },
}

import React from 'react'
import { Flex, Money, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import sizes from '../../../ui-kit/sizes'
import activityType from '../../../types/activities'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { statusDisputeMapping } from '../../../utils/utils'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import ActivityCardStatus from './ActivityCardStatus'
import { getCreatedByEvent } from '../utils'

const DisputeActivity = ({ dispute }) => {
  const { t } = useTranslation()
  return (
    <Flex justifyContent="between">
      <Flex>
        <ActivityCardDate date={dispute?.createdAt} />
        <ActivityCardGeneralInfo
          detailsValue={getCreatedByEvent(dispute)}
          entityId={dispute.disputeId}
          entityLabelKey="invoiceDispute"
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="between">
        <Flex>
          <Flex shrink={0}>
            <Flex column>
              <Text color="text-black-500">{t('invoiceNumber')}:</Text>
              <Text color="text-black-500">{t('outstanding')}:</Text>
              <Text color="text-black-500">{t('reason')}:</Text>
            </Flex>

            <Flex className="ml-1" column>
              <Text>{dispute?.entity.invoice.invoiceNumber}</Text>
              <Text>
                <Money value={dispute?.entity.invoice.amountCents} />
              </Text>
              <Text size={sizes.SM}>{dispute?.entity.reason}</Text>
            </Flex>
          </Flex>
        </Flex>
        <ActivityCardStatus
          lastUpdatesDate={dispute.lastUpdateDate}
          statusNode={
            <StatusBadge
              color={statusDisputeMapping[dispute.entityActualEvent?.eventAction]?.color}
              value={t(statusDisputeMapping[dispute.entityActualEvent?.eventAction]?.label)}
            />
          }
        />
      </Flex>
    </Flex>
  )
}

export default DisputeActivity
DisputeActivity.propTypes = {
  dispute: activityType,
}

import React from 'react'
import { Flex, Money, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import getPresentationName, { getPresentationGuestName } from '../../../utils/getPresentationName'
import activityType from '../../../types/activities'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import { getCreatedByEvent } from '../utils'

const PaymentRequestActivity = ({ paymentRequest }) => {
  const { t } = useTranslation()
  const contacts = [...paymentRequest.recipientsViaEmail, ...paymentRequest.recipientsViaText]
  const outstandingRequested = paymentRequest.requestedAmountCents
  return (
    <Flex justifyContent="between">
      <Flex>
        <ActivityCardDate date={paymentRequest?.createdAt} />
        <ActivityCardGeneralInfo
          detailsValue={getCreatedByEvent(paymentRequest)}
          entityLabelKey="paymentRequest"
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="between">
        <Flex>
          <Flex shrink={0}>
            <Flex column>
              <Text className="mt-1" color="text-black-500">
                {t('sentTo')}:
              </Text>
              {contacts.slice(0, 2).map((contact) => (
                <Text key={contact?.id}>
                  {contact.user
                    ? getPresentationName(contact, t)
                    : getPresentationGuestName(contact?.email, contact?.formattedPhoneNumber, t)}
                </Text>
              ))}
              {contacts.length > 2 && <Text>+{t('additionalRecipients')}</Text>}
            </Flex>
          </Flex>
        </Flex>

        <Flex className="ml-4 w-[56%]" shrink={1} column>
          <Text color="text-black-500">{t('outstandingRequested')}</Text>
          <Money className="font-semibold text-xl" value={outstandingRequested} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PaymentRequestActivity

PaymentRequestActivity.propTypes = {
  paymentRequest: activityType,
}

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Icon, Money } from '../../../../ui-kit'
import sizes from '../../../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import FormattedDate from '../../../../ui-kit/components/text/FormattedDate'
import { InfoNote, Text } from '../../../../ui-kit'
import fontWeight from '../../../../ui-kit/fontWeight'
import DividedList from '../../../../ui-kit/components/sidebar/DividedList'
import MemoField from '../../../../ui-kit/components/inputs/MemoField'
import colors from '../../../../ui-kit/colors'
import { CreditReviewsQuery, CreditReviewQuery } from '../../../../queries/creditReviews.gql'
import { useNotifications } from '../../../../hooks/useNotifications'
import { UpdateCreditReviewMemo } from '../../../../queries/mutations/updateCreditReviewMemo.gql'
import { DeleteFileMutation } from '../../../../queries/files.gql'
import { useCustomQuery } from '../../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

const CreditReviewContent = ({
  data,
  setIsSidebarOpened,
  setIsAddCreditReviewDocumentModalOpened,
  setIsOpenedDeleteCreditReviewModal,
}) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()

  const queryVariables = {
    id: data?.id,
  }
  const { data: creditReviewDocumentsData } = useCustomQuery({
    query: CreditReviewQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !data?.id,
    },
    rollbarOptions: { operationName: 'CreditReviewQuery', target: 'CreditReviewContent' },
  })
  const creditReviewDocuments = creditReviewDocumentsData?.creditReview?.documents || []

  const descriptionData = [
    {
      label: t('date'),
      value: <FormattedDate date={data?.reviewDate} />,
    },
    {
      label: t('createdBy'),
      value: data?.createdBy?.fullName || '-',
    },
    { label: t('recommendedCreditLimit'), value: <Money value={data?.creditLimitCents} /> },
    {
      label: t('nextReviewDate'),
      value: <FormattedDate date={data?.nextReviewDate} />,
    },
  ]
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const [updateCreditReview, { loading: isUpdateCreditReviewLoading }] = useCustomMutation({
    mutation: UpdateCreditReviewMemo,
    rollbarOptions: { operationName: 'UpdateCreditReviewMemo', target: 'CreditReviewContent' },
    mutationOptions: {
      refetchQueries: [CreditReviewsQuery],
    },
  })

  const [deleteFile, { loading: isDeleteFileLoading }] = useCustomMutation({
    mutation: DeleteFileMutation,
    rollbarOptions: { operationName: 'DeleteFileMutation', target: 'CreditReviewContent' },
    mutationOptions: {
      refetchQueries: [CreditReviewQuery, CreditReviewsQuery],
    },
  })
  const handleDeleteDocument = useCallback((id) => {
    const variables = {
      id,
    }
    deleteFile({ variables }).then(({ data }) => {
      const responseData = data?.deleteFile || {}

      if (responseData?.entity) {
        newNotification({ success: t('documentDeletedSuccessfully') })
      }
    })
  }, [])

  return (
    <Flex column>
      <Flex column>
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
          {t('creditReview')}
        </Text>

        <InfoNote label={t('id')} size={sizes.SM}>
          {data.sequentialId}
        </InfoNote>
      </Flex>

      <DividedList content={descriptionData} />

      <div className="w-full mt-6">
        <MemoField
          closeForm={() => null}
          entityId={data.id}
          initialValue={data.memo}
          inputClassName="h-64"
          isFormDirty={memoFormIsDirty}
          isLoading={isUpdateCreditReviewLoading}
          labelKey="notes"
          placeholderKey="notes"
          responseEntityName={'updateCreditReview'}
          setDirtyFormState={setMemoFormIsDirty}
          updateEntity={updateCreditReview}
        />
      </div>

      <Flex className="mt-6" column>
        <Text fontWeight={fontWeight.MEDIUM}>{`${t('creditReviewDocuments')}:`}</Text>
        {creditReviewDocuments.map((document) => (
          <Flex alignItems="center" key={document.id}>
            <Button
              className="w-fit underline mt-3 text-left"
              key={document.id}
              label={document.name}
              onClick={() => window.open(document.url)}
              size={sizes.SM}
              testData="view-document"
              variant={buttonsVariants.LINK}
            />
            <Icon
              className="min-w-5 ml-2 mt-3"
              color={colors.GREY}
              disabled={isDeleteFileLoading}
              name="trash"
              onClick={() => handleDeleteDocument(document.id)}
            />
          </Flex>
        ))}
        <Button
          className="mt-4"
          iconName="plus"
          label={t('addNew')}
          onClick={() => {
            setIsSidebarOpened(false)
            setIsAddCreditReviewDocumentModalOpened(true)
          }}
          size={sizes.SM}
          testData="add-new-doc"
          variant={buttonsVariants.LINK}
        />
      </Flex>

      <Button
        className="mt-6"
        label={t('delete')}
        onClick={() => {
          setIsSidebarOpened(false)
          setIsOpenedDeleteCreditReviewModal(true)
        }}
        size={sizes.BASE}
        testData="delete-credit-review"
        variant={buttonsVariants.TERTIARY}
        fullWidth
      />
    </Flex>
  )
}

CreditReviewContent.propTypes = {
  data: PropTypes.object.isRequired,
  setIsSidebarOpened: PropTypes.func.isRequired,
  setIsOpenedDeleteCreditReviewModal: PropTypes.func.isRequired,
  setIsAddCreditReviewDocumentModalOpened: PropTypes.func.isRequired,
}

export default CreditReviewContent

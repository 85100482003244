import { Flex, InfoNote, Money, StatusBadge, Text, Tooltip } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import colors from '../../../ui-kit/colors'
import React, { useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import InvoiceActivities from '../invoiceActivity/InvoiceActivities'
import { getBillingContacts, recordTypes } from '../invoicesUtils'
import { useNavigate } from 'react-router-dom'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { UpdateInvoiceMemo } from '../../../queries/mutations/updateInvoiceMemo.gql'
import {
  InvoicesPaidQuery,
  InvoicesPaidSidebarQuery,
} from '../../../queries/invoices/invoicesPaid.gql'
import { chargebackLogo, fcIcon, refundLogo } from '../../../ui-kit/assets'
import ViewInvoiceLink from '../ViewInvoiceLink'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useCustomQuery } from '../../../hooks/useCustomQuery'

const SidebarPaidInvoicesContent = ({
  data,
  setIsRefundModalOpened,
  setIsSidebarOpened,
  contractId,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: sidebarQueryData } = useCustomQuery({
    query: InvoicesPaidSidebarQuery,
    queryOptions: {
      variables: { id: data?.id },
      skip: !data?.id,
    },
    rollbarOptions: {
      operationName: 'InvoiceOutstandingSidebarQuery',
      target: 'SidebarInvoicesContent',
    },
  })

  const sidebarData = sidebarQueryData?.invoice || {}

  const invoiceData = {
    id: data?.id,
    hashid: sidebarData?.hashid,
    recordType: sidebarData?.recordType,
    amountCents: sidebarData?.amountCents,
    activities: sidebarData?.activities,
    invoiceNumber: sidebarData?.invoiceNumber,
    customerId: sidebarData?.contract?.id,
    customerName: sidebarData?.contract?.buyer?.name,
    totalOutstanding: sidebarData?.outstandingAmountCents,
    projectId: sidebarData?.project?.id,
    projectStatus: sidebarData?.project?.status,
    project: sidebarData?.project?.name || '-',
    po: sidebarData?.po || '-',
    invoiceDate: <FormattedDate date={sidebarData?.issueDate} />,
    dueDate: <FormattedDate date={sidebarData?.maturityDate} />,
    paymentDate: <FormattedDate date={sidebarData?.paidWith?.paymentTransaction?.entryDate} />,
    daysPastDue: sidebarData?.aging,
    paymentPromise: <FormattedDate date={sidebarData?.paymentPromise?.date} />,
    status: true,
    paymentMethod: false,
    refund: false,
    billingContacts: getBillingContacts(sidebarData?.contract?.buyer?.users),
  }

  const descriptionData = [
    { label: t('location'), value: sidebarData?.location?.name || '-' },
    {
      label: t('customer'),
      node: (
        <Button
          label={invoiceData.customerName}
          onClick={() => navigate(`/customers/${invoiceData.customerId}/overview`)}
          size={sizes.SM}
          testData="customer-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    {
      label: t('project'),
      value: invoiceData.project === '-' && invoiceData.project,
      node: invoiceData.project !== '-' && (
        <Button
          className="overflow-hidden"
          label={invoiceData.project}
          onClick={() =>
            navigate(
              // eslint-disable-next-line max-len
              `/customers/${invoiceData.customerId}/projects/${invoiceData.projectStatus}?additionalFilters=id&filters=id.${invoiceData.projectId}&openSidebarParams=id.${invoiceData.projectId}`,
            )
          }
          size={sizes.SM}
          testData="project-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    { label: t('po'), value: invoiceData.po },
    { label: t('invoiceDate'), value: invoiceData.invoiceDate },
    { label: t('dueDate'), value: invoiceData.dueDate },
    { label: t('paidOn'), value: invoiceData.paymentDate },
  ]

  const billingData = invoiceData.billingContacts.map((contact) => [
    { label: t('name'), value: contact.fullName },
    { label: t('mobilePhone'), value: contact.formattedPhoneNumber },
    { label: t('officePhone'), value: contact.formattedOfficePhoneNumber },
    { label: t('email'), value: contact.email, color: colors.BLUE, email: true },
  ])

  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const [updateInvoice] = useCustomMutation({
    mutation: UpdateInvoiceMemo,
    mutationOptions: {
      refetchQueries: [InvoicesPaidQuery],
    },
    rollbarOptions: { operationName: 'UpdateInvoiceMemo', target: 'SidebarPaidInvoicesContent' },
  })

  return (
    <div className="flex flex-col mt-[-1.5rem]">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
            {t('invoicesDetails')}
          </Text>
          {invoiceData.recordType === recordTypes.FINANCE_CHARGE && (
            <div className="pl-2">
              <img alt="Suppli" className="h-5" src={fcIcon} />
            </div>
          )}
        </div>

        <div className="flex flex-row">
          <div className="mr-2">
            <InfoNote label={t('number')}>
              <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                {invoiceData.invoiceNumber}
              </Text>
            </InfoNote>
          </div>
          <ViewInvoiceLink id={invoiceData.hashid} />
        </div>
      </div>

      <div className="flex flex-row mt-3">
        <div className="w-6/12 flex flex-col">
          <SidebarLabel text="totalOutstanding" />
          <Money className="text-3xl mt-1" value={invoiceData.totalOutstanding} />
        </div>
        <div className="w-6/12 flex flex-col">
          <SidebarLabel text="status" />
          <div className="mt-2 flex items-center">
            <StatusBadge color={colors.GREEN} label={t('paid')} />
            {sidebarData?.refundsPresent && (
              <div>
                <Tooltip content={t('refunded')}>
                  <img alt="Suppli" className="h-6 ml-2" src={refundLogo} />
                </Tooltip>
              </div>
            )}
            {sidebarData?.chargebacksPresent && (
              <div>
                <Tooltip content={t('chargeback')}>
                  <img alt="Suppli" className="h-6 ml-2" src={chargebackLogo} />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>

      <DividedList content={descriptionData} />

      {!!billingData.length && (
        <div className="mt-5">
          <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.BASE}>
            {billingData.length > 1 ? t('billingContacts') : t('billingContact')}
          </Text>
        </div>
      )}

      {billingData.map((data) => (
        <DividedList content={data} key={data[0].value} />
      ))}

      <Flex className="text-black mt-6 mr-1 w-full" justifyContent={'between'}>
        <Button
          className={'mr-2'}
          label={t('issueRefund')}
          onClick={() => {
            setIsSidebarOpened(false)
            setIsRefundModalOpened(true)
          }}
          size={sizes.BASE}
          testData="issue-refund"
          variant={buttonsVariants.TERTIARY}
          fullWidth
        />
      </Flex>

      <MemoField
        closeForm={() => null}
        entityId={sidebarData?.id}
        initialValue={sidebarData?.memo}
        isFormDirty={memoFormIsDirty}
        labelKey="notesOptional"
        placeholderKey="notes"
        responseEntityName={'updateInvoice'}
        setDirtyFormState={setMemoFormIsDirty}
        updateEntity={updateInvoice}
      />

      <InvoiceActivities
        amountCents={invoiceData.amountCents}
        contractId={contractId}
        dueDate={invoiceData?.dueDate.props.date}
        invoiceData={invoiceData}
        invoiceDate={invoiceData.invoiceDate.props.date}
        invoiceId={invoiceData.id}
      />
    </div>
  )
}

SidebarPaidInvoicesContent.propTypes = {
  data: PT.object,
  contractId: PT.string,
  setIsSidebarOpened: PT.func,
  setIsRefundModalOpened: PT.func,
  setAssignProjectModalOpened: PT.func,
}

SidebarPaidInvoicesContent.defaultProps = {
  data: {},
  contractId: '',
}

export default SidebarPaidInvoicesContent

const contractActivitiesFilters = [
  {
    key: 'eventable_type',
    values: [
      'Dispute',
      'Note',
      'Payment',
      'PaymentPlan',
      'PaymentPromise',
      'PaymentRequest',
      'Deposit',
      'InvoiceRefund',
      'CreditReview',
    ],
  },
]
export default contractActivitiesFilters

import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import Textarea from './Textarea'
import { Button } from '../../index'
import sizes from '../../sizes'
import buttonsVariants from '../../buttonsVariants'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import PT from 'prop-types'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../alertModal/AlertModal'
import { idType } from '../../../types/defaultTypes'
import { useNotifications } from '../../../hooks/useNotifications'

const MemoField = ({
  initialValue,
  isFormDirty,
  setDirtyFormState,
  closeForm,
  entityId,
  updateEntity,
  responseEntityName,
  isDisabled,
  labelKey,
  placeholderKey,
  inputClassName,
  isLoading,
}) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()
  const onSubmit = (values) => {
    updateEntity({
      variables: {
        data: { memo: values.memo },
        id: entityId,
      },
    }).then(({ data }) => {
      const responseData = data?.[responseEntityName] || {}

      if (responseData?.entity) {
        newNotification({ success: t('memoWasAddedSuccessfully') })
        setDirtyFormState(false)
      }
    })
  }
  const initialValues = {
    memo: initialValue ? initialValue : '',
  }
  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirtyFieldsSinceLastSubmit, touched, dirty }) => {
          useBeforeUnload({ when: touched && dirty && dirtyFieldsSinceLastSubmit })
          touched &&
            dirtyFieldsSinceLastSubmit.memo !== isFormDirty &&
            setDirtyFormState(dirtyFieldsSinceLastSubmit.memo && dirty)

          return (
            <form className="flex flex-col mt-6" onSubmit={handleSubmit}>
              <Field name="memo">
                {({ input, meta }) => {
                  return (
                    <Textarea
                      {...input}
                      disabled={isDisabled}
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      inputClassName={cx('h-32 w-full', inputClassName)}
                      label={t(labelKey || 'memoOptional')}
                      name={input.name}
                      placeholder={t(placeholderKey || 'memo')}
                    />
                  )
                }}
              </Field>
              {!!isFormDirty && (
                <div>
                  <Button
                    className="mt-6"
                    disabled={isLoading}
                    label={t('save')}
                    size={sizes.BASE}
                    testData="submit-memo"
                    type="submit"
                    variant={buttonsVariants.SECONDARY}
                    fullWidth
                  />
                </div>
              )}
              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
      />
    </>
  )
}

MemoField.propTypes = {
  closeForm: PT.func.isRequired,
  isFormDirty: PT.bool.isRequired,
  initialValue: PropTypes.string,
  setDirtyFormState: PT.func.isRequired,
  entityId: idType,
  updateEntity: PT.func.isRequired,
  responseEntityName: PT.string.isRequired,
  isDisabled: PT.bool,
  labelKey: PT.string,
  placeholderKey: PT.string,
  inputClassName: PT.string,
  isLoading: PT.bool,
}

MemoField.defaultProps = {
  labelKey: '',
  isLoading: false,
  isDisabled: false,
  placeholderKey: '',
  inputClassName: '',
}

export default MemoField

import React from 'react'
import { Flex, Text } from '../ui-kit'
import { useTranslation } from 'react-i18next'
import { logo } from '../ui-kit/assets'
import { SUPPLI_SUPPORT_EMAIL } from '../constants/contacts'
import sizes from '../ui-kit/sizes'
import fontWeight from '../ui-kit/fontWeight'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" className="w-full h-screen bg-white" justifyContent="center" column>
      <Flex alignItems="center" className="px-12" column>
        <img alt="Suppli" className="h-10 mb-4" src={logo} />
        <Text align="center" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL3}>
          {t('pageNotFound')}
        </Text>
        <Text align="center" className="mt-4" size={sizes.BASE}>
          {t('thePageDoesntExist')}
        </Text>
        <Text align="center" className="mt-4">
          {t('contactSuppliSupport')}:
        </Text>
        <a className="blue" href={`mailto:${SUPPLI_SUPPORT_EMAIL}`}>
          {SUPPLI_SUPPORT_EMAIL}
        </a>
      </Flex>
    </Flex>
  )
}

export default NotFound

import React from 'react'
import { Flex, Money, Text } from '../../../ui-kit'
import activityType from '../../../types/activities'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import { getCreatedByEvent } from '../utils'

const CreditReviewActivity = ({ creditReview }) => {
  return (
    <Flex justifyContent="between">
      <Flex>
        <ActivityCardDate date={creditReview?.createdAt} />
        <ActivityCardGeneralInfo
          detailsValue={getCreatedByEvent(creditReview)}
          entityId={creditReview.sequentialId}
          entityLabelKey="creditReview"
          secondaryDetailsValue={<Money value={creditReview.creditLimitCents} />}
          secondaryDetailsValueLabelKey="creditLimit"
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="end">
        <Text className="w-full line-clamp-3">{creditReview.memo}</Text>
      </Flex>
    </Flex>
  )
}

export default CreditReviewActivity

CreditReviewActivity.propTypes = {
  creditReview: activityType,
}

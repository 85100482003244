import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  Flex,
  Input,
  Money,
  PageDialog,
  Tabs,
  Text,
  Tooltip,
} from '../../../ui-kit'
import { steps } from './utils'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { Field, Form } from 'react-final-form'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import colors from '../../../ui-kit/colors'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { useLazyQuery } from '@apollo/react-hooks'
import { InvoicesOutstandingForPayment } from '../../../queriesUpdated/queries/invoiceOutstanding.gql'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import { parseWithMoneyFormat } from '../../../ui-kit/components/text/Money'
import { getOnlyNumbers } from '../../../utils/utils'
import AddMemoForm from '../addMemoForm/AddMemoForm'
import StyledContainer from '../../../ui-kit/components/styledContainer/StyledContainer'
import PaymentMethodsView from '../../../components/paymentType/PaymentMethodsView'
import { paymentMethodsEligibilityEnum } from '../../../constants/paymentMethodEligibilityEnum'
import { validateRequiredField } from '../../../utils/validators'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { CreditsDepositsQuery } from '../../../queriesUpdated/queries/common.gql'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { FORM_ERROR } from 'final-form'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import { paymentMethodTypes, paymentResultMods } from '../../../constants/paymentResults'
import { DateTime } from 'luxon'
import { getFinancialEntitiesOptions } from '../../../components/financialEntity/utils'
import { FinancialEntitiesQuery } from '../../../queriesUpdated/queries/financialEntity.gql'
import {
  getDifDays,
  getFormattedDate,
  getLuxonDate,
  SERVER_DATE,
  SERVER_DATE_FORMAT,
} from '../../../ui-kit/utils/dateUtils'
import rollbar from 'rollbar'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { CreatePaymentMutation } from '../../../queriesUpdated/mutations/createPayment.gql'
import { ContractQuery } from '../../../queries/contracts.gql'
import { VendorQuery } from '../../../queries/vendors.gql'
import { PaymentTransactionProgressQuery } from '../../../queriesUpdated/queries/paymentTransaction.gql'
import PaymentResult from '../../../components/paymentResult/PaymentResult'
import { getTransactionPaymentResult } from '../../../utils/paymentResultsUtils'
import { fcIcon } from '../../../ui-kit/assets'
import { MONEY } from '../../../utils/dataTypes'
import {
  checkSupportPartialDiscount,
  getCombinedErrors,
  getConvenienceFee,
  getInvoicesWithUpdatedDiscounts,
  PAYMENT,
  recordTypes,
} from '../invoicesUtils'

const ReceivePaymentModal = ({
  invoicesIds,
  isOpened,
  setIsOpened,
  onClose,
  onSuccessSubmit,
  contractID,
  customerName,
  sort,
  withPartialPayment,
}) => {
  const { t } = useTranslation()
  const formAPI = useRef(null)
  const currentUser = useCurrentUser()
  const [step, setStep] = useState(steps.FIRST)
  const [invoices, setInvoices] = useState([])
  const [activeTab, setActiveTab] = useState('discounts')
  const [isAddMemoModalOpened, setIsAddMemoModalOpened] = useState(false)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [isReceivePaymentButtonDisabled, setIsReceivePaymentButtonDisabled] = useState(false)
  const [financialEntitiesOptions, setFinancialEntitiesOptions] = useState([])
  const [paymentResult, setPaymentResult] = useState(null)
  const [paymentTransactionId, setPaymentTransactionId] = useState(null)
  const [paymentStatusRefreshCounter, setPaymentStatusRefreshCounter] = useState(0)
  const [failureReason, setFailureReason] = useState(null)
  const [contractCreditCardFeeEnabled, setContractCreditCardFeeEnabled] = useState(false)
  const isSupportPartialDiscount = currentUser.erpMetadata?.isSupportPartialDiscount

  // Payment mutations and status validation
  const [createPayment, { loading: requestInProgress }] = useCustomMutation({
    mutation: CreatePaymentMutation,
    rollbarOptions: {
      operationName: 'CreatePaymentMutation',
      target: 'ReceivePaymentModal',
    },
    mutationOptions: {
      refetchQueries: [ContractQuery, VendorQuery],
    },
  })
  const { refetch: refetchPayment } = useCustomQuery({
    query: PaymentTransactionProgressQuery,
    queryOptions: {
      variables: {
        id: paymentTransactionId,
      },
      skip: !paymentTransactionId,
    },
    rollbarOptions: {
      operationName: 'PaymentTransactionProgressQuery',
      target: 'ReceivePaymentModal',
    },
  })
  useEffect(async () => {
    if (paymentStatusRefreshCounter === 0) {
      return
    }

    if (!paymentTransactionId) {
      setPaymentResult(paymentResultMods.SERVER_ERROR)

      return
    }

    const { data: loadedData } = await refetchPayment()
    const paymentTransaction = loadedData?.paymentTransaction

    const transactionPaymentResult = getTransactionPaymentResult(
      paymentTransaction,
      setFailureReason,
    )

    if (transactionPaymentResult) {
      setPaymentResult(transactionPaymentResult)
      return
    }

    const timeoutId = setTimeout(() => {
      setPaymentStatusRefreshCounter((prevState) => prevState + 1)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [paymentStatusRefreshCounter, refetchPayment, paymentTransactionId])

  // Financial entity
  const onCompletedFinancialEntities = useCallback((response) => {
    const options = getFinancialEntitiesOptions(response?.financialEntities)
    setFinancialEntitiesOptions(options)
  }, [])
  const { loading: financialEntitiesLoading, refetch: refetchFinancialEntities } = useCustomQuery({
    query: FinancialEntitiesQuery,
    onCompleted: onCompletedFinancialEntities,
    queryOptions: {
      skip: !isOpened,
    },
    rollbarOptions: {
      operationName: 'FinancialEntitiesQuery',
      target: 'ReceivePaymentModal',
    },
  })

  // Invoice block
  const queryVariables = {
    filters: [
      {
        key: 'id',
        values: [...invoicesIds],
      },
    ],
    sort,
    withPaymentMethodDiscount: true,
    perPage: invoicesIds.length,
    contractId: contractID,
  }
  const [loadInvoices, { loading: invoicesQueryInProgress }] = useLazyQuery(
    InvoicesOutstandingForPayment,
    {
      variables: queryVariables,
      fetchPolicy: 'network-only',
      skip: true,
      onCompleted: (data) => {
        setInvoices(data?.invoices?.data)
        setContractCreditCardFeeEnabled(data?.contract?.paymentSettings?.creditCardFeeEnabled)
      },
    },
  )
  useEffect(() => {
    loadInvoices()
  }, [])
  const getInvoicesRows = (fields) =>
    fields?.map((name, index) => {
      const invoice = fields.value[index]
      return {
        fieldName: name,
        invoiceIndex: index,
        ...invoice,
      }
    }) || []
  const getInvoicesColumns = (form) => [
    {
      field: 'issueDate',
      headerName: t('invoiceDate'),
      renderCell: (values) => (
        <Text className="pt-1" color="text-warmBlack-500">
          <FormattedDate date={values?.row?.issueDate} />
        </Text>
      ),
      flex: 1.5,
      sortable: false,
    },
    {
      field: 'invoiceNumber',
      headerName: t('number'),
      renderCell: (values) => <span className="pt-1">{values?.row?.invoiceNumber}</span>,
      flex: 2,
      sortable: false,
    },
    {
      field: 'fcIcon',
      headerName: '',
      sortable: false,
      renderCell: (values) =>
        values.row.recordType === recordTypes.FINANCE_CHARGE && (
          <Tooltip content={t('financeCharges')}>
            <img alt="Suppli" className="h-5 mt-1" src={fcIcon} />
          </Tooltip>
        ),
      width: 40,
    },
    {
      field: 'project',
      headerName: t('project'),
      renderCell: (values) => (
        <Text className="pt-1" color={colors.GRAY_500}>
          {values?.row?.project?.name}
        </Text>
      ),
      flex: 3,
      sortable: false,
    },
    {
      field: 'po',
      headerName: t('po'),
      renderCell: (values) => <span className="pt-1">{values?.row?.po}</span>,
      flex: 1.5,
      sortable: false,
    },
    {
      field: 'maturityDate',
      headerName: t('dueDate'),
      renderCell: (values) => (
        <Text className="pt-1" color="text-warmBlack-500">
          <FormattedDate date={values?.row?.maturityDate} />
        </Text>
      ),
      width: 100,
      sortable: false,
    },
    {
      field: 'amountCents',
      headerName: t('invoiced'),
      renderCell: (values) => <Money className="pt-1" value={values?.row?.amountCents} />,
      filterId: 'invoicedAmount',
      filterDataType: MONEY,
      width: 150,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'outstandingAmountCents',
      headerName: t('outstanding'),
      renderCell: (values) => (
        <Money className="pt-1" value={values?.row?.outstandingAmountCents} />
      ),
      width: 150,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'paymentCents',
      headerName: t('payment'),
      renderCell: (values) =>
        withPartialPayment ? (
          <Field name={`${values?.row?.fieldName}.paymentCents`}>
            {({ input, meta }) => (
              <CurrencyInput
                error={meta.error}
                id={`${values?.row?.id}-paymentCents`}
                onChange={(e) => {
                  const value = e.target.value
                  const newValue = Math.round(value * 100)
                  const newPaymentPercent = parseInt(
                    Math.fround((newValue * 100) / values?.row?.balanceDueCents),
                  )

                  const appliedDiscountAmountCents = checkSupportPartialDiscount(
                    isSupportPartialDiscount,
                    values?.row?.discountAmountCents,
                    {
                      paymentCents: newValue,
                      outstandingAmountCents: values?.row?.outstandingAmountCents,
                    },
                  )

                  form.mutators.update('invoices', values?.row?.invoiceIndex, {
                    ...values?.row,
                    paymentCents: newValue,
                    paymentPercent: newPaymentPercent,
                    appliedDiscountAmountCents,
                  })
                }}
                testData="payment-input"
                value={input.value / 100}
                isTableInput
              />
            )}
          </Field>
        ) : (
          <Money value={values?.row?.paymentCents} />
        ),
      width: 150,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'paymentPercent',
      headerName: '',
      renderCell: (values) =>
        withPartialPayment ? (
          <Field name={`${values?.row?.fieldName}.paymentPercent`}>
            {({ input, meta }) => (
              <Input
                className="relative"
                error={meta.error}
                icon={<div>%</div>}
                id={`${values?.row?.id}-paymentPercent`}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    form.mutators.update('invoices', values?.row?.invoiceIndex, {
                      ...values?.row,
                      paymentCents: 0,
                      paymentPercent: 0,
                    })
                  }
                }}
                onChange={(e) => {
                  let newPaymentPercent = getOnlyNumbers(e.target.value)

                  if (newPaymentPercent > 100) {
                    newPaymentPercent = 100
                  }

                  const newPaymentCents = Math.round(
                    (newPaymentPercent * values?.row?.balanceDueCents) / 100,
                  )
                  const appliedDiscountAmountCents = checkSupportPartialDiscount(
                    isSupportPartialDiscount,
                    values?.row?.discountAmountCents,
                    {
                      paymentCents: newPaymentCents,
                      outstandingAmountCents: values?.row?.outstandingAmountCents,
                    },
                  )

                  form.mutators.update('invoices', values?.row?.invoiceIndex, {
                    ...values?.row,
                    paymentCents: newPaymentCents,
                    paymentPercent: newPaymentPercent,
                    appliedDiscountAmountCents,
                  })
                }}
                testData="payment-percent-input"
                value={input.value}
                isTableInput
              />
            )}
          </Field>
        ) : (
          <Text color={colors.GRAY_500}>{values?.row?.paymentPercent}%</Text>
        ),
      width: 78,
      sortable: false,
    },
  ]

  // Discounts, Credits and Deposits block
  const {
    data: creditsDeposits,
    loading: creditsDepositsLoading,
    refetch: refetchCreditsDeposits,
  } = useCustomQuery({
    query: CreditsDepositsQuery,
    queryOptions: {
      skip: !contractID,
      variables: {
        filtersDeposits: [
          { key: 'contract_id', values: [contractID] },
          { key: 'outstanding_amount', values: ['1', ''] },
          { key: 'readonly', values: 'false' },
        ],
        filtersCredits: [
          { key: 'contract_id', values: [contractID] },
          { key: 'outstanding_amount', values: ['1', ''] },
          { key: 'readonly', values: 'false' },
        ],
      },
    },
    rollbarOptions: {
      operationName: 'CreditsDepositsQuery',
      target: 'ReceivePaymentModal',
    },
  })
  const getCreditsDepositsRows = (fields) =>
    fields?.map((name, index) => {
      const invoice = fields.value[index]
      return {
        fieldName: name,
        entityIndex: index,
        ...invoice,
      }
    }) || []
  const getCreditsDepositsColumns = (form) => [
    {
      field: 'entityTypeAndAmount',
      headerName: '',
      renderCell: (values) => (
        <Flex className="h-full justify-evenly text-ellipsis overflow-hidden" column>
          <Tooltip content={values?.row?.memo}>
            <Text>{`${t(values?.row?.type)}: ${values?.row?.displayId}`}</Text>
          </Tooltip>
          <Tooltip content={values?.row?.project?.name}>
            <Text color="text-warmBlack-400 text-ellipsis overflow-hidden block max-w-[16rem]">{`${t(
              'project',
            )}: ${values?.row?.project?.name || '-'}`}</Text>
          </Tooltip>
          <Text color="text-warmBlack-400">
            {t('availableAmountHint', {
              amount: parseWithMoneyFormat(values?.row?.outstandingAmountCents),
            })}
          </Text>
        </Flex>
      ),
      flex: 3,
      sortable: false,
    },
    {
      field: 'enablingToggle',
      headerName: '',
      renderCell: (values) => (
        <Flex alignItems="center" className="h-full">
          <Field name={`${values?.row?.fieldName}.isEntityApplied`}>
            {({ input }) => (
              <Toggle
                className="pt-0"
                handleChange={(state) => {
                  if (!state) {
                    form.mutators.update('creditsDeposits', values?.row?.entityIndex, {
                      ...values?.row,
                      isEntityApplied: state,
                      appliedAmountCents: 0,
                    })
                    return
                  }

                  const formValues = form.getState().values
                  const paymentSummary = getPaymentSummary(formValues)
                  const targetEntity = formValues.creditsDeposits[values?.row?.entityIndex]

                  let appliedAmountCents

                  if (
                    values?.row?.type === 'credit' &&
                    !currentUser.erpMetadata?.partialCreditApplicationEnabled
                  ) {
                    appliedAmountCents = targetEntity.outstandingAmountCents
                  } else if (
                    paymentSummary.netPaymentCents - targetEntity.outstandingAmountCents >=
                    0
                  ) {
                    appliedAmountCents = targetEntity.outstandingAmountCents
                  } else {
                    appliedAmountCents =
                      paymentSummary.netPaymentCents > 0 ? paymentSummary.netPaymentCents : 0
                  }

                  form.mutators.update('creditsDeposits', values?.row?.entityIndex, {
                    ...values?.row,
                    isEntityApplied: state,
                    appliedAmountCents,
                  })
                }}
                testData="entity-toggle"
                value={input.value}
              />
            )}
          </Field>
        </Flex>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: 'appliedAmountCents',
      headerName: '',
      renderCell: (values) => (
        <Flex alignItems="center" className="h-full w-full" justifyContent="end">
          <Field name={`${values?.row?.fieldName}.appliedAmountCents`}>
            {({ input, meta }) =>
              values?.row?.type === 'credit' &&
              !currentUser.erpMetadata?.partialCreditApplicationEnabled ? (
                <Money className="items-center" value={input.value} />
              ) : (
                <CurrencyInput
                  disabled={!values?.row?.isEntityApplied}
                  error={meta.error}
                  id={`${values?.row?.id}-appliedAmountCents`}
                  onChange={(e) => {
                    const value = e.target.value
                    const newValue = Math.round(value * 100)
                    input.onChange(newValue)
                  }}
                  testData="entity-input"
                  value={input.value / 100}
                  isTableInput
                />
              )
            }
          </Field>
        </Flex>
      ),
      flex: 1,
      sortable: false,
    },
  ]
  const discountsCreditsDepositsTabsMap = ['discounts', 'creditsDeposits'].map((tab) => ({
    label: t(tab),
    active: activeTab === tab,
    onClick: () => setActiveTab(tab),
    testData: `tab-${tab}`,
  }))
  const getDiscountsColumns = (form) => [
    {
      field: 'invoiceNumber',
      headerName: t('number'),
      renderCell: (values) => <span className="pt-1">{values?.row?.invoiceNumber}</span>,
      flex: 1,
      sortable: false,
    },
    {
      field: 'discountCutoffDate',
      headerName: t('discDate'),
      renderCell: (values) => (
        <div className="pt-1">
          <FormattedDate date={values?.row?.discountCutoffDate} />
        </div>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: 'discountAmountCents',
      headerName: t('discAvl'),
      renderCell: (values) => <Money className="pt-1" value={values?.row?.discountAmountCents} />,
      flex: 1,
      sortable: false,
      hide: withPartialPayment,
    },
    {
      field: 'paymentCents',
      headerName: t('payment'),
      renderCell: (values) => <Money className="pt-1" value={values?.row?.paymentCents} />,
      flex: 1,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      hide: !withPartialPayment,
    },
    {
      field: 'appliedDiscountAmountCents',
      headerName: t('applied'),
      renderCell: (values) =>
        withPartialPayment && isSupportPartialDiscount ? (
          values?.row?.discountAmountCents ? (
            <Field name={`${values?.row?.fieldName}.appliedDiscountAmountCents`}>
              {({ input, meta }) => (
                <CurrencyInput
                  className="ml-1"
                  error={meta.error}
                  helperText={t('availableAmountHint', {
                    amount: parseWithMoneyFormat(values?.row?.discountAmountCents),
                  })}
                  id={`${values?.row?.id}-appliedDiscountAmountCents`}
                  onChange={(e) => {
                    const value = e.target.value
                    const updatedAppliedDiscountAmountCents = Math.round(value * 100)
                    form.mutators.update('invoices', values?.row?.invoiceIndex, {
                      ...values?.row,
                      appliedDiscountAmountCents: updatedAppliedDiscountAmountCents,
                    })
                  }}
                  testData="discounts-input"
                  value={input.value / 100}
                  isTableInput
                />
              )}
            </Field>
          ) : (
            <span>—</span>
          )
        ) : (
          <Money className="pt-1" value={values?.row?.appliedDiscountAmountCents} />
        ),
      width: 140,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
    },
  ]

  // Payment summary
  const getPaymentSummary = (values) => {
    if (step !== steps.SECOND) {
      return {
        netPaymentCents: 0,
        anyDiscountExists: false,
        totalPaymentCents: 0,
        creditCardFeeCents: 0,
        anyCreditsDepositsExists: false,
        totalAppliedFinanceCharges: 0,
        totalOutstandingAmountCents: 0,
        totalAppliedCreditAmountCents: 0,
        totalAppliedDepositAmountCents: 0,
        totalAppliedDiscountAmountCents: 0,
      }
    }

    const {
      anyDiscountExists,
      totalPaymentCents,
      totalAppliedFinanceCharges,
      totalOutstandingAmountCents,
      totalAppliedDiscountAmountCents,
    } = values.invoices.reduce(
      (acc, invoice) => {
        if (invoice.discountAmountCents) {
          acc.anyDiscountExists = true
        }

        acc.totalPaymentCents += invoice.paymentCents
        if (invoice.recordType === recordTypes.FINANCE_CHARGE) {
          acc.totalAppliedFinanceCharges += invoice.paymentCents
        }
        if (invoice.recordType === recordTypes.INVOICE) {
          acc.totalOutstandingAmountCents += invoice.paymentCents
        }
        acc.totalAppliedDiscountAmountCents += invoice.appliedDiscountAmountCents
        return acc
      },
      {
        anyDiscountExists: false,
        totalPaymentCents: 0,
        totalAppliedFinanceCharges: 0,
        totalOutstandingAmountCents: 0,
        totalAppliedDiscountAmountCents: 0,
      },
    )

    const {
      anyCreditsDepositsExists,
      totalAppliedCreditAmountCents,
      totalAppliedDepositAmountCents,
    } = values.creditsDeposits.reduce(
      (acc, entity) => {
        if (entity.outstandingAmountCents) {
          acc.anyCreditsDepositsExists = true
        }

        if (entity.isEntityApplied && entity.type === 'credit') {
          acc.totalAppliedCreditAmountCents += entity.appliedAmountCents
        }

        if (entity.isEntityApplied && entity.type === 'deposit') {
          acc.totalAppliedDepositAmountCents += entity.appliedAmountCents
        }

        return acc
      },
      {
        anyCreditsDepositsExists: false,
        totalAppliedCreditAmountCents: 0,
        totalAppliedDepositAmountCents: 0,
      },
    )

    const netPaymentCents =
      totalPaymentCents -
      totalAppliedDiscountAmountCents -
      totalAppliedCreditAmountCents -
      totalAppliedDepositAmountCents

    const convenienceFeeData = {
      paymentSettings: currentUser?.paymentSettings,
      contractCreditCardFeeEnabled,
      amount: netPaymentCents,
      invoices: values.invoices,
      totalCreditDeposit: totalAppliedCreditAmountCents + totalAppliedDepositAmountCents,
      type: PAYMENT,
    }
    const creditCardFeeCents =
      values.paymentMethod?.type === paymentMethodTypes.CREDIT_CARD_PAYMENT_METHOD &&
      values.paymentMethod?.data?.['credit-card']
        ? getConvenienceFee(convenienceFeeData)
        : 0

    return {
      netPaymentCents,
      anyDiscountExists,
      totalPaymentCents,
      creditCardFeeCents,
      anyCreditsDepositsExists,
      totalAppliedFinanceCharges,
      totalOutstandingAmountCents,
      totalAppliedCreditAmountCents,
      totalAppliedDepositAmountCents,
      totalAppliedDiscountAmountCents,
    }
  }
  const getPaymentDescriptionData1 = (paymentSummary) => [
    {
      label: t('osPaid'),
      value: <Money value={paymentSummary.totalOutstandingAmountCents} />,
    },
    {
      label: `(+) ${t('financeCharges')}`,
      value: <Money value={paymentSummary.totalAppliedFinanceCharges} />,
    },
  ]
  const getPaymentDescriptionData2 = (paymentSummary) => [
    {
      label: `(-) ${t('discountsApplied')}`,
      value: <Money value={paymentSummary.totalAppliedDiscountAmountCents} />,
    },
    {
      label: `(-) ${t('depositsApplied')}`,
      value: <Money value={paymentSummary.totalAppliedDepositAmountCents} />,
    },
    {
      label: `(-) ${t('creditsApplied')}`,
      value: <Money value={paymentSummary.totalAppliedCreditAmountCents} />,
    },
  ]
  const getPaymentDescriptionData3 = (paymentSummary) => [
    {
      label: `(+) ${t('cardConvenienceFee')}`,
      value: <Money value={paymentSummary.creditCardFeeCents} />,
    },
  ]

  // Component logic
  const initialValues = useMemo(() => {
    const { deposits, credits } = creditsDeposits || {}
    const extendedCredits =
      credits?.data?.map((credit) => ({
        ...credit,
        type: 'credit',
        displayId: credit.referenceId,
        isEntityApplied: false,
        appliedAmountCents: 0,
      })) || []
    const extendedDeposits =
      deposits?.data.map((deposit) => ({
        ...deposit,
        type: 'deposit',
        displayId: currentUser.erpMetadata?.supportDeposits ? deposit.id : deposit.referenceId,
        isEntityApplied: false,
        appliedAmountCents: 0,
      })) || []
    const paymentDate = DateTime.now().toJSDate()

    return {
      memo: '',
      invoices: invoices.map((invoice) => {
        const paymentCents = invoice?.outstandingAmountCents

        return {
          paymentCents,
          paymentPercent: 100,
          balanceDueCents: paymentCents,
          appliedDiscountAmountCents: 0,
          ...invoice,
        }
      }),
      paymentDate,
      paymentMethod: null,
      creditsDeposits: [...extendedCredits, ...extendedDeposits],
      financialEntityId: null,
    }
  }, [invoices, creditsDeposits])
  const handleClose = () => {
    onClose?.()
  }
  const getModalTitle = (step) => (
    <Text fontWeight={fontWeight.BOLD} size={sizes.XL}>
      {t('makePayment')} ({t('stepText', { current: step, max: 2 })}) |{' '}
      <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
        {customerName}
      </Text>
    </Text>
  )
  const handleValidateForm = (values) => {
    let formError
    const paymentSummary = getPaymentSummary(values)
    const {
      totalPaymentCents,
      totalAppliedDiscountAmountCents,
      totalAppliedCreditAmountCents,
      totalAppliedDepositAmountCents,
    } = paymentSummary

    if (
      totalPaymentCents -
        totalAppliedDiscountAmountCents -
        totalAppliedCreditAmountCents -
        totalAppliedDepositAmountCents <
      0
    ) {
      formError = t('invalidPaymentReceivedNew')
    } else if (
      paymentSummary.netPaymentCents === 0 &&
      !currentUser.erpMetadata?.zeroNetPaymentAllowed
    ) {
      formError = t('zeroPaymentValidationNew')
    } else if (
      values.paymentMethod?.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD &&
      !values.financialEntityId
    ) {
      formError = t('depositToNotSelected')
    }

    return {
      [FORM_ERROR]: formError,
      paymentMethod: validateRequiredField(values.paymentMethod),
      financialEntityId:
        values.paymentMethod?.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
          ? validateRequiredField(values.financialEntityId)
          : void 0,
    }
  }
  const handleValidateInvoices = (values) => {
    const errors = values.map((invoice) => {
      const invoiceErrors = {}

      if (step === steps.FIRST) {
        if (invoice.paymentCents > invoice.balanceDueCents) {
          invoiceErrors.paymentCents = t('invalidPaymentAmount')
        } else if (invoice.paymentCents === 0) {
          invoiceErrors.paymentCents = t('paymentAmountValidation')
        } else if (
          !isSupportPartialDiscount &&
          invoice.outstandingAmountCents !== invoice.paymentCents &&
          invoice.discountAmountCents > invoice.outstandingAmountCents - invoice.paymentCents &&
          (!invoice.discountCutoffDate ||
            getDifDays(
              DateTime.now(),
              getLuxonDate(invoice.discountCutoffDate, SERVER_DATE, rollbar),
            ) < 1)
        ) {
          invoiceErrors.paymentCents = t('paymentCanBeGreaterAvailableDiscount', {
            amount: parseWithMoneyFormat(invoice.discountAmountCents),
          })
        }
      }

      if (step === steps.SECOND) {
        if (invoice.appliedDiscountAmountCents > invoice.discountAmountCents) {
          invoiceErrors.appliedDiscountAmountCents = t('invalidDiscount')
        } else if (invoice.appliedDiscountAmountCents > invoice.paymentCents) {
          invoiceErrors.appliedDiscountAmountCents = t('invalidDiscountPayment')
        }
      }

      return Object.keys(invoiceErrors).length ? invoiceErrors : undefined
    })

    return errors.filter(Boolean).length ? errors : undefined
  }
  const validateNavigationButtonsState = (formErrors) => {
    const isPaymentMethodInvalid = !!formErrors.paymentMethod
    const isFinancialEntityIdInvalid = !!formErrors.financialEntityId
    const areCreditsDepositsInvalid = !!formErrors.creditsDeposits
    const { isInvoicePenaltyInvalid, isInvoicePaymentInvalid, isInvoiceDiscountInvalid } =
      formErrors.invoices?.filter(Boolean)?.reduce(
        (acc, invoiceErrors) => {
          if (invoiceErrors.paymentCents) {
            acc.isInvoicePaymentInvalid = true
          }

          if (invoiceErrors.appliedDiscountAmountCents) {
            acc.isInvoiceDiscountInvalid = true
          }

          return acc
        },
        {
          isInvoicePenaltyInvalid: false,
          isInvoicePaymentInvalid: false,
          isInvoiceDiscountInvalid: false,
        },
      ) || {
        isInvoicePenaltyInvalid: false,
        isInvoicePaymentInvalid: false,
        isInvoiceDiscountInvalid: false,
      }

    const isFirstStepError = isInvoicePenaltyInvalid || isInvoicePaymentInvalid

    if (
      (!isFirstStepError && isNextButtonDisabled) ||
      (isFirstStepError && !isNextButtonDisabled)
    ) {
      setIsNextButtonDisabled(isFirstStepError)
    }

    const isSecondStepError =
      isFinancialEntityIdInvalid ||
      isPaymentMethodInvalid ||
      isInvoiceDiscountInvalid ||
      areCreditsDepositsInvalid ||
      formErrors[FORM_ERROR]

    if (
      (!isSecondStepError && isReceivePaymentButtonDisabled) ||
      (isSecondStepError && !isReceivePaymentButtonDisabled)
    ) {
      setIsReceivePaymentButtonDisabled(isSecondStepError)
    }
  }
  const handleValidateCreditsDeposits = (values) => {
    const errors = values.map((entity) => {
      const entityErrors = {}

      if (entity.appliedAmountCents > entity.outstandingAmountCents) {
        entityErrors.appliedAmountCents =
          entity.type === 'credit' ? t('invalidCreditTotal') : t('invalidDepositTotal')
      } else if (entity.isEntityApplied && entity.appliedAmountCents == 0) {
        entityErrors.appliedAmountCents = t('appliedAmountValidation')
      }

      return Object.keys(entityErrors).length ? entityErrors : undefined
    })

    return errors.filter(Boolean).length ? errors : undefined
  }
  const handleSubmit = () => {
    const values = formAPI.current.getState().values
    const paymentSummary = getPaymentSummary(values)
    const { creditsData, depositsData } = values.creditsDeposits.reduce(
      (acc, entity) => {
        if (!entity.isEntityApplied) {
          return acc
        }

        const entityData = {
          id: entity.id,
          appliedAmountCents: entity.appliedAmountCents,
        }

        if (entity.type === 'credit') {
          acc.creditsData.push(entityData)
        } else if (entity.type === 'deposit') {
          acc.depositsData.push(entityData)
        }

        return acc
      },
      { creditsData: [], depositsData: [] },
    )
    const variables = {
      data: {
        date:
          values.paymentMethod.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
            ? getFormattedDate(values.paymentDate, SERVER_DATE_FORMAT)
            : null,
        memo: values.memo,
        contractId: contractID,
        creditsData,
        depositsData,
        invoicesData: values.invoices.map((invoice) => ({
          invoiceId: +invoice.id, // should be changed on server side
          discountCents: invoice.appliedDiscountAmountCents,
          paymentCents: invoice.paymentCents,
          outstandingCents: invoice.paymentCents,
        })),
        paymentMethodId: values.paymentMethod.id,
        appliedCreditCents: paymentSummary.totalAppliedCreditAmountCents,
        appliedDepositCents: paymentSummary.totalAppliedDepositAmountCents,
        paymentCents: paymentSummary.netPaymentCents,
        financialEntityId: values.financialEntityId,
      },
    }
    setPaymentResult(paymentResultMods.LOADING)
    createPayment({
      variables,
    }).then(({ data, errors }) => {
      const responseData = data?.createPayment || {}
      if (responseData?.entity) {
        setPaymentTransactionId(responseData.entity.paymentTransaction?.id)
        setPaymentStatusRefreshCounter((prevState) => prevState + 1)
      } else if (responseData?.errors?.length || errors) {
        setPaymentResult(paymentResultMods.SERVER_ERROR)
      }
    })
  }
  const handleDoneClick = useCallback((isSuccess = true) => {
    setInvoices([])
    setPaymentTransactionId(null)
    setPaymentResult(null)
    setPaymentStatusRefreshCounter(0)
    setFinancialEntitiesOptions([])
    setIsNextButtonDisabled(false)
    setIsReceivePaymentButtonDisabled(false)
    setActiveTab('discounts')
    setStep(steps.FIRST)
    isSuccess && onSuccessSubmit()
  }, [])
  const handleActionClick = useCallback(() => {
    handleDoneClick(false)
    formAPI.current.restart()
    loadInvoices()
    refetchCreditsDeposits()
    refetchFinancialEntities()
  }, [handleDoneClick, formAPI.current])

  return (
    <PageDialog
      bottomPanel={
        <Flex className="w-full" justifyContent="between">
          <Button
            className="ml-16"
            iconName="plusCircle"
            iconType="outline"
            label={t('addMemo')}
            onClick={() => setIsAddMemoModalOpened(true)}
            size={sizes.SM}
            testData="add-payment-memo"
            variant={buttonsVariants.SECONDARY}
          />
          <Flex>
            {step === steps.FIRST && (
              <>
                <Button
                  className="w-28"
                  label={t('cancel')}
                  onClick={handleClose}
                  size={sizes.SM}
                  testData="cancel-payment"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  className="ml-3 w-52"
                  disabled={isNextButtonDisabled}
                  label={t('next')}
                  onClick={() => setStep(steps.SECOND)}
                  size={sizes.SM}
                  testData="payment-next"
                />
              </>
            )}
            {step === steps.SECOND && (
              <>
                <Button
                  className="w-28 ml-3"
                  label={t('back')}
                  onClick={() => setStep(steps.FIRST)}
                  size={sizes.SM}
                  testData="back-btn"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  className="w-52 ml-3"
                  disabled={isReceivePaymentButtonDisabled || requestInProgress}
                  label={t('receivePayment')}
                  onClick={handleSubmit}
                  size={sizes.SM}
                  testData="receive-payment"
                />
              </>
            )}
          </Flex>
        </Flex>
      }
      isOpened={isOpened}
      loading={invoicesQueryInProgress || creditsDepositsLoading || financialEntitiesLoading}
      onModalClose={handleClose}
      setIsOpened={setIsOpened}
      title={getModalTitle(step)}>
      <Form
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
          setMemo: (args, state, utils) => {
            utils.changeValue(state, 'memo', () => args[0])
          },
          setPaymentDate: (args, state, utils) => {
            const paymentDate = args[0]
            const paymentMethod = state.formState.values.paymentMethod
            utils.changeValue(state, 'paymentDate', () => paymentDate)
            utils.changeValue(state, 'invoices', (invoices) =>
              getInvoicesWithUpdatedDiscounts({
                invoices,
                paymentMethodType: paymentMethod.type,
                paymentDate,
                isSupportPartialDiscount,
                rollbar,
              }),
            )
          },
          setPaymentMethod: (args, state, utils) => {
            const paymentMethod = args[0]
            if (!paymentMethod) {
              return
            }
            const paymentDate = DateTime.now().toJSDate()
            const financialEntityId =
              paymentMethod.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
                ? null
                : financialEntitiesOptions.find((option) => option.isDefault)?.value || null
            utils.changeValue(state, 'paymentMethod', () => paymentMethod)
            utils.changeValue(state, 'financialEntityId', () => financialEntityId)
            utils.changeValue(state, 'paymentDate', () => paymentDate)
            utils.changeValue(state, 'invoices', (invoices) =>
              getInvoicesWithUpdatedDiscounts({
                invoices,
                paymentMethodType: paymentMethod.type,
                paymentDate,
                isSupportPartialDiscount,
                rollbar,
              }),
            )
          },
        }}
        onSubmit={handleSubmit}
        validate={handleValidateForm}>
        {({ form, values, handleSubmit, errors, error }) => {
          formAPI.current = form
          validateNavigationButtonsState(errors)
          const paymentSummary = getPaymentSummary(values)
          const areDiscountsAvailable = paymentSummary.anyDiscountExists
          const areCreditsDepositsAvailable = paymentSummary.anyCreditsDepositsExists

          return (
            <form className="w-full" onSubmit={handleSubmit}>
              {step === steps.FIRST && (
                <Flex className="px-8" column>
                  <Text className="pt-5" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                    {t('enterPaymentAmount')} |{' '}
                    <Money
                      className="font-normal"
                      value={values.invoices.reduce((acc, inv) => acc + inv.paymentCents, 0)}
                    />
                  </Text>
                  <FieldArray name="invoices" validate={handleValidateInvoices}>
                    {({ fields }) => (
                      <DataGridComponent
                        columns={getInvoicesColumns(form)}
                        hideFooter={invoices.length <= 100}
                        localPagination={invoices.length > 100}
                        pageSize={100}
                        rowClassName="center-alignment"
                        rowHeight={65}
                        rows={getInvoicesRows(fields)}
                        testData="receive-payment-invoices"
                        wrapperClassNames="pt-9"
                      />
                    )}
                  </FieldArray>
                  <div className="w-full mt-4 text-right">
                    {Object.entries(getCombinedErrors(errors.invoices)).map(([key, error]) => (
                      <div className="text-error" key={error} testData={`error-${key}`}>
                        {error}
                      </div>
                    ))}
                  </div>
                </Flex>
              )}
              {step === steps.SECOND && (
                <Flex className="w-full h-full p-6 gap-14" justifyContent="between">
                  <StyledContainer className="w-[30%]">
                    <Text className="block" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                      {t('selectPaymentMethod')}
                    </Text>
                    <Text className="pt-2" color="text-warmBlack-400 block">
                      {t('selectPaymentMethodHint')}
                    </Text>
                    <PaymentMethodsView
                      contractID={contractID}
                      eligibility={paymentMethodsEligibilityEnum.PAYMENT}
                      financialEntitiesOptions={financialEntitiesOptions}
                      selected={values.paymentMethod}
                      setPaymentDate={form.mutators.setPaymentDate}
                      setSelected={form.mutators.setPaymentMethod}
                      skipCreditCardWarning
                    />
                  </StyledContainer>
                  <StyledContainer className="w-[40%]">
                    <Text className="block" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                      {t('discountsCreditsDeposits')}
                    </Text>
                    <Text className="pt-2 pb-4" color="text-warmBlack-400 block">
                      {t('discountsCreditsDepositsHint')}
                    </Text>
                    <Tabs tabs={discountsCreditsDepositsTabsMap} />
                    {activeTab === 'discounts' && (
                      <>
                        <Text className="pt-4 pb-8" color="text-warmBlack-400 block">
                          {t('discountsByInvoice')}
                        </Text>
                        {areDiscountsAvailable && values.paymentMethod ? (
                          <>
                            <FieldArray name="invoices" validate={handleValidateInvoices}>
                              {({ fields }) => (
                                <DataGridComponent
                                  columns={getDiscountsColumns(form)}
                                  hideFooter={invoices.length <= 6}
                                  localPagination={invoices.length > 6}
                                  pageSize={6}
                                  rowClassName="center-alignment"
                                  rowHeight={65}
                                  rows={getInvoicesRows(fields)}
                                  testData="discounts-invoices"
                                />
                              )}
                            </FieldArray>
                            <Flex
                              alignItems="center"
                              className="pt-5 px-3 border-t"
                              justifyContent="between">
                              <Text fontWeight={fontWeight.BOLD}>{t('totalDiscountsApplied')}</Text>
                              <Money
                                className="text-xl font-semibold"
                                value={values.invoices.reduce(
                                  (acc, inv) => acc + inv.appliedDiscountAmountCents,
                                  0,
                                )}
                              />
                            </Flex>
                            <div className="w-full mt-4 text-right">
                              {Object.entries(getCombinedErrors(errors.invoices)).map(
                                ([key, error]) => (
                                  <div className="text-error" key={error} testData={`error-${key}`}>
                                    {error}
                                  </div>
                                ),
                              )}
                            </div>
                          </>
                        ) : (
                          <Flex className="py-[140px]" justifyContent="center">
                            <Text
                              color="text-warmBlack-400"
                              fontWeight={fontWeight.MEDIUM}
                              size={sizes.BASE}>
                              {t('noEarlyPaymentDiscountsAvailable')}
                            </Text>
                          </Flex>
                        )}
                      </>
                    )}
                    {activeTab === 'creditsDeposits' && (
                      <>
                        <Text className="pt-4 pb-8" color="text-warmBlack-400 block">
                          {t('creditsDepositsWarn')}
                        </Text>
                        {areCreditsDepositsAvailable ? (
                          <>
                            <FieldArray
                              name="creditsDeposits"
                              validate={handleValidateCreditsDeposits}>
                              {({ fields }) => (
                                <DataGridComponent
                                  columns={getCreditsDepositsColumns(form)}
                                  getRowHeight={() => 'auto'}
                                  getRowId={(row) => `${row.type}-${row.id}`}
                                  hideFooter={values.creditsDeposits.length <= 5000}
                                  localPagination={values.creditsDeposits.length > 5000}
                                  pageSize={5000}
                                  rowClassName="center-alignment"
                                  rowHeight={65}
                                  rows={getCreditsDepositsRows(fields)}
                                  testData="credits-deposits"
                                  wrapperClassNames="header-hidden"
                                />
                              )}
                            </FieldArray>
                            <Flex
                              alignItems="center"
                              className="pt-5 px-3 border-t"
                              justifyContent="between">
                              <Text fontWeight={fontWeight.BOLD}>
                                {t('totalCreditsDepositsApplied')}
                              </Text>
                              <Money
                                className="text-xl font-semibold"
                                value={values.creditsDeposits.reduce(
                                  (acc, entity) =>
                                    entity.isEntityApplied ? acc + entity.appliedAmountCents : acc,
                                  0,
                                )}
                              />
                            </Flex>
                            <div className="w-full mt-4 text-right">
                              {Object.entries(getCombinedErrors(errors.creditsDeposits)).map(
                                ([key, error]) => (
                                  <div className="text-error" key={error} testData={`error-${key}`}>
                                    {error}
                                  </div>
                                ),
                              )}
                            </div>
                          </>
                        ) : (
                          <Flex className="py-[140px]" justifyContent="center">
                            <Text
                              color="text-warmBlack-400"
                              fontWeight={fontWeight.MEDIUM}
                              size={sizes.BASE}>
                              {t('noCreditsDepositsAvailable')}
                            </Text>
                          </Flex>
                        )}
                      </>
                    )}
                  </StyledContainer>
                  <StyledContainer className="w-[30%]">
                    <Text className="block" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                      {t('paymentSummary')}
                    </Text>
                    <Text className="py-2" color="text-warmBlack-400 block">
                      {t('paymentSummaryHint')}
                    </Text>

                    <DividedList
                      content={getPaymentDescriptionData1(paymentSummary)}
                      labelTextClassName="py-3"
                      labelTextProps={{
                        fontWeight: fontWeight.MEDIUM,
                      }}
                      valueTextClassName="py-3"
                      wrapperClassName="mt-2"
                      skipDivide
                    />
                    <Flex
                      alignItems="center"
                      className="border-t min-h-10"
                      justifyContent="between">
                      <Text fontWeight={fontWeight.BOLD}>{t('totalPayment')}</Text>
                      <Money
                        className="font-semibold text-xl"
                        value={paymentSummary.totalPaymentCents}
                      />
                    </Flex>

                    <DividedList
                      content={getPaymentDescriptionData2(paymentSummary)}
                      labelTextClassName="py-3"
                      labelTextProps={{
                        fontWeight: fontWeight.MEDIUM,
                      }}
                      valueTextClassName="py-3"
                      wrapperClassName="mt-5"
                      skipDivide
                    />
                    <Flex
                      alignItems="center"
                      className="border-t min-h-10"
                      justifyContent="between">
                      <Text fontWeight={fontWeight.BOLD}>{t('netPayment')}</Text>
                      <Money
                        className="font-semibold text-xl"
                        value={paymentSummary.netPaymentCents}
                      />
                    </Flex>

                    <DividedList
                      content={getPaymentDescriptionData3(paymentSummary)}
                      labelTextClassName="py-3"
                      labelTextProps={{
                        fontWeight: fontWeight.MEDIUM,
                      }}
                      valueTextClassName="py-3"
                      wrapperClassName="mt-5"
                    />
                    <Flex alignItems="center" className="border-t h-12" justifyContent="between">
                      <Text fontWeight={fontWeight.BOLD}>{t('totalCharged')}</Text>
                      <Money
                        className="font-semibold text-xl border-primary-700 border-b-[3px] pb-2"
                        value={paymentSummary.netPaymentCents + paymentSummary.creditCardFeeCents}
                      />
                    </Flex>

                    {values.paymentMethod?.type === paymentMethodTypes.CREDIT_CARD_PAYMENT_METHOD &&
                      !!paymentSummary.creditCardFeeCents && (
                        <Text align="center" className="pt-20" color="text-warmBlack-400 block">
                          {t('creditCardConvenienceFeeHint', {
                            percent: currentUser?.paymentSettings?.creditCardFeePercentage,
                          })}
                        </Text>
                      )}
                    <div className="w-full mt-4 text-right">
                      <div className="text-error text-center" testData="error-global">
                        {error}
                      </div>
                    </div>
                  </StyledContainer>
                </Flex>
              )}

              <Dialog
                isOpened={isAddMemoModalOpened}
                setIsOpened={setIsAddMemoModalOpened}
                title={t('addMemo')}>
                <AddMemoForm
                  customerName={customerName}
                  memo={values.memo}
                  setIsOpenedModal={setIsAddMemoModalOpened}
                  setMemo={form.mutators.setMemo}
                />
              </Dialog>
            </form>
          )
        }}
      </Form>
      {paymentResult && (
        <Dialog shouldCloseOnBackdropClick={false} hideCross isOpened>
          <PaymentResult
            failureReason={failureReason}
            mode={paymentResult}
            onActionClick={handleActionClick}
            onDoneClick={handleDoneClick}
            setMode={setPaymentResult}
          />
        </Dialog>
      )}
    </PageDialog>
  )
}

ReceivePaymentModal.propTypes = {
  isOpened: PT.bool.isRequired,
  contractID: PT.string.isRequired,
  customerName: PT.string,
  invoicesIds: PT.arrayOf(PT.string).isRequired,
  onClose: PT.func,
  onSuccessSubmit: PT.func.isRequired,
  setIsOpened: PT.func.isRequired,
  sort: PT.string,
  withPartialPayment: PT.bool,
}
ReceivePaymentModal.defaultProps = {
  sort: '',
  withPartialPayment: false,
}

export default ReceivePaymentModal

import React, { useCallback } from 'react'
import cx from 'classnames'
import Dialog from '@mui/material/Dialog'
import Backdrop from '@mui/material/Backdrop'
import colors from '../../colors'
import PT from 'prop-types'
import { Flex, Icon } from '../../index'

export const DIALOG_VARIANTS = {
  DIALOG: 'DIALOG',
  FULLSCREEN: 'FULLSCREEN',
  CONFIRMATION: 'CONFIRMATION',
  WARNING: 'WARNING',
}

const CustomBackdrop = ({ open, onClick, transitionDuration, isInner }) => {
  return (
    <Backdrop
      className={cx('!bg-gray-500 !bg-opacity-75', isInner && '!top-14')}
      onClick={onClick}
      open={open}
      transitionDuration={transitionDuration}
    />
  )
}
CustomBackdrop.propTypes = {
  open: PT.bool,
  isInner: PT.bool,
  onClick: PT.func.isRequired,
  transitionDuration: PT.shape({
    exit: PT.number,
    enter: PT.number,
  }),
}
CustomBackdrop.defaultProps = {
  open: false,
  isInner: false,
  transitionDuration: {},
}

const DialogComponent = ({
  icon,
  title,
  isInner,
  children,
  isOpened,
  onModalClose,
  setIsOpened,
  shouldCloseOnBackdropClick,
  titleClass,
  hideCross,
  contentClass,
  hideBackdrop,
  variant,
  fullScreen,
  paperClass,
}) => {
  const headerClasses = cx(
    variant === DIALOG_VARIANTS.FULLSCREEN && 'px-8 py-4 bg-white shadow-sm z-10',
  )
  const titleClasses = titleClass || 'text-lg leading-6 font-medium text-gray-900 pr-11'
  const paperClasses = cx(
    'bg-white !shadow-xl !rounded-lg w-full',
    variant === DIALOG_VARIANTS.DIALOG && '!max-w-max',
    variant === DIALOG_VARIANTS.WARNING && '!max-w-max',
    variant === DIALOG_VARIANTS.CONFIRMATION && '!max-w-md',
    variant !== DIALOG_VARIANTS.FULLSCREEN &&
      '!max-h-[calc(100%-theme(spacing.10))] !max-w-max min-w-min p-6 !m-5',
    variant === DIALOG_VARIANTS.FULLSCREEN && '!w-screen !h-screen',
  )
  const contentClasses = cx(
    'w-full text-left overflow-y-auto',
    variant !== DIALOG_VARIANTS.FULLSCREEN && 'min-w-[25rem]',
    variant === DIALOG_VARIANTS.FULLSCREEN && 'h-full bg-gray-100',
  )

  const handleClose = useCallback(
    (e, reason) => {
      if (
        reason === 'escapeKeyDown' ||
        (reason === 'backdropClick' && !shouldCloseOnBackdropClick)
      ) {
        return
      }

      if (onModalClose) {
        onModalClose()
      }

      setIsOpened?.(false)
    },
    [onModalClose, setIsOpened, shouldCloseOnBackdropClick],
  )

  const handleAutofocus = useCallback((ref) => {
    const firstInput = ref?.querySelector('input[type=text], input[type=password], textarea')
    firstInput && firstInput.focus()
  }, [])

  return (
    <Dialog
      BackdropComponent={CustomBackdrop}
      BackdropProps={{ isInner }}
      classes={{ paper: cx(paperClasses, paperClass) }}
      fullScreen={fullScreen}
      hideBackdrop={hideBackdrop}
      isInner={isInner}
      onClose={handleClose}
      open={isOpened}>
      <Flex
        alignItems="center"
        className={headerClasses}
        justifyContent={hideCross ? 'start' : 'between'}>
        <Flex alignItems="center">
          {icon}
          <h3 className={titleClasses}>{title}</h3>
        </Flex>
        {!hideCross && (
          <Icon
            aria-hidden="true"
            className="h-6 w-6"
            color={colors.BLACK}
            name="x"
            onClick={handleClose}
          />
        )}
      </Flex>
      <div className={cx(contentClasses, contentClass)} ref={handleAutofocus}>
        {children}
      </div>
    </Dialog>
  )
}

DialogComponent.propTypes = {
  icon: PT.node,
  title: PT.string,
  isInner: PT.bool,
  variant: PT.string,
  children: PT.node.isRequired,
  isOpened: PT.bool.isRequired,
  hideCross: PT.bool,
  titleClass: PT.string,
  paperClass: PT.string,
  fullScreen: PT.bool,
  setIsOpened: PT.func,
  contentClass: PT.string,
  onModalClose: PT.func,
  hideBackdrop: PT.bool,
  shouldCloseOnBackdropClick: PT.bool,
}
DialogComponent.defaultProps = {
  icon: null,
  title: '',
  isInner: false,
  variant: DIALOG_VARIANTS.DIALOG,
  isOpened: false,
  hideCross: false,
  titleClass: '',
  paperClass: '',
  fullScreen: false,
  contentClass: '',
  onModalClose: null,
  hideBackdrop: false,
  shouldCloseOnBackdropClick: false,
}

export default DialogComponent

import React from 'react'
import {
  getPaymentMethodTitle,
  paymentTransactionDisplayStatuses,
  refundTransactionStatuses,
  statusRefundTransactionMapping,
} from '../../payments/paymentsTab/util'
import { Flex, FormattedDate, Text } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import { paymentMethodTypes } from '../../../constants/paymentResults'
import { getRefundTitle } from '../invoiceRefund/reasonList'
import { DateTime } from 'luxon'
import { depositStatuses } from '../invoicesUtils'
import colors from '../../../ui-kit/colors'
import { getOverrideActivity } from '../../../utils/formatActivities'
import { InvoicesDepositsQuery } from '../../../queries/invoices/invoicesDeposits.gql'

const getRequestElement = (event, t) => {
  switch (event.entity.displayStatus) {
    case depositStatuses.CANCELLED:
      return {
        title: t('requestCancelled'),
        date: event.createdAt,
        iconName: 'mail',
        bgColorName: 'bg-white',
        iconColor: 'text-darkRed-700',
        borderColorName: 'border-darkRed-700',
      }
    default:
      return {
        title: t('requestSent'),
        date: event.createdAt,
        iconName: 'mail',
        bgColorName: 'bg-white',
        iconColor: 'text-black-500',
        borderColorName: 'border-black-500',
      }
  }
}

const getCreatedElement = (entity, t, contractId) => {
  const latestPaymentTransaction = entity.latestPaymentTransaction
  const title = `${t('depositDate')} (${t(latestPaymentTransaction?.displayStatus)})`
  const basicProps = {
    title,
    date: latestPaymentTransaction.createdAt,
    titleAmount: latestPaymentTransaction?.chargeAmountCents,
    additionalInfoComponent: () => (
      <Flex shrink={0} column>
        {!!latestPaymentTransaction && (
          <div className={'text-black-500'}>
            {t('id')}:{' '}
            <a
              className={'blue'}
              /* eslint-disable-next-line max-len */
              href={
                latestPaymentTransaction?.displayStatus === depositStatuses.AUTHORIZED
                  ? // eslint-disable-next-line max-len
                    `/customers/${contractId}/payments/authorizations?additionalFilters=id&filters=id.${latestPaymentTransaction.id}&openSidebarParams=id.${entity?.latestPaymentTransaction?.id}`
                  : // eslint-disable-next-line max-len
                    `/customers/${contractId}/payments/payments?additionalFilters=id&filters=id.${latestPaymentTransaction.id}&openSidebarParams=id.${entity?.latestPaymentTransaction?.id}`
              }>
              {latestPaymentTransaction?.id}
            </a>
          </div>
        )}
        <div className={'text-black-500'}>{getPaymentMethodTitle(latestPaymentTransaction, t)}</div>
        {latestPaymentTransaction?.paymentMethod?.type ===
          paymentMethodTypes.PAYCHECK_PAYMENT_METHOD && (
          <>
            <div className={'text-black-500'}>
              {t('paymentDate')}: <FormattedDate date={latestPaymentTransaction?.entryDate} />
            </div>
          </>
        )}
      </Flex>
    ),
  }

  switch (latestPaymentTransaction.displayStatus) {
    case paymentTransactionDisplayStatuses.PENDING:
    case paymentTransactionDisplayStatuses.AUTHORIZED:
      return {
        ...basicProps,
        iconName: 'fastForward',
        iconColor: 'text-black-500',
        bgColorName: 'bg-white',
        borderColorName: 'border-black-500',
      }
    case paymentTransactionDisplayStatuses.SUCCESSFUL:
      return {
        ...basicProps,
        iconName: 'currencyDollar',
        bgColorName: 'bg-black-500',
        iconColor: 'text-white',
        borderColorName: 'border-black-500',
      }
    case paymentTransactionDisplayStatuses.FAILED:
    case paymentTransactionDisplayStatuses.CHARGEBACK:
      return {
        ...basicProps,
        iconName: 'x',
        bgColorName: 'bg-darkRed-700',
        iconColor: 'text-white',
        borderColorName: 'border-darkRed-700',
      }
    case paymentTransactionDisplayStatuses.VOIDED:
      return {
        ...basicProps,
        iconName: 'x',
        bgColorName: 'bg-white',
        iconColor: 'text-black-500',
        borderColorName: 'border-black-500',
      }
  }
}
const getAppliedElement = (event, t, contractId) => {
  const title = `${t('depositApplied')}`
  const changesPayload = event.changesPayload
  const amount = changesPayload?.amount_cents
  const transactionId = changesPayload?.payment_transaction_id
  return {
    title,
    date: event.createdAt,
    titleAmount: amount,
    iconName: 'check',
    bgColorName: 'bg-green-600',
    iconColor: 'text-white',
    borderColorName: 'border-green-600',
    additionalInfoComponent: () => (
      <Flex shrink={0} column>
        <div className={'text-black-500'}>
          {t('id')}:{' '}
          <a
            className={'blue'}
            /* eslint-disable-next-line max-len */
            href={`/customers/${contractId}/payments/payments?additionalFilters=id&filters=id.${transactionId}&openSidebarParams=id.${transactionId}`}>
            {transactionId}
          </a>
        </div>
      </Flex>
    ),
  }
}

const getRefundElement = (id, event, t, contractId) => {
  const entity = event.activityOf[0]
  const title = `${t('depositRefunded')}`
  const sumAmount = entity.refundPayableInvoiceAssignments.reduce((acc, item) => {
    const depositUsages = item.depositUsages.find((usage) => usage.deposit.id === id)
    return acc + (depositUsages?.amountCents || 0)
  }, 0)

  return {
    title,
    titleAmount: sumAmount,
    date: event.createdAt,
    iconName: 'check',
    bgColorName: 'bg-orange-900',
    iconColor: 'text-white',
    borderColorName: 'border-orange-900',
    additionalInfoComponent: () => (
      <>
        <Flex>
          <div className={'text-black-500'}>
            {t('id')}:{' '}
            <a
              className={'blue'}
              href={`/customers/${contractId}/payments/refunds?filters=id.${entity?.id}&openSidebarParams=id.${entity?.id}`}>
              {entity?.id}
            </a>
          </div>
        </Flex>
        <Flex className="pb-4" shrink={0} column>
          <div className={'text-black-500'}>
            {t('reason')}: {getRefundTitle(entity?.reason)}
          </div>
        </Flex>
      </>
    ),
  }
}

const getReturnElement = (event, t) => {
  const entity = event?.entity
  const activityOf = event?.activityOf[0]
  const title = `${t('depositApplied')} (${t(event?.changesPayload?.payment_transaction_status)})`
  return {
    title,
    date: event.createdAt,
    titleAmount: activityOf.amountCents,
    iconName: 'x',
    iconColor: colors.WHITE,
    bgColorName: 'bg-warmBlack-400',
    borderColorName: 'border-warmBlack-400',
    additionalInfoComponent: () => (
      <Flex shrink={0} column>
        <div className={'text-black-500'}>
          {t('id')}: {event.changesPayload?.payment_transaction_id}
        </div>
        {entity?.paymentMethod?.type !== paymentMethodTypes.PAYCHECK_PAYMENT_METHOD && (
          <Text size={sizes.SM}>{t(entity?.paymentMethod?.type)}</Text>
        )}
        <Text size={sizes.SM}>{entity?.paymentMethod?.title}</Text>
      </Flex>
    ),
  }
}

const getCustomerRefundElement = (event, t) => {
  const entity = event?.entity
  const activityOf = event?.activityOf[0]
  const title = `${t('depositReturn')} (${t(
    statusRefundTransactionMapping[entity.paymentStatus]?.label,
  )})`

  const basicProps = {
    title,
    date: event.createdAt,
    titleAmount: activityOf.amountCents,
    additionalInfoComponent: () => (
      <Flex shrink={0} column>
        <div className={'text-black-500'}>
          {t('id')}: {entity.latestPaymentTransaction?.id}
        </div>
        {entity?.paymentMethod?.type !== paymentMethodTypes.PAYCHECK_PAYMENT_METHOD && (
          <Text size={sizes.SM}>{t(entity?.paymentMethod?.type)}</Text>
        )}
        <Text size={sizes.SM}>{entity?.paymentMethod?.title}</Text>
      </Flex>
    ),
  }

  switch (entity.paymentStatus) {
    case refundTransactionStatuses.ENQUEUED:
    case refundTransactionStatuses.SUBMITTED:
      return {
        ...basicProps,
        iconName: 'fastForward',
        iconColor: 'text-black-700',
        bgColorName: 'bg-white',
        borderColorName: 'border-black-700',
      }
    case refundTransactionStatuses.PAID:
      return {
        ...basicProps,
        iconName: 'check',
        bgColorName: 'bg-black-700',
        iconColor: 'text-white',
        borderColorName: 'border-black-700',
      }
    case refundTransactionStatuses.FAILED:
      return {
        ...basicProps,
        iconName: 'x',
        bgColorName: 'bg-darkRed-700',
        iconColor: 'text-white',
        borderColorName: 'border-darkRed-700',
      }
  }
}

const createDepositActivities = ({ id, events, audits, t, contractId }) => {
  const mappingEvents = events.map((event) => {
    switch (event.eventAction) {
      case 'request':
        return getRequestElement(event, t, contractId)
      case 'create':
        if (event.entity.latestPaymentTransaction) {
          return getCreatedElement(event.entity, t, contractId)
        }
        return {}
      case 'apply':
        return getAppliedElement(event, t, contractId)
      case 'refund':
        return getRefundElement(id, event, t, contractId)
      case 'revert':
        return getReturnElement(event, t)
      case 'customer_refund':
        return getCustomerRefundElement(event, t)
    }
  })

  const auditsActivity = audits?.length
    ? audits.map((audit) => {
        return getOverrideActivity(audit, t, InvoicesDepositsQuery)
      })
    : []

  return mappingEvents.concat(auditsActivity)
}

export const formatDepositActivities = ({ data, t, contractId }) => {
  if (!data?.events) {
    return []
  }
  const sortedEvents = data.events.sort(function (prev, next) {
    return DateTime.fromISO(next.createdAt).toMillis() - DateTime.fromISO(prev.createdAt).toMillis()
  })
  // filtering out entity related same transaction
  const filteredEvents = sortedEvents.reduce((acc, event) => {
    if (event?.changesPayload?.payment_transaction_id) {
      const indexByPaymentTransaction = acc.find(
        (el) =>
          el?.changesPayload?.payment_transaction_id ===
          event?.changesPayload?.payment_transaction_id,
      )
      if (!indexByPaymentTransaction) {
        acc.push(event)
      }
    } else {
      acc.push(event)
    }
    return acc
  }, [])

  return createDepositActivities({
    id: data.id,
    events: filteredEvents,
    audits: data.audits,
    t,
    contractId,
  }).sort(function (prev, next) {
    return DateTime.fromISO(prev.date).toMillis() - DateTime.fromISO(next.date).toMillis()
  })
}

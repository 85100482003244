import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, EmptyResultBody, Flex, Dialog, Pagination, Text } from '../../../ui-kit'
import {
  getDataSourceColumns,
  getDataSourceData,
  getDataSourceLoadingState,
  getDataSourcePagination,
  getRecentEventsInvoicesQueryParams,
  getRecentEventsLienWaiversQueryParams,
  getRecentEventsPaymentPromisesQueryParams,
  getRecentEventsPaymentTransactionsQueryParams,
  handleDismissLienWaiver,
  totalsButtons,
} from './logic/utils'
import PropTypes from 'prop-types'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { RecentEventsInvoicesQuery } from '../../../queries/invoices/invoicesOutstanding.gql'
import { RecentEventsPaymentTransactionsQuery } from '../../../queries/paymentTransactions.gql'
import { RecentEventsPaymentPromisesQuery } from '../../../queries/paymentPromises.gql'
import { RecentEventsLienWaiversQuery } from '../../../queries/lienWaivers.gql'
import { DismissLienWaiver } from '../../../queries/mutations/dismissLienWaiver.gql'
import { RecentEventsTotalsQuery } from '../../../queries/recentEventsTotals.gql'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { useNotifications } from '../../../hooks/useNotifications'
import { useNavigate } from 'react-router-dom'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { DateTime } from 'luxon'

const RecentEventsDetails = ({ selectedEvent, setSelectedEvent }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { newNotification } = useNotifications()
  const [page, setPage] = useState(1)

  const setIsOpened = useCallback(() => setSelectedEvent(null), [])
  const handlePageChange = useCallback((nextPage) => setPage(nextPage), [])

  // Disputes section
  const disputesQueryVariables = useMemo(() => getRecentEventsInvoicesQueryParams(page), [page])
  const { data: disputesData, loading: disputesDataLoading } = useCustomQuery({
    query: RecentEventsInvoicesQuery,
    queryOptions: {
      variables: disputesQueryVariables,
      skip: selectedEvent !== totalsButtons.disputes.key,
    },
    rollbarOptions: { operationName: 'RecentEventsInvoicesQuery', target: 'RecentEventsDetails' },
  })
  // Payment transactions section
  const paymentTransactionsQueryVariables = useMemo(
    () => getRecentEventsPaymentTransactionsQueryParams(page),
    [page],
  )
  const { data: paymentTransactionsData, loading: paymentTransactionsDataLoading } = useCustomQuery(
    {
      query: RecentEventsPaymentTransactionsQuery,
      queryOptions: {
        variables: paymentTransactionsQueryVariables,
        skip: selectedEvent !== totalsButtons.paymentIssues.key,
      },
      rollbarOptions: {
        operationName: 'RecentEventsPaymentTransactionsQuery',
        target: 'RecentEventsDetails',
      },
    },
  )
  // Payment promises section
  const paymentPromisesQueryVariables = useMemo(
    () => getRecentEventsPaymentPromisesQueryParams(page),
    [page],
  )
  const { data: paymentPromisesData, loading: paymentPromisesDataLoading } = useCustomQuery({
    query: RecentEventsPaymentPromisesQuery,
    queryOptions: {
      variables: paymentPromisesQueryVariables,
      skip: selectedEvent !== totalsButtons.brokenPaymentPromises.key,
    },
    rollbarOptions: {
      operationName: 'RecentEventsPaymentPromisesQuery',
      target: 'RecentEventsDetails',
    },
  })
  // Lien Waiver section
  const lienWaiversQueryVariables = useMemo(
    () => getRecentEventsLienWaiversQueryParams(page),
    [page],
  )
  const {
    data: lienWaiversData,
    loading: lienWaiversDataLoading,
    error,
    refetch,
  } = useCustomQuery({
    query: RecentEventsLienWaiversQuery,
    queryOptions: {
      variables: lienWaiversQueryVariables,
      skip: selectedEvent !== totalsButtons.lienWaivers.key,
    },
    rollbarOptions: {
      operationName: 'RecentEventsLienWaiversQuery',
      target: 'RecentEventsDetails',
    },
  })
  const [dismissLienWaiver, { loading }] = useCustomMutation({
    mutation: DismissLienWaiver,
    rollbarOptions: {
      operationName: 'DismissLienWaiver',
      target: 'RecentEventsDetails',
    },
    mutationOptions: {
      refetchQueries: [RecentEventsLienWaiversQuery, RecentEventsTotalsQuery],
    },
  })
  const handleDismissClick = useCallback(
    async (id) => await handleDismissLienWaiver(id, dismissLienWaiver, t, newNotification),
    [t, dismissLienWaiver],
  )

  // Data definitions
  const isLoading = useMemo(
    () =>
      getDataSourceLoadingState(
        totalsButtons,
        selectedEvent,
        disputesDataLoading,
        paymentTransactionsDataLoading,
        paymentPromisesDataLoading,
        lienWaiversDataLoading,
      ),
    [
      selectedEvent,
      disputesDataLoading,
      paymentTransactionsDataLoading,
      paymentPromisesDataLoading,
      lienWaiversDataLoading,
    ],
  )
  const rows = useMemo(
    () =>
      getDataSourceData(
        totalsButtons,
        selectedEvent,
        disputesData,
        paymentTransactionsData,
        paymentPromisesData,
        lienWaiversData,
      )?.sort(
        (prev, next) =>
          DateTime.fromISO(next?.dispute?.createdAt).toMillis() -
          DateTime.fromISO(prev?.dispute?.createdAt).toMillis(),
      ),
    [selectedEvent, disputesData, paymentTransactionsData, paymentPromisesData, lienWaiversData],
  )

  const columns = useMemo(
    () => getDataSourceColumns(totalsButtons, selectedEvent, t, navigate),
    [t, selectedEvent, navigate],
  )
  const paginationData = useMemo(
    () =>
      getDataSourcePagination(
        totalsButtons,
        selectedEvent,
        disputesData,
        paymentTransactionsData,
        paymentPromisesData,
        lienWaiversData,
      ),
    [selectedEvent, disputesData, paymentTransactionsData, paymentPromisesData, lienWaiversData],
  )

  // Content creation
  const modalContent = useMemo(() => {
    if (!selectedEvent) {
      return null
    }

    switch (selectedEvent) {
      case totalsButtons.disputes.key:
      case totalsButtons.paymentIssues.key:
      case totalsButtons.brokenPaymentPromises.key:
        return (
          <DataGridComponent
            columns={columns}
            handlePageChange={handlePageChange}
            loading={isLoading}
            page={page}
            paginationData={paginationData}
            rows={rows}
            wrapperClassNames="mt-14 mb-2 mx-2"
          />
        )
      case totalsButtons.lienWaivers.key:
        return (
          <Flex className="mt-14" column>
            {!rows.length && !isLoading && (
              <EmptyResultBody error={error} onReloadClick={() => refetch()} />
            )}
            {rows.map((lienWaiver, index) => (
              <Flex className={index !== 0 && 'mt-16'} key={lienWaiver.id} column>
                <Flex alignItems="center" justifyContent="between">
                  <div>
                    <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>{`${t(
                      'paymentId',
                    )}: `}</Text>
                    <Text
                      className="cursor-pointer underline"
                      color="blue"
                      fontWeight={fontWeight.SEMIBOLD}
                      onClick={() =>
                        navigate(
                          // eslint-disable-next-line max-len
                          `/customers/${lienWaiver.contractId}/payments/payments?additionalFilters=id&filters=id.${lienWaiver.paymentTransactionId}&openSidebarParams=id.${lienWaiver.paymentTransactionId}`,
                        )
                      }
                      size={sizes.XL}>
                      {lienWaiver.paymentTransactionId}
                    </Text>
                    <Text
                      className="border-l-2 border-black-700 pl-2 ml-2"
                      fontWeight={fontWeight.SEMIBOLD}
                      size={sizes.XL}>{`${t('type')}: `}</Text>
                    <Text className="capitalize" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
                      {lienWaiver.lienWaiverCondition} - {lienWaiver.lienWaiverPaymentState}
                    </Text>
                  </div>
                  <div>
                    {!!lienWaiver.documentUrl && (
                      <Button
                        className="mr-4"
                        disabled={loading}
                        label={t('viewDocument')}
                        onClick={() => window.open(lienWaiver.documentUrl)}
                        variant={buttonsVariants.TERTIARY}
                      />
                    )}
                    <Button
                      disabled={loading}
                      label={t('dismiss')}
                      onClick={() => handleDismissClick(lienWaiver.id)}
                      variant={buttonsVariants.SECONDARY}
                    />
                  </div>
                </Flex>
                <DataGridComponent
                  columns={columns}
                  loading={isLoading}
                  rows={lienWaiver.invoicesRows}
                  wrapperClassNames="mt-4"
                  localPagination
                />
              </Flex>
            ))}
            {!!rows.length && (
              <Pagination
                className="mt-8"
                currentPage={page}
                fromResult={paginationData.from}
                loading={isLoading}
                onPageChange={handlePageChange}
                toResult={paginationData.to}
                totalPages={paginationData.totalPages}
                totalResults={paginationData.totalCount}
                withResults
              />
            )}
          </Flex>
        )
      default:
        return null
    }
  }, [t, selectedEvent, page, rows, columns, isLoading, paginationData, error, loading])

  return (
    <Dialog
      contentClass="w-320 pr-4"
      setIsOpened={setIsOpened}
      title={t(totalsButtons[selectedEvent]?.label)}
      titleClass="font-semibold text-2xl"
      isOpened>
      {modalContent}
    </Dialog>
  )
}

RecentEventsDetails.propTypes = {
  selectedEvent: PropTypes.string,
  setSelectedEvent: PropTypes.func.isRequired,
}

RecentEventsDetails.defaultProps = {
  selectedEvent: null,
}

export default RecentEventsDetails

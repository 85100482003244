import React from 'react'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import Tooltip from '../../../ui-kit/components/tooltip/Tooltip'
import { Flex, FormattedDate, Icon, Money, Text } from '../../../ui-kit'
import colors from '../../../ui-kit/colors'
import sizes from '../../../ui-kit/sizes'
import fontWeight from '../../../ui-kit/fontWeight'
import { paymentPromiseStatuses } from '../../payments/promiseToPay/util'
import { PAYMENT_PLAN_STATUSES } from '../../../constants/activities'
import { isProcessingStatus } from './InvoiceActions'
import { chargebackLogo } from '../../../ui-kit/assets'

const InvoiceStatusIcons = ({ model }) => {
  const { t } = useTranslation()

  const getStatusIcon = (invoice) => {
    const contents = []
    const tooltipContents = []
    if (isProcessingStatus(invoice?.latestPayment?.paymentTransaction?.status)) {
      const tooltipContent = (
        <div>
          <Flex column>
            <div>
              <Text className="pr-1" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
                {t('pendingPmt')}
              </Text>
              <Text color="text-black-500" size={sizes.SM}>
                (ID: {invoice.latestPayment.id})
              </Text>
            </div>
            <div>
              <Text className="pr-1" color="text-black-500" size={sizes.SM}>
                {t('pmtDate')}:
              </Text>
              <Text size={sizes.SM}>
                <FormattedDate date={invoice?.latestPayment.createdAt} />
              </Text>
            </div>
            <div>
              <Text color="text-black-500" size={sizes.SM}>
                {t('pmtMethod')}:{' '}
              </Text>
              <Text size={sizes.SM}>{invoice.latestPayment.paymentMethod?.title}</Text>
            </div>
            <div>
              <Text color="text-black-500" size={sizes.SM}>
                {t('pmtAmt')}:{' '}
              </Text>
              <Money value={invoice.latestPayment.totalAmountCents} />
            </div>
          </Flex>
        </div>
      )
      tooltipContents.push(tooltipContent)

      contents.push(
        <Tooltip content={tooltipContent}>
          <Icon color={colors.RED} name="fastForward" type="solid" />
        </Tooltip>,
      )
    }
    if (invoice.paymentPromise && invoice.paymentPromise.status === paymentPromiseStatuses.ACTIVE) {
      const tooltipContent = (
        <Flex column>
          <div>
            <Text className="pr-1" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
              {t('p2p')}
            </Text>
            <Text color="text-black-500" size={sizes.SM}>
              (ID: {invoice.paymentPromise.id})
            </Text>
          </div>
          <div>
            <Text color="text-black-500" size={sizes.SM}>
              {t('expPmtDate')}:{' '}
            </Text>
            <Text size={sizes.SM}>
              <FormattedDate date={invoice?.paymentPromise.date} />
            </Text>
          </div>
          <div>
            <Text color="text-black-500" size={sizes.SM}>
              {t('expPmtAmt')}:{' '}
            </Text>
            <Money value={invoice?.paymentPromise.amountCents} />
          </div>
        </Flex>
      )
      tooltipContents.push(tooltipContent)
      contents.push(
        <Tooltip content={tooltipContent}>
          <Icon color="text-orange-600" name="cash" type="outline" />
        </Tooltip>,
      )
    }
    if (
      invoice.latestPaymentPlan &&
      invoice.latestPaymentPlan?.status !== PAYMENT_PLAN_STATUSES.CANCELLED
    ) {
      const tooltipContent = (
        <Flex column>
          <div>
            <Text className="pr-1" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
              {t('paymentPlan')}
            </Text>
            <span className="text-warmBlack-400">(ID: {invoice.latestPaymentPlan.id})</span>
          </div>
          <div>
            <Text color="text-black-500" size={sizes.SM}>
              {t('nextPmtDate')}:{' '}
            </Text>
            <FormattedDate date={invoice.latestPaymentPlan.nextInstallment?.date} />
          </div>
          <div>
            <Text color="text-black-500" size={sizes.SM}>
              {t('nextPmtAmt')}:{' '}
            </Text>
            <Money value={invoice.latestPaymentPlan.nextInstallment?.amountCents} />
          </div>
        </Flex>
      )
      tooltipContents.push(tooltipContent)
      contents.push(
        <Tooltip content={tooltipContent}>
          <Icon color={colors.GREEN} name="clipboardCheck" type="outline" />
        </Tooltip>,
      )
    }
    if (invoice?.dispute) {
      const tooltipContent = (
        <Flex column>
          <div>
            <Text className="pr-1" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
              {t('dispute')}
            </Text>
            <Text color="text-black-500" size={sizes.SM}>
              (ID: {invoice.dispute.id})
            </Text>
          </div>
          <div>
            <Text className="pr-1" color="text-black-500" size={sizes.SM}>
              {t('disputeDate')}:
            </Text>
            <FormattedDate date={invoice?.dispute.createdAt} />
          </div>
          <div>
            <Text className="pr-1" color="text-black-500" size={sizes.SM}>
              {t('reason')}:
            </Text>
            <span>{invoice?.dispute.reason}</span>
          </div>
        </Flex>
      )
      tooltipContents.push(tooltipContent)
      contents.push(
        <Tooltip content={tooltipContent}>
          <Icon color={colors.RED} name="hand" type="outline" />
        </Tooltip>,
      )
    }
    if (invoice?.chargebacksPresent) {
      const tooltipContent = (
        <Flex column>
          <div>
            <Text className="pr-1" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
              {t('chargeback')}
            </Text>
          </div>
        </Flex>
      )
      tooltipContents.push(tooltipContent)
      contents.push(
        <Tooltip content={tooltipContent}>
          <img alt="Suppli" className="h-8" src={chargebackLogo} />
        </Tooltip>,
      )
    }
    return { contents, tooltipContents }
  }
  const renderIcon = (invoice) => {
    const { contents, tooltipContents } = getStatusIcon(invoice)
    if (contents.length > 1) {
      const aggregatedContent = tooltipContents.concat()
      return (
        <Tooltip content={aggregatedContent}>
          <div className="relative">
            {contents[0]}
            <Icon
              className="absolute top-[-8px] right-[-10px]"
              color={colors.PRIMARY}
              name="plusCircle"
              size={sizes.XS}
              type="solid"
            />
          </div>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip content={tooltipContents[0]}>
          <div>{contents[0]}</div>
        </Tooltip>
      )
    }
  }

  return renderIcon(model)
}

InvoiceStatusIcons.propTypes = {
  model: PT.shape({
    latestPayment: PT.shape({}),
  }).isRequired,
}

export default InvoiceStatusIcons

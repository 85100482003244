import Select from './Select'
import AutocompleteSelect from './AutocompleteSelect'
import PT from 'prop-types'

const SelectAdapter = ({ input, className = '', loadOptions, ...rest }) =>
  loadOptions ? (
    <AutocompleteSelect
      loadOptions={loadOptions}
      {...input}
      className={className}
      {...rest}
      searchable
      withPortal
    />
  ) : (
    <Select
      {...input}
      {...rest}
      className={className}
      onChange={(value) => {
        input.onChange(value)
        rest.onChange?.(value)
      }}
      searchable
      withPortal
    />
  )

export default SelectAdapter

SelectAdapter.propTypes = {
  input: PT.shape({
    value: PT.oneOfType([PT.string, PT.number]).isRequired,
    name: PT.string.isRequired,
    onChange: PT.func.isRequired,
  }),
  className: PT.string,
  loadOptions: PT.func,
}
SelectAdapter.defaultProps = {
  className: '',
  loadOptions: null,
}

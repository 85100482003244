import React, { useEffect, useState } from 'react'
import { Button, Flex, Input, LoadingSpinner, Icon } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import styles from './CardIntegrationStyles'
import PT from 'prop-types'
import { CreateCreditCardRequest } from '../../queries/mutations/createCreditCardRequest.gql'
import Checkbox from '../../ui-kit/components/inputs/Checkbox'
import colors from '../../ui-kit/colors'
import inputStyles from './InputStyles.module.scss'
import cs from 'classnames'
import VALIDATION_ERRORS from '../../constants/validationErrors'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { useNotifications } from '../../hooks/useNotifications'
import { SUPPLI_SUPPORT_EMAIL } from '../../constants/contacts'
import { captureError } from '../../utils/rollbar'
import { useRollbar } from '@rollbar/react'

const CardIntegration = ({ contractId, onCreateMethod }) => {
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const { newNotification } = useNotifications()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState()
  const [isDefaultMethod, setIsDefaultMethod] = useState(true)
  const currentUser = useCurrentUser()
  const baseUrl = currentUser?.paymentSettings?.clearentConfiguration?.baseUrl
  const key = currentUser?.paymentSettings?.clearentConfiguration?.publicKey

  const [createCreditCard, { loading }] = useCustomMutation({
    mutation: CreateCreditCardRequest,
    rollbarOptions: { operationName: 'CreateCreditCardRequest', target: 'CardIntegration' },
  })
  useEffect(() => {
    const script = document.createElement('script')
    script.src = `${baseUrl}/js-sdk/js/clearent-host.js`
    script.charset = 'utf-8'
    script.async = true
    script.onload = () => {
      setIsLoading(false)
    }
    script.onerror = () => {
      captureError(rollbar, 'Clearent script loading error')
    }

    script.addEventListener(
      'load',
      function () {
        if (window) {
          try {
            // eslint-disable-next-line no-undef
            ClearentSDK.init({
              baseUrl,
              styles: styles,
              pk: key,
            })
          } catch (err) {
            console.error(err)
          }
        }
      },
      { once: true },
    )
    document.head.appendChild(script)
  }, [])
  const [zipCode, setZipCode] = useState('')
  const [zipCodeErrorMsg, setZipCodeErrorMsg] = useState('')
  const [nickname, setNickname] = useState('')
  const handleSubmit = () => {
    if (!zipCode) {
      return setZipCodeErrorMsg(VALIDATION_ERRORS.REQUIRED)
    }
    // eslint-disable-next-line no-undef
    ClearentSDK.getPaymentToken()
      .then((res) => {
        if (res.code === '200') {
          createCreditCard({
            variables: {
              contractId,
              zipCode,
              nickname,
              default: isDefaultMethod,
              data: JSON.stringify(res),
            },
          }).then((res) => {
            const errors = res.errors || res?.data?.createCreditCardPaymentMethod?.errors
            if (errors?.length > 0) {
              setErrorMessage(errors[0].message)
            } else {
              onCreateMethod(res.data.createCreditCardPaymentMethod.entity)
            }
          })
        } else if (res.code === '401') {
          newNotification({
            error: t('clearentAuthErrorMessage', { email: SUPPLI_SUPPORT_EMAIL }),
          })
        }
      })
      .catch((error) => {
        if (error?.code === '401') {
          newNotification({
            error: t('clearentAuthErrorMessage', { email: SUPPLI_SUPPORT_EMAIL }),
          })
        }
      })
  }
  const zipCodeFormat = (event) => {
    setZipCodeErrorMsg('')
    if (!/[0-9]/.test(event.key) || event.target.value.length > 4) {
      event.preventDefault()
    }
  }
  return (
    <Flex className={cs('mt-4 min-h-[250px]', inputStyles.container)} justifyContent={'end'} column>
      {isLoading && <LoadingSpinner className={'h-[210px]'} />}
      <div id="payment-form" />
      {!isLoading && (
        <>
          <Input
            className={inputStyles.zip}
            errorMessage={zipCodeErrorMsg}
            icon={<Icon color={colors.CLEARENT_ICON_COLOR} name={'mail'} />}
            iconPosition={'start'}
            id={'zipCode'}
            name={'zipCode'}
            onChange={(e) => setZipCode(e.target.value)}
            onKeyPress={zipCodeFormat}
            placeholder={t('zipCode')}
            value={zipCode}
          />
          <Input
            className={inputStyles.nickname}
            id={'nickname'}
            maxLength={16}
            name={'nickname'}
            onChange={(e) => setNickname(e.target.value)}
            placeholder={t('nickname')}
            value={nickname}
          />
          <Flex alignItems={'center'} className="my-2">
            <Checkbox
              id={'setAsDefaultPaymentMethod'}
              name={'setAsDefaultPaymentMethod'}
              onChange={(e) => {
                setIsDefaultMethod(e)
              }}
              value={isDefaultMethod}
            />

            <label className="ml-2" htmlFor="setAsDefaultPaymentMethod">
              {t('setAsDefaultPaymentMethod')}
            </label>
          </Flex>
        </>
      )}
      {errorMessage && <div className={'text-error my-2'}>{errorMessage}</div>}
      <Button
        disabled={isLoading || loading}
        id="payment-button"
        label={t('addCard')}
        onClick={handleSubmit}
        testData="add-card-integration"
        type="button"
      />
    </Flex>
  )
}

export default CardIntegration

CardIntegration.propTypes = {
  contractId: PT.string.isRequired,
  onCreateMethod: PT.func.isRequired,
}

import React, { useMemo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { getRollbarPerson, ROLLBAR_NO_AUTH_PERSON } from '../utils/rollbar'
import { useRollbarPerson } from '@rollbar/react'
import { reloadUserpilot } from '../utils/userpilot'

const PrivateRoute = () => {
  const currentUser = useCurrentUser()
  const rollbarPerson = useMemo(
    () => (currentUser ? getRollbarPerson(currentUser) : ROLLBAR_NO_AUTH_PERSON),
    [currentUser],
  )
  useRollbarPerson(rollbarPerson)
  const location = useLocation()

  React.useEffect(() => {
    reloadUserpilot()
  }, [location])

  if (!currentUser) {
    return <Navigate to="/auth/login" />
  }
  return <Outlet />
}

export default PrivateRoute

import React from 'react'
import PT from 'prop-types'
import sizes from '../../sizes'
import { Text } from '../../index'
import { useTranslation } from 'react-i18next'

const SidebarLabel = ({ text }) => {
  const { t } = useTranslation()
  return (
    <Text color="text-warmBlack-400" size={sizes.SM}>
      {t(text)}
    </Text>
  )
}

SidebarLabel.propTypes = {
  text: PT.string.isRequired,
}

export default SidebarLabel

import React from 'react'
import { Tabs } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { camelCase, snakeCase } from 'lodash'
import { Navigate, Route, Routes } from 'react-router-dom'
import ErpFilesExport from './ErpFilesExport'
import ErpFilesImport from './ErpFilesImport'

const ErpFiles = () => {
  const { t } = useTranslation()

  const tabs = [
    { name: 'export', breadcrumbLabel: 'export' },
    { name: 'import', breadcrumbLabel: 'import' },
  ]
  const currentTabPage = location.pathname.split('/')[3]
  const page = currentTabPage || snakeCase(tabs[0].name)

  const tabsMap = tabs.map((tab) => ({
    label: t(tab.name),
    active: camelCase(page) === tab.name,
    link: snakeCase(tab.name),
  }))

  return (
    <div>
      <Tabs tabs={tabsMap} />
      <Routes>
        <Route element={<Navigate to="export" replace />} path="/" />
        <Route element={<ErpFilesExport />} path="/export" />
        <Route element={<ErpFilesImport />} path="/import" />
      </Routes>
    </div>
  )
}

export default ErpFiles

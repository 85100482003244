import React from 'react'
import PT from 'prop-types'
import { Flex, InformationBox } from '../ui-kit'

const StatsFilters = ({ data, onItemClick }) => {
  return (
    <Flex className="w-full gap-5 mb-4" gap="5" justifyContent="between">
      {data.map(
        (item) =>
          !!item.title && (
            <InformationBox
              className="w-full"
              key={item.id}
              onClick={!item.notClickable && onItemClick ? () => onItemClick(item) : null}
              secondaryLabel={item.secondaryLabel}
              secondaryValue={item.secondaryValue}
              title={item.title}
              type={item.type}
              value={item.value}
              valueLabel={item.valueLabel}
            />
          ),
      )}
    </Flex>
  )
}

StatsFilters.propTypes = {
  data: PT.arrayOf(
    PT.shape({
      id: PT.string,
      value: PT.number,
      type: PT.string,
    }),
  ),
  onItemClick: PT.func,
}

StatsFilters.defaultProps = {
  data: [],
  onItemClick: null,
}

export default StatsFilters

import colors from '../ui-kit/colors'
import { Flex, FormattedDate, Money, Text, Tooltip } from '../ui-kit'
import React from 'react'
import {
  getDueDateIconProps,
  getPaymentProps,
  getRefundProps,
  getDisputeProps,
  getPaymentPromiseProps,
  getPaymentPlanProps,
  getOverrideActivity,
} from './formatActivities'
import getPresentationName, { getPresentationGuestName } from './getPresentationName'
import {
  displayStatusesMapping,
  getPaymentMethodTitleNickName,
} from '../pages/payments/paymentsTab/util'
import { getRefundsMethodTitle, statusRefundMapping } from '../pages/payments/refunds/util'
import { getRefundTitle } from '../pages/invoices/invoiceRefund/reasonList'
import { getSumByField } from './utils'
import { isCounterUser } from '../pages/settings/teamManagement/membershipRoles'
import DividedList from '../ui-kit/components/sidebar/DividedList'
import { InvoiceActivitiesQuery } from '../queriesUpdated/queries/invoiceActivity.gql'
import { paymentMethodTypes } from '../constants/paymentResults'

const createBasicActivities = ({
  dueDate,
  paymentPromises,
  invoicedAmount,
  paymentPlans,
  disputes,
  paymentRequests,
  payments,
  refunds,
  t,
  contractId,
  invoiceData,
  currentUser,
  audits,
  payableInvoiceAssignments,
}) => {
  const getPaymentAmountByTransactionId = (id) => {
    const payment = payableInvoiceAssignments.find((item) => item.payableId === id)
    if (!payment)
      return {
        coveredOutstandingAmountCents: 0,
        appliedDiscountAmountCents: 0,
        total: 0,
      }
    return {
      ...payment,
      total: payment.coveredOutstandingAmountCents - payment.appliedDiscountAmountCents,
    }
  }

  const getTooltipContent = (id) => {
    const { coveredOutstandingAmountCents, appliedDiscountAmountCents } =
      getPaymentAmountByTransactionId(id)
    const descriptionData = [
      {
        label: t('outstanding'),
        value: <Money value={coveredOutstandingAmountCents} />,
      },
      {
        label: `(-) ${t('discountsApplied')}`,
        value: <Money value={appliedDiscountAmountCents} />,
      },
    ]
    return <DividedList content={descriptionData} />
  }

  const disputesCollection = disputes?.map((el) => [
    `dispute${el.id}`,
    {
      iconName: 'hand',
      bgColorName: 'transparent',
      baseUrl: '',
      ...getDisputeProps(el.entityActualEvent?.eventAction, t),
      additionalInfoComponent: () => (
        <Flex shrink={0} column>
          <div className={'text-black-500'}>
            {t('id')}:{' '}
            {!isCounterUser(currentUser) ? (
              <a
                className={'blue'}
                /* eslint-disable-next-line max-len */
                href={`/customers/${contractId}/activity?additionalFilters=id&filters=id.${el?.id}&openSidebarParams=id.${el?.id}`}
                testData={`invoice-activity-dispute-${el?.entity?.disputeId}-id`}>
                {el?.entity?.disputeId}
              </a>
            ) : (
              <Text>{el?.entity?.disputeId}</Text>
            )}
          </div>
          <div className={'text-black-500'}>
            {t('reason')}: {el.entity.reason}
          </div>
        </Flex>
      ),
    },
  ])
  const paymentPromisesCollection = paymentPromises?.map((el) => [
    `paymentPromise${el.id}`,
    {
      baseUrl: '',
      ...getPaymentPromiseProps(el.entity, t),
      additionalInfoComponent: () => (
        <Flex shrink={0} column>
          <div className={'text-black-500'}>
            {t('id')}:{' '}
            <a
              className={'blue'}
              /* eslint-disable-next-line max-len */
              href={`/customers/${contractId}/payments/promisesToPay?additionalFilters=id&filters=id.${el?.entity?.paymentPromiseId}&openSidebarParams=id.${el?.entity?.paymentPromiseId}`}
              testData={`invoice-activity-payment-promise-${el?.entity?.paymentPromiseId}-id`}>
              {el?.entity?.paymentPromiseId}
            </a>
          </div>
          <div className={'text-black-500'}>
            {t('expectedPaymentDate')}: <FormattedDate date={el.entity.date} />
          </div>
          <div className={'text-black-500'}>
            {t('createdBy')}: {el?.entity?.createdBy?.fullName}
          </div>
        </Flex>
      ),
    },
  ])
  const paymentsCollection = payments?.map((el) => [
    `payment${el.id}`,
    {
      title: `${t('payment')} (${t(
        displayStatusesMapping[el.entity?.paymentTransaction?.displayStatus]?.label,
      )})`,
      titleAmountEl: (
        <Tooltip
          className="text-ellipsis overflow-hidden max-w-72 whitespace-nowrap text-sm"
          content={getTooltipContent(el?.entity?.id)}
          placement="bottom">
          <Money value={getPaymentAmountByTransactionId(el?.entity?.id).total} />
        </Tooltip>
      ),
      ...getPaymentProps(el.entity?.paymentTransaction),
      baseUrl: '',
      additionalInfoComponent: () => (
        <Flex shrink={0} column>
          <div className={'text-black-500'}>
            {t('id')}:{' '}
            <a
              className={'blue'}
              /* eslint-disable-next-line max-len */
              href={`/customers/${contractId}/payments/payments?additionalFilters=id&filters=id.${el?.entity?.paymentTransaction?.id}&openSidebarParams=id.${el?.entity?.paymentTransaction?.id}`}
              testData={`invoice-activity-payment-${el?.entity?.paymentTransaction?.id}-id`}>
              {el?.entity.paymentTransaction?.id}
            </a>
          </div>
          <div className={'text-black-500'}>
            {getPaymentMethodTitleNickName(el?.entity?.paymentMethod, t)}
          </div>
          {el?.entity?.paymentMethod?.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD && (
            <>
              <div className={'text-black-500'}>
                {t('paymentDate')}:{' '}
                <FormattedDate date={el?.entity?.paymentTransaction?.entryDate} />
              </div>
            </>
          )}
        </Flex>
      ),
    },
  ])
  const paymentPlansCollection = paymentPlans?.map((el) => [
    `paymentPlan${el.id}`,
    {
      baseUrl: '',
      ...getPaymentPlanProps(el?.entity, t),
      additionalInfoComponent: () => (
        <Flex shrink={0} column>
          <div className={'text-black-500'}>
            {t('id')}:{' '}
            <a
              className={'blue'}
              /* eslint-disable-next-line max-len */
              href={`/customers/${contractId}/payments/paymentPlans?additionalFilters=id&filters=id.${el?.entity?.paymentPlanId}&openSidebarParams=id.${el?.entity?.paymentPlanId}`}
              testData={`invoice-activity-payment-plan-${el?.entity?.paymentPlanId}-id`}>
              {el?.entity?.paymentPlanId}
            </a>
          </div>
          <div className={'text-black-500'}>
            {t('noOfInstallments')}: {el?.entity?.paymentPlanInstallments?.length}
          </div>
          <div className={'text-black-500'}>
            {getPaymentMethodTitleNickName(el?.entity?.paymentMethod)}
          </div>
        </Flex>
      ),
    },
  ])
  const paymentRequestsCollection = paymentRequests?.map((el) => [
    `paymentRequest${el.id}`,
    {
      title: 'paymentRequested',
      iconName: 'mail',
      iconColor: 'text-violet-500',
      bgColorName: 'transparent',
      borderColorName: 'border-violet-500',
      baseUrl: '',
      additionalInfoComponent: () => {
        const recipientsViaEmail = el.entity.recipientsViaEmail || []
        const recipientsViaText = el.entity.recipientsViaText || []
        const contacts = [...recipientsViaEmail, ...recipientsViaText]
        return (
          <Flex className="max-w-[15rem]" shrink={0} column>
            <div className={'text-black-500'}>
              {t('id')}:{' '}
              {!isCounterUser(currentUser) ? (
                <a
                  className={'blue'}
                  /* eslint-disable-next-line max-len */
                  href={`/customers/${contractId}/activity?additionalFilters=id&filters=id.${el?.id}&openSidebarParams=id.${el?.id}`}
                  testData={`invoice-activity-payment-request-${el?.entity?.paymentRequestId}-id`}>
                  {el?.entity?.paymentRequestId}
                </a>
              ) : (
                <Text>{el?.entity?.paymentRequestId}</Text>
              )}
            </div>
            <Flex className={'text-black-500'}>
              {t('to')}:{' '}
              <Flex className={'ml-1'} column>
                {contacts.slice(0, 2).map((contact) => (
                  <Text color="text-black-500" key={contact?.id}>
                    {contact.user
                      ? getPresentationName(contact, t)
                      : getPresentationGuestName(contact?.email, contact?.formattedPhoneNumber, t)}
                  </Text>
                ))}
                {contacts.length > 2 && (
                  <Text color="text-black-500">+{t('additionalRecipients')}</Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        )
      },
    },
  ])
  const refundsCollection = refunds?.map((el) => [
    `invoiceRefund${el.id}`,
    {
      title: `${t('refund')} (${t(statusRefundMapping[el.entity.status]?.label)})`,
      ...getRefundProps(el.entity),
      baseUrl: '',
      additionalInfoComponent: () => {
        const refundPayableInvoiceAssignments = el.entity?.refundPayableInvoiceAssignments

        const getSumAmount = (refundPayableInvoiceAssignments) =>
          refundPayableInvoiceAssignments.reduce(
            (acc, item) =>
              acc +
              item.amountCents +
              getSumByField(item.creditUsages, 'amountCents') +
              getSumByField(item.depositUsages, 'amountCents'),
            0,
          )
        return (
          <>
            <Flex>
              <div className={'text-black-500'}>
                {t('id')}:{' '}
                <a
                  className={'blue'}
                  /* eslint-disable-next-line max-len */
                  href={`/customers/${contractId}/payments/refunds?filters=id.${el?.entity?.id}&openSidebarParams=id.${el?.entity?.id}`}
                  testData={`invoice-activity-refund-${el?.entity?.id}-id`}>
                  {el?.entity?.id}
                </a>
              </div>
            </Flex>
            <Flex className="pb-4" shrink={0} column>
              <div className={'text-black-500'}>
                {t('refundedAmount')}:{' '}
                <Money value={getSumAmount(refundPayableInvoiceAssignments)} />
              </div>
              <div className={'text-black-500'}>
                {getRefundsMethodTitle(refundPayableInvoiceAssignments, t)}
              </div>
              <div className={'text-black-500'}>
                <Tooltip
                  className="text-ellipsis overflow-hidden text-black-500"
                  content={getRefundTitle(el?.entity?.reason) || ''}
                  placement="bottom">
                  {t('reason')}: {getRefundTitle(el?.entity?.reason)}
                </Tooltip>
              </div>
            </Flex>
          </>
        )
      },
    },
  ])
  const auditsCollection = audits?.map((el) => {
    const overrideEl = getOverrideActivity(el, t, InvoiceActivitiesQuery)
    return [`audit${el.id}`, overrideEl]
  })
  return {
    ...(invoicedAmount
      ? {
          invoiceDate: {
            title: 'invoiceDate',
            iconName: 'check',
            iconColor: colors.WHITE,
            bgColorName: 'bg-black-500',
            borderColorName: 'border-gray-500',
            baseUrl: '',
            additionalInfoComponent: () => (
              <Flex shrink={0} column>
                <div className={'text-black-500'}>
                  Invoiced Amt: <Money value={invoicedAmount} />
                </div>
              </Flex>
            ),
          },
        }
      : {}),
    ...(dueDate
      ? {
          dueDate: {
            title: 'dueDate',
            ...getDueDateIconProps(dueDate, invoiceData),
            baseUrl: '',
          },
        }
      : {}),
    ...(paymentPromises ? Object.fromEntries(paymentPromisesCollection) : {}),
    ...(paymentPlans ? Object.fromEntries(paymentPlansCollection) : {}),
    ...(disputes ? Object.fromEntries(disputesCollection) : {}),
    ...(payments ? Object.fromEntries(paymentsCollection) : {}),
    ...(paymentRequests ? Object.fromEntries(paymentRequestsCollection) : {}),
    ...(refunds ? Object.fromEntries(refundsCollection) : {}),
    ...(audits ? Object.fromEntries(auditsCollection) : {}),
  }
}

export default createBasicActivities

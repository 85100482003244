import { useDispatch, useSelector } from 'react-redux'
import { selectBreadcrumbs } from '../store/selectors/breadcrumbs'
import { applyBreadcrumbs } from '../store/actions/breadcrumbs'

export const useBreadcrumbs = () => {
  const dispatch = useDispatch()
  const breadcrumbs = useSelector(selectBreadcrumbs)
  const setBreadcrumbs = (newBreadcrumbs) => {
    dispatch(applyBreadcrumbs(newBreadcrumbs))
  }
  return { breadcrumbs, setBreadcrumbs }
}

import React from 'react'
import { Box, Flex, Money } from '../../ui-kit'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getNumberWithCommas } from '../../utils/utils'
import { DAYS, MONEY } from '../../utils/dataTypes'
// import ColumnChart from '../../ui-kit/components/charts/ColumChart'
// import colors from '../../ui-kit/colors'

// eslint-disable-next-line no-unused-vars
const StatBlock = ({ title, number, className, type, onClick, data }) => {
  const { t } = useTranslation()
  const numberClassName = 'text-2xl text-orange-700'
  const getNumberComponent = (type, number) =>
    type === MONEY ? (
      <Money className={numberClassName} value={number} withCurrency />
    ) : (
      <span className={numberClassName}>
        {getNumberWithCommas(number)} {type === DAYS && 'days'}
      </span>
    )
  // const getComparedNumber = (currentValue, prevValue, type) => {
  //   return type === MONEY ? (
  //     <Money value={currentValue} withCurrency />
  //   ) : (
  //     <span>
  //       {getNumberWithCommas(currentValue)} {type === DAYS && 'days'}
  //     </span>
  //   )
  // }

  // const prevValue = data?.[data?.length - 2]?.value
  // const currentValue = data?.[data?.length - 1]?.value
  // const isCurrentBigger = currentValue > prevValue
  return (
    <Box className={className} onClick={onClick} padding={'px-4 py-[15px]'}>
      <Flex justifyContent={'between'}>
        <Flex className={'w-full'} column>
          <span className={'text-black-500 text-base'}>{t(title)}</span>
          {getNumberComponent(type, number)}
        </Flex>

        {/*<Flex className={'w-1/2'} justifyContent={'between'}>*/}
        {/*  <div className={'w-[70px]'}>*/}
        {/*    <ColumnChart*/}
        {/*      borderRadius={4}*/}
        {/*      chartHeight={50}*/}
        {/*      columnColors={[*/}
        {/*        colors.LIGHT_GRAY1,*/}
        {/*        colors.LIGHT_GRAY1,*/}
        {/*        colors.LIGHT_GRAY1,*/}
        {/*        colors.LIGHT_GRAY1,*/}
        {/*        colors.LIGHT_GRAY1,*/}
        {/*        isCurrentBigger ? colors.SUPPLI_ORANGE : colors.GREEN_600,*/}
        {/*      ]}*/}
        {/*      columnGap={0.05}*/}
        {/*      columnWidth={7}*/}
        {/*      inSection={false}*/}
        {/*      showXAxis={false}*/}
        {/*      spacing={[0, 0, 0, 0]}*/}
        {/*      valueFieldName={type}*/}
        {/*      xAxisData={data?.map((el) => el.date)}*/}
        {/*      yAxisData={data?.map((el) => el.value)}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <Flex*/}
        {/*    alignContent={'center'}*/}
        {/*    alignItems={'center'}*/}
        {/*    className={'w-1/2'}*/}
        {/*    justifyContent={'center'}*/}
        {/*    column>*/}
        {/*    <div className={isCurrentBigger ? 'arrow-up' : 'arrow-down'} />*/}
        {/*    <div*/}
        {/*      className={`whitespace-nowrap font-medium text-base ${*/}
        {/*        isCurrentBigger ? 'text-primary-700' : 'text-green-600'*/}
        {/*      }`}>*/}
        {/*      {isCurrentBigger ? '+' : '-'}*/}
        {/*      {getComparedNumber(currentValue, prevValue, type)}*/}
        {/*    </div>*/}
        {/*  </Flex>*/}
        {/*</Flex>*/}
      </Flex>
    </Box>
  )
}

export default StatBlock

StatBlock.propTypes = {
  className: PT.string,
  title: PT.string.isRequired,
  number: PT.number.isRequired,
  onClick: PT.func,
  type: PT.oneOf([MONEY, DAYS]),
  data: PT.arrayOf(
    PT.shape({
      date: PT.string,
      value: PT.number,
    }),
  ),
}

StatBlock.defaultProps = {
  className: '',
  onClick: null,
  units: undefined,
  type: undefined,
  data: undefined,
}

import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import colors from '../../ui-kit/colors'
import NewPaymentMethod from './NewPaymentMethod'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import NDropdown from '../../ui-kit/components/dropdown/NDropdown'
import { paymentMethodsEligibilityEnum } from '../../constants/paymentMethodEligibilityEnum'
import { paymentMethodTypes } from '../../constants/paymentResults'

export const getDefaultPaymentMethod = (types) => {
  const defaultMainOption = types.find((type) => type.default)
  let defaultSubOption

  types.map((type) => {
    const foundedOption = type.options && type.options.find((option) => option.default)
    if (foundedOption) {
      defaultSubOption = foundedOption
    }
  })
  return defaultMainOption || defaultSubOption || null
}
const PaymentType = ({
  types,
  selected,
  setSelected,
  disabled,
  contractCreditCardFeeEnabled,
  contractID,
  fullWidth,
  onCreateMethod,
  eligibility,
  skipCreditCardWarning,
  noLabel,
  label,
  noDefault,
}) => {
  const [creditCardWarning, setShowCreditCardWarning] = useState(false)
  const { paymentSettings } = useCurrentUser()

  const checkIfCreditCardSelected = (type) => {
    if (!paymentSettings.creditCardFeeEnabled || !contractCreditCardFeeEnabled) {
      return setShowCreditCardWarning(false)
    }
    if (eligibility === paymentMethodsEligibilityEnum.PAYMENT) {
      if (type === 'CreditCardPaymentMethod') {
        return setShowCreditCardWarning(true)
      }
    }

    if (eligibility === paymentMethodsEligibilityEnum.DEPOSIT) {
      if (type === 'CreditCardPaymentMethod' && paymentSettings.creditCardFeeForDepositsEnabled) {
        return setShowCreditCardWarning(true)
      }
    }

    if (eligibility === paymentMethodsEligibilityEnum.PAYMENT_PLAN) {
      if (
        type === 'CreditCardPaymentMethod' &&
        paymentSettings.creditCardFeeForPaymentPlansEnabled
      ) {
        return setShowCreditCardWarning(true)
      }
    }

    setShowCreditCardWarning(false)
  }

  const { t } = useTranslation()
  useEffect(() => {
    if (noDefault) {
      setSelected(selected)
      return
    }
    const defaultOption = getDefaultPaymentMethod(types)
    defaultOption && setSelected(defaultOption)
  }, [types])

  const getTypeById = (id) => {
    const options = types.map((type) => type.options).flat()
    const selectedOption = options.find((option) => option.value === id)
    if (selectedOption?.type === paymentMethodTypes.CREDIT_CARD_PAYMENT_METHOD) {
      return selectedOption?.data?.['credit-card']
        ? selectedOption?.type
        : paymentMethodTypes.DEBIT_CARD_PAYMENT_METHOD
    }
    return selectedOption?.type
  }

  const onSelectChange = (e) => {
    if (!e.target.value) {
      return
    }

    const selectedOption = types
      .map((type) => {
        if (type.value.toString() === e.target.value) {
          return type
        } else {
          const foundedSubOption = type.options.find(
            (subOption) => subOption.value.toString() === e.target.value.toString(),
          )
          if (foundedSubOption) {
            return foundedSubOption
          }
        }
      })
      .filter((el) => !!el)
    setSelected(selectedOption?.[0])
    const type = getTypeById(e.target.value)
    checkIfCreditCardSelected(type)
  }

  useEffect(() => {
    checkIfCreditCardSelected(getTypeById(selected))
  }, [selected])

  const [newMethodModalIsOpened, setNewMethodModalIsOpened] = useState(false)
  const onAddMethodClick = () => {
    setNewMethodModalIsOpened(true)
  }

  const renderValue = (selectedOption) => selectedOption?.label
  const handleCreateMethod = (data) => {
    onCreateMethod(data)
    setNewMethodModalIsOpened(false)
  }

  return (
    <>
      <div className={fullWidth ? 'w-full' : 'w-80'}>
        <NDropdown
          className={noLabel ? '' : 'min-h-[4rem]'}
          isDisabled={disabled}
          label={!noLabel && (label || t('paymentType'))}
          name={'paymentType'}
          onChange={onSelectChange}
          options={types}
          placeholder={t('selectPaymentType')}
          renderValue={renderValue}
          testData="payment-method"
          value={selected}
          withCheckmarks>
          <div className={`${colors.BLUE} capitalize`} onClick={onAddMethodClick}>
            {`+ ${t('addPaymentMethod')}`}
          </div>
        </NDropdown>
        {!skipCreditCardWarning && creditCardWarning && (
          <p className="mt-2 text-xs text-orange-800">{t('convenienceFeeWarn')}</p>
        )}
      </div>

      <NewPaymentMethod
        contractID={contractID}
        isOpened={newMethodModalIsOpened}
        onCreateMethod={handleCreateMethod}
        setIsOpened={setNewMethodModalIsOpened}
        isInner
      />
    </>
  )
}

export default PaymentType

PaymentType.propTypes = {
  contractID: PT.string,
  eligibility: PT.string,
  types: PT.arrayOf(
    PT.shape({
      value: PT.shape({
        name: PT.string,
        label: PT.string,
        value: PT.string,
      }),
      options: PT.arrayOf(
        PT.shape({
          name: PT.string,
          label: PT.string,
          value: PT.string,
          typeLabel: PT.string,
          default: PT.bool,
        }),
      ),
    }),
  ),
  selected: PT.oneOf([
    PT.shape({
      value: PT.shape({
        name: PT.string,
        label: PT.string,
        value: PT.string,
      }),
      options: PT.arrayOf(
        PT.shape({
          name: PT.string,
          label: PT.string,
          value: PT.string,
          typeLabel: PT.string,
          default: PT.bool,
        }),
      ),
    }),
    PT.shape({
      name: PT.string,
      label: PT.string,
      value: PT.string,
      typeLabel: PT.string,
      default: PT.bool,
    }),
  ]),
  disabled: PT.bool,
  contractCreditCardFeeEnabled: PT.bool,
  setSelected: PT.func,
  fullWidth: PT.bool,
  onCreateMethod: PT.func,
  skipCreditCardWarning: PT.bool,
  label: PT.string,
  noLabel: PT.bool,
  noDefault: PT.bool,
}
PaymentType.defaultProps = {
  buyerID: undefined,
  types: [],
  disabled: false,
  setSelected: () => {},
  fullWidth: false,
  skipCreditCardWarning: false,
}

import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Flex, LoadingSpinner, Money, Text, Tooltip } from '../../../ui-kit'
import { validateRequiredField } from '../../../utils/validators'
import { useTranslation } from 'react-i18next'
import { reasonList } from './reasonList'
import colors from '../../../ui-kit/colors'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import sizes from '../../../ui-kit/sizes'
import fontWeight from '../../../ui-kit/fontWeight'
import NarrowList from '../../../ui-kit/components/lists/NarrowList'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { invoiceRefundableDataShape } from '../../../types/invoiceShapes'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { CreateInvoiceRefundMutation } from '../../../queriesUpdated/mutations/createInvoiceRefund.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import { parseWithMoneyFormat } from '../../../ui-kit/components/text/Money'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import { InvoicesPaidQuery } from '../../../queries/invoices/invoicesPaid.gql'
import { getSumByField, handleError } from '../../../utils/utils'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { InvoiceRefundsQuery } from '../../../queries/invoiceRefunds.gql'
import { refundStatuses } from '../../payments/refunds/util'
import { paymentTransactionStatuses } from '../../payments/paymentsTab/util'
import Decimal from 'decimal.js'

const PAYMENTS_KEY = 'PAYMENTS_KEY'
const PAYMENT_KEY = 'PAYMENT_KEY'
const DEPOSIT_KEY = 'DEPOSIT_KEY'
const CREDIT_KEY = 'CREDIT_KEY'
const PAY_CHECK_KEY = 'PAY_CHECK_KEY'

const InvoiceRefundForm = ({
  data,
  setIsOpenedModal,
  closeForm,
  isFormDirty,
  requestClose,
  setDirtyFormState,
  onSuccessSubmit,
}) => {
  const [createInvoiceRefund, { loading: requestInProgress }] = useCustomMutation({
    mutation: CreateInvoiceRefundMutation,
    mutationOptions: {
      refetchQueries: [InvoicesPaidQuery],
    },
    rollbarOptions: { operationName: 'CreateInvoiceRefundMutation', target: 'InvoiceRefundForm' },
  })
  const [statusRefreshCounter, setStatusRefreshCounter] = useState(0)
  const [createdRefundId, setCreatedRefundId] = useState()

  const [refundableData, setRefundableData] = useState([])
  const [initialData, setInitialData] = useState({})

  const getKey = (id, keyEntity) => id.replace(PAYMENT_KEY, keyEntity)

  const getUsages = (values, paymentKey, key) => {
    const usageKey = getKey(paymentKey, key)
    const usageKeys = Object.keys(values).filter((value) => value.includes(usageKey))
    return usageKeys
      .map((usage) => ({
        id: usage.split('/')[3],
        refundedCents: Math.round(values[usage] * 100),
      }))
      .filter((usage) => !!usage.refundedCents)
  }
  const getAllPayments = (values) => {
    const paymentKeys = Object.keys(values).filter((value) => value.includes(PAYMENT_KEY))
    return paymentKeys.map((key) => ({
      id: key.split('/')[1],
      refundedCents: Math.round(values[key] * 100),
      refundedAsPaycheck: !!values[getKey(key, PAY_CHECK_KEY)],
      creditUsages: getUsages(values, key, CREDIT_KEY),
      depositUsages: getUsages(values, key, DEPOSIT_KEY),
    }))
  }

  const getParsedValues = (values) => {
    const allPayments = getAllPayments(values)

    const payments = allPayments.filter(
      (payment) =>
        payment.refundedCents || payment.creditUsages.length || payment.depositUsages.length,
    )

    return {
      data: {
        contractId: data.contract.id,
        invoiceId: data.id,
        refundedCents: getFullAmount(values),
        reason: values.reason,
        memo: values.memo,
        payments,
      },
    }
  }

  const getFullAmount = (values) =>
    Object.keys(values).reduce((refundAmount, key) => {
      if (key.includes(PAYMENTS_KEY) && !key.includes(PAY_CHECK_KEY)) {
        return refundAmount + Math.round(values[key] * 100)
      }
      return refundAmount
    }, 0)

  const { newNotification } = useNotifications()

  const queryVariables = useMemo(
    () => ({
      filters: [{ key: 'id', values: [createdRefundId] }],
    }),
    [createdRefundId],
  )

  const { refetch: refetchRefund } = useCustomQuery({
    query: InvoiceRefundsQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'InvoiceRefundsQuery', target: 'RefundsTab' },
  })

  useEffect(async () => {
    if (statusRefreshCounter === 0) {
      return
    }
    const { data: loadedData } = await refetchRefund()

    const refund = loadedData?.invoiceRefunds?.data?.length && loadedData?.invoiceRefunds?.data[0]

    if (refund?.status === refundStatuses.SETTLED || refund?.status === refundStatuses.PENDING) {
      newNotification({ success: t('refundSubmittedSuccessfully') })
      if (onSuccessSubmit) {
        onSuccessSubmit()
      }
      setIsOpenedModal(false)
      return
    }

    if (refund?.status === refundStatuses.FAILED) {
      newNotification({ error: t('refundFailed') })
      setIsOpenedModal(false)
      return
    }

    const timeoutId = setTimeout(() => {
      setStatusRefreshCounter((prevState) => prevState + 1)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [statusRefreshCounter, createdRefundId])

  const onSubmit = (v) => {
    const variables = getParsedValues(v)
    createInvoiceRefund({
      variables,
    }).then(({ data, errors }) => {
      const responseData = data?.createInvoiceRefund || {}
      if (responseData?.entity) {
        if (responseData?.entity) {
          setCreatedRefundId(responseData.entity.id)
          setStatusRefreshCounter((prevState) => prevState + 1)
        } else if (responseData?.errors?.length || errors) {
          handleError(responseData?.errors || errors, newNotification)
          setIsOpenedModal(false)
        }
      }
    })
  }

  const { t } = useTranslation()
  const initialValues = {
    reason: '',
    memo: '',
  }

  useEffect(() => {
    const refundablePayments = data?.refundablePayments || []
    const refundableData = refundablePayments.reduce((acc, item) => {
      acc.push({
        id: `${PAYMENTS_KEY}/${item.id}/${PAYMENT_KEY}`,
        createdAt: item.createdAt,
        paymentTransactionId: item.paymentTransaction?.id,
        isChargeback: item.paymentTransaction?.status === paymentTransactionStatuses.CHARGEBACK,
        paymentMethodType: item.paymentMethod.type,
        paymentMethodTitle: item.paymentMethod.title,
        refundableAmountCents: item.refundableAmountCents,
        isHidden: item.paymentMethod?.type === 'CreditDepositPaymentMethod',
        refundableToOriginalPaymentMethod: item.refundableToOriginalPaymentMethod,
      })
      if (item.creditUsages?.length) {
        item.creditUsages.forEach((credit) => {
          acc.push({
            id: `${PAYMENTS_KEY}/${item.id}/${CREDIT_KEY}/${credit.id}`,
            createdAt: item.createdAt,
            paymentTransactionId: item.paymentTransactionId,
            paymentMethodType: 'creditMemo',
            paymentMethodTitle: `${t('id')}: ${credit.creditId}`,
            refundableAmountCents: credit.refundableAmountCents,
          })
        })
      }
      if (item.depositUsages?.length) {
        item.depositUsages.forEach((deposit) => {
          acc.push({
            id: `${PAYMENTS_KEY}/${item.id}/${DEPOSIT_KEY}/${deposit.id}`,
            createdAt: item.createdAt,
            paymentTransactionId: item.paymentTransactionId,
            paymentMethodType: 'deposit',
            paymentMethodTitle: `${t('id')}: ${deposit.sequentialDepositId}`,
            refundableAmountCents: deposit.refundableAmountCents,
          })
        })
      }
      return acc
    }, [])
    setRefundableData(refundableData)
    const initialData = refundableData.reduce((acc, item) => {
      acc[item.id] = new Decimal(item.refundableAmountCents).div(100).toFixed(2)
      if (
        item.paymentMethodType === 'PaycheckPaymentMethod' ||
        item.refundableToOriginalPaymentMethod === false
      ) {
        acc[getKey(item.id, PAY_CHECK_KEY)] = true
      }
      return acc
    }, initialValues)
    setInitialData(initialData)
  }, [data, t])

  const fieldValidation = (value, values, target) => {
    const refundableItem = refundableData.find((item) => item.id === target.name)
    if (Math.round(value * 100) > refundableItem.refundableAmountCents) {
      return t('invalidValue')
    }
  }
  const renderRefundFields = () =>
    refundableData.map((refundField) => (
      <>
        <div className={`flex flex-row w-full gap-4 ${refundField.isHidden ? 'hidden' : ''}`}>
          <div className="w-2/12 flex flex-col">
            <Text size={sizes.SM}>{t('date')}</Text>
            <Text color={colors.GREY} size={sizes.XS}>
              <FormattedDate date={refundField.createdAt} />
            </Text>
          </div>
          <div className="w-2/12 flex flex-col">
            <Text size={sizes.SM}>{t('transactionId')}</Text>
            <Text color={colors.GREY} size={sizes.XS}>
              {refundField.paymentTransactionId}
            </Text>
          </div>
          <div className="w-3/12 flex flex-col">
            <Text size={sizes.SM}>{t(refundField.paymentMethodType)}</Text>
            <Text color={colors.GREY} size={sizes.XS}>
              {refundField.paymentMethodTitle}
            </Text>
          </div>
          <div className="w-3/12 flex flex-col">
            <Field name={`${refundField.id}`} validate={fieldValidation}>
              {({ input, meta }) => {
                return (
                  <CurrencyInput
                    disabled={refundField.isChargeback || +input.value === 0}
                    errorMessage={
                      meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                    }
                    helperText={`${t('available')}: ${parseWithMoneyFormat(
                      refundField.refundableAmountCents,
                    )}`}
                    id={input.name}
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value}
                  />
                )
              }}
            </Field>
          </div>
          <div className="w-2/12 flex flex-col">
            <Text className="text-center pt-2" color={colors.GREY} size={sizes.XS}>
              <Field name={getKey(refundField.id, PAY_CHECK_KEY)}>
                {({ input }) => {
                  const isPayCheck =
                    refundField.paymentMethodType === 'PaycheckPaymentMethod' ||
                    !refundField.refundableToOriginalPaymentMethod
                  return (
                    <Tooltip
                      content={refundField.isChargeback ? t('chargebackTooltip') : ''}
                      placement="bottom">
                      <Toggle
                        disabled={isPayCheck || refundField.isChargeback}
                        handleChange={input.onChange}
                        id={input.name}
                        name={input.name}
                        value={input.value}
                      />
                    </Tooltip>
                  )
                }}
              </Field>
            </Text>
          </div>
        </div>
      </>
    ))

  const getFullAmountBySomeProp = useCallback(
    (propName) =>
      data.refundablePayments?.reduce((acc, item) => {
        const refundedCredits = item.creditUsages?.reduce(
          (acc, credit) => acc + (credit[propName] || 0),
          0,
        )
        const refundedDeposits = item.depositUsages?.reduce(
          (acc, deposit) => acc + (deposit[propName] || 0),
          0,
        )
        return acc + item[propName] + refundedCredits + refundedDeposits
      }, 0),
    [data],
  )
  const invoiceBalance = getFullAmountBySomeProp('amountCents')
  const getDetailsData = () => [
    <Flex className="w-full" justifyContent="between" key="invBalance">
      <Text color="text-black-500">{t('invoiceBalance')}</Text>
      <Money value={invoiceBalance} />
    </Flex>,
    <Flex className="w-full" justifyContent="between" key="previouslyRefunded">
      <Text color="text-black-500">(-) {t('previouslyRefunded')}</Text>
      <Flex>
        <Money value={getFullAmountBySomeProp('refundedAmountCents')} />
      </Flex>
    </Flex>,
    <Flex
      className="w-full border-t border-gray-300 pt-4"
      justifyContent="between"
      key="totalAvailableForRefund">
      <Text fontWeight={fontWeight.SEMIBOLD}>{t('totalAvailableForRefund')}</Text>
      <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.LG}>
        <Money value={getFullAmountBySomeProp('refundableAmountCents')} />
      </Text>
    </Flex>,
  ]

  const getDirectRefund = (values) => {
    const allPayments = getAllPayments(values)
    return allPayments.reduce(
      (acc, payment) =>
        acc + payment.refundedAsPaycheck
          ? payment.refundedCents +
            getSumByField(payment.depositUsages, 'refundedCents') +
            getSumByField(payment.creditUsages, 'refundedCents')
          : 0 +
            getSumByField(payment.depositUsages, 'refundedCents') +
            getSumByField(payment.creditUsages, 'refundedCents'),
      0,
    )
  }

  return (
    <Form
      initialValues={initialData}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, values, form }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <>
            {(requestInProgress || createdRefundId) && <LoadingSpinner className={'h-[210px]'} />}
            {!createdRefundId && (
              <form className="flex flex-col w-[50rem] px-2" onSubmit={handleSubmit}>
                <div className="w-full">
                  <Flex justifyContent="between">
                    <div>
                      <Text className="pr-1" color="text-black-500" size={sizes.SM}>
                        {t('id')}:
                      </Text>
                      <Text size={sizes.SM}>{data.id}</Text>
                    </div>
                    {!getFullAmountBySomeProp('refundableAmountCents') && (
                      <div>
                        <Text color={colors.RED}>{t('invoiceFullyRefunded')}</Text>
                      </div>
                    )}
                  </Flex>
                  <Flex>
                    <div className="flex-col w-10/12">
                      <div className="w-full mt-8">
                        <Text className="pr-2" fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
                          {t('paymentsMadeToDate')}
                        </Text>
                        <Text size={sizes.LG}>
                          ({t('invoice')} - {data.invoiceNumber})
                        </Text>
                      </div>
                    </div>
                  </Flex>
                  <div className="w-10/12 pr-4">
                    <NarrowList
                      className="w-full pt-2"
                      listItems={getDetailsData()}
                      paddingsClasses="py-2 flex"
                      isBorderHidden
                    />
                    <div className="w-full mt-6">
                      <Text className="pr-2" fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
                        {t('enterRefundDetails')}
                      </Text>
                      <div className="mt-4">
                        <Text color="text-black-500">{t('refundInformationMsg')}</Text>
                      </div>
                    </div>
                  </div>
                  <Flex className="mt-4">
                    <div className="text-right flex-col w-10/12 pr-4 pt-4 align-bottom">
                      <Button
                        label={t('fullRefund')}
                        onClick={() => form.restart()}
                        testData="full-refund"
                        variant={buttonsVariants.LINK}
                      />
                    </div>
                    <div className="text-center flex-col w-2/12">
                      <div>{t('issueCashCheckCredit')}</div>
                    </div>
                  </Flex>
                  <div className="flex flex-col w-full">
                    <NarrowList
                      listItems={renderRefundFields()}
                      paddingsClasses="py-3 flex"
                      isBorderHidden
                    />

                    <div className="flex flex-row w-full pt-4 mb-2 border-t border-gray-300">
                      <div className="flex flex-col w-1/2">
                        <Text fontWeight={fontWeight.BOLD} size={sizes.SM}>
                          {t('totalRefund')}
                        </Text>
                      </div>
                      <div className="flex items-center justify-end w-1/2">
                        <Text fontWeight={fontWeight.BOLD} size={sizes.XL}>
                          <Money value={getFullAmount(values)} />
                        </Text>
                      </div>
                    </div>

                    <div className="mt-4">
                      <Field name="reason">
                        {({ input, meta }) => {
                          const [hasValue, setHasValue] = useState(input.value !== '')
                          return (
                            <NDropdown
                              errorMessage={
                                !hasValue && meta.error && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              inputClassName="w-full"
                              label={t('reason')}
                              listClass="max-h-40 p-1 pt-0 pb-0"
                              name={input.name}
                              onBlur={() => {
                                setHasValue(input.value !== '')
                              }}
                              onChange={input.onChange}
                              onFocus={() => {
                                setHasValue(true)
                              }}
                              options={reasonList}
                              placeholder={t('reason')}
                              value={input.value}
                            />
                          )
                        }}
                      </Field>
                    </div>

                    <div className="w-full mt-6">
                      <Field name="memo">
                        {({ input, meta }) => {
                          return (
                            <Textarea
                              errorMessage={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              inputClassName="h-44"
                              label={t('memoOptional')}
                              placeholder={t('memo')}
                              {...input}
                            />
                          )
                        }}
                      </Field>
                    </div>
                  </div>
                  <div className="w-full pt-6">
                    <Text size={sizes.XL}>{t('nextSteps')}:</Text>
                  </div>
                  <div className="w-full pt-4">
                    <Text className="pr-1" size={sizes.BASE}>
                      1: {t('recordRefundOf')}
                    </Text>
                    <Text color={colors.PRIMARY} size={sizes.LG}>
                      <Money className="pr-1" value={getFullAmount(values)} />
                    </Text>
                    <Text size={sizes.BASE}>{t('inYourErp')}</Text>
                  </div>
                  {!!getDirectRefund(values) && (
                    <div className="w-full pt-2">
                      <Text className="pr-1" size={sizes.BASE}>
                        2: {t('issueCashCheckPayment')}
                      </Text>
                      <Text color={colors.PRIMARY} size={sizes.LG}>
                        <Money className="pr-1" value={getDirectRefund(values)} />
                      </Text>
                      <Text size={sizes.BASE}>{t('directToCustomer')}</Text>
                    </div>
                  )}

                  <div className="w-full mt-6 flex flex-row justify-end">
                    <Button
                      className="mr-2"
                      label={t('cancel')}
                      onClick={requestClose}
                      testData="cancel-invoice-refund"
                      variant={buttonsVariants.TERTIARY}
                    />
                    <Button
                      disabled={
                        requestInProgress || !getFullAmountBySomeProp('refundableAmountCents')
                      }
                      isLoading={requestInProgress}
                      label={t('issueRefund')}
                      testData="submit-issue-refund"
                      type="submit"
                    />
                  </div>
                </div>

                <AlertModal confirmClose={closeForm} />
              </form>
            )}
          </>
        )
      }}
      validate={(values) => ({
        reason: validateRequiredField(values.reason),
      })}
    />
  )
}

InvoiceRefundForm.propTypes = {
  data: invoiceRefundableDataShape,
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  setIsOpenedModal: PropTypes.func,
  setDirtyFormState: PropTypes.func.isRequired,
  onSuccessSubmit: PropTypes.func,
}

export default InvoiceRefundForm

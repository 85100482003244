import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { validateRequiredField, validateZipCode } from '../../../../utils/validators'
import fontWeight from '../../../../ui-kit/fontWeight'
import sizes from '../../../../ui-kit/sizes'
import { countriesList } from '../../../../utils/countriesList'
import NDropdown from '../../../../ui-kit/components/dropdown/NDropdown'
import { UpdateBuyerMutation } from '../../../../queries/mutations/updateBuyerRequest.gql'
import { BuyerQuery } from '../../../../queries/buyers.gql'
import PT from 'prop-types'
import { getOnlyNumbers } from '../../../../utils/utils'
import { useNotifications } from '../../../../hooks/useNotifications'
import { Input, Text } from '../../../../ui-kit'
import Button from '../../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { useBeforeUnload } from '../../../../hooks/useBeforeUnload'
import { useDirtyFormAlert } from '../../../../hooks/useDirtyFormAlert'
import AlertModal from '../../../../ui-kit/components/alertModal/AlertModal'
import Checkbox from '../../../../ui-kit/components/inputs/Checkbox'
import { isEqual } from 'lodash'
import { normalizePhone } from '../../../../utils'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

const GeneralInfoEditForm = ({ buyer, setEditMode }) => {
  const { t } = useTranslation()

  const initialValues = {
    name: buyer.name,
    dba: buyer.dba,
    isDbaDifferentFromName: buyer.name !== buyer.dba,
    officeCountry: buyer.officeAddress?.country,
    officeStreet: buyer.officeAddress?.street,
    officeStreet2: buyer.officeAddress?.street2,
    officeCity: buyer.officeAddress?.city,
    officeState: buyer.officeAddress?.state,
    officeZipCode: buyer.officeAddress?.zipCode,
    billingCountry: buyer.billingAddress?.country,
    billingStreet: buyer.billingAddress?.street,
    billingStreet2: buyer.billingAddress?.street2,
    billingCity: buyer.billingAddress?.city,
    billingState: buyer.billingAddress?.state,
    billingZipCode: buyer.billingAddress?.zipCode,
    supportPhoneNumber: buyer.formattedSupportPhoneNumber,
    isBillingAddressDifferentFromOfficeAddress: buyer.billingAddress
      ? !isEqual(
          [
            buyer.officeAddress?.country,
            buyer.officeAddress?.street,
            buyer.officeAddress?.street2,
            buyer.officeAddress?.city,
            buyer.officeAddress?.state,
            buyer.officeAddress?.zipCode,
          ],
          [
            buyer.billingAddress?.country,
            buyer.billingAddress?.street,
            buyer.billingAddress?.street2,
            buyer.billingAddress?.city,
            buyer.billingAddress?.state,
            buyer.billingAddress?.zipCode,
          ],
        )
      : false,
  }

  const onCompleted = useCallback(() => {
    setEditMode(false)
  }, [])
  const [update, { loading }] = useCustomMutation({
    mutation: UpdateBuyerMutation,
    onCompleted,
    rollbarOptions: { operationName: 'UpdateBuyerMutation', target: 'GeneralInfoEditForm' },
    mutationOptions: {
      refetchQueries: [BuyerQuery],
    },
  })
  const { newNotification } = useNotifications()

  const onSubmit = (values) => {
    const data = {
      name: values.name,
      dba: values.isDbaDifferentFromName ? values.dba : values.name,
      website: values.website,
      officeAddressAttributes: {
        country: values.officeCountry,
        street: values.officeStreet,
        street2: values.officeStreet2,
        city: values.officeCity,
        state: values.officeState,
        zipCode: values.officeZipCode,
      },
      billingAddressAttributes: values.isBillingAddressDifferentFromOfficeAddress
        ? {
            country: values.billingCountry,
            street: values.billingStreet,
            street2: values.billingStreet2,
            city: values.billingCity,
            state: values.billingState,
            zipCode: values.billingZipCode,
          }
        : {},
      supportPhoneNumber: values.supportPhoneNumber,
    }
    const variables = {
      data,
      id: buyer.id,
    }

    update({ variables }).then(({ data }) => {
      const responseData = data?.updateBuyer || {}

      if (responseData?.entity) {
        newNotification({ success: t('informationWasUpdated') })
      }
    })
  }

  const closeForm = useCallback(() => {
    setEditMode(false)
  }, [setEditMode])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <div className="w-full">
      <Form
        initialValues={initialValues}
        mutators={{
          clearOfficeState: (args, state, utils) => {
            utils.changeValue(state, 'officeState', () => '')
          },
          clearBillingState: (args, state, utils) => {
            utils.changeValue(state, 'billingState', () => '')
          },
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirty, values, form }) => {
          useBeforeUnload({ when: dirty })
          dirty !== isFormDirty && setDirtyFormState(dirty)

          return (
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className="w-2/3">
                <Field name="name">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('legalName')}
                        placeholder={t('name')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="mt-4">
                <Field name="isDbaDifferentFromName">
                  {({ input }) => (
                    <Checkbox
                      id={input.name}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )}
                </Field>

                <label className="ml-2 text-black-500 font-medium" htmlFor="isDbaDifferentFromName">
                  {t('dbaDifferentThanAbove')}
                </label>
              </div>

              {values.isDbaDifferentFromName && (
                <div className="w-2/3 mt-4">
                  <Field name="dba">
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('dba')}
                          placeholder={t('dba')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
              )}

              <div className="mt-6">
                <Text fontWeight={fontWeight.NORMAL} size={sizes.LG}>
                  {t('officeAddress')}
                </Text>
              </div>

              <div className="mt-4 w-4/12 pr-4">
                <Field name="officeCountry">
                  {({ input, meta }) => {
                    return (
                      <NDropdown
                        defaultValue={countriesList[0].value}
                        errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                        id={input.name}
                        inputClassName="w-full"
                        label={t('countryRegion')}
                        name={input.name}
                        onChange={(e) => {
                          const nextCountry = e.target.value

                          if (
                            nextCountry !== values.country &&
                            nextCountry === countriesList[0].value
                          ) {
                            form.mutators.clearOfficeState()
                          }

                          input.onChange(nextCountry)
                        }}
                        options={countriesList}
                        placeholder={t('countryRegion')}
                        value={input.value}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 pr-8">
                <Field name="officeStreet">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('streetAddress')}
                        placeholder={t('address')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 pr-8">
                <Field name="officeStreet2">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('streetAddress2')}
                        placeholder={t('address2')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 flex flex-row space-x-2 pr-8">
                <div className="w-4/12">
                  <Field name="officeCity">
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('city')}
                          placeholder={t('city')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>

                <div className="w-4/12">
                  <Field name="officeState">
                    {({ input, meta }) => (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('stateProvince')}
                        maxLength={25}
                        placeholder={t('state')}
                        type="text"
                        {...input}
                      />
                    )}
                  </Field>
                </div>

                <div className="w-4/12">
                  <Field name="officeZipCode" parse={getOnlyNumbers}>
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('zipPostal')}
                          maxLength={15}
                          placeholder={t('zip')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
              </div>

              <div className="mt-4">
                <Field name="isBillingAddressDifferentFromOfficeAddress">
                  {({ input }) => (
                    <Checkbox
                      id={input.name}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )}
                </Field>

                <label
                  className="ml-2 text-black-500 font-medium"
                  htmlFor="isBillingAddressDifferentFromOfficeAddress">
                  {t('billingAddressDifferentThanAbove')}
                </label>
              </div>

              {values.isBillingAddressDifferentFromOfficeAddress && (
                <>
                  <div className="mt-6">
                    <Text fontWeight={fontWeight.NORMAL} size={sizes.LG}>
                      {t('billingAddress')}
                    </Text>
                  </div>

                  <div className="mt-4 w-2/3">
                    <Field name="billingCountry">
                      {({ input, meta }) => {
                        return (
                          <NDropdown
                            errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                            id={input.name}
                            inputClassName="w-full"
                            label={t('countryRegion')}
                            name={input.name}
                            onChange={(e) => {
                              const nextCountry = e.target.value

                              if (
                                nextCountry !== values.country &&
                                nextCountry === countriesList[0].value
                              ) {
                                form.mutators.clearBillingState()
                              }

                              input.onChange(nextCountry)
                            }}
                            options={countriesList}
                            placeholder={t('countryRegion')}
                            value={input.value}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-4 pr-8">
                    <Field name="billingStreet">
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('streetAddress')}
                            placeholder={t('address')}
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-4 pr-8">
                    <Field name="billingStreet2">
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('streetAddress2')}
                            placeholder={t('address2')}
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-full mt-6 flex flex-row space-x-2 pr-8">
                    <div className="w-4/12">
                      <Field name="billingCity">
                        {({ input, meta }) => {
                          return (
                            <Input
                              errorMessage={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              label={t('city')}
                              placeholder={t('city')}
                              {...input}
                            />
                          )
                        }}
                      </Field>
                    </div>

                    <div className="w-4/12">
                      <Field name="billingState">
                        {({ input, meta }) => (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('stateProvince')}
                            maxLength={25}
                            placeholder={t('state')}
                            type="text"
                            {...input}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="w-4/12">
                      <Field name="billingZipCode" parse={getOnlyNumbers}>
                        {({ input, meta }) => {
                          return (
                            <Input
                              errorMessage={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              label={t('zipPostal')}
                              maxLength={15}
                              placeholder={t('zip')}
                              {...input}
                            />
                          )
                        }}
                      </Field>
                    </div>
                  </div>
                </>
              )}

              <div className="mt-4 w-4/12 pr-4">
                <Field name="supportPhoneNumber" parse={normalizePhone}>
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('phone')}
                        placeholder={t('phone')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-4 flex flex-row justify-end">
                <Button
                  className="mr-4"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="cancel-edit-general-info"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  disabled={loading}
                  label={t('save')}
                  testData="save-edit-general-info"
                  type="submit"
                />
              </div>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
        validate={(values) => {
          const commonFields = {
            name: validateRequiredField(values.name),
            officeCountry: validateRequiredField(values.officeCountry),
            officeStreet: validateRequiredField(values.officeStreet),
            officeStreet2: values.officeStreet2
              ? validateRequiredField(values.officeStreet)
              : undefined,
            officeCity: validateRequiredField(values.officeCity),
            officeState: validateRequiredField(values.officeState),
            officeZipCode:
              values.officeCountry === 'US'
                ? validateRequiredField(values.officeZipCode) ||
                  validateZipCode(values.officeZipCode)
                : validateRequiredField(values.officeZipCode),
            supportPhoneNumber: validateRequiredField(values.supportPhoneNumber),
          }
          const dbaField = values.isDbaDifferentFromName
            ? { dba: validateRequiredField(values.dba) }
            : {}
          const billingAddressFields = values.isBillingAddressDifferentFromOfficeAddress
            ? {
                billingCountry: validateRequiredField(values.billingCountry),
                billingStreet: validateRequiredField(values.billingStreet),
                billingStreet2: values.billingStreet2
                  ? validateRequiredField(values.billingStreet)
                  : undefined,
                billingCity: validateRequiredField(values.billingCity),
                billingState: validateRequiredField(values.billingState),
                billingZipCode:
                  values.billingCountry === 'US'
                    ? validateRequiredField(values.billingZipCode) ||
                      validateZipCode(values.billingZipCode)
                    : validateRequiredField(values.billingZipCode),
              }
            : {}

          return {
            ...commonFields,
            ...dbaField,
            ...billingAddressFields,
          }
        }}
      />
    </div>
  )
}

GeneralInfoEditForm.propTypes = {
  setEditMode: PT.func,
  buyer: PT.shape({
    billingAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      street2: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    id: PT.oneOfType([PT.string, PT.number]),
    officeAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      street2: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    name: PT.string,
    dba: PT.string,
    supportPhoneNumber: PT.string,
    formattedSupportPhoneNumber: PT.string,
  }).isRequired,
}

export default GeneralInfoEditForm

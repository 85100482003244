import createBasicActivities from './createBasicActivities'
import { camelCase } from 'lodash'
import {
  ACTIVITY_TYPES,
  PAYMENT_DISPUTE_ACTIONS,
  PAYMENT_PLAN_STATUSES,
} from '../constants/activities'
import colors from '../ui-kit/colors'
import { eventActions, paymentTransactionStatuses } from '../pages/payments/paymentsTab/util'
import { DateTime } from 'luxon'
import { refundStatuses } from '../pages/payments/refunds/util'
import { paymentPromiseStatuses, statusP2PMapping } from '../pages/payments/promiseToPay/util'
import { statusPaymentPlanMapping } from '../pages/payments/paymentPlan/util'
import { refundLogo } from '../ui-kit/assets'
import { Button, Flex, Money, Tooltip } from '../ui-kit'
import React from 'react'
import buttonsVariants from '../ui-kit/buttonsVariants'
import store from '../store'
import { showMemoForm } from '../store/actions/memo'
import { MEMO_TYPE } from '../ui-kit/components/memo/Memo'

const formatActivities = ({
  activities,
  dueDate,
  amountCents,
  invoiceDate,
  t,
  contractId,
  invoiceData,
  currentUser,
  payableInvoiceAssignments,
}) => {
  if (!activities) {
    return []
  }
  const basicActivities = createBasicActivities({
    dueDate,
    invoicedAmount: amountCents,
    invoiceData,
    paymentPromises: activities.filter((el) => el.eventEntity === ACTIVITY_TYPES.PAYMENT_PROMISE),
    paymentPlans: activities.filter((el) => el.eventEntity === ACTIVITY_TYPES.PAYMENT_PLAN),
    disputes: activities.filter((el) => el.eventEntity === ACTIVITY_TYPES.DISPUTE),
    paymentRequests: activities.filter((el) => el.eventEntity === ACTIVITY_TYPES.PAYMENT_REQUEST),
    payments: activities.filter((el) => el.eventEntity === ACTIVITY_TYPES.PAYMENT),
    refunds: activities.filter((el) => el.eventEntity === ACTIVITY_TYPES.REFUND),
    audits: activities.filter((el) => el.eventEntity === ACTIVITY_TYPES.AUDIT),
    currentUser,
    t,
    contractId,
    payableInvoiceAssignments,
  })
  const restActivities = activities
    .map((activity) => {
      return {
        date: activity.entity?.createdAt || activity.createdAt,
        createdAt: activity.createdAt,
        createdBy: activity.createdBy,
        description: activity.description,
        memo: activity.entity?.memo,
        id: activity.entity?.paymentRequestId || activity.id,
        reason: activity.entity?.reason,
        email: activity.entity?.email,
        phone: activity.entity?.phone,
        ...basicActivities[`${camelCase(activity.eventEntity)}${activity.id}`],
      }
    })
    .filter((el) => el.title)

  const sortedActivities = restActivities.sort(function (prev, next) {
    return DateTime.fromISO(prev.date).toMillis() - DateTime.fromISO(next.date).toMillis()
  })
  return [
    {
      ...basicActivities.invoiceDate,
      date: invoiceDate,
    },
    { ...basicActivities.dueDate, date: dueDate },
    ...sortedActivities,
  ]
}

export const getPaymentTitle = (paymentStatus) => {
  switch (camelCase(paymentStatus)) {
    case eventActions.CREATE:
      return 'create'
    case eventActions.IN_PROCESS:
      return 'pendingPayment'
    case eventActions.PAID:
      return 'payment'
    case eventActions.FAILED:
      return 'failedPayment'
    case eventActions.VOIDED:
      return 'voided'
    case eventActions.CHARGEBACK:
      return 'chargeback'
  }
}
export const getPaymentIconName = (paymentStatus) => {
  switch (paymentStatus) {
    case eventActions.PAID:
    case eventActions.FAILED:
    case eventActions.CHARGEBACK:
      return 'currencyDollar'
    default:
      return 'check'
  }
}
export const getPaymentIconColor = (paymentStatus) => {
  switch (paymentStatus) {
    case eventActions.PAID:
    case eventActions.FAILED:
    case eventActions.CHARGEBACK:
      return colors.WHITE
    case eventActions.CREATE:
      return colors.GREEN
    default:
      return colors.PRIMARY
  }
}
export const getPaymentBgColor = (paymentStatus) => {
  switch (paymentStatus) {
    case eventActions.PAID:
      return 'bg-green-450'
    case eventActions.FAILED:
    case eventActions.CHARGEBACK:
      return 'bg-darkRed-700'
    default:
      return 'bg-transparent'
  }
}
export const getPaymentBorderColor = (paymentStatus) => {
  switch (paymentStatus) {
    case eventActions.FAILED:
    case eventActions.CHARGEBACK:
      return 'border-darkRed-700'
    default:
      return 'border-green-450'
  }
}

export const getDisputeProps = (eventAction, t) =>
  eventAction === PAYMENT_DISPUTE_ACTIONS.RESOLVE
    ? {
        title: `${t('dispute')} (${t('resolved')})`,
        iconColor: 'text-green-450',
        borderColorName: 'border-green-450',
      }
    : {
        title: `${t('dispute')} (${t('created')})`,
        iconColor: 'text-darkRed-700',
        borderColorName: 'border-darkRed-700',
      }

export const getPaymentPlanProps = (paymentPlan, t) => {
  const title = `${t('paymentPlan')} (${t(
    statusPaymentPlanMapping(paymentPlan.paymentPlanStatus, paymentPlan.approvedAt)?.label,
  )})`
  const basicProps = {
    title,
    iconName: 'clipboardCheck',
    iconColor: 'text-green-450',
    bgColorName: 'transparent',
    borderColorName: 'border-green-450',
  }
  switch (paymentPlan.paymentPlanStatus) {
    case PAYMENT_PLAN_STATUSES.INITIAL: {
      if (paymentPlan.approvedAt) {
        return basicProps
      }
      return {
        ...basicProps,
        iconColor: 'text-darkRed-650',
        borderColorName: 'border-darkRed-650',
      }
    }
    case PAYMENT_PLAN_STATUSES.PARTIALLY_PAID:
      return {
        ...basicProps,
      }
    case PAYMENT_PLAN_STATUSES.PAID:
      return {
        ...basicProps,
        bgColorName: 'bg-green-450',
        iconColor: colors.WHITE,
        borderColorName: 'border-green-450',
      }
    case PAYMENT_PLAN_STATUSES.CANCELLED:
      return {
        title: title,
        iconName: 'x',
        iconColor: colors.WHITE,
        bgColorName: 'bg-warmBlack-400',
        borderColorName: 'border-warmBlack-400',
      }
  }
}

export const getPaymentPromiseProps = (paymentPlan, t) => {
  const title = `${t('p2p')} (${t(statusP2PMapping[paymentPlan.paymentPromiseStatus]?.label)})`
  const basicProps = {
    title: title,
    iconName: 'cash',
    iconColor: 'text-orange-600',
    bgColorName: 'transparent',
  }
  switch (paymentPlan.paymentPromiseStatus) {
    case paymentPromiseStatuses.ACTIVE:
      return {
        ...basicProps,
        borderColorName: 'border-orange-600',
      }
    case paymentPromiseStatuses.COMPLETED:
      return {
        ...basicProps,
        borderColorName: 'border-green-450',
      }
    case paymentPromiseStatuses.EXPIRED:
      return {
        ...basicProps,
        borderColorName: 'border-darkRed-650',
      }
    case paymentPromiseStatuses.CANCELLED:
      return {
        title: title,
        iconName: 'x',
        iconColor: colors.WHITE,
        bgColorName: 'bg-warmBlack-400',
        borderColorName: 'border-warmBlack-400',
      }
  }
}

export const formatCustomerActivity = (activity, t) => {
  if (!activity) {
    return null
  }
  switch (activity.eventEntity) {
    case ACTIVITY_TYPES.PAYMENT_PROMISE:
      return {
        ...activity,
        ...getPaymentPromiseProps(activity.entity, t),
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
    case ACTIVITY_TYPES.PAYMENT_PLAN:
      return {
        ...activity,
        ...getPaymentPlanProps(activity.entity, t),
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
    case ACTIVITY_TYPES.DISPUTE:
      return {
        ...activity,
        ...getDisputeProps(activity.entityActualEvent?.eventAction, t),
        iconName: 'hand',
        bgColorName: 'transparent',
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
    case ACTIVITY_TYPES.PAYMENT:
      return {
        ...activity,
        title: getPaymentTitle(activity?.entityActualEvent?.eventAction),
        iconName: getPaymentIconName(activity?.entityActualEvent?.eventAction),
        iconColor: getPaymentIconColor(activity?.entityActualEvent?.eventAction),
        bgColorName: getPaymentBgColor(activity?.entityActualEvent?.eventAction),
        borderColorName: getPaymentBorderColor(activity?.entityActualEvent?.eventAction),
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
    case ACTIVITY_TYPES.PAYMENT_REQUEST:
      return {
        ...activity,
        iconName: 'mail',
        iconColor: 'text-violet-500',
        bgColorName: 'transparent',
        borderColorName: 'border-violet-500',
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
    case ACTIVITY_TYPES.NOTE:
      return {
        ...activity,
        iconName: 'annotation',
        iconColor: 'text-gray-900',
        bgColorName: 'transparent',
        borderColorName: 'bg-white',
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
    case ACTIVITY_TYPES.DEPOSIT:
      return {
        ...activity,
        iconName: null,
        iconElement: (
          <div className={'text-green-450 font-semibold text-[10px]'}>{t('deposit')}</div>
        ),
        iconColor: 'text-green-450',
        bgColorName: 'transparent',
        borderColorName: 'border-green-450',
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
    case ACTIVITY_TYPES.REFUND:
      return {
        ...activity,
        iconName: null,
        iconElement: <img alt="Suppli" className="h-8" src={refundLogo} />,
        iconColor: 'text-primary-800',
        bgColorName: 'transparent',
        borderColorName: 'border-primary-800',
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
    case ACTIVITY_TYPES.CREDIT_REVIEW:
      return {
        ...activity,
        iconName: null,
        iconElement: <div className={'text-black-700 font-semibold'}>{t('creditReviewShort')}</div>,
        iconColor: 'text-black-700',
        bgColorName: 'transparent',
        borderColorName: 'text-black-700',
        baseUrl: '',
        date: activity.entity?.createdAt || activity.createdAt,
        lastUpdateDate: activity.entityActualEvent?.createdAt,
      }
  }
}

export const getDueDateIconProps = (dueDate, invoice) => {
  const formatDueDate = DateTime.fromISO(dueDate)
  const today = DateTime.now()
  const weekBefore = formatDueDate.minus({ days: 7 })
  if (formatDueDate.startOf('day') < today.startOf('day') && invoice.outstandingAmountCents) {
    return {
      iconName: 'exclamation',
      bgColorName: 'bg-darkRed-700',
      iconColor: 'text-white',
      borderColorName: '',
    }
  }
  if (weekBefore.startOf('day') < today.startOf('day') && invoice.outstandingAmountCents) {
    return {
      iconName: 'exclamation',
      bgColorName: 'bg-yellow-300',
      iconColor: 'text-black-700',
      borderColorName: 'bg-yellow-300',
    }
  }
  return {
    iconName: 'exclamation',
    bgColorName: 'bg-white',
    iconColor: 'text-black-500',
    borderColorName: 'border-black-500',
  }
}

export const getPaymentProps = (payment) => {
  switch (payment.status) {
    case paymentTransactionStatuses.SUBMITTED:
    case paymentTransactionStatuses.ENQUEUED:
    case paymentTransactionStatuses.PROCESSING:
      return {
        iconName: 'fastForward',
        iconColor: 'text-green-600',
        bgColorName: 'bg-white',
        borderColorName: 'border-green-600',
      }
    case paymentTransactionStatuses.PAID:
      return {
        iconName: 'currencyDollar',
        bgColorName: 'bg-green-600',
        iconColor: 'text-white',
        borderColorName: 'border-green-600',
      }
    case paymentTransactionStatuses.FAILED:
    case paymentTransactionStatuses.CHARGEBACK:
      return {
        iconName: 'x',
        bgColorName: 'bg-darkRed-700',
        iconColor: 'text-white',
        borderColorName: 'border-darkRed-700',
      }
    case paymentTransactionStatuses.VOIDED:
      return {
        iconName: 'x',
        bgColorName: 'bg-warmBlack-400',
        iconColor: 'text-white',
        borderColorName: 'border-warmBlack-400',
      }
  }
}

export const getRefundProps = (refund) => {
  switch (refund.status) {
    case refundStatuses.INITIAL:
    case refundStatuses.PENDING:
      return {
        iconName: 'fastForward',
        iconColor: 'text-orange-900',
        bgColorName: 'bg-white',
        borderColorName: 'text-orange-900',
      }
    case refundStatuses.SETTLED:
      return {
        iconName: 'check',
        bgColorName: 'bg-orange-900',
        iconColor: 'text-white',
        borderColorName: 'border-orange-900',
      }
    case refundStatuses.FAILED:
      return {
        iconName: 'x',
        bgColorName: 'bg-darkRed-700',
        iconColor: 'text-white',
        borderColorName: 'border-darkRed-700',
      }
  }
}

export const getOverrideActivity = (el, t, refetchQuery) => ({
  title: `${t('erpOverride')}`,
  bgColorName: 'bg-yellow-500',
  borderColorName: 'border-yellow-500',
  iconName: 'circleStack',
  iconColor: colors.WHITE,
  baseUrl: '',
  date: el.createdAt,
  additionalInfoComponent: () => {
    return (
      <>
        <Flex>
          <div className={'text-black-500'}>
            {t('previousAmtOS')}:{' '}
            <Money
              value={
                el.auditedChanges?.outstanding_amount_cents?.length &&
                el.auditedChanges.outstanding_amount_cents[0]
              }
            />
          </div>
        </Flex>
        <Flex>
          <div className={'text-black-500'}>
            {t('newAmtOS')}:{' '}
            <Money
              value={
                el.auditedChanges?.outstanding_amount_cents?.length &&
                el.auditedChanges.outstanding_amount_cents[1]
              }
            />
          </div>
        </Flex>
        <Flex>
          <div className={'text-black-500'}>
            {t('overrideId')}: {el?.id}
          </div>
        </Flex>
        <Flex>
          {!!el?.memo && (
            <Tooltip
              className="text-ellipsis overflow-hidden text-black-500 max-w-[13rem]"
              content={el?.memo}>
              {t('memo')}: {el?.memo}
            </Tooltip>
          )}
        </Flex>
        <Flex>
          <div>
            <Button
              label={el?.memo ? t('editMemo') : t('addMemo')}
              onClick={() =>
                store.dispatch(
                  showMemoForm({
                    id: el?.id,
                    subtitle: `${t('overrideId')}: ${el?.id}`,
                    memoType: MEMO_TYPE.INVOICE_OVERRIDE,
                    text: el?.memo,
                    refetchQuery,
                  }),
                )
              }
              testData="add-override-memo"
              variant={buttonsVariants.LINK}
            />
          </div>
        </Flex>
      </>
    )
  },
})

export default formatActivities

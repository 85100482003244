import PropTypes from 'prop-types'
import React from 'react'
import PT from 'prop-types'
import { FormattedDate, Money, Text } from '../../../ui-kit'
import Icon from '../../../ui-kit/components/icons/Icon'
import colors from '../../../ui-kit/colors'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { getIconView } from './util'

const HorizontalActivity = ({ activity }) => {
  return (
    <div className="flex flex-row min-w-[35rem] max-w-2xl overflow-x-auto mr-4">
      {activity.map((item, index) => (
        <div className="flex mr-4" key={item.id}>
          {!!index && (
            <div className="mt-2 mr-4">
              <Icon color={colors.GREY} name="connectorHor" type="outline" />
            </div>
          )}
          <div className="flex flex-col items-center w-max" key={item.id}>
            {getIconView(item, true)}
            <Text className="mt-2" fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
              {item.label}
            </Text>
            <Text fontWeight={fontWeight.LIGHT} size={sizes.SM}>
              <Money value={item.amount} />
            </Text>
            <Text fontWeight={fontWeight.LIGHT} size={sizes.SM}>
              <FormattedDate date={item.date} />
            </Text>
          </div>
        </div>
      ))}
    </div>
  )
}

HorizontalActivity.propTypes = {
  activity: PropTypes.arrayOf(
    PT.shape({
      label: PT.string,
      href: PT.string,
      onClick: PT.func,
    }),
  ),
}

export default HorizontalActivity

import React from 'react'
import PT from 'prop-types'
import FileSaver from 'file-saver'

const DownloadFiles = ({ files, children }) => {
  const downloadFile = ({ url, name }) => {
    fetch(url, { method: 'GET' })
      .then((res) => {
        return res.blob()
      })
      .then((blob) => {
        FileSaver.saveAs(blob, name)
      })
      .catch((err) => {
        console.error('==================')
        console.error('err', err)
        console.error('==================')
      })
  }

  const handleDownload = () => {
    if (files.length === 1) {
      downloadFile(files[0])
    } else {
      files.forEach((file, i) => {
        setTimeout(() => {
          downloadFile(file)
        }, i * 1000)
      })
    }
  }

  return <>{children(handleDownload)}</>
}

DownloadFiles.propTypes = {
  files: PT.arrayOf(
    PT.shape({
      url: PT.string,
      name: PT.string,
    }),
  ).isRequired,
  children: PT.func.isRequired,
}

export default DownloadFiles

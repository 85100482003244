export const buyerUserAttributes = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  companyRole: '',
  sendInvitation: true,
  officePhoneNumber: '',
  extension: '',
}

import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { MONEY } from '../../../utils/dataTypes'
import { Money } from '../../index'
import { useTranslation } from 'react-i18next'

const InformationBox = ({
  title,
  value,
  valueLabel,
  secondaryLabel,
  secondaryValue,
  className,
  type,
  onClick,
}) => {
  const { t } = useTranslation()
  const containerClassnames = cx(
    'xl:px-6 px-3 xl:py-5 py-3 bg-white shadow rounded-lg overflow-hidden',
    {
      [className]: !!className,
    },
    onClick && 'cursor-pointer',
  )
  const content = type === MONEY ? <Money value={value} /> : value

  return (
    <div className={containerClassnames} onClick={onClick}>
      <div className="text-sm font-medium text-gray-500 truncate">{title}</div>

      <div className="mt-1 2xl:text-xl xl:text-lg text-md font-semibold text-gray-900">
        {content}
        {valueLabel && (
          <span className="2xl:text-base text-sm font-medium pl-2">{t(valueLabel)}</span>
        )}
      </div>
      {secondaryValue && secondaryLabel && (
        <div className={'mt-1 text-gray-500 text-xs'}>
          {secondaryLabel}: {secondaryValue}
        </div>
      )}
    </div>
  )
}

InformationBox.propTypes = {
  className: PT.string,
  title: PT.string.isRequired,
  type: PT.string,
  value: PT.node.isRequired,
  valueLabel: PT.string,
  secondaryLabel: PT.string,
  secondaryValue: PT.node,
  onClick: PT.func,
}

InformationBox.defaultProps = {
  className: '',
  type: null,
  valueLabel: null,
  secondaryLabel: null,
  secondaryValue: null,
  onClick: null,
}

export default InformationBox

import React, { useMemo } from 'react'
import PT from 'prop-types'
import { LoadingSpinner, Box, Flex, Heading, Money, Text } from '../../../../ui-kit'
import { useTranslation } from 'react-i18next'
import sizes from '../../../../ui-kit/sizes'
import DividedList from '../../../../ui-kit/components/sidebar/DividedList'
import fontWeight from '../../../../ui-kit/fontWeight'
import Button from '../../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { useNavigate } from 'react-router-dom'
// import { parseWithMoneyFormat } from '../../../../ui-kit/components/text/Money'

const AccountSummary = ({ data, isLoading, isVendorDashboard }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const descriptionData = useMemo(
    () => [
      {
        label: t('outstandingInvoices'),
        value: (
          <Button
            label={<Money value={data.nonFinanceChargesOutstandingAmountCents} />}
            onClick={() =>
              navigate(
                data.id ? `/customers/${data.id}/invoices/outstanding` : '/invoices/outstanding',
              )
            }
            size={sizes.SM}
            testData="summary-outstanding-link"
            variant={buttonsVariants.LINK}
          />
        ),
      },
      {
        label: t('outstandingFinanceCharges'),
        value: (
          <Button
            label={<Money value={data.financeChargesOutstandingAmountCents} />}
            onClick={() =>
              navigate(
                data.id
                  ? `/customers/${data.id}/invoices/outstanding?filters=hasActionEntity.finance_charges`
                  : '/invoices/outstanding?filters=hasActionEntity.finance_charges',
              )
            }
            size={sizes.SM}
            testData="summary-outstanding-link"
            variant={buttonsVariants.LINK}
          />
        ),
      },
      {
        label: `${t('less')}: ${t('availableCredits')}`,
        value: (
          <Button
            label={<Money value={data.availableCreditAmountCents} />}
            onClick={() =>
              navigate(data.id ? `/customers/${data.id}/invoices/credits` : '/invoices/credits')
            }
            size={sizes.SM}
            testData="summary-outstanding-link"
            variant={buttonsVariants.LINK}
          />
        ),
      },
      {
        label: `${t('less')}: ${t('availableDeposits')}`,
        value: (
          <Button
            label={<Money value={data.availableDepositAmountCents} />}
            onClick={() =>
              navigate(data.id ? `/customers/${data.id}/invoices/deposits` : '/invoices/deposits')
            }
            size={sizes.SM}
            testData="summary-outstanding-link"
            variant={buttonsVariants.LINK}
          />
        ),
      },
    ],
    [t, data, navigate],
  )

  return (
    <Box className="h-[298px]">
      {isLoading ? (
        <LoadingSpinner className="w-full h-full" />
      ) : (
        <Flex column>
          <Heading className="mb-3 font-medium" isBold={false} size={sizes.XL}>
            {isVendorDashboard ? t('summary') : t('accountSummary')}
          </Heading>
          <DividedList content={descriptionData} skipDivide />
          <Flex alignItems="center" className="border-t min-h-10" justifyContent="between">
            <Text color="text-black-500" fontWeight={fontWeight.BOLD}>
              {t('netDue')}
            </Text>
            <Money className="font-bold text-black-500" value={data.netDueAmountCents} />
          </Flex>
          <Flex alignItems="center" className="min-h-5" justifyContent="between">
            <Text color="text-warmBlack-400" size={sizes.SM}>
              {t('pendingPayments')}
            </Text>
            <Button
              label={<Money value={data.pendingPaymentsAmountCents} />}
              onClick={() =>
                navigate(
                  data.id
                    ? `/customers/${data.id}/payments?filters=status.pending`
                    : '/payments?filters=status.pending%26%26payableType.Payment',
                )
              }
              size={sizes.SM}
              testData="summary-outstanding-link"
              variant={buttonsVariants.LINK}
            />
          </Flex>
        </Flex>
      )}
    </Box>
  )
}

export default AccountSummary

AccountSummary.propTypes = {
  data: PT.shape({
    id: PT.string,
    netDueAmountCents: PT.number,
    financeChargesOutstandingAmountCents: PT.number,
    nonFinanceChargesOutstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    pendingPaymentsAmountCents: PT.number,
  }),
  isLoading: PT.bool,
  isVendorDashboard: PT.bool,
}

AccountSummary.defaultProps = {
  data: {},
  isLoading: true,
  isVendorDashboard: false,
}

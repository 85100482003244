import React, { useEffect } from 'react'
import { Section, Tabs } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import PageHeader from '../../components/headers/PageHeader'
import { camelCase, isEqual, snakeCase } from 'lodash'
import { Navigate, Route, Routes } from 'react-router-dom'
import PersonalInformation from './PersonalInformation'
import EmailNotifications from './EmailNotifications'

const MyProfile = () => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const currentTabPage = location.pathname.split('/')[2]
  const tabs = [
    { name: 'personalInformation', breadcrumbLabel: 'personalInformation' },
    { name: 'emailNotifications', breadcrumbLabel: 'emailNotifications' },
  ]
  const page = currentTabPage || snakeCase(tabs[0].name)
  const tabsMap = tabs.map((tab) => ({
    label: t(tab.name),
    active: camelCase(page) === tab.name,
    link: snakeCase(tab.name),
    breadcrumbLabel: t(tab.breadcrumbLabel),
  }))

  useEffect(() => {
    setBreadcrumbs([
      { label: t('profile'), link: '/myprofile' },
      {
        label: t(tabsMap.find((tab) => isEqual(tab.link, currentTabPage))?.breadcrumbLabel),
      },
    ])
  }, [page])

  return (
    <>
      <PageHeader headerTitle={t('myProfile')} />
      <Tabs tabs={tabsMap} />
      <Section className={'mt-6'} withTitle>
        <Routes>
          <Route element={<Navigate to="personal_information" replace />} path="/" />
          <Route element={<PersonalInformation />} path="/personal_information" />
          <Route element={<EmailNotifications />} path="/email_notifications" />
        </Routes>
      </Section>
    </>
  )
}

export default MyProfile

import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { Flex, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import sizes from '../../../ui-kit/sizes'
import Input from '../../../ui-kit/components/inputs/Input'
import fontWeight from '../../../ui-kit/fontWeight'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { createRoleList } from './roleList'
import colors from '../../../ui-kit/colors'
import {
  validateEmail,
  validatePhoneNumber,
  validateRequiredField,
  validateZipCode,
} from '../../../utils/validators'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import { countriesList } from '../../../utils/countriesList'
import { normalizePhone } from '../../../utils'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { getOnlyNumbers } from '../../../utils/utils'
import { statesList } from '../../../utils/statesList'
import Checkbox from '../../../ui-kit/components/inputs/Checkbox'

const ProjectPartyForm = ({
  data,
  onSubmit,
  isLoading,
  onDelete,
  closeForm,
  isFormDirty,
  requestClose,
  setDirtyFormState,
}) => {
  const { t } = useTranslation()
  const ref = useRef()

  useEffect(() => {
    if (!ref?.current?.parentElement) {
      return
    }

    setTimeout(() => {
      ref.current.parentElement.scrollTop = 0
    }, 0)
  }, [ref?.current])

  const initialValues = useMemo(
    () => ({
      name: data?.name || '',
      role: data?.role || '',
      email: data?.email || '',
      legalName: data?.legalName || '',
      officePhone: data?.formattedOfficePhone || '',
      mobilePhone: data?.formattedMobilePhone || '',
      businessName: data?.businessName || '',

      city: data?.address?.city || '',
      state: data?.address?.state || '',
      street: data?.address?.street || '',
      street2: data?.address?.street2 || '',
      country: data?.address?.country || '',
      zipCode: data?.address?.zipCode || '',

      isBusinessNameDifferentFromLegalName: data?.businessName !== data?.legalName,
    }),
    [],
  )
  const roleList = useMemo(() => createRoleList(t), [t])

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        clearState: (args, state, utils) => {
          utils.changeValue(state, 'state', () => '')
        },
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, values, form }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col pt-8 pt-2 w-[40rem]" onSubmit={handleSubmit} ref={ref}>
            <div className="w-full">
              <Flex className="w-full pb-6" column>
                <Field name="businessName">
                  {({ input, meta }) => (
                    <>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('projectPartyBusinessName')}
                      </Text>
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        {...input}
                      />
                    </>
                  )}
                </Field>
              </Flex>

              <Flex className="pb-6">
                <Field name="isBusinessNameDifferentFromLegalName">
                  {({ input }) => (
                    <Checkbox
                      id={input.name}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )}
                </Field>

                <label
                  className="ml-2 text-black-500 font-medium"
                  htmlFor="isBusinessNameDifferentFromLegalName">
                  {t('projectPartyNameDifferentThanAbove')}
                </label>
              </Flex>

              {values.isBusinessNameDifferentFromLegalName && (
                <Flex className="w-full pb-6" column>
                  <Field name="legalName">
                    {({ input, meta }) => (
                      <>
                        <Text
                          className="pb-1"
                          color={colors.GRAY_700}
                          fontWeight={fontWeight.MEDIUM}
                          size={sizes.SM}>
                          {t('projectPartyLegalName')}
                        </Text>
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          {...input}
                        />
                      </>
                    )}
                  </Field>
                </Flex>
              )}

              <Flex className="w-full pb-6" column>
                <Field name="role">
                  {({ input, meta }) => {
                    return (
                      <NDropdown
                        className="w-full"
                        errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                        id={input.name}
                        inputClassName="w-full"
                        label={t('projectPartyRole')}
                        listClass="max-h-40 p-1 pt-0 pb-0"
                        name={input.name}
                        onChange={input.onChange}
                        options={roleList}
                        placeholder={t('projectPartyRole')}
                        value={input.value}
                      />
                    )
                  }}
                </Field>
              </Flex>

              <Flex className="w-full pb-6" column>
                <Field name="name">
                  {({ input, meta }) => (
                    <>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('primaryContactName')}
                      </Text>
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        {...input}
                      />
                    </>
                  )}
                </Field>
              </Flex>

              <Flex className="w-full pb-6" column>
                <Field name="email">
                  {({ input, meta }) => (
                    <>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('email')}
                      </Text>
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        type="email"
                        {...input}
                      />
                    </>
                  )}
                </Field>
              </Flex>

              <Flex className="w-full pb-6" column>
                <Field name="officePhone" parse={normalizePhone}>
                  {({ input, meta }) => (
                    <>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('officePhone')}
                      </Text>
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        {...input}
                      />
                    </>
                  )}
                </Field>
              </Flex>

              <Flex className="w-full pb-6" column>
                <Field name="mobilePhone" parse={normalizePhone}>
                  {({ input, meta }) => (
                    <>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('mobilePhone')}
                      </Text>
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        {...input}
                      />
                    </>
                  )}
                </Field>
              </Flex>

              <div className="pb-6 w-2/3">
                <Field name="country">
                  {({ input, meta }) => {
                    return (
                      <NDropdown
                        defaultValue={countriesList[0].value}
                        errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                        id={input.name}
                        inputClassName="w-full"
                        label={t('countryRegion')}
                        name={input.name}
                        onChange={(e) => {
                          const nextCountry = e.target.value

                          if (
                            nextCountry !== values.country &&
                            nextCountry === countriesList[0].value
                          ) {
                            form.mutators.clearState()
                          }

                          input.onChange(nextCountry)
                        }}
                        options={countriesList}
                        placeholder={t('countryRegion')}
                        value={input.value}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full pb-6">
                <Field name="street">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('streetAddress')}
                        placeholder={t('address')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full pb-6">
                <Field name="street2">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('streetAddress2')}
                        placeholder={t('address2')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <Flex className="w-full pb-6 space-x-2">
                <div className="w-4/12">
                  <Field name="city">
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('city')}
                          placeholder={t('city')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>

                <div className="w-4/12">
                  <Field name="state">
                    {({ input, meta }) => {
                      return values.country === countriesList[0].value ? (
                        <NDropdown
                          errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                          id={input.name}
                          inputClassName="w-full"
                          label={t('stateProvince')}
                          name={input.name}
                          onChange={(e) => {
                            input.onChange(e.target.value)
                          }}
                          options={statesList}
                          placeholder={t('state')}
                          value={input.value}
                        />
                      ) : (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('stateProvince')}
                          placeholder={t('state')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>

                <div className="w-4/12">
                  <Field name="zipCode" parse={getOnlyNumbers}>
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('zipPostal')}
                          maxLength={5}
                          placeholder={t('zip')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
              </Flex>

              <Flex className="w-full" justifyContent={data ? 'between' : 'end'}>
                {data ? (
                  <Flex>
                    <Button
                      iconName="trash"
                      iconType="outline"
                      label={t('delete')}
                      onClick={() => onDelete()}
                      testData="delete-project-party"
                      variant={buttonsVariants.TERTIARY}
                    />
                  </Flex>
                ) : null}
                <Flex>
                  <Button
                    className="mr-4"
                    disabled={isLoading}
                    label={t('cancel')}
                    onClick={requestClose}
                    testData="close-project-party"
                    variant={buttonsVariants.TERTIARY}
                  />
                  <Button
                    disabled={isLoading}
                    label={data ? t('save') : t('add')}
                    testData="save-project-party"
                    type="submit"
                  />
                </Flex>
              </Flex>
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => {
        const commonFields = {
          name: validateRequiredField(values.name),
          role: validateRequiredField(values.role),
          email: validateRequiredField(values.email) || validateEmail(values.email),
          mobilePhone: validatePhoneNumber(values.mobilePhone),
          businessName: validateRequiredField(values.businessName),
          city: validateRequiredField(values.city),
          state: validateRequiredField(values.state),
          street: validateRequiredField(values.street),
          country: validateRequiredField(values.country),
          zipCode: validateRequiredField(values.zipCode) || validateZipCode(values.zipCode),
        }
        const legalName = values.isBusinessNameDifferentFromLegalName
          ? {
              legalName: validateRequiredField(values.legalName),
            }
          : {}

        return {
          ...commonFields,
          ...legalName,
        }
      }}
    />
  )
}

ProjectPartyForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  setDirtyFormState: PropTypes.func.isRequired,
}

export default ProjectPartyForm

import React, { useState } from 'react'
import PT from 'prop-types'
import { Section, Heading, Flex, NarrowList, Text } from '../../../../ui-kit'
import sizes from '../../../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import GeneralInfoEditForm from './GeneralInfoEditForm'
import Button from '../../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { get } from 'lodash'

const GeneralInfo = ({ buyerData, buyerLoading }) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)

  if (buyerLoading) return null

  const buyer = buyerData?.buyer

  const renderGeneralInfoList = () => {
    const buyerAttrsMapping = [
      { label: t('legalName'), key: 'name' },
      { label: t('dba'), key: 'dba' },
      { label: t('officeAddress'), key: 'officeAddress.toHuman' },
      { label: t('billingAddress'), key: 'billingAddress.toHuman' },
      { label: t('phone'), key: 'formattedSupportPhoneNumber' },
    ]

    const listItems = buyerAttrsMapping.map(({ label, key }) => (
      <>
        <Flex basis="30%">
          <Text className="text-gray-500" size={sizes.SM} customColor>
            {label}
          </Text>
        </Flex>
        <Flex basis="70%">
          <Text size={sizes.SM}>{get(buyer, key, '--')}</Text>
        </Flex>
      </>
    ))

    return <NarrowList listItems={listItems} />
  }

  const renderContent = () => {
    if (editMode) {
      return <GeneralInfoEditForm buyer={buyer} setEditMode={setEditMode} />
    }

    return renderGeneralInfoList()
  }

  return (
    <Flex basis="60%" className="mr-8" column>
      <Flex justifyContent="between">
        <Heading isBold={false} size={sizes.XL2}>
          {t('generalInfo')}
        </Heading>
        {!editMode && (
          <Button
            iconName="pencil"
            label={t('edit')}
            onClick={() => setEditMode(true)}
            size={sizes.SM}
            testData="edit-general-info"
            variant={buttonsVariants.FOURTH}
          />
        )}
      </Flex>
      <Section className="mt-4" withTitle>
        {renderContent()}
      </Section>
    </Flex>
  )
}

GeneralInfo.propTypes = {
  buyerData: PT.shape({
    buyer: PT.shape({
      name: PT.string,
      officeAddress: PT.shape({
        toHuman: PT.string,
      }),
      billingAddress: PT.shape({
        toHuman: PT.string,
      }),
      supportPhoneNumber: PT.string,
      supportEmail: PT.string,
    }),
  }).isRequired,
  buyerLoading: PT.bool.isRequired,
}

export default GeneralInfo

export const getValuesString = (values) => {
  if (!values) {
    return ''
  }

  if (Array.isArray(values)) {
    return values.join('||')
  } else {
    return values
      .split(',')
      .map((value) => value.trim())
      .join('-')
  }
}

import { Button, Flex, Input } from '../../../ui-kit'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import {
  validateEmail,
  validatePhoneNumber,
  validateRequiredField,
} from '../../../utils/validators'
import { UpdateVendorMutation } from '../../../queries/mutations/updateVendorRequest.gql'
import { VendorSettingsQuery } from '../../../queries/vendors.gql'
import PT from 'prop-types'
import { useNotifications } from '../../../hooks/useNotifications'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { normalizePhone } from '../../../utils'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { companyInformationModes } from './modes'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const ContactInformationEditForm = ({ vendor, setMode }) => {
  const { t } = useTranslation()

  const initialValues = {
    extension: vendor.extension || '',
    supportEmail: vendor.supportEmail,
    supportPhoneNumber: vendor.formattedSupportPhoneNumber,
  }

  const onCompleted = useCallback(() => {
    setMode(companyInformationModes.VIEW)
  }, [])
  const [update, { loading }] = useCustomMutation({
    mutation: UpdateVendorMutation,
    onCompleted,
    rollbarOptions: { operationName: 'UpdateVendorMutation', target: 'ContactInformationEditForm' },
    mutationOptions: {
      refetchQueries: [VendorSettingsQuery],
    },
  })
  const { newNotification } = useNotifications()

  const onSubmit = (values) => {
    const data = {
      extension: values.extension || '',
      supportEmail: values.supportEmail,
      supportPhoneNumber: values.supportPhoneNumber,
    }
    const variables = {
      data,
      id: vendor.id,
    }

    update({ variables }).then(({ data }) => {
      const responseData = data?.updateVendor || {}

      if (responseData?.entity) {
        newNotification({ success: t('informationWasUpdated') })
      }
    })
  }

  const closeForm = useCallback(() => {
    setMode(companyInformationModes.VIEW)
  }, [setMode])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <div className="w-full">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirty }) => {
          useBeforeUnload({ when: dirty })
          dirty !== isFormDirty && setDirtyFormState(dirty)

          return (
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className="w-1/3 pr-4">
                <Field name="supportEmail">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('customerSupportEmail')}
                        placeholder={t('email')}
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <Flex className="mt-6 w-1/3 pr-4">
                <div className="w-8/12 mr-4">
                  <Field name="supportPhoneNumber" parse={normalizePhone}>
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('customerSupportPhone')}
                          placeholder={t('phone')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
                <div className="w-4/12">
                  <Field name="extension">
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('extension')}
                          placeholder={t('extension')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>
              </Flex>

              <div className="w-full mt-4 flex flex-row justify-end">
                <Button
                  className="mr-4"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="cancel-edit"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button disabled={loading} label={t('save')} testData="save-edit" type="submit" />
              </div>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
        validate={(values) => ({
          supportEmail:
            validateRequiredField(values.supportEmail) || validateEmail(values.supportEmail),
          supportPhoneNumber:
            validateRequiredField(values.supportPhoneNumber) ||
            validatePhoneNumber(values.supportPhoneNumber),
        })}
      />
    </div>
  )
}

ContactInformationEditForm.propTypes = {
  setMode: PT.func,
  vendor: PT.shape({
    billingAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    id: PT.oneOfType([PT.string, PT.number]),
    officeAddress: PT.shape({
      country: PT.string,
      state: PT.string,
      street: PT.string,
      city: PT.string,
      zipCode: PT.string,
    }),
    name: PT.string,
    website: PT.string,
    supportEmail: PT.string,
    supportPhoneNumber: PT.string,
    formattedSupportPhoneNumber: PT.string,
    extension: PT.string,
  }).isRequired,
}

export default ContactInformationEditForm

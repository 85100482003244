import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '../../hooks/useQueryParams'
import {
  DepositQuery,
  InvoicesDepositsQuery,
  InvoicesDepositsAvailableFilters,
} from '../../queries/invoices/invoicesDeposits.gql'
import { getCreditsTableParams, getPaginationData, parseFilterQuery } from '../../utils/utils'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import PT from 'prop-types'
import {
  depositStatuses,
  depositStatusMapping,
  getInvoiceBreadcrumbs,
  getLoadingFunc,
} from './invoicesUtils'
import { Money, ConfirmationDialog, Dialog, Tooltip, Flex, Text } from '../../ui-kit'
import StatusBadge from '../../ui-kit/components/badges/StatusBadge'
import AddDepositModal from './addDeposit/AddDepositModal'
import SidebarDepositContent from './sidebar/SidebarDepositContent'
import { MONEY } from '../../utils/dataTypes'
import Button from '../../ui-kit/components/buttons/Button'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import MenuButton from '../../components/dataGrid/menuButton/MenuButton'
import DepositReturnModal from './depositReturn/DepositReturnModal'
import { CancelDeposit } from '../../queries/mutations/cancelDeposit.gql'
import { useNotifications } from '../../hooks/useNotifications'
import { ContractsAutocompleteQuery } from '../../queries/contracts.gql'
import { camelCase } from 'lodash'
import { CapturePaymentTransactionRequest } from '../../queries/mutations/capturePaymentTransaction.gql'
import { VoidPaymentTransactionRequest } from '../../queries/mutations/voidPaymentTransaction.gql'
import { paymentMethodTypes, paymentResultMods } from '../../constants/paymentResults'
import PaymentResult from '../../components/paymentResult/PaymentResult'
import { paymentTransactionStatuses } from '../payments/paymentsTab/util'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { chargebackLogo } from '../../ui-kit/assets'
import CurrencyInput from '../../ui-kit/components/inputs/CurrencyInput'
import { Field, Form } from 'react-final-form'
import { validateOnGraterThenZero, validateRequiredField } from '../../utils/validators'

const Deposits = ({
  contractData,
  scope,
  isAllContracts,
  contractId,
  isAddDepositModalOpened,
  setIsAddDepositModalOpened,
  refetchContract,
}) => {
  const [selectedDeposit, setSelectedDeposit] = useState({})
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [isReturnDepositModalOpened, setIsReturnDepositModalOpened] = useState(false)
  const [paymentResult, setPaymentResult] = useState(null)
  const [failureReason, setFailureReason] = useState(null)
  const [authorizedDepositId, setAuthorizedDepositId] = useState(null)
  const [paymentStatusRefreshCounter, setPaymentStatusRefreshCounter] = useState(0)
  const [initialAuthorizationValues, setInitialAuthorizationValues] = useState()
  const [authorizationModalOpened, setAuthorizationModalOpened] = useState()

  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const { newNotification } = useNotifications()
  const { sort, page, search } = getCreditsTableParams(queryParams)

  const { setBreadcrumbs } = useBreadcrumbs()
  const [availableFilters, setAvailableFilters] = useState(null)
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'Deposits' },
  })

  const loadOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  useEffect(() => {
    setBreadcrumbs([...getInvoiceBreadcrumbs(contractData, t, scope)])
  }, [scope, contractData])

  const filters = useMemo(
    () =>
      contractId
        ? [
            {
              key: 'contract_id',
              values: [contractId],
            },
          ]
        : [],
    [contractId],
  )

  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(
    () => ({
      filters: [...filters, ...userFilters],
      sort,
      page,
      search,
      perPage: pageSize,
    }),
    [filters, userFilters, sort, page, search, pageSize],
  )

  const onCompletedRecords = useCallback(
    (response) => {
      if (selectedDeposit) {
        const updatedDeposit = response?.deposits?.data?.find?.(
          (deposit) => deposit.id === selectedDeposit.id,
        )
        updatedDeposit && setSelectedDeposit(updatedDeposit)
      }
    },
    [selectedDeposit],
  )
  const { data, loading, refetch } = useCustomQuery({
    query: InvoicesDepositsQuery,
    onCompleted: onCompletedRecords,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'InvoicesDepositsQuery', target: 'Deposits' },
  })
  const onCompletedFilters = useCallback(
    (response) => {
      response?.deposits?.availableFilters &&
        setAvailableFilters(response.deposits.availableFilters)
    },
    [selectedDeposit],
  )
  useCustomQuery({
    query: InvoicesDepositsAvailableFilters,
    onCompleted: onCompletedFilters,
    rollbarOptions: { operationName: 'InvoicesDepositsAvailableFilters', target: 'Deposits' },
  })

  useEffect(() => {
    return function cleanup() {
      setAvailableFilters(null)
    }
  }, [])

  const deposits = data?.deposits

  const rows = deposits?.data || []
  const paginationData = getPaginationData(deposits)
  const [shouldReopenSidebar, setShouldReopenSidebar] = useState(false)

  const handleRowClick = useCallback(
    (values) => {
      setSelectedDeposit(values?.row)
      setIsSidebarOpened(true)
      setShouldReopenSidebar(true)
    },
    [setSelectedDeposit, setIsSidebarOpened],
  )

  const reopenSidebar = useCallback(() => {
    !!Object.keys(selectedDeposit).length && setIsSidebarOpened(true)
  }, [selectedDeposit, setIsSidebarOpened])

  const returnDepositHandler = useCallback(
    (values) => {
      setSelectedDeposit(values.row)
      setIsReturnDepositModalOpened(true)
      isSidebarOpened && setIsSidebarOpened(false)
    },
    [isSidebarOpened],
  )

  const [cancelDeposit, { loading: isCancelDepositLoading }] = useCustomMutation({
    mutation: CancelDeposit,
    rollbarOptions: { operationName: 'CancelDeposit', target: 'Deposits' },
    mutationOptions: {
      refetchQueries: [InvoicesDepositsQuery],
    },
  })

  const [voidPaymentTransaction, { loading: voidPaymentTransactionLoading }] = useCustomMutation({
    mutation: VoidPaymentTransactionRequest,
    rollbarOptions: { operationName: 'VoidPaymentTransactionRequest', target: 'Deposit' },
  })
  const cancelAuthorizationHandler = useCallback(() => {
    const variables = { id: selectedDeposit?.latestPaymentTransaction?.id }
    voidPaymentTransaction({ variables }).then(({ data }) => {
      const responseData = data?.voidPaymentTransaction || {}

      if (responseData?.entity) {
        newNotification({ success: t('depositAuthorizationHasBeenCancelled') })
        setIsOpenedCancelAuthorizationConfirmationModal(false)
        refetch()
      }
    })
  }, [selectedDeposit])
  const [capturePaymentTransaction, { loading: isCapturePaymentTransactionLoading }] =
    useCustomMutation({
      mutation: CapturePaymentTransactionRequest,
      rollbarOptions: { operationName: 'CapturePaymentTransactionRequest', target: 'Deposit' },
    })

  const runAuthorizedPaymentHandler = useCallback((deposit) => {
    setIsSidebarOpened(false)
    setAuthorizationModalOpened(true)
    setInitialAuthorizationValues({
      id: deposit.latestPaymentTransaction.id,
      amountCents: deposit.amountCents / 100,
      hashid: deposit.hashid,
    })
  }, [])

  const handleSubmitAuthorization = (values) => {
    const variables = {
      id: values?.id,
      data: { amountCents: Math.round(values?.amountCents * 100) },
    }
    setAuthorizationModalOpened(false)
    setPaymentResult(paymentResultMods.LOADING)
    capturePaymentTransaction({ variables }).then(({ data, errors }) => {
      const responseData = data?.capturePaymentTransaction || {}

      if (responseData?.entity) {
        const paymentMethodType = responseData.entity.paymentMethod?.type

        if (paymentMethodType === paymentMethodTypes.ACH_PAYMENT_METHOD) {
          setPaymentResult(paymentResultMods.ACH_SUCCESS)
        } else if (paymentMethodType === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD) {
          setPaymentResult(paymentResultMods.CREDIT_CARD_SUCCESS)
        } else {
          setAuthorizedDepositId(values.hashid)
          setPaymentStatusRefreshCounter((prevState) => prevState + 1)
        }
      } else if (responseData?.errors?.length || errors) {
        setPaymentResult(paymentResultMods.SERVER_ERROR)
      }
    })
  }

  const handleDoneClick = useCallback(() => {
    refetch()
    setAuthorizedDepositId(null)
    setPaymentResult(null)
    setPaymentStatusRefreshCounter(0)
  }, [])
  const { refetch: refetchDeposit } = useCustomQuery({
    query: DepositQuery,
    queryOptions: {
      variables: { id: authorizedDepositId },
      skip: !authorizedDepositId,
    },
    rollbarOptions: { operationName: 'DepositQuery', target: 'Deposits' },
  })
  useEffect(async () => {
    if (paymentStatusRefreshCounter === 0) {
      return
    }

    const { data: loadedData } = await refetchDeposit({ id: authorizedDepositId })

    const loadedDeposit = loadedData?.deposit

    if (
      !loadedDeposit?.latestPaymentTransaction ||
      loadedDeposit.latestPaymentTransaction.paymentMethod?.type ===
        paymentMethodTypes.ACH_PAYMENT_METHOD
    ) {
      return
    }

    let transactionPaymentResult = null

    if (loadedDeposit.latestPaymentTransaction.status === paymentTransactionStatuses.PAID) {
      transactionPaymentResult = paymentResultMods.CREDIT_CARD_SUCCESS
    } else if (
      loadedDeposit.latestPaymentTransaction.status === paymentTransactionStatuses.FAILED ||
      loadedDeposit.latestPaymentTransaction.status === paymentTransactionStatuses.CANCELLED
    ) {
      transactionPaymentResult = paymentResultMods.CREDIT_CARD_FAILURE
      setFailureReason(loadedDeposit.latestPaymentTransaction.failureReason)
    }

    if (transactionPaymentResult) {
      setPaymentResult(transactionPaymentResult)

      return
    }

    const timeoutId = setTimeout(() => {
      setPaymentStatusRefreshCounter((prevState) => prevState + 1)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [paymentStatusRefreshCounter, refetchDeposit, authorizedDepositId])

  const options = (rowData) => {
    const items = []

    if (rowData?.refundableAmountCents && rowData?.latestPaymentTransaction) {
      items.push({ label: t('returnDeposit'), action: returnDepositHandler })
    }

    if (rowData?.displayStatus === depositStatuses.SENT) {
      items.push({
        label: t('cancelRequest'),
        action: () => {
          setSelectedDeposit(rowData)
          setIsOpenedCancelRequestConfirmationModal(true)
        },
      })
    }

    if (rowData?.displayStatus === depositStatuses.AUTHORIZED) {
      items.push({
        label: t('runPayment'),
        action: () => runAuthorizedPaymentHandler(rowData),
      })
    }

    return items
  }
  const currentUser = useCurrentUser()
  const supportDeposits = currentUser.erpMetadata?.supportDeposits

  const columns = useMemo(
    () => [
      {
        field: 'issueDate',
        filterId: 'issueDate',
        headerName: t('date'),
        renderCell: (values) => <FormattedDate date={values?.row?.issueDate} />,
        flex: 14,
      },
      {
        field: supportDeposits ? 'id' : 'referenceId',
        filterId: additionalFilters.includes('id') && 'id',
        headerName: t('id'),
        flex: supportDeposits ? 12 : 27,
        renderCell: (values) => (
          <Button
            label={supportDeposits ? values?.row?.id : values?.row?.referenceId}
            onClick={() => handleRowClick(values)}
            size={sizes.SM}
            testData="deposit-details"
            variant={buttonsVariants.LINK}
          />
        ),
      },
      {
        field: 'customer',
        filterId: isAllContracts && 'contractId',
        headerName: t('customer'),
        renderCell: (values) => values?.row?.contract?.buyer?.name,
        loadOptions: loadOptions,
        flex: 20,
        hide: !isAllContracts,
      },
      {
        field: 'project',
        headerName: t('project'),
        flex: 25,
        renderCell: (values) => (
          <Tooltip content={values?.row?.project?.name || '-'}>
            <span className={'text-ellipsis overflow-hidden'}>
              {values?.row?.project?.name || '-'}
            </span>
          </Tooltip>
        ),
      },
      {
        field: 'locationName',
        headerName: t('Location'),
        filterId: 'locationName',
        filterTitle: t('depositLocation'),
        flex: 20,
        renderCell: (values) => (
          <span className={'text-ellipsis overflow-hidden'}>
            {values?.row?.location?.name || '-'}
          </span>
        ),
      },
      {
        field: 'source',
        headerName: t('source'),
        renderCell: (values) => t(values?.row?.source),
        flex: 10,
      },
      {
        field: 'amountCents',
        filterId: 'amountCents',
        filterDataType: MONEY,
        headerName: t('amount'),
        renderCell: (values) => <Money value={values?.row?.amountCents} />,
        flex: 17.5,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'outstandingAmountCents',
        filterId: 'outstandingAmount',
        filterDataType: MONEY,
        headerName: t('outstanding'),
        renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
        flex: 17.5,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'displayStatus',
        filterId: 'status',
        sortable: false,
        headerName: t('status'),
        renderCell: (values) => (
          <StatusBadge
            color={depositStatusMapping[values?.row?.displayStatus]?.color}
            value={t(depositStatusMapping[values?.row?.displayStatus]?.label)}
          />
        ),
        flex: 12.5,
      },
      {
        field: 'refundIcon',
        headerName: '',
        sortable: false,
        renderCell: (values) => {
          if (values.row.chargebacksPresent)
            return (
              <div>
                <Tooltip content={t('chargeback')}>
                  <img alt="Suppli" className="h-8" src={chargebackLogo} />
                </Tooltip>
              </div>
            )
        },
        flex: 2,
      },
      {
        field: 'actions',
        sortable: false,
        headerName: '',
        renderCell: (values) => {
          const menuOptions = options(values?.row)
          return !!menuOptions.length && <MenuButton options={menuOptions} values={values} />
        },
        flex: 2,
      },
    ],
    [isAllContracts, t, additionalFilters],
  )
  const [isOpenedCancelRequestConfirmationModal, setIsOpenedCancelRequestConfirmationModal] =
    useState(false)

  const cancelRequestHandler = useCallback(
    (values) => {
      const variables = { id: values?.row?.id || selectedDeposit?.id }
      cancelDeposit({ variables }).then(({ data }) => {
        setIsOpenedCancelRequestConfirmationModal(false)
        const responseData = data?.cancelDeposit || {}

        if (responseData?.entity) {
          newNotification({ success: t('requestCancelledSuccessfully') })
          if (shouldReopenSidebar) {
            setIsSidebarOpened(false)
          }
        }
      })
    },
    [cancelDeposit, selectedDeposit],
  )

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])
  const handleCloseCancelDepositRequestModal = useCallback(() => {
    setIsOpenedCancelRequestConfirmationModal(false)
    setIsSidebarOpened(true)
  }, [setIsOpenedCancelRequestConfirmationModal, setIsSidebarOpened])

  const [
    isOpenedCancelAuthorizationConfirmationModal,
    setIsOpenedCancelAuthorizationConfirmationModal,
  ] = useState(false)
  const handleCloseCancelDepositAuthorizationModal = useCallback(() => {
    setIsOpenedCancelAuthorizationConfirmationModal(false)
    setIsSidebarOpened(true)
  }, [setIsOpenedCancelAuthorizationConfirmationModal, setIsSidebarOpened])
  const {
    confirmationMessage,
    isOpenedConfirmationModal,
    onSubmitButtonNoClick,
    onSubmitButtonYesClick,
    isSubmitButtonYesDisabled,
    confirmationSetIsOpened,
    confirmationTitle,
  } = useMemo(
    () => ({
      confirmationMessage: isOpenedCancelRequestConfirmationModal
        ? t('cancelDepositRequestConfirmationModalMessage')
        : t('cancelDepositAuthorizationModalMessage'),
      isOpenedConfirmationModal:
        isOpenedCancelRequestConfirmationModal || isOpenedCancelAuthorizationConfirmationModal,
      onSubmitButtonNoClick: isOpenedCancelRequestConfirmationModal
        ? handleCloseCancelDepositRequestModal
        : handleCloseCancelDepositAuthorizationModal,
      onSubmitButtonYesClick: isOpenedCancelRequestConfirmationModal
        ? cancelRequestHandler
        : cancelAuthorizationHandler,
      isSubmitButtonYesDisabled: isOpenedCancelAuthorizationConfirmationModal
        ? voidPaymentTransactionLoading
        : isCancelDepositLoading,
      confirmationSetIsOpened: isOpenedCancelRequestConfirmationModal
        ? setIsOpenedCancelRequestConfirmationModal
        : setIsOpenedCancelAuthorizationConfirmationModal,
      confirmationTitle: isOpenedCancelRequestConfirmationModal
        ? t('cancelDepositRequest')
        : t('cancelAuthorization'),
    }),
    [
      t,
      isOpenedCancelRequestConfirmationModal,
      handleCloseCancelDepositRequestModal,
      cancelRequestHandler,
      isOpenedCancelAuthorizationConfirmationModal,
      handleCloseCancelDepositAuthorizationModal,
      cancelAuthorizationHandler,
      voidPaymentTransactionLoading,
    ],
  )

  return (
    <>
      <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
        <SidebarDepositContent
          depositId={selectedDeposit?.hashid}
          isRunAuthorizedPaymentDisabled={isCapturePaymentTransactionLoading}
          runAuthorizedPaymentHandler={runAuthorizedPaymentHandler}
          setIsOpenedCancelAuthorizationConfirmationModal={
            setIsOpenedCancelAuthorizationConfirmationModal
          }
          setIsOpenedCancelRequestConfirmationModal={setIsOpenedCancelRequestConfirmationModal}
          setIsReturnDepositModalOpened={setIsReturnDepositModalOpened}
          setIsSidebarOpened={setIsSidebarOpened}
        />
      </Sidebar>

      <AddDepositModal
        contractData={contractData}
        isAllContracts={isAllContracts}
        isOpened={isAddDepositModalOpened}
        refetchContract={refetchContract}
        refetchDeposits={refetch}
        setIsOpened={setIsAddDepositModalOpened}
      />

      <DepositReturnModal
        data={selectedDeposit}
        isOpened={isReturnDepositModalOpened}
        reopenSidebar={reopenSidebar}
        setIsOpened={setIsReturnDepositModalOpened}
      />

      <div className="flex pt-4">
        <DataGridComponent
          availableFilters={availableFilters}
          columns={columns}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rowClassName="cursor-pointer"
          rows={rows}
          searchLabel={t('deposits')}
          sortModel={defaultSortModel}
          disableSelectionOnClick
        />
      </div>

      <ConfirmationDialog
        confirmationMessage={confirmationMessage}
        isOpened={isOpenedConfirmationModal}
        isSubmitButtonYesDisabled={isSubmitButtonYesDisabled}
        onModalClose={() => setIsSidebarOpened(true)}
        onSubmitButtonNoClick={onSubmitButtonNoClick}
        onSubmitButtonYesClick={onSubmitButtonYesClick}
        setIsOpened={confirmationSetIsOpened}
        title={confirmationTitle}
      />

      {paymentResult && (
        <Dialog shouldCloseOnBackdropClick={false} hideCross isOpened>
          <PaymentResult
            failureReason={failureReason}
            mode={paymentResult}
            onDoneClick={handleDoneClick}
            setMode={setPaymentResult}
          />
        </Dialog>
      )}
      <Dialog
        isOpened={authorizationModalOpened}
        setIsOpened={setAuthorizationModalOpened}
        title={t('runAuthorization')}>
        <Form
          initialValues={initialAuthorizationValues}
          onSubmit={handleSubmitAuthorization}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field name="amountCents">
                  {({ input, meta }) => {
                    return (
                      <Flex className="py-4 flex-col">
                        <Flex alignItems="center w-full" className="gap-5" row>
                          <div>
                            <Text>{t('paymentAmount')}</Text>
                          </div>
                          <div>
                            <Text color="text-warmBlack-400">{t('authorized')}: </Text>
                            <Money value={initialAuthorizationValues.amountCents * 100} />
                          </div>
                          <div>
                            <CurrencyInput
                              error={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              name={input.name}
                              onChange={input.onChange}
                              value={input?.value}
                            />
                          </div>
                        </Flex>
                        <div className="w-full text-right">
                          {meta.error && meta.touched && meta.submitFailed ? (
                            <p className="pt-2 text-sm text-error">{meta.error}</p>
                          ) : null}
                        </div>
                      </Flex>
                    )
                  }}
                </Field>
                <Flex className="w-full mt-6" justifyContent="end">
                  <Button
                    label={t('cancel')}
                    onClick={() => setAuthorizationModalOpened(false)}
                    testData="cancel-memo"
                    variant={buttonsVariants.TERTIARY}
                  />
                  <Button
                    className="w-36 ml-3"
                    label={t('runPayment')}
                    testData="save-memo"
                    type="submit"
                  />
                </Flex>
              </form>
            )
          }}
          validate={(values) => ({
            amountCents:
              validateRequiredField(values.amountCents) ||
              validateOnGraterThenZero(values.amountCents),
          })}
        />
      </Dialog>
    </>
  )
}

Deposits.propTypes = {
  scope: PT.string.isRequired,
  contractId: PT.string.isRequired,
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  isAllContracts: PT.bool.isRequired,
  refetchContract: PT.func,
  isAddDepositModalOpened: PT.bool.isRequired,
  setIsAddDepositModalOpened: PT.func.isRequired,
}
Deposits.defaultProps = {
  scope: '',
  contractId: '',
  contractData: {},
  isAllContracts: false,
  isAddDepositModalOpened: false,
}

export default Deposits

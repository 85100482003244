import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { CreateProjectParty } from '../../../queries/mutations/createProjectParty.gql'
import { ProjectPartiesOverviewQuery } from '../../../queries/projectParties/projectPartiesOverview.gql'
import ProjectPartyForm from './ProjectPartyForm'
import { Dialog } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const AddProjectPartyModal = ({
  projectId,
  setIsOpenedModal,
  setIsSidebarOpened,
  isOpened,
  setIsOpened,
}) => {
  const { t } = useTranslation()
  const onCompleted = useCallback(() => {
    setIsOpenedModal(false)
    setIsSidebarOpened(true)
  }, [])
  const [createProjectParty, { loading }] = useCustomMutation({
    mutation: CreateProjectParty,
    onCompleted,
    mutationOptions: {
      refetchQueries: [ProjectPartiesOverviewQuery],
    },
  })
  const onSubmit = useCallback(
    (values) => {
      const variables = {
        data: {
          projectId,
          name: values.name,
          role: values.role,
          email: values.email,
          legalName: values.isBusinessNameDifferentFromLegalName
            ? values.legalName
            : values.businessName,
          officePhone: values.officePhone,
          mobilePhone: values.mobilePhone,
          businessName: values.businessName,
          addressAttributes: {
            city: values.city,
            state: values.state,
            street: values.street,
            street2: values.street2,
            country: values.country,
            zipCode: values.zipCode,
          },
        },
      }

      createProjectParty({ variables })
    },
    [createProjectParty, projectId],
  )
  const closeForm = useCallback(() => {
    setIsOpened(false)
    setIsSidebarOpened(true)
  }, [setIsOpened, setIsSidebarOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('addProjectParty')}
      titleClass="text-2xl font-semibold">
      <ProjectPartyForm
        closeForm={closeForm}
        isFormDirty={isFormDirty}
        isLoading={loading}
        onSubmit={onSubmit}
        requestClose={requestClose}
        setDirtyFormState={setDirtyFormState}
      />
    </Dialog>
  )
}

AddProjectPartyModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  setIsOpenedModal: PropTypes.func.isRequired,
  setIsSidebarOpened: PropTypes.func.isRequired,
}

export default AddProjectPartyModal

import { Switch } from '@headlessui/react'
import PT from 'prop-types'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Toggle = ({ value, handleChange, disabled, className, testData, name }) => {
  const changeState = () => {
    if (!disabled) {
      handleChange(!value, name)
    }
  }

  return (
    <Switch
      checked={value}
      className={classNames(
        disabled ? 'opacity-50' : '',
        'flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-9 ' +
          'cursor-pointer focus:outline-none align-middle',
        className,
      )}
      onChange={changeState}
      testData={testData}>
      <span aria-hidden="true" className="pointer-events-none absolute w-full h-full rounded-md" />
      <span
        aria-hidden="true"
        className={classNames(
          value ? 'bg-primary-700' : 'bg-gray-200',
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          value ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full' +
            ' bg-white shadow transform transition-transform ease-in-out duration-200',
        )}
      />
    </Switch>
  )
}

Toggle.propTypes = {
  className: PT.string,
  disabled: PT.bool,
  handleChange: PT.func,
  // restrictFalse: PT.bool,
  value: PT.bool,
  testData: PT.string,
  name: PT.string,
}

Toggle.defaultProps = {
  className: '',
  disabled: false,
  handleChange: null,
  // restrictFalse: false,
  value: false,
}

export default Toggle

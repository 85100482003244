import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ReportFooter from '../reportFooter/ReportFooter'
import { Flex, LoadingSpinner, PageDialog, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { getArAgingUserDetailsFiltersColumns } from './logic'
import ReportsFilters from '../reportsFilters/ReportsFilters'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { getLoadingFunc } from '../../invoices/invoicesUtils'
import { ContractsAutocompleteQuery } from '../../../queries/contracts.gql'

const ArAgingUserDetails = ({
  data,
  isOpened,
  closeReport,
  reportMetadata,
  authToken,
  userFilters,
}) => {
  const { t } = useTranslation()
  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    queryOptions: {
      skip: !isOpened,
    },
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'ArAgingUserDetails' },
  })
  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const filterColumns = useMemo(
    () =>
      getArAgingUserDetailsFiltersColumns(t, {
        loadContractOptions,
      }),
    [t],
  )
  const isCustomerSelected = useMemo(
    () => userFilters.find((filter) => filter.key === 'contract_id'),
    [userFilters],
  )

  return (
    <PageDialog
      bottomPanel={
        <ReportFooter
          authToken={authToken}
          closeReport={closeReport}
          isExcelExportDisabled={!isCustomerSelected}
          reportMetadata={reportMetadata}
          userFilters={userFilters}
        />
      }
      isOpened={isOpened}
      setIsOpened={closeReport}
      title={t('arAgingUserDetails')}>
      {!data ? (
        <LoadingSpinner className="h-full w-full" loading />
      ) : (
        <Flex className="px-8 w-full" column>
          <ReportsFilters availableFilters={data.availableFilters} columns={filterColumns} />
          <Flex alignItems="center" className="pt-4 flex-1" justifyContent="center">
            <Text className="ml-6" fontWeight={fontWeight.MEDIUM} size={sizes.md}>
              {t('excelWarningMsg')}
            </Text>
          </Flex>
        </Flex>
      )}
    </PageDialog>
  )
}

ArAgingUserDetails.propTypes = {
  data: PropTypes.object,
  isOpened: PropTypes.bool.isRequired,
  authToken: PropTypes.string.isRequired,
  closeReport: PropTypes.func.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.any),
  reportMetadata: PropTypes.shape({
    csvUrl: PropTypes.string,
    xlsxUrl: PropTypes.string,
    jsonUrl: PropTypes.string,
    fileNameKey: PropTypes.string,
    defaultFilters: PropTypes.object,
  }).isRequired,
}

export default ArAgingUserDetails

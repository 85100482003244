import React, { useCallback, useMemo } from 'react'
import { Flex, Dialog, Text } from '../../../ui-kit'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import colors from '../../../ui-kit/colors'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { Field, Form } from 'react-final-form'
import DatePicker from '../../../ui-kit/components/datePicker/DatePicker'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { validateRequiredField } from '../../../utils/validators'
import { useNotifications } from '../../../hooks/useNotifications'
import { UpdatePaymentPromise } from '../../../queries/mutations/updatePaymentPromise.gql'
import { PaymentPromisesQuery } from '../../../queries/paymentPromises.gql'
import { DateTime } from 'luxon'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { SERVER_DATE_FORMAT } from '../../../ui-kit/utils/dateUtils'

const UpdatePromiseToPayModal = ({
  data,
  isOpened,
  setIsOpenedModal,
  setIsSidebarOpened,
  setSelectedPromise,
  refetchQuery,
}) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()

  const closeForm = useCallback(() => {
    setIsOpenedModal(false)
    setIsSidebarOpened(true)
  }, [setIsOpenedModal, setIsSidebarOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const [updatePaymentPromise, { loading }] = useCustomMutation({
    mutation: UpdatePaymentPromise,
    rollbarOptions: { operationName: 'UpdatePaymentPromise', target: 'UpdatePromiseToPayModal' },
    mutationOptions: {
      refetchQueries: [PaymentPromisesQuery],
    },
  })
  const onSubmit = useCallback(
    (values) => {
      const variables = {
        id: data.id || data.paymentPromiseId,
        data: {
          date: DateTime.fromJSDate(values.date).toFormat(SERVER_DATE_FORMAT),
        },
      }
      window.dd = values.date

      updatePaymentPromise({ variables }).then(({ data }) => {
        const responseData = data?.updatePaymentPromise || {}

        if (responseData?.entity) {
          newNotification({ success: t('promiseToPayUpdatedSuccessfully') })
          setIsOpenedModal(false)
          setSelectedPromise(responseData.entity)
          if (refetchQuery) {
            refetchQuery()
          }
        }
      })
    },
    [updatePaymentPromise, data],
  )

  const initialValues = useMemo(
    () => ({
      date: DateTime.fromISO(data.date).toJSDate(),
    }),
    [data],
  )

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('editExpectedPaymentDate')}
      titleClass="text-2xl font-semibold">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirty }) => {
          useBeforeUnload({ when: dirty })
          dirty !== isFormDirty && setDirtyFormState(dirty)

          return (
            <form className="flex flex-col pt-8 pt-2 w-[40rem]" onSubmit={handleSubmit}>
              <div className="w-full">
                <Flex className="w-full" column>
                  <Field name="date">
                    {({ input, meta }) => {
                      return (
                        <Flex className="w-full pb-6" column>
                          <Text
                            className="pb-1"
                            color={colors.GRAY_700}
                            fontWeight={fontWeight.MEDIUM}
                            size={sizes.SM}>
                            {t('expectedPaymentDate')}
                          </Text>
                          <DatePicker
                            error={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            onChange={input.onChange}
                            placeholder={t('chooseDate')}
                            selectedDateLabel={t('selected')}
                            value={input.value}
                            isTodayMinDay
                          />
                          {meta.error && meta.touched && meta.submitFailed ? (
                            <p className="pt-2 text-sm text-error">{meta.error}</p>
                          ) : null}
                        </Flex>
                      )
                    }}
                  </Field>
                </Flex>

                <Flex className="w-full" justifyContent="end">
                  <Button
                    className="mr-4"
                    label={t('cancel')}
                    onClick={requestClose}
                    testData="close-update-promise"
                    variant={buttonsVariants.TERTIARY}
                  />
                  <Button
                    disabled={loading || !dirty}
                    label={t('save')}
                    testData="submit-update-promise"
                    type="submit"
                  />
                </Flex>
              </div>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
        validate={(values) => ({
          date: validateRequiredField(values.date),
        })}
      />
    </Dialog>
  )
}

UpdatePromiseToPayModal.propTypes = {
  data: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  setIsOpenedModal: PropTypes.func.isRequired,
  setIsSidebarOpened: PropTypes.func.isRequired,
  setSelectedPromise: PropTypes.func.isRequired,
  refetchQuery: PropTypes.func,
}

export default UpdatePromiseToPayModal

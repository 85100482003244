import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Button, Flex, Money, PageDialog, StatusBadge } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import colors from '../../../ui-kit/colors'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { getSumByField } from '../../../utils/utils'
import PaymentType from '../../../components/paymentType/PaymentType'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import Icon from '../../../ui-kit/components/icons/Icon'
import { isEqual } from 'lodash'
import DatePicker from '../../../ui-kit/components/datePicker/DatePicker'
import { getPaymentTypesOptions } from '../invoicesUtils'
import { useNotifications } from '../../../hooks/useNotifications'
import { CreatePaymentPlanMutation } from '../../../queriesUpdated/mutations/createPaymentPlan.gql'
import { DateTime } from 'luxon'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { PaymentMethodsQuery } from '../../../queriesUpdated/queries/paymentMethod.gql'
import { paymentMethodsEligibilityEnum } from '../../../constants/paymentMethodEligibilityEnum'
import { getDisplayStatusValue } from '../../contracts/utils'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { InvoicesOutstandingIndexQuery } from '../../../queriesUpdated/queries/invoiceOutstanding.gql'

const PaymentPlanModal = ({ invoiceIds, onClose, onSubmit, isOpened, setIsOpened }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [invoices, setInvoices] = useState([])
  const [installments, setInstallments] = useState([])
  const [contractCreditCardFeeEnabled, setContractCreditCardFeeEnabled] = useState(false)
  const { t } = useTranslation()
  const tooMuchPaymentError = t('paymentInvalid')
  const tooLessPaymentError = t('notEnoughPayment')
  const missingAllPaymentDate = t('addAllPaymentDates')
  const missingPaymentType = t('addPaymentType')
  const contractID = invoices[0]?.contract?.id
  const buyerName = invoices[0]?.contract?.buyer?.name

  const totalInvoicesPayment = useMemo(
    () => getSumByField(invoices, 'outstandingAmountCents'),
    [invoices],
  )

  const queryVariables = {
    filters: [
      {
        key: 'id',
        values: [...invoiceIds],
      },
    ],
    sort: 'issue_date.desc',
    perPage: invoiceIds.length,
  }
  const onCompleted = useCallback((response) => {
    response?.invoices && setInvoices(response.invoices?.data)
  }, [])
  useCustomQuery({
    query: InvoicesOutstandingIndexQuery,
    onCompleted: onCompleted,
    queryOptions: {
      variables: queryVariables,
      skip: !isOpened,
    },
    rollbarOptions: { operationName: 'PromiseToPay', target: 'InvoicesOutstanding' },
  })

  const invoiceColumns = useMemo(
    () => [
      {
        field: 'id',
        hide: true,
        sortable: false,
      },
      {
        field: 'issueDate',
        headerName: t('date'),
        renderCell: (values) => <FormattedDate date={values?.row?.issueDate} />,
        flex: 10,
        sortable: false,
      },
      {
        field: 'invoiceNumber',
        headerName: t('number'),
        flex: 10,
        sortable: false,
      },
      {
        field: 'projectName',
        headerName: t('project'),
        renderCell: (values) => (
          <span className={'text-ellipsis overflow-hidden'}>{values?.row?.project?.name}</span>
        ),
        flex: 20,
        sortable: false,
      },
      {
        field: 'maturityDate',
        headerName: t('dueDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.maturityDate} />,
        flex: 10,
        sortable: false,
      },
      {
        field: 'outstandingAmountCents',
        headerName: t('outstanding'),
        renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
        flex: 15,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        field: 'aging',
        headerName: t('status'),
        renderCell: (values) => (
          <StatusBadge
            color={values?.row?.status}
            iconName="dot"
            value={getDisplayStatusValue(values?.row?.overdueLevel)}
          />
        ),
        flex: 10,
        sortable: false,
      },
    ],
    [invoices],
  )

  const deleteInstallment = (id) => {
    const updatedInstallments = installments
      .filter((el) => el.id !== id)
      .map((el, index) => ({ ...el, id: index + 1 }))
      .map((item) => ({
        ...item,
        paymentCents: Math.round(totalInvoicesPayment / (installments.length - 1)),
      }))

    setErrorMessage(null)
    setInstallments(updatedInstallments)
  }
  const addInstallment = () => {
    let updatedInstallments = []
    const newInstallment = {
      id: installments.length + 1,
      date: new Date(DateTime.now().plus({ days: 1 })),
      paymentCents: '',
    }
    if (!installments.length) {
      newInstallment.paymentCents = totalInvoicesPayment
      updatedInstallments = [newInstallment]
    } else {
      const lastInstallment = installments[installments.length - 1]
      newInstallment.date = DateTime.fromJSDate(lastInstallment.date).plus({ weeks: 1 }).toJSDate()
      const isManuallyUpdated = installments.findIndex((installment) => installment.isUpdated) > -1
      if (isManuallyUpdated) {
        newInstallment.paymentCents =
          totalInvoicesPayment - getSumByField(installments, 'paymentCents')
        if (newInstallment.paymentCents < 0) {
          newInstallment.paymentCents = 0
        } else {
          installments.forEach((item) => {
            delete item.error
            delete item.errorMessage
          })
          setErrorMessage(null)
        }
        updatedInstallments = [...installments, newInstallment]
      } else {
        updatedInstallments = [...installments, newInstallment].map((item) => ({
          ...item,
          paymentCents: Math.round(totalInvoicesPayment / (installments.length + 1)),
        }))

        const sumInstallments = getSumByField(updatedInstallments, 'paymentCents')
        if (sumInstallments !== totalInvoicesPayment) {
          const difference = totalInvoicesPayment - sumInstallments
          updatedInstallments[updatedInstallments.length - 1].paymentCents += difference
        }
      }
    }
    setInstallments(updatedInstallments)
  }

  const addPlanSubmit = () => {
    const installmentsSum = installments.reduce((sum, inst) => {
      return sum + Number(inst.paymentCents)
    }, 0)
    if (installmentsSum < totalInvoicesPayment) {
      setErrorMessage(tooLessPaymentError)
    } else if (!selectedPaymentMethod?.id) {
      setErrorMessage(missingPaymentType)
    } else if (installments.some((installment) => !installment.date)) {
      setErrorMessage(missingAllPaymentDate)
    } else {
      const paymentPlanData = {
        contractId: contractID,
        paymentMethodId: selectedPaymentMethod?.id,
        invoiceIds: invoices.map((invoice) => invoice.id),
        paymentCents: totalInvoicesPayment,
        installments: installments.map((installment) => ({
          date: installment.date,
          paymentCents: Number(installment.paymentCents),
        })),
      }
      handleSubmitPaymentPlan(paymentPlanData)
    }
  }

  const { newNotification } = useNotifications()
  const [createPaymentPlan, { loading: requestInProgress }] = useCustomMutation({
    mutation: CreatePaymentPlanMutation,
    rollbarOptions: { operationName: 'CreatePaymentPlanMutation', target: 'PaymentPlanModal' },
  })

  const handleSubmitPaymentPlan = (data) => {
    createPaymentPlan({ variables: { data } }).then(({ data }) => {
      const responseData = data?.createPaymentPlan || {}

      if (responseData?.entity) {
        newNotification({ success: t('paymentPlanSubmittedSuccessfully') })
        onSubmit()
      }
    })
  }

  const planColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('installment'),
        flex: 15,
        sortable: false,
      },
      {
        field: 'date',
        headerName: t('paymentDate'),
        renderCell: (values) => {
          return (
            <DatePicker
              onChange={(dateValue) => handleDateChange(values.row.id, dateValue)}
              value={values.row.date}
              isTomorrowMinDay
            />
          )
        },
        flex: 35,
        sortable: false,
      },
      {
        field: 'paymentCents',
        headerName: t('amount'),
        renderCell: (values) => {
          return (
            <CurrencyInput
              error={values?.row.errorMessage}
              id={`${values?.row.id}-paymentCents`}
              name={`${values?.row.id}-paymentCents`}
              onChange={handleTableInputChange}
              value={values?.row.paymentCents / 100}
            />
          )
        },
        flex: 35,
        sortable: false,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 15,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Icon color={colors.LIGHT_GRAY1} name="trash" size={sizes.BASE} type={'outline'} />
            }
            key={`delete-${params.id}`}
            label="Delete"
            onClick={() => deleteInstallment(params.id)}
          />,
        ],
      },
    ],
    [installments, invoices],
  )
  const clearInstallments = () => setInstallments([])

  const handleTableInputChange = (e) => {
    const value = e.target.value
    const [id, fieldName] = e.currentTarget.id.split('-')
    const currentInstallments = [...installments]

    const installmentIndex = currentInstallments.findIndex((inv) => isEqual(inv.id, Number(id)))
    const updatedInstallment = currentInstallments[installmentIndex]
    updatedInstallment[fieldName] = value * 100 || 0
    updatedInstallment.isUpdated = true
    const installmentsSum = currentInstallments.reduce((sum, currentInstallment) => {
      if (currentInstallment.id === id) {
        sum = sum + value * 100
        return sum
      }
      sum = sum + Number(currentInstallment.paymentCents)
      return sum
    }, 0)

    if (installmentsSum > totalInvoicesPayment) {
      updatedInstallment.error = true
      updatedInstallment.errorMessage = updatedInstallment.error ? tooMuchPaymentError : null
      setErrorMessage(tooMuchPaymentError)
    } else if (installmentsSum < totalInvoicesPayment) {
      updatedInstallment.error = true
      updatedInstallment.errorMessage = updatedInstallment.error ? tooLessPaymentError : null
      setErrorMessage(tooLessPaymentError)
    } else {
      currentInstallments.forEach((currentInstallment) => {
        currentInstallment.error = false
        currentInstallment.errorMessage = null
      })
      setErrorMessage(null)
    }
    setInstallments(currentInstallments)
  }

  const handleDateChange = (id, value) => {
    const currentInstallments = [...installments]
    const installmentIndex = currentInstallments.findIndex((inv) => isEqual(inv.id, Number(id)))
    const updatedInstallment = currentInstallments[installmentIndex]
    updatedInstallment.date = value
    setInstallments(currentInstallments)
  }

  const [paymentTypesState, setPaymentTypesState] = useState([])

  const {
    data: paymentMethodsData,
    loading: paymentQueryInProgress,
    refetch,
  } = useCustomQuery({
    query: PaymentMethodsQuery,
    queryOptions: {
      variables: {
        contractId: contractID,
        eligibility: paymentMethodsEligibilityEnum.PAYMENT_PLAN,
        includeMetaInfo: true,
        includeContractPaymentSettings: true,
      },
      fetchPolicy: 'cache-first',
      skip: !contractID,
    },
    rollbarOptions: { operationName: 'PaymentMethodsQuery', target: 'PaymentPlanModal' },
  })

  useEffect(() => {
    if (paymentMethodsData) {
      setPaymentTypesState(getPaymentTypesOptions(paymentMethodsData, t))
      setContractCreditCardFeeEnabled(
        paymentMethodsData?.contract?.paymentSettings?.creditCardFeeEnabled,
      )
    }

    return function cleanup() {
      setErrorMessage(null)
      setInstallments([])
    }
  }, [paymentMethodsData])

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

  const refetchPaymentMethods = useCallback(() => refetch(), [refetch])

  return (
    <PageDialog
      bottomPanel={
        <>
          <Button
            className="ml-3 px-4"
            disabled={requestInProgress}
            label={t('addPaymentPlan')}
            onClick={addPlanSubmit}
            size={sizes.SM}
            testData="add-payment-plan"
          />

          <Button
            className={'w-40'}
            label={t('cancel')}
            onClick={() => {
              clearInstallments()
              onClose()
            }}
            size={sizes.SM}
            testData="cancel-add-payment-plan"
            variant={buttonsVariants.TERTIARY}
          />
        </>
      }
      isOpened={isOpened}
      loading={paymentQueryInProgress}
      onModalClose={clearInstallments}
      setIsOpened={setIsOpened}
      title={t('addPaymentPlan')}>
      <Flex className="flex-1" column>
        <div className="lg:px-8 md:px-4 py-4 items-center">
          <h3 className="text-lg leading-6 font-medium">{buyerName}</h3>

          <form>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <PaymentType
                  contractCreditCardFeeEnabled={contractCreditCardFeeEnabled}
                  contractID={contractID}
                  eligibility={paymentMethodsEligibilityEnum.PAYMENT_PLAN}
                  label={t('paymentType')}
                  name="paymentType"
                  onCreateMethod={refetchPaymentMethods}
                  selected={selectedPaymentMethod?.value}
                  setSelected={setSelectedPaymentMethod}
                  types={paymentTypesState}
                />
              </div>
            </div>
          </form>
        </div>

        <div className={'flex items-start'}>
          <div className="lg:px-8 md:px-4 py-4 relative flex flex-col w-2/3">
            <h4 className={'text-xl gray-900 mb-6 font-medium'}>{t('selectedInvoices')}:</h4>
            <DataGridComponent
              columns={invoiceColumns}
              hideFooter={invoices.length < 100}
              localPagination={invoices.length > 100}
              pageSize={100}
              rows={invoices}
            />
            <div className={'flex justify-between w-full mt-4 items-center'}>
              <span className={'text-gray-500 text-sm'}>{t('total')}:</span>
              <span className={'text-xl gray-900'}>
                <Money value={totalInvoicesPayment} />
              </span>
            </div>
          </div>
          <div className="lg:px-8 md:px-4 pt-4 relative flex flex-col w-1/3">
            <h4 className={'text-xl gray-900 mb-6 font-medium'}>{t('installments')}:</h4>
            <DataGridComponent columns={planColumns} rows={installments} hideFooter />
            <div className={'flex justify-between w-full mt-4 items-center'}>
              <span className={'text-gray-500 text-sm'}>{t('total')}:</span>
              <span className={'text-xl gray-900'}>
                <Money value={getSumByField(installments, 'paymentCents')} />
              </span>
            </div>
            <div className="mt-4 text-right">
              <div className="text-red-600">{errorMessage}</div>
            </div>
            <Flex justifyContent="start">
              <Button
                disabled={installments.length === 10}
                label={`+ ${t('addInstallment')}`}
                onClick={addInstallment}
                size={sizes.SM}
                testData="add-installment"
                variant={buttonsVariants.FOURTH}
              />
            </Flex>
          </div>
        </div>
      </Flex>
    </PageDialog>
  )
}

PaymentPlanModal.propTypes = {
  financeCharges: PT.number,
  invoiceIds: PT.arrayOf(PT.string).isRequired,
  isOpened: PT.bool.isRequired,
  onSubmit: PT.func,
  onClose: PT.func,
  setIsOpened: PT.func.isRequired,
}
PaymentPlanModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
}

export default PaymentPlanModal

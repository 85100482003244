import React from 'react'
import { scopeFilterMap } from '../../invoices/invoicesUtils'
import { Money } from '../../../ui-kit'

export const getAllInvoicesStatQueryParams = () => ({
  filters: scopeFilterMap['outstanding'],
  sort: 'aging.desc',
  withDaysPastDue: true,
  perPage: 11,
  withTotalPastDue: true,
})

export const getInvoicesOldestPastDueColumns = (t) => [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'buyerName',
    headerName: t('name'),
    maxWidth: 200,
    flex: 0.5,
    minWidth: 100,
    sortable: false,
    renderCell: (values) => (
      <a
        className="text-blue-600 text-ellipsis overflow-hidden"
        href={`/customers/${values?.row?.id}`}>
        {values?.row?.buyer.name}
      </a>
    ),
  },
  {
    field: 'aging',
    headerName: t('daysPastDue'),
    renderCell: (values) => (
      <span>
        {values?.row?.aging}{' '}
        {values?.row?.aging === 0 ? '' : values?.row?.aging > 1 ? 'days' : 'day'}
      </span>
    ),
    maxWidth: 140,
    minWidth: 50,
    flex: 0.35,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'outstandingAmountCents',
    headerName: t('outstanding'),
    renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
    maxWidth: 140,
    minWidth: 50,
    flex: 0.35,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
  },
]

export const getLocalPaginationData = (rows, page, pageSize) => {
  const totalCount = rows.length
  const totalPages = rows.length ? Math.ceil(totalCount / pageSize) : 1
  let to = page * pageSize
  const from = rows.length === 0 ? 0 : to - pageSize + 1

  if (to > totalCount) {
    to = totalCount
  }

  return { from, to, totalCount, totalPages }
}

import React from 'react'
import { LoadingSpinner, Text } from '../../../ui-kit'
import PT from 'prop-types'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import Icon from '../../../ui-kit/components/icons/Icon'
import colors from '../../../ui-kit/colors'
import { useTranslation } from 'react-i18next'
import formatActivities from '../../../utils/formatActivities'
import InvoiceActivity from './InvoiceActivity'
import { InvoiceActivitiesQuery } from '../../../queriesUpdated/queries/invoiceActivity.gql'
import { idType } from '../../../types/defaultTypes'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { ACTIVITY_TYPES } from '../../../constants/activities'

const InvoiceActivities = ({
  dueDate,
  invoiceDate,
  amountCents,
  contractId,
  invoiceData,
  invoiceId,
}) => {
  const { t } = useTranslation()
  const { data, loading } = useCustomQuery({
    query: InvoiceActivitiesQuery,
    queryOptions: {
      variables: {
        id: invoiceId,
      },
      // fetchPolicy: 'cache-and-network',
      skip: !invoiceId,
    },
    rollbarOptions: { operationName: 'InvoiceActivitiesQuery', target: 'InvoiceActivities' },
  })
  const activities = data?.invoice?.activities || []
  const payableInvoiceAssignments = data?.invoice?.payableInvoiceAssignments || []
  const audits = data?.invoice?.audits
    ? data?.invoice?.audits.map((audit) => ({
        ...audit,
        eventEntity: ACTIVITY_TYPES.AUDIT,
        title: ACTIVITY_TYPES.AUDIT,
      }))
    : []
  const allEvents = activities.concat(audits)

  if (!loading && !activities) {
    return null
  }
  const currentUser = useCurrentUser()
  const activitiesData = formatActivities({
    activities: allEvents,
    dueDate,
    amountCents,
    invoiceDate,
    t,
    contractId,
    invoiceData,
    currentUser,
    payableInvoiceAssignments,
  })
  return loading ? (
    <LoadingSpinner />
  ) : (
    <div testData={'invoiceActivities'}>
      <div className="mt-8">
        <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.BASE}>
          {t('invoiceActivity')}
        </Text>
      </div>
      <div className="mt-6 flex flex-col justify-center">
        {activitiesData?.map((el, index) => {
          return (
            <>
              <InvoiceActivity {...el} />{' '}
              {index !== activitiesData.length - 1 && el?.title && (
                <div className="my-2 ml-1.5">
                  <Icon color={colors.GREY} name="connector" />
                </div>
              )}
            </>
          )
        })}
      </div>
    </div>
  )
}

export default InvoiceActivities

InvoiceActivities.propTypes = {
  invoiceData: PT.object,
  dueDate: PT.string.isRequired,
  invoiceDate: PT.string.isRequired,
  contractId: PT.string.isRequired,
  amountCents: PT.number.isRequired,
  invoiceId: idType,
}

export const steps = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
}

export const columnWidth = (step) => {
  switch (step) {
    case steps.SECOND:
      return {
        issueDate: 9,
        invoiceNumber: 16,
        project: 16,
        po: 0,
        maturityDate: 9,
        outstandingAmountCents: 10,
        minus: 0,
        discounts: 0,
        plus: 0,
        financeCharges: 0,
        plusMinus: 0,
        adjustment: 0,
        equal: 0,
        subTotal: 10,
      }
    case steps.THIRD:
      return {
        issueDate: 8,
        invoiceNumber: 12,
        project: 12,
        po: 12,
        maturityDate: 8,
        outstandingAmountCents: 8,
        minus: 0,
        discounts: 0,
        plus: 0,
        financeCharges: 0,
        plusMinus: 2,
        adjustment: 8,
        equal: 2,
        subTotal: 10,
        credDeb: 8,
        payment: 10,
      }
    default:
      return {
        issueDate: 9,
        invoiceNumber: 12,
        project: 12,
        po: 12,
        maturityDate: 9,
        outstandingAmountCents: 10,
        minus: 2,
        discounts: 10,
        plus: 2,
        financeCharges: 10,
        equal: 2,
        subTotal: 10,
      }
  }
}

import React, { useEffect } from 'react'
import { useMemo } from '../../../hooks/useMemo'
import AddMemoForm from '../../../pages/invoices/addMemoForm/AddMemoForm'
import { Dialog } from '../../index'
import { useTranslation } from 'react-i18next'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { UpdateAuditMemo } from '../../../queries/mutations/updateAuditMemo.gql'
import { useNotifications } from '../../../hooks/useNotifications'

export const MEMO_TYPE = {
  INVOICE_OVERRIDE: 'INVOICE_OVERRIDE',
}

const Memo = () => {
  const { memo, hideEditMemo } = useMemo()
  const { t } = useTranslation()
  const [updateAudit] = useCustomMutation({
    mutation: UpdateAuditMemo,
    rollbarOptions: { operationName: 'UpdateAuditMemo', target: 'UpdateMemoModal' },
    mutationOptions: {
      refetchQueries: [memo?.refetchQuery],
    },
  })
  const { newNotification } = useNotifications()
  const customSubmit = (values) => {
    if (memo.memoType === MEMO_TYPE.INVOICE_OVERRIDE) {
      updateAudit({
        variables: {
          data: { memo: values.memo || '' },
          id: memo.id,
        },
      }).then(({ data }) => {
        const responseData = data?.updateAudit || {}

        if (responseData?.entity) {
          newNotification({ success: t('memoWasUpdatedSuccessfully') })
        }
        hideEditMemo()
      })
    }
  }

  useEffect(() => {
    hideEditMemo()
  }, [])

  return (
    <Dialog isOpened={memo?.visible} setIsOpened={hideEditMemo} title={t('addMemo')}>
      <AddMemoForm
        customSubmit={customSubmit}
        customerName={memo?.subtitle}
        memo={memo?.text}
        setIsOpenedModal={hideEditMemo}
      />
    </Dialog>
  )
}

export default Memo

import React from 'react'
import { Button } from '../../ui-kit'
import { upperCase } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'

const BackButton = ({ className, onClick }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleClick = () => {
    if (onClick) {
      onClick()
    } else {
      navigate(-1)
    }
  }
  return (
    <Button
      className={className}
      label={upperCase(t('back'))}
      onClick={handleClick}
      testData="back"
      variant={'tertiary'}
      fullWidth
    />
  )
}

export default BackButton

BackButton.propTypes = {
  className: PT.string,
  onClick: PT.func,
}

BackButton.defaultProps = {
  className: '',
  onClick: null,
}

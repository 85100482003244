import React from 'react'
import { Flex } from '../../../ui-kit'
import { getRefundTitle } from '../invoiceRefund/reasonList'
import { DateTime } from 'luxon'
import { getOverrideActivity } from '../../../utils/formatActivities'
import { InvoicesCreditsQuery } from '../../../queries/invoices/invoicesCredits.gql'

const getCreatedElement = (data, t) => {
  const title = `${t('creditMemoDate')}`
  const basicProps = {
    title,
    date: data.createdAt,
    titleAmount: data.amountCents,
  }
  return {
    ...basicProps,
    iconName: 'check',
    bgColorName: 'bg-black-500',
    iconColor: 'text-white',
    borderColorName: 'border-black-500',
  }
}
const getAppliedElement = (event, t, contractId) => {
  const title = `${t('creditApplied')}`
  const changesPayload = event.changesPayload
  const amount = changesPayload?.amount_cents
  const transactionId = changesPayload?.payment_transaction_id
  return {
    title,
    date: event.createdAt,
    titleAmount: amount,
    iconName: 'check',
    bgColorName: 'bg-green-600',
    iconColor: 'text-white',
    borderColorName: 'border-green-600',
    additionalInfoComponent: () => (
      <Flex shrink={0} column>
        <div className={'text-black-500'}>
          {t('id')}:{' '}
          <a
            className={'blue'}
            /* eslint-disable-next-line max-len */
            href={`/customers/${contractId}/payments/payments?additionalFilters=id&filters=id.${transactionId}&openSidebarParams=id.${transactionId}`}>
            {transactionId}
          </a>
        </div>
      </Flex>
    ),
  }
}

const getRefundElement = (id, event, t, contractId) => {
  const entity = event.activityOf[0]
  const title = `${t('creditRefunded')}`
  const sumAmount = entity.refundPayableInvoiceAssignments.reduce((acc, item) => {
    const creditUsages = item.creditUsages.find((usage) => usage.credit.id === id)
    return acc + creditUsages.amountCents
  }, 0)

  return {
    title,
    titleAmount: sumAmount,
    date: event.createdAt,
    iconName: 'check',
    bgColorName: 'bg-orange-900',
    iconColor: 'text-white',
    borderColorName: 'border-orange-900',
    additionalInfoComponent: () => (
      <>
        <Flex>
          <div className={'text-black-500'}>
            {t('id')}:{' '}
            <a
              className={'blue'}
              href={`/customers/${contractId}/payments/refunds?filters=id.${entity?.id}&openSidebarParams=id.${entity?.id}`}>
              {entity?.id}
            </a>
          </div>
        </Flex>
        <Flex className="pb-4" shrink={0} column>
          <div className={'text-black-500'}>
            {t('reason')}: {getRefundTitle(entity?.reason)}
          </div>
        </Flex>
      </>
    ),
  }
}

const createCreditActivities = ({ data, t, contractId }) => {
  const firstEl = getCreatedElement(data, t, contractId)
  const mappingEvents = data.events.map((event) => {
    switch (event.eventAction) {
      case 'apply':
        return getAppliedElement(event, t, contractId)
      case 'refund':
        return getRefundElement(data.id, event, t, contractId)
    }
  })
  const audits = data?.audits
    ? data?.audits.map((audit) => {
        return getOverrideActivity(audit, t, InvoicesCreditsQuery)
      })
    : []

  return [firstEl].concat(mappingEvents).concat(audits)
}

export const formatCreditActivities = ({ data, t, contractId }) => {
  if (!data?.events && !data?.audits) {
    return []
  }
  return createCreditActivities({ data, t, contractId }).sort(function (prev, next) {
    return DateTime.fromISO(prev.date).toMillis() - DateTime.fromISO(next.date).toMillis()
  })
}

import React from 'react'
import PT from 'prop-types'
import colors from '../../colors'
import MiuMenuItem from '@mui/material/MenuItem'
import Tooltip from '../tooltip/Tooltip'
import { Text } from '../../index'
import sizes from '../../sizes'

const MenuItem = ({ option, onClick }) => {
  return (
    <MiuMenuItem
      href={option?.href}
      key={option.label}
      onClick={option.validationError ? () => {} : onClick || option?.onClick}
      sx={{
        color: colors.GREY_700,
        fontSize: '1rem',
        lineHeight: '1.25rem',
        padding: '0',
        '&:hover': {
          backgroundColor: colors.PRIMARY_200,
        },
        cursor: option.validationError ? 'default' : 'pointer',
      }}
      testData={option.testData}>
      <Tooltip content={option.toolTipContent}>
        <Text
          className="px-4 py-2 block"
          color={option.validationError ? colors.GREY : colors.GREY_700}
          size={sizes.SM}
          nowrap>
          {option.label}
        </Text>
      </Tooltip>
    </MiuMenuItem>
  )
}

export default MenuItem

MenuItem.propTypes = {
  option: PT.shape({
    label: PT.string,
    href: PT.string,
    onClick: PT.func,
    testData: PT.string,
    validationError: PT.oneOfType([PT.string, PT.node]),
    toolTipContent: PT.oneOfType([PT.string, PT.node]),
  }),
  onClick: PT.func,
}

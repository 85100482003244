import React from 'react'
import { Text } from '../../../ui-kit'
import PT from 'prop-types'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import Icon from '../../../ui-kit/components/icons/Icon'
import colors from '../../../ui-kit/colors'
import { useTranslation } from 'react-i18next'
import CreditActivity from './CreditActivity'
import { formatCreditActivities } from './utils'

const CreditActivities = ({ data, contractId }) => {
  const { t } = useTranslation()
  const activitiesData = formatCreditActivities({
    data,
    t,
    contractId,
  })

  const filteredActivities = activitiesData.filter((activity) => !!activity)

  return (
    <div>
      <div className="mt-8">
        <Text fontWeight={fontWeight.SEMIBOLD} size={sizes.BASE}>
          {t('creditActivity')}
        </Text>
      </div>
      <div className="mt-6 flex flex-col justify-center">
        {filteredActivities?.map((el, index) => {
          return (
            <>
              <CreditActivity {...el} />{' '}
              {index !== filteredActivities.length - 1 && el?.title && (
                <div className="my-2 ml-1.5">
                  <Icon color={colors.GREY} name="connector" />
                </div>
              )}
            </>
          )
        })}
      </div>
    </div>
  )
}

export default CreditActivities

CreditActivities.propTypes = {
  data: PT.object,
  contractId: PT.string.isRequired,
}

export const getPaymentsBreadcrumbs = (contractData, t, scope) => {
  return contractData?.id
    ? [
        { label: t('customers'), link: '/customers' },
        { label: contractData?.buyer?.name, link: `/customers/${contractData.id}` },
        scope !== 'payments'
          ? { label: t('payments'), link: `/customers/${contractData.id}/payments` }
          : null,
        { label: t(scope) },
      ]
    : [
        scope !== 'payments' ? { label: t('payments'), link: '/payments' } : null,
        { label: t(scope) },
      ]
}

import { useCallback, useEffect, useState } from 'react'

export const useDataStorage = ({ data, entityName = '', isManualDataUpdate = false } = {}) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    if (isManualDataUpdate) {
      return
    }
    if (!data?.[entityName]?.data?.length) {
      setItems([])
      return
    }
    if (items.length) {
      if (data?.[entityName]?.data.every((el) => items.find((item) => item.id === el.id))) {
        setItems([...data[entityName].data])
      } else {
        setItems([...items, ...data[entityName].data])
      }
    } else {
      setItems([...data[entityName].data])
    }
  }, [data?.[entityName]?.data])

  const updateItem = useCallback(
    (item) => {
      if (!item) {
        return
      }

      const oldItemIndex = items.findIndex((savedItem) => savedItem.id === item.id)

      if (oldItemIndex === -1) {
        return
      }

      const updatedItems = [...items]
      updatedItems.splice(oldItemIndex, 1, item)
      setItems(updatedItems)
    },
    [items],
  )

  const addItems = useCallback((records) => setItems((prevState) => [...prevState, ...records]), [])
  const resetItems = useCallback(() => setItems([]), [])

  return { items, updateItem, resetItems, addItems }
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { camelCase, toInteger } from 'lodash'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import { DeleteFileMutation, UpdateFileMutation } from '../../../queries/files.gql'
import { ContractDocumentsQuery } from '../../../queries/contractDocuments.gql'

import PageHeader from '../../../components/headers/PageHeader'
import DownloadFiles from '../../../components/DownloadFiles'
import DocumentUploadModal from './DocumentUploadModal'
import { DOCUMENT_TYPES } from './documentTypes'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { Button, FormattedDate, Icon, Tabs, Flex, Tooltip } from '../../../ui-kit'
import colors from '../../../ui-kit/colors'
import sizes from '../../../ui-kit/sizes'
import { getModelsByIds, getPaginationData } from '../../../utils/utils'
import { CATEGORIES } from '../../../constants/fileInputResources'
import { getOptionsLabel } from '../../../utils/getOptionsLabel'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const Documents = ({ contractData, contractId }) => {
  const [modalOpened, setModalOpened] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const { queryParams, setQueryParams } = useQueryParams()

  const sort = queryParams.sort || 'created_at.desc'
  const scope = queryParams.scope || 'documents'
  const page = toInteger(queryParams.page) || 1
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    const defaultBreadcrumbs = [
      { label: t('customers'), link: '/customers' },
      { label: contractData?.buyer?.name, link: `/customers/${contractData.id}` },
    ]
    const breadcrumbsWithScope =
      scope === 'documents'
        ? [...defaultBreadcrumbs, { label: t(scope) }]
        : [
            ...defaultBreadcrumbs,
            { label: t('documents'), link: `/customers/${contractData.id}/documents` },
            { label: t(scope) },
          ]
    setBreadcrumbs(breadcrumbsWithScope)
  }, [scope])

  const queryVariables = {
    sort,
    page,
    scope,
    contractId,
  }

  const [deleteFile, { loading: isDeleteFileLoading }] = useCustomMutation({
    mutation: DeleteFileMutation,
    rollbarOptions: { operationName: 'DeleteFileMutation', target: 'Documents' },
    mutationOptions: {
      refetchQueries: [ContractDocumentsQuery],
    },
  })

  const [updateFile, { loading: isUpdateFileLoading }] = useCustomMutation({
    mutation: UpdateFileMutation,
    rollbarOptions: { operationName: 'UpdateFileMutation', target: 'Documents' },
    mutationOptions: {
      refetchQueries: [ContractDocumentsQuery],
    },
  })

  const { data, loading } = useCustomQuery({
    query: ContractDocumentsQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'ContractDocumentsQuery', target: 'Documents' },
  })

  const documents = data?.contractDocuments
  const rows = documents?.data || []
  const paginationData = getPaginationData(documents)

  const tabs = ['documents', 'statements']

  const tabsMap = tabs.map((tab) => ({
    label: t(tab),
    onClick: () => handleTabClick(tab),
    active: scope === tab,
  }))

  const documentCategory = () => {
    let category
    switch (scope) {
      case 'documents':
        category = CATEGORIES.DOCUMENT
        break
      case 'statements':
        category = CATEGORIES.STATEMENT
    }
    return category
  }

  const handleSelectionModelChange = useCallback(
    (models) => {
      setSelectionModel(models)
    },
    [rows],
  )

  const setSelectionModel = (ids) => {
    const selected = getModelsByIds(rows, ids)
    setSelectedDocuments(selected)
  }

  const filesMapping = (files) =>
    files.map((file) => ({
      url: file.url,
      name: file.originalFilename,
    }))

  const renderRowActions = (values) => (
    <Flex>
      <Button
        label={t('view')}
        onClick={() => window.open(values?.row?.url)}
        size={sizes.SM}
        variant={buttonsVariants.FOURTH}
      />
      <DownloadFiles files={filesMapping([values?.row])}>
        {(handleDownload) => (
          <Icon
            className="self-center mr-4"
            color={colors.PRIMARY}
            name="download"
            onClick={handleDownload}
            size={sizes.LG}
          />
        )}
      </DownloadFiles>
      {scope === 'documents' && (
        <Icon
          className="self-center"
          color={colors.GREY}
          disabled={isDeleteFileLoading}
          name="trash"
          onClick={() => handleArchive(values?.row?.id)}
          size={sizes.LG}
        />
      )}
    </Flex>
  )

  const updateVisibleToCustomer = async (id, visibleToCustomer) => {
    await updateFile({
      variables: {
        id,
        data: {
          visibleToCustomer,
        },
      },
    })
  }

  const columns = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: t('uploadDate'),
        flex: 2,
        renderCell: (values) => <FormattedDate date={values?.row?.createdAt} />,
      },
      {
        field: 'name',
        headerName: t('documentName'),
        flex: 3,
        renderCell: (values) => <Tooltip content={values?.row?.name}>{values?.row?.name}</Tooltip>,
      },
      {
        field: 'documentType',
        headerName: t('documentType'),
        flex: 3,
        renderCell: (values) => getOptionsLabel(DOCUMENT_TYPES, values?.row?.documentType) || '--',
        hide: scope === 'statements',
      },
      {
        field: 'createdBy',
        headerName: t('uploadedBy'),
        flex: 3,
        renderCell: (values) => values?.row?.createdBy?.fullName,
        hide: scope === 'statements',
      },
      {
        field: 'notes',
        headerName: t('notes'),
        flex: 3,
        renderCell: (values) => (
          <Tooltip content={values?.row?.notes}>{values?.row?.notes}</Tooltip>
        ),
        hide: scope === 'statements',
      },
      {
        field: 'visibleToCustomer',
        headerName: t('visibleToCustomer'),
        flex: 3,
        hide: scope === 'statements',
        renderCell: (values) => (
          <Toggle
            disabled={isUpdateFileLoading}
            handleChange={() =>
              updateVisibleToCustomer(values?.row?.id, !values?.row?.visibleToCustomer)
            }
            value={values?.row?.visibleToCustomer}
          />
        ),
      },
      {
        field: 'actions',
        headerName: '',
        flex: 1,
        minWidth: 140,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderCell: renderRowActions,
      },
    ],
    [scope, isUpdateFileLoading],
  )

  const handleTabClick = (tab) => {
    setQueryParams({ scope: tab, page: 1, sort: 'created_at.desc' })
  }

  const handleArchive = async (id) => {
    const variables = { id }
    await deleteFile({ variables })
  }

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [scope])

  return (
    <>
      <PageHeader headerTitle={t('documents')} />

      <Flex justifyContent="between">
        <Tabs tabs={tabsMap} />

        <Flex>
          {selectedDocuments.length > 0 && (
            <DownloadFiles files={filesMapping(selectedDocuments)}>
              {(handleDownload) => (
                <Button
                  className="mr-4"
                  color={colors.PRIMARY}
                  iconName="download"
                  iconType="outline"
                  label={t('downloadSelected')}
                  onClick={handleDownload}
                />
              )}
            </DownloadFiles>
          )}
          {scope === 'documents' && (
            <Button
              iconName="plusCircle"
              iconType="outline"
              label={t('addDocument')}
              onClick={() => setModalOpened(true)}
              variant={buttonsVariants.SECONDARY}
              bordered
            />
          )}
        </Flex>
      </Flex>

      {modalOpened && (
        <DocumentUploadModal
          category={documentCategory()}
          contractId={contractId}
          modalOpened={modalOpened}
          onModalClose={() => setModalOpened(false)}
          refetchQueries={[ContractDocumentsQuery]}
        />
      )}

      {scope === 'documents' && (
        <div className="flex pt-4">
          <DataGridComponent
            columns={columns}
            handleSelectionModelChange={handleSelectionModelChange}
            loading={loading}
            page={page}
            paginationData={paginationData}
            rows={rows}
            selectionModel={selectedDocuments.map((doc) => doc.id)}
            sortModel={defaultSortModel}
            checkboxSelection
            disableSelectionOnClick
          />
        </div>
      )}

      {scope === 'statements' && (
        <div className="flex pt-4">
          <DataGridComponent
            columns={columns}
            handleSelectionModelChange={handleSelectionModelChange}
            loading={loading}
            page={page}
            paginationData={paginationData}
            rows={rows}
            selectionModel={selectedDocuments.map((doc) => doc.id)}
            sortModel={defaultSortModel}
            checkboxSelection
            disableSelectionOnClick
          />
        </div>
      )}
    </>
  )
}

Documents.propTypes = {
  contractData: PT.shape({
    id: PT.string,
    buyer: PT.shape({
      name: PT.string,
    }),
  }).isRequired,
  contractId: PT.string.isRequired,
}

export default Documents

import React, { useMemo } from 'react'
import PT from 'prop-types'
import DatePickerLibComponent from 'react-datepicker'
import styles from './DatePicker.module.scss'
import cx from 'classnames'
import { FormattedDate } from '../../index'
import Icon from '../icons/Icon'
import 'react-datepicker/dist/react-datepicker.css'
import Input from '../inputs/Input'
import { subDays, addDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { DEFAULT_DATE_FORMAT } from '../../utils/dateUtils'

const CustomDatePicker = ({
  value,
  onChange,
  placeholder = 'Select Date',
  selectedDateLabel,
  error,
  className,
  isTodayMinDay,
  isTomorrowMinDay,
  disabled,
  maxDate,
  minDate,
  ...props
}) => {
  const handleChange = (v) => {
    onChange(v)
  }
  const today = new Date()
  const { t } = useTranslation()
  const selectedMinDate = useMemo(() => {
    if (isTodayMinDay) {
      return subDays(new Date(), 0)
    }

    if (isTomorrowMinDay) {
      return addDays(new Date(), 1)
    }

    return minDate
  }, [isTodayMinDay, isTomorrowMinDay, minDate])

  return (
    <div className={cx(styles.wrapper, className)}>
      <DatePickerLibComponent
        dateFormat={DEFAULT_DATE_FORMAT}
        disabled={disabled}
        maxDate={maxDate}
        minDate={selectedMinDate}
        {...props}
        closeOnScroll={(e) => e.target === document}
        customInput={
          <Input
            disabled={disabled}
            error={error}
            icon={
              <Icon
                color={'primary-700'}
                name={'calendar'}
                onClick={(e) => {
                  e.preventDefault()
                }}
                type={'solid'}
              />
            }
          />
        }
        onChange={handleChange}
        placeholderText={placeholder}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        portalId="datepicker-portal"
        selected={value}>
        <div className={'flex px-3.5 py-6 justify-between w-full'}>
          <div className={'today'}>
            <Icon color={'warmBlack-400'} name={'dot'} type={'solid'} />
            <div>
              <span>{t('today')}:</span>
              <div>
                <FormattedDate date={today.toISOString()} />
              </div>
            </div>
          </div>
          {value && (
            <div className={'selected'}>
              <Icon color={'primary-700'} name={'dot'} type={'solid'} />
              <div>
                <span>{selectedDateLabel || t('paymentDate')}:</span>
                <div>
                  <FormattedDate date={value?.toISOString()} />
                </div>
              </div>
            </div>
          )}
        </div>
      </DatePickerLibComponent>
    </div>
  )
}

CustomDatePicker.propTypes = {
  error: PT.bool,
  className: PT.string,
  value: PT.string,
  onChange: PT.func,
  placeholder: PT.string,
  selectedDateLabel: PT.string,
  isTodayMinDay: PT.bool,
  isTomorrowMinDay: PT.bool,
  disabled: PT.bool,
  maxDate: PT.any,
  minDate: PT.any,
}

CustomDatePicker.defaultProps = {
  error: false,
  className: '',
  value: '',
  onChange: () => {},
  placeholder: null,
  selectedDateLabel: null,
  isTodayMinDay: false,
  isTomorrowMinDay: false,
  disabled: false,
  maxDate: null,
  minDate: null,
}

export default CustomDatePicker

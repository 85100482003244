export const paymentTransactionStatuses = {
  ENQUEUED: 'enqueued',
  PROCESSING: 'processing',
  SUBMITTED: 'submitted',
  AUTHORIZED: 'authorized',
  PAID: 'paid',
  FAILED: 'failed',
  VOIDED: 'voided',
  CHARGEBACK: 'chargeback',
}

export const paymentTransactionDisplayStatuses = {
  PENDING: 'pending',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  VOIDED: 'voided',
  AUTHORIZED: 'authorized',
  CHARGEBACK: 'chargeback',
}

export const refundTransactionStatuses = {
  ENQUEUED: 'enqueued',
  SUBMITTED: 'submitted',
  PAID: 'paid',
  FAILED: 'failed',
}

export const statusRefundTransactionMapping = {
  [refundTransactionStatuses.ENQUEUED]: { label: 'pending', color: 'gray' },
  [refundTransactionStatuses.SUBMITTED]: { label: 'pending', color: 'gray' },
  [refundTransactionStatuses.PAID]: { label: 'successful', color: 'green' },
  [refundTransactionStatuses.FAILED]: { label: 'failed', color: 'red' },
}

export const displayStatusesMapping = {
  [paymentTransactionDisplayStatuses.PENDING]: { label: 'pending', color: 'gray' },
  [paymentTransactionDisplayStatuses.SUCCESSFUL]: { label: 'successful', color: 'green' },
  [paymentTransactionDisplayStatuses.FAILED]: { label: 'failed', color: 'red' },
  [paymentTransactionDisplayStatuses.VOIDED]: { label: 'voided', color: 'white' },
  [paymentTransactionDisplayStatuses.AUTHORIZED]: { label: 'authorized', color: 'bordered' },
  [paymentTransactionDisplayStatuses.CHARGEBACK]: { label: 'chargeback', color: 'red' },
}

export const eventActions = {
  CREATE: 'create',
  IN_PROCESS: 'in_process',
  PAID: 'paid',
  FAILED: 'failed',
  VOIDED: 'voided',
  CHARGEBACK: 'chargeback',
}

export const getPaymentMethodTitle = (payment, t) => payment?.paymentMethod?.title || t('cashCheck')
export const getPaymentMethodNickName = (nickname) => {
  return `${nickname ? ` (${nickname})` : ''}`
}
export const getPaymentMethodTitleNickName = (paymentMethod, t) => {
  if (!paymentMethod?.title) {
    return t('cashCheck')
  }
  return paymentMethod?.title + getPaymentMethodNickName(paymentMethod?.nickname)
}

export const getRecipientsViaEmail = (recipientsFullListValues) =>
  recipientsFullListValues
    .filter((item) => !!item.sourceEmail)
    .map((item) => ({
      ...(item.id && item.id !== item.sourceEmail ? { buyerUserId: item.id.split('/')[0] } : {}),
      email: item.sourceEmail,
    }))

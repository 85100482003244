export const refundStatuses = {
  INITIAL: 'initial',
  PENDING: 'pending',
  SETTLED: 'settled',
  FAILED: 'failed',
}

export const statusRefundMapping = {
  [refundStatuses.INITIAL]: { label: 'initiated', color: 'gray' },
  [refundStatuses.PENDING]: { label: 'pending', color: 'orange' },
  [refundStatuses.SETTLED]: { label: 'successful', color: 'green' },
  [refundStatuses.FAILED]: { label: 'failed', color: 'red' },
}

export const getRefundsMethodTitle = (refundPayableInvoiceAssignments, t) => {
  const isRegularPaymentExisting = refundPayableInvoiceAssignments.find(
    (refund) => refund.amountCents,
  )
  const isCreditDepositUsed = refundPayableInvoiceAssignments.find(
    (refund) => refund.creditUsages.length || refund.depositUsages.length,
  )

  if (
    refundPayableInvoiceAssignments.length > 1 ||
    (isRegularPaymentExisting && isCreditDepositUsed)
  ) {
    return t('multiple')
  }
  if (isCreditDepositUsed) {
    return t('creditsDeposits')
  }

  return refundPayableInvoiceAssignments[0]?.paymentMethod?.title
}

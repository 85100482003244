import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { Flex, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import sizes from '../../../ui-kit/sizes'
import Input from '../../../ui-kit/components/inputs/Input'
import fontWeight from '../../../ui-kit/fontWeight'
import Button from '../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import colors from '../../../ui-kit/colors'
import { UpdateProject } from '../../../queries/mutations/updateProject.gql'
import { ProjectsQuery } from '../../../queries/projects.gql'
import {
  validateDateRange,
  validateRequiredField,
  validateZipCode,
} from '../../../utils/validators'
import { kindList } from './kindList'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import { createBuildingTypeList, createRoleList } from './roleList'
import { statusList } from './statusList'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import DatePicker from '../../../ui-kit/components/datePicker/DatePicker'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { getOnlyNumbers } from '../../../utils/utils'
import { useNotifications } from '../../../hooks/useNotifications'
import { getFormattedDate, SERVER_DATE_FORMAT } from '../../../ui-kit/utils/dateUtils'
import { DateTime } from 'luxon'
import { countriesList } from '../../../utils/countriesList'
import { statesList } from '../../../utils/statesList'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const UpdateProjectForm = ({
  data,
  contractId,
  setIsOpenedModal,
  setIsSidebarOpened,
  setSelectedProject,
  isFormDirty,
  setDirtyFormState,
  requestClose,
  closeForm,
}) => {
  const { t } = useTranslation()

  const initialValues = useMemo(
    () => ({
      name: data.name,
      kind: data.kind,
      role: data.role,
      status: data.status,
      buildingType: data.buildingType,
      estimatedCostCents: String(data.estimatedCostCents / 100),
      bonded: !!data.bonded,
      taxExempt: !!data.taxExempt,
      estimatedEndDate: data.estimatedEndDate
        ? DateTime.fromFormat(data.estimatedEndDate, SERVER_DATE_FORMAT).toJSDate()
        : null,
      estimatedStartDate: data.estimatedStartDate
        ? DateTime.fromFormat(data.estimatedStartDate, SERVER_DATE_FORMAT).toJSDate()
        : null,
      country: data.address?.country,
      street: data.address?.street,
      street2: data.address?.street2,
      city: data.address?.city,
      state: data.address?.state,
      zipCode: data.address?.zipCode,
    }),
    [data],
  )
  const roleList = useMemo(() => createRoleList(t), [t])
  const buildingTypeList = useMemo(() => createBuildingTypeList(t), [t])

  const { newNotification } = useNotifications()
  const [updateProject, { loading }] = useCustomMutation({
    mutation: UpdateProject,
    rollbarOptions: { operationName: 'UpdateProject', target: 'UpdateProjectForm' },
    mutationOptions: {
      refetchQueries: [ProjectsQuery],
    },
  })
  const onSubmit = useCallback(
    (values) => {
      const estimatedCostValue = Number(values?.estimatedCostCents?.replaceAll(',', ''))
      const variables = {
        id: data.id,
        data: {
          name: values.name,
          kind: values.kind,
          role: values.role,
          status: values.status,
          buildingType: values.buildingType,
          estimatedCostCents: estimatedCostValue * 100,
          bonded: values.bonded,
          taxExempt: values.taxExempt,
          estimatedStartDate: getFormattedDate(values.estimatedStartDate, SERVER_DATE_FORMAT),
          estimatedEndDate: getFormattedDate(values.estimatedEndDate, SERVER_DATE_FORMAT),
          contractId,
          addressAttributes: {
            country: values.country,
            street: values.street,
            street2: values.street2,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
          },
        },
      }

      updateProject({ variables }).then(({ data }) => {
        const responseData = data?.updateProject || {}

        if (responseData?.entity) {
          newNotification({ success: t('projectUpdatedSuccessfully') })
          setIsOpenedModal(false)

          const updatedProject = responseData.entity

          if (updatedProject?.status === data.status) {
            setIsSidebarOpened(true)
          }

          if (updatedProject) {
            setSelectedProject(updatedProject)
          }
        }
      })
    },
    [updateProject, contractId],
  )

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        clearState: (args, state, utils) => {
          utils.changeValue(state, 'state', () => '')
        },
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, form, values }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className="flex flex-col pt-8 pt-2 w-[40rem]" onSubmit={handleSubmit}>
            <div className="w-full">
              <Flex className="w-full" column>
                <Flex className="w-full pb-6" column>
                  <Flex>
                    <Text
                      className="pb-1"
                      color={colors.GRAY_700}
                      fontWeight={fontWeight.MEDIUM}
                      size={sizes.SM}>
                      {t('projectId')}
                    </Text>
                    <Text
                      className="pb-1 whitespace-pre"
                      color="text-warmBlack-400"
                      fontWeight={fontWeight.MEDIUM}
                      size={sizes.SM}>
                      {` (${t('fromYourERP')})`}
                    </Text>
                  </Flex>
                  <Input id="id" value={data.referenceId} disabled />
                </Flex>

                <Field name="name">
                  {({ input, meta }) => (
                    <Flex className="w-full pb-6" column>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('projectName')}
                      </Text>
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        {...input}
                      />
                    </Flex>
                  )}
                </Field>

                <Field name="kind">
                  {({ input, meta }) => {
                    return (
                      <NDropdown
                        className="w-full pb-6"
                        errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                        id={input.name}
                        inputClassName="w-full"
                        label={t('projectType')}
                        listClass="max-h-40 p-1 pt-0 pb-0"
                        name={input.name}
                        onChange={input.onChange}
                        options={kindList}
                        placeholder={t('projectType')}
                        value={input.value}
                      />
                    )
                  }}
                </Field>

                <Flex>
                  <Field name="role">
                    {({ input, meta }) => {
                      return (
                        <NDropdown
                          className="w-6/12 pb-6 pr-3"
                          errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                          id={input.name}
                          inputClassName="w-full"
                          label={t('customerRole')}
                          listClass="max-h-36 p-1 pt-0 pb-0"
                          name={input.name}
                          onChange={input.onChange}
                          options={roleList}
                          placeholder={t('customerRole')}
                          value={input.value}
                        />
                      )
                    }}
                  </Field>

                  <Field name="status">
                    {({ input, meta }) => {
                      return (
                        <NDropdown
                          className="w-6/12 pb-6 pl-3"
                          errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                          id={input.name}
                          inputClassName="w-full"
                          label={t('projectStatus')}
                          listClass="max-h-36 p-1 pt-0 pb-0"
                          name={input.name}
                          onChange={input.onChange}
                          options={statusList}
                          placeholder={t('projectStatus')}
                          value={input.value}
                        />
                      )
                    }}
                  </Field>
                </Flex>

                <Flex>
                  <Field name="estimatedStartDate">
                    {({ input, meta }) => {
                      return (
                        <Flex className="w-6/12 pb-6 pr-3" column>
                          <Text
                            className="pb-1"
                            color={colors.GRAY_700}
                            fontWeight={fontWeight.MEDIUM}
                            size={sizes.SM}>
                            {t('estimatedStartDate')}
                          </Text>
                          <DatePicker
                            error={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            onChange={input.onChange}
                            placeholder={t('chooseDate')}
                            selectedDateLabel={t('selected')}
                            value={input.value}
                          />
                          {meta.error && meta.touched && meta.submitFailed ? (
                            <p className="pt-2 text-sm text-error">{meta.error}</p>
                          ) : null}
                        </Flex>
                      )
                    }}
                  </Field>

                  <Field name="estimatedEndDate">
                    {({ input, meta }) => {
                      return (
                        <Flex className="w-6/12 pb-6 pl-3" column>
                          <Text
                            className="pb-1"
                            color={colors.GRAY_700}
                            fontWeight={fontWeight.MEDIUM}
                            size={sizes.SM}>
                            {t('estimatedEndDate')}
                          </Text>
                          <DatePicker
                            error={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            onChange={input.onChange}
                            placeholder={t('chooseDate')}
                            selectedDateLabel={t('selected')}
                            value={input.value}
                          />
                          {meta.error && meta.touched && meta.submitFailed ? (
                            <p className="pt-2 text-sm text-error">{meta.error}</p>
                          ) : null}
                        </Flex>
                      )
                    }}
                  </Field>
                </Flex>

                <Flex>
                  <Field name="estimatedCostCents">
                    {({ input, meta }) => (
                      <Flex className="w-6/12 pb-3 pr-3" column>
                        <Text
                          className="pb-1"
                          color={colors.GRAY_700}
                          fontWeight={fontWeight.MEDIUM}
                          size={sizes.SM}>
                          {t('estimatedMaterials')}
                        </Text>
                        <CurrencyInput
                          error={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          onChange={input.onChange}
                          value={input.value}
                        />
                        {meta.error && meta.touched && meta.submitFailed ? (
                          <p className="pt-2 text-sm text-error">{meta.error}</p>
                        ) : null}
                      </Flex>
                    )}
                  </Field>

                  <Flex className="w-6/12 pb-3 pl-3" column>
                    <Text
                      className="pb-3"
                      color={colors.GRAY_700}
                      fontWeight={fontWeight.MEDIUM}
                      size={sizes.SM}>
                      {t('taxBoundingStatus')}
                    </Text>
                    <Flex className="pb-6 px-2">
                      <Field name="taxExempt">
                        {({ input }) => (
                          <Flex alignItems="center" className="w-6/12 pr-3">
                            <Toggle handleChange={input.onChange} value={input.value} />
                            <Text
                              className="pl-4"
                              color={colors.GRAY_700}
                              fontWeight={fontWeight.MEDIUM}
                              size={sizes.SM}>
                              {t('taxExempt')}
                            </Text>
                          </Flex>
                        )}
                      </Field>
                      <Field name="bonded">
                        {({ input }) => (
                          <Flex alignItems="center" className="w-6/12 pl-3">
                            <Toggle handleChange={input.onChange} value={input.value} />
                            <Text
                              className="pl-4"
                              color={colors.GRAY_700}
                              fontWeight={fontWeight.MEDIUM}
                              size={sizes.SM}>
                              {t('bonded')}
                            </Text>
                          </Flex>
                        )}
                      </Field>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex>
                  <Field name="buildingType">
                    {({ input, meta }) => {
                      return (
                        <NDropdown
                          className="w-6/12 pb-6 pr-3"
                          errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                          id={input.name}
                          inputClassName="w-full"
                          label={t('buildingType')}
                          listClass="max-h-36 p-1 pt-0 pb-0"
                          name={input.name}
                          onChange={input.onChange}
                          options={buildingTypeList}
                          placeholder={t('buildingType')}
                          value={input.value}
                        />
                      )
                    }}
                  </Field>
                </Flex>

                <div className="pb-6 w-2/3">
                  <Field name="country">
                    {({ input, meta }) => {
                      return (
                        <NDropdown
                          defaultValue={countriesList[0].value}
                          errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                          id={input.name}
                          inputClassName="w-full"
                          label={t('countryRegion')}
                          name={input.name}
                          onChange={(e) => {
                            const nextCountry = e.target.value

                            if (
                              nextCountry !== values.country &&
                              nextCountry === countriesList[0].value
                            ) {
                              form.mutators.clearState()
                            }

                            input.onChange(nextCountry)
                          }}
                          options={countriesList}
                          placeholder={t('countryRegion')}
                          value={input.value}
                        />
                      )
                    }}
                  </Field>
                </div>

                <div className="w-full pb-6">
                  <Field name="street">
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('streetAddress')}
                          placeholder={t('address')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>

                <div className="w-full pb-6">
                  <Field name="street2">
                    {({ input, meta }) => {
                      return (
                        <Input
                          errorMessage={
                            meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                          }
                          id={input.name}
                          label={t('streetAddress2')}
                          placeholder={t('address2')}
                          {...input}
                        />
                      )
                    }}
                  </Field>
                </div>

                <Flex className="w-full pb-6 space-x-2">
                  <div className="w-4/12">
                    <Field name="city">
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('city')}
                            placeholder={t('city')}
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-4/12">
                    <Field name="state">
                      {({ input, meta }) => {
                        return values.country === countriesList[0].value ? (
                          <NDropdown
                            errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                            id={input.name}
                            inputClassName="w-full"
                            label={t('stateProvince')}
                            name={input.name}
                            onChange={(e) => {
                              input.onChange(e.target.value)
                            }}
                            options={statesList}
                            placeholder={t('state')}
                            value={input.value}
                          />
                        ) : (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('stateProvince')}
                            placeholder={t('state')}
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className="w-4/12">
                    <Field name="zipCode" parse={getOnlyNumbers}>
                      {({ input, meta }) => {
                        return (
                          <Input
                            errorMessage={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            label={t('zipPostal')}
                            maxLength={5}
                            placeholder={t('zip')}
                            {...input}
                          />
                        )
                      }}
                    </Field>
                  </div>
                </Flex>
              </Flex>

              <Flex className="w-full" justifyContent="end">
                <Button
                  className="mr-4"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="cancel-update"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  disabled={loading}
                  label={t('save')}
                  testData="submit-update-project"
                  type="submit"
                />
              </Flex>
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => {
        return {
          name: validateRequiredField(values.name),
          kind: validateRequiredField(values.kind),
          role: validateRequiredField(values.role),
          status: validateRequiredField(values.status),
          estimatedCostCents: validateRequiredField(values.estimatedCostCents),
          estimatedStartDate:
            validateRequiredField(values.estimatedStartDate) ||
            validateDateRange(values.estimatedStartDate, values.estimatedEndDate),
          estimatedEndDate:
            validateRequiredField(values.estimatedEndDate) ||
            validateDateRange(values.estimatedStartDate, values.estimatedEndDate),
          buildingType: validateRequiredField(values.buildingType),
          city: validateRequiredField(values.city),
          state: validateRequiredField(values.state),
          street: validateRequiredField(values.street),
          country: validateRequiredField(values.country),
          zipCode: validateRequiredField(values.zipCode) || validateZipCode(values.zipCode),
        }
      }}
    />
  )
}

UpdateProjectForm.propTypes = {
  data: PropTypes.object.isRequired,
  closeForm: PropTypes.func.isRequired,
  contractId: PropTypes.string.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  setIsOpenedModal: PropTypes.func.isRequired,
  setDirtyFormState: PropTypes.func.isRequired,
  setIsSidebarOpened: PropTypes.func.isRequired,
  setSelectedProject: PropTypes.func.isRequired,
}

export default UpdateProjectForm

import React from 'react'
import { Money, Text, Tooltip } from '../../../ui-kit'
import Decimal from 'decimal.js'

export const getAllInvoicesStatQueryParams = (sort) => ({
  scope: 'outstanding',
  sort,
  withTotalPastDue: true,
  perPage: 11,
  withOverdueLevels: true,
})

export const getInvoicesLargestPastDueColumns = (t) => [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'buyerName',
    headerName: t('name'),
    maxWidth: 200,
    minWidth: 50,
    flex: 0.5,
    sortable: false,
    renderCell: (values) => (
      <a
        className={'text-blue-600 text-ellipsis overflow-hidden'}
        href={`/customers/${values?.row?.id}`}>
        {values?.row?.buyer.name}
      </a>
    ),
  },
  {
    field: 'outstandingAmountCents',
    headerName: t('outstanding'),
    renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
    maxWidth: 140,
    minWidth: 50,
    flex: 0.35,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'percentCurrent',
    headerName: t('percentCurrent'),
    renderCell: (values) => {
      const currentOverdueLevelGroup = values?.row?.overdueLevelGroups?.find(
        (overdueLevelGroup) => !overdueLevelGroup.overdueLevel,
      )
      const otherOverdueLevelGroups = values?.row?.overdueLevelGroups?.filter(
        (overdueLevelGroup) => !!overdueLevelGroup.overdueLevel,
      )

      if (!currentOverdueLevelGroup) {
        return '-'
      }

      const tooltipContent = (
        <>
          <div className="py-2 px-2">
            <Text color="text-black-500">{t('totalCurrent')}: </Text>
            <Money value={currentOverdueLevelGroup.amountCents} />
          </div>
          <div className="py-2 px-2">
            <Text color="text-black-500">{t('totalPastDue')}: </Text>
            <Money
              value={
                otherOverdueLevelGroups?.reduce((acc, group) => acc + group.amountCents, 0) || 0
              }
            />
          </div>
          <div className="py-2 px-2 border-t">
            <Text color="text-black-500">{t('totalOutstanding')}: </Text>
            <Money value={values?.row?.outstandingAmountCents} />
          </div>
        </>
      )

      return (
        <Tooltip content={tooltipContent}>
          <span>
            {currentOverdueLevelGroup.amountCents
              ? new Decimal(
                  (currentOverdueLevelGroup.amountCents / values?.row?.outstandingAmountCents) *
                    100,
                ).toFixed(1)
              : '0.0'}
            %
          </span>
        </Tooltip>
      )
    },
    maxWidth: 140,
    minWidth: 50,
    flex: 0.35,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
  },
]

export const GENERAL_CATEGORY = 'general'
export const COLLECTION_CATEGORY = 'collections'
export const TRANSACTIONAL_CATEGORY = 'transactional'
export const notificationSettings = [
  // New Invoices
  {
    name: 'newInvoices',
    title: 'newInvoicesDailySummaryNotification',
    contactTitle: 'newInvoicesNotification',
    description: 'newInvoicesNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: GENERAL_CATEGORY,
  },
  // Generate and Send Monthly Statements From Suppli
  {
    name: 'monthlyStatements',
    title: 'generateAndSendMonthlyStatement',
    contactTitle: 'monthlyStatementNotification',
    description: 'monthlyStatementNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: GENERAL_CATEGORY,
  },
  // Statement Cutoff
  {
    name: 'monthlyStatements',
    title: 'statementCutoff',
    description: '',
    dropdownKey: 'statementCutoff',
    dropdownOptionKey: 'statementCutoff',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: GENERAL_CATEGORY,
  },
  // Document Uploaded
  {
    name: 'documentsUploaded',
    title: 'documentsNotification',
    contactTitle: 'documents',
    description: 'documentsNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: GENERAL_CATEGORY,
  },
  // Invoice Due Date Reminder (Due Date)
  {
    name: 'invoiceDueDateTodayReminder',
    title: 'invoiceDueDateNotification',
    description: 'invoiceDueDateNotificationNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: COLLECTION_CATEGORY,
  },
  // Invoice Due Date Reminder (3 Days Before)
  {
    name: 'invoiceDueDate3DaysReminder',
    title: '3daysReminderNotification',
    description: '3daysReminderNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: COLLECTION_CATEGORY,
  },
  // Invoice Due Date Reminder (7 Days Before) -
  {
    name: 'invoiceDueDate7DaysReminder',
    title: '7daysReminderNotification',
    description: '7daysReminderNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: COLLECTION_CATEGORY,
  },
  // Past Due Invoice Reminder
  {
    name: 'invoicePastDueDateReminder',
    title: 'invoicePastDueNotification',
    description: 'invoicePastDueNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: COLLECTION_CATEGORY,
  },
  {
    name: 'invoicePastDueDateReminder',
    title: '',
    description: '',
    dropdownKey: 'frequency',
    dropdownOptionKey: 'invoicePastDueFrequencyTerms',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: COLLECTION_CATEGORY,
  },
  // Early Payment Discount Expiration Reminder
  {
    name: 'earlyPaymentDiscountReminder',
    title: 'earlyPaymentDiscountNotification',
    description: 'earlyPaymentDiscountNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: COLLECTION_CATEGORY,
  },
  // Successful Payment (Card or ACH) +
  {
    name: 'standardPaymentSuccessful',
    title: 'successfulPaymentReminderAch',
    description: 'successfulPaymentReminderDescriptionAch',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Successful Payment (External Payment or Credits/Deposits)
  {
    name: 'alternativePaymentSuccessful', // standard_payment_successful and alternative_payment_successful
    title: 'successfulPaymentReminderAlternative',
    description: 'successfulPaymentReminderDescriptionAlternative',
    isDisplayOnContract: true,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Initiated ACH Payment
  {
    name: 'paymentInitiated',
    title: 'initiatedPaymentReminder',
    description: 'initiatedPaymentReminderDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Failed Payment +
  {
    name: 'paymentFailed',
    title: 'failedPaymentNotification',
    description: 'failedPaymentReminderDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // ACH Chargeback +
  {
    name: 'achChargeback',
    title: 'achChargebackReminder',
    description: 'achChargebackReminderNotificationDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Payment Request - Successful Payment +
  {
    name: 'paymentRequestPaid',
    title: 'paymentRequestPaidReminder',
    description: 'paymentRequestPaidReminderDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Payment Request - ACH Payment Initiated +
  {
    name: 'paymentRequestInitiated',
    title: 'paymentRequestInitiatedReminder',
    description: 'paymentRequestInitiatedDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Payment Request - Failed Payment +
  {
    name: 'paymentRequestFailed',
    title: 'paymentRequestFailedReminder',
    description: 'paymentRequestFailedDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Voided Payment (Card or ACH)
  {
    name: 'standardPaymentVoided',
    title: 'standardVoidedPaymentReminder',
    description: 'standardVoidedPaymentReminderDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Voided Payment (External Payment or Credits/Deposits)
  {
    name: 'alternativePaymentVoided',
    title: 'alternativeVoidedPaymentReminder',
    description: 'alternativeVoidedPaymentReminderDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Invoice Refund
  {
    name: 'invoicePaymentRefundCreated',
    title: 'standardInvoicePaymentRefundCreatedReminder',
    description: 'standardInvoicePaymentRefundCreatedDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Failed Refund
  {
    name: 'invoicePaymentRefundFailed',
    title: 'invoicePaymentRefundFailedReminder',
    description: 'invoicePaymentRefundFailedDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Deposit Authorization Created
  {
    name: 'depositAuthorization',
    title: 'depositAuthorizationReminder',
    description: 'depositAuthorizationReminderDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Deposit Authorization Canceled
  {
    name: 'depositAuthorizationCancelled',
    title: 'depositAuthorizationCancelledReminder',
    description: 'depositAuthorizationCancelledReminderDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Deposit Authorization Charged
  {
    name: 'depositAuthorizationCaptured',
    title: 'depositAuthorizationChargedReminder',
    description: 'depositAuthorizationChargedReminderDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Deposit Return
  {
    name: 'depositReturn',
    title: 'depositReturnReminder',
    description: 'depositReturnReminderDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Promise-to-Pay (P2P)
  {
    name: 'promiseToPayLifecycleEvent',
    title: 'paymentPromiseLifecycleReminder',
    description: 'paymentPromiseLifecycleDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Promise-to-Pay (P2P) Expiration Reminder +
  {
    name: 'promiseToPayExpirationReminder',
    title: 'paymentPromiseExpirationReminder',
    description: 'paymentPromiseExpirationReminderDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Invoice Disputes
  {
    name: 'disputeCreatedOrResolved',
    title: 'disputesReminder',
    description: 'disputesReminderDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Lien Waiver Request
  {
    name: 'lienWaiverRequested',
    title: 'lienWaiverReminder',
    description: 'lienWaiverReminderDescription',
    isDisplayOnContract: false,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Autopay Enrolled
  {
    name: 'autopayEnabled',
    title: 'autopayEnabledReminder',
    description: 'autopayEnabledReminderNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Autopay Unenrolled
  {
    name: 'autopayDisabled',
    title: 'autopayDisabledReminder',
    description: 'autopayDisabledReminderNotificationDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Expiring Payment Method Reminder
  {
    name: 'paymentMethodExpirationReminder',
    title: 'expiringPaymentMethodReminder',
    description: 'expiringPaymentMethodReminderDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: true,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Payment Method Saved
  {
    name: 'paymentMethodCreated',
    title: 'paymentMethodCreatedReminder',
    description: 'paymentMethodCreatedReminderDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: TRANSACTIONAL_CATEGORY,
  },
  // Payment Method Deleted
  {
    name: 'paymentMethodDeleted',
    title: 'paymentMethodDeletedReminder',
    description: 'paymentMethodDeletedReminderDescription',
    isDisplayOnContract: true,
    isDisplayOnMerchant: false,
    category: TRANSACTIONAL_CATEGORY,
  },
]

export const {
  generalNotificationSettings,
  collectionsNotificationSettings,
  transactionalNotificationSettings,
} = {
  generalNotificationSettings: notificationSettings.filter(
    (notificationSettings) =>
      notificationSettings.category === GENERAL_CATEGORY &&
      notificationSettings.isDisplayOnContract,
  ),
  collectionsNotificationSettings: notificationSettings.filter(
    (notificationSettings) =>
      notificationSettings.category === COLLECTION_CATEGORY &&
      notificationSettings.isDisplayOnContract,
  ),
  transactionalNotificationSettings: notificationSettings.filter(
    (notificationSettings) =>
      notificationSettings.category === TRANSACTIONAL_CATEGORY &&
      notificationSettings.isDisplayOnContract,
  ),
}

export const getContractSettingsByCategory = (category) =>
  notificationSettings.filter(
    (notificationSettings) =>
      notificationSettings.category === category && notificationSettings.isDisplayOnContract,
  )

export const notificationOptions = {
  invoicePastDueFrequencyTerms: [
    { value: 'daily', label: 'Daily' },
    {
      value: 'weekly',
      label: 'Weekly',
    },
    {
      value: 'biweekly',
      label: 'Bi-Weekly',
    },
  ],
  statementCutoff: [
    { value: '25th_day', label: '25th Day' },
    {
      value: 'last_day',
      label: 'End of Month',
    },
  ],
  customersScope: [
    {
      id: 'all',
      value: 'all',
    },
    {
      id: 'assigned',
      value: 'assigned',
    },
    {
      id: 'createdByMe',
      value: 'created_by_me',
    },
    {
      id: 'none',
      value: 'none',
    },
  ],
}

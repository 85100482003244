import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import InvoiceRefundForm from './InvoiceRefundForm'
import { LoadingSpinner, Dialog } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { InvoiceRefundable } from '../../../queriesUpdated/queries/invoiceRefundable.gql'
import { useCustomQuery } from '../../../hooks/useCustomQuery'

const InvoiceRefundModal = ({
  invoiceId,
  isOpened,
  setIsOpened,
  reopenSidebar,
  onSuccessSubmit,
}) => {
  const [invoiceRefundableData, setInvoiceRefundableData] = useState({})
  const onCompleted = useCallback((response) => {
    setInvoiceRefundableData(response?.invoice || {})
  }, [])
  const { loading } = useCustomQuery({
    query: InvoiceRefundable,
    onCompleted,
    queryOptions: {
      variables: { id: invoiceId },
      skip: !isOpened || !invoiceId,
    },
    rollbarOptions: { operationName: 'InvoiceRefundable', target: 'InvoiceRefundModal' },
  })

  const { t } = useTranslation()
  const closeForm = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened, reopenSidebar])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('issueRefund')}
      titleClass="text-2xl font-semibold pl-2">
      {!loading ? (
        <InvoiceRefundForm
          closeForm={closeForm}
          data={invoiceRefundableData}
          invoiceInitialData={invoiceRefundableData}
          isFormDirty={isFormDirty}
          onSuccessSubmit={onSuccessSubmit}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsOpened}
        />
      ) : (
        <LoadingSpinner loading={loading} />
      )}
    </Dialog>
  )
}

InvoiceRefundModal.propTypes = {
  invoiceId: PropTypes.string,
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  reopenSidebar: PropTypes.func.isRequired,
  onSuccessSubmit: PropTypes.func,
}

export default InvoiceRefundModal

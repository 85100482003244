import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import classes from './StatusBadge.module.scss'
import { Icon } from '../../index'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

const StatusBadge = ({ color, iconName, value, label, className }) => {
  const [displayValue, setDisplayValue] = useState(null)
  const [colorBadge, setColorBadge] = useState('')
  const { t } = useTranslation()
  const colorMap = {
    current: 'green',
    '1-30': 'yellow',
    '31-60': 'orange',
    '61-90': 'red',
    '90+': 'darkRed',
  }
  const valueMap = {
    '91-0': '90+',
  }

  const badgeClasses = cx(
    classes.badge,
    {
      [classes[color || colorBadge]]: !!color || !!colorBadge,
      ['justify-center']: !iconName,
      ['justify-start']: !!iconName,
    },
    className,
  )

  useEffect(() => {
    const displayName = valueMap[value] || value
    setDisplayValue(displayName)
    if (!color) {
      setColorBadge(colorMap[displayName])
    }
  }, [value])

  return (
    <span className={badgeClasses}>
      {iconName && <Icon name={iconName} />}

      {label ? label : t(displayValue)}
    </span>
  )
}

StatusBadge.propTypes = {
  color: PT.oneOf([
    'blue',
    'green',
    'yellow',
    'orange',
    'red',
    'gray',
    'darkRed',
    'bordered',
    'suppliOrange',
    'darkGray',
  ]),
  iconName: PT.string,
  label: PT.string,
  value: PT.oneOfType([PT.string, PT.number]).isRequired,
  dueDate: PT.string,
  className: PT.string,
}

StatusBadge.defaultProps = {
  color: null,
  iconName: null,
  dueDate: '',
}

export default StatusBadge

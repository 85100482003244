import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { notificationOptions } from './customerNotificationsConfig'
import { Flex, Tooltip } from '../../ui-kit'
import { Field } from 'react-final-form'
import NDropdown from '../../ui-kit/components/dropdown/NDropdown'
import Toggle from '../../ui-kit/components/inputs/Toggle'
import { snakeCase } from 'lodash'
import RadioGroup from '../../ui-kit/components/inputs/RadioGroup'

const ToggleItem = ({
  values,
  name,
  title,
  description,
  dropdownKey,
  dropdownOptionKey,
  radioKey,
  radioOptionKey,
  disabled,
  onChange,
  hideDropdowns,
  actionSlot,
  actionSlotProps,
  isFirstEl,
  excludedOptions,
  isReadOnlyNonAdmin,
}) => {
  const { t } = useTranslation()

  const targetSetting = values?.find((setting) => setting.name === snakeCase(name))

  if (!targetSetting) {
    return null
  }

  const { emailEnabled, smsEnabled } = targetSetting

  if (radioKey) {
    return (
      <Flex
        alignItems="start"
        className={isFirstEl ? 'py-2' : 'py-2 border-t'}
        justifyContent="between">
        <label className="flex flex-col" htmlFor={name}>
          <span className={emailEnabled || smsEnabled ? 'font-semibold black' : 'text-black-500'}>
            {t(title)}
          </span>
          <span className={'text-warmBlack-400'}>{t(description)}</span>
        </label>
        <Field name={`${name}.${radioKey}`}>
          {({ input }) => {
            const value = targetSetting?.[radioKey]
            return (
              <RadioGroup
                defaultCheckedValue={value}
                excludedOptions={excludedOptions}
                itemClassName="w-[110px] justify-center"
                name={input.name}
                onValueChange={onChange || input.onChange}
                options={notificationOptions[radioOptionKey]}
                wrapperClassName="flex mt-4"
              />
            )
          }}
        </Field>
      </Flex>
    )
  }

  if (dropdownKey) {
    return (
      !hideDropdowns && (
        <Flex alignItems="start" className="py-2 pl-44">
          <Field name={`${name}.${dropdownKey}`}>
            {({ input }) => {
              const options = notificationOptions[dropdownOptionKey]
              const value = targetSetting?.[dropdownKey]
              return (
                <NDropdown
                  className="min-w-[9rem]"
                  id={input.name}
                  isDisabled={disabled}
                  label={t(title || 'frequency')}
                  name={input.name}
                  onChange={onChange || input.onChange}
                  options={options}
                  value={value}
                />
              )
            }}
          </Field>
        </Flex>
      )
    )
  }

  const ActionComponent = actionSlot || Toggle

  return (
    <Flex alignItems="start" className={isFirstEl ? 'py-2' : 'py-2 border-t'}>
      <Field name={`${name}.emailEnabled`}>
        {({ input }) => (
          <Tooltip
            content={isReadOnlyNonAdmin ? t('customerSettingsReadOnlyNonAdmin') : ''}
            placement="bottom">
            <ActionComponent
              disabled={disabled}
              handleChange={onChange || input.onChange}
              id={input.name}
              isDisabled={disabled}
              name={input.name}
              onChange={input.onChange}
              value={emailEnabled}
              {...actionSlotProps}
              className={cx('mt-[3px] ml-4', actionSlotProps.className)}
            />
          </Tooltip>
        )}
      </Field>
      <Field name={`${name}.smsEnabled`}>
        {({ input }) => (
          <Tooltip
            content={isReadOnlyNonAdmin ? t('customerSettingsReadOnlyNonAdmin') : ''}
            placement="bottom">
            <ActionComponent
              disabled={disabled}
              handleChange={onChange || input.onChange}
              id={input.name}
              isDisabled={disabled}
              name={input.name}
              onChange={input.onChange}
              value={smsEnabled}
              {...actionSlotProps}
              className={cx('mt-[3px] ml-8', actionSlotProps.className)}
            />
          </Tooltip>
        )}
      </Field>
      <label className="ml-14 flex flex-col" htmlFor={name}>
        <span className={emailEnabled || smsEnabled ? 'font-semibold black' : 'text-black-500'}>
          {t(title)}
        </span>
        <span className={'text-warmBlack-400'}>{t(description)}</span>
      </label>
    </Flex>
  )
}

ToggleItem.propTypes = {
  values: PT.array,
  excludedOptions: PT.array,
  name: PT.string.isRequired,
  title: PT.string.isRequired,
  description: PT.string.isRequired,
  dropdownKey: PT.string,
  dropdownOptionKey: PT.string,
  radioKey: PT.string,
  radioOptionKey: PT.string,
  disabled: PT.bool,
  onChange: PT.func,
  actionSlot: PT.object,
  hideDropdowns: PT.bool,
  isFirstEl: PT.bool,
  actionSlotProps: PT.object,
  isReadOnlyNonAdmin: PT.bool,
}

ToggleItem.defaultProps = {
  values: [],
  excludedOptions: [],
  actionSlotProps: {},
}

export default ToggleItem

import { Flex, InfoNote, Money, StatusBadge, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import Button from '../../../ui-kit/components/buttons/Button'
import { creditStatusMapping } from '../invoicesUtils'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { useNavigate } from 'react-router-dom'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import CreditActivities from '../creditActivity/CreditActivities'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { UpdateCreditMemo } from '../../../queries/mutations/updateCreditMemo.gql'
import { InvoicesCreditsQuery } from '../../../queries/invoices/invoicesCredits.gql'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const SidebarCreditContent = ({ data }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const descriptionData = [
    { label: t('location'), value: data?.location?.name || '-' },
    {
      label: t('customer'),
      node: (
        <Button
          label={data?.contract?.buyer?.name}
          onClick={() => navigate(`/customers/${data?.contract?.id}/overview`)}
          size={sizes.SM}
          testData="customer-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    {
      label: t('project'),
      value: !data?.project?.name && '-',
      node: data?.project?.name && (
        <Button
          className="overflow-hidden"
          label={data.project.name}
          onClick={() =>
            navigate(
              // eslint-disable-next-line max-len
              `/customers/${data?.contract?.id}/projects/${data.project.status}?additionalFilters=id&filters=id.${data.project.id}&openSidebarParams=id.${data.project.id}`,
            )
          }
          size={sizes.SM}
          testData="project-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    { label: t('createdOn'), value: <FormattedDate date={data?.issueDate} /> },
    { label: t('amount'), value: <Money value={data?.amountCents} /> },
  ]
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const [updateCredit, { loading }] = useCustomMutation({
    mutation: UpdateCreditMemo,
    rollbarOptions: { operationName: 'UpdateCreditMemo', target: 'SidebarCreditContent' },
    mutationOptions: {
      refetchQueries: [InvoicesCreditsQuery],
    },
  })

  return (
    <Flex column>
      <Flex column>
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
          {t('creditMemo')}
        </Text>

        <InfoNote label={t('id')} size={sizes.SM}>
          {data.referenceId}
        </InfoNote>
      </Flex>

      <Flex className="mt-3">
        <Flex className="w-6/12" column>
          <SidebarLabel text="totalOutstanding" />
          <Money className="text-2xl mt-1" value={data?.outstandingAmountCents} />
        </Flex>
        <Flex className="w-6/12" column>
          <SidebarLabel text="status" />
          <div className="mt-2">
            <StatusBadge
              color={creditStatusMapping[data?.balanceStatus]?.color}
              value={t(creditStatusMapping[data?.balanceStatus]?.label)}
            />
          </div>
        </Flex>
      </Flex>

      <DividedList content={descriptionData} />

      <MemoField
        closeForm={() => null}
        entityId={data.id}
        initialValue={data.memo}
        isFormDirty={memoFormIsDirty}
        isLoading={loading}
        responseEntityName={'updateCredit'}
        setDirtyFormState={setMemoFormIsDirty}
        updateEntity={updateCredit}
      />

      {(!!data?.events?.length || !!data?.audits?.length) && (
        <CreditActivities contractId={data.contract?.id} data={data} />
      )}
    </Flex>
  )
}

SidebarCreditContent.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SidebarCreditContent

import React, { useCallback, useMemo } from 'react'
import PT from 'prop-types'
import { Form, Field } from 'react-final-form'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text } from '../../../ui-kit'
import { CreateNote } from '../../../queries/mutations/createNote.gql'
import { UpdateNote } from '../../../queries/mutations/updateNote.gql'
import { TogglePinNote } from '../../../queries/mutations/togglePinNote.gql'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { ContractEventsQuery } from '../../../queries/contractEvents.gql'
import { dateType } from '../../../types/defaultTypes'
import { ContractNotes } from '../../../queries/contractNotes.gql'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const AddNoteForm = ({
  customerName,
  setIsOpenedModal,
  contractId,
  isFormDirty,
  setDirtyFormState,
  requestClose,
  closeForm,
  isViewMode,
  note,
  handleDeleteNote,
}) => {
  const initialValues = useMemo(
    () => ({
      message: isViewMode ? note?.message : '',
      // setReminder: isViewMode ? !!note?.remindOn : false,
      // date: isViewMode && note?.remindOn ? DateTime.fromISO(note?.remindOn).toJSDate() : '',
      pin: isViewMode ? !!note?.pinnedAt : false,
    }),
    [note, isViewMode],
  )
  const { t } = useTranslation()
  const onCompleted = useCallback(() => {
    setIsOpenedModal(false)
  }, [])
  const [createNote, { loading: isCreateNoteLoading }] = useCustomMutation({
    mutation: CreateNote,
    onCompleted,
    rollbarOptions: { operationName: 'CreateNote', target: 'AddNoteForm' },
    mutationOptions: {
      refetchQueries: [ContractEventsQuery, ContractNotes],
    },
  })
  const [updateNote, { loading: isUpdateNoteLoading }] = useCustomMutation({
    mutation: UpdateNote,
    onCompleted,
    rollbarOptions: { operationName: 'UpdateNote', target: 'AddNoteForm' },
    mutationOptions: {
      refetchQueries: [ContractEventsQuery, ContractNotes],
    },
  })
  const [togglePinNote, { loading: isTogglePinNoteLoading }] = useCustomMutation({
    mutation: TogglePinNote,
    rollbarOptions: { operationName: 'TogglePinNote', target: 'AddNoteForm' },
  })

  const handleSubmit = async (values) => {
    if (isViewMode && !!note?.pinnedAt !== values.pin) {
      await togglePinNote({
        variables: {
          id: note.id || note.noteId,
        },
        refetchQueries: !note.editable ? [ContractEventsQuery, ContractNotes] : [],
        onCompleted: !note.editable ? () => setIsOpenedModal(false) : void 0,
      })
    }

    // const formattedDate = getFormattedDate(values.date, SERVER_DATE_FORMAT)
    if (isViewMode) {
      note.editable &&
        updateNote({
          variables: {
            id: note.id || note.noteId,
            data: {
              message: values.message,
              // ...(values.setReminder && values.date && { remindOn: formattedDate }),
            },
          },
        })
    } else {
      createNote({
        variables: {
          data: {
            contractId,
            message: values.message,
            // ...(values.setReminder && values.date && { remindOn: formattedDate }),
            pin: values.pin,
          },
        },
      })
    }
  }

  return (
    <div className={'w-[700px]'}>
      <div className={'mb-2 font-bold'}>
        {isViewMode ? note?.createdBy?.fullName : customerName}
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, dirty, touched }) => {
          useBeforeUnload({ when: touched && dirty })
          if (touched && dirty !== isFormDirty) {
            setDirtyFormState(dirty)
          }
          return (
            <form onSubmit={handleSubmit}>
              <Field name={'pin'}>
                {({ input }) => {
                  return (
                    <Flex>
                      <Text className="pb-2 pr-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
                        {t('pinNote')}
                      </Text>
                      <Toggle handleChange={input.onChange} value={input.value} />
                    </Flex>
                  )
                }}
              </Field>
              <Field name={'message'}>
                {({ input }) => {
                  return (
                    <Textarea
                      disabled={note ? !note.editable : false}
                      id={input.name}
                      inputClassName={'h-44'}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )
                }}
              </Field>
              <Flex className="w-full mt-6" justifyContent="between">
                <div>
                  {isViewMode && note?.editable && (
                    <Button
                      iconName="trash"
                      iconType="outline"
                      label={t('delete')}
                      onClick={handleDeleteNote}
                      testData="trash-note"
                      variant={buttonsVariants.TERTIARY}
                    />
                  )}
                </div>
                <div>
                  <Button
                    className="mr-2 w-28"
                    label={isViewMode ? t('close') : t('cancel')}
                    onClick={requestClose}
                    testData="close-note"
                    variant={'tertiary'}
                    addBorder
                  />
                  {!isViewMode && (
                    <Button
                      className={'w-36'}
                      label={t('addNote')}
                      testData="add-note"
                      type={'submit'}
                    />
                  )}

                  {isViewMode && isFormDirty && (
                    <Button
                      className={'w-36'}
                      disabled={
                        isCreateNoteLoading || isUpdateNoteLoading || isTogglePinNoteLoading
                      }
                      label={t('save')}
                      testData="save-note"
                      type={'submit'}
                    />
                  )}
                </div>
              </Flex>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
      />
    </div>
  )
}

export default AddNoteForm

AddNoteForm.propTypes = {
  customerName: PT.string,
  setIsOpenedModal: PT.func.isRequired,
  contractId: PT.string,
  isFormDirty: PT.bool.isRequired,
  setDirtyFormState: PT.func.isRequired,
  requestClose: PT.func.isRequired,
  closeForm: PT.func.isRequired,
  isViewMode: PT.bool,
  note: PT.shape({
    message: PT.string.isRequired,
    createdAt: PT.string.isRequired,
    createdBy: PT.string.isRequired,
    id: PT.string,
    noteId: PT.string,
    remindOn: dateType,
    pinnedAt: PT.string,
    editable: PT.bool,
  }),
  handleDeleteNote: PT.func,
}

AddNoteForm.defaultProps = {
  note: undefined,
  isViewMode: false,
  handleDeleteNote: null,
}

import React, { useEffect, useState } from 'react'
import { Input, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import fontWeight from '../../ui-kit/fontWeight'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateEmail, validateRequiredField } from '../../utils/validators'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import Button from '../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { normalizePhone } from '../../utils'
import { UpdateVendorUserMutation } from '../../queries/mutations/usersRequest.gql'
import { useNotifications } from '../../hooks/useNotifications'
import ChangePasswordModal from './ChangePasswordModal'
import { applyCurrentUserUpdate } from '../../store/actions/auth'
import { useDispatch } from 'react-redux'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import AsideContainer from '../../components/containers/AsideContainer'

const PersonalInformation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { newNotification } = useNotifications()
  const [modalOpened, setModalOpened] = useState(false)
  const [updateUser, { loading }] = useCustomMutation({
    mutation: UpdateVendorUserMutation,
    rollbarOptions: { operationName: 'UpdateVendorUserMutation', target: 'MyProfile' },
  })
  const onSubmit = (values) => {
    const variables = {
      id: currentUser.id,
      data: { ...values },
    }
    updateUser({ variables }).then(({ data }) => {
      const responseData = data?.updateVendorUser || {}

      if (responseData?.entity) {
        newNotification({ success: t('userProfileUpdatedSuccessfully') })
        dispatch(applyCurrentUserUpdate(responseData.entity))
      }
    })
  }

  useEffect(() => {
    setBreadcrumbs([
      { label: t('profile'), link: '/myprofile' },
      { label: t('personalInformation') },
    ])
  }, [])
  const initialValues = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    email: currentUser.email,
    phoneNumber: currentUser.formattedPhoneNumber || '',
    officePhoneNumber: currentUser.formattedOfficePhoneNumber || '',
  }

  return (
    <AsideContainer
      title={t('personalInformation')}
      titleChild={
        <Text
          className={'text-black-500'}
          color="text-warmBlack-400"
          fontWeight={fontWeight.MEDIUM}>
          {t('profileDescription')}
        </Text>
      }>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form className="flex flex-col w-96" onSubmit={handleSubmit}>
              <div className="w-full">
                <Field name="firstName">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('firstName')}
                        placeholder={t('firstName')}
                        tabIndex="1"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-6">
                <Field name="lastName">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('lastName')}
                        placeholder={t('lastName')}
                        tabIndex="1"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-6">
                <Field name="email">
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('email')}
                        placeholder={t('email')}
                        tabIndex="1"
                        type="email"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-6">
                <Field name="phoneNumber" parse={normalizePhone}>
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('mobilePhone')}
                        placeholder={t('phoneNumber')}
                        tabIndex="1"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-full mt-6">
                <Field name="officePhoneNumber" parse={normalizePhone}>
                  {({ input, meta }) => {
                    return (
                      <Input
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        label={t('officePhone')}
                        placeholder={t('phoneNumber')}
                        tabIndex="1"
                        {...input}
                      />
                    )
                  }}
                </Field>
              </div>

              <div className="w-52 mt-6">
                <Button
                  className="w-full"
                  disabled={loading}
                  iconAlign="left"
                  label={t('submit')}
                  size={sizes.BASE}
                  testData="submit-profile"
                  type="submit"
                  variant={buttonsVariants.PRIMARY}
                />
              </div>
              <div className="mt-6">
                <Text size={sizes.LG}>{t('password')}</Text>
              </div>
              <div className="w-52 mt-3">
                <Button
                  className="w-full"
                  iconAlign="left"
                  label={t('changePassword')}
                  onClick={() => setModalOpened(true)}
                  size={sizes.BASE}
                  testData="change-password"
                  variant={buttonsVariants.SECONDARY}
                />
              </div>
            </form>
          )
        }}
        validate={(values) => ({
          firstName: validateRequiredField(values.firstName),
          lastName: validateRequiredField(values.lastName),
          email: validateRequiredField(values.email) || validateEmail(values.email),
        })}
      />

      {modalOpened && <ChangePasswordModal isOpened={modalOpened} setIsOpened={setModalOpened} />}
    </AsideContainer>
  )
}

export default PersonalInformation

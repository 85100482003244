import React from 'react'
import PropTypes from 'prop-types'
import { Flex, FormattedDate, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'

const ActivityCardStatus = ({ statusNode, lastUpdatesDate }) => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" className="ml-16 justify-around" column>
      {statusNode}
      <Text>
        {t('lastUpdated')}: <FormattedDate date={lastUpdatesDate} />
      </Text>
    </Flex>
  )
}

export default ActivityCardStatus

ActivityCardStatus.propTypes = {
  statusNode: PropTypes.node,
  lastUpdatesDate: PropTypes.string,
}

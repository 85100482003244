import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text, Dialog } from '../../../ui-kit'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { addDepositModes } from './modes'
import DepositReceivePaymentForm from './DepositReceivePaymentForm'
import DepositRequestPaymentForm from './DepositRequestPaymentForm'

const AddDepositModal = ({
  isOpened,
  setIsOpened,
  contractData,
  isAllContracts,
  refetchContract,
  refetchDeposits,
}) => {
  const { t } = useTranslation()
  const [mode, setMode] = useState(addDepositModes.SELECT)

  const closeForm = useCallback(() => {
    setIsOpened(false)
    setMode(addDepositModes.SELECT)
    refetchDeposits()
  }, [setIsOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('addDeposit')}
      titleClass={'font-semibold text-2xl'}>
      {mode === addDepositModes.SELECT && (
        <Flex className="px-12" column>
          <Button
            className="mt-6"
            label={
              <Flex onClick={() => setMode(addDepositModes.RECEIVE_PAYMENT)} column>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
                  {t('receivePayment')}
                </Text>
                <Text color="text-warmBlack-400">{t('processDepositDirectly')}</Text>
              </Flex>
            }
            testData="add-deposit-directly"
            variant={buttonsVariants.SECONDARY}
          />
          <Button
            className="mt-6"
            label={
              <Flex onClick={() => setMode(addDepositModes.REQUEST_PAYMENT)} column>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
                  {t('requestPayment')}
                </Text>
                <Text color="text-warmBlack-400">{t('sendDepositPaylink')}</Text>
              </Flex>
            }
            testData="request-deposit"
            variant={buttonsVariants.TERTIARY}
          />
        </Flex>
      )}
      {mode === addDepositModes.RECEIVE_PAYMENT && (
        <DepositReceivePaymentForm
          closeForm={closeForm}
          contractData={contractData}
          isAllContracts={isAllContracts}
          isFormDirty={isFormDirty}
          isOpened={isOpened}
          refetchContract={refetchContract}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpened={setIsOpened}
        />
      )}
      {mode === addDepositModes.REQUEST_PAYMENT && (
        <DepositRequestPaymentForm
          closeForm={closeForm}
          contractData={contractData}
          isAllContracts={isAllContracts}
          isFormDirty={isFormDirty}
          isOpened={isOpened}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpened={setIsOpened}
        />
      )}
    </Dialog>
  )
}

AddDepositModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  contractData: PropTypes.shape({
    aging: PropTypes.number,
    id: PropTypes.string,
    customerName: PropTypes.string,
    outstandingAmountCents: PropTypes.number,
    availableCreditAmountCents: PropTypes.number,
    availableDepositAmountCents: PropTypes.number,
    buyer: PropTypes.shape({
      name: PropTypes.string,
      paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  }),
  isAllContracts: PropTypes.bool.isRequired,
  refetchContract: PropTypes.func,
  refetchDeposits: PropTypes.func.isRequired,
}

export default AddDepositModal

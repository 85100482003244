import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { Flex, Money, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'

const ReportTotal = ({
  totalLabelKey,
  fontWeight,
  items,
  wrapperClassName,
  itemSeparatorClassName,
}) => {
  const { t } = useTranslation()

  return (
    <Flex className={wrapperClassName} justifyContent="between">
      <Text fontWeight={fontWeight}>{t(totalLabelKey)}</Text>
      <Flex>
        {items.map((item, index) => (
          <Flex
            alignItems="center"
            className={cx(index !== 0 && itemSeparatorClassName)}
            key={item.captionKey || item.caption}>
            <Text fontWeight={fontWeight}>{`${
              item.captionKey ? t(item.captionKey) : item.caption
            }:`}</Text>
            {item.isPlainText ? (
              <Text className="ml-1" fontWeight={fontWeight}>
                {item.value}
              </Text>
            ) : (
              <Money className={cx('ml-1', `font-${fontWeight}`)} value={item.value} />
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

ReportTotal.propTypes = {
  wrapperClassName: PropTypes.string,
  totalLabelKey: PropTypes.string,
  fontWeight: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      caption: PropTypes.string,
      captionKey: PropTypes.string,
      isPlainText: PropTypes.bool,
    }),
  ),
  itemSeparatorClassName: PropTypes.string,
}

ReportTotal.defaultProps = {
  fontWeight: fontWeight.SEMIBOLD,
  totalLabelKey: '',
  wrapperClassName: '',
  itemSeparatorClassName: '',
}

export default React.memo(ReportTotal)

import React, { useMemo, useState } from 'react'
import PT from 'prop-types'
import { FileInput, Flex, Input, Dialog } from '../../../ui-kit'
import { CATEGORIES, FILE_RESOURCES } from '../../../constants/fileInputResources'
import { useTranslation } from 'react-i18next'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import { DOCUMENT_TYPES } from './documentTypes'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import Toggle from '../../../ui-kit/components/inputs/Toggle'

const DocumentUploadModal = ({
  contractId,
  modalOpened,
  onModalClose,
  category,
  refetchQueries,
}) => {
  const { t } = useTranslation()
  const [fileName, setFileName] = useState('')
  const [notes, setNotes] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [visibleToCustomer, setVisibleToCustomer] = useState(false)
  const isUploadDisabled = useMemo(() => !fileName || !documentType, [fileName, documentType])

  return (
    <Dialog
      isOpened={modalOpened}
      onModalClose={onModalClose}
      setIsOpened={onModalClose}
      title={t('UploadNewDocument')}>
      <div className="w-full py-4">
        <Input
          className="mb-4"
          id="FileName"
          label={t('fileName')}
          name="fileName"
          onChange={({ target }) => setFileName(target.value)}
          placeholder={t('fileName')}
          tabIndex="1"
          value={fileName}
          required
        />
        <NDropdown
          className="mb-4"
          id="documentType"
          label={t('documentType')}
          name="documentType"
          onChange={({ target }) => setDocumentType(target.value)}
          options={DOCUMENT_TYPES}
          placeholder={t('documentType')}
          value={documentType}
        />
        <Textarea
          id="notes"
          inputClassName={'h-20'}
          label={t('notesOptional')}
          name="notes"
          onChange={({ target }) => setNotes(target.value)}
          placeholder={t('notes')}
          tabIndex="1"
          value={notes}
        />
        <Flex alignItems="center" className="my-6 w-full">
          <Toggle
            handleChange={() => setVisibleToCustomer(!visibleToCustomer)}
            value={visibleToCustomer}
          />
          <span className="pl-4">{t('visibleInCustomerPortal')}</span>
        </Flex>
        <FileInput
          category={category}
          className="mb-4"
          documentType={documentType}
          fileName={fileName}
          id="documentsFileUpload"
          isUploadDisabled={isUploadDisabled}
          notes={notes}
          onFileUpload={onModalClose}
          refetchQueries={refetchQueries}
          resourceId={contractId}
          resourceType={FILE_RESOURCES.CONTRACT}
          visibleToCustomer={visibleToCustomer}
        />
      </div>
    </Dialog>
  )
}

DocumentUploadModal.propTypes = {
  category: PT.oneOf([CATEGORIES.DOCUMENT, CATEGORIES.STATEMENT]).isRequired,
  contractId: PT.string,
  modalOpened: PT.bool.isRequired,
  onModalClose: PT.func.isRequired,
  refetchQueries: PT.arrayOf(PT.object),
}

DocumentUploadModal.defaultProps = {
  refetchQueries: [],
}

export default DocumentUploadModal

import React from 'react'
import cx from 'classnames'
import { LoadingSpinner } from '../../ui-kit'
import PT from 'prop-types'
import StatBlock from './StatBlock'
import { DAYS, MONEY } from '../../utils/dataTypes'

const ShortStats = ({ isLoading, data, direction, itemClassName }) => {
  const directionOffset = direction === 'column' ? 'mb-2.5' : 'mr-2.5'

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        data?.map(({ title, value, type, data: chartData }, index) => {
          return (
            <StatBlock
              className={cx(index !== data.length - 1 ? directionOffset : '', itemClassName)}
              data={chartData}
              key={title}
              number={value}
              title={title}
              type={type}
            />
          )
        })
      )}
    </>
  )
}

export default ShortStats

ShortStats.propTypes = {
  isLoading: PT.bool,
  direction: PT.oneOf('column', 'row'),
  data: PT.arrayOf(
    PT.shape({
      title: PT.string,
      value: PT.number,
      type: PT.oneOf([MONEY, DAYS]),
      data: PT.arrayOf(
        PT.shape({
          date: PT.string,
          value: PT.number,
        }),
      ),
    }),
  ),
  itemClassName: PT.string,
}
ShortStats.defaultProps = {
  isLoading: false,
  direction: 'column',
  itemClassName: '',
}

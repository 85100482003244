import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import sizes from '../../../ui-kit/sizes'

const ActivityCardGeneralInfo = ({
  entityId,
  entityLabelKey,
  detailsValue,
  additionalCaptionNode,
  detailsValueLabelKey,
  secondaryDetailsValue,
  secondaryDetailsValueLabelKey,
}) => {
  const { t } = useTranslation()

  return (
    <Flex column>
      <div className="ml-2 w-6/12 whitespace-nowrap">
        <Text>
          {t(entityLabelKey)}
          {additionalCaptionNode}
          {entityId && (
            <>
              {' '}
              | {t('id')}: <Text color="blue">{entityId}</Text>
            </>
          )}
        </Text>
      </div>

      <Flex alignItems="center" className="ml-2 w-6/12 mt-4 whitespace-nowrap">
        <Text color="text-black-500">{t(detailsValueLabelKey)}:</Text>
        <Text className="ml-1" color="text-black-700">
          {detailsValue}
        </Text>
        {secondaryDetailsValue && secondaryDetailsValueLabelKey && (
          <>
            <Text className={'mx-4'} color={'text-black-400'} size={sizes.XS}>
              |
            </Text>
            <Text color="text-black-500">{t(secondaryDetailsValueLabelKey)}:</Text>
            <Text className="ml-1" color="text-black-700">
              {secondaryDetailsValue}
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default ActivityCardGeneralInfo

ActivityCardGeneralInfo.defaultProps = {
  detailsValueLabelKey: 'createdBy',
}

ActivityCardGeneralInfo.propTypes = {
  entityId: PropTypes.string,
  fullName: PropTypes.string,
  detailsValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  entityLabelKey: PropTypes.string,
  detailsValueLabelKey: PropTypes.string,
  additionalCaptionNode: PropTypes.node,
  secondaryDetailsValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondaryDetailsValueLabelKey: PropTypes.string,
}

import React from 'react'
import PT from 'prop-types'

const CircleStackIcon = ({ className, onClick }) => (
  <svg
    className={className}
    fill="none"
    height="64"
    onClick={onClick}
    stroke="currentColor"
    strokeWidth="1.5"
    viewBox="0 0 24 24"
    width="6"
    xmlns="http://www.w3.org/2000/svg">
    <path
      /* eslint-disable-next-line max-len */
      d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

CircleStackIcon.propTypes = {
  className: PT.string,
  onClick: PT.func,
}

CircleStackIcon.defaultProps = {
  className: '',
  onClick: null,
}

export default CircleStackIcon

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ConfirmationDialog, Flex, Money, NarrowList, Text, Tooltip } from '../../ui-kit'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import Toggle from '../../ui-kit/components/inputs/Toggle'
import AccountTermsModal from '../../pages/settings/paymentSettingsModals/AccountTermsModal'
import CreditCardFeesModal from '../../pages/settings/paymentSettingsModals/CreditCardFeesModal'
import PT from 'prop-types'
import { idType } from '../../types/defaultTypes'
import { SUPPLI_SUPPORT_EMAIL } from '../../constants/contacts'
import Checkbox from '../../ui-kit/components/inputs/Checkbox'
import { vendorPreferences } from '../../pages/settings/PaymentSettings'

const PaymentSettingsContent = ({
  data,
  loading,
  updatePaymentSettings,
  updatePaymentPreference,
  withCardConvenienceFee,
  withLienWaiver,
  withCreditLimit,
  withPaymentTerms,
  isReadOnly,
  isUpdatePaymentSettingsLoading,
  disabledOptions,
}) => {
  const [confirmationSettingKey, setConfirmationSettingKey] = useState('')
  const [accountTermsModalIsOpened, setAccountTermsModalIsOpened] = useState(false)
  const [creditCardFeesModalIsOpened, setCreditCardFeesModalIsOpened] = useState(false)
  const [settingOverride, setSettingOverride] = useState(false)
  const handleSubmit = (values, closeFunc) => {
    const variables = {
      id: data.id,
      data: {
        ...values,
      },
    }

    updatePaymentSettings(variables, closeFunc)
  }

  const { t } = useTranslation()
  const handleFieldEnabled = (propName) => {
    if (vendorPreferences[propName]) {
      const prefValues = Object.keys(vendorPreferences).reduce((acc, item) => {
        acc[item] = data[item]
        return acc
      }, {})
      const updatedValues = { data: { ...prefValues, [propName]: !data[propName] } }
      updatePaymentPreference(updatedValues)
    } else {
      const updatedValues = {
        [propName]: !data[propName],
      }
      handleSubmit(updatedValues)
    }
  }
  const handleFieldEnabledWithOverride = (propName) => {
    const updatedValues = {
      [propName]: { value: !data[propName], overrideValue: settingOverride },
    }
    handleSubmit(updatedValues)
  }
  const handleSubmitSettingsUpdate = (values, closeFunc) => {
    const updatedValues = {
      ...values,
      creditCardFeePercentage: +values.creditCardFeePercentage,
      creditCardFeePaymentAmountDollarThreshold: values.creditCardFeePaymentAmountDollarThreshold
        ? +values.creditCardFeePaymentAmountDollarThreshold
        : values.creditCardFeePaymentAmountDollarThreshold,
      creditCardFeePaymentAmountCentsThreshold: values.creditCardFeePaymentAmountDollarThreshold
        ? +values.creditCardFeePaymentAmountDollarThreshold * 100
        : values.creditCardFeePaymentAmountDollarThreshold,
    }
    delete updatedValues.creditCardFeePaymentAmountDollarThreshold
    handleSubmit(updatedValues, closeFunc)
  }

  const editPaymentTerm = () => {
    setAccountTermsModalIsOpened(true)
  }

  const renderItems = () => {
    const settingsList = []

    if (withPaymentTerms) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="paymentTerms">
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM}>{t('accountTerms')}</Text>
          </div>
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
              {data.paymentTerms}
            </Text>
          </div>
          <div className="w-1/12">
            <Button
              className="hidden"
              disabled={isReadOnly || isUpdatePaymentSettingsLoading}
              iconName={'pencil'}
              label={t('edit')}
              onClick={() => editPaymentTerm()}
              size={sizes.SM}
              testData="edit-payment-term"
              variant={buttonsVariants.LINK}
            />
          </div>
        </Flex>,
      )
    }

    if (withCardConvenienceFee) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="creditCardConvenienceFee">
          <div className="w-5/12">
            <div>
              <Text fontWeight={fontWeight.MEDIUM}>{t('cardConvenienceFee')}</Text>
            </div>
            {data.creditCardFeeEnabled && (
              <div>
                <Text className="pt-2 pr-4" color="text-green-600" size={sizes.XS}>
                  {t('enabled')}
                </Text>
              </div>
            )}
            {!data.creditCardFeeEnabled && (
              <div>
                <Text className="pt-2 pr-4" color="text-red-600" size={sizes.XS}>
                  {t('disabled')}
                </Text>
              </div>
            )}
            {data.creditCardFeeEnabled && (
              <div>
                <Text
                  className="pt-2 pr-4"
                  color="text-warmBlack-400"
                  fontWeight={fontWeight.LIGHT}
                  size={sizes.XS}>
                  {t('creditCardFeeExplanation')}
                </Text>
              </div>
            )}
          </div>
          {data.creditCardFeeEnabled && (
            <>
              <div className="w-6/12 flex flex-col">
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
                  {data.creditCardFeePercentage}
                  <span className="mr-1">%</span>
                </Text>
                <>
                  {data.creditCardFeePaymentAmountCentsThresholdEnabled && (
                    <Text size={sizes.XS}>
                      <span className="mr-1">
                        {t('applyCreditCardFeePaymentAmountCentsGrater')}
                      </span>
                      <Money value={data.creditCardFeePaymentAmountCentsThreshold} />
                    </Text>
                  )}
                  {data.creditCardFeeInvoiceDaysPastDueThresholdEnabled && (
                    <Text size={sizes.XS}>
                      <span className="mr-1">{t('applyToAllPaymentsOnInvoicesGrater')}</span>
                      {data.creditCardFeeInvoiceDaysPastDueThreshold}{' '}
                      <span className="mr-1">{t('daysPastDue')}</span>
                    </Text>
                  )}
                  {data.creditCardFeeForDepositsEnabled && (
                    <Text size={sizes.XS}>
                      <span className="mr-1">{t('applyToDepositPayments')}</span>
                    </Text>
                  )}
                  {!data.creditCardFeeForDepositsEnabled && (
                    <Text size={sizes.XS}>
                      <span className="mr-1">{t('doNotApplyToDepositPayments')}</span>
                    </Text>
                  )}
                  {data.creditCardFeeForPaymentPlansEnabled && (
                    <Text size={sizes.XS}>
                      <span className="mr-1">{t('applyToAllPaymentPlanInstallments')}</span>
                    </Text>
                  )}
                  {!data.creditCardFeeForPaymentPlansEnabled && (
                    <Text size={sizes.XS}>
                      <span className="mr-1">{t('doNotApplyToAllPaymentPlanInstallments')}</span>
                    </Text>
                  )}
                  <Text size={sizes.XS}>
                    {data.creditCardFeeEnabledDefault
                      ? t('newCustomerCreditCardFee')
                      : t('newCustomerNoCreditCardFee')}
                  </Text>
                  <Text size={sizes.XS}>
                    {data.creditCardFeeForAutopayEnabled
                      ? t('applyToAutopay')
                      : t('doNotApplyToAutopay')}
                  </Text>
                </>
                {!data.creditCardFeeEnabled && (
                  <Text size={sizes.XS}>{t('setUpCreditCardFees')}</Text>
                )}
              </div>
              <div className="w-1/12">
                <Button
                  className="mb-2 w-4/12"
                  label={t('edit')}
                  onClick={() => setCreditCardFeesModalIsOpened(true)}
                  size={sizes.SM}
                  testData="edit_cf_settings"
                  variant={buttonsVariants.LINK}
                />
              </div>
            </>
          )}
          {!data.creditCardFeeEnabled && (
            <>
              <div className="w-7/12 flex flex-col">
                <Text size={sizes.XS}>{t('setUpCreditCardFees')}</Text>
              </div>
            </>
          )}
        </Flex>,
      )
    }

    settingsList.push(
      <Flex alignItems="start" className="w-full" key="earlyPaymentDiscount">
        <div className="w-5/12">
          <Text fontWeight={fontWeight.MEDIUM}>{t('earlyPaymentDiscountForCardPayments')}</Text>
        </div>
        <div className="w-5/12 flex flex-col pt-1">
          <Text size={sizes.XS}>{t('allowEarlyPaymentDiscount')}</Text>
        </div>
        <div className="w-1/12" />
        <div className="w-1/12">
          <Tooltip
            content={
              disabledOptions.includes('earlyPaymentDiscount') &&
              t('modifySettingsHint', { email: SUPPLI_SUPPORT_EMAIL })
            }>
            <Toggle
              className="pl-4 pr-5"
              disabled={
                isReadOnly ||
                isUpdatePaymentSettingsLoading ||
                disabledOptions.includes('earlyPaymentDiscount')
              }
              handleChange={() =>
                setConfirmationSettingKey('earlyPaymentCreditCardDiscountEnabledDefault')
              }
              testData="toggle-early-payment-discount"
              value={data.earlyPaymentCreditCardDiscountEnabledDefault}
            />
          </Tooltip>
        </div>
      </Flex>,
    )

    if (withLienWaiver) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="lienWaiver">
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM}>{t('lienWaiverRequest')}</Text>
          </div>
          <div className="w-5/12 flex flex-col pt-1">
            <Text size={sizes.XS}>{t('lienWaiverSettingsDescription')}</Text>
          </div>
          <div className="w-1/12" />
          <div className="w-1/12">
            <Tooltip
              content={
                disabledOptions.includes('lienWaiver') &&
                t('modifySettingsHint', { email: SUPPLI_SUPPORT_EMAIL })
              }>
              <Toggle
                className="pl-4 pr-5"
                disabled={
                  isReadOnly ||
                  isUpdatePaymentSettingsLoading ||
                  disabledOptions.includes('lienWaiver')
                }
                handleChange={() => handleFieldEnabled('lienWaiverRequestEnabled')}
                testData="toggle-lien-waiver"
                value={data.lienWaiverRequestEnabled}
              />
            </Tooltip>
          </div>
        </Flex>,
      )
    }

    if (withCreditLimit) {
      settingsList.push(
        <Flex alignItems="start" className="w-full" key="creditLimit">
          <div className="w-5/12">
            <Text fontWeight={fontWeight.MEDIUM}>{t('creditLimit')}</Text>
          </div>
          <div className="w-5/12 flex flex-col pt-1">
            <Text size={sizes.XS}>{t('creditLimitDescription')}</Text>
          </div>
          <div className="w-1/12" />
          <div className="w-1/12">
            <Tooltip
              content={
                disabledOptions.includes('creditLimit') &&
                t('modifySettingsHint', { email: SUPPLI_SUPPORT_EMAIL })
              }>
              <Toggle
                className="pl-4 pr-5"
                disabled={
                  isReadOnly ||
                  isUpdatePaymentSettingsLoading ||
                  disabledOptions.includes('creditLimit')
                }
                handleChange={() => handleFieldEnabled('showCreditLimit')}
                testData="toggle-credit-limit"
                value={data.showCreditLimit}
              />
            </Tooltip>
          </div>
        </Flex>,
      )
    }

    settingsList.push(
      <Flex alignItems="start" className="w-full" key="customerPaymentTerms">
        <div className="w-5/12">
          <Text fontWeight={fontWeight.MEDIUM}>{t('partialPayments')}</Text>
        </div>
        <div className="w-5/12 flex flex-col pt-1">
          <Text size={sizes.XS}>{t('allowPartialPaymentsInTheCustomerPortal')}</Text>
        </div>
        <div className="w-1/12" />
        <div className="w-1/12">
          <Toggle
            className="pl-4 pr-5"
            disabled={
              isReadOnly ||
              isUpdatePaymentSettingsLoading ||
              disabledOptions.includes('allowPartialPaymentsDefault')
            }
            handleChange={() => setConfirmationSettingKey('allowPartialPaymentsDefault')}
            testData="toggle-allow-partial-payment"
            value={data.allowPartialPaymentsDefault}
          />
        </div>
      </Flex>,
    )

    settingsList.push(
      <Flex alignItems="start" className="w-full" key="customerPaymentTerms">
        <div className="w-5/12">
          <Text fontWeight={fontWeight.MEDIUM}>{t('customerPaymentTerms')}</Text>
        </div>
        <div className="w-5/12 flex flex-col pt-1">
          <Text size={sizes.XS}>{t('showCustomerPaymentTerms')}</Text>
        </div>
        <div className="w-1/12" />
        <div className="w-1/12">
          <Toggle
            className="pl-4 pr-5"
            handleChange={() => handleFieldEnabled('showPaymentTerms')}
            testData="toggle-customer-payment-terms"
            value={data.showPaymentTerms}
          />
        </div>
      </Flex>,
    )

    settingsList.push(
      <Flex alignItems="center" className="w-full" key="customerPaymentTerms">
        <div className="w-5/12">
          <Text fontWeight={fontWeight.MEDIUM}>
            {t('autopaymentIncludeInvoicesWithExpiringDiscounts')}
          </Text>
        </div>
        <div className="w-5/12 flex flex-col pt-1">
          <Text size={sizes.XS}>{t('autopaymentIncludeInvoicesWithExpDisDescription')}</Text>
        </div>
        <div className="w-1/12" />
        <div className="w-1/12">
          <Toggle
            className="pl-4 pr-5"
            handleChange={() =>
              setConfirmationSettingKey('autopayIncludesExpiringDiscountsDefault')
            }
            testData="toggle-autopay-includes-expiring-discounts-default"
            value={data.autopayIncludesExpiringDiscountsDefault}
          />
        </div>
      </Flex>,
    )

    return settingsList
  }

  const creditCardFeesInitials = withCardConvenienceFee
    ? {
        creditCardFeePercentage: data.creditCardFeePercentage,
        creditCardFeePaymentAmountDollarThreshold:
          data.creditCardFeePaymentAmountCentsThreshold / 100,
        creditCardFeePaymentAmountCentsThresholdEnabled:
          data.creditCardFeePaymentAmountCentsThresholdEnabled,
        creditCardFeeInvoiceDaysPastDueThreshold: data.creditCardFeeInvoiceDaysPastDueThreshold,
        creditCardFeeInvoiceDaysPastDueThresholdEnabled:
          data.creditCardFeeInvoiceDaysPastDueThresholdEnabled,
        creditCardFeeForPaymentPlansEnabled: data.creditCardFeeForPaymentPlansEnabled,
        creditCardFeeForDepositsEnabled: data.creditCardFeeForDepositsEnabled,
        creditCardFeeEnabledDefault: data.creditCardFeeEnabledDefault,
        creditCardFeeForAutopayEnabled: data.creditCardFeeForAutopayEnabled,
      }
    : null

  return (
    <>
      {!loading && (
        <div className="mr-10" onSubmit={handleSubmit}>
          <NarrowList listItems={renderItems()} />
        </div>
      )}

      <ConfirmationDialog
        isOpened={confirmationSettingKey}
        onSubmitButtonNoClick={() => {
          setSettingOverride(false)
          setConfirmationSettingKey(false)
        }}
        onSubmitButtonYesClick={() => {
          handleFieldEnabledWithOverride(confirmationSettingKey)
          setConfirmationSettingKey(false)
          setSettingOverride(false)
        }}
        setIsOpened={setConfirmationSettingKey}
        submitButtonNoLabel={t('cancel')}
        submitButtonYesLabel={t('confirm')}
        title={t('confirmHowApplyChange')}>
        <Flex className="w-[788px]" column>
          <Text className="pt-8" color="text-black-500">
            {t('confirmHowApplyChangeDescription1')}
            <Text
              className="text-black mx-1 border-b border-black-500"
              fontWeight={fontWeight.MEDIUM}>
              {t('not')}
            </Text>
            {t('confirmHowApplyChangeDescription2')}
          </Text>
          <Text className="pt-4" color="text-black-500">
            {t('confirmHowApplyChangeDescription3')}
          </Text>
          <label
            className="pt-4 font-medium"
            color="text-black-500"
            htmlFor="globalNotificationOverride">
            {t('confirmHowApplyChangeDescription4')}
          </label>
          <Checkbox
            className="pt-2"
            id="globalNotificationOverride"
            name="globalNotificationOverride"
            onChange={setSettingOverride}
            value={settingOverride}
          />
          <Text className="font-medium pt-4" color="text-black-500">
            {t('confirmHowApplyChangeDescription5')}
          </Text>
        </Flex>
      </ConfirmationDialog>

      {!isReadOnly && accountTermsModalIsOpened && (
        <AccountTermsModal
          handleSubmit={(values) => {
            handleSubmitSettingsUpdate(values, setAccountTermsModalIsOpened)
          }}
          initialAccountTerms={data.paymentTerms}
          isOpened={accountTermsModalIsOpened}
          setIsOpened={setAccountTermsModalIsOpened}
        />
      )}
      {!isReadOnly && withCardConvenienceFee && creditCardFeesModalIsOpened && (
        <CreditCardFeesModal
          handleSubmit={(values) => {
            handleSubmitSettingsUpdate(values, setCreditCardFeesModalIsOpened)
          }}
          initialValues={creditCardFeesInitials}
          isOpened={creditCardFeesModalIsOpened}
          setIsOpened={setCreditCardFeesModalIsOpened}
        />
      )}
    </>
  )
}

export default PaymentSettingsContent
const contractPaymentSettings = PT.shape({
  earlyPaymentCreditCardDiscountEnabledDefault: PT.bool.isRequired,
  id: idType,
  paymentTerms: PT.number.isRequired,
})
const vendorPaymentSettings = PT.shape({
  creditCardFeeEnabledDefault: PT.bool.isRequired,
  creditCardFeeEnabled: PT.bool.isRequired,
  creditCardFeeForPaymentPlansEnabled: PT.bool.isRequired,
  creditCardFeeInvoiceDaysPastDueThreshold: PT.number.isRequired,
  creditCardFeeInvoiceDaysPastDueThresholdEnabled: PT.bool.isRequired,
  creditCardFeePaymentAmountCentsThreshold: PT.number.isRequired,
  creditCardFeePaymentAmountCentsThresholdEnabled: PT.bool.isRequired,
  creditCardFeePercentage: PT.number.isRequired,
  earlyPaymentCreditCardDiscountEnabledDefault: PT.bool.isRequired,
  id: idType,
  paymentTerms: PT.number.isRequired,
  lienWaiverRequestEnabled: PT.bool.isRequired,
  showCreditLimit: PT.bool.isRequired,
  showPaymentTerms: PT.bool.isRequired,
  allowPartialPaymentsDefault: PT.bool.isRequired,
  creditCardFeeForDepositsEnabled: PT.bool,
  creditCardFeeForAutopayEnabled: PT.bool,
  autopayIncludesExpiringDiscountsDefault: PT.bool,
})
PaymentSettingsContent.propTypes = {
  data: PT.oneOfType([contractPaymentSettings, vendorPaymentSettings]),
  loading: PT.bool.isRequired,
  updatePaymentSettings: PT.func.isRequired,
  updatePaymentPreference: PT.func.isRequired,
  withCardConvenienceFee: PT.bool,
  withLienWaiver: PT.bool,
  withCreditLimit: PT.bool,
  withPaymentTerms: PT.bool,
  isReadOnly: PT.bool,
  isUpdatePaymentSettingsLoading: PT.bool,
  disabledOptions: PT.arrayOf(PT.string),
}

PaymentSettingsContent.defaultProps = {
  disabledOptions: [],
  isReadOnly: false,
  withLienWaiver: false,
  withCreditLimit: false,
  withPaymentTerms: false,
  withCardConvenienceFee: false,
  isUpdatePaymentSettingsLoading: false,
}

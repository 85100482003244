import React, { useState } from 'react'
import PT from 'prop-types'
import Uppy from '@uppy/core'
import { FileInput as UppyFileInput, useUppy } from '@uppy/react'
import AwsS3 from '@uppy/aws-s3'
import { useLazyQuery } from '@apollo/client'
import { values } from 'lodash'
import { FilePresignParamsQuery, CreateFileMutation } from '../../../queries/files.gql'
import { Button, Flex, Text } from '../../index'
import { FILE_RESOURCES, CATEGORIES } from '../../../constants/fileInputResources'
import { useTranslation } from 'react-i18next'
import { handleError } from '../../../utils/utils'
import { useNotifications } from '../../../hooks/useNotifications'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

export const LOGO_IMAGE_TYPES = ['.jpg', '.jpeg', '.png' /*, '.webp', '.pdf', '.svg'*/]
const IMAGE_TYPES = ['.jpg', '.jpeg', '.png', '.webp', '.pdf', '.svg']
const DOCS_TYPES = ['.txt', '.csv', '.doc', '.docx', '.xls', '.xlsx']
const MAX_FILE_SIZE = 31457280

const FileInput = ({
  id,
  allowedFileTypes,
  fileName,
  resourceId,
  resourceType,
  category,
  notes,
  documentType,
  onFileUpload,
  submitButtonLabel,
  refetchQueries,
  isUploadDisabled,
  visibleToCustomer,
  setFileName,
  withExtensionsList,
}) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()
  const [loadPresignParams] = useLazyQuery(FilePresignParamsQuery)
  const [createFile, { loading }] = useCustomMutation({
    mutation: CreateFileMutation,
    mutationOptions: { refetchQueries },
  })
  const [fileToBeUploaded, setFileToBeUploaded] = useState(null)
  const [isFileUploadDisabled, setIsFileUploadDisabled] = useState(false)
  const [fileLimitError, setFileLimitError] = useState(false)

  const uppy = useUppy(() => {
    return new Uppy({
      id: `uppy-${id}`,
      autoProceed: false,
      allowMultipleUploadBatches: false,
      debug: true,
      restrictions: {
        maxFileSize: MAX_FILE_SIZE, // 100 MB
        minFileSize: 10,
        maxTotalFileSize: MAX_FILE_SIZE,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes,
        requiredMetaFields: [],
      },
      meta: {},
      onBeforeFileAdded: (currentFile) => {
        setFileLimitError(currentFile.size > MAX_FILE_SIZE)
        setFileToBeUploaded(currentFile)
        return currentFile
      },
      locale: { strings: { chooseFiles: t('chooseFile') } },
      infoTimeout: 5000,
    }).use(AwsS3, {
      getUploadParameters(file) {
        setIsFileUploadDisabled(true)
        const queryVariables = {
          resourceType,
          resourceId,
          category,
          fileName: `${file.name}`,
        }

        return loadPresignParams({ variables: queryVariables }).then(({ data }) => {
          const presignParams = data?.filePresignParams

          setFileMeta(file, presignParams.metadata)
          return {
            method: presignParams.httpMethod,
            url: presignParams.url,
            fields: presignParams.fields,
            headers: {
              'Content-Type': file.type,
            },
          }
        })
      },
    })
  })

  const setFileMeta = (file, meta) => {
    uppy.setFileMeta(file.id, meta)
  }

  const handleUpload = () => {
    uppy.upload().then((result) => {
      if (result.failed.length > 0) {
        console.error('Errors:')
        result.failed.forEach((file) => {
          console.error(file.error)
        })
        setIsFileUploadDisabled(false)
      }

      if (result.successful.length > 0) {
        const fileData = result.successful[0]
        const fileDataJson = JSON.stringify({
          id: fileData.name,
        })
        const variables = {
          data: {
            fileData: fileDataJson,
            name: fileName,
            notes,
            documentType,
            fileType: fileData.extension,
            originalFilename: fileData.data.name,
            category,
            resourceType,
            resourceId,
            location: fileData.meta.location,
            visibleToCustomer,
          },
        }

        createFile({ variables }).then(({ data, errors }) => {
          const responseData = data?.createFile || {}

          if (responseData?.entity) {
            onFileUpload(data?.createFile?.entity)
            setIsFileUploadDisabled(false)
            setFileToBeUploaded(null)
            setFileName?.('')
          } else {
            handleError(responseData?.errors || errors, newNotification)
          }
        })
      }
    })
  }

  return (
    <Flex column>
      <Flex alignItems="baseline" className="pt-2 pl-1">
        <UppyFileInput uppy={uppy} pretty />
        {fileToBeUploaded && <span className="ml-5">{fileToBeUploaded.data.name}</span>}
      </Flex>
      {withExtensionsList && (
        <Text color="text-gray-500">{`${t('supportedFileTypes')}: ${allowedFileTypes.join(
          ', ',
        )}`}</Text>
      )}
      <Flex alignItems="center" justifyContent="between">
        {!fileLimitError && <Text>{t('sizeLimitWarning')}</Text>}
        {fileLimitError && <Text color="text-darkRed-600">{t('sizeLimitError')}</Text>}
        <Button
          className="mt-2"
          disabled={
            isUploadDisabled ||
            loading ||
            isFileUploadDisabled ||
            fileLimitError ||
            !fileToBeUploaded
          }
          label={submitButtonLabel}
          onClick={handleUpload}
          testData="file-upload"
          type="submit"
        />
      </Flex>
    </Flex>
  )
}

FileInput.propTypes = {
  allowedFileTypes: PT.arrayOf(PT.string),
  documentType: PT.string,
  id: PT.string.isRequired,
  fileName: PT.string,
  notes: PT.string,
  resourceId: PT.oneOfType([PT.number, PT.string]).isRequired,
  resourceType: PT.oneOf(values(FILE_RESOURCES)).isRequired,
  category: PT.oneOf(values(CATEGORIES)).isRequired,
  onFileUpload: PT.func,
  submitButtonLabel: PT.string,
  refetchQueries: PT.arrayOf(PT.object),
  isUploadDisabled: PT.bool,
  visibleToCustomer: PT.bool,
  setFileName: PT.func,
  withExtensionsList: PT.bool,
}

FileInput.defaultProps = {
  allowedFileTypes: [...IMAGE_TYPES, ...DOCS_TYPES],
  documentType: null,
  fileName: null,
  notes: null,
  onFileUpload: null,
  submitButtonLabel: 'Upload',
  refetchQueries: [],
  isUploadDisabled: false,
  withExtensionsList: false,
}

export default FileInput

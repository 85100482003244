import React from 'react'
import { Flex, FormattedDate, Money, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import sizes from '../../../ui-kit/sizes'
import { statusP2PMapping } from '../../payments/promiseToPay/util'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import activityType from '../../../types/activities'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import ActivityCardStatus from './ActivityCardStatus'
import { getCreatedByEvent } from '../utils'

const P2pActivity = ({ p2p }) => {
  const { t } = useTranslation()

  return (
    <Flex justifyContent="between">
      <Flex>
        <ActivityCardDate date={p2p?.entity?.createdAt} />
        <ActivityCardGeneralInfo
          detailsValue={getCreatedByEvent(p2p)}
          entityId={p2p.entity.paymentPromiseId}
          entityLabelKey="promiseToPay"
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="between">
        <Flex>
          <Flex shrink={0}>
            <Flex column>
              <Text className="mt-1" color="text-black-500">
                {t('totalAmount')}:
              </Text>
              <Text className="mt-3" color="text-black-500">
                {t('expectedPaymentDate')}:
              </Text>
            </Flex>
            <Flex className="ml-1" column>
              <Money className="font-semibold text-xl" value={p2p?.entity.amountCents} />
              <Text className="mt-2" size={sizes.SM}>
                <FormattedDate date={p2p?.entity.date} />
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <ActivityCardStatus
          lastUpdatesDate={p2p.lastUpdateDate}
          statusNode={
            <StatusBadge
              color={statusP2PMapping[p2p?.entity.paymentPromiseStatus]?.color}
              value={t(statusP2PMapping[p2p?.entity.paymentPromiseStatus]?.label)}
            />
          }
        />
      </Flex>
    </Flex>
  )
}

export default P2pActivity
P2pActivity.propTypes = {
  p2p: activityType,
}

import React, { useEffect } from 'react'
import PageHeader from '../../../components/headers/PageHeader'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Flex } from '../../../ui-kit'
import InvoicesLargestPastDue from './invoicesLargestPastDue/InvoicesLargestPastDue'
import InvoicesOldestPastDue from './invoicesOldestPastDue/InvoicesOldestPastDue'
import CustomerNotes from './customerNotes/CustomerNotes'
import { DAYS, MONEY } from '../../../utils/dataTypes'
import { ContractStatsQuery } from '../../../queries/contractStats.gql'
import DaysPastDueChart from '../../../components/daysPastDueChart/DaysPastDueChart'
import ShortStats from '../../../components/statsShortChart/ShortStats'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import AccountSummary from './accountSummary/AccountSummary'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { isCounterUser } from '../../settings/teamManagement/membershipRoles'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

const ContractOverview = ({
  contractName,
  contractId,
  areNotesLoading,
  notes,
  overdueLevelGroups,
}) => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  if (isCounterUser(currentUser)) {
    navigate(`/customers/${contractId}/invoices/outstanding`)
  }
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  useEffect(() => {
    setBreadcrumbs([
      { label: t('customers'), link: '/customers' },
      { label: contractName, link: `/customers/${contractId}` },
      { label: t('overview') },
    ])
  }, [])
  const { data, loading: statsIsLoading } = useCustomQuery({
    query: ContractStatsQuery,
    queryOptions: {
      variables: { id: contractId },
    },
    rollbarOptions: { operationName: 'ContractStatsQuery', target: 'ContractOverview' },
  })

  const contractStats = data?.contract
  const statsData = statsIsLoading
    ? null
    : [
        {
          title: 'totalPastDue',
          value: contractStats?.overdueOutstandingAmountCents,
          type: MONEY,
        },
        {
          title: 'daysPastDue',
          value: contractStats?.aging,
          type: DAYS,
        },
      ]

  return (
    <div className={'mb-10'}>
      <Flex>
        <PageHeader className="pt-5 pb-10 w-1/3 pr-9" headerTitle={t('overview')} />
        <Flex className="w-1/3 pr-9">
          <ShortStats
            data={statsData}
            direction="row"
            isLoading={statsIsLoading}
            itemClassName="w-1/2 h-fit"
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex className={'w-1/3 pr-9'} shrink={0} column>
          <AccountSummary data={contractStats} isLoading={statsIsLoading} />
          <InvoicesLargestPastDue className="mt-7" contractId={contractId} />
        </Flex>
        <Flex className={'w-1/3 pr-9 min-w-[440px]'} shrink={0} column>
          <DaysPastDueChart
            className="h-72"
            overdueLevelGroups={overdueLevelGroups}
            redirectUrl={`/customers/${contractId}/invoices/outstanding`}
            valueFieldName={'amountCents'}
          />
          <InvoicesOldestPastDue contractId={contractId} />
        </Flex>
        <Flex className={'w-1/3'} column>
          <CustomerNotes areNotesLoading={areNotesLoading} contractId={contractId} notes={notes} />
        </Flex>
      </Flex>
    </div>
  )
}

export default ContractOverview

ContractOverview.propTypes = {
  notes: PT.arrayOf(
    PT.shape({
      message: PT.string.isRequired,
      createdAt: PT.string.isRequired,
      createdBy: PT.string.isRequired,
      id: PT.string.isRequired,
    }),
  ),
  areNotesLoading: PT.bool,
  contractId: PT.string.isRequired,
  contractName: PT.string.isRequired,
  overdueLevelGroups: PT.arrayOf(
    PT.shape({
      amountCents: PT.number,
      id: PT.string,
      overdueLevel: PT.shape({
        id: PT.string,
        overdueFrom: PT.number,
        overdueTo: PT.number,
        title: PT.string,
      }),
    }),
  ),
}

ContractOverview.defaultProps = {
  notes: undefined,
  areNotesLoading: false,
  overdueLevelGroups: undefined,
}

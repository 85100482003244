import { isCounterUser } from '../../settings/teamManagement/membershipRoles'

const getNavigationMap = (currentUser) => [
  { name: 'Overview', href: 'overview', hidden: isCounterUser(currentUser) },
  { name: 'Invoices', href: 'invoices' },
  { name: 'Projects', href: 'projects' },
  { name: 'Customer Info', href: 'customer_info' },
  { name: 'Payments', href: 'payments' },
  { name: 'Settings', href: 'settings', hidden: isCounterUser(currentUser) },
  { name: 'Activity', href: 'activity', hidden: isCounterUser(currentUser) },
  { name: 'Documents', href: 'documents', hidden: isCounterUser(currentUser) },
]

export default getNavigationMap

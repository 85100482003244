import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text, Dialog } from '../../../ui-kit'
import { Field, Form } from 'react-final-form'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { validateRequiredField } from '../../../utils/validators'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import { range } from 'lodash'
import { makeOptions } from '../../../utils/utils'
import fontWeight from '../../../ui-kit/fontWeight'

const AccountTermsModal = ({ isOpened, initialAccountTerms, handleSubmit, setIsOpened }) => {
  const { t } = useTranslation()

  const closeForm = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const termsOptions = makeOptions(range(0, 91, 5))

  const initPaymentTerms = {
    paymentTerms: initialAccountTerms,
  }

  return (
    <Dialog isOpened={isOpened} setIsOpened={setIsOpened} title={t('accountTerms')}>
      <div className="w-[40rem] px-1">
        <Form
          initialValues={initPaymentTerms}
          onSubmit={handleSubmit}
          render={({ handleSubmit, dirty }) => {
            useBeforeUnload({ when: dirty })
            dirty !== isFormDirty && setDirtyFormState(dirty)

            return (
              <form onSubmit={handleSubmit}>
                <div className="w-full mt-6">
                  <Field name="paymentTerms">
                    {({ input }) => {
                      return (
                        <NDropdown
                          id={input.name}
                          label={t('netTerms')}
                          name={input.name}
                          onChange={input.onChange}
                          options={termsOptions}
                          value={input.value}
                        />
                      )
                    }}
                  </Field>
                </div>
                <div className="w-full mt-3">
                  <Text
                    className="w-full pr-1"
                    color="text-warmBlack-400"
                    fontWeight={fontWeight.LIGHT}>
                    {t('accountTermWarning')}
                  </Text>
                </div>
                <div className="w-full mt-6 flex flex-row justify-end">
                  <Button
                    className="mr-2"
                    label={t('cancel')}
                    onClick={requestClose}
                    testData="close-account-terms"
                    variant={buttonsVariants.TERTIARY}
                  />
                  <Button label={t('save')} testData="submit-account-terms" type="submit" />
                </div>
                <AlertModal confirmClose={closeForm} />
              </form>
            )
          }}
          validate={(values) => ({
            paymentTerms: validateRequiredField(values.paymentTerms),
          })}
        />
      </div>
    </Dialog>
  )
}

AccountTermsModal.propTypes = {
  setIsOpened: PropTypes.func.isRequired,
  isOpened: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialAccountTerms: PropTypes.number,
}

export default AccountTermsModal

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ContractActivity from './ContractActivity'
import { Flex, LoadingSpinner, EmptyResultBody } from '../../../ui-kit'
import { ContractEventsQuery } from '../../../queries/contractEvents.gql'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import PageHeader from '../../../components/headers/PageHeader'
import FiltersControlButton from '../../../components/filters/FiltersControlButton'
import PT from 'prop-types'
import FiltersComponent from '../../../components/filters/FiltersComponent'
import { getPaginationData, parseFilterQuery } from '../../../utils/utils'
import { useQueryParams } from '../../../hooks/useQueryParams'
import InfiniteScroll from 'react-infinite-scroller'
import contractActivitiesFilters from '../../../constants/contractActivitiesFilters'
import Sidebar from '../../../ui-kit/components/sidebar/Sidebar'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { toInteger } from 'lodash'
import { useDataStorage } from '../../../hooks/useDataStorage'

const ContractActivities = ({ contractId, contractName }) => {
  const [availableFilters, setAvailableFilters] = useState(null)
  const { queryParams } = useQueryParams()
  const page = toInteger(queryParams.page) || 1
  const filtersQuery = queryParams.filters || null
  const [, sidebarId] = queryParams.openSidebarParams
    ? queryParams.openSidebarParams.split('.')
    : []
  const userFilters = parseFilterQuery(filtersQuery)
  const queryVariables = useMemo(
    () => ({
      filters: userFilters?.length ? userFilters : contractActivitiesFilters,
      withAvailableFilters: true,
      page,
      contractId,
    }),
    [userFilters, page, contractId],
  )

  const onCompleted = useCallback(
    (response) => {
      response?.events?.availableFilters && setAvailableFilters(response?.events?.availableFilters)
      if (sidebarId) {
        const getActivitySideBarEl = document.getElementById(sidebarId)
        if (getActivitySideBarEl) {
          getActivitySideBarEl.click()
        }
      }
    },
    [sidebarId],
  )
  const { data, loading, refetch, error } = useCustomQuery({
    query: ContractEventsQuery,
    onCompleted,
    queryOptions: {
      variables: queryVariables,
      notifyOnNetworkStatusChange: false,
    },
    rollbarOptions: { operationName: 'ContractEventsQuery', target: 'ContractActivities' },
  })

  const { t } = useTranslation()
  const [isSidebarIsOpened, setIsSidebarIsOpened] = useState(false)
  const { setBreadcrumbs } = useBreadcrumbs()
  useEffect(() => {
    setBreadcrumbs([
      { label: t('customers'), link: '/customers' },
      { label: contractName, link: `/customers/${contractId}` },
      { label: t('activity') },
    ])
  }, [])
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )
  const columns = useMemo(
    () => [
      {
        filterTitle: t('eventType'),
        filterId: 'eventableType',
        filterOptions: [
          {
            key: 'Dispute',
            title: 'Dispute',
          },

          {
            key: 'Note',
            title: 'Note',
          },

          {
            key: 'Payment',
            title: 'Payment',
          },

          {
            key: 'PaymentPlan',
            title: 'Payment Plan',
          },

          {
            key: 'PaymentPromise',
            title: 'Promise to Pay',
          },

          {
            key: 'PaymentRequest',
            title: 'Payment Request',
          },
          {
            key: 'Deposit',
            title: 'Deposit',
          },
          { key: 'InvoiceRefund', title: 'Refund' },
          { key: 'CreditReview', title: 'Credit Review' },
        ],
      },
      {
        filterTitle: t('date'),
        filterId: 'createdAt',
      },
      {
        filterTitle: t('activityId'),
        filterId: additionalFilters.includes('id') && 'id',
      },
    ],
    [queryParams?.additionalFilters],
  )
  const [sidebarContent, setSidebarContent] = useState(null)
  const reloadData = useCallback(() => refetch({ ...queryVariables }), [refetch, queryVariables])
  const renderEmptyResultItem = useCallback(() => {
    return <EmptyResultBody error={error} onReloadClick={reloadData} />
  }, [error, reloadData])
  const paginationData = useMemo(() => getPaginationData(data?.events), [data?.events])

  const hasMore = useMemo(() => paginationData.to < paginationData.totalCount, [paginationData])
  const loadMore = useCallback(() => {
    if (loading || !hasMore) {
      return
    }

    refetch({
      ...queryVariables,
      page: Math.ceil(paginationData.to / 10 + 1),
    })
  }, [refetch, queryVariables, loading, hasMore, paginationData])
  const ref = useRef()
  const { items } = useDataStorage({
    data,
    entityName: 'events',
  })

  const renderItems = useMemo(
    () =>
      items.map((activity, index) => (
        <ContractActivity
          activity={activity}
          className={index !== 0 ? 'mt-6' : ''}
          contractId={contractId}
          key={activity.id}
          refetch={refetch}
          setSidebarContent={setSidebarContent}
          setSidebarIsOpened={setIsSidebarIsOpened}
        />
      )),
    [items],
  )

  return (
    <>
      <div className="relative">
        <PageHeader headerTitle={t('activity')} />
        <FiltersControlButton className="absolute right-0 top-0" />
      </div>

      <Sidebar isSidebarOpened={isSidebarIsOpened} setIsSidebarOpened={setIsSidebarIsOpened}>
        {sidebarContent}
      </Sidebar>
      <FiltersComponent availableFilters={availableFilters} columns={columns} />
      <Flex className={'w-full'} column>
        <InfiniteScroll
          getScrollParent={() => ref?.current?.scrollComponent}
          hasMore={hasMore}
          initialLoad={false}
          loadMore={loadMore}
          loader={<LoadingSpinner loading />}
          pageStart={0}
          ref={ref}
          useWindow>
          {!items.length && !loading && renderEmptyResultItem?.()}
          {!loading && renderItems}
        </InfiniteScroll>
      </Flex>
    </>
  )
}

export default ContractActivities

ContractActivities.propTypes = {
  contractId: PT.string.isRequired,
  contractName: PT.string.isRequired,
}

import React, { useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { Button, Dialog, Flex } from '../../ui-kit'
import { validateRequiredField } from '../../utils/validators'
import { validateEqualFields } from '../../utils/validators'
import PasswordFields from '../../components/security/PasswordFields'
import { useNotifications } from '../../hooks/useNotifications'
import { updateVendorUserPasswordMutation } from '../../queries/mutations/usersRequest.gql'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useCustomMutation } from '../../hooks/useCustomMutation'

const ChangePasswordModal = ({ isOpened, setIsOpened }) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const { newNotification } = useNotifications()
  const [updatePassword, { loading }] = useCustomMutation({
    mutation: updateVendorUserPasswordMutation,
    rollbarOptions: {
      operationName: 'updateVendorUserPasswordMutation',
      target: 'ChangePasswordModal',
    },
  })
  const onSubmit = (values) => {
    const variables = {
      id: currentUser.id,
      data: { ...values },
    }
    updatePassword({ variables }).then(({ data }) => {
      const responseData = data?.updateVendorUserPassword || {}

      if (responseData?.entity) {
        newNotification({ success: t('passwordSuccessfullyUpdated') })
        setIsOpened(false)
      }
    })
  }

  const initialValues = {
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  }
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false)
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false)

  const renderForm = () => (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form className={'flex flex-col flex-1 px-4 text-left'} onSubmit={handleSubmit}>
            <Flex className="flex-1" column>
              <PasswordFields
                isCurrentPasswordVisible={isCurrentPasswordVisible}
                isPasswordConfirmationVisible={isPasswordConfirmationVisible}
                isPasswordVisible={isPasswordVisible}
                setIsCurrentPasswordVisible={setIsCurrentPasswordVisible}
                setIsPasswordConfirmationVisible={setIsPasswordConfirmationVisible}
                setIsPasswordVisible={setIsPasswordVisible}
                isShowCurrentPasswordField
              />
            </Flex>
            <Flex className="py-4">
              <Button
                disabled={loading}
                label={t('createPassword')}
                testData="create-password"
                type="submit"
                fullWidth
              />
            </Flex>
          </form>
        )
      }}
      validate={(values) => ({
        currentPassword: validateRequiredField(values.currentPassword),
        password: validateRequiredField(values.password),
        passwordConfirmation:
          validateRequiredField(values.passwordConfirmation) ||
          validateEqualFields(
            values.password,
            values.passwordConfirmation,
            t('passwordMatchErrorMessage'),
          ),
      })}
    />
  )

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      title={t('changePassword')}
      titleClass="text-2xl font-semibold">
      {renderForm()}
    </Dialog>
  )
}

ChangePasswordModal.propTypes = {
  isOpened: PT.bool.isRequired,
  setIsOpened: PT.func.isRequired,
}

export default ChangePasswordModal

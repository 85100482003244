import { PAYMENT_PLAN_STATUSES } from '../../../constants/activities'
import { paymentTransactionStatuses } from '../paymentsTab/util'
import colors from '../../../ui-kit/colors'
import cx from 'classnames'
import Icon from '../../../ui-kit/components/icons/Icon'
import React from 'react'

export const getInstallmentActivity = (installments, t) =>
  installments.map((installment, index) => ({
    id: installment.id,
    complete: installment.payment?.paymentTransaction?.status === paymentTransactionStatuses.PAID,
    label: `${t('installment')} ${index + 1}:`,
    amount: installment.amountCents,
    date: installment.date,
    paymentTransaction: installment.payment?.paymentTransaction,
  }))

export const statusPaymentPlanMapping = (status, approvedAt) => {
  switch (status) {
    case PAYMENT_PLAN_STATUSES.INITIAL:
      if (approvedAt) {
        return {
          label: 'active',
          color: 'green',
        }
      } else {
        return {
          label: 'sent',
          color: 'bordered',
        }
      }
    case PAYMENT_PLAN_STATUSES.PARTIALLY_PAID:
      return {
        label: 'active',
        color: 'green',
      }
    case PAYMENT_PLAN_STATUSES.PAID:
      return {
        label: 'completed',
        color: 'green',
      }
    case PAYMENT_PLAN_STATUSES.CANCELLED:
      return {
        label: 'cancelled',
        color: 'black',
      }
    case PAYMENT_PLAN_STATUSES.FAILED:
      return {
        label: 'failed',
        color: 'red',
      }
  }
}

export const getInstallmentIconData = (status) => {
  switch (status) {
    case paymentTransactionStatuses.PAID:
    case paymentTransactionStatuses.PROCESSING:
    case paymentTransactionStatuses.SUBMITTED:
    case paymentTransactionStatuses.ENQUEUED:
      return {
        iconName: 'check',
        iconColor: colors.WHITE,
        bgColorName: 'bg-green-600',
        borderColorName: 'bg-green-600',
      }
    case paymentTransactionStatuses.FAILED:
    case paymentTransactionStatuses.CHARGEBACK:
      return {
        iconName: 'x',
        bgColorName: 'bg-darkRed-700',
        iconColor: 'text-white',
        borderColorName: 'border-darkRed-700',
      }
    case paymentTransactionStatuses.VOIDED:
      return {
        iconName: 'x',
        bgColorName: 'bg-white',
        iconColor: 'text-black-500',
        borderColorName: 'border-black-500',
      }

    default:
      return {
        iconName: 'check',
        iconColor: colors.WHITE,
        bgColorName: colors.WHITE,
        borderColorName: 'border-gray-300',
      }
  }
}

export const getIconView = (item, isHorizontal) => {
  const installmentIconData = getInstallmentIconData(item?.paymentTransaction?.status)
  return (
    <div
      className={cx(
        'flex justify-center items-center rounded-full overflow-hidden h-8 w-8 border',
        installmentIconData.bgColorName,
        installmentIconData.borderColorName,
        isHorizontal ? 'w-8' : 'w-9',
      )}>
      <Icon color={installmentIconData.iconColor} name={installmentIconData.iconName} />
    </div>
  )
}

import React, { useEffect } from 'react'
import { Flex, Text } from '../../ui-kit'
import fontWeight from '../../ui-kit/fontWeight'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import AsideContainer from '../../components/containers/AsideContainer'
import EmailNotificationsContent from './EmailNotificationsContent'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { UserEmailNotifications } from '../../queries/users.gql'

const EmailNotifications = () => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    setBreadcrumbs([
      { label: t('profile'), link: '/myprofile' },
      { label: t('emailNotifications') },
    ])
  }, [])
  const currentUser = useCurrentUser()

  const { data, loading } = useCustomQuery({
    query: UserEmailNotifications,
    queryOptions: {
      variables: { id: currentUser?.id },
      skip: !currentUser?.id,
    },
    rollbarOptions: {
      operationName: 'UserEmailNotifications',
      target: 'UserEmailNotifications',
    },
  })

  const initialValues = data?.vendorUser?.vendorUserNotificationSettings

  const requestInProgress = false
  // const [isSub, setIsSub] = useState(false)
  // const handleChange = (state) => setIsSub(state)

  return (
    <AsideContainer
      title={t('emailNotifications')}
      titleChild={
        <Flex column>
          <Text color="text-warmBlack-400">{t('emailNotificationsDescription')}</Text>
          <Text className="pt-2" color="text-warmBlack-400" fontWeight={fontWeight.MEDIUM}>
            {t('allCustomers')}
          </Text>
          <Text color="text-warmBlack-400">{t('emailNotificationsDescriptionAllCustomers')}</Text>
          <Text className="pt-2" color="text-warmBlack-400" fontWeight={fontWeight.MEDIUM}>
            {t('yourCustomers')}
          </Text>
          <Text color="text-warmBlack-400">{t('emailNotificationsDescriptionYourCustomers')}</Text>
          <Text className="pt-2" color="text-warmBlack-400" fontWeight={fontWeight.MEDIUM}>
            {t('createdByMe')}
          </Text>
          <Text color="text-warmBlack-400">{t('createdByMeDescription')}</Text>
          <Text className="pt-2" color="text-warmBlack-400" fontWeight={fontWeight.MEDIUM}>
            {t('noCustomers')}
          </Text>
          <Text color="text-warmBlack-400">{t('emailNotificationsDescriptionNoCustomers')}</Text>
        </Flex>
      }>
      <EmailNotificationsContent
        initialValues={initialValues}
        loading={loading}
        requestInProgress={requestInProgress}
        hideDropdowns
      />
    </AsideContainer>
  )
}

export default EmailNotifications

import React, { useCallback, useState } from 'react'
import PT from 'prop-types'
import {
  Heading,
  Flex,
  Text,
  Dialog,
  ConfirmationDialog,
  FormattedDate,
  Tooltip,
} from '../../../../ui-kit'
import sizes from '../../../../ui-kit/sizes'
import DataGridComponent from '../../../../components/dataGrid/DataGridComponent'
import { useTranslation } from 'react-i18next'
import Button from '../../../../ui-kit/components/buttons/Button'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { useDirtyFormAlert } from '../../../../hooks/useDirtyFormAlert'
import { COMPANY_ROLES_LIST } from '../../../../constants/companyRolesList'
import UserActions from '../../../settings/teamManagement/UserActions'
import {
  ArchiveBuyerUserMutation,
  InviteBuyerUserMutation,
} from '../../../../queries/mutations/usersRequest.gql'
import { useNotifications } from '../../../../hooks/useNotifications'
import { BuyerQuery } from '../../../../queries/buyers.gql'
import EditBuyerUserForm from './buyerUserCreationForm/EditBuyerUserForm'
import InviteBuyerUserForm from './buyerUserCreationForm/InviteBuyerUserForm'
import colors from '../../../../ui-kit/colors'
import fontWeight from '../../../../ui-kit/fontWeight'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

export const getCompanyRole = (companyRole) =>
  COMPANY_ROLES_LIST.find((item) => item.value === companyRole)?.label

const CompanyContacts = ({ buyerData, buyerLoading, refetch, contractId, isInactiveContract }) => {
  const { t } = useTranslation()
  const [editableUser, setEditableUser] = useState(null)
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState(false)
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false)

  if (buyerLoading) return null

  const buyer = buyerData?.buyer

  const rows = buyer?.users

  const handleEditUser = (user) => {
    setEditableUser(user)
    setIsEditUserModalOpen(true)
  }

  const [archiveMutation, { loading: isArchiveMutationLoading }] = useCustomMutation({
    mutation: ArchiveBuyerUserMutation,
    rollbarOptions: { operationName: 'ArchiveBuyerUserMutation', target: 'CompanyContacts' },
    mutationOptions: {
      refetchQueries: [BuyerQuery],
    },
  })
  const { newNotification } = useNotifications()

  const handleArchive = () => {
    const variables = { id: editableUser.id }
    archiveMutation({ variables }).then(({ data }) => {
      const responseData = data?.archiveBuyerUser || {}

      if (responseData?.entity) {
        newNotification({ success: t('userWasDeletedSuccessfully') })
      }

      setIsOpenedDeleteModal(false)
    })
  }
  const handleDeleteUser = (user) => {
    setEditableUser(user)
    setIsOpenedDeleteModal(true)
  }

  const [inviteMutation, { loading: isInviteMutationLoading }] = useCustomMutation({
    mutation: InviteBuyerUserMutation,
    rollbarOptions: { operationName: 'InviteBuyerUserMutation', target: 'CompanyContacts' },
    mutationOptions: {
      refetchQueries: [BuyerQuery],
    },
  })

  const handleSendInvitation = async (user) => {
    if (isInviteMutationLoading) {
      return
    }

    const variables = { id: user.id }
    inviteMutation({ variables }).then(({ data }) => {
      const responseData = data?.sendBuyerUserInvitation || {}

      if (responseData?.entity) {
        newNotification({ success: t('userInvited') })
      }
    })
  }

  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 2,
      renderCell: (values) => <Text className="text-gray-500">{values?.row?.fullName}</Text>,
    },
    {
      field: 'role',
      headerName: t('role'),
      flex: 1,
      renderCell: (values) => getCompanyRole(values?.row?.companyRole),
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1.5,
      renderCell: (values) => <Tooltip content={values?.row?.email}>{values?.row?.email}</Tooltip>,
    },
    {
      field: 'formattedPhoneNumber',
      headerName: t('mobilePhone'),
      flex: 1,
    },
    {
      field: 'formattedOfficePhoneNumber',
      headerName: t('officePhone'),
      flex: 1,
    },
    {
      field: 'extension',
      headerName: t('extension'),
      flex: 1,
    },
    {
      field: 'lastLoggedAt',
      headerName: t('lastLogin'),
      flex: 1.5,
      renderCell: (values) => (
        <FormattedDate date={values?.row?.lastLoggedAt} format="MM/dd/yyyy h:mm a" />
      ),
    },
    {
      field: 'actions',
      sortable: false,
      headerName: '',
      renderCell: (values) => {
        return (
          <UserActions
            isInactiveContract={isInactiveContract}
            onDelete={() => handleDeleteUser(values?.row)}
            onEdit={() => handleEditUser(values?.row)}
            refetchQueries={[BuyerQuery]}
            sendInvitation={() => handleSendInvitation(values?.row)}
            user={values?.row || {}}
            isCompanyContactsActions
          />
        )
      },
      width: 150,
    },
  ]

  const closeForm = useCallback(() => {
    if (isEditUserModalOpen) {
      setIsEditUserModalOpen(false)
    } else if (isInviteUserModalOpen) {
      setIsInviteUserModalOpen(false)
    }
  }, [isEditUserModalOpen, setIsEditUserModalOpen, isInviteUserModalOpen, setIsInviteUserModalOpen])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const renderModalContent = () => {
    if (isEditUserModalOpen) {
      return (
        <EditBuyerUserForm
          closeForm={closeForm}
          contractId={contractId}
          isFormDirty={isFormDirty}
          isInactiveContract={isInactiveContract}
          refetch={refetch}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsEditUserModalOpen}
          user={editableUser}
        />
      )
    }
    if (isInviteUserModalOpen) {
      return (
        <InviteBuyerUserForm
          buyerId={buyer.id}
          closeForm={closeForm}
          contractId={contractId}
          isFormDirty={isFormDirty}
          isInactiveContract={isInactiveContract}
          refetch={refetch}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsInviteUserModalOpen}
        />
      )
    }
  }

  return (
    <>
      {(isEditUserModalOpen || isInviteUserModalOpen) && (
        <Dialog
          isOpened={isEditUserModalOpen || isInviteUserModalOpen}
          setIsOpened={requestClose}
          title={isEditUserModalOpen ? t('editUser') : t('addContact')}>
          {renderModalContent()}
        </Dialog>
      )}
      <Flex className="mt-8" column>
        <Flex justifyContent="between">
          <Heading isBold={false} size={sizes.XL2}>
            {t('companyContacts')}
          </Heading>
          <Button
            iconName="plusCircle"
            iconType="outline"
            label={t('addContact')}
            onClick={() => {
              setIsInviteUserModalOpen(true)
            }}
            testData="add-contract"
            variant={buttonsVariants.SECONDARY}
          />
        </Flex>
        <div className="mt-4">
          <DataGridComponent columns={columns} loading={buyerLoading} rows={rows} localPagination />
        </div>
        <ConfirmationDialog
          confirmationMessage={
            <Text size={sizes.SM}>
              {t('removeUserDescription')}
              <Text color={colors.BLACK} fontWeight={fontWeight.BOLD} size={sizes.SM}>
                {' '}
                {editableUser?.fullName}
              </Text>
              ?
            </Text>
          }
          isOpened={isOpenedDeleteModal}
          isSubmitButtonYesDisabled={isArchiveMutationLoading}
          onSubmitButtonNoClick={() => setIsOpenedDeleteModal(false)}
          onSubmitButtonYesClick={handleArchive}
          setIsOpened={setIsOpenedDeleteModal}
          title={t('removeUser')}
        />
      </Flex>
    </>
  )
}

CompanyContacts.propTypes = {
  buyerData: PT.shape({
    buyer: PT.shape({
      id: PT.string,
      name: PT.string,
      supportPhoneNumber: PT.string,
      supportEmail: PT.string,
    }),
  }).isRequired,
  buyerLoading: PT.bool.isRequired,
  refetch: PT.func.isRequired,
  contractId: PT.string,
  isInactiveContract: PT.bool,
}

export default CompanyContacts

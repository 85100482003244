import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import classes from './Tabs.module.scss'

const Tabs = ({ tabs, vertical }) => {
  const tabsClasses = cx(classes.tabs, vertical && classes.tabsVertical)

  const tabClasses = (tab) =>
    cx(classes.tab, {
      [classes.tabActive]: tab.active,
    })

  const renderTab = (tab) => {
    const Element = tab.onClick ? 'div' : Link

    return (
      <Element
        id={tab.id}
        key={tab.label}
        {...(tab.link && { to: tab.link })}
        {...(tab.onClick && { onClick: tab.onClick })}
        className={tabClasses(tab)}
        testData={tab.testData}>
        {tab.label} {tab.counter || tab.counter === 0 ? `(${tab.counter})` : ''}
      </Element>
    )
  }

  return (
    <nav aria-label="Tabs" className={tabsClasses}>
      {tabs?.map(renderTab)}
    </nav>
  )
}

Tabs.propTypes = {
  tabs: PT.arrayOf(
    PT.shape({
      id: PT.string,
      active: PT.bool,
      label: PT.string.isRequired,
      link: PT.string,
      onClick: PT.func,
      testData: PT.string,
    }),
  ).isRequired,
  vertical: PT.bool,
}

Tabs.defaultProps = {
  vertical: false,
}

export default Tabs

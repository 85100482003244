import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { getInvoiceBreadcrumbs, projectsStatusMapping } from './projectsUtils'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import { useTranslation } from 'react-i18next'
import { ProjectsQuery } from '../../queries/projects.gql'
import { getPaginationData, parseFilterQuery } from '../../utils/utils'
import { EventBox, Money, Text, Icon, LoadingSpinner, Flex, Pagination, Dialog } from '../../ui-kit'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import StatusBadge from '../../ui-kit/components/badges/StatusBadge'
import colors from '../../ui-kit/colors'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import { useQueryParams } from '../../hooks/useQueryParams'
import { toInteger } from 'lodash'
import ProjectsContent from './ProjectsContent'
import AddProjectPartyModal from './projectParty/AddProjectPartyModal'
import UpdateProjectPartyModal from './projectParty/UpdateProjectPartyModal'
import ProjectDocumentUploadModal from './documents/ProjectDocumentUploadModal'
import ViewProjectPartyModal from './projectParty/ViewProjectPartyModal'
import UpdateProjectModal from './projectParty/UpdateProjectModal'
import { useOpenEntitySidebar } from '../../hooks/useOpenEntitySidebar'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import { useCustomQuery } from '../../hooks/useCustomQuery'

const ProjectsView = ({ contractId, scope, contractData, sort }) => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { queryParams, setQueryParams } = useQueryParams()
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)
  const [selectedProject, setSelectedProject] = useState({})
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [isEditProjectModalOpened, setIsEditProjectModalOpened] = useState(false)
  const [isEditProjectPartyModalOpened, setIsEditProjectPartyModalOpened] = useState(false)
  const [viewProjectPartyId, setViewProjectPartyId] = useState(false)
  const [editableProjectPartyId, setEditableProjectPartyId] = useState(false)
  const [isAddProjectPartyModalOpened, setIsAddProjectPartyModalOpened] = useState(false)
  const [isAddProjectDocumentModalOpened, setIsAddProjectDocumentModalOpened] = useState(false)

  useEffect(() => {
    setBreadcrumbs([...getInvoiceBreadcrumbs(contractData, t, scope)])
  }, [scope, contractData])

  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search

  const queryVariables = useMemo(
    () => ({
      contractId,
      filters: [{ key: 'status', values: [scope] }, ...userFilters],
      page,
      search,
      sort,
    }),
    [scope, contractId, page, search, sort, userFilters],
  )

  const { data, loading } = useCustomQuery({
    query: ProjectsQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !scope,
    },
    rollbarOptions: { operationName: 'ProjectsQuery', target: 'ProjectsView' },
  })

  const projects = data?.projects
  const rows = projects?.data || []
  const paginationData = getPaginationData(projects)

  const handleItemClick = useCallback(
    (project) => {
      setSelectedProject(project)
      setIsSidebarOpened(true)
    },
    [setSelectedProject, setIsSidebarOpened],
  )

  const renderSubtitle = (project) => (
    <Flex>
      <Flex className="pr-6">
        <Icon className="mr-1.5" color={colors.GREY} name="users" size="base" type="solid" />
        <span className="text-sm text-gray-500 font-medium pr-1.5">{`${t('role')}:`}</span>
        <span className="text-sm font-medium">{t(project.role)}</span>
      </Flex>
      <Flex className="pr-6">
        <span className="text-sm text-gray-500 font-medium pr-1.5">{`${t('outstanding')}:`}</span>
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
          <Money value={project.outstandingAmountCents} />
        </Text>
      </Flex>
      <Flex>
        <span className="text-sm text-gray-500 font-medium pr-1.5">{`${t(
          'estimatedMaterials',
        )}:`}</span>
        <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
          <Money value={project.estimatedCostCents} />
        </Text>
      </Flex>
    </Flex>
  )

  const handlePageChange = useCallback(
    (newPage) => {
      setQueryParams({ page: newPage })
    },
    [setQueryParams],
  )

  useOpenEntitySidebar(rows, setSelectedProject, setIsSidebarOpened)

  if (loading) return <LoadingSpinner />

  return (
    <div className="pt-4">
      <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
        <ProjectsContent
          contractId={contractId}
          data={selectedProject}
          setEditableProjectPartyId={setEditableProjectPartyId}
          setIsAddProjectDocumentModalOpened={setIsAddProjectDocumentModalOpened}
          setIsAddProjectPartyModalOpened={setIsAddProjectPartyModalOpened}
          setIsEditProjectModalOpened={setIsEditProjectModalOpened}
          setIsEditProjectPartyModalOpened={setIsEditProjectPartyModalOpened}
          setIsSidebarOpened={setIsSidebarOpened}
          setViewProjectPartyId={setViewProjectPartyId}
        />
      </Sidebar>

      {rows.map((project) => {
        return (
          <EventBox
            className="mb-4"
            customBasis={[0, 70, 30]}
            isSelected={project.id === selectedProject.id}
            key={project.id}
            onClick={() => handleItemClick(project)}
            subtitle={renderSubtitle(project)}
            title={project.name}>
            <Flex column>
              <Flex className="mb-3" justifyContent="end">
                <StatusBadge
                  color={projectsStatusMapping[project.status]?.color}
                  value={t(projectsStatusMapping[project.status]?.label)}
                />
              </Flex>
              <Flex>
                <Icon
                  className="mr-1.5"
                  color={colors.GREY}
                  name="calendar"
                  size="base"
                  type="solid"
                />
                <Text color={colors.GREY} fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                  {`${t('latestInvoiceDate')}: `}
                  <FormattedDate date={project.lastInvoiceDate} />
                </Text>
              </Flex>
            </Flex>
          </EventBox>
        )
      })}

      <Pagination
        className="mt-8"
        currentPage={page}
        fromResult={paginationData.from}
        loading={loading}
        onPageChange={handlePageChange}
        toResult={paginationData.to}
        totalPages={paginationData.totalPages}
        totalResults={paginationData.totalCount}
        withResults
      />

      <UpdateProjectModal
        contractId={contractId}
        data={selectedProject}
        isOpened={isEditProjectModalOpened}
        setIsOpenedModal={setIsEditProjectModalOpened}
        setIsSidebarOpened={setIsSidebarOpened}
        setSelectedProject={setSelectedProject}
      />

      <AddProjectPartyModal
        isOpened={isAddProjectPartyModalOpened}
        projectId={selectedProject.id}
        setIsOpened={setIsAddProjectPartyModalOpened}
        setIsOpenedModal={setIsAddProjectPartyModalOpened}
        setIsSidebarOpened={setIsSidebarOpened}
      />

      <Dialog
        isOpened={!!viewProjectPartyId}
        setIsOpened={(state) => {
          setIsSidebarOpened(!state)
          setViewProjectPartyId(state)
        }}
        title={t('projectParty')}
        titleClass="text-2xl font-semibold">
        <ViewProjectPartyModal
          projectPartyId={viewProjectPartyId}
          setIsOpenedModal={setViewProjectPartyId}
          setIsSidebarOpened={setIsSidebarOpened}
        />
      </Dialog>

      <UpdateProjectPartyModal
        isOpened={isEditProjectPartyModalOpened}
        projectId={selectedProject.id}
        projectPartyId={editableProjectPartyId}
        setIsOpened={setIsEditProjectPartyModalOpened}
        setIsSidebarOpened={setIsSidebarOpened}
      />

      <ProjectDocumentUploadModal
        modalOpened={isAddProjectDocumentModalOpened}
        onModalClose={() => {
          setIsSidebarOpened(true)
          setIsAddProjectDocumentModalOpened(false)
        }}
        projectId={selectedProject?.id}
      />
    </div>
  )
}

ProjectsView.propTypes = {
  sort: PT.string,
  scope: PT.string,
  contractId: PT.string,
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    overdueLevelGroups: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        id: PT.string,
        overdueLevel: PT.shape({
          id: PT.string,
          title: PT.string,
          overdueFrom: PT.string,
          overdueTo: PT.string,
        }),
      }),
    ),
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
}
ProjectsView.defaultProps = {
  sort: '',
  scope: '',
  contractId: '',
  contractData: {},
}

export default ProjectsView

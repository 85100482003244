import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { Button, Flex, InfoNote, Money, StatusBadge, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import PT from 'prop-types'
import { Form } from 'react-final-form'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import { ArchiveDispute } from '../../../queries/mutations/disputes.gql'
import { ContractEventsQuery } from '../../../queries/contractEvents.gql'
import { handleError, statusDisputeMapping } from '../../../utils/utils'
import { useNotifications } from '../../../hooks/useNotifications'
import { getCreatedByEvent, getDisplayStatusValue } from '../utils'
import activityType from '../../../types/activities'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { UpdateDisputeMemo } from '../../../queries/mutations/updateDisputeMemo.gql'
import colors from '../../../ui-kit/colors'
import { useLazyQuery } from '@apollo/react-hooks'
import { InvoicePdfQuery } from '../../../queries/invoices/invoicePdf.gql'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const DisputeContent = ({ data, setSidebarIsOpened }) => {
  const { t } = useTranslation()

  const descriptionData = [
    {
      label: t('status'),
      value: (
        <StatusBadge
          color={statusDisputeMapping[data.entityActualEvent?.eventAction]?.color}
          value={t(statusDisputeMapping[data.entityActualEvent?.eventAction]?.label)}
        />
      ),
    },
    { label: t('createdOn'), value: <FormattedDate date={data.createdAt} /> },
    { label: t('createdBy'), value: getCreatedByEvent(data) },
    { label: t('invoiceNumber'), value: data.invoice.invoiceNumber },
    {
      label: t('invoiceStatus'),
      value: data?.invoice?.outstandingAmountCents ? (
        <StatusBadge iconName="dot" value={getDisplayStatusValue(data.invoice.overdueLevel)} />
      ) : (
        <StatusBadge color={colors.GREEN} label={t('paid')} />
      ),
    },
    { label: t('reason'), value: data.reason },
    { label: t('invoiceDate'), value: <FormattedDate date={data.invoice?.issueDate} /> },
    { label: t('dueDate'), value: <FormattedDate date={data.invoice?.maturityDate} /> },
  ]

  const initialValues = {
    memo: data.memo,
  }
  const { newNotification } = useNotifications()

  const onCompleted = useCallback(() => {
    setSidebarIsOpened(false)
  }, [])
  const [archiveDispute, { loading: isArchiveDisputeLoading }] = useCustomMutation({
    mutation: ArchiveDispute,
    onCompleted,
    rollbarOptions: { operationName: 'ArchiveDispute', target: 'DisputeContent' },
    mutationOptions: {
      refetchQueries: [ContractEventsQuery],
    },
  })
  const onSubmit = () => {
    const variables = {
      id: data.disputeId,
    }

    archiveDispute({ variables }).then(({ data }) => {
      const responseData = data?.archiveDispute || {}

      if (responseData?.entity) {
        newNotification({ success: t('disputeResolvedSuccessfully') })
      }

      setSidebarIsOpened(false)
    })
  }

  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const [updateDispute, { loading: isUpdateDisputeLoading }] = useCustomMutation({
    mutation: UpdateDisputeMemo,
    rollbarOptions: { operationName: 'UpdateDisputeMemo', target: 'DisputeContent' },
    mutationOptions: {
      refetchQueries: [ContractEventsQuery],
    },
  })

  const [invoiceUrl, setInvoiceUrl] = useState('')
  const [fetchPdf, { loading: loadingPdfUrl }] = useLazyQuery(InvoicePdfQuery, {
    skip: true,
    onCompleted: (data, errors) => {
      if (data) {
        return setInvoiceUrl(data?.invoicePdf?.url)
      }
      handleError(data?.invoicePdf?.errors || errors, newNotification)
    },
  })
  useEffect(() => {
    fetchPdf({ variables: { invoiceId: data.invoice.hashid } })
  }, [])

  return (
    <>
      <div className="flex flex-col mt-[-1.5rem]">
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
              {t('dispute')}
            </Text>
          </div>

          <div className="flex flex-row">
            <div className="mr-2">
              <InfoNote label={t('id')}>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                  {data?.disputeId}
                </Text>
              </InfoNote>
            </div>
          </div>
        </div>

        <div className="flex flex-row mt-3">
          <div className="w-6/12 flex flex-col">
            <SidebarLabel text="totalAmount" />
            <Money className="text-2xl mt-1" value={data.invoice.amountCents} />
          </div>
        </div>

        <DividedList content={descriptionData} />
        <div className="w-full mt-6">
          <Textarea
            id="disputeDetails"
            inputClassName="h-44 w-full"
            label={t('disputeDetails')}
            name="disputeDetails"
            value={data.details}
            disabled
          />
        </div>
        <MemoField
          closeForm={() => null}
          entityId={data.id}
          initialValue={data.memo}
          isFormDirty={memoFormIsDirty}
          isLoading={isUpdateDisputeLoading}
          responseEntityName={'updateDispute'}
          setDirtyFormState={setMemoFormIsDirty}
          updateEntity={updateDispute}
        />
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => {
            return (
              <form className={'flex flex-col pr-2'} onSubmit={handleSubmit}>
                <Flex className={'mt-6'} justifyContent={'between'}>
                  <Button
                    className={
                      !(data.entityActualEvent?.eventAction === 'resolve' || !!data.archivedAt) &&
                      'w-1/2 mr-1'
                    }
                    disabled={loadingPdfUrl || !invoiceUrl}
                    fullWidth={
                      data.entityActualEvent?.eventAction === 'resolve' || !!data.archivedAt
                    }
                    label={t('viewInvoice')}
                    onClick={() => window.open(invoiceUrl)}
                    size={sizes.BASE}
                    testData="dispute-view-invoice"
                    variant={buttonsVariants.SECONDARY}
                  />
                  {data.entityActualEvent?.eventAction === 'create' && !data.archivedAt && (
                    <Button
                      className="w-1/2 ml-1"
                      disabled={isArchiveDisputeLoading}
                      label={t('markAsResolved')}
                      size={sizes.BASE}
                      testData="dispute-mark-resolved"
                      type={'submit'}
                      variant={buttonsVariants.TERTIARY}
                    />
                  )}
                </Flex>
              </form>
            )
          }}
        />
      </div>
    </>
  )
}

DisputeContent.propTypes = {
  data: activityType,
  setSidebarIsOpened: PT.func.isRequired,
}

DisputeContent.defaultProps = {
  data: {},
}

export default DisputeContent

import { Flex, Dialog, Text } from '../../index'
import colors from '../../colors'
import fontWeight from '../../fontWeight'
import sizes from '../../sizes'
import Button from '../buttons/Button'
import buttonsVariants from '../../buttonsVariants'
import React from 'react'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'

const AlertModal = ({ confirmClose }) => {
  const { t } = useTranslation()
  const { isAlertModalOpened, closeAlertModal } = useDirtyFormAlert()

  return (
    <Dialog
      isOpened={isAlertModalOpened}
      setIsOpened={closeAlertModal}
      title={t('unsavedChangesDetected')}>
      <Flex className="w-full" column>
        <Text
          className="py-6"
          color={colors.GRAY_700}
          fontWeight={fontWeight.MEDIUM}
          size={sizes.SM}>
          {t('unsavedChangesMessage')}
        </Text>
        <Flex justifyContent="end">
          <Button
            className="mr-4"
            label={t('cancel')}
            onClick={closeAlertModal}
            testData="cancel-alert"
            variant={buttonsVariants.TERTIARY}
          />
          <Button label={t('close')} onClick={confirmClose} testData="close-alert" />
        </Flex>
      </Flex>
    </Dialog>
  )
}

AlertModal.propTypes = {
  confirmClose: PT.func.isRequired,
}

export default AlertModal

import { get } from 'lodash'

export const getLienWaiverRecentEventRowItem = (lienWaiver) => {
  if (!lienWaiver) {
    return
  }

  const { condition, createdAt, paymentState, id, document } = lienWaiver
  const { invoices, id: paymentId, paymentTransaction } = get(lienWaiver, 'payment', {})

  const paymentDisplayStatus = paymentTransaction?.displayStatus

  let contractId = void 0
  const invoicesRows = (invoices || []).map((invoice) => {
    const currentContractId = get(invoice, 'contract.id')
    const payableInvoiceAssignment = get(invoice, 'payableInvoiceAssignments', []).find(
      (assignments) => assignments.payableId === paymentId,
    )

    if (!contractId && currentContractId) {
      contractId = currentContractId
    }

    return {
      ...invoice,
      lienWaiverCreatedAt: createdAt,
      paymentDisplayStatus,
      invoicePaidAmountCents:
        get(payableInvoiceAssignment, 'coveredOutstandingAmountCents', 0) -
        get(payableInvoiceAssignment, 'appliedDiscountAmountCents', 0) +
        get(payableInvoiceAssignment, 'appliedPenaltyAmountCents', 0),
    }
  })

  return {
    id,
    paymentId,
    contractId,
    paymentTransactionId: get(paymentTransaction, 'id'),
    invoicesRows,
    documentUrl: document?.url,
    lienWaiverCondition: condition,
    lienWaiverPaymentState: paymentState,
  }
}

import { useTranslation } from 'react-i18next'
import { Flex, Money, Text } from '../../../ui-kit'
import Icon from '../../../ui-kit/components/icons/Icon'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import PT from 'prop-types'
import React from 'react'

const InvoiceActivity = ({
  title,
  titleAmount,
  titleAmountEl,
  date,
  iconName,
  iconColor,
  bgColorName,
  borderColorName,
  additionalInfoComponent,
  installmentNumber,
}) => {
  const { t } = useTranslation()

  return title ? (
    <div
      className={`flex flex-row ${
        additionalInfoComponent ? 'items-top' : 'items-center'
      } shrink-0 justify-between w-full`}
      testData={title}>
      <Flex alignItems={'top'}>
        <div
          className={`flex justify-center items-center rounded-full overflow-hidden 
          ${bgColorName} h-9 w-9 border ${borderColorName}`}>
          <Icon color={iconColor} name={iconName} type={'outline'} />
        </div>
        <Flex alignItems={'top'} className="w-[15.5rem]" justifyContent={'center'} column>
          <div className="ml-2 w-6/12 whitespace-nowrap font-medium">
            <div>
              {t(title)}
              {titleAmount || titleAmountEl ? ':' : ''}
            </div>
            {titleAmount && (
              <div>
                <Money value={titleAmount} />
              </div>
            )}
            {titleAmountEl}
            {installmentNumber ? ` - ${installmentNumber}` : ''}
          </div>
          {additionalInfoComponent ? (
            <Text className="ml-2 w-full whitespace-nowrap">{additionalInfoComponent()}</Text>
          ) : null}
        </Flex>
      </Flex>
      <div className="w-6/12 text-right h-full">
        <FormattedDate date={date} />
      </div>
    </div>
  ) : null
}

export default InvoiceActivity

InvoiceActivity.propTypes = {
  title: PT.string,
  titleAmount: PT.number,
  titleAmountEl: PT.node,
  date: PT.string.isRequired,
  iconName: PT.string.isRequired,
  iconColor: PT.string.isRequired,
  bgColorName: PT.string.isRequired,
  borderColorName: PT.string.isRequired,
  additionalInfoComponent: PT.node,
  installmentNumber: PT.string,
}
InvoiceActivity.defaultProps = {
  title: undefined,
  additionalInfoComponent: null,
  installmentNumber: undefined,
}

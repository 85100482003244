import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import cx from 'classnames'
import PageHeader from '../../components/headers/PageHeader'
import { Flex, Icon, Text } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import fontWeight from '../../ui-kit/fontWeight'
import ArReports from './arReports/ArReports'
import { useSelector } from 'react-redux'
import { selectAuthToken } from '../../store/selectors/auth'
import PaymentsReports from './paymentsReports/PaymentsReports'
import { useQueryParams } from '../../hooks/useQueryParams'
import OtherReports from './other/OtherReports'

const Reports = () => {
  const { t } = useTranslation()
  const authToken = useSelector(selectAuthToken)
  const { removeQueryParam } = useQueryParams()
  const { setBreadcrumbs } = useBreadcrumbs()
  const createSummary = useCallback(({ label, iconName, isExpanded }) => {
    const iconWrapperClassNames = cx(
      {
        'bg-white': isExpanded,
        'bg-primary-100': !isExpanded,
      },
      'h-14 w-14 rounded-full',
    )
    return (
      <Flex alignItems="center" className="p-5">
        <Flex alignItems="center" className={iconWrapperClassNames} justifyContent="center">
          <Icon name={iconName} size={sizes.LG} type="outline" />
        </Flex>
        <Text className="ml-6" fontWeight={fontWeight.MEDIUM} size={sizes.XL}>
          {label}
        </Text>
      </Flex>
    )
  }, [])
  const createDetails = useCallback(
    ({ reportsMetadata, setOpenedReport }) => {
      return Object.keys(reportsMetadata).map((reportKey) => (
        <Flex
          alignItems="center"
          className="report-row h-14 px-4 cursor-pointer"
          justifyContent="between"
          key={reportKey}
          onClick={() => setOpenedReport(reportKey)}>
          <Text>{t(reportKey)}</Text>
          <Icon name="documentAdd" />
        </Flex>
      ))
    },
    [t],
  )

  useEffect(() => {
    setBreadcrumbs([{ label: t('reports') }])
    removeQueryParam(['filters', 'sort', 'page'])
  }, [])

  return (
    <div className="mb-10">
      <PageHeader headerTitle={t('reports')} />
      <ArReports
        authToken={authToken}
        createDetails={createDetails}
        createSummary={createSummary}
      />
      <PaymentsReports
        authToken={authToken}
        createDetails={createDetails}
        createSummary={createSummary}
      />
      <OtherReports
        authToken={authToken}
        createDetails={createDetails}
        createSummary={createSummary}
      />
    </div>
  )
}

export default Reports

import { applyUserSignIn } from '../../../store/actions/auth'
import { FORM_ERROR } from 'final-form'
import {
  AUTH_BY_CREDENTIALS_LINK,
  AUTH_BY_IMPERSONATE_TOKEN_LINK,
  MERCHANT_INVITATION_FLOW_LINK,
  MODIFY_MERCHANT_PASSWORD_LINK,
} from '../../../constants/links'
import {
  DASHBOARD_ROUTE,
  INVALID_TOKEN_ROUTE,
  SWITCH_WORKSPACE_ROUTE,
} from '../../../constants/routes'
import { restRequest } from '../../../utils/restAPI'
import { handleAuthorizationError } from './handleAuthorizationError'
import { Mixpanel } from '../../../utils/mixpanel'
import { getFromSessionStorage } from '../../../utils/sessionStorage'
import { captureCritical, captureInfo } from '../../../utils/rollbar'

export const authorizeUserByCredentials = async (values, t, dispatch, navigate, rollbar) => {
  if (!t) {
    captureCritical(rollbar, 'Auth error translation is not loading', {
      target: 'authorizeUserByCredentials',
      userEmail: values?.email,
    })

    return { [FORM_ERROR]: 'Unexpected error detected. Please, contact support.' }
  }

  if (!values || !dispatch || !navigate) {
    captureInfo(rollbar, 'Auth error no values', {
      target: 'authorizeUserByCredentials',
      userEmail: values?.email,
    })

    return { [FORM_ERROR]: t('unexpectedError') }
  }

  try {
    const config = {
      url: AUTH_BY_CREDENTIALS_LINK,
      method: 'post',
      data: { vendor_user: { ...values } },
    }
    const { authToken, user } = await restRequest(config)
    dispatch(applyUserSignIn(authToken, user))
    if (user.hashid) {
      Mixpanel.alias(user.hashid, getFromSessionStorage('session_uuid'))
    }
    navigate(SWITCH_WORKSPACE_ROUTE)
  } catch (error) {
    captureInfo(rollbar, error.response?.data?.error || error.message, {
      target: 'authorizeUserByCredentials',
      userEmail: values?.email,
    })

    return handleAuthorizationError(error, t)
  }
}

export const resetMerchantPassword = async (values, t, rollbar) => {
  if (!t) {
    captureCritical(rollbar, 'Auth error translation is not loading', {
      target: 'resetMerchantPassword',
      userEmail: values?.email,
    })

    return { [FORM_ERROR]: 'Unexpected error detected. Please, contact support.' }
  }

  if (!values) {
    captureInfo(rollbar, 'Reset password error no values', {
      target: 'resetMerchantPassword',
    })

    return { [FORM_ERROR]: t('unexpectedError') }
  }

  try {
    const config = {
      url: MODIFY_MERCHANT_PASSWORD_LINK,
      method: 'post',
      data: { vendor_user: { ...values } },
    }
    await restRequest(config)
  } catch (error) {
    captureInfo(rollbar, error.response?.data?.error || error.message, {
      target: 'resetMerchantPassword',
      userEmail: values?.email,
    })

    return handleAuthorizationError(error, t)
  }
}

export const setNewMerchantPassword = async (values, token, t, dispatch, navigate, rollbar) => {
  if (!t) {
    captureCritical(rollbar, 'Auth error translation is not loading', {
      target: 'setNewMerchantPassword',
    })

    return { [FORM_ERROR]: 'Unexpected error detected. Please, contact support.' }
  }

  if (!values || !dispatch || !navigate) {
    captureInfo(rollbar, 'Set new password error no values', {
      target: 'setNewMerchantPassword',
    })

    return { [FORM_ERROR]: t('unexpectedError') }
  }

  if (!token) {
    captureInfo(rollbar, 'Set new password no token', {
      target: 'setNewMerchantPassword',
    })
    navigate(INVALID_TOKEN_ROUTE)

    return
  }

  try {
    const config = {
      url: MODIFY_MERCHANT_PASSWORD_LINK,
      method: 'put',
      data: {
        vendor_user: {
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          reset_password_token: token,
        },
      },
    }
    const { authToken, user } = await restRequest(config)
    dispatch(applyUserSignIn(authToken, user))
    navigate(DASHBOARD_ROUTE)
  } catch (error) {
    captureInfo(rollbar, error.response?.data?.error || error.message, {
      target: 'setNewMerchantPassword',
    })
    navigate(INVALID_TOKEN_ROUTE)
  }
}

export const validatePasswords = (
  password,
  passwordConfirmation,
  setPasswordConfirmationError,
  t,
) => {
  if (!setPasswordConfirmationError || !t) {
    return
  }

  if (password === passwordConfirmation) {
    setPasswordConfirmationError('')
  } else {
    setPasswordConfirmationError(t('passwordMatchErrorMessage'))
  }
}

export const confirmMerchantUserInvitation = async (
  password,
  passwordConfirmation,
  token,
  t,
  newNotification,
  dispatch,
  navigate,
  rollbar,
) => {
  if (!navigate) {
    captureCritical(rollbar, 'Confirm invitation error no navigate', {
      target: 'confirmMerchantUserInvitation',
    })

    return
  }

  if (!password || !passwordConfirmation || !token || !t || !newNotification || !dispatch) {
    captureInfo(rollbar, 'Confirm invitation error no values', {
      target: 'confirmMerchantUserInvitation',
    })

    navigate(INVALID_TOKEN_ROUTE)
    return
  }

  if (password !== passwordConfirmation) {
    newNotification({ error: t('passwordMatchErrorMessage') })
    return
  }

  try {
    const config = {
      url: MERCHANT_INVITATION_FLOW_LINK,
      method: 'patch',
      data: {
        vendor_user: {
          password,
          password_confirmation: passwordConfirmation,
          invitation_token: token,
        },
      },
    }
    const { authToken, user } = await restRequest(config)
    newNotification({ success: t('userUpdatedSuccessfully') })
    dispatch(applyUserSignIn(authToken, user))
    navigate(DASHBOARD_ROUTE)
  } catch (error) {
    captureInfo(rollbar, error.response?.data?.error || error.message, {
      target: 'confirmMerchantUserInvitation',
    })
    navigate(INVALID_TOKEN_ROUTE)
  }
}

export const authorizeUserByImpersonationToken = async (token, dispatch, navigate, rollbar) => {
  if (!navigate) {
    captureCritical(rollbar, 'Impersonate auth error no navigate', {
      target: 'authorizeUserByImpersonationToken',
    })

    return
  }

  if (!token || !dispatch || !navigate) {
    captureInfo(rollbar, 'Impersonate auth error no values', {
      target: 'authorizeUserByImpersonationToken',
    })
    navigate(INVALID_TOKEN_ROUTE)

    return
  }

  try {
    const config = {
      url: AUTH_BY_IMPERSONATE_TOKEN_LINK,
      method: 'post',
      data: {
        impersonation_token: token,
      },
    }
    const { authToken, user } = await restRequest(config)
    dispatch(applyUserSignIn(authToken, user))
    navigate(DASHBOARD_ROUTE)
  } catch (error) {
    captureInfo(rollbar, error.response?.data?.error || error.message, {
      target: 'authorizeUserByImpersonationToken',
    })
    navigate(INVALID_TOKEN_ROUTE)
  }
}

import React from 'react'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { Money, Tooltip } from '../../../ui-kit'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { creditStatusMapping, depositStatusMapping } from '../../invoices/invoicesUtils'
import { daysPastDueStatusMapping } from '../../contracts/utils'
import colors from '../../../ui-kit/colors'

export const STATIC_WIDTH = 115
export const invoicesListDaysPastDueStatusMapping = {
  ...daysPastDueStatusMapping,
  ['Paid']: { label: 'paid', color: 'green' },
}

export const getArAgingDetailsFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'issueDate',
    filterTitle: t('invoiceDate'),
  },
  {
    filterId: 'maturityDate',
    headerName: t('dueDate'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'projectName',
    filterTitle: t('project'),
    loadOptions: loadFilter?.loadProjectOptions,
  },
  {
    filterId: 'locationName',
    filterTitle: t('location'),
  },
  {
    filterId: 'excludeRetention',
    filterTitle: t('excludeRetention'),
  },
]

export const getArAgingSummaryFiltersColumns = (t) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'locationName',
    filterTitle: t('invoiceLocation'),
  },
  {
    filterId: 'excludeRetention',
    filterTitle: t('excludeRetention'),
  },
]

export const getArAgingSummaryColumns = (t, rows) => {
  let overdueLevelsColumns = null

  if (rows[0]?.overdueLevels) {
    overdueLevelsColumns = rows[0].overdueLevels.map((level) => ({
      field: `level_${level.id}`,
      headerName: level.title,
      renderCell: (values) => {
        const targetLevel = values?.row?.overdueLevels?.find(
          (currentLevel) => currentLevel.id === level.id,
        )

        return <Money value={targetLevel.amount} />
      },
      width: STATIC_WIDTH,
      align: 'right',
      headerAlign: 'right',
      totalPropName: level.title,
    }))
  }

  return [
    {
      field: 'customerName',
      headerName: t('customerName'),
      flex: 30,
    },
    {
      field: 'customerId',
      headerName: t('customerId'),
      flex: 10,
    },
    {
      field: 'current',
      headerName: t('current'),
      renderCell: (values) => <Money value={values?.row?.current} />,
      width: STATIC_WIDTH,
      align: 'right',
      headerAlign: 'right',
      totalPropName: 'current',
    },
    ...(overdueLevelsColumns || []),
    {
      field: 'total',
      headerName: t('total'),
      renderCell: (values) => <Money value={values?.row?.total} />,
      width: STATIC_WIDTH,
      align: 'right',
      headerAlign: 'right',
      totalPropName: 'total',
    },
    {
      field: 'credits',
      headerName: t('credits'),
      renderCell: (values) => <Money value={values?.row?.credits} />,
      width: STATIC_WIDTH,
      align: 'right',
      headerAlign: 'right',
      totalPropName: 'credits',
    },
    {
      field: 'deposits',
      headerName: t('deposits'),
      renderCell: (values) => <Money value={values?.row?.deposits} />,
      width: STATIC_WIDTH,
      align: 'right',
      headerAlign: 'right',
      totalPropName: 'deposits',
    },
    {
      field: 'netDue',
      headerName: t('netDue'),
      renderCell: (values) => <Money value={values?.row?.netDue} />,
      width: STATIC_WIDTH,
      align: 'right',
      headerAlign: 'right',
      totalPropName: 'netDue',
    },
    {
      field: 'pendingPayments',
      headerName: t('pendingPmt'),
      renderCell: (values) => <Money value={values?.row?.pendingPayments} />,
      width: STATIC_WIDTH,
      align: 'right',
      headerAlign: 'right',
      totalPropName: 'pendingPayments',
    },
  ]
}

export const getArAgingSummaryTotalItems = (total) => {
  let overdueLevelsItems = null

  if (total?.overdueLevels) {
    overdueLevelsItems = total.overdueLevels.map((level) => ({
      captionKey: level.title,
      value: level?.amount,
    }))
  }

  return [
    { captionKey: 'current', value: total?.current },
    ...(overdueLevelsItems || []),
    { captionKey: 'total', value: total?.grandTotal },
    { captionKey: 'credits', value: total?.credits },
    { captionKey: 'deposits', value: total?.deposits },
    { captionKey: 'netDue', value: total?.netDue },
    { captionKey: 'pendingPayments', value: total?.pendingPayments },
  ]
}

export const getCreditsReportFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'createdAt',
    filterTitle: t('creditMemoDate'),
  },
  {
    filterId: 'locationName',
    filterTitle: t('creditLocation'),
  },
]

export const getCreditsReportColumns = (t) => [
  {
    field: 'creditMemoNumber',
    headerName: t('creditMemoNumber'),
    flex: 1,
  },
  {
    field: 'creditDate',
    headerName: t('creditMemoDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.creditDate} />,
    flex: 1,
  },
  {
    field: 'customer',
    headerName: t('customer'),
    flex: 1,
  },
  {
    field: 'customerId',
    headerName: t('customerId'),
    flex: 1,
  },
  {
    field: 'projectName',
    headerName: t('project'),
    flex: 1.5,
    renderCell: (values) => (
      <span className={'text-ellipsis overflow-hidden'}>{values?.row?.projectName || '-'}</span>
    ),
  },
  {
    field: 'location',
    headerName: t('location'),
    flex: 1,
  },
  {
    field: 'amount',
    headerName: t('amount'),
    renderCell: (values) => <Money value={values?.row?.amount} />,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'outstanding',
    headerName: t('outstanding'),
    renderCell: (values) => <Money value={values?.row?.outstanding} />,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'balanceStatus',
    headerName: t('status'),
    renderCell: (values) => (
      <StatusBadge
        color={creditStatusMapping[values?.row?.balanceStatus?.toLowerCase()]?.color}
        value={t(creditStatusMapping[values?.row?.balanceStatus.toLowerCase()]?.label)}
      />
    ),
    flex: 1,
  },
  {
    field: 'memo',
    headerName: t('memo'),
    flex: 1,
    sortable: false,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
    ),
  },
]

export const getCreditsReportTotalItems = (total) => [
  { captionKey: 'amount', value: total?.amountCents },
  { captionKey: 'outstanding', value: total?.outstandingAmountCents },
]

export const getDepositsReportFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'issueDate',
    filterTitle: t('date'),
  },
  {
    filterId: 'status',
    filterTitle: t('status'),
  },
  {
    filterId: 'locationName',
    filterTitle: t('depositLocation'),
  },
]

export const getDepositsReportColumns = (t) => [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'depositMemoNumber',
    headerName: t('id'),
    flex: 1,
  },
  {
    field: 'depositDate',
    headerName: t('date'),
    renderCell: (values) => <FormattedDate date={values?.row?.depositDate} />,
    flex: 1,
  },
  {
    field: 'customer',
    headerName: t('customer'),
    flex: 1,
  },
  {
    field: 'customerId',
    headerName: t('customerId'),
    flex: 1,
  },
  {
    field: 'projectName',
    headerName: t('project'),
    flex: 1.5,
    renderCell: (values) => (
      <span className={'text-ellipsis overflow-hidden'}>{values?.row?.projectName || '-'}</span>
    ),
  },
  {
    field: 'location',
    headerName: t('location'),
    flex: 0.5,
    renderCell: (values) => (
      <span className={'text-ellipsis overflow-hidden'}>{values?.row?.location}</span>
    ),
  },
  {
    field: 'amount',
    headerName: t('amount'),
    renderCell: (values) => <Money value={values?.row?.amount} />,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'outstanding',
    headerName: t('outstanding'),
    renderCell: (values) => <Money value={values?.row?.outstanding} />,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'balanceStatus',
    headerName: t('status'),
    renderCell: (values) => (
      <StatusBadge
        color={depositStatusMapping[values?.row?.balanceStatus.toLowerCase()]?.color}
        value={t(depositStatusMapping[values?.row?.balanceStatus.toLowerCase()]?.label)}
      />
    ),
    flex: 1,
  },
  {
    field: 'memo',
    headerName: t('memo'),
    flex: 1,
    sortable: false,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
    ),
  },
]

export const getDepositsReportTotalItems = (total) => [
  { captionKey: 'amount', value: total?.amountCents },
  { captionKey: 'outstanding', value: total?.outstandingAmountCents },
]

export const getDisputedInvoicesFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'issueDate',
    filterTitle: t('date'),
  },
  {
    filterId: 'maturityDate',
    headerName: t('dueDate'),
  },
  {
    filterId: 'locationName',
    filterTitle: t('invoiceLocation'),
  },
  {
    filterId: 'excludeRetention',
    filterTitle: t('excludeRetention'),
  },
]

export const getDisputedInvoicesColumns = (t) => [
  {
    field: 'issueDate',
    headerName: t('date'),
    renderCell: (values) => <FormattedDate date={values?.row?.issueDate} />,
    flex: 10,
  },
  {
    field: 'invoiceNumber',
    headerName: t('number'),
    flex: 20,
  },
  {
    field: 'buyerName',
    headerName: t('customer'),
    flex: 21.25,
  },
  {
    field: 'contractId',
    headerName: t('customerId'),
    flex: 10,
  },
  {
    field: 'projectName',
    headerName: t('project'),
    flex: 10,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.projectName}</span>
    ),
  },
  {
    field: 'location',
    headerName: t('location'),
    flex: 10,
  },
  {
    field: 'amountCents',
    headerName: t('invoiced'),
    renderCell: (values) => <Money value={values?.row?.amountCents} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'outstandingAmountCents',
    headerName: t('outstanding'),
    renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'maturityDate',
    headerName: t('dueDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.maturityDate} />,
    flex: 10,
  },
  {
    field: 'overdueLevel',
    headerName: t('status'),
    flex: 10,
    renderCell: (values) =>
      values?.row?.outstandingAmountCents ? (
        <StatusBadge
          color={daysPastDueStatusMapping[values?.row?.overdueLevel].color}
          iconName="dot"
          value={daysPastDueStatusMapping[values?.row?.overdueLevel].label}
        />
      ) : (
        <StatusBadge color={colors.GREEN} label={t('paid')} />
      ),
  },
  {
    field: 'retention',
    headerName: t('ret'),
    flex: 5,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.retention}</span>
    ),
  },
  {
    field: 'disputeDate',
    headerName: t('disputeDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.disputeDate} />,
    flex: 10,
  },
  {
    field: 'memo',
    headerName: t('memo'),
    flex: 10,
    sortable: false,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.memo}</span>
    ),
  },
]

export const getDisputedInvoicesTotalItems = (total) => [
  { captionKey: 'invoiced', value: total?.amountCents },
  { captionKey: 'outstanding', value: total?.outstandingAmountCents },
]

export const getInvoiceListColumns = (t) => [
  {
    field: 'invoiceDate',
    headerName: t('date'),
    renderCell: (values) => <FormattedDate date={values?.row?.invoiceDate} />,
    flex: 10,
  },
  {
    field: 'invoiceNumber',
    headerName: t('number'),
    flex: 15,
  },
  {
    field: 'type',
    headerName: t('type'),
    renderCell: (values) => t(values?.row?.type?.toLowerCase?.()),
    flex: 10,
  },
  {
    field: 'customer',
    headerName: t('customer'),
    flex: 21.25,
  },
  {
    field: 'customerId',
    headerName: t('customerId'),
    flex: 10,
  },
  {
    field: 'project',
    headerName: t('project'),
    flex: 10,
    renderCell: (values) => (
      <Tooltip className="text-ellipsis overflow-hidden" content={values?.row?.project}>
        {values?.row?.project}
      </Tooltip>
    ),
  },
  {
    field: 'location',
    headerName: t('location'),
    flex: 10,
  },
  {
    field: 'amount',
    headerName: t('invoiced'),
    renderCell: (values) => <Money value={values?.row?.amount} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'outstanding',
    headerName: t('outstanding'),
    renderCell: (values) => <Money value={values?.row?.outstanding} />,
    flex: 10,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'dueDate',
    headerName: t('dueDate'),
    renderCell: (values) => <FormattedDate date={values?.row?.dueDate} />,
    flex: 10,
  },
  {
    field: 'aging',
    headerName: t('aging'),
    flex: 10,
  },
  {
    field: 'daysPastDue',
    headerName: t('status'),
    flex: 10,
    renderCell: (values) => (
      <StatusBadge
        color={invoicesListDaysPastDueStatusMapping[values?.row?.daysPastDue]?.color}
        iconName="dot"
        value={invoicesListDaysPastDueStatusMapping[values?.row?.daysPastDue]?.label}
      />
    ),
  },
  {
    field: 'memo',
    headerName: t('notes'),
    flex: 10,
    sortable: false,
    renderCell: (values) => (
      <Tooltip className="text-ellipsis overflow-hidden" content={values?.row?.memo}>
        {values?.row?.memo}
      </Tooltip>
    ),
  },
  {
    field: 'retention',
    headerName: t('ret'),
    flex: 5,
    renderCell: (values) => (
      <span className="text-ellipsis overflow-hidden">{values?.row?.retention}</span>
    ),
  },
]

export const getInvoiceListTotalItems = (total) => [
  { captionKey: 'invoiced', value: total?.amountCents },
  { captionKey: 'outstanding', value: total?.outstandingAmountCents },
]

export const getInvoiceListFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'vendorUser',
    filterTitle: t('user'),
  },
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
  },
  {
    filterId: 'projectName',
    filterTitle: t('project'),
    loadOptions: loadFilter?.loadProjectOptions,
  },
  {
    filterId: 'issueDate',
    filterTitle: t('date'),
  },
  {
    filterId: 'status',
    filterTitle: t('status'),
  },
  {
    filterId: 'maturityDate',
    headerName: t('dueDate'),
  },
  {
    filterId: 'locationName',
    filterTitle: t('invoiceLocation'),
  },
  {
    filterId: 'excludeRetention',
    filterTitle: t('excludeRetention'),
  },
]

export const getArAgingUserSummaryColumns = (t, rows) => {
  let overdueLevelsColumns = null

  if (rows[0]?.overdueLevels) {
    overdueLevelsColumns = rows[0].overdueLevels.map((level) => ({
      field: `level_${level.id}`,
      headerName: level.title,
      renderCell: (values) => {
        const targetLevel = values?.row?.overdueLevels?.find(
          (currentLevel) => currentLevel.id === level.id,
        )

        return <Money value={targetLevel.amount} />
      },
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    }))
  }

  return [
    {
      field: 'user',
      headerName: t('user'),
      flex: 20,
    },
    {
      field: 'current',
      headerName: t('current'),
      renderCell: (values) => <Money value={values?.row?.current} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    ...(overdueLevelsColumns || []),
    {
      field: 'total',
      headerName: t('total'),
      renderCell: (values) => <Money value={values?.row?.total} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'credits',
      headerName: t('credits'),
      renderCell: (values) => <Money value={values?.row?.credits} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'deposits',
      headerName: t('deposits'),
      renderCell: (values) => <Money value={values?.row?.deposits} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'netDue',
      headerName: t('netDue'),
      renderCell: (values) => <Money value={values?.row?.netDue} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'pendingPayments',
      headerName: t('pendingPmt'),
      renderCell: (values) => <Money value={values?.row?.pendingPayments} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
  ]
}

export const getRetentionSummaryFiltersColumns = (t) => [
  {
    filterId: 'excludeRetention',
    filterTitle: t('excludeRetention'),
  },
  {
    filterId: 'locationName',
    filterTitle: t('invoiceLocation'),
  },
]

export const getArAgingLocationSummaryColumns = (t, rows) => {
  let overdueLevelsColumns = null

  if (rows[0]?.overdueLevels) {
    overdueLevelsColumns = rows[0].overdueLevels.map((level) => ({
      field: `level_${level.id}`,
      headerName: level.title,
      renderCell: (values) => {
        const targetLevel = values?.row?.overdueLevels?.find(
          (currentLevel) => currentLevel.id === level.id,
        )

        return <Money value={targetLevel.amount} />
      },
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    }))
  }

  return [
    {
      field: 'location',
      headerName: t('location'),
      flex: 20,
      renderCell: (values) => (
        <span className="text-ellipsis overflow-hidden">{values?.row?.location}</span>
      ),
    },
    {
      field: 'current',
      headerName: t('current'),
      renderCell: (values) => <Money value={values?.row?.current} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    ...(overdueLevelsColumns || []),
    {
      field: 'total',
      headerName: t('total'),
      renderCell: (values) => <Money value={values?.row?.total} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'credits',
      headerName: t('credits'),
      renderCell: (values) => <Money value={values?.row?.credits} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'deposits',
      headerName: t('deposits'),
      renderCell: (values) => <Money value={values?.row?.deposits} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'netDue',
      headerName: t('netDue'),
      renderCell: (values) => <Money value={values?.row?.netDue} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'pendingPayments',
      headerName: t('pendingPmt'),
      renderCell: (values) => <Money value={values?.row?.pendingPayments} />,
      flex: 10,
      align: 'right',
      headerAlign: 'right',
    },
  ]
}

export const getNetDueByCustomerFiltersColumns = (t) => [
  {
    filterId: 'excludeRetention',
    filterTitle: t('excludeRetention'),
  },
]

export const getArAgingUserDetailsFiltersColumns = (t, loadFilter) => [
  {
    filterId: 'contractId',
    filterTitle: t('customer'),
    loadOptions: loadFilter?.loadContractOptions,
    ignoreMultipleSelect: true,
  },
]

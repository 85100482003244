import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Button, Flex, Money, PageDialog, StatusBadge } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import colors from '../../../ui-kit/colors'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { getSumByField } from '../../../utils/utils'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import DatePicker from '../../../ui-kit/components/datePicker/DatePicker'
import VALIDATION_ERRORS from '../../../constants/validationErrors'
import { CreatePaymentPromiseMutation } from '../../../queriesUpdated/mutations/createPaymentPromise.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import { DateTime } from 'luxon'
import { getDisplayStatusValue } from '../../contracts/utils'
import Text from '../../../ui-kit/components/text/Text'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { InvoicesOutstandingIndexQuery } from '../../../queriesUpdated/queries/invoiceOutstanding.gql'

const PromiseToPayModal = ({ invoiceIds, onSubmit, isOpened, setIsOpened }) => {
  const [updatedInvoices, updateInvoices] = useState(null)
  const [invoices, setInvoices] = useState([])
  const [recalculatedFields, updateRecalculatedFields] = useState({})
  const [p2pDate, updateDate] = useState(new Date(DateTime.now().plus({ days: 1 })))
  const [isDateError, setIsDateError] = useState(false)
  const [memo, updateMemo] = useState('')
  const { t } = useTranslation()
  const [createP2P, { loading: requestInProgress }] = useCustomMutation({
    mutation: CreatePaymentPromiseMutation,
    rollbarOptions: { operationName: 'CreatePaymentPromiseMutation', target: 'PromiseToPayModal' },
  })
  const { newNotification } = useNotifications()
  const contractID = invoices[0]?.contract?.id
  const buyerName = invoices[0]?.contract?.buyer?.name

  const queryVariables = {
    filters: [
      {
        key: 'id',
        values: [...invoiceIds],
      },
    ],
    sort: 'issue_date.desc',
    perPage: invoiceIds.length,
  }
  const onCompleted = useCallback((response) => {
    response?.invoices && setInvoices(response.invoices?.data)
  }, [])
  useCustomQuery({
    query: InvoicesOutstandingIndexQuery,
    onCompleted: onCompleted,
    queryOptions: {
      variables: queryVariables,
      skip: !isOpened,
    },
    rollbarOptions: { operationName: 'PromiseToPay', target: 'InvoicesOutstanding' },
  })

  useEffect(() => {
    const outstanding = getSumByField(invoices, 'outstandingAmountCents')
    updateRecalculatedFields({
      paymentEntered: outstanding,
    })

    return function cleanup() {
      updateInvoices(null)
    }
  }, [invoices])

  const invoiceColumns = useMemo(
    () => [
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'issueDate',
        headerName: t('invoiceDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.issueDate} />,
        flex: 10,
        sortable: false,
      },
      {
        field: 'invoiceNumber',
        headerName: t('invoiceNumber'),
        flex: 25,
        sortable: false,
      },
      {
        field: 'project',
        headerName: t('project'),
        renderCell: (values) => <Text color={colors.GRAY_500}>{values?.row?.project?.name}</Text>,
        flex: 25,
        sortable: false,
      },
      {
        field: 'maturityDate',
        headerName: t('dueDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.maturityDate} />,
        flex: 10,
        sortable: false,
      },
      {
        field: 'amountCents',
        headerName: t('amount'),
        renderCell: (values) => <Money value={values?.row?.amountCents} />,
        flex: 10,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        field: 'outstandingAmountCents',
        headerName: t('outstanding'),
        renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
        flex: 10,
        sortable: false,
      },
      {
        field: 'aging',
        headerName: t('status'),
        renderCell: (values) => (
          <StatusBadge
            color={values?.row?.status}
            iconName="dot"
            value={getDisplayStatusValue(values?.row?.overdueLevel)}
          />
        ),
        flex: 10,
        sortable: false,
      },
    ],
    [invoices, updatedInvoices],
  )

  const handleNotesChange = (e) => {
    updateMemo(e.target.value)
  }

  const handleDateChange = (oldDate, newDate) => {
    updateDate(newDate)
    setIsDateError(!newDate)
  }

  const handleSubmit = async () => {
    if (!isDateError) {
      const invoiceIds = invoices.map((invoice) => +invoice.id)
      const variables = {
        data: {
          contractId: contractID,
          invoiceIds,
          date: p2pDate.toISOString(),
          memo,
        },
      }
      createP2P({
        variables,
      }).then(({ data }) => {
        const responseData = data?.createPaymentPromise || {}

        if (responseData?.entity) {
          newNotification({ success: t('p2pCreatedSuccessfully') })
          onSubmit()
        }
      })
    }
  }

  return (
    <PageDialog
      isOpened={isOpened}
      rightPanel={
        <>
          <div className={'text-2xl text-gray-900'}>{t('summary')}</div>
          <div className={'flex justify-between w-full mt-4 items-center'}>
            <span className={'text-gray-500 text-sm'}>{t('totalPaymentPromised')}:</span>
            <span className={'text-xl gray-900'}>
              <Money value={recalculatedFields.paymentEntered} />
            </span>
          </div>
          <Textarea
            className={'mt-14'}
            id={'comment'}
            inputClassName={'h-32'}
            label={t('memoOptional')}
            name={'comments'}
            onChange={handleNotesChange}
            placeholder={t('addComment')}
            value={memo}
          />
          <Button
            className="mt-2 w-full"
            color={colors.PRIMARY}
            disabled={requestInProgress}
            label={t('addP2P')}
            onClick={handleSubmit}
            size={sizes.SM}
            testData="submit-p2p"
          />
        </>
      }
      setIsOpened={setIsOpened}
      title={t('addP2P')}>
      <Flex className="relative flex-1" column>
        <div className="px-8 py-4 flex items-center">
          <div className="flex-1 content-center">
            <h3 className="text-lg leading-6 font-medium">{buyerName}</h3>
            <form>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <span className="pb-2">{t('expectedPaymentDate')}</span>
                  <DatePicker
                    error={isDateError}
                    onChange={(dateValue) => handleDateChange(p2pDate, dateValue)}
                    value={p2pDate}
                    isTodayMinDay
                  />
                  {isDateError && (
                    <p className="mt-2 text-sm text-error">{VALIDATION_ERRORS.REQUIRED}</p>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="px-8 py-8 relative">
          <DataGridComponent
            columns={invoiceColumns}
            hideFooter={invoices.length < 100}
            localPagination={invoices.length > 100}
            pageSize={100}
            rows={updatedInvoices || invoices}
          />
        </div>
      </Flex>
    </PageDialog>
  )
}

PromiseToPayModal.propTypes = {
  financeCharges: PT.number,
  invoiceIds: PT.arrayOf(PT.string).isRequired,
  isOpened: PT.bool.isRequired,
  onSubmit: PT.func,
  setIsOpened: PT.func.isRequired,
}
PromiseToPayModal.defaultProps = {
  onSubmit: () => {},
}

export default PromiseToPayModal

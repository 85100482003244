import { useEffect } from 'react'

export const useBeforeUnload = ({
  when,
  message = 'Do you want to leave? All unsaved data will be lost.',
}) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = message
      return message
    }

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [when, message])
}

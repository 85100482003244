import { useEffect } from 'react'
import { useQueryParams } from './useQueryParams'

export const useOpenEntitySidebar = (data, setSelected, setIsOpened) => {
  const { queryParams, removeQueryParam } = useQueryParams()

  useEffect(() => {
    if (!queryParams?.openSidebarParams || !data?.length || !setIsOpened || !setSelected) {
      return
    }

    const [fieldName, fieldValue] = queryParams.openSidebarParams.split('.')
    const [mainFieldName, subFieldName] = fieldName.split('-')

    const targetEntity = data.find((item) => {
      if (subFieldName) {
        return item[mainFieldName]?.[subFieldName] === fieldValue
      }

      return item[fieldName] === fieldValue
    })

    if (targetEntity) {
      setSelected(targetEntity)
      setIsOpened(true)
    }

    removeQueryParam('openSidebarParams')
  }, [queryParams?.openSidebarParams, data, setIsOpened])
}

export const paymentsReportsListMap = {
  CUSTOMER_PAYMENTS: 'customerPayments',
  AUTHORIZATION: 'Authorizations',
  PROMISE_TO_PAYS: 'promiseToPays',
  PAYMENT_PLANS: 'paymentPlans',
  REFUNDS: 'refunds',
}

export const paymentsReports = {
  [paymentsReportsListMap.CUSTOMER_PAYMENTS]: {
    csvUrl: '/reports/customer_payments.csv?sort=entry_date.desc',
    jsonUrl: '/reports/customer_payments.json?sort=entry_date.desc',
    xlsxUrl: '/reports/customer_payments.xlsx?sort=entry_date.desc',
    fileNameKey: paymentsReportsListMap.CUSTOMER_PAYMENTS,
  },
  [paymentsReportsListMap.AUTHORIZATION]: {
    csvUrl: '/reports/authorizations.csv?sort=date.desc',
    jsonUrl: '/reports/authorizations.json?sort=date.desc',
    xlsxUrl: '/reports/authorizations.xlsx?sort=date.desc',
    fileNameKey: paymentsReportsListMap.AUTHORIZATION,
  },
  [paymentsReportsListMap.PROMISE_TO_PAYS]: {
    csvUrl: '/reports/payment_promises.csv?sort=created_at.desc',
    jsonUrl: '/reports/payment_promises.json?sort=created_at.desc',
    xlsxUrl: '/reports/payment_promises.xlsx?sort=created_at.desc',
    fileNameKey: paymentsReportsListMap.PROMISE_TO_PAYS,
  },
  [paymentsReportsListMap.PAYMENT_PLANS]: {
    csvUrl: '/reports/payment_plans.csv?sort=created_at.desc',
    jsonUrl: '/reports/payment_plans.json?sort=created_at.desc',
    xlsxUrl: '/reports/payment_plans.xlsx?sort=created_at.desc',
    fileNameKey: paymentsReportsListMap.PAYMENT_PLANS,
  },
  [paymentsReportsListMap.REFUNDS]: {
    csvUrl: '/reports/refunds.csv?sort=created_at.desc',
    jsonUrl: '/reports/refunds.json?sort=created_at.desc',
    xlsxUrl: '/reports/refunds.xlsx?sort=created_at.desc',
    fileNameKey: paymentsReportsListMap.REFUNDS,
  },
}

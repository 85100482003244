import React, { useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { getRollbarPerson, ROLLBAR_NO_AUTH_PERSON } from '../utils/rollbar'
import { useRollbarPerson } from '@rollbar/react'

const RedirectRoute = () => {
  const currentUser = useCurrentUser()
  const rollbarPerson = useMemo(
    () => (currentUser ? getRollbarPerson(currentUser) : ROLLBAR_NO_AUTH_PERSON),
    [currentUser],
  )
  useRollbarPerson(rollbarPerson)

  if (currentUser?.membershipRole === 'counter') {
    return (
      <Routes>
        <Route element={<Navigate to="/customers" replace />} path="/" />
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route element={<Navigate to="/dashboard" replace />} path="/" />
      </Routes>
    )
  }
}

export default RedirectRoute

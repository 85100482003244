import { useTranslation } from 'react-i18next'

{
  /* eslint-disable react/jsx-closing-tag-location */
}
import React, { useEffect, useRef, useState } from 'react'
import PT from 'prop-types'
import { range } from 'lodash'
import { Text, Icon, Flex, Money } from '../../index'
import fontWeight from '../../fontWeight'
import align from '../../align'
import sizes from '../../sizes'
import colors from '../../colors'
import cx from 'classnames'
import classes from './Pagination.module.scss'

const Pagination = ({
  totalResults,
  totalPages,
  currentPage,
  className,
  alignment,
  fromResult,
  toResult,
  withResults,
  loading,
  onPageChange,
  name,
  isTotalDisplay,
  columns,
  totalData,
  columnsWidth,
}) => {
  const { t } = useTranslation()
  const prevState = useRef({
    toResult,
    fromResult,
    totalPages,
    currentPage,
    totalResults,
  })

  if (!loading) {
    prevState.current = {
      toResult,
      fromResult,
      totalPages,
      currentPage,
      totalResults,
    }
  }
  const containerClassnames = cx(classes.pagination, {
    [classes.paginationWithResults]: withResults,
    [classes.paginationAlignLeft]: !withResults && alignment === align.LEFT,
    [classes.paginationAlignCenter]: !withResults && alignment === align.CENTER,
    [classes.paginationAlignRight]: !withResults && alignment === align.RIGHT,
    [className]: !!className,
  })

  const arrowButtonClassnames = (arrowSide, disabled) =>
    cx(classes.arrowButton, {
      [classes.arrowButtonLeft]: arrowSide === 'left',
      [classes.arrowButtonRight]: arrowSide === 'right',
      [classes.disabled]: loading || disabled,
    })

  const pageCellClassnames = (currentPage) =>
    cx(classes.pageCell, {
      [classes.currentPage]: currentPage,
      [classes.disabled]: loading,
    })

  const perPageClassnames = () => cx(classes.perPageContainer)

  const [displayedPages, setDisplayedPages] = useState(range(1, 10))

  useEffect(() => {
    const { totalPages, currentPage } = prevState.current

    if (totalPages === 0 || totalPages === 1) {
      setDisplayedPages(range(1, 0))
    } else if (currentPage <= 5) {
      const lastPageNumber = totalPages > 10 ? 10 : totalPages
      setDisplayedPages(range(1, lastPageNumber + 1))
    } else if (currentPage > 5 && currentPage < totalPages - 4) {
      setDisplayedPages(range(currentPage - 4, currentPage + 5))
    } else {
      let pagesFrom = totalPages - 8
      pagesFrom = pagesFrom < 1 ? 1 : pagesFrom
      setDisplayedPages(range(pagesFrom, totalPages + 1))
    }
  }, [prevState.current.totalPages, prevState.current.currentPage])

  const renderResultsInfo = () => {
    if (!withResults) return null

    return (
      <Text size={sizes.SM} paragraph>
        Showing
        <Text fontWeight={fontWeight.BOLD} size={sizes.SM}>
          {` ${prevState.current.fromResult || 0} `}
        </Text>
        to
        <Text fontWeight={fontWeight.BOLD} size={sizes.SM}>
          {` ${prevState.current.toResult || 0} `}
        </Text>
        of
        <Text fontWeight={fontWeight.BOLD} size={sizes.SM}>
          {` ${prevState.current.totalResults || 0} `}
        </Text>
        results
      </Text>
    )
  }
  if (prevState.current.totalPages === 1 && !isTotalDisplay) {
    return null
  }

  const getTotalValue = (propName) => {
    const total = totalData.find((currentLevel) => currentLevel.captionKey === propName)
    return total?.value
  }

  return (
    <div className={containerClassnames}>
      {isTotalDisplay && (
        <Flex className="w-full pt-2" justifyContent="between">
          {columns.map((column, index) => {
            if (index === 0) {
              return (
                <div key="title" style={{ width: columnsWidth[column.field], paddingLeft: '12px' }}>
                  <Text fontWeight={fontWeight.SEMIBOLD}>{t('grandTotal')}</Text>
                </div>
              )
            }
            return (
              <div
                key={column.field}
                style={{
                  width: columnsWidth[column.field],
                  textAlign: 'right',
                  paddingRight: '12px',
                }}>
                {!!column.totalPropName && (
                  <Money className="font-semibold" value={getTotalValue(column.totalPropName)} />
                )}
              </div>
            )
          })}
        </Flex>
      )}
      {prevState.current.totalPages > 1 && (
        <Flex className="w-full pt-2 items-center" row>
          <div className={perPageClassnames()} />
          <div className={classes.resultInfo}>{renderResultsInfo()}</div>

          <div aria-label="Pagination" className={classes.navContainer}>
            <div
              className={arrowButtonClassnames('left', prevState.current.currentPage === 1)}
              onClick={() => onPageChange(1, name)}>
              <Icon color={colors.GREY} name="chevronDoubleLeft" />
            </div>
            <div
              className={arrowButtonClassnames(null, prevState.current.currentPage === 1)}
              onClick={() => onPageChange(prevState.current.currentPage - 1, name)}>
              <Icon color={colors.GREY} name="chevronLeft" />
            </div>

            {displayedPages.map((pageNumber) => (
              <div
                className={pageCellClassnames(prevState.current.currentPage === pageNumber)}
                key={pageNumber}
                onClick={() => onPageChange(pageNumber, name)}>
                {pageNumber}
              </div>
            ))}

            <div
              className={arrowButtonClassnames(
                null,
                prevState.current.currentPage === prevState.current.totalPages,
              )}
              onClick={() => onPageChange(prevState.current.currentPage + 1, name)}>
              <Icon color={colors.GREY} name="chevronRight" />
            </div>
            <div
              className={arrowButtonClassnames(
                'right',
                prevState.current.currentPage === prevState.current.totalPages,
              )}
              onClick={() => onPageChange(prevState.current.totalPages, name)}>
              <Icon color={colors.GREY} name="chevronDoubleRight" />
            </div>
          </div>
        </Flex>
      )}
    </div>
  )
}

Pagination.propTypes = {
  name: PT.string,
  alignment: PT.oneOf([align.LEFT, align.CENTER, align.RIGHT]),
  className: PT.string,
  currentPage: PT.number,
  fromResult: PT.number,
  loading: PT.bool,
  onPageChange: PT.func.isRequired,
  toResult: PT.number,
  totalPages: PT.number,
  totalResults: PT.number,
  withResults: PT.bool,
  isTotalDisplay: PT.bool,
  columns: PT.array,
  totalData: PT.object,
  columnsWidth: PT.object,
}

Pagination.defaultProps = {
  name: '',
  alignment: align.LEFT,
  className: '',
  currentPage: 0,
  totalPages: 0,
  fromResult: 0,
  loading: false,
  totalResults: 0,
  withResults: false,
}

export default Pagination

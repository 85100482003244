import PT from 'prop-types'
import { Text } from '../../index'
import sizes from '../../sizes'
import React from 'react'
import cx from 'classnames'
import Tooltip from '../tooltip/Tooltip'

const DividedList = ({
  content,
  title,
  skipDivide,
  labelTextProps,
  valueTextProps,
  wrapperClassName,
  labelTextClassName,
  valueTextClassName,
}) => {
  return (
    <div className={cx('mt-3 w-full', wrapperClassName)}>
      {title && (
        <Text fontWeight={'medium'} size={'base'}>
          {title}
        </Text>
      )}
      <div className={cx('flex flex-col', !skipDivide && 'divide-y')}>
        {content.reduce((acc, item) => {
          if (!item.hidden) {
            acc.push(
              <div className="flex flex-row min-h-8 justify-between" key={`${item.label}`}>
                <Text
                  className={cx('flex flex-col justify-start w-fit py-2 pr-2', labelTextClassName)}
                  color="text-warmBlack-400"
                  size={sizes.SM}
                  {...labelTextProps}>
                  {item.label}
                </Text>
                {item.node ||
                  (item.address ? (
                    <Tooltip
                      className="flex items-center justify-end max-w-4/5 relative"
                      content={item.value}>
                      <Text
                        align="right"
                        className={cx(
                          'ml-1 whitespace-nowrap text-ellipsis overflow-hidden block',
                          valueTextClassName,
                        )}
                        color={item?.color}
                        size={sizes.SM}
                        {...valueTextProps}>
                        {item.value}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text
                      className={cx(
                        'flex flex-col justify-center text-right items-end py-2',
                        valueTextClassName,
                      )}
                      color={item?.color}
                      size={sizes.SM}
                      {...valueTextProps}>
                      {item.email ? <a href={`mailto:${item.email}`}>{item.value}</a> : item.value}
                    </Text>
                  ))}
              </div>,
            )
          }

          return acc
        }, [])}
      </div>
    </div>
  )
}

DividedList.propTypes = {
  content: PT.arrayOf(
    PT.shape({
      label: PT.string,
      value: PT.oneOfType([PT.string, PT.number, PT.node]),
      hidden: PT.bool,
    }).isRequired,
  ),
  title: PT.string,
  skipDivide: PT.bool,
  labelTextProps: PT.object,
  valueTextProps: PT.object,
  wrapperClassName: PT.string,
  labelTextClassName: PT.string,
  valueTextClassName: PT.string,
}
DividedList.defaultProps = {
  title: undefined,
  skipDivide: false,
  labelTextProps: {},
  valueTextProps: {},
  wrapperClassName: '',
  labelTextClassName: '',
  valueTextClassName: '',
}

export default DividedList

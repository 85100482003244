import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { Flex, Input } from '../../ui-kit'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useTranslation } from 'react-i18next'
import NDropdown from '../../ui-kit/components/dropdown/NDropdown'
import { camelCase } from 'lodash/string'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import Button from '../../ui-kit/components/buttons/Button'
import FilterDatePicker from '../../ui-kit/components/datePicker/FilterDatePicker'
import { DateTime } from 'luxon'
import { DEFAULT_DATE_FORMAT } from '../../ui-kit/utils/dateUtils'
import { getDateToDatePicker, parseDisplayFilterQuery, reformatISODate } from '../../utils/utils'
import { useFilters } from '../../hooks/useFilters'
import FilterChip from './FilterChip'
import { MONEY } from '../../utils/dataTypes'
import FormSelect from '../../ui-kit/components/Select/FormSelect'

const FiltersComponent = ({ columns, availableFilters, controlledData }) => {
  const { queryParams, setQueryParams, removeQueryParam } = useQueryParams()
  const { t } = useTranslation()
  const [filtersList, updateFilterList] = useState([])
  const [availableFilterOptions, setAvailableFilterOptions] = useState([])
  const [selectedFilter, setSelectedFilters] = useState({})
  const [isShowAddFilterBtn, setIsShowAddFilterBtn] = useState(false)
  const filtersQuery = queryParams.filters || null
  const userFilters = parseDisplayFilterQuery(filtersQuery)
  const [asyncLabel, setAsyncLabel] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [isAutocompleteDataFetched, setIsAutocompleteDataFetched] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  const getFromAvailableFilters = (filterId) =>
    availableFilters.find((item) => camelCase(item.key) === filterId)

  const parseCurrentUserFilters = (filterOptions, userFilters) => {
    if (userFilters?.length && filterOptions.length) {
      showFilters()
      const {
        updatedFilterOptions: newAvailableFilterOptions,
        newFilterList,
        selectedFilters,
      } = userFilters.reduce(
        (accum, filter) => {
          const key = camelCase(filter.key)
          accum.selectedFilters[key] = filter.values
          return {
            ...populateNewFilter(accum.updatedFilterOptions, key, accum.newFilterList),
            selectedFilters: accum.selectedFilters,
          }
        },
        {
          updatedFilterOptions: filterOptions,
          newFilterList: [],
          selectedFilters: selectedFilter,
        },
      )
      setSelectedFilters(selectedFilters)
      updateFilterList(newFilterList)
      setAvailableFilterOptions(newAvailableFilterOptions)
    } else if (filtersList.length) {
      updateFilterList([])
    }
  }

  const { isShowFilters, showFilters, hideFilters } = useFilters()

  useEffect(() => {
    const availableFilterOptions =
      availableFilters && columns
        ? columns.reduce((acc, column) => {
            if (column.filterId) {
              const filter = getFromAvailableFilters(column.filterId)
              acc.push({
                label: column.filterTitle || column.headerName,
                value: column.filterId,
                type: column.filterType || filter?.type,
                dataType: column.filterDataType,
                filterOptions: column.filterOptions || filter?.options,
                loadOptions: column.loadOptions,
              })
            }
            return acc
          }, [])
        : []
    setAvailableFilterOptions(availableFilterOptions)
    parseCurrentUserFilters(availableFilterOptions, userFilters)
    availableFilters && setIsInitialized(true)
  }, [availableFilters, queryParams?.filters])

  useEffect(() => {
    return hideFilters()
  }, [])

  const populateNewFilter = (availableFilterOptions, newFilterKey, filtersList) => {
    const updatedFilterOptions = changeFilterOptionsStatus(
      availableFilterOptions,
      newFilterKey,
      true,
    )
    const selectedFilterIndex = availableFilterOptions.findIndex(
      (filter) => filter.value === newFilterKey,
    )
    const selectedFilter = availableFilterOptions[selectedFilterIndex]

    const newFilterList = [...filtersList]
    selectedFilter &&
      newFilterList.push({
        key: newFilterKey,
        label: selectedFilter.label,
        type: selectedFilter.type,
        options: selectedFilter.filterOptions?.map((item) => ({
          value: item.key,
          label: t(item.title),
        })),
        dataType: selectedFilter.dataType,
        loadOptions: selectedFilter.loadOptions,
      })
    setIsShowAddFilterBtn(true)
    return { updatedFilterOptions, newFilterList }
  }
  const newFilterChange = (newFilterKey) => {
    const { updatedFilterOptions: newAvailableFilterOptions, newFilterList } = populateNewFilter(
      availableFilterOptions,
      newFilterKey,
      filtersList,
    )
    updateFilterList(newFilterList)
    setAvailableFilterOptions(newAvailableFilterOptions)
  }

  const filterChange = (value, key, isApplyOnBlur) => {
    const copyFilters = { ...selectedFilter }

    if (key.indexOf('/') > -1) {
      const [keyName, index] = key.split('/')
      const filterCurrentValues = copyFilters[keyName] || [null, null]
      filterCurrentValues[index] = value
      copyFilters[keyName] = filterCurrentValues
    } else {
      copyFilters[key] = value
    }
    setSelectedFilters(copyFilters)
    if (!isApplyOnBlur) {
      applyFilters(copyFilters)
    }
  }

  const filterValueChange = (value, key, isApplyOnBlur) => {
    const valueArray = value.split(',').map((val) => val.trim())
    filterChange(valueArray, key, isApplyOnBlur)
  }

  const datesFilterChange = (dates, key) => {
    if (Array.isArray(dates)) {
      const formattedDates = dates.map((date, index) => {
        if (!date) {
          return ''
        }

        if (index > 0) {
          return DateTime.fromJSDate(date).endOf('day').toISO()
        }

        return DateTime.fromJSDate(date).toISO()
      })

      filterChange(formattedDates, key)
    }
  }

  const getValuesString = (values) => {
    if (Array.isArray(values)) {
      return values.join('||')
    } else {
      return values
        .split(',')
        .map((value) => value.trim())
        .join('-')
    }
  }

  const getQueryString = (filters = selectedFilter) =>
    Object.keys(filters).reduce((string, key) => {
      const valueString = getValuesString(filters[key])

      if (!valueString) {
        return string
      }

      const filterString = `${key}.${valueString}`

      string += string ? `&&${filterString}` : filterString
      return string
    }, '')

  const applyFilters = (filters = selectedFilter, isRemoveQueryParams) => {
    const { setFilters } = controlledData

    if (setFilters) {
      setFilters(filters)
      return
    }
    // const validFilters = validateFilters(filters)
    const removeQueryParams = []

    if (isRemoveQueryParams) {
      removeQueryParams.push('additionalFilters')
    }

    if (queryParams?.page) {
      removeQueryParams.push('page')
    }

    setQueryParams({ filters: getQueryString(filters) }, removeQueryParams)
  }

  const checkIfConvert = (value, filter) => {
    return filter.dataType === MONEY && value && !isNaN(value)
  }

  const onChangeAutocompleteValues = (values, filter) => {
    const { valuesArray, valuesLabels } = values?.reduce(
      (acc, item) => {
        acc.valuesArray.push(item.value)
        acc.valuesLabels.push(item.label)
        return acc
      },
      { valuesArray: [], valuesLabels: [] },
    ) || { valuesArray: [], valuesLabels: [] }

    setAsyncLabel({ ...asyncLabel, [filter.key]: valuesLabels.join(', ') })
    setSelectedOptions?.({ ...selectedOptions, [filter.key]: values })
    filterChange(valuesArray, filter.key)
  }

  const getFilterInput = (filter) => {
    switch (filter.type) {
      case 'autocomplete_select':
        return (
          <div className="w-4/12 pb-4">
            <FormSelect
              className="mr-4"
              label={filter.label}
              loadOptions={filter.loadOptions}
              onChange={(values) => onChangeAutocompleteValues(values, filter)}
              options={selectedOptions[filter.key] || []}
              placeholder={t('choose') + ' ' + filter.label}
              value={selectedFilter[filter.key]}
              isMulti
              withCheckmarks
            />
          </div>
        )
      case 'select':
        return (
          <div className="w-4/12 pb-4">
            <NDropdown
              className="pr-4"
              inputClassName={'w-full'}
              key={filter.key}
              label={filter.label}
              listClass={'max-h-40'}
              name={filter.key}
              onChange={(e) => {
                filterChange(e.target.value, filter.key)
              }}
              options={filter.options}
              placeholder={t('choose') + ' ' + filter.label}
              value={selectedFilter[filter.key] || []}
              isMultipleSelect
            />
          </div>
        )
      case 'value':
        return (
          <div className="w-4/12 pb-4">
            <Input
              className="pr-4"
              id={filter.key}
              label={filter.label}
              onBlur={() => applyFilters()}
              onChange={({ target }) => filterValueChange(target.value, filter.key, true)}
              placeholder={t('choose') + ' ' + filter.label}
              value={selectedFilter[filter.key]}
            />
          </div>
        )
      case 'values_range': {
        const convertToCents = (value) => {
          return checkIfConvert(value, filter) ? value * 100 : value
        }
        const convertToDollar = (value) => {
          return checkIfConvert(value, filter) ? value / 100 : value
        }

        return (
          <Flex alignItems="end" className="flex w-4/12 pr-4 pb-4">
            <Input
              id={filter.key + '/0'}
              label={filter.label}
              onBlur={() => applyFilters()}
              onChange={({ target }) =>
                filterChange(convertToCents(target.value), filter.key + '/0', true)
              }
              placeholder={t('min')}
              value={convertToDollar(selectedFilter[filter.key]?.[0])}
              isCurrencyInput
            />
            <Input
              id={filter.key + '/1'}
              onBlur={() => applyFilters()}
              onChange={({ target }) =>
                filterChange(convertToCents(target.value), filter.key + '/1', true)
              }
              placeholder={t('max')}
              value={convertToDollar(selectedFilter[filter.key]?.[1])}
              isCurrencyInput
            />
          </Flex>
        )
      }
      case 'dates_range':
      case 'date_times_range':
        return (
          <div className="w-4/12 pr-4 pb-4">
            <label className="mb-[0.3rem] block text-sm font-medium text-gray-700">
              {filter.label}
            </label>
            <FilterDatePicker
              endDate={getDateToDatePicker(selectedFilter[filter.key]?.[1])}
              onBlur={() => applyFilters()}
              onChange={(dateValue) => datesFilterChange(dateValue, filter.key)}
              placeholder={t('chooseDates')}
              startDate={getDateToDatePicker(selectedFilter[filter.key]?.[0])}
            />
          </div>
        )
      default:
        return null
    }
  }

  const changeFilterOptionsStatus = (availableFilterOptions, key, isHidden) => {
    const selectedFilterIndex = availableFilterOptions.findIndex((filter) => filter.value === key)

    if (selectedFilterIndex === -1) {
      return availableFilterOptions
    }

    const selectedFilter = availableFilterOptions[selectedFilterIndex]
    selectedFilter.isHidden = isHidden
    return availableFilterOptions
  }

  const handleRemoveFilter = (key) => {
    const copySelectedFilter = { ...selectedFilter }
    const updatedFilterList = filtersList.filter((filter) => filter.key !== key)
    delete copySelectedFilter[key]

    if (asyncLabel[key]) {
      const copyAsyncLabel = { ...asyncLabel }
      delete copyAsyncLabel[key]
      setAsyncLabel(copyAsyncLabel)
    }

    if (selectedOptions[key]) {
      const copySelectedOptions = { ...selectedOptions }
      delete copySelectedOptions[key]
      setSelectedOptions(copySelectedOptions)
    }

    if (!updatedFilterList.length) {
      setIsShowAddFilterBtn(false)
    }
    setAvailableFilterOptions(changeFilterOptionsStatus(availableFilterOptions, key, false))
    updateFilterList(updatedFilterList)
    setSelectedFilters(copySelectedFilter)
    applyFilters(copySelectedFilter, !Object.keys(copySelectedFilter).length)
  }

  useEffect(() => {
    if (isAutocompleteDataFetched || !isInitialized) {
      return
    }

    if (!isAutocompleteDataFetched && !filtersList?.length) {
      setIsAutocompleteDataFetched(true)
      return
    }

    const autocompleteFilters = filtersList.filter(
      (filter) => filter.type === 'autocomplete_select' && selectedFilter[filter.key],
    )
    autocompleteFilters.forEach(async (item) => {
      const selectedItems = selectedFilter[item.key] || []
      const { options: loadedSelectedOptions } = await item.loadOptions(null, null, selectedItems)
      const loadedFilter = loadedSelectedOptions.filter(
        (item) => selectedItems.indexOf(item.value) > -1,
      )
      const labels = loadedFilter.map((filter) => filter.label).join(', ')
      setAsyncLabel((prevState) => ({ ...prevState, [item.key]: labels }))
      setSelectedOptions((prevState) => ({ ...prevState, [item.key]: loadedSelectedOptions }))
    })

    setIsAutocompleteDataFetched(true)
  }, [filtersList, isInitialized])

  const getChips = (filter) => {
    if (selectedFilter[filter.key]) {
      switch (filter.type) {
        case 'autocomplete_select': {
          const value = asyncLabel[filter.key]
          return (
            !!value && (
              <FilterChip
                label={filter.label}
                onRemoveFilter={() => handleRemoveFilter(filter.key)}
                value={value}
              />
            )
          )
        }
        case 'select': {
          const value = selectedFilter[filter.key]
            ?.map((value) => filter.options.find((option) => option.value === value)?.label)
            .join(', ')
          return (
            <FilterChip
              label={filter.label}
              onRemoveFilter={() => handleRemoveFilter(filter.key)}
              value={value}
            />
          )
        }
        case 'value':
          return (
            <FilterChip
              label={filter.label}
              onRemoveFilter={() => handleRemoveFilter(filter.key)}
              value={selectedFilter[filter.key]?.join(', ')}
            />
          )
        case 'values_range':
          return (
            <FilterChip
              label={filter.label}
              onRemoveFilter={() => handleRemoveFilter(filter.key)}
              value={selectedFilter[filter.key]
                ?.map((value) => (checkIfConvert(value, filter) ? value / 100 : value))
                .join('-')}
            />
          )
        case 'dates_range':
        case 'date_times_range':
          return (
            <FilterChip
              label={filter.label}
              onRemoveFilter={() => handleRemoveFilter(filter.key)}
              value={selectedFilter[filter.key]
                .map((value) => reformatISODate(value, DEFAULT_DATE_FORMAT))
                ?.join('-')}
            />
          )
        default:
          return null
      }
    }
  }

  const getAvailableOptions = (availableFilterOptions) =>
    availableFilterOptions
      .filter((option) => !option.isHidden)
      .map((option) => ({
        ...option,
        label: t('by') + option.label,
      }))

  const removeAllFilters = () => {
    updateFilterList([])
    setSelectedFilters({})
    setIsShowAddFilterBtn(false)
    setAvailableFilterOptions(
      availableFilterOptions.map((filter) => ({ ...filter, isHidden: false })),
    )
    setAsyncLabel({})
    setSelectedOptions({})
    const { setFilters } = controlledData
    if (setFilters) {
      setFilters({})
    } else {
      removeQueryParam(['filters', 'additionalFilters'])
    }
  }
  return (
    <>
      {isShowFilters && (
        <Flex alignItems="end" className="w-full basis-1 flex-wrap">
          <>
            {filtersList.map((filter) => getFilterInput(filter))}
            {isShowAddFilterBtn && !!getAvailableOptions(availableFilterOptions).length && (
              <div className="w-4/12 pb-4">
                <Button
                  className="mb-2 w-4/12"
                  iconName="plus"
                  label={t('addFilter')}
                  onClick={() => setIsShowAddFilterBtn(false)}
                  size={sizes.SM}
                  testData="add_filter"
                  variant={buttonsVariants.LINK}
                />
              </div>
            )}
            {!isShowAddFilterBtn && !!getAvailableOptions(availableFilterOptions).length && (
              <div className="w-4/12 pb-4">
                <NDropdown
                  inputClassName={'w-full'}
                  label={t('newFilter')}
                  listClass={'max-h-40'}
                  onChange={(e) => newFilterChange(e.target.value)}
                  options={getAvailableOptions(availableFilterOptions)}
                  placeholder={t('chooseFilter')}
                  value={selectedFilter['newFilter']}
                />
              </div>
            )}
          </>
        </Flex>
      )}
      {!!filtersList.length && (
        <Flex className="w-full mb-4">
          {filtersList.map((filter) => {
            return getChips(filter)
          })}
          {!!filtersList.length && (
            <Button
              label={t('clearAll')}
              onClick={() => removeAllFilters()}
              size={sizes.SM}
              testData="clear_all_filters"
              variant={buttonsVariants.LINK}
            />
          )}
        </Flex>
      )}
    </>
  )
}

FiltersComponent.propTypes = {
  availableFilters: PT.arrayOf(
    PT.shape({
      key: PT.string,
      type: PT.string,
    }),
  ),
  columns: PT.arrayOf(
    PT.shape({
      field: PT.string,
    }),
  ),
  controlledData: PT.shape({
    setFilters: PT.func,
  }),
}

FiltersComponent.defaultProps = {
  availableFilters: null,
  columns: [],
  controlledData: {},
}

export default FiltersComponent

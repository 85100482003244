export const MEMO_KEY = 'memo'

const actions = {}
const initialState = {
  memo: {},
}

// ------------------------------------
// Reducer
// ------------------------------------

const reducer = (state = initialState, action) => {
  const handler = actions[action.type]

  return handler ? handler(state, action) : state
}

export default reducer

// ------------------------------------
// Actions
// ------------------------------------

// Apply memo change
export const SHOW_MEMO_FORM = `${MEMO_KEY}/SHOW_MEMO_FORM`
export const HIDE_MEMO_FORM = `${MEMO_KEY}/HIDE_MEMO_FORM`

actions[SHOW_MEMO_FORM] = (state, { payload }) => ({ memo: { ...payload, visible: true } })
actions[HIDE_MEMO_FORM] = () => ({ memo: { visible: false } })

import { snakeCase, toInteger } from 'lodash'
import { DateTime } from 'luxon'
import { toFixedDecimal } from './toFixedDecimal'
import Decimal from 'decimal.js'

export const MAX_NUMBER = 2147483647
export const MAX_PER_PAGE = 500

export const getPaginationData = (model) => {
  const paginationData = model?.paginationData
  const totalCount = paginationData?.totalCount || 0
  const totalPages = paginationData?.totalPages || 0
  const from = paginationData?.from || 0
  const to = paginationData?.to || 0
  return { totalCount, totalPages, from, to }
}

export const getModelsByIds = (array = [], ids = []) => {
  return array.filter((item) => ids.indexOf(item.id) > -1)
}

export const getSumByField = (array = [], fieldName) => {
  return array.reduce((sum, item) => {
    if (item[fieldName] && !isNaN(Number.parseFloat(item[fieldName]))) {
      return sum + +item[fieldName]
    } else {
      return sum
    }
  }, 0)
}

export const getTableParams = (queryParams) => {
  const sort = queryParams.sort || 'payment_date.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  return { sort, page, search }
}

export const excludeErrorMsg = (error) => {
  if (Array.isArray(error) && error.length) {
    return error.map((error) => error.message).join(', ')
  }
}

export const handleError = (error, notification) => {
  if (Array.isArray(error) && error.length) {
    notification({ error: excludeErrorMsg(error) })
  } else if (error?.message) {
    notification({ error: error?.message })
  }
}

export const parseToFinanceFormat = (value, allFractionDigits = false) => {
  if ((!value && value !== 0) || isNaN(Number.parseFloat(value))) {
    return ''
  }

  const number = Number.parseFloat(value)

  return allFractionDigits ? number.toString() : new Decimal(number).toFixed(2)
}

export const getActiveTab = (location, mainPath) => {
  const pathArray = location.pathname.split('/')
  const indexInvoice = pathArray.indexOf(mainPath)
  return pathArray[indexInvoice + 1]
}

export const getTabsMap = (tabs, activeTab, handleTabClick, t, counters) =>
  tabs.map((tab, i) => ({
    label: t(tab),
    onClick: () => handleTabClick(tab),
    active: activeTab === tab,
    counter: counters?.[i],
  }))

export const getNumberWithCommas = (number) => {
  if (Number.isNaN(Number(number))) return null

  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const transformCentsToDollars = (number) => {
  return (number || 0) / 100
}

export const checkOnUniqueElement = (values) => {
  const set = new Set(values)
  return set.size > 1
}

export const parseFilterQuery = (url) => {
  if (url) {
    const filters = url.split('&&')
    return filters
      .map((filter) => {
        const keyIndex = filter.indexOf('.')
        const key = filter.substring(0, keyIndex)
        const stringValues = filter.substring(keyIndex + 1, filter.length)
        const values = stringValues.split('||')
        if (key === 'actionItem') {
          return values.map((value) => ({
            key: value,
            values: ['true'],
          }))
        }
        return {
          key: snakeCase(key),
          values,
        }
      })
      .flat()
  }
  return []
}

export const parseDisplayFilterQuery = (url) => {
  if (url) {
    const filters = url.split('&&')
    return filters.map((filter) => {
      const keyIndex = filter.indexOf('.')
      const key = filter.substring(0, keyIndex)
      const stringValues = filter.substring(keyIndex + 1, filter.length)
      const values = stringValues.split('||')
      return {
        key: snakeCase(key),
        values,
      }
    })
  }
  return []
}

export const getDateToDatePicker = (date) => {
  return (date && new Date(date)) || ''
}

export const reformatISODate = (date, formatTo) => {
  return (date && DateTime.fromISO(date).toFormat(formatTo)) || ''
}

export const getCreditsTableParams = (queryParams) => {
  const sort = queryParams.sort || 'issue_date.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  return { sort, page, search }
}

export const makeOptions = (arr) => {
  if (arr?.every((el) => typeof el === 'string' || typeof el === 'number')) {
    return arr.map((el) => ({
      label: el,
      value: el,
      name: el,
    }))
  } else return null
}

export const statusDisputeMapping = {
  create: { label: 'disputed', color: 'red' },
  resolve: { label: 'resolved', color: 'green' },
}

export const getOnlyNumbers = (value) => {
  if (typeof value === 'string') {
    return value.replace(/[^\d]/g, '')
  }
}

export const getDecimal = (value, fixed) => {
  if (typeof value === 'string') {
    const parsedValue = value.replace(/^\d*\.?\d*$/g, '')
    const fixedValue = toFixedDecimal(parsedValue, fixed)
    return parsedValue === fixedValue + '.' ? parsedValue : fixedValue
  }
}

export const writeTextToClipboard = async (text) => {
  if (!text) {
    return false
  }

  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (err) {
    console.warn(err)
    return false
  }
}

import { useMutation } from '@apollo/client'
import { useRollbar } from '@rollbar/react'
import { handleError } from '../utils/utils'
import { captureCritical, captureError } from '../utils/rollbar'
import { useNotifications } from './useNotifications'

const useCustomMutation = ({
  mutation,
  mutationOptions = {},
  onCompleted,
  rollbarOptions = {},
  customErrorHandling,
}) => {
  const rollbar = useRollbar()
  const { newNotification } = useNotifications()

  const [mutate, { loading }] = useMutation(mutation, {
    fetchPolicy: 'no-cache',
    ...mutationOptions,
    context: {
      rollbar,
      rollbarOptions,
    },
    onCompleted: (response) => {
      if (!response) {
        return
      }

      try {
        onCompleted?.(response)

        const responseEntity = Object.values(response)[0]

        if (responseEntity?.errors?.length) {
          if (customErrorHandling) {
            customErrorHandling?.(responseEntity.errors)
            return
          }
          responseEntity.errors.forEach((currentError) =>
            captureError(rollbar, currentError, {
              target: 'useCustomMutation',
              operationVariables: JSON.stringify(response.variables || {}),
              ...rollbarOptions,
            }),
          )
          handleError(responseEntity.errors, newNotification)
        }
      } catch (error) {
        captureCritical(rollbar, error, {
          target: 'useCustomMutation',
          operationVariables: JSON.stringify(response.variables || {}),
          ...rollbarOptions,
        })
      }
    },
  })

  return [mutate, { loading }]
}

export { useCustomMutation }

import { getValuesString } from './getValuesString'

export const getQueryString = (filters) => {
  return Object.keys(filters || {}).reduce((string, key) => {
    const filterString = `${key}.${getValuesString(filters[key])}`

    string += string ? `&&${filterString}` : filterString
    return string
  }, '')
}

import React, { useCallback, useMemo } from 'react'
import PT from 'prop-types'
import { Form, Field } from 'react-final-form'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text } from '../../../ui-kit'
import { CreateOrUpdateContractPreference } from '../../../queries/mutations/createOrUpdateContractPreference.gql'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import colors from '../../../ui-kit/colors'
import { ContractQuery } from '../../../queries/contracts.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const ContractPreferencesForm = ({
  setIsOpenedModal,
  contractId,
  isFormDirty,
  setDirtyFormState,
  requestClose,
  closeForm,
  contractPreference,
}) => {
  const initialValues = useMemo(
    () => ({
      email: contractPreference?.email || false,
      text: contractPreference?.text || false,
      phone: contractPreference?.phone || false,
      monthlyStatement: contractPreference?.monthlyStatement || false,
      specialInstructions: contractPreference?.specialInstructions || '',
    }),
    [contractPreference],
  )
  const { t } = useTranslation()
  const { newNotification } = useNotifications()
  const onCompleted = useCallback(() => {
    setIsOpenedModal(false)
  }, [])
  const [createOrUpdateContractPreference, { loading }] = useCustomMutation({
    mutation: CreateOrUpdateContractPreference,
    onCompleted,
    rollbarOptions: {
      operationName: 'CreateOrUpdateContractPreference',
      target: 'ContractPreferencesForm',
    },
    mutationOptions: {
      refetchQueries: [ContractQuery],
    },
  })

  const handleSubmit = (values) => {
    createOrUpdateContractPreference({
      variables: {
        contractId,
        data: {
          email: values.email,
          text: values.text,
          phone: values.phone,
          monthlyStatement: values.monthlyStatement,
          specialInstructions: values.specialInstructions || '',
        },
      },
    }).then(({ data }) => {
      const responseData = data?.createOrUpdateContractPreference || {}

      if (responseData?.entity) {
        newNotification({ success: t('userPreferencesUpdatedSuccessfully') })
        setIsOpenedModal(false)
      }
    })
  }

  return (
    <div className={'w-[600px]'}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, dirty, touched }) => {
          useBeforeUnload({ when: touched && dirty })
          if (touched && dirty !== isFormDirty) {
            setDirtyFormState(dirty)
          }
          return (
            <form onSubmit={handleSubmit}>
              <Flex className="pt-4">
                <Text> {t('communicationPreference')} </Text>
              </Flex>
              <Flex className="pb-4">
                <Text color={colors.GREY}> {t('communicationPreferenceNote')} </Text>
              </Flex>
              <Field name={'email'}>
                {({ input }) => {
                  return (
                    <Flex>
                      <Toggle handleChange={input.onChange} value={input.value} />
                      <Text className="pb-4 pl-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
                        {t('email')}
                      </Text>
                    </Flex>
                  )
                }}
              </Field>
              <Field name={'text'}>
                {({ input }) => {
                  return (
                    <Flex>
                      <Toggle handleChange={input.onChange} value={input.value} />
                      <Text className="pb-4 pl-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
                        {t('text')}
                      </Text>
                    </Flex>
                  )
                }}
              </Field>
              <Field name={'phone'}>
                {({ input }) => {
                  return (
                    <Flex>
                      <Toggle handleChange={input.onChange} value={input.value} />
                      <Text className="pb-4 pl-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
                        {t('phone')}
                      </Text>
                    </Flex>
                  )
                }}
              </Field>
              <Field name={'monthlyStatement'}>
                {({ input }) => {
                  return (
                    <Flex>
                      <Toggle handleChange={input.onChange} value={input.value} />
                      <Text className="pb-4 pl-4" fontWeight={fontWeight.SEMIBOLD} size={sizes.SM}>
                        {t('mailMonthlyStatement')}
                      </Text>
                    </Flex>
                  )
                }}
              </Field>
              <Field name={'specialInstructions'}>
                {({ input }) => {
                  return (
                    <Textarea
                      id={input.name}
                      inputClassName={'h-44'}
                      label={t('specialInstructions')}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )
                }}
              </Field>
              <Flex className="w-full mt-6" justifyContent="end">
                <Button
                  className="mr-2"
                  label={t('close')}
                  onClick={requestClose}
                  variant={'tertiary'}
                />
                <Button disabled={loading} label={t('save')} type={'submit'} />
              </Flex>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
      />
    </div>
  )
}

export default ContractPreferencesForm

ContractPreferencesForm.propTypes = {
  setIsOpenedModal: PT.func.isRequired,
  contractId: PT.string.isRequired,
  isFormDirty: PT.bool.isRequired,
  setDirtyFormState: PT.func.isRequired,
  requestClose: PT.func.isRequired,
  closeForm: PT.func.isRequired,
  contractPreference: PT.shape({
    email: PT.bool.isRequired,
    text: PT.bool.isRequired,
    phone: PT.bool.isRequired,
    monthlyStatement: PT.bool,
    specialInstructions: PT.string,
  }),
}

import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import PT from 'prop-types'
import { Button, Flex, Icon, Text, Dialog } from '../../../ui-kit'
import colors from '../../../ui-kit/colors'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import sizes from '../../../ui-kit/sizes'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { getModelsByIds } from '../../../utils/utils'
import getPresentationName, { requestSources } from '../../../utils/getPresentationName'
import { Form } from 'react-final-form'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { BuyerUsersForRequestsQuery } from '../../../queriesUpdated/queries/buyer.gql'
import EmailForm from '../../invoices/requestPayment/EmailForm'
import TextForm from '../../invoices/requestPayment/TextForm'

const ResendPaymentReceiptForm = ({
  isOpened,
  setIsOpened,
  buyerName,
  onSubmit,
  buyerId,
  isLoading,
  paymentId,
}) => {
  const { t } = useTranslation()

  const buyerVariables = {
    id: buyerId,
    withAddresses: false,
  }

  const { data } = useCustomQuery({
    query: BuyerUsersForRequestsQuery,
    queryOptions: {
      variables: buyerVariables,
      skip: !buyerId,
    },
    rollbarOptions: { operationName: 'BuyerUsersForRequestsQuery', target: 'RequestPaymentModal' },
  })
  const users = useMemo(
    () => data?.buyer?.users?.filter((user) => user.companyRole !== 'view_only') || [],
    [data],
  )

  const closeForm = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const [listOfRecipients, setListOfRecipients] = useState([])
  const [guestEmails, setGuestEmails] = useState([])
  const [guestPhones, setGuestPhones] = useState([])
  const [recipientsFullListValues, setRecipientsFullListValues] = useState([])
  const [guestEmailModalIsOpened, setGuestEmailModalIsOpened] = useState(false)
  const [guestPhoneModalIsOpened, setGuestPhoneModalIsOpened] = useState(false)

  const { requestClose } = useDirtyFormAlert({
    closeForm,
  })
  const onChangeRecipients = (e) => {
    setListOfRecipients(e.target.value)
    recipientsRef.current.handleExternalClose()
  }

  useEffect(() => {
    const emails = listOfRecipients
      .filter((value) => value && value.indexOf('email') > -1)
      .map((value) => value.replace('/email', ''))

    const selectedUserEmails = getModelsByIds(users, emails)
    const presentationValuesEmails = selectedUserEmails.map((user) => ({
      presentedName: getPresentationName({ user }, t, requestSources.EMAIL),
      sourceEmail: user.email,
      id: `${user.id}/email`,
    }))
    presentationValuesEmails.push(
      ...guestEmails.map((user) => ({
        presentedName: t('guestUser'),
        sourceEmail: user.email,
        id: user.email,
      })),
    )
    setRecipientsFullListValues([...presentationValuesEmails])
  }, [listOfRecipients, guestEmails, guestPhones])
  const onEmailClick = useCallback(() => {
    setGuestEmailModalIsOpened(true)
  }, [])

  const getRecipientsOptions = useCallback(() => {
    const emails = users.reduce((acc, user) => {
      if (!user.email) {
        return acc
      }

      acc.push({
        label: () => (
          <div className="text-sm ml-6">
            {getPresentationName({ user }, t, requestSources.EMAIL)}
          </div>
        ),
        value: `${user.id}/email`,
      })
      return acc
    }, [])

    emails.push({
      label: () => (
        <div
          className={`${colors.BLUE} capitalize text-sm w-[30rem] mb-2 ml-6`}
          onClick={onEmailClick}>
          {`+ ${t('addEmail')}`}
        </div>
      ),
      value: 'addEmail',
    })

    const recipients = []

    if (emails.length) {
      recipients.push({
        label: t('email'),
        options: emails,
      })
    }

    return recipients
  }, [t, users, onEmailClick])

  const handleAddNewEmail = (values) => {
    const copyGuestEmails = [...guestEmails]
    copyGuestEmails.push(values)
    setGuestEmails(copyGuestEmails)
    setGuestEmailModalIsOpened(false)
  }

  const handleAddNewPhone = (values) => {
    const copyGuestPhones = [...guestPhones]
    copyGuestPhones.push(values)
    setGuestPhones(copyGuestPhones)
    setGuestPhoneModalIsOpened(false)
  }

  const recipientsRef = useRef(null)

  const handleSubmitRequestPayment = (values) => {
    onSubmit(recipientsFullListValues, values)
  }

  const handleDelete = (item) => {
    const copyRecipients = listOfRecipients.filter((recipient) => recipient !== item.id)
    setListOfRecipients(copyRecipients)
    const copyGuestEmails = guestEmails.filter((recipient) => recipient.email !== item.id)
    setGuestEmails(copyGuestEmails)
    const copyGuestPhones = guestPhones.filter((recipient) => recipient.phone !== item.id)
    setGuestPhones(copyGuestPhones)
  }

  return (
    <>
      <Dialog
        isOpened={isOpened}
        setIsOpened={requestClose}
        title={t('resendReceipt')}
        titleClass={'font-semibold text-2xl'}>
        <div className="w-[40rem] px-2">
          <h4 className="text-sm font-medium mt-2 mb-6">
            {buyerName} | {t('paymentId')}: {paymentId}
          </h4>

          <Form
            initialValues={{}}
            onSubmit={handleSubmitRequestPayment}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <NDropdown
                    className="mt-6"
                    inputClassName="w-full"
                    label={t('addRecipients')}
                    listClass="max-h-40"
                    onChange={onChangeRecipients}
                    options={getRecipientsOptions()}
                    placeholder={t('addRecipients')}
                    ref={recipientsRef}
                    value={listOfRecipients.filter(
                      (value) => value !== 'addEmail' && value !== 'addMobilePhone',
                    )}
                    isHiddenInputValue
                    isMultipleSelect
                    withCheckmarks
                  />
                  <Flex className="w-full pt-2">
                    <div className="w-full">
                      <Text>{t('sendTo')}</Text>
                      <div className="w-full border mt-1 rounded-md min-h-[8rem] py-2 px-2">
                        {!recipientsFullListValues.length && (
                          <Text color="text-black-500">{t('addRecipients')}</Text>
                        )}
                        {!!recipientsFullListValues.length &&
                          recipientsFullListValues.map((item) => (
                            <Flex justifyContent="between mb-1" key={item.email}>
                              <Text className="pr-1">{item.presentedName}</Text>
                              <span>
                                {!!item.sourceEmail && (
                                  <a
                                    className={'text-blue-500 hover:text-blue-500'}
                                    href={`mailto:${item.sourceEmail}`}>
                                    {item.sourceEmail}
                                  </a>
                                )}
                                {!!item.sourcePhone && (
                                  <a
                                    className={'text-blue-500 hover:text-blue-500'}
                                    href={`tel:${item.sourcePhone}`}>
                                    {item.sourcePhone}
                                  </a>
                                )}
                                <Icon
                                  className="w-8 inline"
                                  color="text-warmBlack-400"
                                  name="trash"
                                  onClick={() => handleDelete(item)}
                                  type="outline"
                                />
                              </span>
                            </Flex>
                          ))}
                      </div>
                    </div>
                  </Flex>

                  <div className={'w-full flex justify-end mt-6'}>
                    <Button
                      className={'mr-2'}
                      label={t('cancel')}
                      onClick={() => setIsOpened(false)}
                      size={sizes.SM}
                      testData="cancel-request-payment"
                      variant={buttonsVariants.TERTIARY}
                    />
                    <Button
                      className={'mr-2 px-4'}
                      disabled={isLoading || !recipientsFullListValues.length}
                      label={t('resendReceipt')}
                      size={sizes.SM}
                      testData="submit-request-payment"
                      type="submit"
                    />
                  </div>
                </form>
              )
            }}
          />
        </div>
      </Dialog>

      <Dialog
        isOpened={guestEmailModalIsOpened}
        setIsOpened={setGuestEmailModalIsOpened}
        isInner
        shouldCloseOnBackdropClick>
        <EmailForm handleSubmitForm={handleAddNewEmail} />
      </Dialog>

      <Dialog
        isOpened={guestPhoneModalIsOpened}
        setIsOpened={setGuestPhoneModalIsOpened}
        isInner
        shouldCloseOnBackdropClick>
        <TextForm handleSubmitForm={handleAddNewPhone} />
      </Dialog>
    </>
  )
}

export default ResendPaymentReceiptForm

ResendPaymentReceiptForm.propTypes = {
  buyerId: PT.string,
  paymentId: PT.string,
  isOpened: PT.bool.isRequired,
  onSubmit: PT.func.isRequired,
  buyerName: PT.string,
  isLoading: PT.bool,
  setIsOpened: PT.func.isRequired,
}

ResendPaymentReceiptForm.defaultProps = {
  buyerName: undefined,
  isLoading: false,
}

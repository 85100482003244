import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Button } from '../../../ui-kit'
import { validateRequiredField } from '../../../utils/validators'
import { useTranslation } from 'react-i18next'
import { reasonList } from './reasonList'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import { CreateDisputeMutation } from '../../../queriesUpdated/mutations/createDispute.gql'
import { InvoicesOutstandingIndexQuery } from '../../../queriesUpdated/queries/invoiceOutstanding.gql'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const MarkAsDisputeForm = ({
  setIsOpenedModal,
  invoiceId,
  closeForm,
  isFormDirty,
  setDirtyFormState,
  requestClose,
}) => {
  const { t } = useTranslation()

  const onCompleted = useCallback(() => {
    setIsOpenedModal(false)
  }, [])
  const [createDispute, { loading: requestInProgress }] = useCustomMutation({
    mutation: CreateDisputeMutation,
    onCompleted,
    rollbarOptions: { operationName: 'CreateDisputeMutation', target: 'MarkAsDisputeForm' },
    mutationOptions: {
      refetchQueries: [InvoicesOutstandingIndexQuery],
    },
  })

  const onSubmit = (values) => {
    const variables = {
      data: {
        ...values,
        invoiceId,
      },
    }

    createDispute({ variables })
  }

  const initialValues = {
    reason: '',
    details: '',
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, values }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return (
          <form className={'flex flex-col pr-2'} onSubmit={handleSubmit}>
            <div className="w-full">
              <Field name="reason">
                {({ input, meta }) => {
                  const [hasValue, setHasValue] = useState(input.value !== '')
                  return (
                    <NDropdown
                      errorMessage={
                        !hasValue && meta.error && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      inputClassName="w-full"
                      label={t('reason')}
                      listClass="max-h-40 p-1 pt-0 pb-0"
                      name={input.name}
                      onBlur={() => {
                        setHasValue(input.value !== '')
                      }}
                      onChange={input.onChange}
                      onFocus={() => {
                        setHasValue(true)
                      }}
                      options={reasonList}
                      placeholder={t('reason')}
                      testData="reasons"
                      value={input.value}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="details">
                {({ input, meta }) => {
                  return (
                    <Textarea
                      errorMessage={
                        meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                      }
                      id={input.name}
                      inputClassName="h-44"
                      label={t('disputeDetails')}
                      placeholder={t('disputeDetails')}
                      testData="dispute-details"
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6 flex flex-row justify-end">
              <Button
                className="mr-2"
                label={t('cancel')}
                onClick={requestClose}
                testData="cancel-mark-dispute"
                variant={buttonsVariants.TERTIARY}
              />
              <Button
                disabled={!values.reason || requestInProgress}
                label={t('createDispute')}
                testData="submit-dispute"
                type="submit"
              />
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => ({
        reason: validateRequiredField(values.reason),
        details: validateRequiredField(values.details),
      })}
    />
  )
}

MarkAsDisputeForm.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  setIsOpenedModal: PropTypes.func.isRequired,
  setDirtyFormState: PropTypes.func.isRequired,
}

export default MarkAsDisputeForm

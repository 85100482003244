import React, { useEffect, createRef, useState, useMemo } from 'react'
import { Button, Flex, Icon, LoadingSpinner, Text, Tooltip } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import { ErpMetadata } from '../../queries/vendors.gql'
import { CreateErpFileImport } from '../../queries/mutations/createErpFileImport.gql'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { useNotifications } from '../../hooks/useNotifications'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import colors from '../../ui-kit/colors'
export const maxAllowedMb = 100

export const getUploadedFiles = (e) => {
  let uploadedFiles = []

  if (e.target.files) {
    uploadedFiles = Array.from(e.target.files || [])
  } else if (e.dataTransfer.files) {
    uploadedFiles = Array.from(e.dataTransfer.files)
  }

  if (!uploadedFiles.length) {
    e.preventDefault()
    return
  }

  return uploadedFiles
}

export const validateAllowedExtensions = (allowedExtensions, fileName) => {
  if (allowedExtensions) {
    const parsedFileName = fileName.split('.')
    const fileExtension = parsedFileName[parsedFileName.length - 1]
      ? `.${parsedFileName[parsedFileName.length - 1]}`
      : ''

    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      return false
    }
  }

  return true
}

export const validateMaxAllowedFileSize = (fileSize) => {
  const maxAllowedSize = maxAllowedMb * 1024 * 1024

  if (fileSize > maxAllowedSize) {
    return false
  }

  return true
}

const ErpFilesImport = () => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { newNotification } = useNotifications()
  const [importFiles, setImportFiles] = useState({})
  const [requiredFiles, setRequiredFiles] = useState({})
  const [refreshCounter, setRefreshCounter] = useState(1)
  const [importInProgress, setImportInProgress] = useState(false)

  useEffect(() => {
    setBreadcrumbs([
      { label: t('settings'), link: '/settings' },
      { label: t('importFiles'), link: '/settings/erp_files' },
      { label: t('import'), link: '/settings/erp_files/import' },
    ])
  }, [])

  const { data, loading, refetch } = useCustomQuery({
    query: ErpMetadata,
    rollbarOptions: { operationName: 'ErpMetadataQuery', target: 'ErpFilesImport' },
  })
  const importSettings =
    data?.currentVendor?.erpMetadata?.data?.settings?.general?.manual_import_files || []

  const inputRefs = useMemo(
    () =>
      importSettings.reduce(
        (acc, config) => ({
          ...acc,
          [config.label]: createRef(),
        }),
        {},
      ),
    [importSettings],
  )

  const [createErpFileImport, { loading: isCreateErpFileImportLoading }] = useCustomMutation({
    mutation: CreateErpFileImport,
    rollbarOptions: {
      operationName: 'CreateErpFileImport',
      target: 'ErpFilesImport',
    },
  })

  const readFiles = (e, label, extension) => {
    const uploadedFiles = getUploadedFiles(e)

    if (!uploadedFiles) {
      return
    }

    const file = uploadedFiles[0]

    if (!validateAllowedExtensions([extension], file.name)) {
      newNotification({ error: t('uploadedFileUnsupportedFormat', { extensions: extension }) })
      return
    }

    if (!validateMaxAllowedFileSize(file.size)) {
      newNotification({ error: t('uploadedFileExceedsSizeLimit', { size: maxAllowedMb }) })
      return
    }

    if (inputRefs[label]?.current) {
      inputRefs[label].current.value = ''
    }

    setImportFiles((prevState) => ({
      ...prevState,
      [label]: file,
    }))
  }

  const handleCancelClick = () => {
    setImportFiles({})
  }

  const handleSubmitClick = () => {
    const data = Object.keys(importFiles).map((label) => ({
      label,
      file: importFiles[label],
    }))
    createErpFileImport({
      variables: { data },
    }).then(({ data }) => {
      const responseData = data?.createErpFileImport || {}
      setRefreshCounter(1)
      setImportInProgress(true)
      if (responseData?.entity) {
        newNotification({ success: t('erpFilesImportWasSuccessful') })
        setImportFiles({})
      }
    })
  }
  const handleDelete = (fileLabel) => {
    const currentFiles = { ...importFiles }
    delete currentFiles[fileLabel]
    setImportFiles(currentFiles)
  }

  useEffect(() => {
    const requiredFiles = Object.keys(importFiles).reduce((acc, file) => {
      const fileRequires = importSettings.find((item) => item.label === file)?.requires || []
      fileRequires.forEach((requiredFile) => {
        if (!importFiles[requiredFile]) {
          acc[requiredFile] = true
        }
      })
      return acc
    }, {})
    setRequiredFiles(requiredFiles)
  }, [importFiles])

  useEffect(async () => {
    if (refreshCounter === 0) {
      return
    }

    const { data: loadedData } = await refetch()

    const importInProgress = !!loadedData?.erpRequests?.data?.length

    setImportInProgress(importInProgress)

    if (!importInProgress) {
      setRefreshCounter(0)
      return
    }

    const timeoutId = setTimeout(() => {
      setRefreshCounter((prevState) => prevState + 1)
    }, 10000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [refreshCounter])

  return (
    <Flex alignItems="center" column>
      {loading && <LoadingSpinner loading />}
      {!loading && (
        <>
          {importSettings.map((fileConfig, index) => (
            <Flex
              alignItems="center"
              className={index ? 'w-full py-9 border-t' : 'w-full py-9'}
              justifyContent="between"
              key={fileConfig.label}>
              <div className="w-1/2 pl-4">
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.LG}>
                  {fileConfig.label}
                </Text>
                {requiredFiles[fileConfig.label] && (
                  <Text className="pl-2" color={colors.RED}>
                    ({t('fileRequired')})
                  </Text>
                )}
              </div>
              <Flex alignItems="end" className="w-1/2">
                <input
                  accept={fileConfig.extension}
                  onChange={(e) => readFiles(e, fileConfig.label, fileConfig.extension)}
                  ref={inputRefs[fileConfig.label]}
                  style={{ display: 'none' }}
                  type="file"
                />
                {importFiles[fileConfig.label] && (
                  <Text className="pr-5">{importFiles[fileConfig.label].name}</Text>
                )}
                {importFiles[fileConfig.label] && (
                  <Icon
                    className="self-center"
                    color={colors.GREY}
                    name="trash"
                    onClick={() => handleDelete(fileConfig.label)}
                    size={sizes.LG}
                  />
                )}
                {!importFiles[fileConfig.label] && (
                  <Tooltip
                    content={importInProgress ? t('importInProgress') : null}
                    placement="top">
                    <Button
                      disabled={isCreateErpFileImportLoading || importInProgress}
                      label={t('selectFile', {
                        label: fileConfig.label,
                      })}
                      onClick={() => inputRefs[fileConfig.label]?.current?.click()}
                      variant={buttonsVariants.SECONDARY}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          ))}
          <Flex className="w-full" justifyContent="end">
            <Button
              className="mr-5"
              disabled={Object.keys(importFiles).length === 0}
              label={t('cancel')}
              onClick={handleCancelClick}
              variant={buttonsVariants.SECONDARY}
            />
            <Tooltip content={importInProgress ? t('importInProgress') : null} placement="top">
              <Button
                disabled={
                  isCreateErpFileImportLoading ||
                  importInProgress ||
                  !!Object.keys(requiredFiles).length ||
                  !Object.keys(importFiles).length
                }
                label={t('submit')}
                onClick={handleSubmitClick}
              />
            </Tooltip>
          </Flex>
          <Flex className="w-full pt-2" justifyContent="end">
            <Text color="text-warmBlack-400" size={sizes.SM}>
              {t('importWarningMsg')}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  )
}

export default ErpFilesImport

export const getErpMetadata = (vendorData) => {
  const settings = vendorData?.data?.currentVendor?.erpMetadata?.data?.settings
  const entities = settings?.entities
  const general = settings?.general
  const depositReturnsEnabled = entities?.deposit_returns?.enabled
  const erpExportDownloadsEnabled = general?.show_erp_files?.enabled
  const erpSyncButtonEnabled = general?.show_erp_sync_btn?.enabled || false
  const partialCreditApplicationEnabled =
    !entities?.payments?.push?.disable_partial_credit_application?.enabled
  const supportDeposits = entities?.deposits?.ui?.create?.enabled
  const showErpSyncErrors = general?.show_erp_sync_errors?.enabled
  const vendorSyncDepositReturns = entities?.deposit_returns?.push?.enabled
  const zeroNetPaymentAllowed = !entities?.payments?.push?.disable_zero_net?.enabled
  const isSupportPartialDiscount = !entities?.payments?.early_payment_discount_readonly?.enabled

  return {
    depositReturnsEnabled,
    erpExportDownloadsEnabled,
    erpSyncButtonEnabled,
    partialCreditApplicationEnabled,
    supportDeposits,
    showErpSyncErrors,
    vendorSyncDepositReturns,
    zeroNetPaymentAllowed,
    isSupportPartialDiscount,
  }
}

import React, { useCallback, useEffect, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import NDropdown from '../../ui-kit/components/dropdown/NDropdown'
import { paymentMethodTypes } from '../../constants/paymentResults'

const FinancialEntity = ({
  options,
  selected,
  setSelected,
  disabled,
  selectedPaymentMethod,
  skipSettingDefaultValue,
  withSingleOptionAutoSelection,
}) => {
  const { t } = useTranslation()
  const [isDisabled, setIsDisabled] = useState(false)

  const onSelectChange = useCallback((e) => {
    if (!e.target.value) {
      return
    }

    setSelected(e.target.value)
  }, [])

  useEffect(() => {
    if (!selectedPaymentMethod) {
      return
    }

    if (!skipSettingDefaultValue) {
      const defaultOption = options.find((option) => option.isDefault)
      setSelected(defaultOption?.value || null)
    }

    if (selectedPaymentMethod.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [selectedPaymentMethod, options])

  return (
    <div className="w-full">
      <NDropdown
        isDisabled={disabled || isDisabled}
        label={t('depositTo')}
        name={'financialEntity'}
        onChange={onSelectChange}
        options={options}
        placeholder={t('selectBankAccount')}
        testData="financial-entity"
        value={selected}
        withSingleOptionAutoSelection={withSingleOptionAutoSelection}
      />
    </div>
  )
}

export default FinancialEntity

FinancialEntity.propTypes = {
  options: PT.arrayOf(
    PT.shape({
      value: PT.string,
      label: PT.string,
    }),
  ),
  selected: PT.string,
  disabled: PT.bool,
  setSelected: PT.func,
  selectedPaymentMethod: PT.object,
  skipSettingDefaultValue: PT.bool,
  withSingleOptionAutoSelection: PT.bool,
}
FinancialEntity.defaultProps = {
  options: [],
  disabled: false,
  setSelected: () => {},
  withSingleOptionAutoSelection: false,
}

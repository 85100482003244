import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import DepositReturnForm from './DepositReturnForm'
import { Dialog } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'

const DepositReturnModal = ({ data, isOpened, setIsOpened, reopenSidebar }) => {
  const { t } = useTranslation()
  const closeForm = useCallback(() => {
    setIsOpened(false)
    reopenSidebar()
  }, [setIsOpened, reopenSidebar])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('returnDeposit')}
      titleClass="text-2xl font-semibold pl-2">
      <DepositReturnForm
        closeForm={closeForm}
        data={data}
        isFormDirty={isFormDirty}
        requestClose={requestClose}
        setDirtyFormState={setDirtyFormState}
        setIsOpenedModal={setIsOpened}
      />
    </Dialog>
  )
}

DepositReturnModal.propTypes = {
  data: PropTypes.any,
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  reopenSidebar: PropTypes.func.isRequired,
}

export default DepositReturnModal

import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Text, Flex } from '../../ui-kit'
import WarningDialog from '../../ui-kit/components/dialog/WarningDialog'

const transactionBusinessErrors = {
  transactionNotVoidable: 'transaction_not_voidable',
  transactionNotYetVoidable: 'transaction_not_yet_voidable',
}
const VoidErrorMessageDialog = ({
  voidErrorCode,
  isVoidModalOpened,
  setIsVoidModalOpened,
  onModalClose,
}) => {
  const { t } = useTranslation()
  return (
    <WarningDialog
      buttonLabel="OK"
      isOpened={isVoidModalOpened && voidErrorCode}
      onModalClose={onModalClose}
      setIsOpened={setIsVoidModalOpened}
      title={
        voidErrorCode === transactionBusinessErrors.transactionNotVoidable
          ? t('paymentNoLongerVoidable')
          : ''
      }>
      <>
        {voidErrorCode === transactionBusinessErrors.transactionNotVoidable && (
          <Flex className="w-[28rem] pt-2" column>
            <Text className="pt-2">{t('nonVoidableErrorText1')}</Text>
            <Text className="pt-2">{t('nonVoidableErrorText2')}</Text>
            <Text className="pt-2">{t('nonVoidableErrorText3')}</Text>
            <ul className="pt-2 pl-2">
              <li>
                <Text>&#x2022; 9:00am ET</Text>
              </li>
              <li>
                <Text>&#x2022; 11:15am ET</Text>
              </li>
              <li>
                <Text>&#x2022; 2:15pm ET</Text>
              </li>
              <li>
                <Text>&#x2022; 7:00pm ET</Text>
              </li>
            </ul>
            <Text className="pt-4">{t('nonVoidableErrorText4')}</Text>
          </Flex>
        )}
        {voidErrorCode === transactionBusinessErrors.transactionNotYetVoidable && (
          <Flex className="w-[28rem] pt-2" column>
            <Text className="pt-2">{t('nonVoidableYetErrorText')}</Text>
          </Flex>
        )}
      </>
    </WarningDialog>
  )
}
VoidErrorMessageDialog.propTypes = {
  voidErrorCode: PT.string,
  isVoidModalOpened: PT.bool,
  setIsVoidModalOpened: PT.func,
  onModalClose: PT.func,
}

export default VoidErrorMessageDialog

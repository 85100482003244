import { useDispatch, useSelector } from 'react-redux'
import { selectFilters } from '../store/selectors/filters'
import { showFiltersAction, hideFiltersAction } from '../store/actions/filters'

export const useFilters = () => {
  const dispatch = useDispatch()
  const isShowFilters = useSelector(selectFilters)
  const showFilters = () => {
    dispatch(showFiltersAction())
  }
  const hideFilters = () => {
    dispatch(hideFiltersAction())
  }
  return { showFilters, hideFilters, isShowFilters }
}

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { map } from 'lodash'
import PT from 'prop-types'
import { Flex, Icon, Text } from '../../index'
import colors from '../../colors'
import sizes from '../../sizes'

const Breadcrumbs = ({ breadcrumbs }) => {
  const renderBreadcrumbs = () =>
    map(breadcrumbs, (breadcrumb, index) => {
      if (breadcrumb) {
        const isSingleBreadcrumb = breadcrumbs.length === 1
        const isLastBreadcrumb = index === breadcrumbs.length - 1

        if (!isSingleBreadcrumb && isLastBreadcrumb) {
          return (
            <Text color={colors.BLACK} key={index} size={sizes.SM}>
              {breadcrumb.label}
            </Text>
          )
        }

        if (breadcrumb?.link) {
          return (
            <Fragment key={index}>
              <Link
                className={`${
                  isSingleBreadcrumb ? colors.GRAY_500 : colors.GREY
                } hover:text-primary-700`}
                to={breadcrumb.link}>
                {breadcrumb.label}
              </Link>

              {!isSingleBreadcrumb && (
                <Icon className="mx-4" color={colors.GREY} name="chevronRight" size={sizes.BASE} />
              )}
            </Fragment>
          )
        }

        return (
          <Fragment key={index}>
            <Text color={isSingleBreadcrumb ? colors.GRAY_500 : colors.GREY} size={sizes.SM}>
              {breadcrumb.label}
            </Text>

            {!isSingleBreadcrumb && (
              <Icon className="mx-4" color={colors.GREY} name="chevronRight" size={sizes.BASE} />
            )}
          </Fragment>
        )
      }
    })
  return (
    <Flex alignItems="center" justifyContent="center" row>
      {renderBreadcrumbs()}
    </Flex>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: PT.arrayOf(
    PT.shape({
      label: PT.string,
      link: PT.string,
    }),
  ),
}

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
}

export default Breadcrumbs

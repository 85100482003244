import React, { useCallback, useEffect, useState } from 'react'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import { MONEY } from '../../utils/dataTypes'
import StatsFilters from '../../components/StatsFilters'
import { useTranslation } from 'react-i18next'
import Invoices from '../invoices/Invoices'
import { getDisplayStatusValue, getStatisticInvoiceData } from '../contracts/utils'
import { VendorQuery } from '../../queries/vendors.gql'
import { applyStatusFilter } from '../../components/filters/filterUtils'
import { useLocation, useNavigate } from 'react-router-dom'
import { InvoicesAvailableFilters } from '../../queries/invoices/invoicesOutstanding.gql'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { Money } from '../../ui-kit'

const InvoicesPage = () => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [availableFilters, setAvailableFilters] = useState(null)

  useEffect(() => {
    setBreadcrumbs([{ label: t('invoices'), link: '/invoices' }])
  }, [])

  const vendorData = useCustomQuery({
    query: VendorQuery,
    rollbarOptions: { operationName: 'VendorQuery', target: 'InvoicesPage' },
  })

  const { overdueLevelGroups, outstandingAmountCents } = vendorData?.data?.currentVendor || {}

  const onCompleted = useCallback((response) => {
    response?.invoices?.availableFilters && setAvailableFilters(response.invoices.availableFilters)
  }, [])
  useCustomQuery({
    query: InvoicesAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'InvoicesAvailableFilters', target: 'InvoicesPage' },
  })

  const outstandingRetentionAmountCents =
    overdueLevelGroups?.reduce((acc, level) => {
      if (level.retentionAmountCents) {
        acc += level.retentionAmountCents
      }

      return acc
    }, 0) || 0
  const statisticData = [
    ...getStatisticInvoiceData(overdueLevelGroups, t),
    {
      id: 'outstandingAmountCents',
      title: t('totalOutstanding'),
      value: outstandingAmountCents,
      type: MONEY,
      notClickable: true,
      secondaryValue: outstandingRetentionAmountCents ? (
        <Money value={outstandingRetentionAmountCents} />
      ) : null,
      secondaryLabel: outstandingRetentionAmountCents ? t('retention') : null,
    },
  ]
  const onItemClick = useCallback(
    ({ level }) => {
      const levelTitle = getDisplayStatusValue(level?.overdueLevel)
      applyStatusFilter(
        'days_past_due',
        levelTitle,
        availableFilters,
        navigate,
        '/invoices/outstanding',
      )
    },
    [availableFilters, navigate, pathname],
  )

  return (
    <>
      <StatsFilters data={statisticData} onItemClick={onItemClick} />
      <Invoices refetchContract={() => null} isAllContracts />
    </>
  )
}

export default InvoicesPage

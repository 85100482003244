import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Money, Text } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import PropTypes from 'prop-types'
import { statusRefundMapping } from '../../payments/refunds/util'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import ActivityCardStatus from './ActivityCardStatus'
import { getCreatedByEvent } from '../utils'

const RefundActivity = ({ refund }) => {
  const { t } = useTranslation()
  const getPaymentMethodTitle = (refundPayableInvoiceAssignments) => {
    if (!refundPayableInvoiceAssignments) return '-'
    if (refundPayableInvoiceAssignments.length > 1) return 'Multiple'
    else return refundPayableInvoiceAssignments[0].paymentMethod.title
  }
  return (
    <Flex justifyContent="between">
      <Flex>
        <ActivityCardDate date={refund?.createdAt} />
        <ActivityCardGeneralInfo
          detailsValue={getCreatedByEvent(refund)}
          entityId={refund?.entity?.id}
          entityLabelKey="refund"
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="between">
        <Flex>
          <Flex shrink={0}>
            <Flex column>
              <Text className="mt-1" color="text-black-500">
                {t('refundAmount')}:
              </Text>
              <Text className="mt-3" color="text-black-500">
                {t('paymentMethod')}:
              </Text>
            </Flex>

            <Flex className="ml-1" column>
              <Money className="font-semibold text-xl" value={refund?.entity?.amountCents} />
              <Text className="mt-2" size={sizes.SM}>
                {getPaymentMethodTitle(refund?.refundPayableInvoiceAssignments)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <ActivityCardStatus
          lastUpdatesDate={refund.lastUpdateDate}
          statusNode={
            <StatusBadge
              color={statusRefundMapping[refund?.entity?.status]?.color}
              value={t(statusRefundMapping[refund?.entity?.status]?.label)}
            />
          }
        />
      </Flex>
    </Flex>
  )
}

export default RefundActivity
RefundActivity.propTypes = {
  refund: PropTypes.object.isRequired,
}

import React from 'react'
import cx from 'classnames'
import PT from 'prop-types'

const StyledContainer = ({ className, children }) => {
  const sectionClassNames = cx('px-8 py-9 bg-white shadow rounded-lg overflow-y-auto', className)
  return <div className={sectionClassNames}>{children}</div>
}

StyledContainer.propTypes = {
  className: PT.string,
  children: PT.node.isRequired,
}
StyledContainer.defaultProps = {
  className: '',
}
export default StyledContainer

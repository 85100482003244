import appConfig from '../configs/appConfig'
import { useEffect, useState } from 'react'
import { useCurrentUser } from './useCurrentUser'

let isInitialized = false
let isInitializing = false

export const initializeZendesk = (setStates) => {
  if (appConfig.CHAT_SUPPORT === 'true' && !isInitializing && !isInitialized) {
    isInitializing = true
    setStates((prevProps) => ({ ...prevProps, isInitializing: true }))
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.id = 'ze-snippet'
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=5b3a194e-0479-47be-95e1-ab3ab24ef3dc'
    script.onerror = () => {
      isInitialized = false
      isInitializing = false
      setStates({ isInitializing: false, isInitialized: false })
    }
    script.onload = () => {
      isInitialized = true
      isInitializing = false
      setStates({ isInitializing: false, isInitialized: true })
    }
    document.body.appendChild(script)
  } else {
    setStates({ isInitializing, isInitialized })
  }
}

export const useZendesk = () => {
  const currentUser = useCurrentUser()
  const [states, setStates] = useState({ isInitialized: false, isInitializing: true })

  useEffect(() => {
    initializeZendesk(setStates)
  }, [])

  useEffect(() => {
    if (states.isInitializing || !states.isInitialized) {
      return
    }

    try {
      const command = currentUser ? 'show' : 'hide'
      window.zE('messenger', command)
    } catch (error) {
      console.warn(error)
    }
  }, [states, currentUser])
}

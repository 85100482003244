import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { Money, StatusBadge } from '../../ui-kit'
import { camelCase, isEqual, toInteger } from 'lodash'
import { useQueryParams } from '../../hooks/useQueryParams'
import {
  InvoicesOutstandingIndexQuery,
  InvoiceOutstandingSidebarQuery,
  InvoicesSelectAllQuery,
} from '../../queriesUpdated/queries/invoiceOutstanding.gql'
import { InvoicesAvailableFilters } from '../../queriesUpdated/queries/common.gql'
import { ContractQuery } from '../../queries/contracts.gql'
import { ContractsAutocompleteQuery } from '../../queriesUpdated/queries/contract.gql'
import { ProjectsAutocompleteQuery } from '../../queriesUpdated/queries/project.gql'
import { VendorQuery } from '../../queries/vendors.gql'
import {
  getModelsByIds,
  getPaginationData,
  MAX_PER_PAGE,
  parseFilterQuery,
} from '../../utils/utils'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import InvoiceSelected from './InvoiceSelected'
import ReceivePaymentModalNew from './receivePaymentNew/ReceivePaymentModal'
import PT from 'prop-types'
import SidebarInvoicesContent from './sidebar/SidebarInvoicesContent'
import PaymentPlanModal from './paymentPlan/PaymentPlanModal'
import PromiseToPayModal from './p2p/PromiseToPay'
import {
  getCommonColumns,
  getInvoiceBreadcrumbs,
  getLoadingFunc,
  scopeFilterMap,
} from './invoicesUtils'
import RequestPaymentModal from './requestPayment/RequestPaymentModal'
import MenuButton from '../../components/dataGrid/menuButton/MenuButton'
import MarkAsDisputeModal from './markAsDispute/MarkAsDisputeModal'
import { ArchiveDisputeMutation } from '../../queriesUpdated/mutations/archiveDispute.gql'
import { PaymentRequestMutation } from '../../queriesUpdated/mutations/createPaymentRequest.gql'
import { getDisplayStatusValue } from '../contracts/utils'
import { SendInvoicesMutation } from '../../queriesUpdated/mutations/sendInvoices.gql'
import { useNotifications } from '../../hooks/useNotifications'
import { getValidTooltipContent, validateInvoice } from './invoiceActions/InvoiceActions'
import InvoiceStatusIcons from './invoiceActions/InvoiceStatusIcons'
import AssignToProjectModal from './assignToProject/AssignToProjectModal'
import { MONEY } from '../../utils/dataTypes'
import { useOpenEntitySidebar } from '../../hooks/useOpenEntitySidebar'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import InvoiceRefundModal from './invoiceRefund/InvoiceRefundModal'
import RetentionIcon from '../../ui-kit/components/retentionIcon/RetentionIcon'
import { MarkInvoiceRetention } from '../../queriesUpdated/mutations/markInvoiceRetention.gql'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { useCurrentUser } from '../../hooks/useCurrentUser'

const InvoicesOutstanding = ({
  contractData,
  contractId,
  scope,
  refetchContract,
  isAllContracts,
}) => {
  const [invoices, setInvoices] = useState([])
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [currentSelectionModel, setCurrentSelectionModel] = useState([])
  const [previousSelectionModel, setPreviousSelectionModel] = useState([])
  const [isReceiveModalOpened, setIsReceiveModalOpened] = useState(false)
  const [assignToProjectModalOpened, setAssignToProjectModalOpened] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState({})
  const [availableFilters, setAvailableFilters] = useState(null)
  const onCompleted = useCallback(() => {
    setIsSidebarOpened(false)
  }, [])
  const [archiveDispute, { loading: isArchiveDisputeLoading }] = useCustomMutation({
    mutation: ArchiveDisputeMutation,
    onCompleted,
    rollbarOptions: { operationName: 'ArchiveDisputeMutation', target: 'InvoicesOutstanding' },
    mutationOptions: {
      refetchQueries: [InvoicesOutstandingIndexQuery, InvoiceOutstandingSidebarQuery],
    },
  })
  const [createPaymentRequest, { loading: isCreatePaymentRequestLoading }] = useCustomMutation({
    mutation: PaymentRequestMutation,
    rollbarOptions: { operationName: 'PaymentRequestMutation', target: 'InvoicesOutstanding' },
  })

  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const { queryParams } = useQueryParams()
  const defaultSort = 'issue_date.desc'
  const sort = queryParams.sort || defaultSort
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search || null
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)
  const { setBreadcrumbs } = useBreadcrumbs()
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  useEffect(() => {
    setBreadcrumbs([...getInvoiceBreadcrumbs(contractData, t, scope)])
  }, [scope, contractData])

  const filters = [scopeFilterMap[scope]]

  if (contractId) {
    filters.push({
      key: 'contract_id',
      values: [contractId],
    })
  }

  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(
    () => ({
      filters: [...filters, ...userFilters],
      sort,
      page,
      search,
      perPage: pageSize,
    }),
    [filters, userFilters, sort, page, search, pageSize],
  )

  const currentContractData =
    contractData || selectedInvoice?.contract || selectedInvoices[0]?.contract
  const selectedInvoicesContract = contractId || currentContractData?.id
  const customerName = currentContractData?.buyer?.name

  const onCompletedRecords = useCallback(
    (response) => {
      setInvoices(response?.invoices)
      divideSelection(response?.invoices?.data || [])
      resetSidebarData(response?.invoices)
    },
    [selectedInvoice, previousSelectionModel],
  )
  const { loading, refetch } = useCustomQuery({
    query: InvoicesOutstandingIndexQuery,
    onCompleted: onCompletedRecords,
    queryOptions: {
      variables: queryVariables,
      skip: !scope,
    },
    rollbarOptions: {
      operationName: 'InvoicesOutstandingIndexQuery',
      target: 'InvoicesOutstanding',
    },
  })
  const onCompletedFilters = useCallback((response) => {
    response?.invoices?.availableFilters && setAvailableFilters(response.invoices.availableFilters)
  }, [])
  useCustomQuery({
    query: InvoicesAvailableFilters,
    onCompleted: onCompletedFilters,
    rollbarOptions: { operationName: 'InvoicesAvailableFilters', target: 'InvoicesOutstanding' },
  })

  const { data: selectAllData, refetch: refetchInvoicesForSelectAll } = useCustomQuery({
    query: InvoicesSelectAllQuery,
    queryOptions: {
      variables: { perPage: MAX_PER_PAGE, filters: [...filters, ...userFilters] },
      fetchPolicy: 'cache-first',
      skip: !contractId || !selectedInvoices.length,
    },
    rollbarOptions: {
      operationName: 'InvoicesSelectAllQuery all',
      target: 'InvoicesOutstanding',
    },
  })
  const invoicesForSelectAll = selectAllData?.invoices?.data || []
  const { data: selectAllPayableData, refetch: refetchInvoicesForSelectAllPayable } =
    useCustomQuery({
      query: InvoicesSelectAllQuery,
      queryOptions: {
        skip: !contractId || !selectedInvoices.length,
        fetchPolicy: 'cache-first',
        variables: {
          filters: [
            ...filters,
            ...userFilters,
            {
              key: 'eligible_for_payment',
              values: ['true'],
            },
          ],
          perPage: MAX_PER_PAGE,
        },
      },
      rollbarOptions: {
        operationName: 'InvoicesSelectAllQuery payable',
        target: 'InvoicesOutstanding',
      },
    })
  const invoicesForSelectAllPayable = selectAllPayableData?.invoices?.data || []

  const rows = useMemo(() => {
    return invoices?.data || []
  }, [invoices?.data])

  useEffect(() => {
    return function cleanup() {
      setAvailableFilters(null)
    }
  }, [contractId])

  //ToDo: Check why pagination is showing some initial state items before the data is loaded
  const paginationData = getPaginationData(invoices)

  const sideBarHandler = (values) => {
    setSelectedInvoice(values?.row)
    setIsSidebarOpened(true)
  }
  const receivePaymentHandler = (values) => {
    handleClearAll()
    setSelectedInvoice(values.row)
    showReceivePaymentModal()
  }
  const requestPaymentHandler = (values) => {
    handleClearAll()
    setSelectedInvoice(values.row)
    setIsRequestPaymentModalOpened(true)
    isSidebarOpened && setIsSidebarOpened(false)
  }
  const p2pHandler = (values) => {
    handleClearAll()
    setSelectedInvoice(values.row)
    setIsPromiseToPayModalOpened(true)
    isSidebarOpened && setIsSidebarOpened(false)
  }
  const paymentPlanHandler = (values) => {
    handleClearAll()
    setSelectedInvoice(values.row)
    setIsPaymentPlanModalOpened(true)
    isSidebarOpened && setIsSidebarOpened(false)
  }
  const markAsDisputeHandler = (values) => {
    setSelectedInvoice(values.row)
    setIsMarkAsDisputeModalOpened(true)
  }

  const markAsResolvedHandler = (rowData) => {
    if (isArchiveDisputeLoading) {
      return
    }

    setSelectedInvoice(rowData)
    const variables = {
      id: rowData.dispute.id,
    }
    archiveDispute({ variables })
  }

  const refundHandler = (values) => {
    setSelectedInvoice(values.row)
    setIsRefundModalOpened(true)
  }
  const updateInvoices = () => {
    refetch()
    contractId && refetchInvoicesForSelectAllPayable()
    contractId && refetchInvoicesForSelectAll()
    refetchContract()
    setSelectedInvoices([])
  }

  const [setRetention, { loading: isSetRetentionLoading }] = useCustomMutation({
    mutation: MarkInvoiceRetention,
    onCompleted: updateInvoices,
    rollbarOptions: { operationName: 'UnmarkInvoiceRetention', target: 'InvoicesOutstanding' },
    mutationOptions: {
      refetchQueries: [
        InvoicesOutstandingIndexQuery,
        InvoiceOutstandingSidebarQuery,
        ContractQuery,
        VendorQuery,
      ],
    },
  })

  const markRetentionHandler = (values) => {
    if (isSetRetentionLoading) {
      return
    }

    setRetention({
      variables: {
        id: values.row.id,
        retention: !values.row.markedAsRetentionAt,
      },
    })
  }

  const options = (rowData) => {
    const disputeOption = rowData.dispute
      ? [
          {
            testData: 'markAsResolved',
            label: t('markAsResolved'),
            action: () => markAsResolvedHandler(rowData),
          },
        ]
      : [{ testData: 'markAsDisputed', label: t('markAsDisputed'), action: markAsDisputeHandler }]
    const retentionOption = rowData.markedAsRetentionAt
      ? [{ label: t('unmarkAsRetention'), action: markRetentionHandler }]
      : [{ label: t('markAsRetention'), action: markRetentionHandler }]
    const baseAction = [
      { label: t('viewDetails'), action: sideBarHandler },
      { label: t('download'), action: handleDownloadAction },
      {
        label: t('receivePayment'),
        action: receivePaymentHandler,
        testData: 'receivePayment',
        validationError: validateInvoice('receivePayment', [rowData]),
        toolTipContent: getValidTooltipContent('receivePayment', [rowData]),
      },
      {
        label: t('addP2p'),
        action: p2pHandler,
        validationError: validateInvoice('promiseToPay', [rowData]),
        toolTipContent: getValidTooltipContent('promiseToPay', [rowData]),
      },
      {
        label: t('paymentPlan'),
        action: paymentPlanHandler,
        validationError: validateInvoice('paymentPlan', [rowData]),
        toolTipContent: getValidTooltipContent('paymentPlan', [rowData]),
      },
      {
        label: t('requestPayment'),
        action: requestPaymentHandler,
        testData: 'requestPayment',
        validationError: validateInvoice('requestPayment', [rowData]),
        toolTipContent: getValidTooltipContent('requestPayment', [rowData]),
      },
      {
        label: t('assignToProject'),
        action: assignToProjectHandler,
      },
      ...disputeOption,
      ...(currentUser?.membershipRole !== 'counter' ? retentionOption : []),
    ]
    if (rowData.outstandingAmountCents < rowData.amountCents) {
      baseAction.push({
        label: t('issueRefund'),
        action: refundHandler,
      })
    }
    return baseAction
  }

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'InvoicesOutstanding' },
  })

  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const { refetch: refetchProjectsLoadingData } = useCustomQuery({
    query: ProjectsAutocompleteQuery,
    rollbarOptions: { operationName: 'ProjectsAutocompleteQuery', target: 'InvoicesOutstanding' },
  })

  const loadProjectOptions = getLoadingFunc(
    refetchProjectsLoadingData,
    'projectsAutocomplete',
    null,
    {
      contractId,
    },
  )

  const columns = useMemo(
    () => [
      ...getCommonColumns(
        t,
        isAllContracts,
        sideBarHandler,
        additionalFilters,
        {
          loadContractOptions,
          loadProjectOptions,
        },
        selectedInvoices,
      ),
      {
        field: 'outstandingAmountCents',
        headerName: t('outstanding'),
        renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
        filterId: 'outstandingAmount',
        filterDataType: MONEY,
        flex: 10,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'statusIcon',
        filterId: 'hasActionEntity',
        filterTitle: t('actionItem'),
        headerName: '',
        sortable: false,
        renderCell: (values) => <InvoiceStatusIcons model={values?.row} />,
        flex: 2.5,
      },
      {
        field: 'maturityDate',
        filterId: 'maturityDate',
        headerName: t('dueDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.maturityDate} />,
        flex: 10,
      },
      {
        field: 'aging',
        filterId: 'daysPastDue',
        headerName: t('status'),
        renderCell: (values) => (
          <StatusBadge
            color={values?.row?.status}
            iconName="dot"
            value={getDisplayStatusValue(values?.row?.overdueLevel)}
          />
        ),
        flex: 10,
      },
      {
        field: 'markedAsRetentionAt',
        headerName: '',
        sortable: false,
        renderCell: (values) => !!values.row.markedAsRetentionAt && <RetentionIcon />,
        flex: 0.1,
      },
      {
        field: 'actions',
        sortable: false,
        headerName: '',
        renderCell: (values) =>
          !selectedInvoices?.length && (
            <MenuButton options={options(values?.row)} testData="invoice" values={values} />
          ),
        flex: 0.1,
      },
    ],
    [selectedInvoices],
  )

  const setSelectionModel = (ids) => {
    const selected = getModelsByIds(rows, ids)
    setCurrentSelectionModel(selected)
    setSelectedInvoices(previousSelectionModel.concat(selected))
  }

  const handleSelectionModelChange = useCallback(
    (models) => {
      setSelectionModel(models)
    },
    [rows, previousSelectionModel],
  )

  const divideSelection = (rows, model) => {
    const previousSelection = []
    const currentSelection = []
    const selectionModel = model || previousSelectionModel
    selectionModel.forEach((invoice) => {
      const index = rows.findIndex((item) => invoice.id === item.id)
      if (index > -1) {
        currentSelection.push(invoice)
      } else {
        previousSelection.push(invoice)
      }
    })
    setPreviousSelectionModel(previousSelection)
    setCurrentSelectionModel(currentSelection)
  }

  useEffect(() => {
    setPreviousSelectionModel(selectedInvoices)
  }, [contractId, scope, sort, page, search, filtersQuery])

  const showReceivePaymentModal = () => {
    setIsReceiveModalOpened(true)
  }

  const resetSidebarData = (invoices) => {
    if (selectedInvoice && invoices?.data) {
      const selectedInvoiceId = selectedInvoice.id
      const selectedInvoiceLoadedData = invoices.data.find(
        (invoice) => invoice.id === selectedInvoiceId,
      )
      if (selectedInvoiceLoadedData) {
        setSelectedInvoice(selectedInvoiceLoadedData)
        reopenSidebar()
      }
    }
  }

  const hideReceivePaymentModal = () => {
    setIsReceiveModalOpened(false)
  }

  const reloadInvoices = () => {
    hideReceivePaymentModal()
    updateInvoices()
  }

  const handleReceivePaymentAction = () => {
    showReceivePaymentModal()
  }
  // TODO old receive payment flow func
  // eslint-disable-next-line no-unused-vars
  const handleSubmitReceivePayment = () => {
    setIsReceiveModalOpened(false)
    reopenSidebar()
  }

  const [isPaymentPlanModalOpened, setIsPaymentPlanModalOpened] = useState(false)
  const [isPromiseToPayModalOpened, setIsPromiseToPayModalOpened] = useState(false)
  const [isRequestPaymentModalOpened, setIsRequestPaymentModalOpened] = useState(false)
  const [isMarkAsDisputeModalOpened, setIsMarkAsDisputeModalOpened] = useState(false)
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [isRefundModalOpened, setIsRefundModalOpened] = useState(false)

  const reopenSidebar = useCallback(() => {
    !!Object.keys(selectedInvoice).length && setIsSidebarOpened(true)
  }, [selectedInvoice, setIsSidebarOpened])

  const handleP2PAction = () => {
    setIsPromiseToPayModalOpened(true)
    isSidebarOpened && setIsSidebarOpened(false)
  }
  const handleSubmitP2p = () => {
    setIsPromiseToPayModalOpened(false)
    reopenSidebar()
    updateInvoices()
  }

  const handleRequestPaymentAction = () => {
    setIsRequestPaymentModalOpened(true)
    isSidebarOpened && setIsSidebarOpened(false)
  }
  const handleSubmitRequestPayment = (recipientsFullListValues, values) => {
    const recipientsViaEmail = recipientsFullListValues
      .filter((item) => !!item.sourceEmail)
      .map((item) => ({
        ...(item.id && item.id !== item.sourceEmail ? { buyerUserId: item.id.split('/')[0] } : {}),
        email: item.sourceEmail,
      }))
    const recipientsViaText = recipientsFullListValues
      .filter((item) => !!item.sourcePhone)
      .map((item) => ({
        ...(item.id && item.id !== item.sourcePhone ? { buyerUserId: item.id.split('/')[0] } : {}),
        phoneNumber: item.sourcePhone,
      }))

    const { creditsData, depositsData } = values.creditsDeposits.reduce(
      (acc, entity) => {
        if (!entity.isEntityApplied) {
          return acc
        }

        const entityData = {
          id: entity.id,
          appliedAmountCents: entity.appliedAmountCents,
        }

        if (entity.type === 'credit') {
          acc.creditsData.push(entityData)
        } else if (entity.type === 'deposit') {
          acc.depositsData.push(entityData)
        }

        return acc
      },
      { creditsData: [], depositsData: [] },
    )

    const variables = {
      data: {
        contractId: selectedInvoicesContract,
        invoiceIds: invoicesToPay.map((invoice) => invoice.id),
        recipientsViaEmail,
        recipientsViaText,
        attachInvoices: values.attachInvoices,
        selfCopy: false,
        memo: values.memo,
        creditsData,
        depositsData,
      },
    }
    createPaymentRequest({ variables }).then(({ data }) => {
      const responseData = data?.createPaymentRequest || {}

      if (responseData?.entity) {
        newNotification({ success: t('requestSuccessfullySent') })
        reloadInvoices()
      }

      setIsRequestPaymentModalOpened(false)
      reopenSidebar()
    })
  }

  const handlePaymentPlanAction = () => {
    setIsPaymentPlanModalOpened(true)
    isSidebarOpened && setIsSidebarOpened(false)
  }
  const handleSubmitPaymentPlan = () => {
    setIsPaymentPlanModalOpened(false)
    reopenSidebar()
    reloadInvoices()
  }

  const assignToProjectHandler = (values) => {
    handleClearAll()
    setSelectedInvoice(values.row)
    setAssignToProjectModalOpened(true)
  }
  const handleSubmitAssignToProject = () => {
    setAssignToProjectModalOpened(false)
    reloadInvoices()
  }

  const [sendInvoices, { loading: isSendInvoicesLoading }] = useCustomMutation({
    mutation: SendInvoicesMutation,
    rollbarOptions: { operationName: 'SendInvoicesMutation', target: 'InvoicesOutstanding' },
  })
  const { newNotification } = useNotifications()

  const handleDownloadAction = (values) => {
    if (isSendInvoicesLoading) {
      return
    }

    const variables = {
      invoiceIds: values?.row ? [values.row.id] : selectedInvoices.map((inv) => inv.id),
    }
    sendInvoices({ variables }).then(({ data }) => {
      const responseData = data?.sendInvoices || {}

      if (responseData?.entity) {
        newNotification({ success: t('selectedInvoicesSentByEmail') })
      }
    })
  }

  const defaultSortModel = useMemo(() => {
    const sortSource = queryParams?.sort || defaultSort

    const [field, direction] = sortSource.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  useOpenEntitySidebar(rows, setSelectedInvoice, setIsSidebarOpened)

  const selectedContractId = useMemo(
    () => selectedInvoice?.contract?.id || contractId,
    [selectedInvoice, contractId],
  )
  const handleClearAll = () => {
    setSelectedInvoices([])
    setPreviousSelectionModel([])
    setCurrentSelectionModel([])
  }
  const handleSelectAll = () => {
    if (invoicesForSelectAll.length === selectedInvoices.length) {
      return
    }

    setSelectedInvoices(invoicesForSelectAll)
    divideSelection(rows, invoicesForSelectAll)
  }
  const handleSelectAllPayable = () => {
    const payableIds = invoicesForSelectAllPayable.map((inv) => inv.id)
    const selectedIds = selectedInvoices.map((inv) => inv.id)

    if (isEqual(payableIds, selectedIds)) {
      return
    }

    setSelectedInvoices(invoicesForSelectAllPayable)
    divideSelection(rows, invoicesForSelectAllPayable)
  }

  const invoicesToPay = useMemo(() => {
    if (selectedInvoice?.id) {
      return [selectedInvoice]
    }

    if (selectedInvoices?.length) {
      return selectedInvoices
    }

    return []
  }, [selectedInvoices, selectedInvoice])

  return (
    <>
      <Sidebar
        clearSelectedInvoice={() => setSelectedInvoice({})}
        isSidebarOpened={isSidebarOpened}
        setIsSidebarOpened={setIsSidebarOpened}>
        <SidebarInvoicesContent
          contractId={selectedContractId}
          data={selectedInvoice}
          markAsResolvedHandler={() => markAsResolvedHandler(selectedInvoice)}
          setAssignProjectModalOpened={setAssignToProjectModalOpened}
          setIsMarkAsDisputeModalOpened={setIsMarkAsDisputeModalOpened}
          setIsPaymentPlanModalOpened={setIsPaymentPlanModalOpened}
          setIsPromiseToPayModalOpened={setIsPromiseToPayModalOpened}
          setIsReceiveModalOpened={setIsReceiveModalOpened}
          setIsRefundModalOpened={setIsRefundModalOpened}
          setIsRequestPaymentModalOpened={setIsRequestPaymentModalOpened}
          setIsSidebarOpened={setIsSidebarOpened}
          setSelectedInvoice={setSelectedInvoice}
        />
      </Sidebar>

      <div className={cx('flex pt-4', !!selectedInvoices.length && 'pb-12')}>
        <DataGridComponent
          availableFilters={availableFilters}
          columns={columns}
          handleSelectionModelChange={handleSelectionModelChange}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rows={rows}
          searchLabel={t('invoices')}
          selectionModel={currentSelectionModel.map((inv) => inv.id)}
          sortModel={defaultSortModel}
          testData="invoices"
          checkboxSelection
        />
      </div>

      <InvoiceSelected
        handleClearAll={handleClearAll}
        handleSelectAll={contractId && handleSelectAll}
        handleSelectAllPayable={contractId && handleSelectAllPayable}
        iconType="solid"
        models={selectedInvoices}
        onDownloadAction={handleDownloadAction}
        onP2PAction={handleP2PAction}
        onPaymentPlanAction={handlePaymentPlanAction}
        onReceivePaymentAction={handleReceivePaymentAction}
        onRequestPaymentAction={handleRequestPaymentAction}
        showPanel={!!selectedInvoices.length}
      />

      {isMarkAsDisputeModalOpened && (
        <MarkAsDisputeModal
          customerName={customerName}
          data={selectedInvoice}
          isOpened={isMarkAsDisputeModalOpened}
          reopenSidebar={reopenSidebar}
          setIsOpened={setIsMarkAsDisputeModalOpened}
        />
      )}

      {isReceiveModalOpened && (
        <ReceivePaymentModalNew
          contractID={selectedInvoicesContract}
          customerName={customerName}
          invoicesIds={invoicesToPay.map((inv) => inv.id)}
          onClose={hideReceivePaymentModal}
          onSuccessSubmit={reloadInvoices}
          setIsOpened={(state) => {
            setIsReceiveModalOpened(state)
            reopenSidebar()
          }}
          sort="maturity_date.asc"
          isOpened
          withPartialPayment
        />
      )}
      {isPromiseToPayModalOpened && (
        <PromiseToPayModal
          invoiceIds={invoicesToPay.map((inv) => inv.id)}
          isOpened={isPromiseToPayModalOpened}
          onSubmit={handleSubmitP2p}
          setIsOpened={(state) => {
            setIsPromiseToPayModalOpened(state)
            reopenSidebar()
          }}
        />
      )}

      {isPaymentPlanModalOpened && (
        <PaymentPlanModal
          invoiceIds={invoicesToPay.map((inv) => inv.id)}
          isOpened={isPaymentPlanModalOpened}
          onClose={() => {
            setIsPaymentPlanModalOpened(false)
            reopenSidebar()
          }}
          onSubmit={handleSubmitPaymentPlan}
          setIsOpened={(state) => {
            setIsPaymentPlanModalOpened(state)
            reopenSidebar()
          }}
        />
      )}

      {isRequestPaymentModalOpened && (
        <RequestPaymentModal
          buyerId={(selectedInvoices[0] || selectedInvoice)?.contract?.buyer?.id}
          buyerName={customerName}
          contractId={selectedInvoicesContract}
          invoicesIds={invoicesToPay.map((inv) => inv.id)}
          isLoading={isCreatePaymentRequestLoading}
          isOpened={isRequestPaymentModalOpened}
          onSubmit={handleSubmitRequestPayment}
          reopenSidebar={reopenSidebar}
          setIsOpened={setIsRequestPaymentModalOpened}
        />
      )}

      {assignToProjectModalOpened && (
        <AssignToProjectModal
          contractId={selectedInvoice?.contract?.id}
          customerName={customerName}
          handleSubmit={handleSubmitAssignToProject}
          invoiceId={selectedInvoice?.id}
          isOpened={assignToProjectModalOpened}
          reopenSidebar={reopenSidebar}
          setIsOpened={setAssignToProjectModalOpened}
        />
      )}

      {setIsRefundModalOpened && (
        <InvoiceRefundModal
          invoiceId={selectedInvoice?.id}
          isOpened={isRefundModalOpened}
          onSuccessSubmit={() => {
            reopenSidebar()
            updateInvoices()
          }}
          reopenSidebar={reopenSidebar}
          setIsOpened={setIsRefundModalOpened}
        />
      )}
    </>
  )
}

InvoicesOutstanding.propTypes = {
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    buyer: PT.shape({
      id: PT.string,
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  contractId: PT.string,
  isAllContracts: PT.bool,
  scope: PT.string.isRequired,
  refetchContract: PT.func,
}
InvoicesOutstanding.defaultProps = {
  contractId: null,
  isAllContracts: false,
  refetchContract: () => {},
}

export default InvoicesOutstanding

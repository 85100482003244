import PT from 'prop-types'

const paymentMethodType = PT.shape({
  value: PT.shape({
    name: PT.string,
    label: PT.string,
    value: PT.string,
  }),
  options: PT.arrayOf(
    PT.shape({
      name: PT.string,
      label: PT.string,
      value: PT.string,
      typeLabel: PT.string,
    }),
  ),
})
export default paymentMethodType

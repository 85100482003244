export const ASSIGNED_REPS_ROLES = [
  {
    label: 'Salesperson',
    value: 'salesperson',
  },
  {
    label: 'Credit Contact',
    value: 'credit_contact',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

import PT from 'prop-types'
import cx from 'classnames'
import { Text } from '../../../ui-kit'

const RadioGroup = ({
  name,
  defaultCheckedValue,
  options,
  onValueChange,
  wrapperClassName,
  itemClassName,
  itemMargin,
  excludedOptions,
}) => {
  return (
    <fieldset>
      <div className={wrapperClassName || 'space-y-5'}>
        {options.map((option, index) => {
          if (excludedOptions.includes(option.id)) {
            return (
              <Text
                align="center"
                className={cx(itemClassName)}
                color="text-black-500"
                key={option.id}>
                {'-'}
              </Text>
            )
          }

          return (
            <div
              className={`relative flex items-start ${itemClassName} ${
                index !== 0 ? itemMargin : ''
              }`}
              key={option.id}>
              <div className="flex items-center h-5">
                <input
                  aria-describedby={`${option.id}-description`}
                  className="form-radio primary focus:outline-none focus:ring-transparent
                h-4 w-4 text-indigo-600 border-gray-300 cursor-pointer"
                  defaultChecked={
                    options.find((option) => defaultCheckedValue === option.value)
                      ? defaultCheckedValue === option.value
                      : index === 0
                  }
                  id={option.id}
                  name={name}
                  onChange={onValueChange}
                  type="radio"
                  value={option.value}
                />
              </div>

              {!!option.label && (
                <div className="ml-3 text-sm">
                  <label className="text-gray-700 cursor-pointer" htmlFor={option.id}>
                    {option.label}
                  </label>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </fieldset>
  )
}

RadioGroup.propTypes = {
  defaultCheckedValue: PT.string,
  itemClassName: PT.string,
  itemMargin: PT.string,
  name: PT.string.isRequired,
  onValueChange: PT.func.isRequired,
  options: PT.arrayOf(
    PT.shape({
      id: PT.string,
      value: PT.oneOfType([PT.string, PT.number]),
      label: PT.string,
    }),
  ).isRequired,
  wrapperClassName: PT.string,
  excludedOptions: PT.array,
}
RadioGroup.defaultProps = {
  defaultCheckedValue: null,
  itemClassName: '',
  itemMargin: '',
  wrapperClassName: '',
  excludedOptions: [],
}

export default RadioGroup

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { getActiveTab, getTabsMap, parseFilterQuery } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import PageHeader from '../../components/headers/PageHeader'
import { Button, Flex, Tabs } from '../../ui-kit'
import ProjectsView from './ProjectsView'
import FiltersControlButton from '../../components/filters/FiltersControlButton'
import NDropdown, { dropdownTypes } from '../../ui-kit/components/dropdown/NDropdown'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { useQueryParams } from '../../hooks/useQueryParams'

const Projects = ({ contractId, contractData }) => {
  const defaultSort = 'outstanding_amount_cents.asc'
  const { t } = useTranslation()
  const location = useLocation()
  const { queryParams, removeQueryParam } = useQueryParams()
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)
  const [activeTab, setActiveTab] = useState('')
  const [sort, setSort] = useState(defaultSort)
  const tabs = ['active', 'inactive']

  const navigate = useNavigate()

  const handleTabClick = (tab) => {
    navigate(`./${tab}`)
    setActiveTab(tab)
    setSort(defaultSort)
  }

  const tabsMap = getTabsMap(tabs, activeTab, handleTabClick, t)

  useEffect(() => {
    setActiveTab(getActiveTab(location, 'projects') || 'active')
  }, [location])

  const availableSorting = useMemo(
    () => [
      {
        label: t('outstandingDescending'),
        value: 'outstanding_amount_cents.desc',
      },
      {
        label: t('outstandingAscending'),
        value: 'outstanding_amount_cents.asc',
      },
      {
        label: t('projectNameAscending'),
        value: 'name.asc',
      },
      {
        label: t('projectNameDescending'),
        value: 'name.desc',
      },
    ],
    [t],
  )

  const handleSortChange = useCallback((e) => setSort(e.target.value), [])

  return (
    <>
      <PageHeader headerTitle={t('projects')} />

      <Flex className="justify-between items-center">
        <Tabs tabs={tabsMap} />
        <Flex alignItems="center" justifyContent="center">
          {!!userFilters.length && (
            <Button
              className="w-44 mr-4 h-[37px] flex items-center justify-center"
              label={t('showAll')}
              onClick={() => removeQueryParam(['filters', 'additionalFilters'])}
              variant={buttonsVariants.SECONDARY}
            />
          )}
          <NDropdown
            buttonClassName="w-44"
            className="pr-14 flex flex-col"
            id="sort"
            label={t('sort')}
            listClass="max-h-40"
            name="sort"
            onChange={handleSortChange}
            options={availableSorting}
            type={dropdownTypes.BUTTON}
            value={sort}
          />
          <FiltersControlButton hideFiltersButton />
        </Flex>
      </Flex>

      <Routes>
        <Route
          element={
            <ProjectsView
              contractData={contractData}
              contractId={contractId}
              scope={activeTab}
              sort={sort}
            />
          }
          path="/"
        />
        <Route
          element={
            <ProjectsView
              contractData={contractData}
              contractId={contractId}
              scope={activeTab}
              sort={sort}
            />
          }
          path="/active"
        />
        <Route
          element={
            <ProjectsView
              contractData={contractData}
              contractId={contractId}
              scope={activeTab}
              sort={sort}
            />
          }
          path="/inactive"
        />
      </Routes>
    </>
  )
}

Projects.propTypes = {
  contractId: PT.string,
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    overdueLevelGroups: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        id: PT.string,
        overdueLevel: PT.shape({
          id: PT.string,
          title: PT.string,
          overdueFrom: PT.string,
          overdueTo: PT.string,
        }),
      }),
    ),
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
}
Projects.defaultProps = {
  contractId: '',
  contractData: {},
}

export default Projects

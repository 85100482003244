import { useDispatch, useSelector } from 'react-redux'
import { selectMemo } from '../store/selectors/memo'
import { hideMemoForm, showMemoForm } from '../store/actions/memo'

export const useMemo = () => {
  const dispatch = useDispatch()
  const memo = useSelector(selectMemo)
  const editMemo = (payload) => {
    dispatch(showMemoForm(payload))
  }
  const hideEditMemo = () => {
    dispatch(hideMemoForm())
  }
  return { memo, editMemo, hideEditMemo }
}

import { HIDE_MEMO_FORM, SHOW_MEMO_FORM } from '../reducers/memo'

const showMemoForm = (payload) => ({
  type: SHOW_MEMO_FORM,
  payload: payload,
})

const hideMemoForm = () => ({
  type: HIDE_MEMO_FORM,
})

export { showMemoForm, hideMemoForm }

import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, InformationBox, Money, Tabs } from '../../ui-kit'
import PT from 'prop-types'
import { MONEY } from '../../utils/dataTypes'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import InvoicesOutstanding from './InvoicesOutstanding'
import InvoicesPaid from './InvoicesPaid'
import PageHeader from '../../components/headers/PageHeader'
import { getActiveTab, getTabsMap } from '../../utils/utils'
import Credits from './Credits'
import Deposits from './Deposits'
import FiltersControlButton from '../../components/filters/FiltersControlButton'
import { contractDataShape } from '../../types/invoiceShapes'
import { getDisplayStatusValue } from '../contracts/utils'
import { applyStatusFilter } from '../../components/filters/filterUtils'
import { InvoicesAvailableFilters } from '../../queriesUpdated/queries/common.gql'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { useCurrentUser } from '../../hooks/useCurrentUser'

const Invoices = ({ contractData, contractId, refetchContract, isAllContracts }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('')
  const [isAddDepositModalOpened, setIsAddDepositModalOpened] = useState(false)
  const [availableFilters, setAvailableFilters] = useState(null)

  const tabs = ['outstanding', 'paid', 'credits', 'deposits']

  const navigate = useNavigate()

  const handleTabClick = (tab) => {
    navigate(`./${tab}`)
    setActiveTab(tab)
  }

  const tabsMap = getTabsMap(tabs, activeTab, handleTabClick, t)

  useEffect(() => {
    setActiveTab(getActiveTab(location, 'invoices') || 'outstanding')
  }, [location])

  const onCompleted = useCallback((response) => {
    response?.invoices?.availableFilters && setAvailableFilters(response.invoices.availableFilters)
  }, [])
  useCustomQuery({
    query: InvoicesAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'InvoicesAvailableFilters', target: 'Invoices' },
  })
  const currentUser = useCurrentUser()
  const onItemClick = useCallback(
    (level) => {
      const levelTitle = getDisplayStatusValue(level?.overdueLevel)
      const redirectUrl = contractId
        ? `/customers/${contractId}/invoices/outstanding`
        : '/invoices/outstanding'
      applyStatusFilter('days_past_due', levelTitle, availableFilters, navigate, redirectUrl)
    },
    [availableFilters, navigate, contractId],
  )

  const { outstandingRetentionAmountCents, outstandingAmountCents } =
    contractData?.overdueLevelGroups?.reduce(
      (acc, level) => {
        if (level.retentionAmountCents) {
          acc.outstandingRetentionAmountCents += level.retentionAmountCents
        }

        if (level.amountCents) {
          acc.outstandingAmountCents += level.amountCents
        }

        return acc
      },
      {
        outstandingAmountCents: 0,
        outstandingRetentionAmountCents: 0,
      },
    ) || 0

  return (
    <>
      {contractData && (
        <Flex className="w-full" gap="5" justifyContent="between">
          {contractData.overdueLevelGroups &&
            contractData.overdueLevelGroups.map((item) => (
              <InformationBox
                className="w-full"
                key={item.id}
                onClick={() => onItemClick(item)}
                secondaryLabel={item.retentionAmountCents ? t('retention') : null}
                secondaryValue={
                  item.retentionAmountCents ? <Money value={item.retentionAmountCents} /> : null
                }
                title={item?.overdueLevel?.title || t('current')}
                type={MONEY}
                value={item.amountCents}
              />
            ))}
          <InformationBox
            className="w-full"
            secondaryLabel={outstandingRetentionAmountCents ? t('retention') : null}
            secondaryValue={
              outstandingRetentionAmountCents ? (
                <Money value={outstandingRetentionAmountCents} />
              ) : null
            }
            title={t('totalOutstanding')}
            type={MONEY}
            value={outstandingAmountCents}
          />
        </Flex>
      )}

      <PageHeader headerTitle={t('invoices')} />

      <Flex className="justify-between items-center">
        <Tabs tabs={tabsMap} />
        <Flex>
          {activeTab === 'deposits' ? (
            <Flex className="pr-5" justifyContent="end">
              {currentUser.erpMetadata?.supportDeposits && (
                <Button
                  iconName="plusCircle"
                  iconType="outline"
                  label={t('addDeposit')}
                  onClick={() => setIsAddDepositModalOpened(true)}
                  testData="add-deposit"
                  variant="secondary"
                />
              )}
            </Flex>
          ) : null}
          <FiltersControlButton />
        </Flex>
      </Flex>

      <Routes>
        <Route element={<Navigate to="outstanding" replace />} path="/" />
        <Route
          element={
            <InvoicesOutstanding
              contractData={contractData}
              contractId={contractId}
              isAllContracts={isAllContracts}
              refetchContract={refetchContract}
              scope={activeTab}
            />
          }
          path="/outstanding"
        />
        <Route
          element={
            <InvoicesPaid
              contractData={contractData}
              contractId={contractId}
              isAllContracts={isAllContracts}
              refetchContract={refetchContract}
              scope={activeTab}
            />
          }
          path="/paid"
        />
        <Route
          element={
            <Credits
              contractData={contractData}
              contractId={contractId}
              isAllContracts={isAllContracts}
              scope={activeTab}
            />
          }
          path="/credits"
        />
        <Route
          element={
            <Deposits
              contractData={contractData}
              contractId={contractId}
              isAddDepositModalOpened={isAddDepositModalOpened}
              isAllContracts={isAllContracts}
              refetchContract={refetchContract}
              scope={activeTab}
              setIsAddDepositModalOpened={setIsAddDepositModalOpened}
            />
          }
          path="/deposits"
        />
      </Routes>
    </>
  )
}

Invoices.propTypes = {
  contractData: contractDataShape,
  contractId: PT.string,
  isAllContracts: PT.bool,
  refetchContract: PT.func.isRequired,
}
Invoices.defaultProps = {
  contractId: '',
  isAllContracts: false,
}

export default Invoices

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import ProjectPartyForm from './ProjectPartyForm'
import { ProjectPartyQuery } from '../../../queries/projectParties/projectParty.gql'
import { LoadingSpinner, Dialog, ConfirmationDialog } from '../../../ui-kit'
import { UpdateProjectParty } from '../../../queries/mutations/updateProjectParty.gql'
import { ProjectPartiesOverviewQuery } from '../../../queries/projectParties/projectPartiesOverview.gql'
import { DeleteProjectParty } from '../../../queries/mutations/deleteProjectParty.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const UpdateProjectPartyModal = ({
  projectId,
  projectPartyId,
  setIsSidebarOpened,
  isOpened,
  setIsOpened,
}) => {
  const { t } = useTranslation()
  const queryVariables = {
    id: projectPartyId,
  }
  const { data, loading } = useCustomQuery({
    query: ProjectPartyQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !queryVariables.id,
    },
    rollbarOptions: { operationName: 'ProjectPartyQuery', target: 'UpdateProjectPartyModal' },
  })

  const onCompletedUpdateProjectParty = useCallback(() => {
    setIsOpened(false)
    setIsSidebarOpened(true)
  }, [])
  const [updateProjectParty, { loading: updateLoading }] = useCustomMutation({
    mutation: UpdateProjectParty,
    onCompleted: onCompletedUpdateProjectParty,
    rollbarOptions: { operationName: 'UpdateProjectParty', target: 'UpdateProjectPartyModal' },
    mutationOptions: {
      refetchQueries: [ProjectPartiesOverviewQuery],
    },
  })
  const { newNotification } = useNotifications()
  const onSubmit = useCallback(
    (values) => {
      const variables = {
        id: projectPartyId,
        data: {
          projectId,
          name: values.name,
          role: values.role,
          email: values.email,
          legalName: values.isBusinessNameDifferentFromLegalName
            ? values.legalName
            : values.businessName,
          officePhone: values.officePhone,
          mobilePhone: values.mobilePhone,
          businessName: values.businessName,
          addressAttributes: {
            city: values.city,
            state: values.state,
            street: values.street,
            street2: values.street2,
            country: values.country,
            zipCode: values.zipCode,
          },
        },
      }

      updateProjectParty({ variables })
    },
    [updateProjectParty, projectId, projectPartyId],
  )
  const [isOpenedConfirmationModal, setIsOpenedConfirmationModal] = useState(false)

  const onCompletedDeleteProjectParty = useCallback(() => {
    setIsOpenedConfirmationModal(false)
    setIsOpened(false)
    setIsSidebarOpened(true)
  }, [])
  const [deleteProjectParty, { loading: deleteLoading }] = useCustomMutation({
    mutation: DeleteProjectParty,
    onCompleted: onCompletedDeleteProjectParty,
    rollbarOptions: { operationName: 'DeleteProjectParty', target: 'UpdateProjectPartyModal' },
    mutationOptions: {
      refetchQueries: [ProjectPartiesOverviewQuery],
    },
  })
  const onDelete = useCallback(() => {
    const variables = {
      id: projectPartyId,
    }

    deleteProjectParty({ variables }).then(({ data }) => {
      const responseData = data?.deleteProjectParty || {}

      if (responseData?.entity) {
        newNotification({ success: t('projectPartyDeletedSuccessfully') })
      }
    })
  }, [deleteProjectParty, projectPartyId])

  const closeForm = useCallback(() => {
    setIsOpenedConfirmationModal(false)
    setIsOpened(false)
    setIsSidebarOpened(true)
  }, [setIsOpened, setIsSidebarOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const handleCancelDeleteProjectParty = useCallback(() => {
    setIsOpenedConfirmationModal(false)
    setIsOpened(true)
  }, [setIsOpenedConfirmationModal, setIsOpened])

  return (
    <>
      <Dialog
        isOpened={isOpened}
        setIsOpened={requestClose}
        title={t('editProjectParty')}
        titleClass="text-2xl font-semibold">
        {data && !loading ? (
          <ProjectPartyForm
            closeForm={closeForm}
            data={data?.projectParty}
            isFormDirty={isFormDirty}
            isLoading={updateLoading || deleteLoading}
            onDelete={() => {
              setIsOpened(false)
              setIsOpenedConfirmationModal(true)
            }}
            onSubmit={onSubmit}
            requestClose={requestClose}
            setDirtyFormState={setDirtyFormState}
          />
        ) : (
          <LoadingSpinner loading={loading} />
        )}
      </Dialog>
      <ConfirmationDialog
        confirmationMessage={t('projectPartyWarn')}
        isOpened={isOpenedConfirmationModal}
        onModalClose={() => setIsOpened(true)}
        onSubmitButtonNoClick={handleCancelDeleteProjectParty}
        onSubmitButtonYesClick={onDelete}
        setIsOpened={setIsOpenedConfirmationModal}
        title={t('deleteProjectParty')}
      />
    </>
  )
}

UpdateProjectPartyModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  projectPartyId: PropTypes.string.isRequired,
  setIsSidebarOpened: PropTypes.func.isRequired,
}

export default UpdateProjectPartyModal

import React, { useEffect } from 'react'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import { useTranslation } from 'react-i18next'
import Payments from '../payments/Payments'

const PaymentsPage = () => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    setBreadcrumbs([{ label: t('payments'), link: '/payments' }])
  }, [])

  return <Payments isAllContracts />
}

export default PaymentsPage

import React, { useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, Flex, FormattedDate, LoadingSpinner, Text, Tooltip } from '../../ui-kit'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { SyncErrorsSummaryQuery } from '../../queriesUpdated/queries/syncErrorsSummary.gql'
import { SyncErrorsDetailsQuery } from '../../queriesUpdated/queries/syncErrorsDetails.gql'
import fontWeight from '../../ui-kit/fontWeight'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import {
  convertTimeZone,
  DEFAULT_DATE_FORMAT,
  getFormattedDate,
} from '../../ui-kit/utils/dateUtils'
import DataGridComponent from '../dataGrid/DataGridComponent'
import { membershipRoles } from '../../pages/settings/teamManagement/membershipRoles'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import sizes from '../../ui-kit/sizes'
import { useNavigate } from 'react-router-dom'
import { getPaginationData } from '../../utils/utils'
import { toInteger } from 'lodash'
import { useQueryParams } from '../../hooks/useQueryParams'

const syncTypes = {
  PULL: 'Suppli Import',
  PUSH: 'ERP Export',
}
const objectTypes = {
  DEPOSIT: 'Deposit',
  INVOICE: 'Invoice',
  PROJECT: 'Project',
  PAYMENT: 'Payment',
  CUSTOMER: 'Customer',
  LOCATION: 'Location',
  CREDIT_MEMO: 'Credit Memo',
  VOID_PAYMENT: 'Void Payment',
  DEPOSIT_RETURN: 'Deposit Return',
}

const SyncExceptions = ({ className }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isOpened, setIsOpened] = useState(false)
  const currentUser = useCurrentUser()

  const { data, loading, refetch } = useCustomQuery({
    query: SyncErrorsSummaryQuery,
    rollbarOptions: { operationName: 'SyncErrorsSummaryQuery', target: 'SyncExceptions' },
  })
  const syncErrorsSummary = data?.syncErrorsSummary
  const { queryParams, removeQueryParam } = useQueryParams()

  const page = toInteger(queryParams.syncPage) || 1
  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(() => {
    return {
      page,
      perPage: pageSize,
    }
  }, [page, pageSize])
  const {
    data: detailsData,
    loading: detailsLoading,
    refetch: detailsQueryRefetch,
  } = useCustomQuery({
    query: SyncErrorsDetailsQuery,
    queryOptions: {
      variables: queryVariables,
      skip: currentUser?.membershipRole !== membershipRoles.ADMIN,
    },
    rollbarOptions: { operationName: 'SyncErrorsDetailsQuery', target: 'SyncExceptions' },
  })

  useEffect(() => {
    if (isOpened) {
      detailsQueryRefetch()
    }
  }, [isOpened])

  const syncErrorsDetails = detailsData?.syncErrorsDetails?.data || []
  const paginationData = getPaginationData(detailsData?.syncErrorsDetails)

  const getObjectIdElement = (exception) => {
    if (!exception) {
      return
    }

    if (exception.syncType === syncTypes.PULL) {
      return (
        <Tooltip className="text-ellipsis overflow-hidden" content={exception.referenceId}>
          {exception.referenceId}
        </Tooltip>
      )
    }

    let navigateTo

    switch (exception.objectType) {
      case objectTypes.DEPOSIT:
      case objectTypes.DEPOSIT_RETURN:
        navigateTo = `/invoices/deposits?additionalFilters=id&filters=id.${exception.referenceId}`
        break
      case objectTypes.PAYMENT:
      case objectTypes.VOID_PAYMENT:
        navigateTo = `/payments/payments?additionalFilters=id&filters=id.${exception.referenceId}`
        break
      case objectTypes.CUSTOMER:
        navigateTo = `/customers?additionalFilters=id&filters=id.${exception.referenceId}`
        break
      case objectTypes.CREDIT_MEMO:
        navigateTo = `/invoices/credits?additionalFilters=id&filters=id.${exception.referenceId}`
        break
      default:
        break
    }

    if (!navigateTo) {
      return exception.referenceId
    }

    return (
      <Button
        label={exception.referenceId}
        onClick={() => {
          setIsOpened(false)
          navigate(navigateTo)
        }}
        size={sizes.SM}
        variant={buttonsVariants.LINK}
      />
    )
  }
  const columns = [
    {
      field: 'id',
      headerName: t('id'),
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'syncType',
      headerName: t('syncType'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'objectType',
      headerName: t('objectType'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'referenceId',
      headerName: t('objectId'),
      width: 230,
      renderCell: (values) => getObjectIdElement(values?.row),
      sortable: false,
    },
    {
      field: 'description',
      headerName: t('description'),
      flex: 4,
      renderCell: (values) => (
        <Text className="whitespace-normal" color="text-warmBlack">
          {values?.row?.description}
        </Text>
      ),
      sortable: false,
    },
  ]

  useEffect(() => {
    const interval = 1000 * 60 * 15
    const intervalId = setInterval(() => refetch(), interval)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <Flex className={className} column>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <div class="mb-5">
            <Text color="text-black-500" fontWeight={fontWeight.MEDIUM}>
              {`${t('lastPush')}:`}
            </Text>
            <Text color="text-black-500" fontWeight={fontWeight.MEDIUM}>
              <div>
                <FormattedDate
                  date={syncErrorsSummary?.lastPushedAt}
                  format="MM/dd/yyyy"
                  timeZone={currentUser.timeZone}
                />
              </div>

              {syncErrorsSummary?.lastPushedAt && (
                <div>
                  <FormattedDate
                    date={syncErrorsSummary?.lastPushedAt}
                    format="hh:mm:ss a"
                    timeZone={currentUser.timeZone}
                  />
                  {` ${convertTimeZone(currentUser.timeZone)}`}
                </div>
              )}
            </Text>
          </div>
          <div class="mb-5">
            <Text color="text-black-500" fontWeight={fontWeight.MEDIUM}>
              {`${t('lastPull')}:`}
            </Text>
            <Text color="text-black-500" fontWeight={fontWeight.MEDIUM}>
              <div>
                <FormattedDate
                  date={syncErrorsSummary?.lastPulledAt}
                  format="MM/dd/yyyy"
                  timeZone={currentUser.timeZone}
                />
              </div>
              {syncErrorsSummary?.lastPulledAt && (
                <div>
                  <FormattedDate
                    date={syncErrorsSummary?.lastPulledAt}
                    format="hh:mm:ss a"
                    timeZone={currentUser.timeZone}
                  />
                  {` ${convertTimeZone(currentUser.timeZone)}`}
                </div>
              )}
            </Text>
          </div>
          {!!syncErrorsSummary?.syncExceptionsCount && (
            <>
              <Text
                className="cursor-pointer"
                color="text-blue-500"
                fontWeight={fontWeight.MEDIUM}
                onClick={() => setIsOpened(true)}
                variant={buttonsVariants.LINK}>
                {`${syncErrorsSummary?.syncExceptionsCount} ${t('exceptions')}`}
              </Text>
              <Dialog
                isOpened={isOpened}
                onModalClose={() => removeQueryParam('syncPage')}
                setIsOpened={setIsOpened}
                title={t('syncExceptionsAsOf', {
                  date: getFormattedDate(
                    syncErrorsSummary?.lastSyncedAt,
                    DEFAULT_DATE_FORMAT,
                    currentUser.timeZone,
                  ),
                })}>
                <>
                  <Text color="text-black-500">{t('syncExceptionsHint')}</Text>
                  <DataGridComponent
                    columns={columns}
                    customPaginationQuery="syncPage"
                    getRowHeight={() => 'auto'}
                    hideFooter={paginationData.totalCount < 10}
                    loading={detailsLoading}
                    localPagination={syncErrorsDetails.length > 10}
                    page={page}
                    pageSize={pageSize}
                    paginationData={paginationData}
                    rowClassName="top-alignment"
                    rows={syncErrorsDetails}
                    wrapperClassNames="w-[70rem] pt-9"
                  />
                </>
              </Dialog>
            </>
          )}
        </>
      )}
    </Flex>
  )
}

SyncExceptions.propTypes = {
  className: PT.string,
}

export default SyncExceptions

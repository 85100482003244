import React, { useCallback, useState } from 'react'
import PT from 'prop-types'
import { LoadingSpinner, Dialog, PreviewBlock, ConfirmationDialog } from '../../../../ui-kit'
import Note from '../../../../ui-kit/components/note/Note'
import { useTranslation } from 'react-i18next'
import AddNoteForm from '../../addNoteForm/AddNoteForm'
import { useDirtyFormAlert } from '../../../../hooks/useDirtyFormAlert'
import { getFormattedDate } from '../../../../ui-kit/utils/dateUtils'
import { DeleteNote } from '../../../../queries/mutations/deleteNote.gql'
import { ContractEventsQuery } from '../../../../queries/contractEvents.gql'
import { ContractNotes } from '../../../../queries/contractNotes.gql'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

const CustomerNotes = ({ notes, areNotesLoading, contractId }) => {
  const { t } = useTranslation()
  const [selectedNote, setSelectedNote] = useState({})
  const [isViewNoteModalOpened, setIsViewNoteModalOpened] = useState(false)
  const [confirmDeleteModalOpened, setConfirmDeleteModalOpened] = useState(false)

  const handleNoteClick = useCallback((note) => {
    setSelectedNote(note)
    setIsViewNoteModalOpened(true)
  }, [])
  const notesList = notes?.map((note, index) => (
    <Note
      body={note.message}
      className={index !== notes.length - 1 ? 'mb-4' : ''}
      createdAt={note.createdAt}
      createdBy={note.createdBy}
      key={note.id}
      onClick={() => handleNoteClick(note)}
      pin={!!note.pinnedAt}
    />
  ))

  const closeForm = useCallback(() => {
    setIsViewNoteModalOpened(false)
    setSelectedNote({})
  }, [])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const onCompleted = useCallback(() => {
    setConfirmDeleteModalOpened(false)
    setSelectedNote({})
  }, [])
  const [deleteNote, { loading: isDeleteNoteLoading }] = useCustomMutation({
    mutation: DeleteNote,
    onCompleted,
    rollbarOptions: { operationName: 'DeleteNote', target: 'CustomerNotes' },
    mutationOptions: {
      refetchQueries: [ContractEventsQuery, ContractNotes],
    },
  })
  const handleDeleteNote = useCallback(() => {
    deleteNote({
      variables: {
        id: selectedNote.id || selectedNote.noteId,
      },
    })
  }, [selectedNote])
  const handleCancelDeleteNoteModal = useCallback(() => {
    setConfirmDeleteModalOpened(false)
    setIsViewNoteModalOpened(true)
  }, [setConfirmDeleteModalOpened, setIsViewNoteModalOpened])

  if (areNotesLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <PreviewBlock
        className="pt-5"
        testData="customer-notes"
        title={t('customerNotes')}
        viewMoreLink={`/customers/${contractId}/activity?filters=eventableType.Note`}>
        {notesList}
      </PreviewBlock>

      <Dialog
        isOpened={isViewNoteModalOpened}
        setIsOpened={requestClose}
        title={`${t('note')} - ${getFormattedDate(
          selectedNote?.createdAt || '',
          'MM/dd/yyyy hh:mm a',
        )}`}>
        <AddNoteForm
          closeForm={closeForm}
          handleDeleteNote={() => {
            setIsViewNoteModalOpened(false)
            setConfirmDeleteModalOpened(true)
          }}
          isFormDirty={isFormDirty}
          note={selectedNote}
          requestClose={requestClose}
          setDirtyFormState={setDirtyFormState}
          setIsOpenedModal={setIsViewNoteModalOpened}
          isViewMode
        />
      </Dialog>
      <ConfirmationDialog
        confirmationMessage={t('deleteNoteWarning')}
        isOpened={confirmDeleteModalOpened}
        isSubmitButtonYesDisabled={isDeleteNoteLoading}
        onModalClose={() => setIsViewNoteModalOpened(true)}
        onSubmitButtonNoClick={handleCancelDeleteNoteModal}
        onSubmitButtonYesClick={handleDeleteNote}
        setIsOpened={setConfirmDeleteModalOpened}
        title={t('deleteNote')}
      />
    </>
  )
}

export default CustomerNotes

CustomerNotes.propTypes = {
  notes: PT.arrayOf(
    PT.shape({
      message: PT.string.isRequired,
      createdAt: PT.string.isRequired,
      createdBy: PT.string.isRequired,
      id: PT.string.isRequired,
    }),
  ),
  areNotesLoading: PT.bool,
  contractId: PT.string,
}

CustomerNotes.defaultProps = {
  notes: undefined,
  areNotesLoading: false,
  contractId: '',
}

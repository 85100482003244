import React, { useEffect, useMemo } from 'react'
import PageHeader from '../../components/headers/PageHeader'
import { Flex } from '../../ui-kit'
import DaysPastDueChart from '../../components/daysPastDueChart/DaysPastDueChart'
import InvoicesLargestPastDue from './ivoicesLargestPastDue/InvoicesLargestPastDue'
import InvoicesOldestPastDue from './invoicesOldestPastDue/InvoicesOldestPastDue'
import { useTranslation } from 'react-i18next'
import {
  VendorStatsQuery,
  VendorOverdueLevelGroups,
  VendorDailyStatsQuery,
} from '../../queries/vendors.gql'
import { useCurrentUser } from '../../hooks/useCurrentUser'
// import DaysPastDueOverTimeChart from './daysPastDueOverTimeChart/DaysPastDueOverTimeChart'
import ShortStats from '../../components/statsShortChart/ShortStats'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
// import OutstandingVSPastDueChart from './outstandingVSPastDueChart/OutstandingVSPastDueChart'
import DashboardRecentEvents from './dashboardRecentEvents/DashboardRecentEvents'
import {
  getCurrentDateInfo,
  getStatsData,
  getVendorDailyStatsQueryParams,
  getVendorOverdueLevelGroupsParams,
  getVendorStatsQueryParams,
} from './logic/utils'
import { get } from 'lodash'
import { formatDailyStatsData } from './logic/formatDailyStatsData'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { isCounterUser } from '../settings/teamManagement/membershipRoles'
import { useNavigate } from 'react-router-dom'
import AccountSummary from '../contracts/contractOverview/accountSummary/AccountSummary'

const Dashboard = () => {
  const { setBreadcrumbs } = useBreadcrumbs()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (isCounterUser(currentUser)) {
      navigate('/customers')
    }
    setBreadcrumbs([{ label: t('dashboard') }])
  }, [])

  const { t } = useTranslation()

  const vendorStatsQueryVariables = useMemo(
    () => getVendorStatsQueryParams(currentUser),
    [currentUser],
  )
  const { data: stats, loading: statsIsLoading } = useCustomQuery({
    query: VendorStatsQuery,
    queryOptions: {
      variables: vendorStatsQueryVariables,
      skip: !vendorStatsQueryVariables.id,
    },
    rollbarOptions: { operationName: 'VendorStatsQuery', target: 'Dashboard' },
  })
  const vendorOverdueLevelGroupsVariables = useMemo(
    () => getVendorOverdueLevelGroupsParams(currentUser),
    [currentUser],
  )
  const { data: daysPastDue, loading: daysPastDueIsLoading } = useCustomQuery({
    query: VendorOverdueLevelGroups,
    queryOptions: {
      variables: vendorOverdueLevelGroupsVariables,
      skip: !vendorOverdueLevelGroupsVariables.id,
    },
    rollbarOptions: { operationName: 'VendorOverdueLevelGroups', target: 'Dashboard' },
  })
  const vendorDailyStatsQueryVariables = useMemo(
    () => getVendorDailyStatsQueryParams(currentUser),
    [currentUser],
  )
  const { data: dailyStats /* loading: dailyStatsLoading */ } = useCustomQuery({
    query: VendorDailyStatsQuery,
    queryOptions: {
      variables: vendorDailyStatsQueryVariables,
      skip: !vendorDailyStatsQueryVariables.id,
    },
    rollbarOptions: { operationName: 'VendorDailyStatsQuery', target: 'Dashboard' },
  })
  const overdueLevelGroups = useMemo(
    () => get(daysPastDue, 'vendor.overdueLevelGroups'),
    [daysPastDue],
  )
  const vendorStats = useMemo(() => get(stats, 'vendor'), [stats])
  const dailyStatsData = useMemo(
    () => get(dailyStats, 'vendor.vendorDailyStatistics'),
    [dailyStats],
  )
  // const totalOutstandingDailyStats = useMemo(
  //   () => formatDailyStatsData(dailyStatsData, 'avgTotalOutstandingAmountCents'),
  //   [dailyStatsData],
  // )
  const totalPastDueDailyStats = useMemo(
    () => formatDailyStatsData(dailyStatsData, 'avgTotalOverdueAmountCents'),
    [dailyStatsData],
  )
  const daysPastDueDailyStats = useMemo(
    () => formatDailyStatsData(dailyStatsData, 'avgAging'),
    [dailyStatsData],
  )

  const statsData = useMemo(
    () =>
      getStatsData(
        statsIsLoading,
        vendorStats,
        totalPastDueDailyStats,
        daysPastDueDailyStats,
        dailyStatsData,
      ),
    [statsIsLoading, vendorStats, totalPastDueDailyStats, daysPastDueDailyStats, dailyStatsData],
  )

  const { currentDate, currentMonth } = useMemo(() => getCurrentDateInfo(), [])
  const vendorSummary = useMemo(
    () => ({
      nonFinanceChargesOutstandingAmountCents: get(
        vendorStats,
        'nonFinanceChargesOutstandingAmountCents',
        'N/A',
      ),
      financeChargesOutstandingAmountCents: get(
        vendorStats,
        'financeChargesOutstandingAmountCents',
        'N/A',
      ),
      availableCreditAmountCents: get(vendorStats, 'availableCreditAmountCents', 'N/A'),
      availableDepositAmountCents: get(vendorStats, 'availableDepositAmountCents', 'N/A'),
      netDueAmountCents: get(vendorStats, 'netDueAmountCents', 'N/A'),
      pendingPaymentsAmountCents: get(vendorStats, 'pendingPaymentsAmountCents', 'N/A'),
    }),
    [vendorStats],
  )

  return (
    <div className={'mb-10'}>
      <Flex alignItems="center" className={'mb-10'} justifyContent="between">
        <Flex column>
          <PageHeader headerTitle={t('dashboard')} />
          <div className="uppercase text-base text-black-500">
            <span className={'text-primary-700 font-medium'}>{currentDate}</span>{' '}
            <span>{currentMonth}</span>
          </div>
        </Flex>
        <DashboardRecentEvents />
      </Flex>

      <Flex>
        <Flex className={'w-1/3 pr-8'} shrink={0} column>
          <AccountSummary data={vendorSummary} isLoading={statsIsLoading} isVendorDashboard />
          <Flex className="mt-4" gap="4">
            <ShortStats data={statsData} isLoading={statsIsLoading} itemClassName="w-1/2 mb-0" />
          </Flex>
          <DaysPastDueChart
            className={'mt-4'}
            isLoading={daysPastDueIsLoading}
            overdueLevelGroups={overdueLevelGroups}
            redirectUrl="/invoices/outstanding"
            valueFieldName={'outstandingAmountCents'}
          />
        </Flex>
        <Flex className={'w-1/3 pr-8'} column>
          <InvoicesLargestPastDue />
          {/*<DaysPastDueOverTimeChart*/}
          {/*  className={'mt-10'}*/}
          {/*  data={daysPastDueDailyStats}*/}
          {/*  isLoading={dailyStatsLoading}*/}
          {/*/>*/}
        </Flex>
        <Flex className={'w-1/3'} column>
          <InvoicesOldestPastDue />
          {/*<OutstandingVSPastDueChart*/}
          {/*  className={'mt-10'}*/}
          {/*  isLoading={dailyStatsLoading}*/}
          {/*  outstandingDailyStats={totalOutstandingDailyStats}*/}
          {/*  pastDueDailyStats={totalPastDueDailyStats}*/}
          {/*/>*/}
        </Flex>
      </Flex>
    </div>
  )
}

export default Dashboard

import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { snakeCase } from 'lodash'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tabs } from '../../../ui-kit'
import ContactInfo from './ContactInfo'
import PaymentProfile from './PaymentProfile'
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs'
import PageHeader from '../../../components/headers/PageHeader'
import CreditReview from './CreditReview'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { isCounterUser } from '../../settings/teamManagement/membershipRoles'

const CustomerInfo = ({ contractData }) => {
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const [activeTab, setActiveTab] = useState('contactInfo')

  useEffect(() => {
    setBreadcrumbs([
      { label: t('customers'), link: '/customers' },
      { label: contractData?.buyer?.name, link: `/customers/${contractData.id}` },
      { label: t('customerInfo'), link: `/customers/${contractData.id}/customer_info` },
      { label: t(activeTab) },
    ])
  }, [activeTab, contractData])
  const currentUser = useCurrentUser()

  const baseTabs = ['contactInfo', 'paymentProfile']
  const creditReview = ['creditReview']
  const tabs = isCounterUser(currentUser) ? baseTabs : baseTabs.concat(creditReview)

  const navigate = useNavigate()

  const handleTabClick = (tab) => {
    navigate(`./${snakeCase(tab)}`)
    setActiveTab(tab)
  }

  const tabsMap = tabs.map((tab) => ({
    id: tab,
    label: t(tab),
    onClick: () => handleTabClick(tab),
    active: activeTab === tab,
  }))

  return (
    <>
      <PageHeader headerTitle={t('customerInfo')} />
      <Tabs tabs={tabsMap} />

      <Routes>
        <Route element={<CreditReview contractData={contractData} />} path="/credit_review" />
        <Route element={<ContactInfo contractData={contractData} />} path="/contact_info" />
        <Route element={<PaymentProfile contractData={contractData} />} path="/payment_profile" />
        <Route element={<ContactInfo contractData={contractData} />} path="/" />
      </Routes>
    </>
  )
}

CustomerInfo.propTypes = {
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
}
CustomerInfo.defaultProps = {
  contractData: {},
}

export default CustomerInfo

export const paymentResultMods = {
  LOADING: 'LOADING',
  ACH_SUCCESS: 'ACH_SUCCESS',
  ACH_FAILURE: 'ACH_FAILURE',
  ALREADY_PAID: 'ALREADY_PAID',
  SERVER_ERROR: 'SERVER_ERROR',
  CREDIT_CARD_SUCCESS: 'CREDIT_CARD_SUCCESS',
  CREDIT_CARD_FAILURE: 'CREDIT_CARD_FAILURE',
  CARD_AUTHORIZATION_SUCCESS: 'CARD_AUTHORIZATION_SUCCESS',
  CARD_AUTHORIZATION_FAILURE: 'CARD_AUTHORIZATION_FAILURE',
  PAYCHECK_CREDIT_DEPOSIT_SUCCESS: 'PAYCHECK_CREDIT_DEPOSIT_SUCCESS',
}

export const paymentMethodTypes = {
  ACH_PAYMENT_METHOD: 'AchPaymentMethod',
  PAYCHECK_PAYMENT_METHOD: 'PaycheckPaymentMethod',
  CREDIT_CARD_PAYMENT_METHOD: 'CreditCardPaymentMethod',
  DEBIT_CARD_PAYMENT_METHOD: 'DebitCardPaymentMethod',
  CREDIT_DEPOSIT_PAYMENT_METHOD: 'CreditDepositPaymentMethod',
}

import React from 'react'
import PT from 'prop-types'
import colors from '../../colors'
import sizes from '../../sizes'
import classes from './Icon.module.scss'

import cx from 'classnames'
import { capitalize, values } from 'lodash'

import outlineIconComponents from './outlineIconsList'
import solidIconComponents from './solidIconsList'

const ICON_TYPES = {
  solid: solidIconComponents,
  outline: outlineIconComponents,
}

const Icon = ({
  type,
  name,
  className,
  color,
  size,
  onClick,
  backgroundColor,
  style,
  disabled,
}) => {
  const iconClasses = cx(classes.icon, {
    [classes[`iconSize${capitalize(size)}`]]: !!size,
    [classes[`iconBackground${capitalize(backgroundColor)}`]]: !!backgroundColor,
    [color]: color,
    'cursor-pointer': !!onClick,
    [className]: !!className,
    [classes.iconDisabled]: disabled,
  })
  const Element = ICON_TYPES[type][name]

  return Element ? (
    <Element className={iconClasses} onClick={!disabled && onClick} style={style} />
  ) : null
}

Icon.propTypes = {
  backgroundColor: PT.string,
  className: PT.string,
  color: PT.string,
  name: PT.string.isRequired,
  onClick: PT.func,
  size: PT.oneOf(values(sizes)),
  style: PT.shape({
    color: PT.string,
    fontSize: PT.number,
    height: PT.oneOfType([PT.number, PT.string]),
    width: PT.oneOfType([PT.number, PT.string]),
  }),
  type: PT.oneOf(['solid', 'outline']),
  disabled: PT.bool,
}

Icon.defaultProps = {
  backgroundColor: '',
  className: '',
  color: colors.PRIMARY,
  onClick: null,
  type: 'solid',
  size: sizes.BASE,
  style: null,
  disabled: false,
}

export default Icon

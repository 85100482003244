import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { values } from 'lodash'
import colors from '../../colors'
import sizes from '../../sizes'
import fontWeight from '../../fontWeight'
import align from '../../align'

const Text = ({
  children,
  paragraph,
  fontWeight,
  color,
  customColor,
  size,
  align,
  nowrap,
  className,
  onClick,
  testData,
}) => {
  const textClassnames = cx(`text-${align}`, `font-${fontWeight}`, `text-${size}`, {
    [color]: color && !customColor,
    ['whitespace-nowrap']: nowrap,
    [className]: !!className,
  })

  const Element = paragraph ? 'p' : 'span'

  return (
    <Element className={textClassnames} onClick={onClick} testData={testData}>
      {children}
    </Element>
  )
}

Text.propTypes = {
  align: PT.oneOf(values(align)),
  children: PT.node,
  className: PT.string,
  color: PT.string,
  customColor: PT.bool,
  fontWeight: PT.oneOf(values(fontWeight)),
  nowrap: PT.bool,
  paragraph: PT.bool,
  size: PT.oneOf(values(sizes)),
  onClick: PT.func,
  testData: PT.string,
}

Text.defaultProps = {
  align: align.LEFT,
  className: '',
  color: colors.BLACK,
  customColor: false,
  fontWeight: fontWeight.NORMAL,
  nowrap: false,
  paragraph: false,
  size: sizes.SM,
  onClick: null,
  testData: '',
}

export default Text

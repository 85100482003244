import React from 'react'
import PT from 'prop-types'
import Dialog, { DIALOG_VARIANTS } from './Dialog'
import { Button, Text } from '../../index'
import buttonsVariants from '../../buttonsVariants'
import Flex from '../spacing/Flex'
import { useTranslation } from 'react-i18next'
import sizes from '../../sizes'

const ConfirmationDialog = ({
  title,
  children,
  isOpened,
  setIsOpened,
  onModalClose,
  withCustomButtons,
  confirmationMessage,
  submitButtonNoLabel,
  submitButtonYesLabel,
  onSubmitButtonNoClick,
  onSubmitButtonYesClick,
  isSubmitButtonYesDisabled,
  withHideNoBtn,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      isOpened={isOpened}
      onModalClose={onModalClose}
      setIsOpened={setIsOpened}
      title={title}
      variant={DIALOG_VARIANTS.CONFIRMATION}>
      {confirmationMessage && (
        <Flex>
          <Text className="pt-4" size={sizes.SM}>
            {confirmationMessage}
          </Text>
        </Flex>
      )}
      {children}
      {!withCustomButtons && (
        <Flex className="w-full mt-6" justifyContent="end">
          {!withHideNoBtn && (
            <Button
              className="mr-2"
              label={submitButtonNoLabel || t('no')}
              onClick={onSubmitButtonNoClick}
              testData="confirm-no"
              variant={buttonsVariants.TERTIARY}
            />
          )}
          <Button
            disabled={isSubmitButtonYesDisabled}
            label={submitButtonYesLabel || t('yes')}
            onClick={onSubmitButtonYesClick}
            testData="confirm-yes"
          />
        </Flex>
      )}
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  title: PT.string,
  children: PT.node,
  isOpened: PT.bool.isRequired,
  setIsOpened: PT.func.isRequired,
  onModalClose: PT.func,
  withCustomButtons: PT.bool,
  confirmationMessage: PT.oneOfType([PT.string, PT.node]),
  submitButtonNoLabel: PT.string,
  submitButtonYesLabel: PT.string,
  onSubmitButtonNoClick: PT.func,
  onSubmitButtonYesClick: PT.func,
  isSubmitButtonYesDisabled: PT.bool,
  withHideNoBtn: PT.bool,
}
ConfirmationDialog.defaultProps = {
  withCustomButtons: false,
  confirmationMessage: '',
  submitButtonNoLabel: '',
  submitButtonYesLabel: '',
  onSubmitButtonNoClick: null,
  onSubmitButtonYesClick: null,
  isSubmitButtonYesDisabled: false,
  withHideNoBtn: false,
}

export default ConfirmationDialog

import { restRequest } from '../../../utils/restAPI'
import { downloadResponse } from './downloadResponse'
import { captureCritical } from '../../../utils/rollbar'

export const downloadFile = async (url, authToken, params, fileName, rollbar) => {
  if (!url || !authToken) {
    return
  }

  const config = {
    url,
    method: 'get',
    params,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    responseType: 'blob',
  }

  try {
    const response = await restRequest(config)
    downloadResponse(url, response, fileName)
  } catch (error) {
    captureCritical(rollbar, error, {
      target: 'downloadReport',
      arguments: JSON.stringify({ url, params }),
    })
  }
}

export const downloadReport = async (url, authToken, params, rollbar) => {
  if (!url || !authToken) {
    return null
  }

  const config = {
    url,
    method: 'get',
    params,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  }

  try {
    return await restRequest(config)
  } catch (error) {
    captureCritical(rollbar, error, {
      target: 'downloadReport',
      arguments: JSON.stringify({ url, params }),
    })
    return null
  }
}

export const getReportsData = (response) => response?.data || []

export const getReportsTotal = (response) => response?.total || {}

export const getCustomRowId = (row, key) => row?.[key]

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import ReportFooter from '../reportFooter/ReportFooter'
import ReportTotal from '../reportTotal/ReportTotal'
import { LoadingSpinner, PageDialog } from '../../../ui-kit'
import {
  getDepositsReportColumns,
  getDepositsReportFiltersColumns,
  getDepositsReportTotalItems,
} from './logic'
import { getCustomRowId, getReportsData, getReportsTotal } from '../logic/reportsUtils'
import ReportsFilters from '../reportsFilters/ReportsFilters'
import { ContractsAutocompleteQuery } from '../../../queries/contracts.gql'
import { getLoadingFunc } from '../../invoices/invoicesUtils'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { getPaginationData } from '../../../utils/utils'

const Deposits = ({
  data,
  isOpened,
  closeReport,
  reportMetadata,
  authToken,
  userFilters,
  page,
}) => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const columns = useMemo(() => getDepositsReportColumns(t), [t])
  const rows = useMemo(() => getReportsData(data), [data])
  const total = useMemo(() => getReportsTotal(data), [data])
  const getRowId = useCallback((row) => getCustomRowId(row, 'depositMemoNumber'), [])
  const totalItems = useMemo(() => getDepositsReportTotalItems(total), [total])
  const paginationData = getPaginationData(data)

  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    queryOptions: {
      skip: !isOpened,
    },
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'Deposits' },
  })

  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')

  const filterColumns = useMemo(
    () =>
      getDepositsReportFiltersColumns(t, {
        loadContractOptions,
      }),
    [t, loadContractOptions],
  )

  return (
    <PageDialog
      bottomPanel={
        <ReportFooter
          authToken={authToken}
          closeReport={closeReport}
          reportMetadata={reportMetadata}
          sort={queryParams.sort}
          userFilters={userFilters}
        />
      }
      isOpened={isOpened}
      setIsOpened={closeReport}
      title={t('deposits')}>
      {!data ? (
        <LoadingSpinner className="h-full w-full" loading />
      ) : (
        <div className="px-8 w-full">
          <ReportsFilters availableFilters={data.availableFilters} columns={filterColumns} />

          <div className="pt-4">
            <DataGridComponent
              columns={columns}
              getRowId={getRowId}
              page={page}
              pageSize={100}
              paginationData={paginationData}
              rows={rows}
            />
          </div>

          <ReportTotal
            itemSeparatorClassName="ml-4"
            items={totalItems}
            totalLabelKey="grandTotal"
            wrapperClassName="py-4 px-2"
          />
        </div>
      )}
    </PageDialog>
  )
}

Deposits.propTypes = {
  data: PropTypes.object,
  page: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  authToken: PropTypes.string.isRequired,
  closeReport: PropTypes.func.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.any),
  reportMetadata: PropTypes.shape({
    csvUrl: PropTypes.string,
    xlsxUrl: PropTypes.string,
    jsonUrl: PropTypes.string,
    fileNameKey: PropTypes.string,
    defaultFilters: PropTypes.object,
  }).isRequired,
}

export default Deposits

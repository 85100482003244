import React, { useCallback } from 'react'
import { Dialog } from '../../../ui-kit'
import UpdateProjectForm from '../editProject/UpdateProjectForm'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'

const UpdateProjectModal = ({
  isOpened,
  contractId,
  data,
  setIsOpenedModal,
  setIsSidebarOpened,
  setSelectedProject,
}) => {
  const { t } = useTranslation()

  const closeForm = useCallback(() => {
    setIsOpenedModal(false)
    setIsSidebarOpened(true)
  }, [setIsOpenedModal, setIsSidebarOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('editProjectInfo')}
      titleClass="text-2xl font-semibold">
      <UpdateProjectForm
        closeForm={closeForm}
        contractId={contractId}
        data={data}
        isFormDirty={isFormDirty}
        requestClose={requestClose}
        setDirtyFormState={setDirtyFormState}
        setIsOpenedModal={setIsOpenedModal}
        setIsSidebarOpened={setIsSidebarOpened}
        setSelectedProject={setSelectedProject}
      />
    </Dialog>
  )
}

UpdateProjectModal.propTypes = {
  data: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  contractId: PropTypes.string.isRequired,
  setIsOpenedModal: PropTypes.func.isRequired,
  setIsSidebarOpened: PropTypes.func.isRequired,
  setSelectedProject: PropTypes.func.isRequired,
}

export default UpdateProjectModal

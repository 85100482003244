import { paymentMethodTypes, paymentResultMods } from '../constants/paymentResults'
import { paymentTransactionStatuses } from '../pages/payments/paymentsTab/util'

export const getTransactionPaymentResult = (
  paymentTransaction,
  setFailureReason,
  isAuthorizationRunning = false,
) => {
  if (!paymentTransaction) {
    return
  }

  let transactionPaymentResult = null

  if (paymentTransaction.paymentMethod?.type === paymentMethodTypes.ACH_PAYMENT_METHOD) {
    if (paymentTransaction.status === paymentTransactionStatuses.SUBMITTED) {
      transactionPaymentResult = paymentResultMods.ACH_SUCCESS
    }
    if (paymentTransaction.status === paymentTransactionStatuses.FAILED) {
      transactionPaymentResult = paymentResultMods.ACH_FAILURE
    }
  } else {
    if (
      paymentTransaction.status === paymentTransactionStatuses.PAID ||
      paymentTransaction.status === paymentTransactionStatuses.AUTHORIZED
    ) {
      if (isAuthorizationRunning) {
        transactionPaymentResult = paymentResultMods.CARD_AUTHORIZATION_SUCCESS
      } else if (
        paymentTransaction.paymentMethod?.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD ||
        paymentTransaction.paymentMethod?.type === paymentMethodTypes.CREDIT_DEPOSIT_PAYMENT_METHOD
      ) {
        transactionPaymentResult = paymentResultMods.PAYCHECK_CREDIT_DEPOSIT_SUCCESS
      } else {
        transactionPaymentResult = paymentResultMods.CREDIT_CARD_SUCCESS
      }
    } else if (
      paymentTransaction.status === paymentTransactionStatuses.FAILED ||
      paymentTransaction.status === paymentTransactionStatuses.CHARGEBACK
    ) {
      transactionPaymentResult = isAuthorizationRunning
        ? paymentResultMods.CARD_AUTHORIZATION_FAILURE
        : paymentResultMods.CREDIT_CARD_FAILURE
      setFailureReason(paymentTransaction.failureReason)
    }
  }

  return transactionPaymentResult
}

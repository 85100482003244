import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Button, Dialog, Flex, Icon, InfoNote, Money, Text, Tooltip } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import React, { useEffect, useState } from 'react'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import {
  displayStatusesMapping,
  getRecipientsViaEmail,
  paymentTransactionDisplayStatuses,
} from './util'
import { ResendReceiptMutation } from '../../../queries/mutations/resendReceipt.gql'
import MemoField from '../../../ui-kit/components/inputs/MemoField'
import { PaymentsQuery } from '../../../queries/paymentTransactions.gql'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { paymentMethodTypes } from '../../../constants/paymentResults'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { PaymentTransactionQuery } from '../../../queries/paymentTransactions.gql'
import getPresentationName, { getPresentationGuestName } from '../../../utils/getPresentationName'
import PaymentInvoicesDetailsModal from './PaymentInvoicesDetailsModal'
import { useNavigate } from 'react-router-dom'
import colors from '../../../ui-kit/colors'
import UpdateAttachmentForm from '../../contracts/cashApp/updateAttachmentForm/UpdateAttachmentForm'
import { UpdatePayment } from '../../../queriesUpdated/mutations/updatePayment.gql'
import ResendPaymentReceiptForm from './ResendPaymentReceiptForm'
import { useNotifications } from '../../../hooks/useNotifications'

const PaymentContent = ({
  id,
  paymentMethod,
  voidTransaction,
  contractId,
  isAuthorization,
  paymentMethodNickname,
  attachmentRefetchQueries,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [transaction, setTransaction] = useState({})
  const [data, setData] = useState({})
  const [isPaymentInvoicesDetailsModalOpened, setIsPaymentInvoicesDetailsModalOpened] =
    useState(false)
  const [isAttachmentMemoModalOpened, setIsAttachmentMemoModalOpened] = useState(false)
  const [isResendPaymentModalOpened, setIsResendPaymentModalOpened] = useState(false)

  const queryVariables = {
    id,
  }

  const { data: transactionData } = useCustomQuery({
    query: PaymentTransactionQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !id,
    },
    rollbarOptions: { operationName: 'PaymentTransactionQuery', target: 'PaymentContent' },
  })

  useEffect(() => {
    setTransaction(transactionData?.paymentTransaction || {})
    setData(transactionData?.paymentTransaction?.payable || {})
  }, [transactionData])

  const getSource = () => {
    if (!data) {
      return ''
    }
    if (!data.createdBy && data?.paymentRequest) {
      return t('paymentRequest')
    }
    // eslint-disable-next-line
    if (data.createdBy?.__typename === 'BuyerUser' || !data.createdBy) {
      return t('customerPortal')
    }
    // eslint-disable-next-line
    if (data.createdBy?.__typename === 'VendorUser') {
      return t('supplierPortal')
    }
  }
  const getUserName = () => {
    const paymentRequest = data.paymentRequest
    if (!data.createdBy && paymentRequest) {
      const contacts = paymentRequest
        ? [...paymentRequest.recipientsViaEmail, ...paymentRequest.recipientsViaText]
        : []
      return (
        <>
          {contacts.slice(0, 2).map((contact) => (
            <Text key={contact?.id}>
              {contact.user
                ? getPresentationName(contact, t)
                : getPresentationGuestName(contact?.email, contact?.formattedPhoneNumber, t)}
            </Text>
          ))}
          {contacts.length > 2 && <Text>+{t('additionalRecipients')}</Text>}
        </>
      )
    }
    if (!data.createdBy && !paymentRequest) {
      return t('system')
    }
    return data.createdBy?.fullName
  }

  const descriptionData = isAuthorization
    ? [
        {
          label: t('status'),
          value: (
            <StatusBadge
              color={displayStatusesMapping[transaction.displayStatus]?.color}
              value={t(displayStatusesMapping[transaction.displayStatus]?.label)}
            />
          ),
        },
        {
          label: t('details'),
          value: (
            <Tooltip
              className="text-ellipsis overflow-hidden max-w-72 whitespace-nowrap text-sm"
              content={transaction.failureReason}
              placement="bottom">
              {transaction.failureReason}
            </Tooltip>
          ),
          hidden: !transaction.failureReason,
        },
        { label: t('erpID'), value: data?.erpId || '-' },
        {
          label: t('paymentMethod'),
          value:
            paymentMethod || transaction.paymentMethod?.title
              ? `${paymentMethod || transaction.paymentMethod?.title}${
                  paymentMethodNickname || transaction.paymentMethod?.nickname
                    ? ` (${paymentMethodNickname || transaction.paymentMethod?.nickname})`
                    : ''
                }`
              : '-',
        },
        {
          label: t('depositedTo'),
          value: data?.financialEntity?.name || '-',
        },
        {
          label: t('authDate'),
          value: <FormattedDate date={transaction?.createdAt} format={'MM/dd/yyyy h:mm a'} />,
        },
        {
          label: t('expirationDate'),
          value: (
            <FormattedDate date={transaction?.authExpirationAt} format={'MM/dd/yyyy h:mm a'} />
          ),
        },
        { label: t('source'), value: getSource() },
        {
          label: !data.createdBy && data.paymentRequest ? t('sentTo') : t('createdBy'),
          value: getUserName(),
        },
        {
          label: t('customer'),
          node: (
            <Button
              label={data?.contract?.buyer?.name}
              onClick={() => navigate(`/customers/${data?.contract?.id}/overview`)}
              size={sizes.SM}
              testData="customer-link"
              variant={buttonsVariants.LINK}
            />
          ),
        },
      ]
    : [
        {
          label: t('status'),
          value: (
            <StatusBadge
              color={displayStatusesMapping[transaction.displayStatus]?.color}
              value={t(displayStatusesMapping[transaction.displayStatus]?.label)}
            />
          ),
        },
        {
          label: t('details'),
          value: (
            <Tooltip
              className="text-ellipsis overflow-hidden max-w-72 whitespace-nowrap text-sm"
              content={transaction.failureReason}
              placement="bottom">
              {transaction.failureReason}
            </Tooltip>
          ),
          hidden: !transaction.failureReason,
        },
        { label: t('erpID'), value: data?.erpId || '-' },
        {
          label: t('paymentMethod'),
          value:
            paymentMethod || transaction.paymentMethod?.title
              ? `${paymentMethod || transaction.paymentMethod?.title}${
                  paymentMethodNickname || transaction.paymentMethod?.nickname
                    ? ` (${paymentMethodNickname || transaction.paymentMethod?.nickname})`
                    : ''
                }`
              : '-',
        },
        {
          label: t('depositedTo'),
          value: data?.financialEntity?.name || '-',
        },
        {
          label: t('paymentDate'),
          value: <FormattedDate date={transaction?.entryDate} format={'MM/dd/yyyy'} />,
        },
        {
          label: t('createdOn'),
          value: <FormattedDate date={transaction?.processedAt} format={'MM/dd/yyyy h:mm a'} />,
        },
        {
          label: t('settlementDate'),
          value: <FormattedDate date={transaction.settledAt} format={'MM/dd/yyyy h:mm a'} />,
          hidden: transaction?.paymentMethod?.type !== paymentMethodTypes.ACH_PAYMENT_METHOD,
        },
        { label: t('source'), value: getSource() },
        {
          label: !data.createdBy && data.paymentRequest ? t('sentTo') : t('createdBy'),
          value: getUserName(),
        },
        {
          label: t('customer'),
          node: (
            <Button
              label={data?.contract?.buyer?.name}
              onClick={() => navigate(`/customers/${data?.contract?.id}/overview`)}
              size={sizes.SM}
              testData="customer-link"
              variant={buttonsVariants.LINK}
            />
          ),
        },
      ]

  const paymentDescriptionData1 = [
    {
      label: t('outstanding'),
      value: <Money value={data.outstandingAmountCents} />,
    },
    {
      label: `(+) ${t('financeCharges')}`,
      value: <Money value={data.financeChargesAmountCents} />,
    },
  ]
  const paymentDescriptionData2 = [
    {
      label: `(-) ${t('discountsApplied')}`,
      value: <Money value={data.discountsAppliedCents} />,
    },
    {
      label: `(-) ${t('depositsApplied')}`,
      value: <Money value={data.depositsAppliedCents} />,
    },
    {
      label: `(-) ${t('creditsApplied')}`,
      value: <Money value={data.creditsAppliedCents} />,
    },
  ]
  const paymentDescriptionData3 = [
    {
      label: `(+) ${t('cardConvenienceFee')}`,
      value: <Money value={data.feeAmountCents} />,
    },
  ]
  const [memoFormIsDirty, setMemoFormIsDirty] = useState(false)
  const [updatePaymentMemo, { loading }] = useCustomMutation({
    mutation: UpdatePayment,
    rollbarOptions: { operationName: 'UpdatePayment', target: 'PaymentContent' },
    mutationOptions: {
      refetchQueries: [PaymentsQuery],
    },
  })

  const [updatePaymentFile, { loading: updatePaymentFileLoading }] = useCustomMutation({
    mutation: UpdatePayment,
    rollbarOptions: {
      operationName: 'UpdatePayment',
      target: 'PaymentContent',
    },
    onCompleted: () => setIsAttachmentMemoModalOpened(false),
    mutationOptions: {
      refetchQueries: [PaymentTransactionQuery, ...attachmentRefetchQueries],
    },
  })
  const onAttachmentSubmit = (values) => {
    const variables = { id: data.id, data: { file: values.file } }
    updatePaymentFile({ variables })
  }
  const handleDeleteAttachment = () => {
    const variables = { id: data.id, data: { file: null } }
    updatePaymentFile({ variables })
  }
  const { newNotification } = useNotifications()

  const [resendReceipt, { loading: isResendReceiptLoading }] = useCustomMutation({
    mutation: ResendReceiptMutation,
    rollbarOptions: { operationName: 'PaymentRequestMutation', target: 'InvoicesOutstanding' },
  })

  const buyerId = data?.contract?.buyer?.id
  const buyerName = data?.contract?.buyer?.name
  const handleSubmitResendPayment = (recipientsFullListValues) => {
    const recipientsViaEmail = getRecipientsViaEmail(recipientsFullListValues)
    const variables = {
      data: {
        recipientsViaEmail,
      },
      id: transactionData?.paymentTransaction?.id, //Ask David paymentTransaction or PaymentId
    }
    resendReceipt({ variables }).then(({ data }) => {
      const responseData = data?.resendReceipt || {}
      setIsResendPaymentModalOpened(false)

      if (responseData?.entity) {
        newNotification({ success: t('paymentReceiptSuccessfullySent') })
      }
    })
  }

  return (
    <>
      <div className="flex flex-col mt-[-1.5rem]">
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
              {t('invoicePayment')}
            </Text>
          </div>

          <div className="flex flex-row">
            <div className="mr-2">
              <InfoNote label={t('id')}>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                  {id || data.paymentTransaction?.id}
                </Text>
              </InfoNote>
            </div>
          </div>
        </div>

        <div className="flex flex-row mt-3">
          <div className="w-6/12 flex flex-col">
            <SidebarLabel text="paymentAmount" />
            <Money className="text-2xl mt-1 font-medium" value={data.totalAmountCents} />
          </div>
        </div>

        <DividedList content={descriptionData} />

        <Text className="mt-10" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL}>
          {t('paymentDetails')}
        </Text>

        <DividedList content={paymentDescriptionData1} skipDivide />
        <Flex alignItems="center" className="border-t min-h-10" justifyContent="between">
          <Text fontWeight={fontWeight.BOLD}>{t('totalPayment')}</Text>
          <Money className="font-semibold text-xl" value={data.totalAmountCents} />
        </Flex>

        <DividedList content={paymentDescriptionData2} skipDivide />
        <Flex alignItems="center" className="border-t min-h-10" justifyContent="between">
          <Text fontWeight={fontWeight.BOLD}>{t('netPayment')}</Text>
          <Money className="font-semibold text-xl" value={data.chargeAmountCents} />
        </Flex>

        <DividedList content={paymentDescriptionData3} />
        <Flex alignItems="center" className="border-t min-h-10" justifyContent="between">
          <Text fontWeight={fontWeight.BOLD}>{t('totalCharged')}</Text>
          <Money className="font-semibold text-xl" value={data.totalChargedCents} />
        </Flex>

        <MemoField
          closeForm={() => null}
          entityId={data.id}
          initialValue={data.memo}
          isFormDirty={memoFormIsDirty}
          isLoading={loading}
          labelKey="memo"
          responseEntityName={'updatePayment'}
          setDirtyFormState={setMemoFormIsDirty}
          updateEntity={updatePaymentMemo}
        />
        {!data.file ? (
          <Button
            className="mt-8"
            iconName="plus"
            label={t('addAttachment')}
            onClick={() => setIsAttachmentMemoModalOpened(true)}
            size={sizes.SM}
            testData="add-new-doc"
            variant={buttonsVariants.LINK}
          />
        ) : (
          <Flex alignItems="center" className="mt-8">
            <Button
              className="w-fit underline text-left"
              label={data.file.filename}
              onClick={() => window.open(data.file.url)}
              size={sizes.SM}
              testData="view-file"
              variant={buttonsVariants.LINK}
            />
            <Icon
              className="min-w-5 ml-2"
              color={colors.GREY}
              disabled={updatePaymentFileLoading}
              name="pencil"
              onClick={() => setIsAttachmentMemoModalOpened(true)}
            />
            <Icon
              className="min-w-5 ml-2"
              color={colors.GREY}
              disabled={updatePaymentFileLoading}
              name="trash"
              onClick={handleDeleteAttachment}
            />
          </Flex>
        )}
        <Dialog
          isOpened={isAttachmentMemoModalOpened}
          setIsOpened={setIsAttachmentMemoModalOpened}
          title={t('addAttachment')}>
          <UpdateAttachmentForm
            onSubmit={onAttachmentSubmit}
            setIsOpenedModal={setIsAttachmentMemoModalOpened}
            fileRequired
          />
        </Dialog>
        <PaymentInvoicesDetailsModal
          contractId={contractId}
          invoices={data.invoices}
          isOpened={isPaymentInvoicesDetailsModalOpened}
          payableId={data.id}
          paymentDate={transaction?.entryDate}
          paymentMethod={paymentMethod || data.paymentMethod?.title}
          setIsOpened={setIsPaymentInvoicesDetailsModalOpened}
          transactionId={id || transaction.id}
        />
        <Button
          className="mt-8"
          label={t('viewDetails')}
          onClick={() => setIsPaymentInvoicesDetailsModalOpened(true)}
          testData="payment-details-modal-button"
          variant={buttonsVariants.SECONDARY}
        />
        {transaction.displayStatus === paymentTransactionDisplayStatuses.SUCCESSFUL && (
          <Button
            className="mt-8"
            label={t('resendReceipt')}
            onClick={() => setIsResendPaymentModalOpened(true)}
            testData="resend-receipt-button"
            variant={buttonsVariants.SECONDARY}
          />
        )}
        {transaction.voidable && (
          <Button
            className="mt-8"
            label={t('void')}
            onClick={voidTransaction}
            testData="void-transaction"
            variant={buttonsVariants.SECONDARY}
          />
        )}
      </div>
      {isResendPaymentModalOpened && (
        <ResendPaymentReceiptForm
          buyerId={buyerId}
          buyerName={buyerName}
          contractId={data?.contract?.id}
          isLoading={isResendReceiptLoading}
          isOpened={isResendPaymentModalOpened}
          onSubmit={handleSubmitResendPayment}
          paymentId={transactionData?.paymentTransaction?.id}
          setIsOpened={setIsResendPaymentModalOpened}
        />
      )}
    </>
  )
}

PaymentContent.propTypes = {
  id: PT.string,
  contractId: PT.string,
  paymentMethod: PT.string,
  voidTransaction: PT.func,
  isAuthorization: PT.bool,
  paymentMethodNickname: PT.string,
  attachmentRefetchQueries: PT.arrayOf(PT.any),
}

PaymentContent.defaultProps = {
  id: '',
  contractId: '',
  isAuthorization: false,
  attachmentRefetchQueries: [],
}

export default PaymentContent

import React, { useCallback, useMemo } from 'react'
import { Button, Flex, Heading, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import sizes from '../../../ui-kit/sizes'
import fontWeight from '../../../ui-kit/fontWeight'
import { SetDefaultPaymentMethod } from '../../../queries/mutations/setDefaultPaymentMethod.gql'
import PT from 'prop-types'
import paymentMethodType from '../../../types/paymentMethod'
import { paymentMethodTypes } from '../../../constants/paymentResults'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const PaymentMethodDetails = ({
  data,
  setIsSidebarOpened,
  refetch,
  setConfirmDeleteModalOpened,
  seIsUpdateModalOpened,
}) => {
  const { t } = useTranslation()

  const onCompleted = useCallback(() => {
    refetch()
    setIsSidebarOpened(false)
  }, [])
  const [setDefaultPaymentMethod, { loading }] = useCustomMutation({
    mutation: SetDefaultPaymentMethod,
    onCompleted,
    rollbarOptions: { operationName: 'SetDefaultPaymentMethod', target: 'PaymentMethodDetails' },
  })

  const onSetDefault = (id, isDefault) => {
    setDefaultPaymentMethod({
      variables: {
        id,
        default: isDefault,
      },
    })
  }
  const descriptionData = useMemo(
    () =>
      data.type === paymentMethodTypes.CREDIT_CARD_PAYMENT_METHOD
        ? [
            { label: t('type'), value: data?.data?.['card-type'] },
            { label: t('lastFourDigits'), value: `*${data?.data?.['last-four-digits']}` },
            { label: t('nickname'), value: `${data?.nickname ? data?.nickname : '-'}` },
            {
              label: t('expirationDate'),
              value: `${data?.data?.['exp-date']?.substr?.(0, 2)}/${data?.data?.[
                'exp-date'
              ]?.substr?.(2, 2)}`,
            },
          ]
        : [
            { label: t('type'), value: t(`${data?.data?.type?.toLowerCase?.()}Account`) },
            { label: t('nickname'), value: `${data?.nickname ? data?.nickname : '-'}` },
          ],
    [t, data],
  )
  return (
    <>
      <Flex className="h-full" column>
        <Heading>
          <Text
            className="max-w-4/5 px-3 ellipsis"
            fontWeight={fontWeight.SEMIBOLD}
            size={sizes.XL}>
            {data?.title}
          </Text>
        </Heading>
        <Flex className="px-4 pt-6 flex-1" column>
          <Flex className="flex-1 w-full">
            <DividedList content={descriptionData} />
          </Flex>
          <Flex className="w-full py-5" column>
            {!data?.default && (
              <Button
                disabled={loading}
                label={t('setAsDefaultPaymentMethod')}
                onClick={() => onSetDefault(data?.id, true)}
                testData="set-default-pm"
                fullWidth
              />
            )}
            <Button
              className={!data?.default && 'mt-5'}
              label={t('updateNickname')}
              onClick={() => {
                setIsSidebarOpened(false)
                seIsUpdateModalOpened(true)
              }}
              testData="update-pm-nickname"
              variant={buttonsVariants.TERTIARY}
              fullWidth
            />
            <Button
              className="mt-5"
              label={t('removePaymentMethod')}
              onClick={() => {
                setIsSidebarOpened(false)
                setConfirmDeleteModalOpened(true)
              }}
              testData="remove-pm"
              variant={buttonsVariants.TERTIARY}
              fullWidth
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default PaymentMethodDetails

PaymentMethodDetails.propTypes = {
  data: paymentMethodType,
  setIsSidebarOpened: PT.bool.isRequired,
  refetch: PT.func.isRequired,
  setConfirmDeleteModalOpened: PT.func,
  seIsUpdateModalOpened: PT.func,
}

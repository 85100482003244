export default {
  XS: 'xs', // 	font-size: 0.75rem;  (12px)   line-height: 1rem;    (16px)
  SM: 'sm', // 	font-size: 0.875rem; (14px)   line-height: 1.25rem; (20px)
  BASE: 'base', // 	font-size: 1rem;     (16px)   line-height: 1.5rem;  (24px)
  LG: 'lg', // 	font-size: 1.125rem; (18px)   line-height: 1.75rem; (28px)
  XL: 'xl', // 	font-size: 1.25rem;  (20px)   line-height: 1.75rem; (28px)
  XL2: '2xl', // 	font-size: 1.5rem;   (24px)   line-height: 2rem;    (32px)
  XL3: '3xl', // 	font-size: 1.875rem; (30px)   line-height: 2.25rem; (36px)
  XL4: '4xl', // 	font-size: 2.25rem;  (36px)   line-height: 2.5rem;  (40px)
}

import React, { useCallback, useMemo, useState } from 'react'
import PT from 'prop-types'
import { Button, ConfirmationDialog, Flex, Heading, Icon, Money, Tooltip } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import { getPaginationData } from '../../../utils/utils'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { camelCase, toInteger } from 'lodash'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { CreditReviewsQuery } from '../../../queries/creditReviews.gql'
import Sidebar from '../../../ui-kit/components/sidebar/Sidebar'
import CreditReviewContent from './creditReview/CreditReviewContent'
import CreditReviewDocumentUploadModal from './creditReview/CreditReviewDocumentUploadModal'
import AddCreditLimitModal from './creditReview/AddCreditLimitModal'
import { ContractQuery } from '../../../queries/contracts.gql'
import { DeleteCreditReview } from '../../../queries/mutations/deleteCreditReview.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const CreditReview = ({ contractData }) => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const { newNotification } = useNotifications()
  const defaultSort = 'review_date.desc'
  const sort = queryParams.sort || defaultSort
  const page = toInteger(queryParams.page) || 1
  const [selectedCreditReview, setSelectedCreditReview] = useState({})
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [isAddCreditReviewDocumentModalOpened, setIsAddCreditReviewDocumentModalOpened] =
    useState(false)
  const [isAddCreditReviewModalOpened, setIsAddCreditReviewModalOpened] = useState(false)
  const [isOpenedDeleteCreditReviewModal, setIsOpenedDeleteCreditReviewModal] = useState(false)

  const reopenSidebar = useCallback(() => {
    !!Object.keys(selectedCreditReview).length && setIsSidebarOpened(true)
  }, [selectedCreditReview, setIsSidebarOpened])
  const resetSidebarData = useCallback(
    (creditReviews) => {
      if (selectedCreditReview) {
        const selectedEntityId = selectedCreditReview.id
        const selectedEntityLoadedData = creditReviews?.data.find(
          (creditReview) => creditReview.id === selectedEntityId,
        )
        if (selectedEntityLoadedData) {
          setSelectedCreditReview(selectedEntityLoadedData)
          reopenSidebar()
        }
      }
    },
    [selectedCreditReview, reopenSidebar],
  )
  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(
    () => ({
      contractId: contractData?.id,
      sort,
      page,
      perPage: pageSize,
    }),
    [sort, page, pageSize, contractData?.id],
  )
  const onCompleted = useCallback((response) => {
    resetSidebarData(response?.creditReviews)
  }, [])
  const { data, loading } = useCustomQuery({
    query: CreditReviewsQuery,
    onCompleted,
    queryOptions: {
      variables: queryVariables,
      skip: !contractData?.id,
    },
    rollbarOptions: { operationName: 'CreditReviewsQuery', target: 'CreditReview' },
  })

  const creditReviews = data?.creditReviews
  const rows = creditReviews?.data || []
  const paginationData = getPaginationData(creditReviews)

  const sideBarHandler = useCallback((values) => {
    setSelectedCreditReview(values?.row)
    setIsSidebarOpened(true)
  }, [])

  const columns = useMemo(
    () => [
      {
        field: 'reviewDate',
        headerName: t('date'),
        renderCell: (values) => <FormattedDate date={values?.row?.reviewDate} />,
        flex: 7,
      },
      {
        field: 'id',
        headerName: t('id'),
        renderCell: (values) => (
          <Button
            label={values?.row?.sequentialId}
            onClick={() => sideBarHandler(values)}
            size={sizes.SM}
            testData="credit-review-detail"
            variant={buttonsVariants.LINK}
          />
        ),
        flex: 7,
      },
      {
        field: 'createdBy',
        headerName: t('createdBy'),
        renderCell: (values) => values?.row?.createdBy?.fullName,
        flex: 11,
      },
      {
        field: 'creditLimitCents',
        headerName: t('recommendedCreditLimitShort'),
        renderCell: (values) => <Money value={values?.row?.creditLimitCents} />,
        flex: 10,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'nextReviewDate',
        headerName: t('nextReviewDate'),
        renderCell: (values) => <FormattedDate date={values?.row?.nextReviewDate} />,
        flex: 10,
      },
      {
        field: 'memo',
        headerName: t('notes'),
        sortable: false,
        renderCell: (values) => (
          <Tooltip className="text-ellipsis overflow-hidden" content={values?.row?.memo}>
            {values?.row?.memo}
          </Tooltip>
        ),
        flex: 23,
      },
      {
        field: 'documents',
        headerName: '',
        sortable: false,
        renderCell: (values) =>
          !!values.row?.documents?.length && (
            <Icon color="text-black-500" name="paperClip" type="outline" />
          ),
        flex: 3,
      },
      {
        field: 'addDocuments',
        sortable: false,
        headerName: '',
        renderCell: (values) => {
          return (
            <Button
              iconName="plus"
              label={t('addDocument')}
              onClick={() => {
                setSelectedCreditReview(values?.row)
                setIsAddCreditReviewDocumentModalOpened(true)
              }}
              variant={buttonsVariants.LINK}
            />
          )
        },
        flex: 10,
      },
    ],
    [t],
  )

  const defaultSortModel = useMemo(() => {
    const sortSource = queryParams?.sort || defaultSort

    const [field, direction] = sortSource.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  const [deleteCreditReview, { loading: isDeleteCreditReviewLoading }] = useCustomMutation({
    mutation: DeleteCreditReview,
    rollbarOptions: { operationName: 'DeleteCreditReview', target: 'CreditReview' },
    mutationOptions: {
      refetchQueries: [CreditReviewsQuery, ContractQuery],
    },
  })
  const handleDeleteCreditReview = useCallback(() => {
    const variables = {
      id: selectedCreditReview?.id,
    }
    deleteCreditReview({ variables }).then(({ data }) => {
      const responseData = data?.deleteCreditReview || {}

      if (responseData?.entity) {
        newNotification({ success: t('creditReviewDeletedSuccessfully') })
        setIsOpenedDeleteCreditReviewModal(false)
      }
    })
  }, [selectedCreditReview])
  const handleCloseDeleteCreditReviewModal = useCallback(() => {
    setIsOpenedDeleteCreditReviewModal(false)
    setIsSidebarOpened(true)
  }, [setIsOpenedDeleteCreditReviewModal, setIsSidebarOpened])

  return (
    <Flex className="mt-4" column>
      <Sidebar
        clearSelectedInvoice={() => setSelectedCreditReview({})}
        isSidebarOpened={isSidebarOpened}
        setIsSidebarOpened={setIsSidebarOpened}>
        <CreditReviewContent
          data={selectedCreditReview}
          setIsAddCreditReviewDocumentModalOpened={setIsAddCreditReviewDocumentModalOpened}
          setIsOpenedDeleteCreditReviewModal={setIsOpenedDeleteCreditReviewModal}
          setIsSidebarOpened={setIsSidebarOpened}
        />
      </Sidebar>

      <Flex justifyContent="between">
        <Heading isBold={false} size={sizes.XL2}>
          {t('creditReviews')}
        </Heading>
        <Button
          iconName="plusCircle"
          iconType="outline"
          label={t('newReview')}
          onClick={() => setIsAddCreditReviewModalOpened(true)}
          testData="new-credit-review"
          variant={buttonsVariants.SECONDARY}
        />
      </Flex>
      <Flex className="pt-4">
        <DataGridComponent
          columns={columns}
          loading={loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rows={rows}
          sortModel={defaultSortModel}
        />
      </Flex>

      <AddCreditLimitModal
        contractId={contractData?.id}
        isOpened={isAddCreditReviewModalOpened}
        setIsOpened={setIsAddCreditReviewModalOpened}
      />

      <CreditReviewDocumentUploadModal
        creditReviewId={selectedCreditReview?.id}
        modalOpened={isAddCreditReviewDocumentModalOpened}
        onModalClose={() => {
          setIsSidebarOpened(true)
          setIsAddCreditReviewDocumentModalOpened(false)
        }}
        refetchQueries={[CreditReviewsQuery]}
      />

      <ConfirmationDialog
        confirmationMessage={t('deleteCreditReviewMessage')}
        isOpened={isOpenedDeleteCreditReviewModal}
        isSubmitButtonYesDisabled={isDeleteCreditReviewLoading}
        onModalClose={() => setIsSidebarOpened(true)}
        onSubmitButtonNoClick={handleCloseDeleteCreditReviewModal}
        onSubmitButtonYesClick={handleDeleteCreditReview}
        setIsOpened={setIsOpenedDeleteCreditReviewModal}
        title={t('deleteCreditReview')}
      />
    </Flex>
  )
}

CreditReview.propTypes = {
  contractData: PT.shape({
    id: PT.string,
  }).isRequired,
}

export default CreditReview

import React from 'react'
import { Button, Flex, Money } from '../../ui-kit'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import InvoiceActions from './invoiceActions/InvoiceActions'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import sizes from '../../ui-kit/sizes'

const InvoiceSelected = ({
  models,
  onDownloadAction,
  onReceivePaymentAction,
  onP2PAction,
  onPaymentPlanAction,
  onRequestPaymentAction,
  showPanel,
  handleClearAll,
  handleSelectAll,
  handleSelectAllPayable,
}) => {
  const { t } = useTranslation()

  const total = models.reduce((acc, item) => acc + item.outstandingAmountCents, 0)

  if (showPanel) {
    return (
      <Flex
        alignItems="center"
        className="w-full h-16 bg-white p-8 text-sm fixed bottom-0 right-0 lg:pl-56 md:pl-14"
        justifyContent="between"
        testData={'selectedInvoicesPanel'}
        row>
        <div className="pl-8">
          <span className="bg-primary primary px-2 py-1  rounded-md">{models.length}</span>
          <span className="pl-2">{t('selected')}</span>
          <span>
            {handleSelectAll && (
              <Button
                className="px-2 ml-4 w-25"
                disabled={!models.length}
                label={t('selectAll')}
                onClick={handleSelectAll}
                size={sizes.SM}
                testData="select-all-action"
                variant={buttonsVariants.SECONDARY}
              />
            )}
            {handleSelectAllPayable && (
              <Button
                className="px-2 ml-4 w-25"
                disabled={!models.length}
                label={t('selectAllPayable')}
                onClick={handleSelectAllPayable}
                size={sizes.SM}
                testData="select-all-payable-action"
                variant={buttonsVariants.SECONDARY}
              />
            )}
            <Button
              className="px-2 ml-4 w-25"
              disabled={!models.length}
              label={t('clearAll')}
              onClick={handleClearAll}
              size={sizes.SM}
              testData="clear-all-action"
              variant={buttonsVariants.SECONDARY}
            />
          </span>
        </div>

        <div>
          {t('totalOutstanding')}:
          <span className="font-bold pl-2">
            <Money value={total} />
          </span>
        </div>
        <InvoiceActions
          alignment="top"
          models={models}
          onDownloadAction={onDownloadAction}
          onP2PAction={onP2PAction}
          onPaymentPlanAction={onPaymentPlanAction}
          onReceivePaymentAction={onReceivePaymentAction}
          onRequestPaymentAction={onRequestPaymentAction}
        />
      </Flex>
    )
  }

  return null
}

InvoiceSelected.propTypes = {
  models: PT.arrayOf(
    PT.shape({
      outstandingAmountCents: PT.number,
    }),
  ),
  onDownloadAction: PT.func,
  handleClearAll: PT.func,
  onP2PAction: PT.func,
  onPaymentPlanAction: PT.func,
  onReceivePaymentAction: PT.func,
  onRequestPaymentAction: PT.func,
  showPanel: PT.bool.isRequired,
  handleSelectAll: PT.func,
  handleSelectAllPayable: PT.func,
}
InvoiceSelected.defaultProps = {
  models: [],
}

export default InvoiceSelected

import { useDataStorage } from './useDataStorage'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { getPaginationData } from '../utils/utils'
import { useRollbar } from '@rollbar/react'

export const useAllRecordsFetch = ({ query, entityName, queryOptions, rollbarOptions } = {}) => {
  const rollbar = useRollbar()
  const [isReady, setIsReady] = useState(false)
  const [fetch] = useLazyQuery(query, {
    context: {
      rollbar,
      rollbarOptions,
    },
    fetchPolicy: 'network-only',
    ...queryOptions,
  })
  const { items, addItems, resetItems } = useDataStorage({
    entityName,
    isManualDataUpdate: true,
  })
  const isFetchingBlocked = useRef(false)
  const [paginationData, setPaginationData] = useState()
  useEffect(() => () => (isFetchingBlocked.current = true), [])

  const fetchRecords = useCallback(async (variables) => {
    let page = 1
    let hasMore = false

    do {
      try {
        if (isFetchingBlocked.current) {
          break
        }

        const { data } = await fetch({ variables: { ...variables, page } })
        const response = data[entityName]
        addItems(response.data)
        const nextPaginationData = getPaginationData(response)
        hasMore = nextPaginationData.to < nextPaginationData.totalCount
        page += 1

        if (!hasMore) {
          setPaginationData(nextPaginationData)
        }
      } catch (err) {
        resetItems()
        console.warn(err)
        break
      }
    } while (hasMore)

    setIsReady(true)
  }, [])
  const resetRecords = useCallback(() => {
    setIsReady(false)
    resetItems()
    setPaginationData(void 0)
  }, [resetItems])
  const refetchRecords = useCallback(() => {
    resetRecords()
    fetchRecords()
  }, [resetItems, resetRecords])

  return { resetRecords, records: items, refetchRecords, isReady, paginationData }
}

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { styled } from '@mui/material/styles'
import Icon from '../icons/Icon'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import sizes from '../../sizes'

const AccordionComponent = styled((props) => (
  <MuiAccordion
    TransitionProps={{ unmountOnExit: true }}
    classes={{ root: cx(props.className) }}
    elevation={0}
    disableGutters
    {...props}
  />
))(() => ({
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-rounded': {
    borderRadius: '6px',
  },
}))

const AccordionSummaryOverride = styled((props) => (
  <MuiAccordionSummary expandIcon={<Icon name="chevronDown" size={sizes.LG} />} {...props} />
))((props) => ({
  '&': {
    minHeight: '42px',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    display: props.isMobile && 'flex',
    justifyContent: props.isMobile && 'center',
  },
  borderRadius: '6px',
}))
const AccordionSummary = styled(AccordionSummaryOverride)`
  ${({ isExpanded, isMobile }) =>
    !isMobile ? (isExpanded ? 'background-color: #FFF9F8;' : '') : ''}
  ${({ isMobile }) => (isMobile ? 'border: solid;' : '')}
`

const AccordionDetails = styled((props) => (
  <MuiAccordionDetails {...props} classes={{ root: cx(props.className) }} />
))((props) => ({
  padding: 0,
  marginTop: props.isMobile && '0.875rem',
}))

const Accordion = ({
  id,
  isExpanded,
  setIsExpanded,
  createSummary,
  summaryLabel,
  summaryProps,
  wrapperClassName,
  detailsClassName,
  createDetails,
  children,
  detailsProps,
  isMobile,
}) => {
  const handleChange = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded, setIsExpanded])

  return (
    <AccordionComponent
      className={cx('report-accordion', wrapperClassName)}
      expanded={isExpanded}
      id={id}
      onChange={handleChange}>
      <AccordionSummary
        className={isMobile && '!border !border-orange-700'}
        id={`accordion-summary-${id}`}
        isExpanded={isExpanded}
        isMobile={isMobile}>
        {createSummary ? createSummary(summaryProps) : summaryLabel}
      </AccordionSummary>
      <AccordionDetails className={detailsClassName} isMobile={isMobile}>
        {createDetails ? createDetails(detailsProps) : children}
      </AccordionDetails>
    </AccordionComponent>
  )
}

Accordion.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isMobile: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  summaryLabel: PropTypes.node,
  summaryProps: PropTypes.shape({
    label: PropTypes.string,
    iconName: PropTypes.string,
    isExpanded: PropTypes.bool,
  }),
  detailsProps: PropTypes.shape({
    label: PropTypes.string,
    iconName: PropTypes.string,
    isExpanded: PropTypes.bool,
  }),
  setIsExpanded: PropTypes.func.isRequired,
  createSummary: PropTypes.func,
  createDetails: PropTypes.func,
  wrapperClassName: PropTypes.string,
  detailsClassName: PropTypes.string,
}

Accordion.defaultProps = {
  children: null,
  summaryLabel: null,
  summaryProps: {},
  detailsProps: {},
  isMobile: false,
}

export default Accordion

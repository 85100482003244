import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Icon, Text } from '../../../ui-kit'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { RecentEventsTotalsQuery } from '../../../queries/recentEventsTotals.gql'
import {
  getRecentEventsTotals,
  getRecentEventsTotalsQueryParams,
  handleRecentEventsItemClick,
  totalsButtons,
} from './logic/utils'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import classes from './DashboardRecentEvents.module.scss'
import { CreateOrUpdateUserView } from '../../../queries/mutations/createOrUpdateUserView.gql'
import RecentEventsDetails from './RecentEventsDetails'
import { keys } from 'lodash'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'

const DashboardRecentEvents = () => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const [selectedEvent, setSelectedEvent] = useState(null)

  const queryVariables = useMemo(() => getRecentEventsTotalsQueryParams(currentUser), [currentUser])
  const { data: recentEventsTotalsData } = useCustomQuery({
    query: RecentEventsTotalsQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !queryVariables.vendorId,
    },
    rollbarOptions: { operationName: 'RecentEventsTotalsQuery', target: 'DashboardRecentEvents' },
  })
  const totals = useMemo(
    () => getRecentEventsTotals(recentEventsTotalsData),
    [recentEventsTotalsData],
  )
  const totalsButtonsKeys = useMemo(() => keys(totalsButtons), [])

  const [createOrUpdateUserView] = useCustomMutation({
    mutation: CreateOrUpdateUserView,
    rollbarOptions: { operationName: 'CreateOrUpdateUserView', target: 'DashboardRecentEvents' },
    mutationOptions: {
      refetchQueries: [RecentEventsTotalsQuery],
    },
  })
  const handleItemClick = useCallback(
    (event) =>
      handleRecentEventsItemClick(event, setSelectedEvent, currentUser, createOrUpdateUserView),
    [createOrUpdateUserView, currentUser],
  )

  return (
    <>
      <Flex className="space-x-3">
        {totalsButtonsKeys.map((totalButtonKey) => (
          <Flex
            alignItems="center"
            className={classes.recentEventCard}
            id={totalsButtons[totalButtonKey].key}
            key={totalsButtons[totalButtonKey].key}
            onClick={handleItemClick}>
            <Icon name={totalsButtons[totalButtonKey].iconName} size={sizes.LG} type="outline" />
            <Text
              className="px-2"
              color="text-primary-700"
              fontWeight={fontWeight.MEDIUM}
              size={sizes.XL2}>
              {totals[totalsButtons[totalButtonKey].key] || 0}
            </Text>
            <Text className={classes.recentEventCardTitle} color="text-black-500" size={sizes.BASE}>
              {t(totalsButtons[totalButtonKey].boxLabel || totalsButtons[totalButtonKey].label)}
            </Text>
          </Flex>
        ))}
      </Flex>
      {!!selectedEvent && (
        <RecentEventsDetails selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} />
      )}
    </>
  )
}

export default DashboardRecentEvents

import { get } from 'lodash'
import { DAYS } from '../../../utils/dataTypes'
import { formatDailyStatsData } from './formatDailyStatsData'

export const getVendorStatsQueryParams = (currentUser) => ({ id: get(currentUser, 'vendorId') })

export const getVendorOverdueLevelGroupsParams = (currentUser) => ({
  id: get(currentUser, 'vendorId'),
})

export const getVendorDailyStatsQueryParams = (currentUser) => ({
  id: get(currentUser, 'vendorId'),
})

export const getStatsData = (
  statsIsLoading,
  vendorStats,
  totalPastDueDailyStats,
  daysPastDueDailyStats,
  dailyStatsData,
) => {
  if (statsIsLoading) {
    return []
  }

  return [
    // {
    //   title: 'totalOutstanding',
    //   value: get(vendorStats, 'outstandingAmountCents', 'N/A'),
    //   type: MONEY,
    //   data: formatDailyStatsData(dailyStatsData, 'avgTotalOutstandingAmountCents'),
    // },
    // {
    //   title: 'totalPastDue',
    //   value: get(vendorStats, 'overdueAmountCents', 'N/A'),
    //   type: MONEY,
    //   data: totalPastDueDailyStats,
    // },
    {
      title: 'daysPastDue',
      value: get(vendorStats, 'aging', 'N/A'),
      type: DAYS,
      data: daysPastDueDailyStats,
    },
    {
      title: 'customersPastDue',
      value: get(vendorStats, 'overdueContractsCount', 'N/A'),
      type: '',
      data: formatDailyStatsData(dailyStatsData, 'avgOverdueContractsCount'),
    },
  ]
}

export const getCurrentDateInfo = () => {
  const currentFullDate = new Date()
  const currentDate = currentFullDate.getDate()
  const currentMonth = currentFullDate.toLocaleString('en', { month: 'long' })

  return { currentDate, currentMonth }
}

export const reasonList = [
  {
    label: 'Incorrect Items',
    value: 'Incorrect Items',
    testData: 'Incorrect Items',
  },
  {
    label: 'Damaged Items',
    value: 'Damaged Items',
    testData: 'Damaged Items',
  },
  {
    label: 'Billing Error',
    value: 'Billing Error',
    testData: 'Billing Error',
  },
  {
    label: 'Other (Please specify reason in notes)',
    value: 'Other',
    testData: 'Other',
  },
]

import mixpanel from 'mixpanel-browser'
import appConfig from '../configs/appConfig'
import store from '../store'

if (appConfig.MIXPANEL_ACCESS_TOKEN) {
  mixpanel.init(appConfig.MIXPANEL_ACCESS_TOKEN, {
    debug: appConfig.MIXPANEL_IS_DEBUG === 'true',
  })
}

const isProduction = appConfig.APP_ENV === 'production' && appConfig.MIXPANEL_ACCESS_TOKEN

let actions = {
  identify: (id) => {
    if (isProduction) mixpanel.identify(id)
  },
  alias: (newId, oldId) => {
    if (isProduction) mixpanel.alias(newId, oldId)
  },
  track: (name, props = {}) => {
    if (isProduction) {
      const workspace = store.getState()?.auth?.currentUser?.vendorName
      if (workspace) {
        mixpanel.track(name, { workspace, ...props })
      } else {
        mixpanel.track(name, props)
      }
    }
  },
}

export let Mixpanel = actions

import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '../../../ui-kit'

const ActivityCardDate = ({ date }) => {
  const activityDate = new Date(date)

  return (
    <Flex alignItems="center" className="pr-8" column>
      <div className="text-2xl font-medium">{activityDate.getDate()}</div>
      <div className="text-lg font-normal text-black-500 leading-none">
        {activityDate?.toLocaleString('en-us', { month: 'short' })}
      </div>
      <div className="text-lg font-normal text-black-500 leading-none">
        {activityDate?.getFullYear()}
      </div>
    </Flex>
  )
}

export default ActivityCardDate

ActivityCardDate.propTypes = {
  date: PropTypes.string,
}

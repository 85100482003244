import { Flex } from '../../ui-kit'
import React from 'react'
import PropTypes from 'prop-types'
import ToggleItem from './ToggleItem'

const NotificationSettingsView = ({
  settings,
  values,
  disabled,
  onChange,
  hideDropdowns,
  actionSlot,
  actionSlotProps,
  isReadOnlyNonAdmin,
}) => {
  if (!settings?.length) {
    return null
  }

  return (
    <Flex column>
      {settings.map((el, index) => (
        <ToggleItem
          actionSlot={actionSlot}
          actionSlotProps={actionSlotProps}
          disabled={disabled}
          hideDropdowns={hideDropdowns}
          isReadOnlyNonAdmin={isReadOnlyNonAdmin}
          onChange={onChange}
          values={values}
          {...el}
          isFirstEl={index === 0}
          key={el.name}
        />
      ))}
    </Flex>
  )
}

NotificationSettingsView.propTypes = {
  values: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  settings: PropTypes.arrayOf(PropTypes.object),
  actionSlot: PropTypes.object,
  hideDropdowns: PropTypes.bool,
  actionSlotProps: PropTypes.object,
  isVendorUserEmailNotifications: PropTypes.bool,
  isReadOnlyNonAdmin: PropTypes.bool,
}

export default NotificationSettingsView

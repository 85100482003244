import colors from '../../colors'

const options = {
  chart: {
    spacing: [20, 0, 0, 0],
    height: 195,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    lineColor: 'transparent',
    labels: {
      style: {
        color: colors.GRAY_500,
        fontSize: 14,
        fontFamily: 'Inter, sans-serif',
        textTransform: 'capitalize',
      },
    },
    minPadding: 0,
    maxPadding: 0,
    endOnTick: false,
    startOnTick: false,
    margin: 0,
    style: {
      padding: 0,
      margin: 0,
    },
  },
  yAxis: {
    visible: false,
    endOnTick: false,
    startOnTick: false,
    min: 0,
    style: {
      padding: 0,
      margin: 0,
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: { enabled: false },
}
export default options

import { useTranslation } from 'react-i18next'
import PT from 'prop-types'
import { Button, Flex, InfoNote, Money, Text } from '../../../ui-kit'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import DividedList from '../../../ui-kit/components/sidebar/DividedList'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import React from 'react'
import SidebarLabel from '../../../ui-kit/components/sidebar/SidebarLabel'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import VerticalActivity from './VerticalActivity'
import { getInstallmentActivity, statusPaymentPlanMapping } from './util'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import InvoicesAccordion from '../../../ui-kit/components/invoicesAccordion/InvoicesAccordion'
import { useNavigate } from 'react-router-dom'

const PaymentPlanContent = ({ data, setIsSidebarOpened, setIsOpenedCancelModal }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isCancelled =
    data.status === 'cancelled' ||
    data.status === 'paid' ||
    data.paymentPlanStatus === 'cancelled' ||
    data.paymentPlanStatus === 'paid' ||
    data.status === 'failed'

  const descriptionData = [
    {
      label: t('status'),
      value: (
        <StatusBadge
          color={
            statusPaymentPlanMapping(data?.paymentPlanStatus || data?.status, data.approvedAt)
              ?.color
          }
          value={t(
            statusPaymentPlanMapping(data?.paymentPlanStatus || data?.status, data.approvedAt)
              ?.label,
          )}
        />
      ),
    },
    { label: t('createdOn'), value: <FormattedDate date={data.createdAt} /> },
    { label: t('createdBy'), value: data.createdBy?.fullName },
    {
      label: t('customer'),
      node: (
        <Button
          label={data.contract?.buyer?.name}
          onClick={() => navigate(`/customers/${data.contract?.id}/overview`)}
          size={sizes.SM}
          testData="customer-link"
          variant={buttonsVariants.LINK}
        />
      ),
    },
    {
      label: t('paymentMethod'),
      value: data.paymentMethod?.title
        ? `${data.paymentMethod?.title}${
            data.paymentMethod?.nickname ? ` (${data.paymentMethod?.nickname})` : ''
          }`
        : '-',
    },
  ]

  return (
    <>
      <div className="flex flex-col mt-[-1.5rem]">
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
              {t('paymentPlan')}
            </Text>
          </div>

          <div className="flex flex-row">
            <div className="mr-2">
              <InfoNote label={t('id')}>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                  {data?.id}
                </Text>
              </InfoNote>
            </div>
          </div>
        </div>

        <div className="flex flex-row mt-3">
          <div className="w-6/12 flex flex-col">
            <SidebarLabel text="totalPlanAmount" />
            <Money className="text-2xl mt-1" value={data.amountCents} />
          </div>
        </div>

        <DividedList content={descriptionData} />

        <Flex className="mt-10" column>
          <InvoicesAccordion invoices={data.invoices} />
          {!isCancelled && (
            <Button
              className="mt-4 px-0"
              label={t('cancelPaymentPlan')}
              onClick={() => {
                setIsSidebarOpened(false)
                setIsOpenedCancelModal(true)
              }}
              size={sizes.BASE}
              testData="cancel-payment-plan"
              variant={buttonsVariants.TERTIARY}
              fullWidth
            />
          )}
        </Flex>

        <div className="w-full">
          {!!data.paymentPlanInstallments && (
            <VerticalActivity
              activity={getInstallmentActivity(data.paymentPlanInstallments, t)}
              nextInstallment={data.nextInstallment}
              title={t('paymentPlanSchedule')}
            />
          )}
        </div>
      </div>
    </>
  )
}

PaymentPlanContent.propTypes = {
  data: PT.object,
  setIsSidebarOpened: PT.func.isRequired,
  setIsOpenedCancelModal: PT.func.isRequired,
}

PaymentPlanContent.defaultProps = {
  data: {},
}

export default PaymentPlanContent

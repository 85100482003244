import PT from 'prop-types'
import { idType, moneyType } from './defaultTypes'

export const contractDataShape = PT.shape({
  aging: PT.number,
  id: idType,
  customerName: PT.string,
  outstandingAmountCents: moneyType,
  availableCreditAmountCents: moneyType,
  availableDepositAmountCents: moneyType,
  overdueLevelGroups: PT.arrayOf(
    PT.shape({
      amountCents: moneyType,
      id: idType,
      overdueLevel: PT.shape({
        id: idType,
        title: PT.string,
        overdueFrom: PT.string,
        overdueTo: PT.string,
      }),
    }),
  ),
  buyer: PT.shape({
    name: PT.string,
    paymentMethods: PT.arrayOf(
      PT.shape({
        id: idType,
        title: PT.string,
        type: PT.string,
      }),
    ),
  }),
})

export const invoiceDataShape = PT.shape({
  amountCents: PT.number,
  contract: contractDataShape,
  id: PT.string,
  invoiceNumber: PT.string,
  issueDate: PT.string,
})

export const invoiceRefundableDataShape = PT.shape({
  id: PT.string,
  invoiceNumber: PT.string,
  refundablePayments: PT.shape({
    amountCents: PT.number,
    appliedPenaltyAmountCents: PT.number,
    createdAt: PT.string,
    creditUsages: PT.shape({
      amountCents: PT.number,
      creditId: PT.string,
      id: PT.string,
      refundableAmountCents: PT.number,
      refundedAmountCents: PT.number,
    }),
    depositUsages: PT.shape({
      amountCents: PT.number,
      depositId: PT.string,
      id: PT.string,
      refundableAmountCents: PT.number,
      refundedAmountCents: PT.number,
      sequentialDepositId: PT.string,
    }),
    id: PT.string,
    refundedAmountCents: PT.number,
  }),
})

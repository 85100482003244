import React, { useRef } from 'react'
import { Flex, LoadingSpinner, Text } from '../../ui-kit'
import NotificationSettingsView from '../../components/notificationSettingsContent/NotificationSettingsView'
import PropTypes from 'prop-types'
import { useBeforeUnload } from '../../hooks/useBeforeUnload'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import sizes from '../../ui-kit/sizes'
import { snakeCase } from 'lodash'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { UpdateNotificationSettingMutation } from '../../queriesUpdated/mutations/updateNotificationSetting.gql'
import { useNotifications } from '../../hooks/useNotifications'
import {
  collectionsEmailNotificationSettings,
  generalEmailNotificationSettings,
  transactionalEmailNotificationSettings,
} from './emailNotificationsConfig'

const EmailNotificationsContent = ({
  initialValues,
  loading,
  isReadOnly,
  hideDropdowns,
  refetchQueries,
}) => {
  const { t } = useTranslation()
  const formAPI = useRef(null)
  const handleChange = (updater, fieldName) => {
    let values

    if (updater?.target?.name) {
      const [name, key] = updater.target.name.split('.')
      const value = updater.target.value
      values = { key, name, value }
    } else {
      const [name, key] = fieldName.split('.')
      values = { key, name, value: updater }
    }

    handleSubmit(values)
  }
  const { newNotification } = useNotifications()

  const [updateVendorCustomerNotificationSetting, { loading: requestInProgress }] =
    useCustomMutation({
      mutation: UpdateNotificationSettingMutation,
      rollbarOptions: {
        operationName: 'UpdateNotificationSettingMutation',
        target: 'CustomerNotifications',
      },
      mutationOptions: {
        refetchQueries: [refetchQueries],
      },
    })

  const handleSubmit = (values) => {
    const { key, name, value } = values
    const targetSetting = initialValues?.notificationTypes?.find(
      (setting) => setting.name === snakeCase(name),
    )

    if (!targetSetting) {
      return
    }

    // eslint-disable-next-line no-unused-vars
    const { __typename, id, ...rest } = { ...targetSetting, [key]: value }
    updateVendorCustomerNotificationSetting({
      variables: {
        id: initialValues?.id,
        data: [rest],
      },
    }).then(({ data }) => {
      const responseData = data?.updateNotificationSetting || {}

      if (responseData?.entity) {
        newNotification({ success: t('notificationSettingsUpdated') })
      }
    })
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ dirty, values, form }) => {
        formAPI.current = form
        useBeforeUnload({ when: dirty })

        return (
          <form className="mr-10">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Flex justifyContent="end">
                  <Text className="w-[110px]">{t('allCustomers')}</Text>
                  <Text className="w-[110px]">{t('myCustomers')}</Text>
                  <Text className="w-[110px]">{t('createdByMe')}</Text>
                  <Text className="w-[110px]">{t('noCustomers')}</Text>
                </Flex>
                <NotificationSettingsView
                  disabled={isReadOnly || requestInProgress}
                  hideDropdowns={hideDropdowns}
                  onChange={handleChange}
                  settings={generalEmailNotificationSettings}
                  values={values.notificationTypes}
                  isVendorUserEmailNotifications
                />
                {!!collectionsEmailNotificationSettings.length && (
                  <>
                    <Flex alignItems="center mt-4 mb-2" justifyContent="between">
                      <Text size={sizes.XL} paragraph>
                        {t('collections')}
                      </Text>
                    </Flex>
                    <NotificationSettingsView
                      disabled={isReadOnly || requestInProgress}
                      hideDropdowns={hideDropdowns}
                      onChange={handleChange}
                      settings={collectionsEmailNotificationSettings}
                      values={values.notificationTypes}
                      isVendorUserEmailNotifications
                    />
                  </>
                )}
                {!!transactionalEmailNotificationSettings.length && (
                  <>
                    <Flex alignItems="center mt-4 mb-2" justifyContent="between">
                      <Text size={sizes.XL} paragraph>
                        {t('transactional')}
                      </Text>
                    </Flex>
                    <NotificationSettingsView
                      disabled={isReadOnly || requestInProgress}
                      hideDropdowns={hideDropdowns}
                      onChange={handleChange}
                      settings={transactionalEmailNotificationSettings}
                      values={values.notificationTypes}
                      isVendorUserEmailNotifications
                    />
                  </>
                )}
              </>
            )}
          </form>
        )
      }}
    />
  )
}

EmailNotificationsContent.propTypes = {
  loading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  refetchQueries: PropTypes.object,
  initialValues: PropTypes.array.isRequired,
  hideDropdowns: PropTypes.bool,
  labelClassName: PropTypes.string,
  requestInProgress: PropTypes.bool,
}

EmailNotificationsContent.defaultProps = {
  loading: false,
  isReadOnly: false,
  hideDropdowns: false,
  requestInProgress: false,
}

export default EmailNotificationsContent

import PT from 'prop-types'
import { PAYMENT_PLAN_STATUSES, PAYMENT_PROMISE_STATUSES } from '../constants/activities'
import { dateType, idType } from './defaultTypes'

const disputeType = PT.shape({
  disputeId: PT.string.isRequired,
  memo: PT.string,
  reason: PT.string.isRequired,
})
export const noteType = PT.shape({
  createdAt: dateType,
  createdBy: PT.shape({
    id: idType,
    fullName: PT.string,
  }),
  noteId: idType,
  message: PT.string,
})

const paymentPlanType = PT.shape({
  paymentPlanId: PT.string.isRequired,
  paymentPlanStatus: PT.oneOf([...Object.values(PAYMENT_PLAN_STATUSES)]),
  createdAt: PT.string.isRequired,
})
const paymentPlanInstallmentType = PT.shape({
  paymentPlanId: PT.string.isRequired,
  paymentPlanStatus: PT.oneOf([...Object.values(PAYMENT_PLAN_STATUSES)]),
  createdAt: PT.string.isRequired,
})
const paymentPromiseType = PT.shape({
  paymentPromiseId: PT.string.isRequired,
  paymentPromiseStatus: PT.oneOf([...Object.values(PAYMENT_PROMISE_STATUSES)]),
  date: PT.string.isRequired,
})
const paymentRequestType = PT.shape({
  paymentPromiseId: PT.string.isRequired,
  paymentPromiseStatus: PT.oneOf([...Object.values(PAYMENT_PROMISE_STATUSES)]),
  date: PT.string.isRequired,
  paymentRequestId: PT.string.isRequired,
  recipients: PT.shape({
    createdAt: PT.string.isRequired,
    email: PT.string.isRequired,
    fullName: PT.string,
    id: PT.string.isRequired,
    phoneNumber: PT.string,
    status: PT.string,
  }),
  paymentTransaction: PT.shape({
    status: PT.string.isRequired,
    payable: PT.shape({
      outstandingAmountCents: PT.number.isRequired,
    }),
  }),
})

const activityType = PT.shape({
  id: PT.string.isRequired,
  eventEntity: PT.string.isRequired,
  eventAction: PT.string.isRequired,
  entityActualEvent: PT.shape({
    eventAction: PT.string.isRequired,
  }).isRequired,
  description: PT.string,
  createdAt: PT.string.isRequired,
  createdBy: PT.shape({
    fullName: PT.string,
    id: PT.string.isRequired,
  }),
  entity: PT.oneOfType([
    disputeType,
    paymentPlanType,
    paymentPlanInstallmentType,
    paymentPromiseType,
    paymentRequestType,
    noteType,
  ]),
})

export default activityType

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { useQueryParams } from '../../hooks/useQueryParams'
import { camelCase, toInteger } from 'lodash'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import {
  InvoiceRefundsQuery,
  InvoiceRefundsAvailableFilters,
} from '../../queries/invoiceRefunds.gql'
import { getPaginationData, parseFilterQuery } from '../../utils/utils'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import { Money } from '../../ui-kit'
import StatusBadge from '../../ui-kit/components/badges/StatusBadge'
import { useOpenEntitySidebar } from '../../hooks/useOpenEntitySidebar'
import Button from '../../ui-kit/components/buttons/Button'
import sizes from '../../ui-kit/sizes'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import Text from '../../ui-kit/components/text/Text'
import colors from '../../ui-kit/colors'
import { statusRefundMapping } from './refunds/util'
import { getRefundTitle } from '../invoices/invoiceRefund/reasonList'
import RefundContent from './refunds/RefundContent'
import Sidebar from '../../ui-kit/components/sidebar/Sidebar'
import { ProjectsAutocompleteQuery } from '../../queries/projects.gql'
import { getLoadingFunc } from '../invoices/invoicesUtils'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { getPaymentsBreadcrumbs } from './paymentsUtils'
import { ContractsAutocompleteQuery } from '../../queriesUpdated/queries/contract.gql'

const RefundsTab = ({ contractData, isAllContracts, scope }) => {
  const { queryParams } = useQueryParams()
  const sort = queryParams.sort || 'created_at.desc'
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)
  const [selectedRefund, setSelectedRefund] = useState(null)
  useEffect(() => {
    setBreadcrumbs(getPaymentsBreadcrumbs(contractData, t, scope))
    return function cleanup() {
      setAvailableFilters(null)
    }
  }, [contractData, scope])
  const [availableFilters, setAvailableFilters] = useState(null)
  const filtersQuery = queryParams.filters || null
  const userFilters = parseFilterQuery(filtersQuery)

  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(() => {
    const variables = {
      filters: [...userFilters],
      sort,
      page,
      search,
      perPage: pageSize,
    }

    if (contractData?.id) {
      variables.filters.push({
        key: 'contract_id',
        values: [contractData.id],
      })
    }

    return variables
  }, [userFilters, contractData?.id, sort, page, search, pageSize])

  const { data, loading } = useCustomQuery({
    query: InvoiceRefundsQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'InvoiceRefundsQuery', target: 'RefundsTab' },
  })
  const onCompleted = useCallback((response) => {
    response?.invoiceRefunds?.availableFilters &&
      setAvailableFilters(response.invoiceRefunds.availableFilters)
  }, [])
  useCustomQuery({
    query: InvoiceRefundsAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'InvoiceRefundsAvailableFilters', target: 'RefundsTab' },
  })
  const refunds = data?.invoiceRefunds
  const rows = refunds?.data || []
  const paginationData = getPaginationData(refunds)
  const handleIdClick = (data) => {
    setSelectedRefund(data.row)
    setIsSidebarOpened(true)
  }
  const { refetch: refetchProjectsLoadingData } = useCustomQuery({
    query: ProjectsAutocompleteQuery,
    rollbarOptions: { operationName: 'ProjectsAutocompleteQuery', target: 'RefundsTab' },
  })
  const loadProjectOptions = getLoadingFunc(
    refetchProjectsLoadingData,
    'projectsAutocomplete',
    null,
    isAllContracts
      ? {}
      : {
          contractId: contractData?.id,
        },
  )
  const { refetch: refetchContractsLoadingData } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    rollbarOptions: { operationName: 'ContractsAutocompleteQuery', target: 'InvoicesOutstanding' },
  })
  const loadContractOptions = getLoadingFunc(refetchContractsLoadingData, 'contractsAutocomplete')
  const columns = useMemo(
    () => [
      {
        field: 'createdAt',
        filterId: 'createdAt',
        headerName: t('refundDate'),
        renderCell: (values) => (
          <FormattedDate date={values?.row?.createdAt} format={'MM/dd/yyyy h:mm a'} />
        ),
        flex: 12.5,
      },
      {
        field: 'id',
        headerName: t('id'),
        flex: 6,
        renderCell: (values) => (
          <Button
            label={values?.row?.id}
            onClick={() => handleIdClick(values)}
            size={sizes.SM}
            testData="open-refund-detail"
            variant={buttonsVariants.LINK}
          />
        ),
      },
      {
        field: 'customerName',
        headerName: t('customer'),
        renderCell: (values) => values?.row?.contract?.buyer?.name,
        filterId: isAllContracts && 'contractId',
        loadOptions: loadContractOptions,
        flex: 15,
        hide: !isAllContracts,
      },
      {
        field: 'amountCents',
        headerName: t('amount'),
        renderCell: (values) => <Money value={values?.row?.amountCents} />,
        flex: 12.5,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'reason',
        filterId: 'reason',
        headerName: t('reason'),
        renderCell: (values) => getRefundTitle(values?.row?.reason),
        flex: 15,
      },
      {
        field: 'invoiceNumber',
        headerName: t('underlyingInvoice'),
        flex: 15,
        renderCell: (values) => (
          <Text color={colors.GRAY_500}>{values?.row?.invoice?.invoiceNumber}</Text>
        ),
      },
      {
        field: 'projectName',
        filterId: 'projectName',
        headerName: t('project'),
        loadOptions: loadProjectOptions,
        renderCell: (values) => <Text color={colors.GRAY_500}>{values?.row?.project?.name}</Text>,
        flex: 15,
      },
      {
        field: 'status',
        headerName: t('status'),
        filterId: 'status',
        filterOptions: Object.keys(statusRefundMapping).map((key) => ({
          title: t(statusRefundMapping[camelCase(key)].label),
          key,
        })),
        flex: 15,
        renderCell: (values) => (
          <StatusBadge
            color={statusRefundMapping[values?.row?.status]?.color}
            value={t(statusRefundMapping[values?.row?.status]?.label)}
          />
        ),
      },
      {
        field: 'homeLocation',
        filterId: isAllContracts && 'homeLocation',
        filterTitle: t('homeLocation'),
        hide: true,
      },
    ],
    [isAllContracts, loadContractOptions],
  )

  useOpenEntitySidebar(rows, setSelectedRefund, setIsSidebarOpened)

  const defaultSortModel = useMemo(() => {
    const [field, direction] = sort.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])

  return (
    <div className="pt-4">
      <Sidebar isSidebarOpened={isSidebarOpened} setIsSidebarOpened={setIsSidebarOpened}>
        <RefundContent data={selectedRefund || {}} />
      </Sidebar>

      <DataGridComponent
        availableFilters={availableFilters}
        columns={columns}
        loading={loading}
        page={page}
        pageSize={pageSize}
        paginationData={paginationData}
        rows={rows}
        searchLabel={t('refunds')}
        selectionModel={selectedRefund ? [selectedRefund.id] : []}
        sortModel={defaultSortModel}
      />
    </div>
  )
}
RefundsTab.propTypes = {
  scope: PT.string,
  contractData: PT.shape({
    aging: PT.number,
    id: PT.string,
    customerName: PT.string,
    outstandingAmountCents: PT.number,
    availableCreditAmountCents: PT.number,
    availableDepositAmountCents: PT.number,
    overdueLevelGroups: PT.arrayOf(
      PT.shape({
        amountCents: PT.number,
        id: PT.string,
        overdueLevel: PT.shape({
          id: PT.string,
          title: PT.string,
          overdueFrom: PT.string,
          overdueTo: PT.string,
        }),
      }),
    ),
    buyer: PT.shape({
      name: PT.string,
      paymentMethods: PT.arrayOf(
        PT.shape({
          id: PT.string,
          title: PT.string,
          type: PT.string,
        }),
      ),
    }),
  }),
  isAllContracts: PT.bool,
}

RefundsTab.defaultProps = {
  scope: '',
  contractData: {},
  isAllContracts: false,
}

export default RefundsTab

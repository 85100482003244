import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { Dialog, Money, StatusBadge, Text } from '../../../ui-kit'
import sizes from '../../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import MarkAsDisputeForm from './MarkAsDisputeForm'
import PT from 'prop-types'
import FormattedDate from '../../../ui-kit/components/text/FormattedDate'
import { getDisplayStatusValue } from '../../contracts/utils'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'

const MarkAsDisputeModal = ({ setIsOpened, data, customerName, reopenSidebar, isOpened }) => {
  const invoiceData = useMemo(
    () => ({
      ...data,
      customerName: customerName,
      totalOutstanding: data?.outstandingAmountCents,
      project: data.project?.name || '-',
      po: data.po ? data.po : '-',
      invoiceDate: <FormattedDate date={data?.issueDate} />,
      dueDate: <FormattedDate date={data?.maturityDate} />,
    }),
    [data, customerName],
  )

  const { t } = useTranslation()

  const closeForm = useCallback(() => {
    setIsOpened(false)
    reopenSidebar()
  }, [setIsOpened, reopenSidebar])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('createNewDispute')}
      titleClass="text-2xl font-semibold">
      <div className="flex flex-row mt-6" testdata="modal-markAsDisputed">
        <div className="w-80">
          <div className="flex flex-col mt-3 divide-y">
            <div className="flex flex-row h-10">
              <Text
                className="w-6/12 flex flex-col justify-center text-gray-500"
                size={sizes.SM}
                customColor>
                {t('totalAmount')}
              </Text>
              <Text className="w-6/12 flex flex-col justify-center text-right" size={sizes.SM}>
                <Money value={invoiceData.totalOutstanding} />
              </Text>
            </div>

            <div className="flex flex-row h-10">
              <Text
                className="w-6/12 flex flex-col justify-center text-gray-500"
                size={sizes.SM}
                customColor>
                {t('invoiceNumber')}
              </Text>
              <Text className="w-6/12 flex flex-col justify-center text-right" size={sizes.SM}>
                {invoiceData.invoiceNumber}
              </Text>
            </div>

            <div className="flex flex-row h-10">
              <Text
                className="w-6/12 flex flex-col justify-center text-gray-500"
                size={sizes.SM}
                customColor>
                {t('invoiceStatus')}
              </Text>
              <div className="w-6/12 flex flex-col justify-center items-end">
                <StatusBadge
                  iconName="dot"
                  value={getDisplayStatusValue(invoiceData?.overdueLevel)}
                />
              </div>
            </div>

            <div className="flex flex-row h-10">
              <Text
                className="w-6/12 flex flex-col justify-center text-gray-500"
                size={sizes.SM}
                customColor>
                {t('customer')}
              </Text>
              <Text className="w-6/12 flex flex-col justify-center text-right" size={sizes.SM}>
                {invoiceData.customerName}
              </Text>
            </div>

            <div className="flex flex-row h-10">
              <Text
                className="w-6/12 flex flex-col justify-center text-gray-500"
                size={sizes.SM}
                customColor>
                {t('project')}
              </Text>
              <Text className="w-6/12 flex flex-col justify-center text-right" size={sizes.SM}>
                {invoiceData.project}
              </Text>
            </div>

            <div className="flex flex-row h-10">
              <Text
                className="w-6/12 flex flex-col justify-center text-gray-500"
                size={sizes.SM}
                customColor>
                {t('po')}
              </Text>
              <Text className="w-6/12 flex flex-col justify-center text-right" size={sizes.SM}>
                {invoiceData.po}
              </Text>
            </div>

            <div className="flex flex-row h-10">
              <Text
                className="w-6/12 flex flex-col justify-center text-gray-500"
                size={sizes.SM}
                customColor>
                {t('invoiceDate')}
              </Text>
              <Text className="w-6/12 flex flex-col justify-center text-right" size={sizes.SM}>
                {invoiceData.invoiceDate}
              </Text>
            </div>

            <div className="flex flex-row h-10">
              <Text
                className="w-6/12 flex flex-col justify-center text-gray-500"
                size={sizes.SM}
                customColor>
                {t('dueDate')}
              </Text>
              <Text className="w-6/12 flex flex-col justify-center text-right" size={sizes.SM}>
                {invoiceData.dueDate}
              </Text>
            </div>
          </div>
        </div>
        <div className="w-80 ml-16">
          <MarkAsDisputeForm
            closeForm={closeForm}
            invoiceId={invoiceData.id}
            isFormDirty={isFormDirty}
            requestClose={requestClose}
            setDirtyFormState={setDirtyFormState}
            setIsOpenedModal={setIsOpened}
          />
        </div>
      </div>
    </Dialog>
  )
}

MarkAsDisputeModal.propTypes = {
  data: PT.object,
  isOpened: PropTypes.bool.isRequired,
  customerName: PropTypes.string.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  reopenSidebar: PropTypes.func.isRequired,
}
MarkAsDisputeModal.defaultProps = {
  data: {},
  isOpened: false,
  customerName: '',
}

export default MarkAsDisputeModal

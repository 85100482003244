import React from 'react'
import PT from 'prop-types'

const Heading = ({ className, children, subHeading }) => {
  const color = 'black'
  const Element = 'h3'

  return (
    <Element className={className} color={color} tw="font-bold text-3xl">
      {children}

      {subHeading && <span>{` (${subHeading})`}</span>}
    </Element>
  )
}
Heading.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  subHeading: PT.oneOfType([PT.string, PT.number]),
}

Heading.defaultProps = {
  subHeading: null,
  className: '',
}

export default Heading

import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Text, Dialog } from '../../../ui-kit'
import { Field, Form } from 'react-final-form'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { makeOptions, parseToFinanceFormat } from '../../../utils/utils'
import fontWeight from '../../../ui-kit/fontWeight'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { range } from 'lodash'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'

const CreditCardFeesModal = ({ isOpened, initialValues, handleSubmit, setIsOpened }) => {
  const { t } = useTranslation()

  const closeForm = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })

  const daysPastDueThreshold = makeOptions(range(0, 91, 1))
  const validateCardConvenienceFee = (value) => {
    if (value > 3) {
      return t('theValueIsHigherThan3.9')
    } else {
      return undefined
    }
  }
  return (
    <Dialog isOpened={isOpened} setIsOpened={setIsOpened} title={t('creditCardConvenienceFees')}>
      <div className="w-[50rem] px-1">
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({ handleSubmit, values, dirty }) => {
            useBeforeUnload({ when: dirty })
            dirty !== isFormDirty && setDirtyFormState(dirty)

            return (
              <form onSubmit={handleSubmit}>
                <div className="w-full mt-6">
                  <Field
                    format={(value) => parseToFinanceFormat(value, true)}
                    name="creditCardFeePercentage"
                    formatOnBlur>
                    {({ input, meta }) => {
                      return (
                        <Input
                          error={meta.error}
                          errorMessage={meta.error}
                          id="creditCardFeePercentage"
                          label={`${t('creditCardConvenienceFee')} (%)`}
                          name={input.name}
                          onChange={input.onChange}
                          value={input.value}
                        />
                      )
                    }}
                  </Field>
                </div>
                <div className="w-full mt-2">
                  <Text color="text-warmBlack-400" fontWeight={fontWeight.LIGHT}>
                    {t('valueMustBeBetween')}
                  </Text>
                </div>
                <div className="flex w-full mt-6">
                  <div className="w-9/12">
                    <Text>{t('newCustomersDefault')}</Text>
                  </div>
                  <div className="w-3/12 px-2 text-right">
                    <Field name="creditCardFeeEnabledDefault">
                      {({ input }) => {
                        return <Toggle handleChange={input.onChange} value={input.value} />
                      }}
                    </Field>
                  </div>
                </div>
                <div className="flex w-full mt-2">
                  <div className="w-9/12 pr-4">
                    <Text color="text-warmBlack-400" fontWeight={fontWeight.LIGHT}>
                      {t('newCustomersDefaultExplanation')}
                    </Text>
                  </div>
                </div>
                <div className="flex w-full mt-4">
                  <div className="w-9/12">
                    <div className="w-full">
                      <Text>{t('paymentAmountTrigger')}</Text>
                    </div>
                    <div className="w-full mt-2 pr-4">
                      <Text color="text-warmBlack-400" fontWeight={fontWeight.LIGHT}>
                        {t('paymentAmountTriggerWarning')}
                      </Text>
                    </div>
                  </div>
                  <div className="w-2/12 px-2">
                    <Field name="creditCardFeePaymentAmountDollarThreshold">
                      {({ input, meta }) => {
                        return (
                          <Input
                            disabled={!values.creditCardFeePaymentAmountCentsThresholdEnabled}
                            error={
                              meta.error && meta.touched && meta.submitFailed
                                ? meta.error
                                : undefined
                            }
                            id={input.name}
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value}
                            isCurrencyInput
                          />
                        )
                      }}
                    </Field>
                  </div>
                  <div className="w-1/12 px-2 text-right">
                    <Field name="creditCardFeePaymentAmountCentsThresholdEnabled">
                      {({ input }) => {
                        return <Toggle handleChange={input.onChange} value={input.value} />
                      }}
                    </Field>
                  </div>
                </div>
                <div className="flex w-full mt-6">
                  <div className="w-9/12">
                    <div className="w-full">
                      <Text>{t('invoiceDaysPastDueTrigger')}</Text>
                    </div>
                    <div className="w-full mt-2 pr-4">
                      <Text color="text-warmBlack-400" fontWeight={fontWeight.LIGHT}>
                        {t('invoiceDaysPastDueWarning')}
                      </Text>
                    </div>
                  </div>
                  <div className="w-2/12 px-2">
                    <Field name="creditCardFeeInvoiceDaysPastDueThreshold">
                      {({ input }) => {
                        return (
                          <NDropdown
                            id={input.name}
                            isDisabled={!values.creditCardFeeInvoiceDaysPastDueThresholdEnabled}
                            name={input.name}
                            onChange={input.onChange}
                            options={daysPastDueThreshold}
                            value={input.value}
                          />
                        )
                      }}
                    </Field>
                  </div>
                  <div className="w-1/12 px-2 pt-2 text-right">
                    <Field name="creditCardFeeInvoiceDaysPastDueThresholdEnabled">
                      {({ input }) => {
                        return <Toggle handleChange={input.onChange} value={input.value} />
                      }}
                    </Field>
                  </div>
                </div>
                <div className="flex w-full mt-4">
                  <div className="w-9/12">
                    <div className="w-full">
                      <Text>{t('deposits')}</Text>
                    </div>
                    <div className="w-full mt-2 pr-4">
                      <Text color="text-warmBlack-400" fontWeight={fontWeight.LIGHT}>
                        {t('depositCreditCardFeeWarning')} {t('paymentAmountTriggerWarn')}
                      </Text>
                    </div>
                  </div>
                  <div className="w-2/12 px-2" />
                  <div className="w-1/12 px-2 pt-2 text-right">
                    <Field name="creditCardFeeForDepositsEnabled">
                      {({ input }) => {
                        return <Toggle handleChange={input.onChange} value={input.value} />
                      }}
                    </Field>
                  </div>
                </div>
                <div className="flex w-full mt-4">
                  <div className="w-9/12">
                    <div className="w-full">
                      <Text>{t('paymentPlanInstallments')}</Text>
                    </div>
                    <div className="w-full mt-2 pr-4">
                      <Text color="text-warmBlack-400" fontWeight={fontWeight.LIGHT}>
                        {t('convenienceFeesAppliedToAllPaymentPlanInstallments')}{' '}
                        {t('paymentAmountTriggerWarn')}
                      </Text>
                    </div>
                  </div>
                  <div className="w-2/12 px-2" />
                  <div className="w-1/12 px-2 pt-2 text-right">
                    <Field name="creditCardFeeForPaymentPlansEnabled">
                      {({ input }) => {
                        return <Toggle handleChange={input.onChange} value={input.value} />
                      }}
                    </Field>
                  </div>
                </div>
                <div className="flex w-full mt-4">
                  <div className="w-9/12">
                    <div className="w-full">
                      <Text>{t('autopay')}</Text>
                    </div>
                    <div className="w-full mt-2 pr-4">
                      <Text color="text-warmBlack-400" fontWeight={fontWeight.LIGHT}>
                        {t('autopaysWarn')}
                      </Text>
                    </div>
                  </div>
                  <div className="w-2/12 px-2" />
                  <div className="w-1/12 px-2 pt-2 text-right">
                    <Field name="creditCardFeeForAutopayEnabled">
                      {({ input }) => {
                        return <Toggle handleChange={input.onChange} value={input.value} />
                      }}
                    </Field>
                  </div>
                </div>
                <div className="w-full mt-6 flex flex-row justify-end">
                  <Button
                    className="mr-2"
                    label={t('cancel')}
                    onClick={requestClose}
                    testData="cancel-credit-card"
                    variant={buttonsVariants.TERTIARY}
                  />
                  <Button label={t('save')} testData="save-credit-card" type="submit" />
                </div>
                <AlertModal confirmClose={closeForm} />
              </form>
            )
          }}
          validate={(values) => {
            const errors = {}
            errors.creditCardFeePercentage = validateCardConvenienceFee(
              values.creditCardFeePercentage,
            )

            return errors
          }}
        />
      </div>
    </Dialog>
  )
}

CreditCardFeesModal.propTypes = {
  setIsOpened: PropTypes.func.isRequired,
  isOpened: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.number,
}

export default CreditCardFeesModal

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LoadingSpinner, PageDialog } from '../../../ui-kit'
import DataGridComponent from '../../../components/dataGrid/DataGridComponent'
import ReportFooter from '../reportFooter/ReportFooter'
import ReportsFilters from '../reportsFilters/ReportsFilters'
import {
  getArAgingSummaryColumns,
  getArAgingSummaryFiltersColumns,
  getArAgingSummaryTotalItems,
} from './logic'
import { getCustomRowId, getReportsData, getReportsTotal } from '../logic/reportsUtils'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { getPaginationData } from '../../../utils/utils'

const ArAgingSummary = ({
  data,
  isOpened,
  closeReport,
  reportMetadata,
  authToken,
  userFilters,
  page,
}) => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const rows = useMemo(() => getReportsData(data), [data])
  const columns = useMemo(() => getArAgingSummaryColumns(t, rows), [t, rows])
  const total = useMemo(() => getReportsTotal(data), [data])
  const getRowId = useCallback((row) => getCustomRowId(row, 'customerId'), [])
  const totalItems = useMemo(() => getArAgingSummaryTotalItems(total, t), [total, t])
  const filterColumns = useMemo(() => getArAgingSummaryFiltersColumns(t), [t])
  const paginationData = getPaginationData(data)

  return (
    <PageDialog
      bottomPanel={
        <ReportFooter
          authToken={authToken}
          closeReport={closeReport}
          reportMetadata={reportMetadata}
          sort={queryParams.sort}
          userFilters={userFilters}
        />
      }
      isOpened={isOpened}
      setIsOpened={closeReport}
      title={t('arAgingSummary')}>
      {!data ? (
        <LoadingSpinner className="h-full w-full" loading />
      ) : (
        <div className="px-8 w-full">
          <ReportsFilters availableFilters={data.availableFilters} columns={filterColumns} />

          <div className="py-4">
            <DataGridComponent
              columns={columns}
              getRowId={getRowId}
              page={page}
              pageSize={100}
              paginationData={paginationData}
              rows={rows}
              totalData={totalItems}
              isTotalDisplay
            />
          </div>
        </div>
      )}
    </PageDialog>
  )
}

ArAgingSummary.propTypes = {
  data: PropTypes.object,
  page: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  authToken: PropTypes.string.isRequired,
  closeReport: PropTypes.func.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.any),
  reportMetadata: PropTypes.shape({
    csvUrl: PropTypes.string,
    xlsxUrl: PropTypes.string,
    jsonUrl: PropTypes.string,
    fileNameKey: PropTypes.string,
    defaultFilters: PropTypes.object,
  }).isRequired,
}

export default ArAgingSummary

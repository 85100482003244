import React, { useState } from 'react'
import PT from 'prop-types'
import { FileInput, Input, Dialog } from '../../../ui-kit'
import { CATEGORIES, FILE_RESOURCES } from '../../../constants/fileInputResources'
import { useTranslation } from 'react-i18next'

const ProjectDocumentUploadModal = ({ projectId, modalOpened, onModalClose, refetchQueries }) => {
  const { t } = useTranslation()
  const [fileName, setFileName] = useState('')

  return (
    <Dialog
      isOpened={modalOpened}
      onModalClose={onModalClose}
      setIsOpened={onModalClose}
      title={t('UploadNewDocument')}>
      <div className="py-4">
        <Input
          className="mb-4"
          id="FileName"
          label={t('fileName')}
          name="fileName"
          onChange={({ target }) => setFileName(target.value)}
          placeholder={t('fileName')}
          value={fileName}
          required
        />
        <FileInput
          category={CATEGORIES.PROJECT}
          className="mb-4"
          fileName={fileName}
          id="documentsFileUpload"
          isUploadDisabled={!fileName}
          onFileUpload={onModalClose}
          refetchQueries={refetchQueries}
          resourceId={projectId}
          resourceType={FILE_RESOURCES.PROJECT}
        />
      </div>
    </Dialog>
  )
}

ProjectDocumentUploadModal.propTypes = {
  projectId: PT.string.isRequired,
  modalOpened: PT.bool.isRequired,
  onModalClose: PT.func.isRequired,
  refetchQueries: PT.arrayOf(PT.object),
}

ProjectDocumentUploadModal.defaultProps = {
  refetchQueries: [],
}

export default ProjectDocumentUploadModal

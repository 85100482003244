import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../ui-kit'
import colors from '../../../ui-kit/colors'
import { downloadFile } from '../logic/reportsUtils'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import sizes from '../../../ui-kit/sizes'
import { useRollbar } from '@rollbar/react'

const ReportFooter = ({
  authToken,
  closeReport,
  reportMetadata,
  userFilters,
  sort,
  isExcelExportDisabled,
}) => {
  const { t } = useTranslation()
  const rollbar = useRollbar()

  const handleDownloadCsv = useCallback(async () => {
    let params = {}

    if (userFilters.length) {
      params.filters = userFilters.reduce((acc, filter) => {
        acc[filter.key] = filter.values
        return acc
      }, {})
    }

    if (reportMetadata?.defaultFilters) {
      params.filters = { ...params.filters, ...reportMetadata.defaultFilters }
    }

    if (sort) {
      params.sort = sort
    }

    await downloadFile(
      reportMetadata?.csvUrl,
      authToken,
      params,
      `${t(reportMetadata?.fileNameKey)}.csv`,
      rollbar,
    )
  }, [userFilters, reportMetadata, authToken, sort])

  const handleDownloadXlsx = useCallback(async () => {
    let params = {}

    if (userFilters.length) {
      params.filters = userFilters.reduce((acc, filter) => {
        acc[filter.key] = filter.values
        return acc
      }, {})
    }

    if (reportMetadata?.defaultFilters) {
      params.filters = { ...params.filters, ...reportMetadata.defaultFilters }
    }

    if (sort) {
      params.sort = sort
    }

    await downloadFile(
      reportMetadata?.xlsxUrl,
      authToken,
      params,
      `${t(reportMetadata?.fileNameKey)}.xlsx`,
      rollbar,
    )
  }, [userFilters, reportMetadata, authToken, sort])

  return (
    <>
      {reportMetadata?.xlsxUrl && (
        <Button
          className="ml-4"
          color={colors.PRIMARY}
          disabled={isExcelExportDisabled}
          label={t('exportExcel')}
          onClick={handleDownloadXlsx}
          size={sizes.SM}
          testData="export-xlsx"
        />
      )}
      {reportMetadata?.csvUrl && (
        <Button
          className="ml-4"
          color={colors.PRIMARY}
          label={t('exportCSV')}
          onClick={handleDownloadCsv}
          size={sizes.SM}
          testData="export-csv"
        />
      )}
      <Button
        color={colors.PRIMARY}
        label={t('cancel')}
        onClick={closeReport}
        size={sizes.SM}
        testData="close-report"
        variant={buttonsVariants.TERTIARY}
      />
    </>
  )
}

ReportFooter.propTypes = {
  sort: PropTypes.string,
  authToken: PropTypes.string.isRequired,
  closeReport: PropTypes.func.isRequired,
  userFilters: PropTypes.arrayOf(PropTypes.any),
  reportMetadata: PropTypes.shape({
    csvUrl: PropTypes.string,
    xlsxUrl: PropTypes.string,
    jsonUrl: PropTypes.string,
    fileNameKey: PropTypes.string,
    defaultFilters: PropTypes.object,
  }).isRequired,
  isExcelExportDisabled: PropTypes.bool,
}

ReportFooter.defaultProps = {
  userFilters: [],
  isExcelExportDisabled: false,
}

export default React.memo(ReportFooter)

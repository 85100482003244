import React, { useMemo } from 'react'
import { Flex, PreviewBlock } from '../../../../ui-kit'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../../../components/dataGrid/DataGridComponent'
import { InvoicesShortInfoQuery } from '../../../../queries/invoices/invoicesShortInfo.gql'
import PT from 'prop-types'
import { scopeFilterMap } from '../../../invoices/invoicesUtils'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../ui-kit/components/buttons/Button'
import sizes from '../../../../ui-kit/sizes'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { useCustomQuery } from '../../../../hooks/useCustomQuery'

const InvoicesOldestPastDue = ({ contractId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const sort = 'aging.desc'
  const page = 1
  const search = null

  const queryVariables = {
    filters: [
      scopeFilterMap['outstanding'],
      {
        key: 'contract_id',
        values: [contractId],
      },
    ],
    sort,
    page,
    search,
  }

  const { data, loading } = useCustomQuery({
    query: InvoicesShortInfoQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'InvoicesShortInfoQuery', target: 'InvoicesOldestPastDue' },
  })

  const invoices = data?.invoices
  const rows = invoices?.data || []
  const columns = useMemo(
    () => [
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'invoiceNumber',
        headerName: t('invoiceNumber'),
        flex: 1,
        sortable: false,
        renderCell: (values) => (
          <Button
            label={values?.row?.invoiceNumber}
            onClick={() =>
              navigate(
                // eslint-disable-next-line max-len
                `/customers/${contractId}/invoices/outstanding?additionalFilters=invoicedNumber&filters=invoicedNumber.${values?.row?.invoiceNumber}&openSidebarParams=invoiceNumber.${values?.row?.invoiceNumber}`,
              )
            }
            size={sizes.SM}
            testData="invoice-link-oldest-past-due"
            variant={buttonsVariants.LINK}
          />
        ),
      },
      {
        field: 'project',
        headerName: t('project'),
        flex: 1,
        sortable: false,
        renderCell: (values) => (
          <span className={'text-ellipsis overflow-hidden'}>
            {values?.row?.project?.name || '-'}
          </span>
        ),
      },
      {
        field: 'aging',
        headerName: t('daysPastDue'),
        sortable: false,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
      },
    ],
    [],
  )

  return (
    <PreviewBlock
      className={'mt-7'}
      loading={loading}
      testData="oldest-past-due-invoices"
      title={t('oldestPastDueInvoices')}
      viewMoreLink={`/customers/${contractId}/invoices?sort=${sort}`}>
      <Flex>
        <DataGridComponent columns={columns} rows={rows} hideFooter />
      </Flex>
    </PreviewBlock>
  )
}

export default InvoicesOldestPastDue

InvoicesOldestPastDue.propTypes = {
  contractId: PT.string.isRequired,
}

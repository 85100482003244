export const COMPANY_ROLES_LIST = [
  {
    label: 'Primary Contact',
    value: 'primary_contact',
  },
  {
    label: 'Billing Contact',
    value: 'billing_contact',
  },
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'View only',
    value: 'view_only',
  },
]

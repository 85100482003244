export const reasonList = [
  {
    label: 'Returned Goods',
    value: 'returned_goods',
  },
  {
    label: 'Incorrect Items',
    value: 'incorrect_items',
  },
  {
    label: 'Damaged Items',
    value: 'damaged_items',
  },
  {
    label: 'Billing Error',
    value: 'billing_error',
  },
  {
    label: 'Other (Please specify reason in notes)',
    value: 'other',
  },
]

export const getRefundTitle = (id) => reasonList.find((item) => item.value === id)?.label || id

import PropTypes from 'prop-types'
import React from 'react'
import { Flex, FormattedDate, Money, Text } from '../../../ui-kit'
import Icon from '../../../ui-kit/components/icons/Icon'
import colors from '../../../ui-kit/colors'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import { getIconView } from './util'

const VerticalActivity = ({ activity, title, nextInstallment }) => {
  return (
    <div className="flex flex-col justify-center mt-10 mb-6">
      <Text className={'mb-6'} fontWeight={fontWeight.SEMIBOLD} size={sizes.BASE}>
        {title}
      </Text>
      {activity.map((item, index) => (
        <div className="flex flex-col" key={item.id}>
          {!!index && (
            <div className="mt-2 ml-1.5 mb-2">
              <Icon color={colors.GREY} name="connector" type="outline" />
            </div>
          )}
          <div className="flex flex-row items-center" key={item.id}>
            {getIconView(item)}
            <Flex
              alignItems={'baseline'}
              className={`${
                item.id === nextInstallment?.id ? 'border border-primary-700 bg-primary-100' : ''
              } w-full p-2.5 ml-2`}
              justifyContent={'between'}>
              <div className=" w-6/12">
                <Text
                  className="mr-1 whitespace-nowrap"
                  fontWeight={fontWeight.MEDIUM}
                  size={sizes.SM}>
                  {item.label}
                </Text>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                  <Money value={item.amount} />
                </Text>
              </div>

              <Text className="w-6/12 text-right" fontWeight={fontWeight.LIGHT} size={sizes.SM}>
                <FormattedDate date={item.date} />
              </Text>
            </Flex>
          </div>
        </div>
      ))}
    </div>
  )
}

VerticalActivity.propTypes = {
  activity: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  title: PropTypes.string,
  nextInstallment: PropTypes.any,
}

VerticalActivity.defaultProps = {
  title: '',
}

export default VerticalActivity

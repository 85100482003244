import React from 'react'
import { Navigate } from 'react-router-dom'
import PT from 'prop-types'

const ContractIndex = ({ contractId }) => {
  return <Navigate to={`/customers/${contractId}/invoices`} />
}

ContractIndex.propTypes = {
  contractId: PT.string,
}
ContractIndex.defaultProps = {
  contractId: null,
}

export default ContractIndex

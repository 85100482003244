import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { applyCurrentUserUpdate, applyUserSignIn, applyUserSignOut } from '../../store/actions/auth'
import { Avatar, Flex, Icon, LoadingSpinner, Text, Tooltip } from '../../ui-kit'
import sizes from '../../ui-kit/sizes'
import { useTranslation } from 'react-i18next'
import fontWeight from '../../ui-kit/fontWeight'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { logo } from '../../ui-kit/assets'
import { tooltipTypes } from '../../ui-kit/components/tooltip/Tooltip'
import MenuDropdown from '../../ui-kit/components/menuDropdown/MenuDropdown'
import { SUPPLI_SUPPORT_EMAIL, SUPPLI_SUPPORT_PHONE } from '../../constants/contacts'
import { VendorsQuery } from '../../queries/vendors.gql'
import { selectAuthToken } from '../../store/selectors/auth'
import { Mixpanel } from '../../utils/mixpanel'
import { useCustomQuery } from '../../hooks/useCustomQuery'
import { UserQuery } from '../../queries/users.gql'

const SelectWorkspace = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authToken = useSelector(selectAuthToken)
  const currentUser = useCurrentUser()
  const [perPageVendors, setPerPageVendors] = useState(10)
  const [isSupportOpen, setIsSupportOpen] = useState(false)
  const [workspaceAuthToken, setWorkspaceAuthToken] = useState(null)
  const closeSupportView = useCallback(() => {
    setIsSupportOpen(false)
  }, [])
  useEffect(() => {
    if (isSupportOpen) {
      window.addEventListener('click', closeSupportView, false)
    } else {
      window.removeEventListener('click', closeSupportView, false)
    }
  }, [isSupportOpen])

  const openChatWidget = useCallback(() => {
    try {
      window.zE('messenger', 'open')
    } catch (error) {
      console.error(error)
    }
  }, [window.zE])

  const vendorUserData = useCustomQuery({
    query: UserQuery,
    queryOptions: {
      variables: { id: currentUser?.id },
      skip: !workspaceAuthToken,
    },
    rollbarOptions: { operationName: 'UserQuery', target: 'SelectWorkspace' },
  })

  const handleVendorSwitch = useCallback(
    async (id, name) => {
      try {
        Mixpanel.track('CX Select Workspace', {
          workspace: name,
          workspaceId: id,
        })
        const { authToken: vendorAuthToken } = await axios.post(
          '/auth/vendor_switch',
          { id },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        dispatch(applyUserSignIn(vendorAuthToken, currentUser))
        setWorkspaceAuthToken(vendorAuthToken)
      } catch (error) {
        console.warn(error)
      }
    },
    [dispatch, navigate, authToken],
  )
  useEffect(() => {
    if (vendorUserData?.data && workspaceAuthToken) {
      navigate('/')
      dispatch(
        applyCurrentUserUpdate({
          ...currentUser,
          ...vendorUserData?.data?.vendorUser,
          vendorId: vendorUserData?.data?.currentVendor?.id,
        }),
      )
    }
  }, [vendorUserData?.data, workspaceAuthToken])

  const handleSignOut = useCallback(
    async (e) => {
      e.preventDefault()
      await axios.delete('/vendor_users/logout', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      dispatch(applyUserSignOut())
      navigate('/auth/login')
    },
    [dispatch, navigate, authToken],
  )

  const supportContent = useMemo(
    () => (
      <>
        <div className="py-2 px-4">
          <Text color="text-black-500">{t('available247')}</Text>
        </div>
        <div className="py-2 px-4 hover:bg-primary-200 cursor-pointer" onClick={openChatWidget}>
          <Text>{t('chat')}</Text>
        </div>
        <div className="py-2 px-4 hover:bg-primary-200 cursor-pointer">
          <a className="hover:no-underline" href={`mailto:${SUPPLI_SUPPORT_EMAIL}`}>
            <Text>{`${t('email')} ${SUPPLI_SUPPORT_EMAIL}`}</Text>
          </a>
        </div>
        {/*<div
          className="py-2 px-4 hover:bg-primary-200 cursor-pointer"
          onClick={() => window.open(SUPPLI_SUPPORT_URL)}>
          <Text>{t('helpCenter')}</Text>
        </div>*/}
        <div className="py-2 px-4 border-t">
          <Text color="text-black-500">{t('suppliCallAvailable')}</Text>
        </div>
        <div className="py-2 px-4 hover:bg-primary-200">
          <Text>{`${t('call')} ${SUPPLI_SUPPORT_PHONE}`}</Text>
        </div>
      </>
    ),
    [t],
  )

  const queryVariables = useMemo(
    () => ({
      scope: 'active',
      sort: 'name.asc',
      perPage: perPageVendors,
    }),
    [perPageVendors],
  )

  const onCompleted = useCallback((response) => {
    /*if (response?.vendors?.paginationData?.totalCount <= 1) {
      navigate('/dashboard')
      return
    }*/

    if (response?.vendors?.paginationData?.totalCount > perPageVendors) {
      setPerPageVendors(response.projects.paginationData.totalCount)
    }
  }, [])
  const { data, loading } = useCustomQuery({
    query: VendorsQuery,
    onCompleted,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'VendorsQuery', target: 'SelectWorkspace' },
  })
  const vendors = useMemo(() => data?.vendors?.data || [], [data])

  return (
    <Flex alignItems="center" className="w-full h-screen" justifyContent="center" column>
      <Flex alignItems="center" className="w-full bg-white px-6" justifyContent="between">
        <img alt="Suppli" className="h-8" src={logo} />
        <Flex className="space-x-10">
          <Flex
            alignItems="center"
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation()
              setIsSupportOpen(!isSupportOpen)
            }}>
            <Tooltip
              className="flex items-center pr-1"
              content={supportContent}
              isOpen={isSupportOpen}
              placement="bottom"
              type={tooltipTypes.LIGHT}>
              <>
                <Icon color="text-warmBlack-400" name="globeAlt" size={sizes.LG} />
                <Text
                  className="pl-2"
                  color="text-black-500"
                  fontWeight={fontWeight.MEDIUM}
                  size={sizes.LG}>
                  {t('support')}
                </Text>
              </>
            </Tooltip>
          </Flex>

          <Flex alignItems="center" row>
            <div className="p-2">
              <MenuDropdown options={[{ label: t('logOut'), onClick: handleSignOut }]}>
                <Avatar firstName={currentUser.firstName} lastName={currentUser.lastName} />
              </MenuDropdown>
            </div>
          </Flex>
        </Flex>
      </Flex>

      <Flex alignItems="center" className="w-full flex-1 py-8 overflow-auto" column>
        <Text align="center" fontWeight={fontWeight.SEMIBOLD} size={sizes.XL3}>
          {t('selectWorkspace')}
        </Text>
        <Text align="center" className="mt-4" color="text-black-500" size={sizes.XS}>
          {t('workspaceAddHint')}
        </Text>
        <Flex
          className={`${vendors.length > 5 ? 'w-248 justify-between' : 'w-146 justify-center'}`}
          wrap>
          {loading && <LoadingSpinner className="w-full" loading />}
          {vendors.map((vendor) => (
            <>
              <Flex
                alignItems="center"
                className="h-20 min-w-112 mt-8 bg-white rounded-md cursor-pointer shadow-1-3-1-2"
                id={vendor.slug}
                justifyContent="center"
                key={vendor.id}
                onClick={() => handleVendorSwitch(vendor.id, vendor.name)}>
                <Text size={sizes.XL}>{vendor.name}</Text>
              </Flex>
            </>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SelectWorkspace

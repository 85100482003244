import sizes from '../../ui-kit/sizes'
import colors from '../../ui-kit/colors'
import Button from '../../ui-kit/components/buttons/Button'
import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import buttonsVariants from '../../ui-kit/buttonsVariants'
import { useLazyQuery } from '@apollo/react-hooks'
import { InvoicePdfQuery } from '../../queriesUpdated/queries/invoicePdf.gql'
import { handleError } from '../../utils/utils'
import { useNotifications } from '../../hooks/useNotifications'
import { CircularProgress } from '@mui/material'
import { Text } from '../../ui-kit'

const ViewInvoiceLink = ({ id, isNumberView, invoiceNumber, variant, fullWidth }) => {
  const { t } = useTranslation()

  const [invoiceUrl, setInvoiceUrl] = useState('')
  const { newNotification } = useNotifications()
  const [fetchPdf, { loading: loadingPdfUrl }] = useLazyQuery(InvoicePdfQuery, {
    skip: true,
    onCompleted: (data, errors) => {
      if (data) {
        return setInvoiceUrl(data?.invoicePdf?.url)
      }
      handleError(data?.invoicePdf?.errors || errors, newNotification)
    },
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (id) {
      fetchPdf({ variables: { invoiceId: id } })
    }
  }, [id])

  return (
    <>
      {isNumberView && (
        <>
          {invoiceUrl && (
            <Button
              className={variant === buttonsVariants.LINK && 'underline'}
              fullWidth={fullWidth}
              label={invoiceNumber}
              onClick={() => window.open(invoiceUrl)}
              size={sizes.SM}
              testData="view-invoices"
              variant={variant}
            />
          )}
          {!invoiceUrl && <Text>{invoiceNumber}</Text>}
        </>
      )}
      {!isNumberView && (
        <>
          {invoiceUrl && !loadingPdfUrl && (
            <Button
              className={variant === buttonsVariants.LINK && 'underline'}
              fullWidth={fullWidth}
              label={t('viewInvoice')}
              onClick={() => window.open(invoiceUrl)}
              size={sizes.SM}
              testData="view-invoices"
              variant={variant}
            />
          )}
          {loadingPdfUrl && (
            <CircularProgress
              className="mt-1"
              size="1rem"
              sx={{
                color: colors.SUPPLI_ORANGE,
              }}
            />
          )}
        </>
      )}
    </>
  )
}

ViewInvoiceLink.propTypes = {
  id: PT.string.isRequired,
  variant: PT.oneOf(Object.values(buttonsVariants)),
  isNumberView: PT.bool,
  invoiceNumber: PT.number,
  fullWidth: PT.bool,
}

ViewInvoiceLink.defaultProps = {
  variant: buttonsVariants.LINK,
  fullWidth: false,
}

export default ViewInvoiceLink

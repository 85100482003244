import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog } from '../../../ui-kit'
import { Field, Form } from 'react-final-form'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { validateRequiredField } from '../../../utils/validators'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import { ProjectsToAssignQuery } from '../../../queriesUpdated/queries/project.gql'
import { AssignProjectMutation } from '../../../queriesUpdated/mutations/assignProject.gql'
import { useNotifications } from '../../../hooks/useNotifications'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import { useDirtyFormAlert } from '../../../hooks/useDirtyFormAlert'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { MAX_NUMBER } from '../../../utils/utils'

const AssignToProjectModal = ({
  isOpened,
  setIsOpened,
  invoiceId,
  contractId,
  handleSubmit,
  reopenSidebar,
  customerName,
}) => {
  const { t } = useTranslation()
  const queryVariables = {
    contractId,
    filters: [{ key: 'status', values: 'active' }],
    perPage: MAX_NUMBER,
  }
  const { data, loading } = useCustomQuery({
    query: ProjectsToAssignQuery,
    queryOptions: {
      variables: queryVariables,
      skip: !contractId,
    },
    rollbarOptions: { operationName: 'ProjectsToAssignQuery', target: 'AssignToProjectModal' },
  })
  const projectList =
    data?.projects?.data.map((item) => ({
      value: item.id,
      label: item.name,
      testData: item.name,
    })) || []
  projectList.unshift({
    value: ' ',
    label: t('unassigned'),
    testData: 'unassigned',
  })
  const [assignProject, { loading: isAssignProjectLoading }] = useCustomMutation({
    mutation: AssignProjectMutation,
    rollbarOptions: { operationName: 'AssignProjectMutation', target: 'AssignToProjectModal' },
  })
  const { newNotification } = useNotifications()
  const onSubmit = (values) => {
    const variables =
      values.projectId !== ' '
        ? {
            id: invoiceId,
            projectId: values.projectId,
          }
        : {
            id: invoiceId,
            projectId: null,
          }

    assignProject({ variables }).then(({ data }) => {
      const responseData = data?.assignProject || {}

      if (responseData?.entity) {
        newNotification({ success: t('projectAssigned') })
        handleSubmit()
      }
    })
  }

  const initialValues = {
    projectId: null,
  }

  const closeForm = useCallback(() => {
    setIsOpened(false)
    reopenSidebar()
  }, [setIsOpened, reopenSidebar])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })
  return (
    <Dialog
      isOpened={isOpened}
      setIsOpened={requestClose}
      title={t('assignToProject')}
      titleClass={'font-semibold text-2xl'}>
      <h3 className="text-lg leading-6 font-medium">{customerName}</h3>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, dirty }) => {
          useBeforeUnload({ when: dirty })
          dirty !== isFormDirty && setDirtyFormState(dirty)

          return (
            <form onSubmit={handleSubmit}>
              <div className="w-full mt-6">
                <Field name="projectId">
                  {({ input, meta }) => {
                    return (
                      <NDropdown
                        errorMessage={meta.error && meta.submitFailed ? meta.error : undefined}
                        id={input.name}
                        inputClassName="w-full"
                        isDisabled={loading}
                        label={t('chooseProject')}
                        listClass="max-h-44 p-1 pt-0 pb-0"
                        name={input.name}
                        onChange={(e) => input.onChange(e.target.value)}
                        options={projectList}
                        placeholder={t('project')}
                        testData="assign-to-project"
                        value={input.value}
                      />
                    )
                  }}
                </Field>
              </div>
              <div className="w-full mt-6 flex flex-row justify-end">
                <Button
                  className="mr-2"
                  label={t('cancel')}
                  onClick={requestClose}
                  testData="cancel-assign-project"
                  variant={buttonsVariants.TERTIARY}
                />
                <Button
                  disabled={isAssignProjectLoading}
                  label={t('assignToProject')}
                  testData="submit-assign-project"
                  type="submit"
                />
              </div>
              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
        validate={(values) => ({
          projectId: validateRequiredField(values.projectId),
        })}
      />
    </Dialog>
  )
}

AssignToProjectModal.propTypes = {
  setIsOpened: PropTypes.func.isRequired,
  isOpened: PropTypes.bool,
  invoiceId: PropTypes.string,
  contractId: PropTypes.string,
  customerName: PropTypes.string,
  handleSubmit: PropTypes.func,
  reopenSidebar: PropTypes.func,
}

export default AssignToProjectModal

import React from 'react'
import { Text, Tooltip } from '../../index'
import fontWeight from '../../fontWeight'
import { useTranslation } from 'react-i18next'
import PT from 'prop-types'

const RetentionIcon = ({ className }) => {
  const { t } = useTranslation()
  return (
    <Tooltip className={className} content={t('retention')}>
      <Text fontWeight={fontWeight.MEDIUM}>{t('ret')}</Text>
    </Tooltip>
  )
}

export default RetentionIcon

RetentionIcon.propTypes = {
  className: PT.string,
}
RetentionIcon.defaultPtops = {
  className: '',
}

import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text } from '../../../ui-kit'
import { Field, Form } from 'react-final-form'
import buttonsVariants from '../../../ui-kit/buttonsVariants'
import { validateRequiredField } from '../../../utils/validators'
import { ReceiveDeposit } from '../../../queries/mutations/receiveDeposit.gql'
import { ContractsAutocompleteQuery } from '../../../queries/contracts.gql'
import { ProjectsQuery } from '../../../queries/projects.gql'
import { DepositQuery } from '../../../queries/invoices/invoicesDeposits.gql'
import colors from '../../../ui-kit/colors'
import fontWeight from '../../../ui-kit/fontWeight'
import sizes from '../../../ui-kit/sizes'
import CurrencyInput from '../../../ui-kit/components/inputs/CurrencyInput'
import Textarea from '../../../ui-kit/components/inputs/Textarea'
import FormSelect from '../../../ui-kit/components/Select/FormSelect'
import AlertModal from '../../../ui-kit/components/alertModal/AlertModal'
import { useBeforeUnload } from '../../../hooks/useBeforeUnload'
import Toggle from '../../../ui-kit/components/inputs/Toggle'
import { LocationsQuery } from '../../../queries/locations.gql'
import PaymentType, { getDefaultPaymentMethod } from '../../../components/paymentType/PaymentType'
import { paymentMethodsEligibilityEnum } from '../../../constants/paymentMethodEligibilityEnum'
import VALIDATION_ERRORS from '../../../constants/validationErrors'
import { PaymentMethodsQuery } from '../../../queriesUpdated/queries/paymentMethod.gql'
import { getPaymentTypesOptions } from '../invoicesUtils'
import PaymentResult from '../../../components/paymentResult/PaymentResult'
import { paymentMethodTypes, paymentResultMods } from '../../../constants/paymentResults'
import FinancialEntity from '../../../components/financialEntity/FinancialEntity'
import { FinancialEntitiesQuery } from '../../../queries/financialEntities.gql'
import { getFinancialEntitiesOptions } from '../../../components/financialEntity/utils'
import NDropdown from '../../../ui-kit/components/dropdown/NDropdown'
import { useCustomQuery } from '../../../hooks/useCustomQuery'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { getTransactionPaymentResult } from '../../../utils/paymentResultsUtils'
import { DateTime } from 'luxon'
import DatePicker from '../../../ui-kit/components/datePicker/DatePicker'
import { getFormattedDate, SERVER_DATE_FORMAT } from '../../../ui-kit/utils/dateUtils'
import DepositAttachment from './DepositAttachment'

const DepositReceivePaymentForm = ({
  isOpened,
  contractData,
  isAllContracts,
  isFormDirty,
  setDirtyFormState,
  closeForm,
  requestClose,
  refetchContract,
}) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const [paymentResult, setPaymentResult] = useState(null)
  const [createdDepositId, setCreatedDepositId] = useState(null)
  const [selectedPaymentType, setSelectedPaymentType] = useState(null)
  const [paymentStatusRefreshCounter, setPaymentStatusRefreshCounter] = useState(0)
  const [financialEntity, setFinancialEntity] = useState(null)
  const [financialEntitiesOptions, setFinancialEntitiesOptions] = useState([])
  const [financialEntityValidationError, setFinancialEntityValidationError] = useState(false)
  const [selectedContractId, setSelectedContractId] = useState(contractData?.id || '')
  const [isAuthorizationRunning, setIsAuthorizationRunning] = useState(false)
  const [issueDate, setIssueDate] = useState(DateTime.now().toJSDate())
  const [contractCreditCardFeeEnabled, setContractCreditCardFeeEnabled] = useState(false)
  const [attachment, setAttachment] = useState()

  const { data, refetch } = useCustomQuery({
    query: ContractsAutocompleteQuery,
    queryOptions: {
      skip: !isOpened || !isAllContracts,
    },
    rollbarOptions: { operationName: 'ContractsQuery', target: 'DepositReceivePaymentForm' },
  })
  const [failureReason, setFailureReason] = useState(null)
  const contractsList = useMemo(
    () =>
      isAllContracts
        ? data?.contractsAutocomplete?.data || []
        : [{ value: contractData?.id, label: contractData?.buyer?.name }],
    [data, isAllContracts],
  )
  const { data: locationsData, loading: locationsLoading } = useCustomQuery({
    query: LocationsQuery,
    rollbarOptions: { operationName: 'LocationsQuery', target: 'DepositReceivePaymentForm' },
  })
  const locations = locationsData?.locations?.data
  const locationsList = useMemo(
    () =>
      locations?.map?.((item) => ({
        value: item.id,
        label: item.name,
        testData: item.name,
      })) || [],
    [locations],
  )
  const { data: projectsData, refetch: projectsRefetch } = useCustomQuery({
    query: ProjectsQuery,
    queryOptions: {
      skip: !isOpened || !selectedContractId,
      variables: { filters: [{ key: 'status', values: ['active'] }] },
    },
    rollbarOptions: { operationName: 'ProjectsQuery', target: 'DepositReceivePaymentForm' },
  })
  const [receiveDeposit, { loading }] = useCustomMutation({
    mutation: ReceiveDeposit,
    rollbarOptions: { operationName: 'ReceiveDeposit', target: 'DepositReceivePaymentForm' },
  })
  const onSubmit = useCallback(
    (values) => {
      if (!selectedPaymentType?.id) {
        setPaymentTypeValidationError(true)
        return
      }

      if (!financialEntity) {
        setFinancialEntityValidationError(true)
        return
      }

      const amountCentsValue = Number(values.amountCents.replaceAll(',', ''))
      const variables = {
        data: {
          ...values,
          locationId: values.locationId,
          amountCents: Math.round(amountCentsValue * 100),
          paymentMethodId: selectedPaymentType.id,
          financialEntityId: financialEntity,
          projectId: values.projectId || null,
          authorizationOnly:
            selectedPaymentType.type === paymentMethodTypes.CREDIT_CARD_PAYMENT_METHOD
              ? values.authorizationOnly
              : false,
          issueDate:
            selectedPaymentType?.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD
              ? getFormattedDate(issueDate, SERVER_DATE_FORMAT)
              : null,
          file: attachment,
        },
      }

      setPaymentResult(paymentResultMods.LOADING)
      receiveDeposit({ variables }).then(({ data, errors }) => {
        const responseData = data?.receiveDeposit || {}

        if (responseData?.entity) {
          const paymentMethodType =
            responseData.entity.latestPaymentTransaction?.paymentMethod?.type

          if (paymentMethodType === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD) {
            setPaymentResult(paymentResultMods.PAYCHECK_CREDIT_DEPOSIT_SUCCESS)
          } else {
            setCreatedDepositId(responseData.entity.hashid)
            setIsAuthorizationRunning(variables.data.authorizationOnly)
            setPaymentStatusRefreshCounter((prevState) => prevState + 1)
          }
        } else if (responseData?.errors?.length || errors) {
          setPaymentResult(paymentResultMods.SERVER_ERROR)
        }
      })
    },
    [receiveDeposit, selectedPaymentType, financialEntity, issueDate, attachment],
  )

  const initialValues = useMemo(
    () => ({
      memo: '',
      contractId: contractData?.id || '',
      amountCents: '',
      overpayment: false,
      locationId: '',
      projectId: '',
      authorizationOnly: false,
    }),
    [contractData],
  )

  const loadOptions = useCallback(
    async (search, values) => {
      try {
        const { data: loadedData } = await refetch({
          statuses: ['active'],
          search: search || '',
          sort: 'name.asc',
          page: !values?.length
            ? 1
            : parseInt(data?.contractsAutocomplete?.paginationData.to / 10) + 1 || 1,
        })

        if (loadedData) {
          return {
            options: loadedData.contractsAutocomplete.data,
            hasMore:
              loadedData.contractsAutocomplete.paginationData &&
              loadedData.contractsAutocomplete.paginationData.to <
                loadedData.contractsAutocomplete.paginationData.totalCount,
          }
        }
      } catch (err) {
        return {
          options: [],
          hasMore: false,
        }
      }
    },
    [data],
  )

  const emptyOption = useMemo(() => ({ label: t('unassigned'), value: '' }), [t])
  const loadProjectsOptions = useCallback(
    async (search, values) => {
      const isFirstOptionEmpty =
        values[0] && values[0].label === emptyOption.label && values[0].value === emptyOption.value
      const valuesLength = isFirstOptionEmpty ? values.length - 1 : values.length
      const nextPage = !valuesLength
        ? 1
        : parseInt(projectsData?.projects?.paginationData.to / 10) + 1 || 1
      try {
        const { data: loadedData } = await projectsRefetch({
          search: search || '',
          sort: 'name.asc',
          page: nextPage,
          contractId: selectedContractId,
          filters: [{ key: 'status', values: ['active'] }],
        })

        if (loadedData) {
          const options = loadedData.projects.data.map((option) => ({
            label: option.name,
            value: option.id,
          }))
          return {
            options: isFirstOptionEmpty ? options : [emptyOption, ...options],
            hasMore:
              loadedData.projects.paginationData &&
              loadedData.projects.paginationData.to < loadedData.projects.paginationData.totalCount,
          }
        }
      } catch (err) {
        return {
          options: [],
          hasMore: false,
        }
      }
    },
    [projectsRefetch, projectsData, selectedContractId, emptyOption],
  )

  const [paymentTypesState, setPaymentTypesState] = useState([])
  const [paymentTypeValidationError, setPaymentTypeValidationError] = useState(false)
  const {
    data: paymentMethodsData,
    loading: paymentQueryInProgress,
    refetch: refetchPaymentMethods,
  } = useCustomQuery({
    query: PaymentMethodsQuery,
    queryOptions: {
      variables: {
        contractId: selectedContractId,
        eligibility: paymentMethodsEligibilityEnum.DEPOSIT,
        includeMetaInfo: true,
        includeContractPaymentSettings: true,
        includeAuthorizationInfo: true,
      },
      skip: !isOpened || !selectedContractId,
    },
    rollbarOptions: { operationName: 'PaymentMethodsQuery', target: 'DepositReceivePaymentForm' },
  })
  const handleSetSelectedPaymentType = useCallback((e) => {
    setSelectedPaymentType(e)
    setPaymentTypeValidationError(false)
  }, [])
  useEffect(() => {
    if (isOpened && paymentMethodsData) {
      setPaymentTypesState(getPaymentTypesOptions(paymentMethodsData, t))
      setContractCreditCardFeeEnabled(
        paymentMethodsData?.contract?.paymentSettings?.creditCardFeeEnabled,
      )
    }
  }, [t, isOpened, paymentMethodsData])
  useEffect(() => {
    if (!isOpened) {
      setSelectedContractId(contractData?.id || '')
      setSelectedPaymentType(null)
      setCreatedDepositId(null)
      setPaymentStatusRefreshCounter(0)
      setIsAuthorizationRunning(false)
    }
  }, [isOpened, contractData])
  const handleDoneClick = useCallback(() => {
    refetchContract?.()
    closeForm()
  }, [closeForm, refetchContract])

  const { refetch: refetchDeposit } = useCustomQuery({
    query: DepositQuery,
    queryOptions: {
      variables: { id: createdDepositId },
      skip: !createdDepositId,
    },
    rollbarOptions: { operationName: 'DepositQuery', target: 'DepositReceivePaymentForm' },
  })
  useEffect(async () => {
    if (paymentStatusRefreshCounter === 0) {
      return
    }

    const { data: loadedData } = await refetchDeposit({ id: createdDepositId })

    const transactionPaymentResult = getTransactionPaymentResult(
      loadedData?.deposit?.latestPaymentTransaction,
      setFailureReason,
      isAuthorizationRunning,
    )

    if (transactionPaymentResult) {
      setPaymentResult(transactionPaymentResult)

      return
    }

    const timeoutId = setTimeout(() => {
      setPaymentStatusRefreshCounter((prevState) => prevState + 1)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [paymentStatusRefreshCounter, refetchDeposit, createdDepositId])

  const onCompleted = useCallback((response) => {
    const options = getFinancialEntitiesOptions(response?.financialEntities)
    setFinancialEntitiesOptions(options)
  }, [])
  const { loading: financialEntitiesInProgress } = useCustomQuery({
    query: FinancialEntitiesQuery,
    onCompleted,
    queryOptions: {
      skip: !isOpened,
    },
    rollbarOptions: {
      operationName: 'FinancialEntitiesQuery',
      target: 'DepositReceivePaymentForm',
    },
  })
  const handleSelectedFinancialEntityChange = useCallback((value) => {
    setFinancialEntity(value)
    setFinancialEntityValidationError(false)
  }, [])

  const setCashCheckPaymentMethod = (val) => {
    if (val) {
      const paymentTypeState = paymentTypesState.find((type) => type.name === 'cashCheck')
      if (paymentTypeState) {
        setSelectedPaymentType(paymentTypeState)
      }
    } else {
      setSelectedPaymentType(getDefaultPaymentMethod(paymentTypesState))
    }
  }
  return (
    <Form
      initialValues={initialValues}
      mutators={{
        clearSelectedProject: (args, state, utils) => {
          utils.changeValue(state, 'projectId', () => '')
        },
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, values, form }) => {
        useBeforeUnload({ when: dirty })
        dirty !== isFormDirty && setDirtyFormState(dirty)

        return paymentResult ? (
          <PaymentResult
            failureReason={failureReason}
            mode={paymentResult}
            onDoneClick={handleDoneClick}
            setMode={setPaymentResult}
          />
        ) : (
          <form className={'overflow-hidden'} onSubmit={handleSubmit}>
            <div className="w-full mt-6">
              <Field name="contractId">
                {({ input, ...fieldProps }) => {
                  return (
                    <>
                      <FormSelect
                        input={input}
                        {...fieldProps}
                        error={
                          fieldProps.meta.error && fieldProps.meta.submitFailed
                            ? fieldProps.meta.error
                            : undefined
                        }
                        id={input.name}
                        isDisabled={!isAllContracts}
                        label={t('customer')}
                        loadOptions={loadOptions}
                        onChange={(val) => {
                          input.onChange(val)
                          setSelectedContractId(val)
                          form.mutators.clearSelectedProject()
                        }}
                        options={contractsList || []}
                        placeholder={t('customer')}
                        testData="contracts"
                        touched={fieldProps.meta.touched}
                        value={input.value}
                        withCheckmarks
                      />
                    </>
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="projectId">
                {({ input, ...fieldProps }) => {
                  return (
                    <>
                      <FormSelect
                        input={input}
                        {...fieldProps}
                        cacheUniqs={[selectedContractId]}
                        error={
                          fieldProps.meta.error && fieldProps.meta.submitFailed
                            ? fieldProps.meta.error
                            : undefined
                        }
                        id={input.name}
                        isDisabled={!selectedContractId}
                        label={t('project')}
                        loadOptions={loadProjectsOptions}
                        onChange={input.onChange}
                        options={[emptyOption]}
                        placeholder={t('projects')}
                        testData="projects"
                        touched={fieldProps.meta.touched}
                        value={input.value}
                        withCheckmarks
                      />
                    </>
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="locationId">
                {({ input, ...fieldProps }) => {
                  return (
                    <>
                      <NDropdown
                        errorMessage={
                          fieldProps.meta.error && fieldProps.meta.submitFailed
                            ? fieldProps.meta.error
                            : undefined
                        }
                        isDisabled={locationsLoading}
                        label={t('location')}
                        listClass="max-h-40"
                        onChange={input.onChange}
                        options={locationsList || []}
                        placeholder={t('location')}
                        testData="locations"
                        value={input.value}
                        withSingleOptionAutoSelection
                      />
                    </>
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="amountCents">
                {({ input, meta }) => {
                  return (
                    <Flex column>
                      <Text
                        className="pb-1"
                        color={colors.GRAY_700}
                        fontWeight={fontWeight.MEDIUM}
                        size={sizes.SM}>
                        {t('amount')}
                      </Text>
                      <CurrencyInput
                        error={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        id={input.name}
                        name={input.name}
                        onChange={input.onChange}
                      />
                      {meta.error && meta.touched && meta.submitFailed ? (
                        <p className="pt-2 text-sm text-error">{meta.error}</p>
                      ) : null}
                    </Flex>
                  )
                }}
              </Field>
            </div>

            <div className="w-full mt-6">
              <Field name="overpayment">
                {({ input }) => (
                  <Flex column>
                    <Text className="pb-1" fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                      {t('markAsOverpayment')}
                    </Text>
                    <Toggle
                      handleChange={(val) => {
                        setCashCheckPaymentMethod(val)
                        input.onChange(val)
                      }}
                      value={input.value}
                    />
                  </Flex>
                )}
              </Field>
            </div>

            <Flex className="w-full mt-6" column>
              <PaymentType
                contractCreditCardFeeEnabled={contractCreditCardFeeEnabled}
                contractID={values.contractId}
                disabled={
                  !selectedContractId ||
                  paymentQueryInProgress ||
                  (selectedPaymentType?.name === 'cashCheck' && values.overpayment)
                }
                eligibility={paymentMethodsEligibilityEnum.DEPOSIT}
                name="paymentType"
                onCreateMethod={refetchPaymentMethods}
                selected={selectedPaymentType?.value}
                setSelected={handleSetSelectedPaymentType}
                types={paymentTypesState}
                fullWidth
              />
              {paymentTypeValidationError && (
                <p className="mt-2 text-sm text-error">{VALIDATION_ERRORS.REQUIRED}</p>
              )}
            </Flex>
            {selectedPaymentType?.type === paymentMethodTypes.PAYCHECK_PAYMENT_METHOD && (
              <Flex className="w-full mt-6" column>
                <Text color="text-gray-700" fontWeight={fontWeight.MEDIUM}>
                  {t('paymentDate')}
                </Text>
                <Flex className="w-full" column>
                  <DatePicker
                    className="mt-[0.3rem]"
                    maxDate={DateTime.now().toJSDate()}
                    onChange={setIssueDate}
                    placeholder={t('chooseDate')}
                    value={issueDate}
                  />
                </Flex>
              </Flex>
            )}
            <Flex className="w-full mt-6" column>
              <FinancialEntity
                disabled={!selectedPaymentType || financialEntitiesInProgress}
                options={financialEntitiesOptions}
                selected={financialEntity}
                selectedPaymentMethod={selectedPaymentType}
                setSelected={handleSelectedFinancialEntityChange}
              />
              {financialEntityValidationError && (
                <p className="mt-2 text-sm text-error">{VALIDATION_ERRORS.REQUIRED}</p>
              )}
            </Flex>

            {selectedPaymentType?.allowsAuthorization && (
              <div className="w-full mt-6">
                <Field name="authorizationOnly">
                  {({ input }) => (
                    <Flex column>
                      <Text className="pb-1" fontWeight={fontWeight.MEDIUM} size={sizes.SM}>
                        {t('runAsAuthorization')}
                      </Text>
                      <Toggle handleChange={input.onChange} value={input.value} />
                      {input.value && (
                        <Text
                          className="pt-1"
                          color={colors.RED}
                          fontWeight={fontWeight.MEDIUM}
                          size={sizes.SM}>
                          {t('runAsAuthorizationHint', {
                            period: currentUser.vendorPreference?.authorizationWindowPeriod,
                          })}
                        </Text>
                      )}
                    </Flex>
                  )}
                </Field>
              </div>
            )}

            <div className="w-full mt-6">
              <Field name={'memo'}>
                {({ input }) => {
                  return (
                    <Textarea
                      id={input.name}
                      inputClassName={'h-20'}
                      label={t('memoOptional')}
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )
                }}
              </Field>
            </div>

            <DepositAttachment attachment={attachment} setAttachment={setAttachment} />

            <div className="w-full mt-6 flex flex-row justify-end">
              <Button
                className="mr-2"
                label={t('cancel')}
                onClick={requestClose}
                testData="cancel-receive-dep"
                variant={buttonsVariants.TERTIARY}
              />
              <Button
                disabled={loading}
                label={t('receivePayment')}
                testData="submit-receive-dep"
                type="submit"
              />
            </div>

            <AlertModal confirmClose={closeForm} />
          </form>
        )
      }}
      validate={(values) => {
        let financialEntityError

        if (!financialEntity) {
          financialEntityError = VALIDATION_ERRORS.REQUIRED
          !financialEntityValidationError && setFinancialEntityValidationError(true)
        } else {
          financialEntityValidationError && setFinancialEntityValidationError(false)
        }

        return {
          contractId: validateRequiredField(values.contractId),
          locationId: validateRequiredField(values.locationId),
          amountCents: validateRequiredField(values.amountCents),
          financialEntity: financialEntityError,
        }
      }}
    />
  )
}

DepositReceivePaymentForm.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  contractData: PropTypes.shape({
    aging: PropTypes.number,
    id: PropTypes.string,
    customerName: PropTypes.string,
    outstandingAmountCents: PropTypes.number,
    availableCreditAmountCents: PropTypes.number,
    availableDepositAmountCents: PropTypes.number,
    buyer: PropTypes.shape({
      name: PropTypes.string,
      paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  }),
  isAllContracts: PropTypes.bool.isRequired,
  refetchContract: PropTypes.func,
  setDirtyFormState: PropTypes.func.isRequired,
}

export default DepositReceivePaymentForm

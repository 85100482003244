import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'

const Flex = ({
  row,
  column,
  justifyContent,
  alignItems,
  alignContent,
  flex,
  grow,
  shrink,
  basis,
  gap,
  wrap,
  nowrap,
  className,
  children,
  tag,
  onClick,
  id,
  testData,
}) => {
  const flexClassnames = cx({
    ['flex']: flex,
    ['flex-row']: row,
    ['flex-col']: column,
    ['flex-wrap']: wrap,
    ['flex-nowrap']: nowrap,
    ['flex-grow-0']: grow === 0,
    ['flex-grow']: grow === 1,
    ['flex-shrink-0']: shrink === 0,
    ['flex-shrink']: shrink === 1,
    [`gap-${gap}`]: !!gap, // https://tailwindcss.com/docs/gap
    [`justify-${justifyContent}`]: !!justifyContent,
    [`content-${alignContent}`]: !!alignContent,
    [`items-${alignItems}`]: !!alignItems,
    [className]: !!className,
  })

  const styles = {
    flexBasis: basis,
  }

  const Element = tag

  return (
    <Element
      className={flexClassnames}
      id={id}
      onClick={onClick}
      style={styles}
      testdata={testData}>
      {children}
    </Element>
  )
}

Flex.propTypes = {
  alignContent: PT.oneOf(['start', 'end', 'center', 'between', 'around', 'evenly']),
  alignItems: PT.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
  basis: PT.string,
  children: PT.node.isRequired,
  className: PT.string,
  column: PT.bool,
  flex: PT.bool,
  gap: PT.string,
  grow: PT.number,
  justifyContent: PT.oneOf(['start', 'end', 'center', 'between', 'around', 'evenly']),
  nowrap: PT.bool,
  row: PT.bool,
  shrink: PT.number,
  tag: PT.string,
  wrap: PT.bool,
  onClick: PT.func,
  id: PT.string,
  testData: PT.string,
}

Flex.defaultProps = {
  alignContent: null,
  alignItems: null,
  basis: 'auto',
  className: null,
  column: false,
  flex: true,
  gap: '',
  grow: null,
  justifyContent: null,
  nowrap: false,
  row: false,
  shrink: null,
  tag: 'div',
  wrap: false,
  onClick: null,
  id: '',
  testData: '',
}

export default Flex

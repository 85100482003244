export const kindList = [
  {
    label: 'Commercial',
    value: 'commercial',
  },
  {
    label: 'Residential',
    value: 'residential',
  },
  {
    label: 'Industrial',
    value: 'industrial',
  },
  {
    label: 'Government',
    value: 'government',
  },
]

const VALIDATION_ERRORS = {
  REQUIRED: 'This field is required',
  SHORT_CARD_NUMBER: 'Too short number, should have 16 digits',
  SHORT_CVC: 'Too short number, should have 3 digits',
  EMAIL: 'Email is not valid',
  PHONE_NUMBER: 'Phone number is not valid',
  WEBSITE: 'Website url is not valid',
  ZIPCODE: 'ZIP code is not valid',
  START_DATE_BEFORE_LAST: 'Start date cannot be later than end date',
  START_DATE_EQUALS_LAST: 'End date cannot be equal to start date',
  BANK_ROUTING_NUMBER: 'Bank routing number is not valid',
  RECIPIENT_REQUIRED: 'At least one recipient should be selected',
  AMOUNT_SHOULD_BE_GRATER_THAN_ZERO: 'Payment amount should be greater than zero',
}
export default VALIDATION_ERRORS

import React from 'react'
import PT from 'prop-types'
import { Box, Flex, Icon } from '../../index'
import { useTranslation } from 'react-i18next'
import sizes from '../../sizes'
import { getFormattedDate } from '../../utils/dateUtils'

const Note = ({ createdAt, createdBy, body, className, onClick, pin }) => {
  const { t } = useTranslation()
  const date = new Date(createdAt)

  return (
    <Box className={className} onClick={onClick}>
      <Flex>
        <Flex alignItems={'center'} className={'pr-6'} column>
          <div className={'text-2xl font-medium'}>{date?.getDate()}</div>
          <div className={'text-lg font-normal text-black-500 leading-none'}>
            {date?.toLocaleString('en-us', { month: 'short' })}
          </div>
          <div className={'text-lg font-normal text-black-500 leading-none'}>
            {date?.getFullYear()}
          </div>
        </Flex>
        <Flex className="w-full" column>
          <Flex
            alignItems="center"
            className={'text-warmBlack-400'}
            justifyContent={pin ? 'between' : 'start'}>
            <div>
              {t('createdBy')}: <span className="text-black-500">{createdBy?.fullName}</span>
              <span className="text-black-500 pl-1">
                | {getFormattedDate(createdAt || '', 'hh:mm a')}
              </span>
            </div>
            {pin && <Icon name="pin" size={sizes.LG} />}
          </Flex>
          <div>{body}</div>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Note

Note.propTypes = {
  createdAt: PT.string.isRequired,
  createdBy: PT.string.isRequired,
  body: PT.string.isRequired,
  className: PT.string,
  onClick: PT.func,
  pin: PT.func,
}

Note.defaultProps = {
  onClick: null,
  className: '',
}

import { membershipRoles } from './pages/settings/teamManagement/membershipRoles'

const getNavigationMap = (membershipRole) => [
  {
    name: 'dashboard',
    href: '/dashboard',
    icon: 'home',
    root: true,
    hidden: membershipRole === membershipRoles.COUNTER,
  },
  { name: 'customers', href: '/customers', icon: 'users' },
  { name: 'invoices', href: '/invoices', icon: 'inbox' },
  { name: 'payments', href: '/payments', icon: 'currencyDollar' },
  {
    name: 'reports',
    href: '/reports',
    icon: 'chartBar',
    hidden: membershipRole === membershipRoles.COUNTER,
  },
  {
    name: 'settings',
    href: '/settings',
    icon: 'adjustments',
    hidden: membershipRole !== membershipRoles.ADMIN,
  },
]

export default getNavigationMap

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useQueryParams } from '../../hooks/useQueryParams'
import { authorizeUserByImpersonationToken } from './logic/logic'
import { useRollbar } from '@rollbar/react'

const Impersonate = () => {
  const { queryParams } = useQueryParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const rollbar = useRollbar()

  useEffect(
    async () =>
      await authorizeUserByImpersonationToken(
        queryParams.impersonation_token,
        dispatch,
        navigate,
        rollbar,
      ),
    [dispatch, navigate, queryParams, rollbar],
  )

  return <p>redirecting... please wait</p>
}

export default Impersonate

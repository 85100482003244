import React, { useMemo } from 'react'
import { Flex, Money, PreviewBlock } from '../../../../ui-kit'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../../../components/dataGrid/DataGridComponent'
import { InvoicesShortInfoQuery } from '../../../../queries/invoices/invoicesShortInfo.gql'
import PT from 'prop-types'
import { scopeFilterMap } from '../../../invoices/invoicesUtils'
import Button from '../../../../ui-kit/components/buttons/Button'
import sizes from '../../../../ui-kit/sizes'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import { useNavigate } from 'react-router-dom'
import { useCustomQuery } from '../../../../hooks/useCustomQuery'

const InvoicesLargestPastDue = ({ contractId, className }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const sort = 'outstanding_amount_cents.desc'
  const page = 1
  const search = null

  const queryVariables = {
    filters: [
      scopeFilterMap['outstanding'],
      {
        key: 'contract_id',
        values: [contractId],
      },
    ],
    sort,
    page,
    search,
  }

  const { data, loading } = useCustomQuery({
    query: InvoicesShortInfoQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'InvoicesShortInfoQuery', target: 'InvoicesLargestPastDue' },
  })

  const invoices = data?.invoices

  const rows = invoices?.data || []
  const columns = useMemo(
    () => [
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'invoiceNumber',
        headerName: t('invoiceNumber'),
        flex: 1,
        sortable: false,
        renderCell: (values) => (
          <Button
            label={values?.row?.invoiceNumber}
            onClick={() =>
              navigate(
                // eslint-disable-next-line max-len
                `/customers/${contractId}/invoices/outstanding?additionalFilters=invoicedNumber&filters=invoicedNumber.${values?.row?.invoiceNumber}&openSidebarParams=invoiceNumber.${values?.row?.invoiceNumber}`,
              )
            }
            size={sizes.SM}
            testData="invoice-link-largest-past-due"
            variant={buttonsVariants.LINK}
          />
        ),
      },
      {
        field: 'project',
        headerName: t('project'),
        flex: 1,
        sortable: false,
        renderCell: (values) => (
          <span className={'text-ellipsis overflow-hidden'}>
            {values?.row?.project?.name || '-'}
          </span>
        ),
      },
      {
        field: 'outstandingAmountCents',
        headerName: t('outstanding'),
        renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
        flex: 1,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
      },
    ],
    [],
  )

  return (
    <PreviewBlock
      className={className}
      loading={loading}
      testData="largest-outstanding-invoices"
      title={t('largestPastDueInvoices')}
      viewMoreLink={`/customers/${contractId}/invoices?sort=${sort}`}>
      <Flex>
        <DataGridComponent columns={columns} rows={rows} hideFooter />
      </Flex>
    </PreviewBlock>
  )
}

export default InvoicesLargestPastDue

InvoicesLargestPastDue.propTypes = {
  className: PT.string,
  contractId: PT.string.isRequired,
}

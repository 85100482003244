import React from 'react'
import { Flex, Money, Text } from '../../../ui-kit'
import { useTranslation } from 'react-i18next'
import sizes from '../../../ui-kit/sizes'
import StatusBadge from '../../../ui-kit/components/badges/StatusBadge'
import { displayStatusesMapping, getPaymentMethodNickName } from '../../payments/paymentsTab/util'
import activityType from '../../../types/activities'
import ActivityCardDate from './ActivityCardDate'
import ActivityCardGeneralInfo from './ActivityCardGeneralInfo'
import ActivityCardStatus from './ActivityCardStatus'
import { getCreatedByEvent } from '../utils'

const PaymentActivity = ({ payment }) => {
  const { t } = useTranslation()
  return (
    <Flex justifyContent={'between'}>
      <Flex>
        <ActivityCardDate date={payment?.createdAt} />
        <ActivityCardGeneralInfo
          detailsValue={getCreatedByEvent(payment)}
          entityId={payment.entity?.paymentTransaction?.id}
          entityLabelKey={payment.title || 'payment'}
        />
      </Flex>

      <Flex className="ml-4 w-[620px]" justifyContent="between">
        <Flex>
          <Flex shrink={0}>
            <Flex column>
              <Text className={'mt-1'} color={'text-black-500'}>
                {t('paymentAmount')}:
              </Text>
              <Text className={'mt-3'} color={'text-black-500'}>
                {t('paymentMethod')}:
              </Text>
            </Flex>
            <Flex className={'ml-1'} column>
              <Money className={'font-semibold text-xl'} value={payment?.entity.totalAmountCents} />
              <Text className={'mt-2'} size={sizes.SM}>
                {payment.entity?.paymentMethod?.title +
                  getPaymentMethodNickName(payment.entity?.paymentMethod?.nickname)}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <ActivityCardStatus
          lastUpdatesDate={payment.lastUpdateDate}
          statusNode={
            <StatusBadge
              color={
                displayStatusesMapping[payment?.entity?.paymentTransaction?.displayStatus]?.color
              }
              value={t(
                displayStatusesMapping[payment?.entity?.paymentTransaction?.displayStatus]?.label,
              )}
            />
          }
        />
      </Flex>
    </Flex>
  )
}

export default PaymentActivity
PaymentActivity.propTypes = {
  payment: activityType,
}

import colors from '../../colors'

const customStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      minWidth: 30,
      height: 'fit-content',
      zIndex: 10,
      opacity: state.isDisabled ? '0.5' : '1',
      backgroundColor: 'white',
    }
  },
  control: (provided, state) => {
    return {
      ...provided,
      paddingRight: 0,
      borderRadius: '6px',
      cursor: 'pointer',
      boxShadow: 'none',
      borderColor:
        state.isFocused || state.isActive || state.selectProps.meta.error
          ? colors.SUPPLI_ORANGE
          : colors.BLACK_400,
      '&:hover': {
        borderColor:
          state.isFocused || state.isActive || state.selectProps.meta.error
            ? colors.SUPPLI_ORANGE
            : colors.BLACK_400,
      },
      backgroundColor: state.isDisabled ? 'white' : state.backgroundColor,
    }
  },
  input: (provided) => {
    return {
      ...provided,
      margin: 0,
      padding: 0,
    }
  },
  option: (provided, state) => {
    const fontWeight = state.isSelected ? 'bold' : 'normal'
    const color = state.isSelected ? colors.BLACK : colors.BLACK
    const backgroundColor = colors.WHITE
    const padding = state.isSelected ? ' 0.6rem 2.2rem' : '0.6rem 1rem'
    return {
      ...provided,
      fontWeight,
      color,
      backgroundColor,
      cursor: 'pointer',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#F8F7FC',
        color: colors.GRAY_700,
      },
      padding,
      display: 'flex',
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    // padding: '7px 13px',
    padding: '0 13px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.selectProps.meta.error ? colors.SUPPLI_ORANGE : colors.GRAY_500,
    fontSize: '14px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textAlign: 'left',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => {
    const transform = state.selectProps.menuIsOpen ? null : 'rotate(180deg)'
    const transition = 'transform 300ms ease-out, fill 500ms ease-out'
    return {
      ...provided,
      transform,
      transition,
      '&>svg': {
        width: '20px',
        height: '20px',
        fill: state.selectProps.menuIsOpen ? colors.LIGHT_GRAY1 : colors.GRAY_500,
      },
    }
  },
  menu: (provided) => {
    return {
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      maxHeight: '31rem',
      padding: 0,
      overflowY: 'hidden',
      zIndex: 999999,
    }
  },
  menuList: (provided) => {
    return {
      ...provided,
      paddingRight: 0,
    }
  },
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 999999,
  }),
}

export default customStyles

export const createRoleList = (t) => [
  {
    label: t('generalContractor'),
    value: 'general',
  },
  {
    label: t('subcontractor'),
    value: 'subcontractor',
  },
  {
    label: t('owner'),
    value: 'owner',
  },
  {
    label: t('tenant'),
    value: 'tenant',
  },
  {
    label: t('other'),
    value: 'other',
  },
]

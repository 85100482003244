import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Flex, Text } from '../../../../ui-kit'
import { useDirtyFormAlert } from '../../../../hooks/useDirtyFormAlert'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload } from '../../../../hooks/useBeforeUnload'
import { Field, Form } from 'react-final-form'
import fontWeight from '../../../../ui-kit/fontWeight'
import sizes from '../../../../ui-kit/sizes'
import Textarea from '../../../../ui-kit/components/inputs/Textarea'
import buttonsVariants from '../../../../ui-kit/buttonsVariants'
import AlertModal from '../../../../ui-kit/components/alertModal/AlertModal'
import colors from '../../../../ui-kit/colors'
import DatePicker from '../../../../ui-kit/components/datePicker/DatePicker'
import CurrencyInput from '../../../../ui-kit/components/inputs/CurrencyInput'
import { CreateCreditReview } from '../../../../queries/mutations/createCreditReview.gql'
import { CreditReviewsQuery } from '../../../../queries/creditReviews.gql'
import { ContractQuery } from '../../../../queries/contracts.gql'
import { useNotifications } from '../../../../hooks/useNotifications'
import { validateRequiredField } from '../../../../utils/validators'
import { DateTime } from 'luxon'
import { getFormattedDate, SERVER_DATE_FORMAT } from '../../../../ui-kit/utils/dateUtils'
import { useCustomMutation } from '../../../../hooks/useCustomMutation'

const AddCreditLimitModal = ({ isOpened, setIsOpened, contractId }) => {
  const { t } = useTranslation()
  const { newNotification } = useNotifications()

  const closeForm = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])
  const { isFormDirty, setDirtyFormState, requestClose } = useDirtyFormAlert({
    closeForm,
  })
  const initialValues = useMemo(
    () => ({
      memo: '',
      reviewDate: null,
      nextReviewDate: null,
      creditLimitCents: '',
    }),
    [],
  )

  const [createCreditReview, { loading }] = useCustomMutation({
    mutation: CreateCreditReview,
    rollbarOptions: { operationName: 'CreateCreditReview', target: 'AddCreditLimitModal' },
    mutationOptions: {
      refetchQueries: [CreditReviewsQuery, ContractQuery],
    },
  })
  const handleSubmit = useCallback(
    (values) => {
      const creditLimitCentsValue = Number(values.creditLimitCents.replaceAll(',', ''))
      const variables = {
        data: {
          contractId,
          memo: values.memo || '',
          reviewDate: getFormattedDate(values.reviewDate, SERVER_DATE_FORMAT),
          nextReviewDate: getFormattedDate(values.nextReviewDate, SERVER_DATE_FORMAT),
          creditLimitCents: Math.round(creditLimitCentsValue * 100),
        },
      }
      createCreditReview({ variables }).then(({ data }) => {
        const responseData = data?.createCreditReview || {}

        if (responseData?.entity) {
          newNotification({ success: t('creditReviewAddedSuccessfully') })
          setIsOpened(false)
        }
      })
    },
    [contractId],
  )

  return (
    <Dialog isOpened={isOpened} setIsOpened={requestClose} title={t('addCreditReview')}>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ handleSubmit, dirty, touched }) => {
          useBeforeUnload({ when: touched && dirty })

          if (touched && dirty !== isFormDirty) {
            setDirtyFormState(dirty)
          }

          return (
            <form className="flex flex-col pt-8 pt-2 w-[40rem]" onSubmit={handleSubmit}>
              <div className="w-full">
                <Flex className="w-full pb-6">
                  <Flex className="w-1/2 mr-2" column>
                    <Field name="reviewDate">
                      {({ input, meta }) => (
                        <>
                          <Text
                            className="pb-1"
                            color={colors.GRAY_700}
                            fontWeight={fontWeight.MEDIUM}
                            size={sizes.SM}>
                            {t('creditReviewDate')}
                          </Text>
                          <DatePicker
                            error={meta.error && meta.submitFailed ? meta.error : undefined}
                            maxDate={DateTime.now().toJSDate()}
                            onChange={input.onChange}
                            placeholder={t('chooseDate')}
                            value={input.value}
                          />
                          {meta.error && meta.touched && meta.submitFailed ? (
                            <p className="pt-2 text-sm text-error">{meta.error}</p>
                          ) : null}
                        </>
                      )}
                    </Field>
                  </Flex>
                  <Flex className="w-1/2 ml-2" column>
                    <Field name="nextReviewDate">
                      {({ input, meta }) => (
                        <>
                          <Text
                            className="pb-1"
                            color={colors.GRAY_700}
                            fontWeight={fontWeight.MEDIUM}
                            size={sizes.SM}>
                            {t('nextCreditReviewDateOptional')}
                          </Text>
                          <DatePicker
                            error={meta.error && meta.submitFailed ? meta.error : undefined}
                            minDate={DateTime.now().plus({ day: 1 }).toJSDate()}
                            onChange={input.onChange}
                            placeholder={t('chooseDate')}
                            value={input.value}
                          />
                          {meta.error && meta.touched && meta.submitFailed ? (
                            <p className="pt-2 text-sm text-error">{meta.error}</p>
                          ) : null}
                        </>
                      )}
                    </Field>
                  </Flex>
                </Flex>

                <Flex className="w-full pb-6">
                  <Flex className="w-1/2 mr-2" column>
                    <Field name="creditLimitCents">
                      {({ input, meta }) => {
                        return (
                          <>
                            <Text
                              className="pb-1"
                              color={colors.GRAY_700}
                              fontWeight={fontWeight.MEDIUM}
                              size={sizes.SM}>
                              {t('recommendedCreditLimit')}
                            </Text>
                            <CurrencyInput
                              className="w-full"
                              error={
                                meta.error && meta.touched && meta.submitFailed
                                  ? meta.error
                                  : undefined
                              }
                              id={input.name}
                              name={input.name}
                              onChange={input.onChange}
                            />
                            {meta.error && meta.touched && meta.submitFailed ? (
                              <p className="pt-2 text-sm text-error">{meta.error}</p>
                            ) : null}
                          </>
                        )
                      }}
                    </Field>
                  </Flex>
                  <Flex className="w-1/2 ml-2"></Flex>
                </Flex>

                <Flex className="w-full pb-6" column>
                  <Field name="memo">
                    {({ input, meta }) => (
                      <Textarea
                        errorMessage={
                          meta.error && meta.touched && meta.submitFailed ? meta.error : undefined
                        }
                        inputClassName={'h-44'}
                        label={t('notes')}
                        name="memo"
                        onChange={input.onChange}
                        placeholder={t('notes')}
                        value={input.value}
                      />
                    )}
                  </Field>
                </Flex>

                <Flex className="w-full pb-6">
                  <Text color="text-gray-500" size={sizes.BASE}>
                    {t('addCreditReviewHint')}
                  </Text>
                </Flex>

                <Flex className="w-full" justifyContent="end">
                  <Button
                    className="mr-4"
                    label={t('cancel')}
                    onClick={requestClose}
                    testData="close-add-credit-review"
                    variant={buttonsVariants.TERTIARY}
                  />
                  <Button
                    disabled={loading}
                    label={t('add')}
                    testData="add-credit-review"
                    type="submit"
                  />
                </Flex>
              </div>

              <AlertModal confirmClose={closeForm} />
            </form>
          )
        }}
        validate={(values) => ({
          reviewDate: validateRequiredField(values.reviewDate),
          creditLimitCents: validateRequiredField(values.creditLimitCents),
        })}
      />
    </Dialog>
  )
}

AddCreditLimitModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  contractId: PropTypes.string.isRequired,
  setIsOpened: PropTypes.func.isRequired,
}

export default AddCreditLimitModal

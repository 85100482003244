import React, { useRef, useState } from 'react'
import cx from 'classnames'
import { ConfirmationDialog, Flex, LoadingSpinner, Text } from '../../ui-kit'
import NotificationSettingsView from '../../components/notificationSettingsContent/NotificationSettingsView'
import PropTypes from 'prop-types'
import { useBeforeUnload } from '../../hooks/useBeforeUnload'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import fontWeight from '../../ui-kit/fontWeight'
import sizes from '../../ui-kit/sizes'
import { snakeCase } from 'lodash'
import { useCustomMutation } from '../../hooks/useCustomMutation'
import { UpdateNotificationSettingMutation } from '../../queriesUpdated/mutations/updateNotificationSetting.gql'
import { useNotifications } from '../../hooks/useNotifications'
import {
  collectionsNotificationSettings,
  generalNotificationSettings,
  transactionalNotificationSettings,
} from './customerNotificationsConfig'
import Checkbox from '../../ui-kit/components/inputs/Checkbox'

const CustomerNotificationsContent = ({
  isGlobalSettings,
  initialValues,
  loading,
  isReadOnlyNonAdmin,
  hideDropdowns,
  actionSlot,
  actionSlotProps,
  labelClassName,
  refetchQueries,
  isInactiveContract,
}) => {
  const { t } = useTranslation()
  const formAPI = useRef(null)
  const [updateValues, setUpdateValues] = useState(null)
  const [notificationOverride, setNotificationOverride] = useState(false)

  const handleChange = (updater, fieldName) => {
    let values

    if (updater instanceof Event) {
      const [name, key] = updater.target.name.split('.')
      const value = updater.target.value
      values = { key, name, value }
    } else {
      const [name, key] = fieldName.split('.')
      values = { key, name, value: updater }
    }

    if (isGlobalSettings && values.key !== 'statementCutoff') {
      setUpdateValues(values)
    } else {
      handleSubmit(values)
    }
  }
  const { newNotification } = useNotifications()

  const [updateVendorCustomerNotificationSetting, { loading: requestInProgress }] =
    useCustomMutation({
      mutation: UpdateNotificationSettingMutation,
      rollbarOptions: {
        operationName: 'UpdateNotificationSettingMutation',
        target: 'CustomerNotifications',
      },
      mutationOptions: {
        refetchQueries: [refetchQueries],
      },
    })

  const handleSubmit = (values, notificationOverride = false) => {
    const { key, name, value } = values
    const targetSetting = initialValues?.notificationTypes?.find(
      (setting) => setting.name === snakeCase(name),
    )

    if (!targetSetting) {
      return
    }

    // eslint-disable-next-line no-unused-vars
    const { __typename, id, ...rest } = { ...targetSetting, [key]: value }
    if (isGlobalSettings) {
      rest.overrideExisting = notificationOverride
      rest.overrideDeliveryMethod = key === 'emailEnabled' ? 'email' : 'sms'
      delete rest.statementCutoff
    }
    const variables =
      key === 'statementCutoff'
        ? {
            id: initialValues?.id,
            data: [],
            monthlyStatementCutoff: value,
          }
        : { id: initialValues?.id, data: [rest] }

    updateVendorCustomerNotificationSetting({
      variables,
    }).then(({ data }) => {
      const responseData = data?.updateNotificationSetting || {}

      if (responseData?.entity) {
        newNotification({ success: t('notificationSettingsUpdated') })
      }
    })
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ dirty, values, form }) => {
        formAPI.current = form
        useBeforeUnload({ when: dirty })

        return (
          <form className="mr-10">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Flex className="pb-5 pt-2">
                  <Text className="ml-4" fontWeight={fontWeight.MEDIUM}>
                    {t('email')}
                  </Text>
                  <Text className="ml-6" fontWeight={fontWeight.MEDIUM}>
                    {t('text')}
                  </Text>
                </Flex>
                {isInactiveContract && (
                  <Flex
                    alignItems="center"
                    className="w-full h-40 p-5 border border-dashed my-2"
                    justifyContent="center">
                    <Text align="center" color="text-black-500">
                      {t('inactiveNotificationGeneral')}
                    </Text>
                  </Flex>
                )}
                {!isInactiveContract && (
                  <NotificationSettingsView
                    actionSlot={actionSlot}
                    actionSlotProps={actionSlotProps}
                    disabled={isReadOnlyNonAdmin || requestInProgress}
                    hideDropdowns={!isGlobalSettings}
                    isReadOnlyNonAdmin={isReadOnlyNonAdmin}
                    onChange={handleChange}
                    settings={generalNotificationSettings}
                    values={values.notificationTypes}
                  />
                )}
                {collectionsNotificationSettings.length && (
                  <>
                    <Text className={cx('pl-44 my-2', labelClassName)} size={sizes.XL} paragraph>
                      {t('collections')}
                    </Text>
                    {isInactiveContract && (
                      <Flex
                        alignItems="center"
                        className="w-full h-40 p-5 border border-dashed my-2"
                        justifyContent="center">
                        <Text align="center" color="text-black-500">
                          {t('inactiveNotificationCollection')}
                        </Text>
                      </Flex>
                    )}
                    {!isInactiveContract && (
                      <NotificationSettingsView
                        actionSlot={actionSlot}
                        actionSlotProps={actionSlotProps}
                        disabled={isReadOnlyNonAdmin || requestInProgress}
                        hideDropdowns={hideDropdowns}
                        isReadOnlyNonAdmin={isReadOnlyNonAdmin}
                        onChange={handleChange}
                        settings={collectionsNotificationSettings}
                        values={values.notificationTypes}
                      />
                    )}
                  </>
                )}
                {transactionalNotificationSettings.length && (
                  <>
                    <Text className={cx('pl-44 my-2', labelClassName)} size={sizes.XL} paragraph>
                      {t('transactional')}
                    </Text>
                    <NotificationSettingsView
                      actionSlot={actionSlot}
                      actionSlotProps={actionSlotProps}
                      disabled={isReadOnlyNonAdmin || requestInProgress}
                      hideDropdowns={hideDropdowns}
                      isReadOnlyNonAdmin={isReadOnlyNonAdmin}
                      onChange={handleChange}
                      settings={transactionalNotificationSettings}
                      values={values.notificationTypes}
                    />
                  </>
                )}
              </>
            )}
            {isGlobalSettings && (
              <ConfirmationDialog
                isOpened={!!updateValues}
                onSubmitButtonNoClick={() => {
                  setNotificationOverride(false)
                  setUpdateValues(null)
                }}
                onSubmitButtonYesClick={() => {
                  handleSubmit(updateValues, notificationOverride)
                  setUpdateValues(null)
                  setNotificationOverride(false)
                }}
                setIsOpened={() => {
                  setNotificationOverride(false)
                  setUpdateValues(null)
                }}
                submitButtonNoLabel={t('cancel')}
                submitButtonYesLabel={t('confirm')}
                title={t('confirmHowApplyChange')}>
                <Flex className="w-[788px]" column>
                  <Text className="pt-8" color="text-black-500">
                    {t('confirmHowApplyChangeDescription1')}
                    <Text
                      className="text-black mx-1 border-b border-black-500"
                      fontWeight={fontWeight.MEDIUM}>
                      {t('not')}
                    </Text>
                    {t('confirmHowApplyChangeDescription2')}
                  </Text>
                  <Text className="pt-4" color="text-black-500">
                    {t('confirmHowApplyChangeDescription3')}
                  </Text>
                  <label
                    className="pt-4 font-medium"
                    color="text-black-500"
                    htmlFor="globalNotificationOverride">
                    {t('confirmHowApplyChangeDescription4')}
                  </label>
                  <Checkbox
                    className="pt-2"
                    id="globalNotificationOverride"
                    name="globalNotificationOverride"
                    onChange={setNotificationOverride}
                    value={notificationOverride}
                  />
                  <Text className="font-medium pt-4" color="text-black-500">
                    {t('confirmHowApplyChangeDescription5')}
                  </Text>
                </Flex>
              </ConfirmationDialog>
            )}
          </form>
        )
      }}
    />
  )
}

CustomerNotificationsContent.propTypes = {
  loading: PropTypes.bool,
  isReadOnlyNonAdmin: PropTypes.bool,
  actionSlot: PropTypes.object,
  refetchQueries: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.array.isRequired,
  hideDropdowns: PropTypes.bool,
  labelClassName: PropTypes.string,
  actionSlotProps: PropTypes.object,
  isGlobalSettings: PropTypes.bool,
  requestInProgress: PropTypes.bool,
  isInactiveContract: PropTypes.bool,
}

CustomerNotificationsContent.defaultProps = {
  loading: false,
  isReadOnlyNonAdmin: false,
  hideDropdowns: false,
  isGlobalSettings: false,
  requestInProgress: false,
  isInactiveContract: false,
}

export default CustomerNotificationsContent

import React from 'react'
import { Box, Flex, Heading, LoadingSpinner, Money } from '../../index'
import PT from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import colors from '../../colors'
import { renderToStaticMarkup } from 'react-dom/server'
import basicOptions from './basicChartOptions'

const ColumnChart = ({
  className,
  xAxisData,
  yAxisData,
  showYAxis,
  showXAxis,
  valueFieldName,
  title,
  isLoading,
  showLabels,
  columnColors,
  chartHeight,
  inSection,
  columnWidth,
  borderRadius,
  spacing,
  columnGap,
  subTitle,
  onColumnClick,
}) => {
  const options = {
    ...basicOptions,
    chart: {
      ...basicOptions.chart,
      height: chartHeight || basicOptions.chart.height,
      type: 'column',
      spacing: spacing,
    },
    series: [
      {
        data: yAxisData,
      },
    ],
    xAxis: {
      ...basicOptions.xAxis,
      categories: xAxisData,
      visible: showXAxis,
    },
    yAxis: {
      ...basicOptions.yAxis,
      visible: showYAxis,
    },
    plotOptions: {
      column: {
        colorByPoint: true,
        colors: columnColors || ['#D1FAE5', '#FFFAE4', '#FFEDDC', '#FEE2E2', '#CD2525'],
        states: {
          hover: {
            brightness: -0.05,
          },
          inactive: {
            opacity: 0,
          },
        },
        groupPadding: 0,
        groupZPadding: 0,
        pointPadding: columnGap,
        borderRadius: borderRadius,
        pointWidth: columnWidth,
        dataLabels: {
          enabled: showLabels,
          crop: false,
          overflow: 'none',
          style: {
            fontSize: 12,
            color: colors.BLACK,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
          },
          formatter: function () {
            if (valueFieldName === 'amountCents' || valueFieldName === 'outstandingAmountCents') {
              return renderToStaticMarkup(<Money fractionDigits={0} value={this.point.options.y} />)
            } else {
              return renderToStaticMarkup(<span>{this.point.options.y}</span>)
            }
          },
        },
        tooltip: { enabled: false },
        events: {
          click: onColumnClick,
        },
      },
    },
  }

  return inSection ? (
    <Box className={className}>
      <Flex column>
        <Heading
          className={'font-medium'}
          isBold={false}
          size={'xl'}
          subHeading={subTitle}
          subHeadingSize="sm">
          {title}
        </Heading>
        <div className={'mt-2'}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <HighchartsReact constructorType={'chart'} highcharts={Highcharts} options={options} />
          )}
        </div>
      </Flex>
    </Box>
  ) : (
    <HighchartsReact constructorType={'chart'} highcharts={Highcharts} options={options} />
  )
}

export default ColumnChart

ColumnChart.propTypes = {
  className: PT.string,
  chartHeight: PT.number,
  columnColors: PT.arrayOf(PT.string),
  isLoading: PT.bool,
  showYAxis: PT.bool,
  showXAxis: PT.bool,
  showLabels: PT.bool,
  title: PT.string,
  xAxisData: PT.arrayOf(PT.oneOfType([PT.number, PT.string])),
  yAxisData: PT.arrayOf(PT.oneOfType([PT.number, PT.string])),
  valueFieldName: PT.string.isRequired,
  inSection: PT.bool,
  columnWidth: PT.number,
  borderRadius: PT.number,
  spacing: PT.arrayOf(PT.number),
  columnGap: PT.number,
  subTitle: PT.string,
  onColumnClick: PT.func,
}

ColumnChart.defaultProps = {
  className: '',
  chartHeight: undefined,
  columnColors: undefined,
  isLoading: false,
  showYAxis: false,
  showXAxis: false,
  showLabels: false,
  title: '',
  inSection: true,
  columnWidth: 30,
  borderRadius: 2,
  spacing: [20, 0, 0, 0],
  columnGap: 0.28,
  subTitle: '',
  onColumnClick: null,
}

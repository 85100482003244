import {
  GENERAL_CATEGORY,
  getContractSettingsByCategory,
  notificationSettings,
} from '../../../../../components/notificationSettingsContent/customerNotificationsConfig'
import { snakeCase } from 'lodash'
export const getExtendedNotificationSettings = (
  contractNotificationTypes,
  userNotificationTypes,
  isCreateUser,
) =>
  notificationSettings
    .filter((item) => item.isDisplayOnContract)
    .filter((item) => !item.dropdownKey)
    .map((configSetting) => {
      const contractItem = contractNotificationTypes.find(
        (item) => item.name === snakeCase(configSetting.name),
      )
      const userItem = userNotificationTypes.find(
        (item) => item.name === snakeCase(configSetting.name),
      )
      return {
        ...configSetting,
        isEmailEditEnabled: contractItem?.emailEnabled,
        isTextEditEnabled: contractItem?.smsEnabled,
        email: contractItem?.emailEnabled && (isCreateUser ? true : userItem?.emailEnabled),
        text: contractItem?.smsEnabled && (isCreateUser ? true : userItem?.smsEnabled),
      }
    })

export const mapEditUserInitialValues = (extendedNotificationConfig) =>
  extendedNotificationConfig.reduce((acc, notification) => {
    if (notification.category === GENERAL_CATEGORY) {
      acc[notification.name] = {
        title: notification.title,
        contactTitle: notification.contactTitle,
        isEmailEditEnabled: notification.isEmailEditEnabled,
        isTextEditEnabled: notification.isTextEditEnabled,
        email: notification.email,
        text: notification.text,
        category: notification.category,
      }
    }
    if (notification.category !== GENERAL_CATEGORY) {
      const accItem = acc[notification.category] || {}
      acc[notification.category] = {
        title: notification.category,
        isEmailEditEnabled: accItem.isEmailEditEnabled || notification.isEmailEditEnabled,
        isTextEditEnabled: accItem.isTextEditEnabled || notification.isTextEditEnabled,
        email: accItem.email || notification.email,
        text: accItem.text || notification.text,
        category: notification.category,
      }
    }
    return acc
  }, {})

export const getMappedNotificationSettings = (values) => {
  return Object.keys(values).reduce((acc, prop) => {
    if (values[prop].category === GENERAL_CATEGORY) {
      acc.push({
        name: snakeCase(prop),
        emailEnabled: values[prop].email,
        smsEnabled: values[prop].text,
        customerScope: 'none',
        frequency: 'once',
      })
      return acc
    } else {
      const filteredValues = getContractSettingsByCategory(values[prop].category)
      const mappedValues = filteredValues.map((item) => ({
        name: snakeCase(item.name),
        emailEnabled: values[prop].email,
        smsEnabled: values[prop].text,
        customerScope: 'none',
        frequency: 'once',
      }))
      return acc.concat(mappedValues)
    }
  }, [])
}

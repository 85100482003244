import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryParams } from '../../hooks/useQueryParams'
import { camelCase, toInteger } from 'lodash'
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs'
import { ContractsQuery, ContractsAvailableFilters } from '../../queries/contracts.gql'
import { VendorQuery } from '../../queries/vendors.gql'

import PageHeader from '../../components/headers/PageHeader'
import { MONEY } from '../../utils/dataTypes'
import StatsFilters from '../../components/StatsFilters'
import { useTranslation } from 'react-i18next'
import DataGridComponent from '../../components/dataGrid/DataGridComponent'
import { useNavigate } from 'react-router-dom'
import FormattedDate from '../../ui-kit/components/text/FormattedDate'
import { Flex, Money, StatusBadge } from '../../ui-kit'
import { getPaginationData, parseFilterQuery } from '../../utils/utils'
import { getDisplayStatusValue, getStatisticData } from './utils'
import FiltersControlButton from '../../components/filters/FiltersControlButton'
import { applyStatusFilter } from '../../components/filters/filterUtils'
import { useCustomQuery } from '../../hooks/useCustomQuery'

const Contracts = () => {
  const { queryParams } = useQueryParams()

  const defaultSort = 'outstanding_amount_cents.desc'
  const sort = queryParams.sort || defaultSort
  const page = toInteger(queryParams.page) || 1
  const search = queryParams.search
  const { t } = useTranslation()
  const { setBreadcrumbs } = useBreadcrumbs()
  const [availableFilters, setAvailableFilters] = useState(null)
  const filtersQuery = useMemo(() => queryParams.filters || null, [queryParams.filters])
  const userFilters = useMemo(() => parseFilterQuery(filtersQuery), [filtersQuery])
  const additionalFilters = useMemo(
    () => (queryParams?.additionalFilters || '').split(','),
    [queryParams?.additionalFilters],
  )

  useEffect(() => {
    setBreadcrumbs([{ label: t('customers'), link: '/customers' }, { label: t('allCustomers') }])

    return function cleanup() {
      setAvailableFilters(null)
    }
  }, [])

  const pageSize = useMemo(() => 100, [])
  const queryVariables = useMemo(
    () => ({
      filters: userFilters,
      sort,
      page,
      search,
      perPage: pageSize,
    }),
    [userFilters, sort, page, search, pageSize],
  )
  const { data, loading } = useCustomQuery({
    query: ContractsQuery,
    queryOptions: {
      variables: queryVariables,
    },
    rollbarOptions: { operationName: 'ContractsQuery', target: 'Contracts' },
  })
  const onCompleted = useCallback((response) => {
    response?.contracts?.availableFilters &&
      setAvailableFilters(response.contracts.availableFilters)
  }, [])
  useCustomQuery({
    query: ContractsAvailableFilters,
    onCompleted,
    rollbarOptions: { operationName: 'ContractsAvailableFilters', target: 'Contracts' },
  })
  const vendorData = useCustomQuery({
    query: VendorQuery,
    rollbarOptions: { operationName: 'VendorQuery', target: 'Contracts' },
  })

  const { overdueLevelGroups, outstandingAmountCents, paymentSettings } =
    vendorData?.data?.currentVendor || {}
  const statisticData = [
    ...getStatisticData(overdueLevelGroups, t),
    {
      id: 'totalOutstanding',
      title: t('totalOutstanding'),
      value: outstandingAmountCents || 0,
      type: MONEY,
      level: null,
      notClickable: true,
    },
  ]
  const navigate = useNavigate()

  const contracts = data?.contracts
  const rows = contracts?.data
  const paginationData = getPaginationData(contracts)
  const columns = useMemo(() => {
    const columnsFirstPart = [
      {
        field: 'id',
        filterId: additionalFilters.includes('id') && 'id',
        headerName: t('id'),
        hide: true,
      },
      {
        field: 'homeLocation',
        filterId: 'homeLocation',
        filterTitle: t('homeLocation'),
        hide: true,
      },
      {
        field: 'referenceId',
        headerName: t('id'),
        flex: 12.5,
      },
      {
        field: 'name',
        headerName: t('customerName'),
        flex: 20,
        valueGetter: (values) => values?.row?.buyer?.name,
      },
      {
        field: 'outstandingAmountCents',
        filterId: 'outstandingAmount',
        filterDataType: MONEY,
        headerName: t('outstanding'),
        flex: 12.5,
        renderCell: (values) => <Money value={values?.row?.outstandingAmountCents} />,
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'netDueAmountCents',
        filterDataType: MONEY,
        headerName: t('netDue'),
        flex: 12.5,
        renderCell: (values) => <Money value={values?.row?.netDueAmountCents} />,
        align: 'right',
        headerAlign: 'right',
      },
    ]
    const columnsSecondPart = [
      {
        field: 'pendingPaymentsAmountCents',
        headerName: t('pendingPayments'),
        flex: 12.5,
        renderCell: (values) => <Money value={values?.row?.pendingPaymentsAmountCents} />,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        field: 'lastPaymentDate',
        filterId: 'lastPaymentDate',
        headerName: t('lastPayment'),
        align: 'center',
        headerAlign: 'center',
        flex: 12.5,
        renderCell: (values) => <FormattedDate date={values?.row?.lastPaymentDate} />,
      },
      {
        field: 'status',
        filterId: 'overdueLevel',
        headerName: t('status'),
        flex: 12,
        renderCell: (values) => {
          return (
            <StatusBadge iconName="dot" value={getDisplayStatusValue(values?.row?.overdueLevel)} />
          )
        },
      },
      {
        field: 'autopay',
        headerName: t('autopay'),
        flex: 12,
        sortable: false,
        renderCell: (values) => {
          return values?.row?.autopayConfiguration ? (
            <StatusBadge className="text-sm" color={'suppliOrange'} value={t('autopay')} />
          ) : null
        },
      },
      {
        id: 'vendorUser',
        filterId: 'vendorUser',
        headerName: t('user'),
        hide: true,
      },
    ]
    const creditLimitColumn = {
      field: 'creditLimitCents',
      headerName: t('creditLimit'),
      flex: 12.5,
      renderCell: (values) => <Money value={values?.row?.creditLimitCents} />,
      align: 'right',
      headerAlign: 'right',
    }

    return paymentSettings?.showCreditLimit
      ? [...columnsFirstPart, creditLimitColumn, ...columnsSecondPart]
      : [...columnsFirstPart, ...columnsSecondPart]
  }, [t, paymentSettings, additionalFilters])

  const handleRowClick = (model) => {
    navigate(`/customers/${model?.row?.id}/overview`)
  }

  const defaultSortModel = useMemo(() => {
    const sortSource = queryParams?.sort || defaultSort

    const [field, direction] = sortSource.split('.')

    return [{ field: camelCase(field), sort: direction }]
  }, [])
  const onItemClick = useCallback(
    ({ level }) => {
      const levelTitle = getDisplayStatusValue(level?.overdueLevel)
      applyStatusFilter('overdue_level', levelTitle, availableFilters, navigate, '/customers')
    },
    [availableFilters, navigate],
  )

  return (
    <>
      <StatsFilters data={statisticData} onItemClick={onItemClick} />

      <Flex className="justify-between items-center">
        <PageHeader headerTitle={t('customers')} subHeading={paginationData.totalCount} />
        <FiltersControlButton />
      </Flex>
      <div className="flex mt-4">
        <DataGridComponent
          availableFilters={availableFilters}
          columns={columns}
          loading={loading || vendorData?.loading}
          page={page}
          pageSize={pageSize}
          paginationData={paginationData}
          rowClassName="cursor-pointer"
          rowClick={handleRowClick}
          rows={rows}
          searchLabel={t('customers')}
          sortModel={defaultSortModel}
        />
      </div>
    </>
  )
}

export default Contracts
